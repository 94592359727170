import React from 'react';
import style from 'components/Admin/UI/Label/style.less';


export const AdminLabel = ({ title, children }) => {
	return (
		<div className={style.label}>
			<div className={style.title}><span>{ title }</span></div>
			<div className={style.value}>{ children }</div>
		</div>
	);
};