import listStyle from 'components/Admin/UI/List/style.less';
import RouteList from 'components/Admin/UI/RouteList';
import AdminStatus from 'components/Admin/UI/Status';
import language from 'language';
import { dateToString, dateUTCToClient } from 'libs/convertDate';
import { shortFullName } from 'libs/shortName';
import React from 'react';
import { Link } from 'react-router-dom';


const OfferItem = props => {
    return (
        <ul>
            {
                props.users.map(el => {
                    const date = dateUTCToClient(el.createTime);
                    return (
                        <li key={el.id} className={listStyle.item}>
                            <Link to={`${props.link ? props.link : '/admin/offer/'}${el.id}`} className={listStyle.link}/>

                            <div className={listStyle.item__id}>
                                {el.id}
                            </div>

                            <div className={listStyle.item__route}>
                                <RouteList
                                    start={el.startPlace}
                                    end={el.finishPlace}
                                />
                            </div>

                            <div className={listStyle.item__createTime}>
                                {
                                    el.cargoCategory &&
                                    <div className={listStyle.item__description}>
                                        <span className={listStyle.false}>Тип груза:</span>{' '}
                                        {language[el.cargoCategory]}
                                    </div>
                                }
                                <div className={listStyle.item__description}>
                                    <span className={listStyle.false}>ФИО:</span>{' '}
                                    {shortFullName(el.user.lastName, el.user.firstName, el.user.middleName)}
                                </div>
                                <div className={listStyle.item__description}>
                                    <span className={listStyle.false}>Дата создания:</span>{' '}
                                    {dateToString(date, 'fullDateShortYear')}, {dateToString(date, 'time')}
                                </div>
                                {
                                    el.proposalsTotalCount &&
                                    <div className={listStyle.item__description}>
                                        <span className={listStyle.false}>Кол-во предложений:</span>{' '}
                                        {el.proposalsTotalCount ? el.proposalsTotalCount : 0}
                                    </div>
                                }

                            </div>

                            <div className={listStyle.item__verification}>
                                <AdminStatus status={el.status}/>

                                {/*<button style={{ position: 'relative', zIndex: 9 }}*/}
                                        {/*onClick={() => props.setOfferStatus(el.id, 'published')}>*/}
                                    {/*status*/}
                                {/*</button>*/}
                            </div>

                        </li>
                    )
                })
            }
        </ul>
    )
};


export default OfferItem;