import React from 'react';
import Panel from 'components/UI/Wrappers/Panel';
import {Field} from "redux-form";
import Input from 'components/UI/Form/Fields/Input';
import InputDate from 'components/UI/Form/Fields/InputDate';
import Address2 from 'components/UI/Form/Fields/Address2';
import PropTypes from 'prop-types';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import InputSelectGroup from 'components/UI/Wrappers/InputSelectGroup';
import Grid from 'components/UI/Wrappers/Grid';
import Point from 'components/UI/Point';
import { getItem } from "libs/localStorage";

const BeginPoint = props => {
	const {name} = props;

	let heightPointLine = getItem('heightPoint', 0);

	return (
		<Panel title="Информация о загрузке" marginBottom={30}>
			<LayoutField>
				<span>Укажите адрес используя поиск по буквам</span>
				<InputSelectGroup>
					<Point point="A" absolute height={heightPointLine} />
					<Address2
						renderToMap="A"
						name={`${name}.point`}
						dispatch={props.dispatch}
						label="Город, населёный пункт, улица, дом"
						setValue={props.setValue}
					/>
					<Field
						placeholder="Радиус"
						type="number"
						unit="км"
						name={`${name}.radius`}
						childGroup="right"
						component={Input}
					/>
				</InputSelectGroup>
			</LayoutField>
			<Grid col="form-2">
				<Field
					name={`${name}.startDate`}
					component={InputDate}
					label="Дата начала маршрута"
				/>
			</Grid>
		</Panel>
	);
};

BeginPoint.propTypes = {
	name: PropTypes.string.isRequired,
	dispatch: PropTypes.func.isRequired,
	setValue: PropTypes.func.isRequired,
};

export default BeginPoint;