import React from 'react';
import styles from './styles.less';
import star from 'components/UI/Icons/svg/stargold.svg';
import getWordEnd from 'libs/getWordEnd';
import PropTypes from 'prop-types';


const RatingShort = ({ rating, count }) => {
	// рейтинг в кратком виде для поиска
	if (rating === 0 || rating === undefined || rating === null || count === 0 || count === null) return null;
	return (
		<div className={`${styles.rating} ${ count > 10 && styles.justText}`}>
			<img src={star} alt="" />
			<span>&nbsp;{ rating }</span>
			<span className={styles.dash}>&nbsp;—</span>
			<span className={styles.count}>&nbsp;{ count } { getWordEnd(count, ['отзыв','отзыва','отзывов']) }</span>
		</div>
	)
};


RatingShort.propTypes = {
	rating: PropTypes.number,
	count: PropTypes.number,
};
RatingShort.defaultProps = {
	rating: null,
	count: null,
};


export default RatingShort;