import React from 'react';
import PropTypes from 'prop-types';
import NotFound from 'components/Admin/UI/List/NotFound';
import style from 'components/Admin/UI/Container/style.less';


const AdminContainer = ({ children }) => (
	<div className={style.container}>{ children }</div>
);

NotFound.propTypes = {
	children: PropTypes.node,
};

NotFound.defaultProps = {
	text: '',
};

export default AdminContainer;