import React from 'react';
import styles from './style.less';
import Select from 'components/UI/Form/Fields/Select';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import getWordEnd from 'libs/getWordEnd';
import sortByInverse from 'components/UI/Icons/svg/sort-by-attributes.svg';
import sortBy from 'components/UI/Icons/svg/sort-by-attributes-interface-button-option.svg';


const SearchHeader = ({ role, loading, count, changeSort, sortVal }) => {
	if (role === undefined) return null;
	
	let filter = [
		{ val: 'price', title: 'По ставке', icon: sortBy },
		{ val: '!price', title: 'По ставке', icon: sortByInverse },
		{ val: 'startDate', title: 'По дате', icon: sortBy },
		{ val: '!startDate', title: 'По дате', icon: sortByInverse },
	];
	
	if (role === 'driver')
		filter.push(
			{ val: 'distance', title: 'По дистанции', icon: sortBy },
			{ val: '!distance', title: 'По дистанции', icon: sortByInverse });
	
	const find = role === 'driver' ? ['Найдена','Найдено','Найдено'] : ['Найден','Найдено','Найдено'];
	const whomFind = role === 'driver' ? ['заявка','заявки','заявок'] : ['маршрут','маршрута','маршрутов'];
	
	return (
		<div className={styles.result_header}>
			
			{
				loading && count === 0 ?
					<h3>Ищем { role === 'driver' ? 'заявки' : 'маршруты' }</h3> :
					<h3>{ getWordEnd(count, find) } { count } { getWordEnd(count, whomFind) }</h3>
			}
			
			<div className={styles.filter}>
				
				<Select
					classNameContainer={styles.select}
					input={{ onChange: e => changeSort(e), value: sortVal } }
				>
					{
						filter.map(el =>
							<OptionSelect
								key={el.val}
								value={el.val}
							>
								<img src={el.icon} alt="" />
								{ el.title }
							</OptionSelect>
						)
					}
				</Select>
			</div>
		</div>
	)
};


export default SearchHeader;