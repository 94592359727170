import React, { Fragment } from 'react';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import style from 'components/ReduxForms/Search/style.less';
import InputSelectGroup from 'components/UI/Wrappers/InputSelectGroup';
import Point from 'components/UI/Point';
import Address2 from 'components/UI/Form/Fields/Address2';
import { change, Field } from 'redux-form';
import Input from 'components/UI/Form/Fields/Input';
import { NumberNormalize, NumberNormalizeSlice } from 'libs/normalize';
import InputDate from 'components/UI/Form/Fields/InputDate';
import Button from 'components/UI/Form/Button';
import { ArrowDownNavigate } from 'components/UI/Icons';
import Grid from 'components/UI/Wrappers/Grid';
import Select from 'components/UI/Form/Fields/Select';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBodyTypesListStore, getCargoListStore, getLoadTypesListStore } from 'libs/selections';


class Intercity extends React.Component {

	static propTypes = {
		handleSubmit: PropTypes.func,
		error: PropTypes.string,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
	};

	static defaultProps = {
		handleSubmit: () => {
		},
		invalid: false,
		submitting: false,
		error: '',
	};

	setValue = (field, value) => {
		this.props.dispatch(change('searchCustomers', field, value));
	};

	render() {
		let { dispatch } = this.props;
		return (
			<Fragment>
				<LayoutField className={style.customMargin}>
					<div className={`${style.address} ${this.props.showAllParams ? style.defaultGrid : ''}`}>
						<InputSelectGroup>
							<Point point="A" className={style.point} pointLineClass={style.pointLine} />
							<Address2
								setValue={this.setValue}
								dispatch={dispatch}
								name="point_a"
								containerGroup="right-input"
								classNameContainer={style.pointForInput}
								label="Откуда забрать?"
							/>
							<Field
								component={Input}
								childGroup="right"
								name="fromRadius"
								placeholder="Радиус"
								classNameContainer={style.large_size}
								normalize={NumberNormalize}
								unit="км"
							/>
						</InputSelectGroup>
						<InputSelectGroup>
							<Point point="B" className={style.point} />
							<Address2
								setValue={this.setValue}
								dispatch={dispatch}
								name="point_b"
								containerGroup="right-input"
								classNameContainer={style.pointForInput}
								label="Куда доставить?"
							/>
							<Field
								component={Input}
								childGroup="right"
								name="toRadius"
								placeholder="Радиус"
								normalize={NumberNormalize}
								classNameContainer={style.large_size}
								unit="км"
							/>
						</InputSelectGroup>
						<Field
							component={InputDate}
							name={'startDate'}
							label={'Дата загрузки'}
						/>
					</div>
				</LayoutField>
				<div className={style.params}>
					<Button
						className={`${style.extendedSearchBtn} ${this.props.showAllParams ? style.openButton : ''}`}
						onClick={this.props.toggleParams}
					>
						<span>{ this.props.showAllParams ? 'Скрыть поиск' : 'Расширенный поиск' }</span>
						{ ArrowDownNavigate() }
					</Button>
					<div className={this.props.showAllParams ? style.open : ''}>
						<Grid col="search-3">
							<LayoutField>
								<InputSelectGroup>
									<Field
										name="weight"
										component={Input}
										label="Вес груза"
										containerGroup="right"
										normalize={NumberNormalize}
									/>
									<Field
										name="weightType"
										component={Select}
										childGroup="right"
									>
										<OptionSelect value={1000}>тонн</OptionSelect>
										<OptionSelect value={1}>кг</OptionSelect>
									</Field>
								</InputSelectGroup>
							</LayoutField>
							<LayoutField>
								<Field
									name="volume"
									component={Input}
									label="Обьём груза"
									normalize={NumberNormalizeSlice}
									unit={['м',<sup>3</sup>]}
								/>
							</LayoutField>
							<LayoutField>
								<Field
									name="categoryId"
									component={Select}
									label="Тип груза"
								>
									{
										this.props.categoryList.map((el) => (
											<OptionSelect value={el.id} key={el.id}>
												{ el.titleRu }
											</OptionSelect>
										))
									}
								</Field>
							</LayoutField>
						</Grid>
						<LayoutField>
							<Grid col="search-3">
								<Field
									name="bodyTypeId"
									component={Select}
									label="Тип кузова"
								>
									{
										this.props.bodyTypes.map((el) => (
											<OptionSelect value={el.id} key={el.id}>
												{ el.titleRu }
											</OptionSelect>
										))
									}
								</Field>
								<Field
									name="loadTypeId"
									component={Select}
									label="Тип погрузки"
								>
									{
										this.props.loadTypes.map((el) => (
											<OptionSelect value={el.id} key={el.id}>
												{ el.titleRu }
											</OptionSelect>
										))
									}
								</Field>
							</Grid>
						</LayoutField>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default connect(
	state => ({
		categoryList: getCargoListStore(state),
		bodyTypes: getBodyTypesListStore(state),
		loadTypes: getLoadTypesListStore(state),
	})
)(Intercity);
