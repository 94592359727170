import * as adminActions from 'actions/admin';
import { chatActions } from 'actions/chat';
import { add } from 'actions/notifications';
import request from 'libs/request';
import { call, put } from 'redux-saga/effects';
import { offersActions } from 'actions/offers';

let listEmpty = { items: [], total: 0, meta: { totalToday: 0 } };


export function* getDrivers(action) {

    const response = yield call(() =>
        request('get', '/api/1.0/admin/executors', action.payload));

    if (response.data.errors || response.status !== 200) {
        if (action.payload.isProfileVerification === 1)
            yield put(adminActions.setDrivers(listEmpty));
        else
            yield put(adminActions.setDriversVerification(listEmpty));

        action.meta.reject();
    } else {
        if (action.payload.isProfileVerification === 1)
            yield put(adminActions.setDrivers(response.data));
        else
            yield put(adminActions.setDriversVerification(response.data));

        action.meta.resolve();
    }
}

export function* getClients(action) {

    const response = yield call(() =>
        request('get', '/api/1.0/admin/customers', action.payload));

    if (response.data.errors || response.status !== 200) {
        yield put(adminActions.setClients(listEmpty));
        action.meta.reject();
    } else {
        yield put(adminActions.setClients(response.data));
        action.meta.resolve();
    }
}

export function* getOffers(action) {

    const response = yield call(() =>
        request('get', '/api/1.0/admin/offers', action.payload));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: response.data.errors,
            time: 10
        }));
    } else {
        yield put(adminActions.setOffers({ ...response.data, type: action.payload.type }));
    }
}

export function* getOfferByIDAdmin(action) {

    const response = yield call(() =>
        request('get', '/api/1.0/trucking/offer/' + action.payload));

    yield put(adminActions.loadingOfferByID());

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: response.data.errors,
            time: 10
        }));
    } else {
        yield put(adminActions.setOfferByID(response.data));
    }
}


export function* moderationOffer(action) {
    const response = yield call(() =>
        request('post', '/api/1.0/admin/offer/' + action.payload.id + '/moderation', { status: action.payload.status, description: action.payload.description }));
    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: response.data.errors,
            time: 10
        }));
    } else {
        yield put(adminActions.changeOfferStatus(action.payload));
        yield put(add({
            type: 'success',
            text: 'Статус заявки успешно изменен',
            time: 10
        }));
    }
}

export function* moderationRoute(action) {
    const response = yield call(() =>
        request('post', '/api/1.0/admin/route/' + action.payload.id + '/moderation', { status: action.payload.status, description: action.payload.description }));
    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: response.data.errors,
            time: 10
        }));
    } else {
        yield put(adminActions.changeRouteStatus(action.payload));
        yield put(add({
            type: 'success',
            text: 'Статус маршрута успешно изменен',
            time: 10
        }));
    }
}

export function* getRouteByIDAdmin(action) {
    const response = yield call(() =>
        request('get', '/api/1.0/driver/route/' + action.payload));
    yield put(adminActions.loadingRouteByID());
    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: response.data.errors,
            time: 10
        }));
    } else {
        // let fromServerToClient = routeServerToClient(response.data);
        // yield put(mapAction.setPoints(fromServerToClient.items.map(el => el.point)));
        yield put(adminActions.setRouteByID(response.data));
    }
}

export function* moderationArbitrate(action) {
    const response = yield call(() =>
        request('post', '/api/1.0/trucking/arbitration/' + action.payload.id + '/' + action.payload.status));
    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: response.data.errors,
            time: 10
        }));
    } else {
        yield put(adminActions.getArbitrations());
        yield put(add({
            type: 'success',
            text: 'Статус арбитража успешно изменен',
            time: 10
        }));
    }
}

export function* getRoutes(action) {
    const response = yield call(() =>
        request('get', '/api/1.0/admin/routes', action.payload));
    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: response.data.errors,
            time: 10
        }));
    } else {
        yield put(adminActions.setRoutes({ ...response.data, type: action.payload.type }));
    }
}

export function* getTrades(action) {
    const response = yield call(() =>
        request('get', '/api/1.0/admin/trades', action.payload));
    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: response.data.errors,
            time: 10
        }));
    } else {
        yield put(adminActions.setTrades(response.data));
    }
}

export function* getArbitrations(action) {
    const response = yield call(() =>
        request('get', '/api/1.0/admin/trades', action.payload));
    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: response.data.errors,
            time: 10
        }));
    } else {
        yield put(adminActions.setArbitrations(response.data));
    }
}

export function* getPayTradeStatus(action) {
    const response = yield call(() =>
        request('get', '/api/1.0/trucking/trade/pay-status/' + action.payload));
    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: response.data.errors,
            time: 10
        }));
    } else {
        console.log(response.data);
    }
}

export function* getArbitrate(action) {
    // todo arbitrate
    const response = yield call(() =>
        request('get', '/api/1.0/trucking/arbitration/' + action.payload));
    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: response.data.errors,
            time: 10
        }));
    } else {
        yield put(adminActions.setArbitrateByID(response.data));
        // console.log(response.data.dialog.id); todo нужно получить чат в рамках арбитража, сейчас получает в общем списке чата
        yield put(chatActions.getHistoryByID({ dialogId: response.data.dialogArbitration.id, page: 1 }));
    }
}

export function* setBanToUser(action) {
    const response = yield call(() =>
        request('post', '/api/1.0/dialog/' + action.payload.dialogId + '/create-ban', { userId: action.payload.userId }));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: response.data.errors,
            time: 10
        }));
    } else {
        yield put(add({
            type: 'success',
            text: 'Пользователь забанен',
            time: 10
        }));
    }
}

export function* rmBanToUser(action) {
    const response = yield call(() =>
        request('post', '/api/1.0/dialog/' + action.payload.dialogId + '/delete-ban', { userId: action.payload.userId }));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Произошла непредвиденная ошибка',
            time: 10
        }));
    } else {
        yield put(add({
            type: 'success',
            text: 'Пользователь разбанен',
            time: 10
        }));
    }
}


export function* verifyUser(action) {
    action.payload.message = 'тест';
    action.payload.userId = parseInt(action.payload.userId);

    const response = yield call(() =>
        request('post', '/api/1.0/profile/verification', action.payload));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: response.data.errors,
            time: 10
        }));
    } else {
        yield put(add({
            type: 'success',
            text: 'Статус верификации был изменен',
            time: 10
        }));
    }
}

export function* getUsers(action) {
    const response = yield call(() =>
        request('get', '/api/1.0/profiles/', action.payload));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: response.data.errors,
            time: 10
        }));
        action.meta.reject();
    } else {
        yield put(adminActions.setUsers(response.data));
        action.meta.resolve();
    }
}

export function* getUserByID(action) {
    const response = yield call(() =>
        request('get', '/api/1.0/profile/' + action.payload));

    if (response.data.errors || response.status !== 200) {
        yield put(adminActions.setUserByID(undefined));
        action.meta.reject();
    } else {
        yield put(adminActions.setUserByID(response.data));
        action.meta.resolve();
    }
}

export function* setInsure(action) {
    const response = yield call(() =>
      request('post', '/api/1.0/admin/trades/' + action.payload.id + '/insurance', action.payload));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Не удалось задать код страхования',
            time: 10
        }));
    } else {
        yield put(add({
            type: 'success',
            text: 'Код страхования успешно задан',
            time: 10
        }));
        yield put(offersActions.getTradeByID(action.payload.id))
    }
}
