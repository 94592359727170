import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, stopSubmit, formValueSelector, change, reset} from 'redux-form';
import Input from 'components/UI/Form/Fields/Input';
import Button from 'components/UI/Form/Button';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import Grid from 'components/UI/Wrappers/Grid';
import InputSelectGroup from 'components/UI/Wrappers/InputSelectGroup';
import style from '../style.less';
import Address2 from 'components/UI/Form/Fields/Address2';
import Select from 'components/UI/Form/Fields/Select';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import InputDate from 'components/UI/Form/Fields/InputDate';
import connect from 'react-redux/es/connect/connect';
import Point from 'components/UI/Point';
import { ArrowDownNavigate } from 'components/UI/Icons';
import { compose } from 'redux';
import InputGroup from 'components/UI/Wrappers/InputGroup';
import { NumberNormalize, NumberNormalizeSlice } from 'libs/normalize';
import styles from 'components/Search/style.less';
import Panel from 'components/UI/Wrappers/Panel';
import { getBodyTypesListStore, getLoadTypesListStore } from 'libs/selections';

class SearchDrivers extends React.PureComponent {

	static propTypes = {
		handleSubmit: PropTypes.func,
		error: PropTypes.string,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
	};

	static defaultProps = {
		handleSubmit: () => {
		},
		invalid: false,
		submitting: false,
		error: '',
	};

	state = {
		showAllParams: false,
	};

	setValue = (field, value) => {
		this.props.dispatch(change('searchDrivers', field, value));
	};

	reset = () => {
		this.props.dispatch(reset('searchDrivers'));
		this.props.resetData();
	};

	toggleParams = () => this.setState({ showAllParams: !this.state.showAllParams });

	render() {
		let { handleSubmit, invalid, dispatch } = this.props;
		return (
			<Panel
				className={styles.panelForm}
				marginBottom={30}
			>
				<form onSubmit={handleSubmit} autoComplete="off">
					<LayoutField className={style.customMargin}>
						<div className={`${style.address} ${this.state.showAllParams ? style.defaultGrid : ''}`}>
							<InputSelectGroup>
								<Point point="A" className={style.point} pointLineClass={style.pointLine} />
								<Address2
									setValue={this.setValue}
									dispatch={dispatch}
									name="point_a"
									containerGroup="right-input"
									classNameContainer={style.pointForInput}
									label="Откуда забрать?"
								/>
								<Field
									component={Input}
									childGroup="right"
									name="fromRadius"
									placeholder="Радиус"
									normalize={NumberNormalize}
									classNameContainer={style.large_size}
									unit="км"
								/>
							</InputSelectGroup>
							<InputSelectGroup>
								<Point point="B" className={style.point} />
								<Address2
									setValue={this.setValue}
									dispatch={dispatch}
									name="point_b"
									containerGroup="right-input"
									classNameContainer={style.pointForInput}
									label="Куда доставить?"
								/>
								<Field
									component={Input}
									childGroup="right"
									name="toRadius"
									placeholder="Радиус"
									normalize={NumberNormalize}
									classNameContainer={style.large_size}
									unit="км"
								/>
							</InputSelectGroup>
							<Field
								component={InputDate}
								name={'startDate'}
								label={'Дата загрузки'}
							/>
						</div>
					</LayoutField>
					<div className={style.params}>
						<div className={this.state.showAllParams ? style.open : ''}>
							<Grid col="search-3">
								<LayoutField>
									<InputSelectGroup>
										<Field
											name="weight"
											component={Input}
											label="Грузоподъемность"
											containerGroup="right"
											normalize={NumberNormalize}
										/>
										<Field
											name="weightType"
											component={Select}
											childGroup="right"
										>
											<OptionSelect value={1000}>тонн</OptionSelect>
											<OptionSelect value={1}>кг</OptionSelect>
										</Field>
									</InputSelectGroup>
								</LayoutField>
								<LayoutField>
									<Field
										name="volume"
										component={Input}
										label="Обьём кузова"
										normalize={NumberNormalizeSlice}
										unit={['м',<sup>3</sup>]}
									/>
								</LayoutField>
								<LayoutField>
									<Field
										name="bodyTypeId"
										component={Select}
										label="Тип кузова"
									>
										{
											this.props.bodyTypes.map((el) => (
												<OptionSelect value={el.id} key={el.id}>
													{ el.titleRu }
												</OptionSelect>
											))
										}
									</Field>
								</LayoutField>
							</Grid>
							<LayoutField>
								<Grid col="search-3">
									<Field
										name="loadTypeId"
										component={Select}
										label="Тип погрузки"
									>
										{
											this.props.loadTypes.map((el) => (
												<OptionSelect value={el.id} key={el.id}>
													{ el.titleRu }
												</OptionSelect>
											))
										}
									</Field>
									<InputGroup className={style.mobileInputGroupPrice}>
										<Field
											name="priceFrom"
											component={Input}
											normalize={NumberNormalize}
											label="Ставка от"
											unit={'р/км'}
										/>
										<Field
											name="priceTo"
											component={Input}
											normalize={NumberNormalize}
											label="Ставка до"
											unit={'р/км'}
										/>
									</InputGroup>
								</Grid>
							</LayoutField>
						</div>
					</div>
					<div className={style.footer}>
						<Button
							className={`${style.extendedSearchBtn} ${this.state.showAllParams ? style.openButton : ''}`}
							onClick={this.toggleParams}
						>
							<span>{ this.state.showAllParams ? 'Скрыть поиск' : 'Расширенный поиск' }</span>
							{ ArrowDownNavigate() }
						</Button>
						<div className={style.searchIn}>
							{/*<Button onClick={this.reset} size={'large'} color={'remove'}>*/}
							{/*	Сброс*/}
							{/*</Button>*/}
							<Button disabled={invalid} size={'large'} type={'submit'}>
								Показать грузы
							</Button>
						</div>
					</div>
				</form>
			</Panel>
		);
	}
}

const selector = formValueSelector('searchDrivers');

export default compose(
	connect(
		store => ({
			point_a: selector(store, 'point_a'),
			point_b: selector(store, 'point_b'),
			bodyTypes: getBodyTypesListStore(store),
			loadTypes: getLoadTypesListStore(store),
		})
	),
	reduxForm({
		form: 'searchDrivers',
		onChange: (newValues, dispatch) => {
			dispatch(stopSubmit('searchDrivers', {}));
		}
	})
)(SearchDrivers);
