exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3Y2921t4zgkcYcDrRwd_DW {\n  max-width: calc(90% + 10px);\n}\n@media screen and (max-width: 700px) {\n  ._3Y2921t4zgkcYcDrRwd_DW {\n    max-width: 100%;\n  }\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/ReduxForms/FeedbackForm/style.less"],"names":[],"mappings":"AAAA;EACE,4BAA4B;CAC7B;AACD;EACE;IACE,gBAAgB;GACjB;CACF","file":"style.less","sourcesContent":[".width80 {\n  max-width: calc(90% + 10px);\n}\n@media screen and (max-width: 700px) {\n  .width80 {\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"width80": "_3Y2921t4zgkcYcDrRwd_DW"
};