import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';

const Points = props => (
	<div className={`${style.points} ${props.className}`}>
		<div className={`${style.point} ${style.point_A}`}>A</div>
		<div className={`${style.point} ${style.point_B}`}>B</div>
	</div>
);

Points.propTypes = {
	point: PropTypes.string,
	className: PropTypes.string,
};
Points.defaultProps = {
	point: '',
	className: '',
};

export default Points;
