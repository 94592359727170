exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n._3UiVM85n1MFRauB_KGjPHA {\n  width: 1rem;\n  fill: #fff;\n}\n._2Pay9opaQEy6SFa8MrlF8X {\n  fill: #7f8488;\n}\n.E5cUMnvIZrR-ZvcYd0gMo {\n  fill: #272727;\n}\n.NR-yZj9HErnjmXW6Ytkpr {\n  width: 1.4rem;\n  margin-left: -5px;\n}\n.cudkcdaUe_hG27A-eVW6g {\n  fill: #ff421d;\n  width: 12px;\n}\n._249GdsEz7mdFK1Fwit6uvc,\n._2SmbJ2jsjsLLdWNjnbmuaJ {\n  fill: #D6D5D8;\n}\n.bfqz3ODaWI0s2nPu_bzas {\n  fill: #d8d8d8;\n}\n._3Q7WF_Dla7flSmsrABLcbs {\n  fill: #cecfcf;\n}\n.WUx6N8qHk6kOBHDkRMKzv {\n  width: 22px;\n  fill: #bdbdbd;\n  margin: 0!important;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/UI/Icons/style.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,YAAY;EACZ,WAAW;CACZ;AACD;EACE,cAAc;CACf;AACD;EACE,cAAc;CACf;AACD;EACE,cAAc;EACd,kBAAkB;CACnB;AACD;EACE,cAAc;EACd,YAAY;CACb;AACD;;EAEE,cAAc;CACf;AACD;EACE,cAAc;CACf;AACD;EACE,cAAc;CACf;AACD;EACE,YAAY;EACZ,cAAc;EACd,oBAAoB;CACrB","file":"style.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.icon {\n  width: 1rem;\n  fill: #fff;\n}\n.iconUnlink {\n  fill: #7f8488;\n}\n.iconExchange {\n  fill: #272727;\n}\n.iconLogin {\n  width: 1.4rem;\n  margin-left: -5px;\n}\n.iconArrowUp {\n  fill: #ff421d;\n  width: 12px;\n}\n.iconApple,\n.iconGooglePlay {\n  fill: #D6D5D8;\n}\n.iconStar {\n  fill: #d8d8d8;\n}\n.iconSpeechBubble {\n  fill: #cecfcf;\n}\n.iconHeartOutline {\n  width: 22px;\n  fill: #bdbdbd;\n  margin: 0!important;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"icon": "_3UiVM85n1MFRauB_KGjPHA",
	"iconUnlink": "_2Pay9opaQEy6SFa8MrlF8X",
	"iconExchange": "E5cUMnvIZrR-ZvcYd0gMo",
	"iconLogin": "NR-yZj9HErnjmXW6Ytkpr",
	"iconArrowUp": "cudkcdaUe_hG27A-eVW6g",
	"iconApple": "_249GdsEz7mdFK1Fwit6uvc",
	"iconGooglePlay": "_2SmbJ2jsjsLLdWNjnbmuaJ",
	"iconStar": "bfqz3ODaWI0s2nPu_bzas",
	"iconSpeechBubble": "_3Q7WF_Dla7flSmsrABLcbs",
	"iconHeartOutline": "WUx6N8qHk6kOBHDkRMKzv"
};