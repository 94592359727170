import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import style from 'components/Admin/UI/Menu/style.less';


class AdminNav extends Component {
	
	render() {
		
		const list = [
			{ href: '/admin/drivers/', title: 'Водители' },
			{ href: '/admin/clients/', title: 'Клиенты' },
			{ href: '/admin/offers/', title: 'Заявки' },
			{ href: '/admin/trades/', title: 'Сделки' },
			{ href: '/admin/routes/', title: 'Маршруты' },
			{ href: '/admin/statistic/', title: 'Статистика' },
		];
		
		const { pathname } = this.props.location;
		
		return (
			<ul className={style.nav}>
				{
					list.map(el => (
						<li key={el.href}>
							<Link
								className={el.href === pathname ? style.active : ''}
								to={el.href}
							>
								{ el.title }
							</Link>
						</li>
					))
				}
			</ul>
		)
	}
}


export default withRouter(AdminNav);