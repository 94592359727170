import { call, put } from 'redux-saga/effects';
import request from 'libs/request';
import { add } from 'actions/notifications';
import ym from 'react-yandex-metrika';
import * as serverHelpersActions from 'actions/serverHelpersActions';

export function *registrationUser(action){
	const response = yield call(()=>
		request('post',
			'/api/1.0/auth/registration/' + (action.payload.driver ? 'driver' :'customer'),
			action.payload));

	if(response.data.errors){
		yield call(() => ym('reachGoal', 'FAILURE_ONE_STEP_REGISTRATION'));
		yield call(()=>action.meta.reject(response.data.errors));
	}else{
		yield call(() => ym('reachGoal', 'SUCCESS_ONE_STEP_REGISTRATION'));
		yield call(()=>action.meta.resolve(true));
	}
}

export function *sendOfferWithoutReg(action) {
	const response = yield call(() => request('post', '/api/1.0/auth/registration/client-offer', action.payload));
	
	if (response.data.errors) {
		yield call(()=>action.meta.reject(response.data.errors));
	} else {
		yield put(add({ type: 'success', text: 'Заявка успешно сохранена', time: 10 }));
		yield call(()=>action.meta.resolve());
	}
}

export function *authUser(action) {
	const response = yield call(()=>
		request('post', '/api/1.0/auth/login', action.payload));

	if(response.data.errors){
		yield call(()=>action.meta.reject(response.data.errors));
	}else{
		yield call(()=>action.meta.resolve(response.data.token));
	}
}

export function* confirmUser(action) {
	const response = yield call(()=>
		request('post', '/api/1.0/auth/registration/confirm-phone', action.payload));

	if(response.data.errors){
		yield call(() => ym('reachGoal', 'FAILURE_TWO_STEP_REGISTRATION'));
		yield call(()=>action.meta.reject({ code: ['Неправильный код'] }));
		// yield call(()=>action.meta.reject(response.data.errors));
	}else{
		yield call(() => ym('reachGoal', 'SUCCESS_TWO_STEP_REGISTRATION'));
		yield call(()=>action.meta.resolve(response.data.token));
	}
}

export function* feedback(action) {
	const response = yield call(() =>
	request('post', '/api/1.0/feedback', action.payload));

	if (response.data.errors || response.status !== 200) {
		yield put(add({
			type: 'error',
			text: 'При отправке письма произошла ошибка',
			time: 10
		}));
		action.meta.reject(response.data.errors);
	} else {
		yield put(add({
			type: 'success',
			text: 'Ваше письмо успешно отправлено в поддержку',
			time: 10
		}));
		action.meta.resolve(response);
	}
}

export function* recoveryPassword (action) {
	const response = yield call(()=>
		request('post', '/api/1.0/auth/recovery', action.payload));

	if(response.data.errors || response.status !== 200){
		yield call(()=>action.meta.reject(response.data.errors));
	}else {
		yield call(() => action.meta.resolve(true));
	}
}

export function* verifyPhone (action) {
	const response = yield call(()=>
		request('post', '/api/1.0/auth/verify-phone', action.payload));

	if(response.data.errors || response.status !== 200){
		yield call(()=>action.meta.reject(response.data.errors));
	}else {
		yield call(() => action.meta.resolve(true));
	}
}
