import * as adminActions from "actions/admin";
import { getArbitrationsState, getTradesState } from 'components/Admin/getStore';
import LeftMenu from 'components/Admin/Pages/Trades/List/LeftMenu';
import TradeContent from "components/Admin/Pages/Trades/List/TradeContent";
import SearchAdminForm from 'components/Admin/ReduxForms/SearchAdminForm';
import AdminGrid from 'components/Admin/UI/Grid';
import AdminList from 'components/Admin/UI/List';
import AdminPage from 'components/Admin/UI/Page';
import AdminPagination from "components/Admin/UI/Pagination/index";
import UpdateButton from 'components/Admin/UI/UpdateButton';
import { convertNumberSpaces } from 'libs/convertNumberSpaces';
import { getItem, setItem } from 'libs/localStorage';
import { statusList } from "libs/vars";
import React from 'react';
import { connect } from 'react-redux';


class AdminTradeList extends React.Component {

    state = {
        loading: true,
        status: 'all',
        params: getItem('tradeListParams_all', false) ? JSON.parse(getItem('tradeListParams_all')) : {
            page: 1,
            pageSize: 30
        }
    };

    getData = (status, params) => {
        if (status === 'all')
            this.props.dispatch(adminActions.getTrades({
                ...params, ...this.state.params,
                status: statusList[status]
            }));
        else
            this.props.dispatch(adminActions.getArbitrations({ ...this.state.params, status: statusList[status] }));
    };

    setPage = page => {
        const { status, params } = this.state;
        this.setState({ params: { ...params, page } }, () => {
            setItem(`tradeListParams_${status}`, JSON.stringify(this.state.params));
            this.getData(status, { ...this.state.params, status: statusList[status] })
        });
    };

    onHandleData = status => {
        let params = getItem(`tradeListParams_${status}`, false) ?
            JSON.parse(getItem(`tradeListParams_${status}`))
            : { page: 1, pageSize: 30 };

        this.setState({ params, status });
        this.getData(status, { ...params, status: statusList[status] });
    };

    updateTrades = () => {
        const { params, status } = this.state;
        this.getData(status, { ...params, status: statusList[status] });
    };

    componentWillMount() {
        this.getData('all', { page: 1, pageSize: 30, status: statusList['all'] });
        this.getData('arbitrate', { page: 1, pageSize: 30, status: statusList['arbitrate'] });
    }

    render() {

        const { trades, arbitrations } = this.props,
            { status } = this.state,
            isAll = status === 'all',
            titles = ['ID', 'Маршрут', 'Информация', 'Статус'];

        let list = [
            {
                onClick: () => this.onHandleData('all'),
                isActive: isAll,
                title: 'Список',
                count: convertNumberSpaces(trades.total),
                newCount: convertNumberSpaces(0)
            },
            {
                onClick: () => this.onHandleData('arbitrate'),
                isActive: !isAll,
                title: 'Арбитраж',
                count: convertNumberSpaces(arbitrations.total),
                newCount: convertNumberSpaces(0)
            },
        ];

        return (
            <AdminPage>
                <LeftMenu
                    list={list}
                />
                <div>
                    <AdminGrid type="mainContent">
                        <h1>
                            {isAll ? 'Список сделок' : 'Список арбитражей'}
                            <UpdateButton
                                onClick={this.updateTrades}
                            />
                        </h1>
                        <SearchAdminForm/>
                        <AdminList
                            titles={titles}
                            type="offers"
                        >
                            <TradeContent
                                trade={isAll ? trades : arbitrations}
                                loading={false}
                            />
                            <AdminPagination
                                pageCount={Math.ceil((isAll ? trades.total : arbitrations.total) / 30)}
                                activePage={this.state.params.page}
                                setPage={e => this.setPage(e)}
                            />
                        </AdminList>
                    </AdminGrid>
                </div>
            </AdminPage>
        )
    }
}


export default connect(
    state => ({
        trades: getTradesState(state),
        arbitrations: getArbitrationsState(state),
    })
)(AdminTradeList);