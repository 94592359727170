import React from 'react';
import Page from 'components/UI/Wrappers/Page';

class Requisites extends React.Component {

	render() {

		return (
			<Page title={'Реквизиты'}>
				<div style={{lineHeight: '1.7'}}>ООО "Харри Ларри"</div>
				<div style={{lineHeight: '1.7'}}>ИНН/КПП 6324097480/632401001</div>
				<div style={{lineHeight: '1.7'}}>ОГРН 1186313106176</div>
				<div style={{lineHeight: '1.7'}}>Юридический адрес</div>
				<div style={{lineHeight: '1.7'}}>445012, Самарская область, город Тольятти, Коммунистическая улица, дом 8, офис 602</div>
			</Page>
		)
	}
}

export default Requisites;