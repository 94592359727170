exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1L6QmHsUTmTpOIVJ9yfYCZ {\n  padding: 15px 15px 20px 15px;\n  border-bottom: 1px solid #f0f4f9;\n}\n._1L6QmHsUTmTpOIVJ9yfYCZ form {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}\n._1L6QmHsUTmTpOIVJ9yfYCZ form > div {\n  width: 230px;\n  height: 54px;\n}\n._1L6QmHsUTmTpOIVJ9yfYCZ form > label {\n  margin-left: 30px;\n}\n._1L6QmHsUTmTpOIVJ9yfYCZ ._1L0Ih8Q_LyPHw0Dg7CnnKE {\n  margin-left: 15px;\n  margin-right: 15px;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/Admin/UI/Periods/style.less"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,iCAAiC;CAClC;AACD;EACE,cAAc;EACd,oBAAoB;EACpB,4BAA4B;CAC7B;AACD;EACE,aAAa;EACb,aAAa;CACd;AACD;EACE,kBAAkB;CACnB;AACD;EACE,kBAAkB;EAClB,mBAAmB;CACpB","file":"style.less","sourcesContent":[".periodContainer {\n  padding: 15px 15px 20px 15px;\n  border-bottom: 1px solid #f0f4f9;\n}\n.periodContainer form {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}\n.periodContainer form > div {\n  width: 230px;\n  height: 54px;\n}\n.periodContainer form > label {\n  margin-left: 30px;\n}\n.periodContainer .tire {\n  margin-left: 15px;\n  margin-right: 15px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"periodContainer": "_1L6QmHsUTmTpOIVJ9yfYCZ",
	"tire": "_1L0Ih8Q_LyPHw0Dg7CnnKE"
};