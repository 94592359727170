import React from 'react';
import { Link } from 'react-router-dom';
import logoShortWhite from 'components/UI/Icons/Logo/logo_short__white.svg';
import logoShortColor from 'components/UI/Icons/Logo/logo_short__color.svg';
import logoShortestColor from 'components/UI/Icons/Logo/logo_shortest__color.svg';
import logoShortestWhite from 'components/UI/Icons/Logo/logo_shortest__white.svg';
// import logoLongColor from 'components/UI/Icons/Logo/logo_long__color.svg';
import style from './style.less';

export default class Logo extends React.Component {

	state = {
		width: window.innerWidth,
	};

	componentDidMount() {
		window.addEventListener('resize', this.onResizePage, true);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onResizePage, true);
	}

	onResizePage = () => this.setState({ width: window.innerWidth });

	render () {
		const { isCustomStyles } = this.props,
					{ width } = this.state;

		let renderClassName = style.logo,
			logo = logoShortColor; // для больших экранов цветной логотип

		if (width <= 992) logo = logoShortColor; // для планшетов
		if (width <= 575) logo = logoShortestColor; // для смартфонов

		if (isCustomStyles) {
			renderClassName += ' ' + style.logoCustom;
			logo = logoShortWhite; // для главной и стать перевозчиком
			if (width <= 575) logo = logoShortestWhite; // для смартфонов для главной и стать перевозчиком
		}

		return (
			<Link to={`/`} onClick={this.props.closeMenu} className={renderClassName}>
				<img src={logo} alt="logo" />
			</Link>
		);
	}
}
