import React from 'react';
import PropTypes from 'prop-types';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import Input from 'components/UI/Form/Fields/Input';
import Select from 'components/UI/Form/Fields/Select';
import Textarea from 'components/UI/Form/Fields/Textarea';
import Button from 'components/UI/Form/Button';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import Grid from 'components/UI/Wrappers/Grid';
import { connect } from 'react-redux';
import { compose } from 'redux';
import asyncValidate from 'libs/asyncValidate';
import style from 'components/ReduxForms/FeedbackForm/style.less';
import InputSelectGroup from 'components/UI/Wrappers/InputSelectGroup';
import ReCAPTCHA from 'react-google-recaptcha';
import NativeSelect from 'components/UI/_V2/Select';

const options = [
	{ value: 'clients_work', label: 'Отдел по работе с клиентами' },
	{ value: 'drivers_work', label: 'Отдел по работе с перевозчиками' },
	{ value: 'support', label: 'Отдел технической поддержки' },
	{ value: 'marketing', label: 'Отдел маркетинга' },
	{ value: 'cooperation', label: 'Сотрудничество' },
];

class FeedbackForm extends React.Component {

	static propTypes = {
		handleSubmit: PropTypes.func,
		error: PropTypes.string,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
	};

	static defaultProps = {
		handleSubmit: () => {},
		invalid: false,
		submitting: false,
		error: '',
	};

	isEnabledSubmit = () => {
		const { category, message, personalInfo, phone_number, email, recaptchaValue } = this.props.values;
		return category && message && personalInfo && phone_number && email && recaptchaValue;
	};

	onChange = token => {
		this.props.dispatch(change('feedback', 'recaptchaValue', token));
	};

	render () {

		const { handleSubmit, invalid } = this.props;

		const isEnabledSubmit = this.isEnabledSubmit();

		return (
			<form onSubmit={handleSubmit} autoComplete="off">
				<LayoutField className={style.width80}>
					<Field
						component={NativeSelect}
						name={'category'}
						label={`Выберите отдел`}
						options={options}
					/>
				</LayoutField>
				<LayoutField className={style.width80}>
					<Field
						name="message"
						component={Textarea}
						label="Напишите сообщение"
					/>
				</LayoutField>
				<LayoutField className={style.width80}>
					<span>Ваши данные</span>
					<Field
						name="personalInfo"
						component={Input}
						label="Имя Фамилия"
					/>
				</LayoutField>
				<Grid col="form-2-notfull">
					<LayoutField>
						<InputSelectGroup>
							<Field
								value={1}
								name="phone_number"
								type="text"
								component={Input}
								label="Телефон"
								mask="9999 99 99 99"
								containerGroup="left"
							/>
							<Field
								name="phone_code"
								component={Select}
								childGroup="left"
							>
								<OptionSelect value='7'>+7</OptionSelect>
								<OptionSelect value='375'>+375</OptionSelect>
								<OptionSelect value='98'>+98</OptionSelect>
							</Field>
						</InputSelectGroup>
					</LayoutField>
					<LayoutField>
						<Field
							name="email"
							component={Input}
							label="Email"
						/>
					</LayoutField>
				</Grid>

				<div style={{ marginBottom: '15px' }}>
					<ReCAPTCHA
						sitekey="6Lf5T7kUAAAAALGUCClRc6jZmwEUcGhW9OcZYuHm"
						onChange={this.onChange}
					/>
				</div>

				<Button
					disabled={invalid || !isEnabledSubmit}
					loading={this.props.submitting}
					size={'large'}
					type={'submit'}
				>
					Отправить
				</Button>
			</form>
		);
	}
}

const selector = formValueSelector('feedback');

export default compose(
	reduxForm({
		form: 'feedback',
	}),
	connect(
		state => ({
			values: selector(state, 'category', 'message', 'personalInfo', 'phone_number', 'email', 'recaptchaValue')
		})
	)
)(FeedbackForm);

/*
* Форма содержит:
1. Выберите отдел - select с одним из возможных значений:
    1.1. Отдел по работе с клиентами
    1.2. Отдел по работе с перевозчиками
    1.3. Отдел технической поддержки
    1.4. Отдел маркетинга
    1.5. Сотрудничество
2. Напишите сообщение - textarea
3. Ваши данные - input (string),
4. Телефон с маской
5. Email
*/
