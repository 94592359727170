import Button from 'components/UI/Form/Button';
import { ArrowLeft, ArrowRight } from 'components/UI/Icons';
import PropTypes from 'prop-types';
import React from 'react';
import style from './style.less';


class Pagination extends React.Component {

    static propTypes = {
        setPage: PropTypes.func.isRequired,
        setAjaxPage: PropTypes.func,
        pageCount: PropTypes.number.isRequired,
        activePage: PropTypes.number.isRequired,
        viewArrows: PropTypes.bool,
        className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        showMore: PropTypes.bool,
    };

    static defaultProps = {
        viewArrows: true,
        className: false,
        showMore: true,
        setAjaxPage: () => false
    };

    renderButton = currentPage => {
        const className = this.props.activePage === currentPage ? `${style.btn} ${style.btn_active}` : style.btn;
        return <Button className={className} key={currentPage} size="small" color="white"
                       onClick={() => this.props.setPage(currentPage)}>{currentPage}</Button>;
    };

    renderPageButtons = () => {
        const { activePage, pageCount } = this.props;
        let pageButtons = [];

        if (pageCount > 7) {
            for (let i = 0; i < pageCount; i++) {
                let j = i + 1;

                // если активная страница меньше четвертой
                if (activePage < 5) {
                    if (j < 6 || j === pageCount)
                        pageButtons.push(this.renderButton(j));
                    else if (j === 6)
                        pageButtons.push(<Button className={style.disabled} key={j} size="small" color="white" disabled>...</Button>);
                }

                // Промежуток от первой и до последней страницы с точками
                if (activePage > 4 && activePage < pageCount - 3) {
                    if (j === 1 || j === pageCount || (activePage - 2 < j && activePage + 2 > j))
                        pageButtons.push(this.renderButton(j));
                    else if (j === 2 || j === pageCount - 1)
                        pageButtons.push(<Button className={style.disabled} key={j} size="small" color="white" disabled>...</Button>);
                }

                // если активная страница доходит почти до конца или равна последней
                if (activePage > pageCount - 4) {
                    if (j > pageCount - 5 || j === 1)
                        pageButtons.push(this.renderButton(j));
                    else if (j === pageCount - 5)
                        pageButtons.push(<Button className={style.disabled} key={j} size="small" color="white" disabled>...</Button>);
                }

            }
        } else {
            for (let i = 0; i < pageCount; i++) {
                pageButtons.push(this.renderButton(i + 1));
            }
        }

        return pageButtons;
    };

    setPage = crement => {
        const { activePage, setPage, pageCount, setAjaxPage } = this.props;
        if (crement === 'minus')
            if (activePage - 1 !== 0) setPage(activePage - 1);
        if (crement === 'plus')
            if (activePage + 1 !== pageCount + 1) setPage(activePage + 1);
        if (crement === 'ajax')
            if (activePage + 1 !== pageCount + 1) setAjaxPage(activePage + 1);
    };

    render() {
        const { activePage, pageCount, viewArrows, className, showMore } = this.props;
        const renderClass = `${style.pagination} ${className ? className : ''} ${pageCount > 5 ? style.lotPages : ''}`;

        if (pageCount === 0) return null;

        return (
            <div className={renderClass}>
                {
                    (showMore && activePage < pageCount) &&
                    <div className={style.page_more}>
                        <Button onClick={() => this.setPage('ajax')}>Показать еще</Button>
                    </div>
                }
                <div className={style.page_list}>
                    {this.renderPageButtons()}
                </div>
                {
                    viewArrows &&
                    <div className={style.page_arrows}>
                        <Button
                            disabled={activePage === 1}
                            className={style.prevBtn}
                            onClick={() => this.setPage('minus')}
                            color="white"
                        >
                            {ArrowLeft(style.prev)}Назад
                        </Button>
                        <Button
                            disabled={activePage === pageCount}
                            className={style.nextBtn}
                            onClick={() => this.setPage('plus')}
                            color="white"
                        >
                            Вперед{ArrowRight(style.next)}
                        </Button>
                    </div>
                }
            </div>
        )
    }
}


export default Pagination;
