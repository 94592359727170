import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import style from './style.less';
import Aside from 'components/UI/Wrappers/Aside';
import AsideYourBid from 'components/UI/AsideItems/AsideYourBid';
import AsideItem from 'components/UI/AsideItems/AsideItem';
import AsideOfferStatus from 'components/UI/AsideItems/AsideOfferStatus';
import Badge from 'components/UI/Badge';
import { setItem } from 'libs/sessionStorage';
import forwardTo from 'libs/forwardTo';
import mapperFromRouteToSearch from 'mappers/mapperFromRouteToSearch';

class RouteOptions extends React.PureComponent {

	static propTypes = {
		route: PropTypes.object.isRequired,
		dispatch: PropTypes.func.isRequired,
		actionEdit: PropTypes.func.isRequired,
		actionDelete: PropTypes.func.isRequired,
		actionExtend: PropTypes.func.isRequired,
		role: PropTypes.string,
	};
	
	goToSearch = () => {
		setItem('searchParams', JSON.stringify(mapperFromRouteToSearch(this.props.route)));
		forwardTo('/search/');
	};

	render() {
		let { route, actionEdit, actionDelete, actionFinish, actionExtend } = this.props;
		return (
			<Aside marginBottom={30}>

				<AsideYourBid
					price={parseInt(route.price)}
					isRoute
					text={this.props.role === 'customer' ? 'Ставка' : 'Ваша ставка'}
				/>

				{
					this.props.role === 'driver' &&
					<Fragment>
						<AsideItem url={`/route/${route.id}#proposals`}>
							Предложений
							<span className={style.justText}>
						{ route.proposalsTotalCount === null ? 0 : route.proposalsTotalCount }{' '}
								{
									route.proposalsNewCount !== null &&
									<Badge>+{ route.proposalsNewCount }</Badge>
								}
					</span>
						</AsideItem>

						<AsideItem onClick={this.goToSearch}>
							Найдено клиентов<span className={style.justText}>{ route.matches }</span>
						</AsideItem>

						{
							route.status !== 'unpublished' &&
							<AsideItem onClick={actionEdit}>
								Редактировать маршрут
							</AsideItem>
						}

						{
							route.status === 'published' &&
							<AsideItem onClick={actionFinish}>
								Завершить маршрут
							</AsideItem>
						}

						{
							route.status === 'unpublished' && route.type === 'desired' &&
							<AsideItem onClick={actionExtend}>
								Продлить маршрут
							</AsideItem>
						}

						<AsideItem onClick={actionDelete} redLnk>
							Удалить маршрут
						</AsideItem>
					</Fragment>
				}

				<AsideOfferStatus
					isRoute
					status={route.status || 'active'}
				/>
			</Aside>
		);
	}
}

export default RouteOptions;