import React from 'react';
import PropTypes from 'prop-types';
import Page from 'components/UI/Wrappers/Page';
import Grid from 'components/UI/Wrappers/Grid';
import HeaderPageDescription from 'components/UI/HeaderPageDescription';
import getWordEnd from 'libs/getWordEnd';
import { Tabs, TabList, Tab } from 'react-tabs';
import Questions from 'components/UI/Questions';
import tabStyle from 'components/UI/CustomTab/style.less';
import style from './requestlayer.less';

const RequestLayer = props => {
	const { title, children, questions, tabList, requestType, selectedTab, onClickTab } = props,
		createdView = `Всего ${getWordEnd(requestType.length, ['создана','создано','создано'])} ${requestType.length} ${getWordEnd(requestType.length, requestType.word)}`;
	return (
		<Page title={title}>
			<Grid col="10-2" className={style.grid_marginInverse}>
				<div>
					<Tabs>
						<HeaderPageDescription className={style.headerPageDescription}>
							<div className={style.descriptionLeft}>
								{ createdView }
							</div>
							<TabList className={tabStyle.tab_list}>
								{
									tabList.map((el, i) => (
										<Tab
											key={i}
											className={`${tabStyle.tab_item} ${i === selectedTab ? tabStyle.active : ''}`}
											onClick={() => onClickTab(i)}
										>
											{el.title} <span className={style.gray}>{el.count}</span>
										</Tab>
									))
								}
							</TabList>
						</HeaderPageDescription>
						{ children }
					</Tabs>
				</div>
				<div>
					<HeaderPageDescription className={`${style.headerPageDescription} ${style.headerPageDescription2}`}>
						<div className={style.descriptionLeft2}>Популярные вопросы</div>
					</HeaderPageDescription>
					<div className={style.quest_list}>
						<Questions list={questions} />
					</div>
				</div>
			</Grid>
		</Page>
	)
};

RequestLayer.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node,
};
RequestLayer.defaultProps = {
	children: '',
};

export default RequestLayer;
