import { chatActions } from 'actions/chat';
import CenterDialog from "components/Chat/center/index";
import { filter } from 'components/Chat/filter';
import ChatFilter from 'components/Chat/left/ChatFilter';
import ChatNotFound from 'components/Chat/left/ChatNotFound';
import Companion from 'components/Chat/left/Companion';
import UserCounter from 'components/Chat/left/UserCounter';
import style from 'components/Chat/style.less';
import Dialog from 'components/UI/Dialog';
import Button from 'components/UI/Form/Button';
import { Close } from 'components/UI/Icons';
import { getChatDialogs, getIdSelectedDialog, getProfile } from 'libs/selections';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CustomScroll from 'react-custom-scroll';
import { connect } from 'react-redux';


class DialogChat extends Component {

    static propTypes = {
        handleClose: PropTypes.func.isRequired,
    };

    static defaultProps = {
        dialogs: { items: [], total: 0 },
        history: [],
    };

    state = {
        dialog: undefined,
        loading: false,
        value: '',
    };

    closeDialog = () => {
        this.setState({ dialog: undefined });
    };

    selectDialog = dialog => {
        this.setState({ dialog, loading: true });
        return new Promise((resolve, reject) => {
            this.props.dispatch(chatActions.getHistoryByID({
                dialogId: dialog.id, page: 1
            }, { resolve, reject }));
        })
            .then(() => this.setState({ loading: false }))
            .catch(err => {
                console.log(err);
            });

    };

    handleFilter = event => this.setState({ value: event.target.value });

    async componentWillMount() {
        if (this.props.idSelectedDialog)
            await this.selectDialog(this.props.dialogs.items.filter(el => el.id === this.props.idSelectedDialog)[0]);
    }

    async componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.idSelectedDialog && this.props.idSelectedDialog !== nextProps.idSelectedDialog)
            await this.selectDialog(nextProps.dialogs.items.filter(el => el.id === nextProps.idSelectedDialog)[0]);
    }

    render() {
        let { dialogs, profile } = this.props,
            { dialog, value } = this.state,
            filterDialogs = dialogs.items;


        if (value !== '') // todo удалить после доработки с бекенда, нужно переписать и делать запрос на сервер
            filterDialogs = filter(value, dialogs.items, profile.id);

        return (
            <Dialog
                handleClose={this.props.handleClose}
                customDialogClass={style.mobileChat}
                className={style.chat}
            >
                <div className={style.left__container}>

                    <Button
                        className={style.mobileChatClose}
                        onClick={this.props.handleClose}
                    >
                        {Close()}
                        Закрыть чат
                    </Button>

                    <ChatFilter
                        value={value}
                        handleFilter={this.handleFilter}
                    />

                    <UserCounter
                        role={profile.role}
                        length={filterDialogs.length}
                        isFilter={value !== ''}
                    />

                    <div className={style.chat__list}>
                        {
                            filterDialogs.length > 0 &&
                            <CustomScroll heightRelativeToParent="100%">
                                {
                                    filterDialogs.map(el => (
                                        <Companion
                                            dialog={el}
                                            key={el.id}
                                            profileID={profile.id}
                                            selected={this.state.dialog}
                                            selectDialog={e => this.selectDialog(e)}
                                        />
                                    ))
                                }
                            </CustomScroll>
                        }

                        <ChatNotFound
                            filterLength={filterDialogs.length}
                            dialogLength={dialogs.total}
                            role={profile.role}
                            onClick={this.props.handleClose}
                        />

                    </div>

                </div>

                <CenterDialog
                    dialog={dialog}
                    handleClose={this.props.handleClose}
                />

            </Dialog>
        );
    }
}


export default connect(
    state => ({
        dialogs: getChatDialogs(state),
        idSelectedDialog: getIdSelectedDialog(state),
        profile: getProfile(state)
    })
)(DialogChat);