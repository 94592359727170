import React from 'react';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import {Field, reduxForm, stopSubmit} from 'redux-form';
import Language from 'language';
import Button from 'components/UI/Form/Button';
import PropTypes from 'prop-types';
import Select from 'components/UI/Form/Fields/Select';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import Textarea from 'components/UI/Form/Fields/Textarea';
import ImageUpload from 'components/UI/Form/Fields/ImageUpload';
import style from './style.less';

let ArbitrationForm = props => {
	let { handleSubmit, invalid } = props;

	return (
		<form onSubmit={handleSubmit} autoComplete="off">
			<LayoutField>
				<Field
					name="reason"
					component={Select}
					label="Причина отправки в арбитраж"
				>
					<OptionSelect value="pay_problem">{Language.pay_problem}</OptionSelect>
					<OptionSelect value="deadline_problem">{Language.deadline_problem}</OptionSelect>
					<OptionSelect value="lost">{Language.lost}</OptionSelect>
					<OptionSelect value="other">{Language.other}</OptionSelect>
				</Field>
			</LayoutField>
			<LayoutField>
				<Field
					name="comment"
					component={Textarea}
					label="Опишите вашу проблему"
				/>
			</LayoutField>
			<div className={style.flexFilesButton}>
				<LayoutField className={style.uploadImageProfile}>
					<Field
						type='arbitration'
						name="fileIds"
						component={ImageUpload}
						multiload
						justFile
					/>
				</LayoutField>
				<Button
					disabled={invalid}
					size={'large'}
					type={'submit'}
				>
					Отправить
				</Button>
			</div>
		</form>
	);
};

ArbitrationForm.propTypes = {
	handleSubmit: PropTypes.func,
	error: PropTypes.string,
	submitting: PropTypes.bool,
	invalid: PropTypes.bool,
};
ArbitrationForm.defaultProps = {
	handleSubmit: () => {},
	invalid: false,
	submitting: false,
	error: '',
};

ArbitrationForm = reduxForm({
	form: 'ArbitrationForm',
	onChange: (newValues, dispatch) => {
		dispatch(stopSubmit('ArbitrationForm', {}));
	}
})(ArbitrationForm);

export default ArbitrationForm;