import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { dateToString, serverDateToClientDate, dateObjToString } from 'libs/convertDate';
import { LabelError } from 'components/UI/Form/Labels';
import { DateDots } from 'components/UI/Icons';
import Button from 'components/UI/Form/Button';
import style from './style.less';

export default class InputDate extends React.PureComponent {
	state = {
		openCalendar: false,
		focus: false,
		date:
			this.props.input.value ?
				(typeof(this.props.input.value) === 'string' ?
					new Date(serverDateToClientDate(this.props.input.value))
					: this.props.input.value)
				: new Date(),
		inputValue: this.props.input.value ? (typeof(this.props.input.value) === 'string' ?
			new Date(serverDateToClientDate(this.props.input.value))
			: dateObjToString(this.props.input.value))
			: new Date()
	};

	inputRef = undefined;
	dateRef = undefined;
	containerRef = undefined;

	static propTypes = {
		input: PropTypes.object,
		meta: PropTypes.object,
		label: PropTypes.string,
		classNameContainer: PropTypes.string,
		classNameInput: PropTypes.string,
		showMonthDropdown: PropTypes.bool,
		showYearDropdown: PropTypes.bool
	};

	static defaultProps = {
		input: {},
		meta: {},
		label: '',
		classNameContainer: '',
		classNameInput: '',
		showMonthDropdown: false,
		showYearDropdown: false
	};

	componentDidMount() {
		document.addEventListener('click', this.handleClick, false);
		this.inputRef.addEventListener('focus', this.handleFocus, false);
		this.inputRef.addEventListener('blur', this.handleBlur, false);
		this.inputRef.addEventListener('click', this.handleClickForDatePicker, false);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClick, false);
		this.inputRef.removeEventListener('focus', this.handleFocus, false);
		this.inputRef.removeEventListener('blur', this.handleBlur, false);
		this.inputRef.addEventListener('click', this.handleClickForDatePicker, false);
	}

	handleClick = event => {
		// if (this.dateRef && !this.dateRef.contains(event.target)) {
		// 	console.log('testsdf');
		// 	this.setState({ openCalendar: false });
		// }

		if (this.containerRef && !this.containerRef.contains(event.target)) {
			this.setState({ openCalendar: false });
		}
	};

	handleClickForDatePicker = () => {
		this.setState({ openCalendar: true });
	};

	handleFocus = () => this.setState({ focus: true });

	handleBlur = () => this.setState({ focus: false });

	handleInput = event => this.setState({inputValue: event.target.value});

	handleToggleCalendar = () => {
		this.setState({openCalendar: !this.state.openCalendar})
	};

	handleDate = date => {
		this.setState({
			inputValue: dateToString(date),
			openCalendar: false,
			date,
		});
		return this.props.input.onChange(date);
	};

	handleChange = event => {
		let {value} =  event.target,
			patch = value.split('.'),
			date = new Date();

		if(patch.length === 3) {
			if (patch[2].length === 4) {
				date.setUTCDate(parseInt(patch[0]));
				date.setMonth(parseInt(patch[1]) - 1);
				date.setFullYear(parseInt(patch[2]));
				this.setState({ date });
				this.handleDate(date);
			}
		}
		this.setState({inputValue: event.target.value})
	};

	componentWillReceiveProps(nextProps) {
		if(!nextProps.input.value) this.setState({inputValue: '', date: new Date()});
	}

	render() {
		const { label, meta, classNameContainer, classNameInput } = this.props;
		const { focus, inputValue } = this.state;

		const container = `
			${style.field__flex_container} 
			${classNameContainer} 
			${focus ? style.focus : ''}
			${inputValue ? style.not_empty : ''}
			${meta.error ? style.error : ''}
		`;
		const container_input = `${style.field__input} ${classNameInput}`;
		const errorCheck = meta.error !== null && meta.error !== undefined;
		return (
			<React.Fragment>
				<div className={container} ref={ref => this.containerRef = ref}>
					<div className={container_input}>
						<InputMask
							inputRef={ref => this.inputRef = ref}
							onInput={this.handleInput}
							onChange={this.handleChange}
							type="text"
							value={inputValue}
							mask="99.99.9999"
						/>
						<label>{ label }</label>
					</div>
					{meta.error && <div className={`${style.show_state} ${style.rIcon} ${style.show_error}`}>!</div>}
					<div className={style.container_date} ref={ref => this.dateRef = ref}>
						<Button className={style.date_dots} size="small" onClick={this.handleToggleCalendar}>{ DateDots() }</Button>
						{
							<div className={`${style.date_picker} ${this.state.openCalendar && style.date_picker__open}`}>
								<DatePicker
									locale='ru'
									// selected={this.state.date}
									onChange={this.handleDate}
									dropdownMode="select"
									dateFormat="dd/MM/yyyy"
									showMonthDropdown={this.props.showMonthDropdown}
									showYearDropdown={this.props.showYearDropdown}
									minDate={new Date()}
									inline
								/>
							</div>
						}
					</div>
				</div>
				{meta.error && <LabelError message={meta.error} isOn={errorCheck} />}
			</React.Fragment>
		);
	}
}
