import React from 'react';
import Questions from 'components/UI/Questions';
import Button from 'components/UI/Form/Button';
import { Link } from 'react-router-dom';
import src from 'components/HelpfulInformations/assets/phone-icon.svg';
import style from '../style.less';

class PopularQuestions extends React.Component {
	state = {
		list: [
			{question: 'Как создать заявку на грузоперевозку?', answer: ['Пользователь, зарегистрированный в статусе грузоотправителя, может создавать заявки на отправку грузов. Для этого, в своем профиле следует нажать кнопку «+Добавить груз». Система переадресует вас в форму создания заявки на грузоперевозку. Эта форма требует заполнения полей: адрес, дата, время отправки, контакты отправителя, а также аналогичной информации о разгрузке. Сервис допускает заполнение до пяти адресов разгрузки. После уточнения деталей отправки и разгрузки необходимо заполнить информацию о грузе: тип груза, его название, вес, габариты, тип упаковки и, если понадобиться, дополнительные условия. В процессе заполнения заявки также необходимо указать стоимость перевозки, отметить страхование груза и согласие с Правилами сайта. Обратите внимание, что адрес получателя и отправителя необходимо выбирать, используя поиск по буквам. После заполнения всех полей, нажмите кнопку «Разместить заявку» и уточните детали. После этого ваша заявка будет доступна всем пользователям Сервиса.'], open: false},
			{
				question: 'А если меня обманут и не привезут груз?',
				answer: [
					'Сервис «HurryLorry» имеет многоуровневую защиту пользователей от обмана и невыполнения обязательств. Она включает:',
					[
						'принцип «Сделка без риска» – позволяет не платить, в случае отсутствия доставки;',
						'страхование груза – вы получите компенсацию за «пропавший» груз;',
						'систему репутации грузоперевозчика – вы можете ориентироваться на отзывы о грузоперевозчике от других заказчиков и выбирать только проверенных исполнителей;',
						'верификацию исполнителей – мы проверяем реальность контактных и паспортных данных исполнителей.',
					],
					''
				],
				open: false
			},
			{question: 'Что такое сделка без риска?', answer: ['«Сделка без риска» – это ключевой принцип работы Сервиса «HurryLorry». Он гарантирует, с одной стороны, оплату за каждый выполненный заказ, а с другой, – страхует заказчика от оплаты за невыполненную доставку. При заключении сделки, оговоренная сумма заказа списывается с банковской карты заказчика, резервируется на специальном счете Сервиса и сохраняется там до уведомления о выполнении доставки. После подтверждения исполнения заказа обеими сторонами, деньги перечисляются на банковскую карту грузоперевозчика. В случае невыполнения задания или отказа от него, зарезервированная сумма возвращается на карту заказчика.'], open: false},
			{question: 'Какие гаранатии что перевозчик доставит груз вовремя?', answer: ['Работа Сервиса построена на принципе «Сделка без риска», что позволяет не платить за неисполнение, некачественное или несвоевременное исполнение заказа. Кроме этого, каждый грузоперевозчик заботиться о своей репутации и не рискует получить негативный отзыв, который существенно осложнит его дальнейшую работу на Сервисе. Дополнительную защиту заказчиков формирует страхование операции доставки на сервисе.'], open: false},
			{question: 'Кто платит комиссию?', answer: ['Организация доставки груза через «HurryLorry» является посреднической услугой. Сервис помогает грузоотправителям и грузоперевозчикам найти друг друга. При этом, комиссию за пользование услугами «HurryLorry» оплачивает грузоперевозчик. Она составляет 3 % от стоимости перевозки, и взимается с общей суммы сделки.'], open: false},
			{question: 'Где можно получить полную информацию о работе сервиса', answer: ['Наиболее полную информацию о пользовании Сервисом и его функционале можно получить из Правил сайта, доступных по ссылке http://hurrylorry.com/agreement_hl.pdf, а также из раздела, где собраны ответы на часто задаваемые вопросы http://hurrylorry.com/questions. Чтобы получить ответ на вопрос, недоступный за приведенными ссылками, обратитесь в службу поддержки Сервиса support@hurrylorry.com или на страницу техподдержки «Помощь 24/7».'], open: false},
		]
	};

	render() {
		return (
			<div className={style.question__screen}>
				<div>
					<h3 className={style.section_title}>Популярные вопросы</h3>
					<Questions viewType={2} list={this.state.list} />
					<Link to={'/questions'} className={style.link_allQuestion}>Все вопросы</Link>
				</div>
				<div className={style.support}>
					<div className={style.icon}><img src={src} alt=""/></div>
					<div>
						<h4>Служба поддержки клиентов</h4>
						<p>Вы можете обратиться за помощью через приложение
							или с помощью формы.</p>
						<Link to={'/feedback'}>
							<Button>Задать вопрос</Button>
						</Link>
					</div>
				</div>
			</div>
		)
	}
}

export default PopularQuestions;
