exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.wyzIofpmSAA89qM30Vf6u {\n  position: relative;\n}\n.wyzIofpmSAA89qM30Vf6u h3 {\n  font-size: 24px;\n  margin-bottom: 20px;\n}\n.O9lcMAzhETCM1YOal3AEG {\n  margin-top: 50px;\n}\n.O9lcMAzhETCM1YOal3AEG h3 {\n  font-size: 24px;\n  margin-bottom: 20px;\n}\n.O9lcMAzhETCM1YOal3AEG p {\n  color: #848484;\n  font-size: 15px;\n}\n.R29mexjutG85FXw2lasIW {\n  margin-top: 50px;\n}\n.R29mexjutG85FXw2lasIW h3 {\n  font-size: 24px;\n  margin-bottom: 20px;\n}\n.R29mexjutG85FXw2lasIW p {\n  color: #848484;\n  font-size: 15px;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/Profile/ProfileView/style.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,mBAAmB;CACpB;AACD;EACE,gBAAgB;EAChB,oBAAoB;CACrB;AACD;EACE,iBAAiB;CAClB;AACD;EACE,gBAAgB;EAChB,oBAAoB;CACrB;AACD;EACE,eAAe;EACf,gBAAgB;CACjB;AACD;EACE,iBAAiB;CAClB;AACD;EACE,gBAAgB;EAChB,oBAAoB;CACrB;AACD;EACE,eAAe;EACf,gBAAgB;CACjB","file":"style.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.profile {\n  position: relative;\n}\n.profile h3 {\n  font-size: 24px;\n  margin-bottom: 20px;\n}\n.profileRoutes {\n  margin-top: 50px;\n}\n.profileRoutes h3 {\n  font-size: 24px;\n  margin-bottom: 20px;\n}\n.profileRoutes p {\n  color: #848484;\n  font-size: 15px;\n}\n.profileReviews {\n  margin-top: 50px;\n}\n.profileReviews h3 {\n  font-size: 24px;\n  margin-bottom: 20px;\n}\n.profileReviews p {\n  color: #848484;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"profile": "wyzIofpmSAA89qM30Vf6u",
	"profileRoutes": "O9lcMAzhETCM1YOal3AEG",
	"profileReviews": "R29mexjutG85FXw2lasIW"
};