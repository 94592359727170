import React from 'react';
import styles from '../style.less';
import { Link } from "react-router-dom";

const navigationInform = [
  {
    link: '/feedback/',
    title: 'Помощь 24/7'
  },
  {
    link: '/riskfree/',
    title: 'Сделка без риска'
  },
  {
    link: '/questions/',
    title: 'Частые вопросы'
  }
];

const navigationCarrier = [
  {
    link: '/verification/',
    title: 'Верификация'
  },
  {
    link: '/mobile/',
    title: 'Мобильное приложение'
  },
  {
    link: '/tariffs/',
    title: 'Тарифы'
  }
];

const navigationClient = [
  {
    link: '/how_start_work/',
    title: 'Как начать работу  '
  },
  {
    link: '/insurance/',
    title: 'Страхование грузов'
  }
];

const Navigation = props => {

  const { isActive } = props;

  let navigation = props.isCarrier ? navigationCarrier : props.isClient ? navigationClient : navigationInform;
  let title = props.isCarrier ? 'ПЕРЕВОЗЧИКАМ' : props.isClient ? 'КЛИЕНТАМ' : 'ПОЛЕЗНАЯ ИНФОРМАЦИЯ';

    return (
      <div className={styles.navigation}>
        <div className={styles.container}>
          <div className={styles.navigation__title}>
            {title}
          </div>
          <div className={styles.navigation__menu}>
            {
              navigation.map((el, index) => {
                return (
                  <Link to={el.link} style={isActive === index ? { color: '#ff483b' } : {}}>
                    { el.title }
                  </Link>
                )
              })
            }
          </div>
        </div>
      </div>
    )
};

export default Navigation
