import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from 'components/Chat/style.less';
import messageStyle from 'components/Chat/center/message.less';
import InfiniteScroll from 'components/Chat/InfiniteScroll';
import MyMessage from 'components/Chat/center/MyMessage';
import CompanionMessage from 'components/Chat/center/CompanionMessage';
import { isDate, isTime, isUser } from 'components/Chat/center/compareMessages';
import { getMessageDateString } from 'libs/getMessageTime';


class MessageList extends Component {
	
	static propTypes = {
		list: PropTypes.object,
		profile: PropTypes.object,
	};
	
	static defaultProps = {
		list: { items: [], total: 0 },
		profile: {},
	};
	
	render() {
		let { list, profile, dialog } = this.props;

		list.items = [ ...list.items ].reverse();
		
		let users = {
			[dialog.user1.id]: dialog.user1,
			[dialog.user2.id]: dialog.user2,
		};
		
		if (dialog.admins) {
			dialog.admins.forEach(el => {
				users[el.id] = el;
			});
		}
		
		return (
			<div className={style.message__flex_container}>
				
				{
					!this.props.loading && list.total === 0 &&
					<div className={`${style.loader} ${style.show}`}>История сообщений отсутствует</div>
				}
				
				{
					list.total > 0 && list.items.length > 0 &&
					<InfiniteScroll
						dialog={dialog}
						total={list.total}
						current={list.items.length}
						loadMore={this.props.loadMore}
						loading={this.props.loading}
					>
						{
							list.items.map((el, i) => {
								const isLastMessage = list.items[i + 1] === undefined;
								
								let renderClassName = isLastMessage ? style.lastMessage : '';
								
								return (
									<div
										key={el.id}
										className={renderClassName}
									>
										{
											isDate(el, list.items[i - 1]) &&
											<div className={messageStyle.date}>
												{ getMessageDateString(el.createTime) }
											</div>
										}
										{
											el.senderId === profile.id ?
												<MyMessage
													messageData={el}
													handleClose={this.props.handleClose}
													isTime={isTime(el, list.items[i - 1])}
												/>
												:
												<CompanionMessage
													messageData={el}
													user={users[el.senderId]}
													dialogID={dialog.id}
													handleClose={this.props.handleClose}
													isUser={isUser(el, list.items[i - 1])}
													isTime={isTime(el, list.items[i - 1])}
												/>
										}
									</div>
									
								)
							})
						}
					</InfiniteScroll>
				}
				
			</div>
		)
	}
}


export default MessageList;