import React from 'react';
import style from 'components/Admin/UI/Header/style.less';
import Container from 'components/UI/Wrappers/Container';
import AdminHeaderProfile from 'components/Admin/UI/Profile';
import AdminLogo from 'components/Admin/UI/Header/Logo';


const AdminHeader = () => (
	<header className={style.header}>
		<Container className={style.container}>
			<AdminLogo />
			<AdminHeaderProfile />
		</Container>
	</header>
);


export default AdminHeader;