import React from 'react';
import Dialog from 'components/UI/Dialog';
import Button from 'components/UI/Form/Button';
import dialogStyles from 'components/UI/Dialog/style.less';

export const CancelTrade = ({ agreeAction, cancelAction }) => (
	<Dialog handleClose={cancelAction}>
		<h3 className={dialogStyles.textcenter}>
			Вы действительно хотите отменить сделку?
		</h3>
		<div className={dialogStyles.actions}>
			<Button
				color={'red'}
				onClick={agreeAction}>
				Да
			</Button>
			<Button
				color={'white'}
				onClick={cancelAction}>
				Нет
			</Button>
		</div>
	</Dialog>
);