import React, { Fragment } from 'react';
import style from 'components/UI/_V2/Proposals/List/style.less';
import language from 'language';
import Warning from 'components/UI/Warning';
import Button from 'components/UI/Form/Button';


const ProposalListSendButton = ({ showSendButton, openProposalDialog }) => (
	<Fragment>
		{
			showSendButton === 2 &&
			<Warning type={'warning'}>
				<p>{ language.notVerifyAccount_offerPage.one }</p>
				<p>{ language.notVerifyAccount_offerPage.two }</p>
			</Warning>
		}
		
		{
			showSendButton === 1 &&
			<Button
				className={style.btn}
				onClick={openProposalDialog}
			>
				Отправить предложение о перевозке
			</Button>
		}
	</Fragment>
);


export default ProposalListSendButton;