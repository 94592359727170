import handleActions from 'redux-actions/es/handleActions';
import {removeAll, rmPointByKey, setDistance, setPoint, setPoints, removeBetweenPoint, setPointsToABC} from 'actions/map';

const getMap = (initStore) => handleActions({
	[setPoint]: (state, action) => {
		let newPoint = true,
			newState = state.points.map(point => {
				if (point === undefined || point.key === action.payload.key) {
					newPoint = false;
					return {...point, ...action.payload};
				}
				return point;
			});
		if (newPoint) {
			newState = [...newState, action.payload]
		}

		return { ...state, points: newState };
	},
	[setPointsToABC]: (state, action) => {
		let newPoints = state.points.filter(it => it.key === 'A' || it.key === 'B');

		newPoints = [...newPoints, ...action.payload];
		return { ...state, points: newPoints.sort((a, b) => {
			if(b.key === 'A' || a.key === 'A') return -1;
			if(a.key === 'B' || b.key === 'B') return 1;
			if(a.key < b.key) return -1;
			if(a.key > b.key) return 1;
			return 0;
		})};
	},
	[removeBetweenPoint] : state => ({
		...state,
		points: state.points.filter(it => 'A' === it.key || 'B' === it.key),
	}),
	[setPoints]: (state, action) => ({...state, points: action.payload}),
	[setDistance]: (state, action) => ({
		...state,
		distance: action.payload
	}),
	[rmPointByKey]: (state, action) => ({
		...state,
		points: state.points.filter(it => action.payload !== it.key),
	}),
	[removeAll] : () => initStore,
}, initStore);

export default getMap;
