import React from 'react';
import Panel from 'components/UI/Wrappers/Panel';
import Grid from 'components/UI/Wrappers/Grid';
import { LabelInfo } from 'components/UI/Form/Labels';
import style from './style.less';

const InfoTransport = ({ bodyTypeId, loadTypeId }) => (
	<Panel title="Информация о транспорте" marginBottom={60}>
		<Grid col="2" className={style.gridPadding}>
			<div>
				<LabelInfo title="Тип кузова">{ bodyTypeId.titleRu }</LabelInfo>
			</div>
			<div>
				<LabelInfo title="Тип погрузки">{ loadTypeId.titleRu }</LabelInfo>
			</div>
		</Grid>
	</Panel>
);

export default InfoTransport;
