exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n._1Li8mgGnzYjjSUtqT0io9_ {\n  margin-top: 40px;\n}\n@media screen and (max-width: 1200px) {\n  ._3VFbHtdQh62ON2t6erCCPO {\n    margin-top: -45px;\n    margin-bottom: -45px;\n  }\n}\n.uxAIbdEE39vNoobamjxEO {\n  color: #848484;\n  margin-bottom: 8px;\n  display: inline-block;\n}\n@media screen and (max-width: 575px) {\n  .uxAIbdEE39vNoobamjxEO {\n    font-size: 15px!important;\n  }\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/ReduxForms/DriverContactDataForm/style.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,iBAAiB;CAClB;AACD;EACE;IACE,kBAAkB;IAClB,qBAAqB;GACtB;CACF;AACD;EACE,eAAe;EACf,mBAAmB;EACnB,sBAAsB;CACvB;AACD;EACE;IACE,0BAA0B;GAC3B;CACF","file":"style.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.button {\n  margin-top: 40px;\n}\n@media screen and (max-width: 1200px) {\n  .mobileLoadPhoto {\n    margin-top: -45px;\n    margin-bottom: -45px;\n  }\n}\n.spanFix {\n  color: #848484;\n  margin-bottom: 8px;\n  display: inline-block;\n}\n@media screen and (max-width: 575px) {\n  .spanFix {\n    font-size: 15px!important;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"button": "_1Li8mgGnzYjjSUtqT0io9_",
	"mobileLoadPhoto": "_3VFbHtdQh62ON2t6erCCPO",
	"spanFix": "uxAIbdEE39vNoobamjxEO"
};