import React, { PureComponent } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { LabelError } from 'components/UI/Form/Labels';
import styles from 'components/UI/_V2/Select/styles.less';
import { ArrowDownChevron, Tick } from 'components/UI/Icons';

const DropdownIndicator = (
	props: ElementConfig<typeof components.DropdownIndicator>,
) => {
	return (
		<components.DropdownIndicator {...props}>
			{ ArrowDownChevron(styles.icon_arrow) }
		</components.DropdownIndicator>
	);
};

const Option = (
	props: ElementConfig<typeof components.Option>
) => {
	return (
		<components.Option {...props}>
			<div className={styles.option}>
				<span>{ props.data.label }</span> { props.isSelected && Tick(styles.icon_success) }
			</div>
		</components.Option>
	);
};

const Control = (
	props: ElementConfig<typeof components.Control>
) => {
	let labelClassName = styles.label;
	if (props.hasValue) labelClassName += ' ' + styles.label_filled;

	if (props.selectProps.isSearchable && props.isFocused) labelClassName += ' ' + styles.label_focus_searchable;

	return (
		<components.Control {...props}>
			<div className={labelClassName}>
				{ props.selectProps.label }
			</div>
			{ props.children }
			<div className={[styles.dropdownIndicator]} style={{transform: props.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)' }}>
				{ ArrowDownChevron(styles.icon_arrow) }
			</div>
		</components.Control>
	);
};


class NativeSelect extends PureComponent {

	static propTypes = {
		options: PropTypes.array,
		meta: PropTypes.object,
		input: PropTypes.object,
		label: PropTypes.string,
		isSearchable: PropTypes.bool,
	};

	static defaultProps = {
		options: [],
		meta: {},
		input: {
			value: {}
		},
		label: 'Выберите значение...',
		isSearchable: false,
	};

	selectRef = React.createRef();

	state = {
		fontSize: '16px',
	};

	handleChange = selectedOption => {
		this.props.input.onChange(selectedOption);
		this.setState({ filled: true });
		this.selectRef.current.select.blur();
	};

	componentDidMount() {
		this.updateFontSize();
		window.addEventListener('resize', this.updateFontSize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateFontSize);
	}

	updateFontSize = () => {
		if (window.innerWidth <= 420) {
			this.setState({ fontSize: '14px' })
		} else {
			this.setState({ fontSize: '16px' })
		}
	};

	render () {

		const { options, meta, input, isSearchable } = this.props;

		const { fontSize } = this.state;

		const customStyles = {
			container: (provided, state) => ({
				...provided,
				// position: 'relative',
				zIndex: state.isFocused ? 5 : 0,
			}),
			control: (provided, state) => {
				return {
					...provided,
					border: state.selectProps.isError ? '1px solid #e9483f' : state.isFocused ? '1px solid #afcbf6' : '1px solid #d5deef',
					height: '64px',
					cursor: 'pointer',
					boxShadow: 'none',
					'&:hover': {
						borderColor: state.selectProps.isError ? '#e9483f' : '#afcbf6'
					},
				}
			},
			option: (provided, state) => ({
				...provided,
				padding: 0,
				cursor: 'pointer',
				background: state.isSelected ? '#f8fbff' : state.isFocused ? '#eef3fd' : '#fff',
				color: '#353c4b'
			}),
			dropdownIndicator: (provided, state) => {
				return {
					...provided,
					paddingLeft: '20px',
					paddingRight: '20px',
					transition: 'all .3s',
					display: 'none',
				}
			},
			indicatorSeparator: (provided, state) => ({
				...provided,
				display: 'none'
			}),
			valueContainer: (provided, state) => ({
				...provided,
				paddingLeft: '25px',
				marginTop: '18px',
				width: '100%',
				fontSize
			}),
			menu: (provided, state) => {
				return {
					...provided,
					boxShadow: 'none',
					border: '1px solid #d5deef',
					marginTop: '-2px',
					borderRadius: '0 0 4px 4px',
				}
			},
			singleValue: (provided, state) => {
				return {
					...provided,
					marginLeft: 0,
					marginRight: 0,
					fontSize
				}
			},
			input: (provided, state) => ({
				...provided,
				marginLeft: 0,
				marginRight: 0,
				fontSize
			})
		};

		return (
			<div>
				<Select
					ref={this.selectRef}
					placeholder={''}
					value={input.value}
					components={{ DropdownIndicator, Option, Control }}
					onChange={this.handleChange}
					options={options}
					isSearchable={isSearchable}
					label={this.props.label}
					isError={meta.error}
					noOptionsMessage={() => 'Ничего не найдено'}
					styles={customStyles}
				/>
				{ meta.error && <LabelError message={meta.error} isOn={meta.error} /> }
			</div>
		);
	}
}

export default NativeSelect;
