import React from 'react';
import PropTypes from 'prop-types';
import style from './data.less';

const AboutRow = props => {
	const { title, children } = props;

	return (
		<div className={style.table_row}>
			<div className={style.table_row_td}><span>{ title }</span></div>
			<div className={style.table_row_td}>{ children }</div>
		</div>
	)
};

AboutRow.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
};
AboutRow.defaultProps = {
	title: '',
	children: '',
};

export default AboutRow;