exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n._3KwRMUB5P7Z_0KdP05ZeOk {\n  display: block!important;\n  color: #cc1c12 !important;\n  font-size: 13px;\n  opacity: 0;\n  transition: all 0.3s;\n  transform: translateY(-4px);\n  margin: 0!important;\n}\n._2e1JIHhrhpATYKR5W55mTC {\n  opacity: 1;\n  transform: none;\n  margin-top: 2px;\n}\n.s0zTnlEj9Ng2BdHc12_fT {\n  margin-bottom: 20px;\n}\n.s0zTnlEj9Ng2BdHc12_fT ._2vVQyrIuaESp0q_v5Lh8mf {\n  color: #979797;\n  font-size: 14px;\n  margin-bottom: 5px;\n}\n.s0zTnlEj9Ng2BdHc12_fT ._1Zl0-8sQkVmzGgPz-JnA_I {\n  word-wrap: break-word;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/UI/Form/Labels/style.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,yBAAyB;EACzB,0BAA0B;EAC1B,gBAAgB;EAChB,WAAW;EACX,qBAAqB;EACrB,4BAA4B;EAC5B,oBAAoB;CACrB;AACD;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;CACjB;AACD;EACE,oBAAoB;CACrB;AACD;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;CACpB;AACD;EACE,sBAAsB;CACvB","file":"style.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.containerError {\n  display: block!important;\n  color: #cc1c12 !important;\n  font-size: 13px;\n  opacity: 0;\n  transition: all 0.3s;\n  transform: translateY(-4px);\n  margin: 0!important;\n}\n.activeError {\n  opacity: 1;\n  transform: none;\n  margin-top: 2px;\n}\n.label_info {\n  margin-bottom: 20px;\n}\n.label_info .title {\n  color: #979797;\n  font-size: 14px;\n  margin-bottom: 5px;\n}\n.label_info .text {\n  word-wrap: break-word;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"containerError": "_3KwRMUB5P7Z_0KdP05ZeOk",
	"activeError": "_2e1JIHhrhpATYKR5W55mTC",
	"label_info": "s0zTnlEj9Ng2BdHc12_fT",
	"title": "_2vVQyrIuaESp0q_v5Lh8mf",
	"text": "_1Zl0-8sQkVmzGgPz-JnA_I"
};