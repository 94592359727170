import React from 'react';
import Page from 'components/UI/Wrappers/Page';
import Grid from 'components/UI/Wrappers/Grid';
import style from 'components/Verification/style.less';

const Helps = () => (
	<Page title="Помощь 24/7">
		<Grid col="9-3">
			<div>
				<p>
					Представители HurryLorry всегда на связи — без выходных и праздников, в любое время дня и ночи. Нет связи с Отправителем? Возникли форс-мажорные обстоятельства? Некорректно работает приложение? Сотрудники круглосуточной информационной поддержки успешно и быстро решат ваши проблемы:
				</p>
				<ul className={style.defaultList}>
					<li>помогут с регистрацией на бирже;</li>
					<li>подскажут, как загрузить документы для верификации;</li>
					<li>посоветуют, как найти перевозчика на наиболее выгодных условиях;</li>
					<li>оперативно решат любые вопросы, возникающие при выполнении заказа.</li>
				</ul>
				<p>
					Специалисты не только порекомендуют, как по максимуму использовать функционал HurryLorry, но и позаботятся о том, чтобы сгладить назревающий конфликт между сторонами сделки. Они всегда объективны и руководствуются принципами справедливости и разумности.
				</p>
			</div>
		</Grid>
	</Page>
);

export default Helps;