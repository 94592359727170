import React from 'react';
import PropTypes from 'prop-types';
import { convertNumberSpaces } from 'libs/convertNumberSpaces';
import { Pencil } from 'components/UI/Icons';
import ChangeCargoPrice from 'components/ReduxForms/ChangeCargoPrice';
import style from './style.less';
import Button from 'components/UI/Form/Button';

class AsideYourBid extends React.PureComponent{

	static propTypes = {
		price: PropTypes.number,
		text: PropTypes.string,
		isRoute: PropTypes.bool
	};

	static defaultProps = {
		price: 0,
		isEdit: false,
		text: 'Ваша ставка',
		edit: ()=>{},
		isRoute: false,
	};

	state = {
		edit: false
	};

	toggleForm = () => {
		this.setState({edit: !this.state.edit});
	};

	handleEdit = values => {
		this.props.edit(values);
		this.setState({edit: false});
	};

	render() {
		const {price, text, isEdit} = this.props;

		if(this.state.edit)
			return (
				(
					<div className={style.your_bid}>
						<div className={style.title}>{ text }</div>
						<div className={style.body}>
							<ChangeCargoPrice
								onSubmit={this.handleEdit}
								initialValues={{price: Number.isInteger(price) ? price : 0}}
							/>
						</div>
					</div>
				)
			);
		else
			return (
				(
					<div className={style.your_bid}>
						<div className={style.title}>{ text }</div>
						<div className={style.body}>
							{
								price ?
									<div className={style.value}>
										{ convertNumberSpaces(price) } <span className={style.currency}>{ this.props.isRoute ? 'р/км' : 'р.' }</span>
									</div>
									:
									<span>Не указана</span>
							}
							{isEdit && <Button onClick={this.toggleForm}>{ Pencil(style.pencil) }</Button>}
						</div>
					</div>
				)
			);
	}
}

export default AsideYourBid;