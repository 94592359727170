import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { registration } from 'actions/user';
import GrayBackground from 'components/UI/Wrappers/GrayBackground';
import Container from 'components/UI/Wrappers/Container';
import Grid from 'components/UI/Wrappers/Grid';
import Button from 'components/UI/Form/Button';
import RegistrationForm from 'components/ReduxForms/RegistrationForm';
import { SubmissionError } from 'redux-form';
import showError from 'libs/showFormErrors';
import { confirmPassword, required } from 'libs/validationsForms';
import { setItem, getItem } from 'libs/localStorage';
import { getProfileType } from 'libs/selections';
import ConfirmPhoneBySMS from 'components/ConfirmPhoneBySMS';
import style from './style.less';
import { normalizeString } from 'libs/normalizeString';

class RegistrationCustomer extends React.PureComponent {
	
	recaptchaRef = React.createRef();

	state = {
		// step: getItem('phone', false) ? 2 : 1
		step: 1
	};

	handleSubmitRegistration = values => {

		showError({
			firstName: [required(values.firstName)],
			lastName: [required(values.lastName)],
			email: [required(values.email)],
			phone_number: [required(values.phone_number)],
			password: [required(values.password)],
			passwordAgain: [required(values.passwordAgain), confirmPassword(values.password, values.passwordAgain)]
		});
		values.driver = false;

		values.firstName = normalizeString(values.firstName);
		values.lastName = normalizeString(values.lastName);

		const phone = values.code + values.phone_number.replace(/\s/g, '');
		return new Promise((resolve, reject) => {
			this.props.dispatch(registration({
				...values,
				phone: phone
			}, {
				resolve: resolve,
				reject: reject,
			}));
		}).then(result => {
			if (result) {
				setItem('phone', phone);
				this.setState({ step: 2 });
			}
		}).catch(err => {
			this.recaptchaRef.current.reset();
			if (err.phone)
				err.phone_number = 'Указанный телефон уже зарегистрирован в системе';
			
			if (err.email)
				err.email = 'Указанный e-mail уже зарегистрирован в системе';
			throw new SubmissionError({
				...err,
				_error: 'Ошибка регистрации'
			})
		});
	};

	render() {
		if (this.props.role !== 'guest') {
			return <Redirect to="/edit/profile/" />
		}

		return (
			<GrayBackground left={'client'} right>
				<Container>
					<Grid className={style.container} col="2">
						<div className={style.content}>
							<h2>Вы уже зарегистрировались?</h2>
							<p>
								Войдите с помощью регистрационных данных и получите доступ к сервису грузоперевозок
							</p>
							<Link to="/auth" className={style.link}>
								<Button color="white-reg" size="large">Войти</Button>
							</Link>
						</div>
						<div className={`${style.content} ${style.form}`}>
							{this.state.step === 1 &&
								<RegistrationForm
									initialValues={{driver: false}}
									onSubmit={this.handleSubmitRegistration}
									recaptchaRef={this.recaptchaRef}
								/>
							}
							{this.state.step === 2 &&
								<ConfirmPhoneBySMS dispatch={this.props.dispatch} />
							}
						</div>
					</Grid>
				</Container>
			</GrayBackground>
		);
	}
}

export default connect(
	store => ({
		role: getProfileType(store),
	})
)(RegistrationCustomer);
