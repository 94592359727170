import handleActions from 'redux-actions/es/handleActions';
import {setData, setDataAfterUpdateProfile} from 'actions/profile';

const getProfile = initStore => handleActions({
	[setData]: (state, action) => action.payload,
	[setDataAfterUpdateProfile]: (state, action) => {
		let newState = state;
		newState.firstName = action.payload.firstName;
		newState.lastName = action.payload.lastName;
		newState.middleName = action.payload.middleName;
		newState.phone = action.payload.phone;
		newState.additionalPhone = action.payload.additionalPhone;
		newState.driver.city = action.payload.city;
		newState.driver.experienceYears = action.payload.experienceYears;
		newState.driver.languages = action.payload.languages;
		return newState
	},
}, initStore);

export default getProfile;