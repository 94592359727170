import React from 'react';
import styles from 'components/Pages/Trades/Detail/styles.less';
import PropTypes from 'prop-types';
import language from 'language';
import { Link } from 'react-router-dom';
import { convertPhone } from 'libs/convertNumberSpaces';
import Aside from 'components/UI/Wrappers/Aside';
import Rating from 'components/UI/ProfileUI/Rating';
import { LabelInfo } from 'components/UI/Form/Labels';
// import Avatar from 'components/UI/ProfileUI/Avatar';

class Contacts extends React.Component {

	static propTypes = {
		user: PropTypes.object.isRequired,
		contactList: PropTypes.array.isRequired,
	};


	render() {
		let { user, contactList } = this.props;
		return (
			<Aside marginBottom={30}>
				<div className={styles.header}>{ language['ContactInfo'] }</div>
				<div className={styles.body}>
					<div className={styles.body_profile}>
						<div className={styles.avatar}>
							{/*<Avatar src={user.driver.photo} />*/}
						</div>
						<div className={styles.body}>
							<div className={styles.username}>
								<Link to={`/profile/${user.id}`}>
									{ user.firstName } { user.lastName }
								</Link>
							</div>
							{
								user.reviewsCount > 0
								&& <Rating
									className={styles.rating}
									count={user.rating}
									reviews={ { count: user.reviewsCount } }
								/>
							}
							<div className={`${styles.info_2} ${styles.marginBottom20}`}>
								{ convertPhone(user.phone) }{ user.email ? `, ${user.email}` : '' }
							</div>
							{
								contactList.map((el, i) => (
									<LabelInfo key={i} title={i === 0 ? 'Грузоотправитель' : 'Грузополучатель'}>
										{ el.providerName }, { convertPhone(el.phone_code + el.providerPhone) }
									</LabelInfo>
								))
							}
						</div>
					</div>
				</div>
			</Aside>
		);
	}
}

export default Contacts;