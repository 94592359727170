import React from 'react';
import messageStyle from 'components/Chat/center/message.less';
import { getMessageTime } from 'libs/getMessageTime';
import MessageStatus from 'components/Chat/center/MessageStatus';
import MessageOffers from 'components/Chat/center/MessageOffers';
import MessageType from 'components/Chat/center/MessageType';


const MyMessage = ({ messageData, isTime, handleClose }) => {
	
	let text = messageData.text,
		status = messageData.status ? messageData.status : messageData.isRead;

	if (typeof messageData.text === 'string')
		text = messageData.text.split('\n').map((i, k) => <div key={k}>{i}</div>);
	
	// console.log(messageData);
	return (
		<div className={`${messageStyle.messageContainer} ${messageStyle.my}`}>
			
			{
				isTime &&
				<div className={messageStyle.info}>
					<span className={messageStyle.time}>
						{getMessageTime(messageData.createTime)}
					</span>
				</div>
			}
			
			{
				text !== null &&
				<div className={messageStyle.text}>
					<MessageStatus status={status} />
					{ text }
					<MessageType type={messageData.type} />
				</div>
			}
			
			<MessageOffers handleClose={handleClose} offers={messageData.offers} />
			
		</div>
	)
};


export default MyMessage;