import React, { Component } from 'react';
import style from 'components/Admin/UI/Periods/style.less';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Checkbox from 'components/UI/Form/Fields/Checkbox';
import InputDate from 'components/UI/Form/Fields/InputDate';


class Periods extends Component {
	
	static propTypes = {
		handleSubmit: PropTypes.func,
		error: PropTypes.string,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
	};
	
	static defaultProps = {
		handleSubmit: () => {},
		invalid: false,
		submitting: false,
		error: '',
	};
	
	render() {
		
		let { handleSubmit } = this.props;
		
		return (
			<div className={style.periodContainer}>
				
				<form
					onSubmit={handleSubmit}
				>
					
					<Field
						name={'startDate'}
						component={InputDate}
						label={'Период с'}
					/>
					<span className={style.tire}>—</span>
					<Field
						name={'endDate'}
						component={InputDate}
						label={'Период по'}
					/>
					
					<Field
						name="all"
						component={Checkbox}
						label={'Общая за весь период'}
					/>
				
				</form>
			</div>
		)
	}
}

export default reduxForm({
	form: 'PeriodAdminForm',
})(Periods);