import handleActions from 'redux-actions/es/handleActions';
import {add, rm} from 'actions/notifications';
import {setItem} from 'libs/sessionStorage';

const getNotifications = initStore => handleActions({
	[add]: (state, action) => {
		let newData = [
			...state,
			action.payload
		];
		setItem('notifications', JSON.stringify(newData));
		return newData;
	},
	[rm]: (state, action) => {
		let newData = state.filter(it => it.id !== action.payload);
		setItem('notifications', JSON.stringify(newData));
		return newData;
	},
}, initStore);

export default getNotifications;