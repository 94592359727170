import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';

const Badge = props => (
	<div className={`${style.badge} ${style[props.color]} ${props.className}`}>
		{ props.children }
	</div>
);

Badge.propTypes = {
	children: PropTypes.node,
	color: PropTypes.string,
	className: PropTypes.string,
};
Badge.defaultProps = {
	children: '',
	color: 'yellow',
	className: '',
};

export default Badge;
