exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3bUklaB53rVm1Zcce_RsnO {\n  margin-top: 10px;\n  margin-bottom: 40px;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/ReduxForms/RegistrationForm/style.less"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,oBAAoB;CACrB","file":"style.less","sourcesContent":[".button {\n  margin-top: 10px;\n  margin-bottom: 40px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"button": "_3bUklaB53rVm1Zcce_RsnO"
};