import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Status from 'components/UI/Status';
import { dateToString, stringToDate } from 'libs/convertDate';
import { convertNumberSpaces } from 'libs/convertNumberSpaces';
import { getProfileType } from 'libs/selections';
import style from './style.less';

class RequestContract extends React.Component {

	static propTypes = {
		offerID: PropTypes.number.isRequired,
		price: PropTypes.number.isRequired,
		proposals: PropTypes.array,
		status: PropTypes.string.isRequired,
		createTime: PropTypes.string,
	};

	static defaultProps = {
		proposals: [],
	};

	render () {
		const { props } = this;

		let executor = {
			uid: 0,
			name: '',
			lastName: '',
			role: 'Перевозчик'
		};

		props.contract.forEach(el => {
			if (el.status === 'accepted') {
				if (props.role === 'driver') {
					executor.id = el.user2.id;
					executor.name = el.user2.firstName.charAt(0).toUpperCase();
					executor.lastName = el.user2.lastName;
					executor.role = 'Заказчик';
				} else {
					executor.id = el.user1.id;
					executor.name = el.user1.firstName.charAt(0).toUpperCase();
					executor.lastName = el.user1.lastName;
				}
			}
		});

		let date = dateToString(stringToDate(props.createTime), 'default');

		return (
			<div className={`${style.requestCommon} ${style.requestDriver}`}>
				<div className={style.price}>
					<div className={style.title}>Сумма сделки</div>
					<div className={style.value}>
						{convertNumberSpaces(this.props.price)} <span className={style.currency}>р.</span>
					</div>
				</div>
				<div className={style.item}>
					<div className={style.gray}>{executor.role}</div>
					<Link to={`/profile/${executor.id}`}>{executor.lastName} {executor.name}.</Link>
				</div>
				<div className={style.item}>
					<div className={style.gray}>Дата заключения сделки</div>
					<span style={{ fontSize: '15px' }}>{date}</span>
				</div>
				<div className={`${style.item} ${style.status}`}>
					<Status status={props.status} role={this.props.role}/>
				</div>
			</div>
		);
	}
}

export default connect(
	store => ({
		role: getProfileType(store),
	})
)(RequestContract);