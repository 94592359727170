import React  from 'react';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
// import createHistory from 'history/createBrowserHistory';
import { createBrowserHistory } from 'history';
import Main from 'components/Main/Main';
import store from './store';
import {getData} from 'actions/profile';
import * as serverHelpersActions from 'actions/serverHelpersActions';
import {getToken} from 'libs/tokenDriver';
import {add} from 'actions/notifications';
import {scrollToTop} from 'libs/scrollTo';
import {getProfileType} from 'libs/selections';
import languagesForDataPicker from 'libs/languagesForDataPicker';
import './initialStyles.less';
import { YMInitializer } from 'react-yandex-metrika';

export const history = createBrowserHistory();

history.listen(data => {
	if (data.search === '')
		if (!data.hash) scrollToTop(100);
});


export default class App extends React.Component {

	state = {
		load: true
	};

	componentWillMount() {
		languagesForDataPicker();
		/**
		 * при первичном запуске приложения
		 * мы попробуем получить данные профиля
		 * (инициализирвать)
		 */
		if(getToken()){
			return new Promise((resolve, reject) => {
				store.dispatch(getData(null, {
					resolve: resolve,
					reject: reject,
				}));
			}).catch(err => {
				store.dispatch(add({
					time: 10,
					type: 'error',
					text: err,
				}));
			}).finally(this.handleHideLoader);
		}else{
			this.handleHideLoader();
		}
	}

	componentDidMount() {
		store.dispatch(serverHelpersActions.getCargoList());
		store.dispatch(serverHelpersActions.getPackageList());
		store.dispatch(serverHelpersActions.getBodyTypeList());
		store.dispatch(serverHelpersActions.getLoadTypeList());
		store.dispatch(serverHelpersActions.getAdditionalList());
	}

	handleHideLoader = () => {
		setTimeout(()=> {
			this.setState({ load: false }, () => document.querySelector('#loader').classList.add('hide'))
		}, 400);
	};

	render() {
		if (this.state.load)
			return <div>Loading...</div>;
		else
			return (
				<>
					<Provider store={store}>
						<ConnectedRouter history={history}>
							<Route path="/" component={()=><Main role={getProfileType(store.getState())} />}/>
						</ConnectedRouter>
					</Provider>
					<YMInitializer
						accounts={[55495876]}
						options={{
							id: 55495876,
							clickmap: true,
							trackLinks: true,
							accurateTrackBounce: true,
							webvisor: true
						}}
						version="2"
					/>
				</>
			);
	}
}

/*
* доступ к БД
* http://pma.hurrylorry.com
* root / ZAR25RMcXA
*/


/*
* админ
* 1111111111 / 123123Aa
*/

/*
*
* Dev server
*
* Михаил customer
* 9277760943 / 123123Aa
*
* Анна customer
* 9276116315 / 123123Aa
*
* Светлана driver
* 9871556547 / 123123Aa
*
* Ашот driver
* 9270212050 / 123123Aa
*
* */
