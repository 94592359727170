exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n._1cp3qruav-QNSQBJpOB9qc {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n._1jeqTfCkHT_PU_FjzkujL1 {\n  flex: 1;\n  display: flex;\n}\n._1jeqTfCkHT_PU_FjzkujL1 > div {\n  height: inherit;\n  width: 100%;\n}\n._2DX5ltVjfJXi4bzavoxnqD > div {\n  display: flex;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/UI/Layouts/style.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,cAAc;EACd,uBAAuB;EACvB,kBAAkB;CACnB;AACD;EACE,QAAQ;EACR,cAAc;CACf;AACD;EACE,gBAAgB;EAChB,YAAY;CACb;AACD;EACE,cAAc;CACf","file":"style.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.main-container {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n.main-content {\n  flex: 1;\n  display: flex;\n}\n.main-content > div {\n  height: inherit;\n  width: 100%;\n}\n.flexForChildren > div {\n  display: flex;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"main-container": "_1cp3qruav-QNSQBJpOB9qc",
	"main-content": "_1jeqTfCkHT_PU_FjzkujL1",
	"flexForChildren": "_2DX5ltVjfJXi4bzavoxnqD"
};