import * as adminAction from 'actions/admin';
import { chatActions } from 'actions/chat';
import { offersActions } from 'actions/offers';
import LeftMenu from 'components/Admin/Pages/Drivers/Detail/LeftMenu';
import AdminContainer from "components/Admin/UI/Container/index";
import AdminGrid from "components/Admin/UI/Grid/index";
import { AdminLabel } from "components/Admin/UI/Label/index";
import AdminPage from "components/Admin/UI/Page/index";
import CenterDialog from "components/Chat/center/index";
import Button from "components/UI/Form/Button/index";
import Loader from 'components/UI/Loader';
import Page from 'components/UI/Wrappers/Page';
import { getAdminArbitrate } from 'libs/selections';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import style from './style.less';
import Controls from 'components/Admin/Pages/Arbitrations/Item/Controls';


class AdminArbitrateItem extends React.Component {

    state = {
        dialogChat: false
    };

    changeStatus = status => {
        const { id } = this.props.arbitrate;
        // console.log(status);
        this.props.dispatch(adminAction.setArbitrateStatus({ id, status }));
    };

    banToUser = (userId, isSet) => {
        const dialogId = this.props.arbitrate.dialog.id;
        if (isSet) this.props.dispatch(adminAction.setBan({ userId, dialogId }));
        else this.props.dispatch(adminAction.rmBan({ userId, dialogId }));
    };

    toggleChat = dialogId => {
        this.props.dispatch(chatActions.getHistoryByID({
            dialogId, page: 1
        }));
    };

    downloadContract = () => {
        this.props.dispatch(offersActions.downloadContract(this.props.arbitrate.trade.id));
    };

    componentWillMount() {
        this.props.dispatch(adminAction.getArbitrateByID(this.props.match.params.id));
    }

    render() {

        const { arbitrate } = this.props;

        if (arbitrate === undefined)
            return <Page title={`Арбитраж №${this.props.match.params.id}`}><Loader/></Page>;
        const { customer, executor, id } = arbitrate.trade;

        return (
            <AdminPage>
                <LeftMenu link="/admin/trades/"/>
                <div>
                    <AdminGrid type="mainContent">
                        <div className="flex">
                            <h1>{`Арбитраж №${arbitrate.id} по сделке ${id}`}</h1>
                        </div>
                        <AdminContainer>
                            <Controls changeStatus={(status) => this.changeStatus(status)} />

                            <div className={style.users}>
                                <div className={style.users__item}>
                                    <b>Клиент:</b> {customer.firstName} {customer.lastName}
                                    <div className={style.buttons}>
                                        <Button
                                            color={'red'}
                                            onClick={() => this.banToUser(customer.id, true)}
                                        >
                                            Забанить
                                        </Button>
                                        <Button onClick={() => this.banToUser(customer.id, false)}>Разбанить</Button>
                                    </div>
                                </div>
                                <div className={style.users__item}>
                                    <b>Водитель:</b> {executor.firstName} {executor.lastName}
                                    <div className={style.buttons}>
                                        <Button
                                            color={'red'}
                                            onClick={() => this.banToUser(executor.id, true)}
                                        >
                                            Забанить
                                        </Button>
                                        <Button onClick={() => this.banToUser(executor.id, false)}>Разбанить</Button>
                                    </div>
                                </div>
                            </div>

                            <h3>Информация</h3>
                            <AdminLabel title="Сделка">
                                <Link to={`/admin/trade/${id}`}> №{id} </Link>
                            </AdminLabel>
                            <AdminLabel title="Водитель">
                                <Link
                                    to={`/admin/driver/${executor.id}`}> {executor.lastName} {executor.firstName} </Link>
                            </AdminLabel>
                            <AdminLabel title="Грузоотправитель">
                                <Link
                                    to={`/admin/client/${customer.id}`}> {customer.lastName} {customer.firstName} </Link>
                            </AdminLabel>
                            <AdminLabel title="Договор">
                                <Link to="#" onClick={e => {
                                    e.preventDefault();
                                    this.downloadContract();
                                }}> договор.pdf </Link>
                            </AdminLabel>

                            <h3>История статусов</h3>


                            <h3 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                Чат
                                <div className={style.buttons}>
                                    <Button color={'red'}> Арбитраж </Button>
                                    <Button> Чат пользователей </Button>
                                </div>
                            </h3>

                            <CenterDialog
                                dialog={arbitrate.dialogArbitration}
                                handleClose={() => false}
                                customStyle={style.shadow}
                            />


                        </AdminContainer>
                    </AdminGrid>
                </div>

            </AdminPage>

        )
    }
}


export default connect(
    state => ({
        arbitrate: getAdminArbitrate(state)
    })
)(AdminArbitrateItem);
