import React from 'react';
import styles from '../style.less';
import Avatar from 'components/UI/ProfileUI/Avatar';
import RatingShort from 'components/UI/ProfileUI/RatingShort';
import shortName from 'libs/shortName';


const Userdata = ({ user }) => {
	if (user === undefined) return null;
	return (
		<div className={styles.userdata}>
			<Avatar className={styles.avatar} size={79} src={user.profile.photo} />
			<div className={styles.username}>{ shortName(user.firstName, user.lastName) }</div>
			<RatingShort rating={user.rating} count={user.reviewsCount} />
		</div>
	)
};


export default Userdata;