import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.less';
import Panel from 'components/UI/Wrappers/Panel';
import Point from 'components/UI/Point';
import language from 'language';
import { LabelInfo } from 'components/UI/Form/Labels';

const RouteDesired = props => {
	
	const { route } = props,
		title = route.geography === 'city' ? 'городу' : route.geography === 'region' ? 'области' : 'стране',
		isCity = route.geography === 'city',
		isRegion = route.geography === 'region',
		isCountry = route.geography === 'country',
		citiesRender = route.items.slice(1, route.items.length);
	
	return (
		<Panel title={`География маршрута (по ${title})`} marginBottom={30}>
			<div className={styles.container}>
				<Point point={isCity || isRegion ? 'map' : 'A'} className={styles.point} />
				<div className={styles.address}>
					{
						isCity && route.items[0].locality
					}
					{
						isRegion && route.items[0].region
					}
					{
						isCountry &&
						<Fragment>
							<LabelInfo title={`Точка отправки`}>
								{ route.items[0].address }
							</LabelInfo>
							<LabelInfo title={`Возможные точки разгрузки`}>
								{
									citiesRender.map((el,i) =>
										`${el.locality}${i + 1 < citiesRender.length ? ', ' : ''}`)
								}
							</LabelInfo>
						</Fragment>
					}
					<span className={styles.publicPeriod}>
						Публикация { language[`period${route.typePeriodPublication}`] }
					</span>
				</div>
			</div>
		</Panel>
	);
};

RouteDesired.propTypes = {
	route: PropTypes.object,
	cities: PropTypes.array,
};
RouteDesired.defaultProps = {
	route: { address: '', startDate: '' },
	cities: [],
};

export default RouteDesired;