import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getProfileType, getPoints, getDistance } from 'libs/selections';
import Page from 'components/UI/Wrappers/Page';
import OfferForm from 'components/ReduxForms/OfferForm';
import { sendNewOfferToServer } from 'components/ReduxForms/OfferForm/submit';
import style from 'components/AddCargo/style.less';
import Grid from 'components/UI/Wrappers/Grid';
import Maps from 'components/UI/Maps';
import Sticky from 'react-sticky-fill';
import { setItem, getItem } from 'libs/localStorage';
import forwardTo from 'libs/forwardTo';


class CreateOffer extends Component {
	
	recaptchaRef = React.createRef();
	
	handleSubmit = values => {
		return sendNewOfferToServer(values, this.props.dispatch, this.props.role, (phone) => {
			setItem('phone', phone);
			forwardTo('/confirm-phone-after-offer/');
		}, () => {
			// if was error in validate, we reset captcha
			this.recaptchaRef.current.reset();
		});
	};

	render() {

		let initialValues = {
			agree: true,
			route: {
				items: getItem('points') ? JSON.parse(getItem('points')) : [{}, {}]
			},
			price: 0,
			isGuest: false, // далее принимается статус signIn или signUp в offerGuest, false - default
		};

		return (
			<Page title={'Создание заявки на перевозку'}>
				<Grid className={style.marginAfterPanel} col="9-3">
					<div>
						<OfferForm
							initialValues={initialValues}
							recaptchaRef={this.recaptchaRef}
							onSubmit={this.handleSubmit}
						/>
					</div>
					<div>
						<Sticky style={{ top: this.props.role === 'guest' ? '30px' :'75px' }}>
							<Maps height={window.innerWidth < 768 ? 200 : window.innerWidth < 1366 ? 350 : 500} />
						</Sticky>
					</div>
				</Grid>
			</Page>
		);
	}
}

export default connect(
	store => ({
		role: getProfileType(store),
		points: getPoints(store),
		distance: getDistance(store)
	})
)(CreateOffer);
