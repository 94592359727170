import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import Input from 'components/UI/Form/Fields/Input';
import Button from 'components/UI/Form/Button';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import style from './style.less';
import InputSelectGroup from 'components/UI/Wrappers/InputSelectGroup';
import Select from 'components/UI/Form/Fields/Select';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import asyncValidate from 'libs/asyncValidate';
import ReCAPTCHA from "react-google-recaptcha";

const	selector = formValueSelector('recoveryPassword');

class RecoveryPassword extends React.Component {

	static propTypes = {
		handleSubmit: PropTypes.func,
		error: PropTypes.string,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
	};

	static defaultProps = {
		handleSubmit: () => {
		},
		invalid: false,
		submitting: false,
		error: '',
	};

	isEnabledSubmit = () => {
		const { phone } = this.props;
		if (phone !== null && phone !== undefined && phone.replace(/[^0-9]/gim,'').length === 10)
			if (phone.search('_') === -1)
				return true;
		return false;
	};

	onChange = token => {
		this.props.dispatch(change('recoveryPassword', 'recaptchaValue', token));
	};


	render() {
		let { handleSubmit, invalid, submitting } = this.props;
		const isEnabledSubmit = this.isEnabledSubmit();
		return (
			<form onSubmit={handleSubmit} autoComplete="off">
				<h1>Восстановление пароля</h1>
				<p>Введите телефон указанный при регистрации</p>
				<LayoutField>
					<LayoutField>
						<InputSelectGroup>
							<Field
								name="phoneNumber"
								type="text"
								component={Input}
								label="Телефон"
								mask="9999 99 99 99"
								containerGroup="left"
							/>
							<Field
								name="recovery_code"
								component={Select}
								childGroup="left"
							>
								<OptionSelect value="7">+7</OptionSelect>
								<OptionSelect value="375">+375</OptionSelect>
								<OptionSelect value="98">+98</OptionSelect>
							</Field>
						</InputSelectGroup>

						<div style={{ marginTop: '15px' }}>
							<ReCAPTCHA
								sitekey="6Lf5T7kUAAAAALGUCClRc6jZmwEUcGhW9OcZYuHm"
								onChange={this.onChange}
							/>
						</div>
					</LayoutField>
				</LayoutField>
				<Button
					className={style.button}
					disabled={invalid || !isEnabledSubmit}
					loading={submitting}
					size="large"
					type="submit"
				>
					Отправить код на телефон
				</Button>
			</form>
		);
	}
}

export default compose(
	reduxForm({
		form: 'recoveryPassword',
		asyncValidate,
		asyncBlurFields: ['phoneNumber'], //recovery_number
	}),
	connect(
		state => ({
			phone: selector(state, 'phoneNumber')
		})
	)
)(RecoveryPassword);