import React from 'react';
import Panel from 'components/UI/Wrappers/Panel';
import Point from 'components/UI/Point';
import getTimeInterval from 'libs/getTimeInterval';
import { dateToString, serverDateToClientDate } from 'libs/convertDate';
import { LabelInfo } from 'components/UI/Form/Labels';
import { convertPhone } from 'libs/convertNumberSpaces';
import style from './style.less';

const InfoPointA = props => {
	let provider = [];
	if(props.route.providerName) provider.push(<span key="name">{props.route.providerName}</span>);
	if(props.route.providerPhone) provider.push(
		<span key="phone">
			{ provider.length > 0 && ', ' }
			{ convertPhone(props.route.phone_code + props.route.providerPhone) }
		</span>
	);
	let timeInterval = getTimeInterval(props.route.timeIntervalLoad);
	return (
		<Panel title="Информация о загрузке" marginBottom={30}>
			<div className={style.point}>
				<Point point="A" className={style.pointA} pointLineClass={style.pointLine} />
				<LabelInfo title="Адрес загрузки">{ props.route.address.name }</LabelInfo>
				<LabelInfo title="Дата, время загрузки">{ dateToString(serverDateToClientDate(props.route.startDate), 'default') }, { timeInterval.toLowerCase() }</LabelInfo>
				{provider.length > 0 }
				<LabelInfo title="Грузоотправитель">{provider}</LabelInfo>
			</div>
		</Panel>
	);
};

export default InfoPointA;


