import React from 'react';
import leftStyle from 'components/Admin/UI/LeftContent/style.less';
import AdminGrid from 'components/Admin/UI/Grid';
import LeftContent from 'components/Admin/UI/LeftContent';


const LeftMenu = props => {
	
	return (
		<div>
			<AdminGrid type="aside">
				<LeftContent title={'Клиенты'}>
					<ul className={leftStyle.list}>
						{
							props.list.map(el => (
								<li className={`${leftStyle.item} ${leftStyle.item__lnk} ${leftStyle.item__active}`}
									key={el.title}
								>
									{ el.title }
									<span className={leftStyle.item__counter}>
										{ el.count }{ el.newCount !== '0' ? ` (+${el.newCount})` : '' }
									</span>
								</li>
							))
						}
					</ul>
				</LeftContent>
			</AdminGrid>
		</div>
	)
};


export default LeftMenu;