import React from 'react';
import PropTypes from 'prop-types';
import Container from 'components/UI/Wrappers/Container';
import style from 'components/Admin/UI/Page/style.less';
import AdminGrid from 'components/Admin/UI/Grid';


const AdminPage = props => (
	<Container className={style.container}>
		<AdminGrid>
			{ props.children }
		</AdminGrid>
	</Container>
);


AdminPage.propTypes = {
	children: PropTypes.node,
};
AdminPage.defaultProps = {
	children: null,
};

export default AdminPage;
