import Input from 'components/UI/Form/Fields/Input';
import Grid from 'components/UI/Wrappers/Grid';
import InputGroup from 'components/UI/Wrappers/InputGroup';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import { NumberNormalizeSlice } from 'libs/normalize';
import React from 'react';
import { connect } from 'react-redux'
import { Field, formValueSelector, getFormSubmitErrors } from 'redux-form';
import PropTypes from 'prop-types';
import style from './style.less';
// import { cargoesValidate } from 'libs/validationsForms';
// import { showErrorAsync } from 'libs/showFormErrors';


let nameForms = ' ';
let selector = formValueSelector(nameForms);


class Dimensions extends React.PureComponent {

    static propTypes = {
        nameForm: PropTypes.string,  // название формы для выбора данных
        name: PropTypes.string  // название поля для валидации
    };

    static defaultProps = {
        height: 0,
        width: 0,
        length: 0,
        name: 'cargo',
        nameForm: 'addCargo'
    };

    state = {
        height: this.props.height,
        width: this.props.width,
        length: this.props.length
    };

    componentWillMount() {
        let { nameForm } = this.props;
        nameForms = nameForm;
        selector = formValueSelector(nameForm);
    }

    componentWillReceiveProps(nextProps) {
		// const { height, width, length } = nextProps;
		// showErrorAsync({
         //    [this.props.name]: [cargoesValidate({ height, width, length })]
        // });
    }

    render() {
        const error = this.props.submitErrors[this.props.name];
        const styleError = error && style.error;
        let volume = this.props.height * this.props.width * this.props.length;

        return (
            <LayoutField>
                <Grid col="form-3" className={style.grid2}>
                    <InputGroup className={style.grid2}>
                        <Field
                            name="cargo[length]"
                            component={Input}
                            label="Длина, м."
                            normalize={NumberNormalizeSlice}
                            showError={false}
                            classNameContainer={styleError}
                        />
                        <Field
                            name="cargo[width]"
                            component={Input}
                            label="Ширина, м."
                            normalize={NumberNormalizeSlice}
                            showError={false}
                            classNameContainer={styleError}
                        />
                        <Field
                            name="cargo[height]"
                            component={Input}
                            label="Высота, м."
                            normalize={NumberNormalizeSlice}
                            showError={false}
                            classNameContainer={styleError}
                        />
                    </InputGroup>
                    <div className={style.volume}>
                        <span>Объем, м<sup>3</sup></span>
                        <div>{ volume }</div>
                    </div>
                    {
                        error &&
                        <div className={style.error_label}> Заполните все поля </div>
                    }
                </Grid>
            </LayoutField>
        );
    }
}


export default connect(
    state => ({
        height: selector(state, 'cargo[height]'),
        width: selector(state, 'cargo[width]'),
        length: selector(state, 'cargo[length]'),
        submitErrors: getFormSubmitErrors(nameForms)(state)
    })
)(Dimensions);