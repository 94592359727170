import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import style from 'components/ReduxForms/AddCargoForm/style.less';
import Panel from 'components/UI/Wrappers/Panel';
import { getVolume } from 'libs/getVolume';
import Grid from 'components/UI/Wrappers/Grid';
import Input from 'components/UI/Form/Fields/Input';
// import { Security } from 'components/UI/Icons';
import Checkbox from 'components/UI/Form/Fields/Checkbox';
import Textarea from 'components/UI/Form/Fields/Textarea';


class OfferOther extends PureComponent {

	calcVolume = () => {
		if (this.props.cargo) {
			if (this.props.cargo.height && this.props.cargo.length && this.props.cargo.width) {
				const { height, length, width } = this.props.cargo;
				return getVolume({ height, length, width });
			}
		}
		return 0
	};

	render () {

		return (
			<Panel className={style.customPricePanel} title="Стоимость перевозки">
				<span>Рекомендованная стоимость перевозки</span>
				<Grid col="form-2">
					<Field
						maxLength={7}
						name={'price'}
						component={Input}
						classNameContainer={style.priceBold}
						unit={'руб.'}
					/>
				</Grid>
				<p>* Стоимость перевозки не может быть меньше рекомендованной на 40%</p>
				<div className={style.securityCargo}>
					{/*<div className={style.securityLogo}>*/}
					{/*	{ Security(style.icon) }*/}
					{/*</div>*/}
					<div className={style.securityText}>
						<Field
							name="toInsurance"
							component={Checkbox}
							label={['Я хочу застраховать груз при заключении сделки', <p>Размещая заказ, вы принимаете условия <a href="/agreement.pdf" target="_blank">Пользовательского соглашения</a></p>]}
						/>
					</div>
				</div>
				<Field
					name='comment'
					label="Комментарий к заказу"
					component={Textarea}
				/>
			</Panel>
		)
	}
}

const selector = formValueSelector('offerForm');

export default connect(
	state => ({
		cargo: selector(state, 'cargo'),
	})
)(OfferOther);
