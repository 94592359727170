import { rm } from 'actions/notifications';
import Notification from 'components/UI/Notification';
import { getNotifications } from 'libs/selections';
import React from 'react';
import * as ReactDOM from 'react-dom';
import connect from 'react-redux/es/connect/connect';


class Notifications extends React.PureComponent {

    handleRemoveNotification = id => this.props.dispatch(rm(id));

    render() {
        return ReactDOM.createPortal(
            this.props.notifications.map((it, index) => {
                if (index > 4) {
                    const id = this.props.notifications[index - 5].id;
                    this.handleRemoveNotification(id)
                }
                setTimeout(() => {
                    this.props.dispatch(rm(it.id));
                }, it.time * 1000);
                return (
                    <Notification
                        key={it.id}
                        {...it}
                        onClick={() => this.handleRemoveNotification(it.id)}
                    />
                );
            }),
            document.getElementById('notification')
        );
    }
}


export default connect(
    store => ({
        notifications: getNotifications(store),
    })
)(Notifications);