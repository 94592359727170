import { driverActions } from 'actions/driver';
import { statusListRoute } from 'libs/vars';
import handleActions from 'redux-actions/es/handleActions';

const getDriver = (initStore) => handleActions({
    [driverActions.setCars]: (state, action) => ({
        ...state,
        cars: action.payload.items
    }),
    [driverActions.pushCarToStore]: (state, action) => ({
        ...state,
        cars: [...state.cars, action.payload]
    }),
    [driverActions.setRoutes]: (state, action) => {
        // ...state,
        // routes: action.payload
        if (action.meta) return { ...state, routes: { ...state.routes, [action.meta]: action.payload } };
        let routes = {};
        if (Array.isArray(action.payload)) {
            action.payload.forEach(el => {
                for (let key in statusListRoute) {
                    if (statusListRoute[key].indexOf(el.status) !== -1) {
                        if (Array.isArray(routes[key])) routes[key].push(el);
                        else routes[key] = [el];
                    }
                }
            });
            return { ...state, routes };
        }
        return state;
    },
    [driverActions.setCards]: (state, action) => ({
        ...state,
        carts: action.payload
    }),
    [driverActions.setRouteByID]: (state, action) => ({
        ...state,
        detail: action.payload
    }),
    [driverActions.setRoutesByUserID]: (state, action) => ({
        ...state,
        routes: { ...state.routes, active: { ...action.payload, loading: false } }
    }),


    [driverActions.setActiveRoute]: (state, action) => ({
        ...state,
        routes: { ...state.routes, active: { ...action.payload, loading: false } }
    }),
    [driverActions.setCancelledRoute]: (state, action) => ({
        ...state,
        routes: { ...state.routes, cancelled: { ...action.payload, loading: false } }
    }),
    [driverActions.setUnpublishedRoute]: (state, action) => ({
        ...state,
        routes: { ...state.routes, unpublished: { ...action.payload, loading: false } }
    }),

    [driverActions.loadingActiveRoute]: (state, action) => ({
        ...state,
        routes: { ...state.routes, active: { ...action.payload.active, loading: true } }
    }),
    [driverActions.loadingCancelledRoute]: (state, action) => ({
        ...state,
        routes: { ...state.routes, cancelled: { ...action.payload.cancelled, loading: true } }
    }),
    [driverActions.loadingUnpublishedRoute]: (state, action) => ({
        ...state,
        routes: { ...state.routes, unpublished: { ...action.payload.unpublished, loading: true } }
    }),




}, initStore);

export default getDriver;