import {
    addToFavoriteFromServer,
    loadingActiveCargo,
    loadingCancelCargo,
    setActiveCargo,
    setCancelCargo,
    setFavoriteDrivers,
    setMyCargoes
} from 'actions/customer';
import { statusListCargo } from 'libs/vars';
import handleActions from 'redux-actions/es/handleActions';

const getCustomer = (initStore) => handleActions({
    [setMyCargoes]: (state, action) => {
        if (action.meta) return { ...state, cargoes: { ...state.cargoes, [action.meta]: action.payload } };
        let cargoes = {};

        if (Array.isArray(action.payload)) {
            action.payload.forEach(it => {
                for (let key in statusListCargo) {
                    if (statusListCargo[key].indexOf(it.status) !== -1) {
                        if (Array.isArray(cargoes[key])) cargoes[key].push(it);
                        else cargoes[key] = [it];
                    }
                }
            });
            return { ...state, cargoes };
        }
        return state;
    },
    [setActiveCargo]: (state, action) => ({
        ...state,
        cargoes: { ...state.cargoes, active: { ...action.payload, loading: false } }
    }),
    [loadingActiveCargo]: (state) => ({
        ...state,
        cargoes: { ...state.cargoes, active: { ...state.cargoes.active, loading: true } }
    }),
    [setCancelCargo]: (state, action) => ({
        ...state,
        cargoes: { ...state.cargoes, cancelled: { ...action.payload, loading: false } }
    }),
    [loadingCancelCargo]: (state) => ({
        ...state,
        cargoes: { ...state.cargoes, cancelled: { ...state.cargoes.cancelled, loading: true } }
    }),
    [setFavoriteDrivers]: (state, action) => ({
        ...state,
        favorites: action.payload.items
    }),
    [addToFavoriteFromServer]: (state, action) => ({
        ...state,
        favorites: [...state.favorites, action.payload]
    }),
}, initStore);

export default getCustomer;