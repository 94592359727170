import language from 'language';
import { dateToString, stringToDate } from 'libs/convertDate';

export const required = val => {
    const err = 'Необходимо заполнить это поле';
    if (val instanceof Array)
        return val.length === 0 ? err : undefined;
    if (val instanceof Object && Object.keys(val).length > 0) {
        for (let key in val) {
            if (val[key]) {
                return undefined;
            }
        }
    }
    return val ? undefined : err;
};

export const cardName = val => val && (!/[a-zA-Z]$/.test(val)) ?
    language.errorCardName : undefined;

export const datePickerValue = val => {
    if (val && typeof(val.getMonth) !== 'function') {
        try {
            let d = stringToDate(val);
            return (d instanceof Date && !isNaN(d)) === false ? 'Неверный формат даты' : undefined;
        } catch (e) {
            return 'Неверный формат даты';
        }
    }
};

export const phone = val => val && val.replace(/(_| )/gim, '').length > 0 && val.replace(/[^0-9]/gim, '').length !== 10 ?
    language.errorPhone : undefined;

// old password validate
// export const password = val => val && !/(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(val) ?
//     language.errorPassword : undefined;

export const password = val => val && !/(?=^.{6,}$)/.test(val) ?
    language.errorPassword : undefined;

export const passwordConfirm = (pass1, pass2) => {
    if (pass2 && pass2.length > 0)
        return pass1 === pass2 ? undefined : language.errorPasswordConfirm;
    else return undefined;
};

export const code = val => val && (!/[0-9]{4,}$/.test(val) || val.length !== 4) ?
    language.errorCode : undefined;

export const name = val => val && (val.replace(/( )/gim, '').length < 3 || !/[а-яА-Я]{3,}$/.test(val)) ?
    language.errorName : undefined;

export const clientPassport = val => {
    if (val && val.length === 11) {
        let path = val.split(' ');
        return path[0].match(/^\d{4}$/) && path[1].match(/^\d{6}$/) ? undefined : 'Неверный формат паспорта'
    }
};

export const isDate = date => {
    return !/(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/.test(date);
};

const dateNow = new Date();
const dayNow = dateNow.getUTCDate();
const monthNow = dateNow.getUTCMonth();
const yearNow = dateNow.getUTCFullYear();

/**
 * Проверка даты на текущий день
 * или проверка двух дат
 * nextDate(дата выгрузки или точка маршрута)
 * должна быть позже или равна начальной дате
 */
export const isToday = (date) => {
    if (date) {
        const TIMESTAMP_NOW = new Date(yearNow, monthNow, dayNow).getTime();
        let dateFirst = dateToString(new Date(date));

        if (dateFirst.length === 10) {
            const TIMESTAMP_FIRST_DATE = new Date(date).getTime();
            if (TIMESTAMP_FIRST_DATE < TIMESTAMP_NOW) {
                return 'Указанная дата уже прошла';
            }
        }
    }
};

export const prevDate = (date, nextDate) => {
    if (date && nextDate) {
        let dateFirst = dateToString(new Date(date));
        let dateSecond = dateToString(new Date(nextDate));

        if (dateFirst.length === 10 && dateSecond.length === 10) {
            const TIMESTAMP_FIRST_DATE = new Date(date).getTime();
            const TIMESTAMP_SECOND_DATE = new Date(nextDate).getTime();

            if (TIMESTAMP_SECOND_DATE < TIMESTAMP_FIRST_DATE) {
                return 'Дата рагрузки не можеть раньше, чем указана на предыдущей точке';
            }
        }
    }
};

export const loadDate = (date) => {
    if (date) {
        const TIMESTAMP_NOW = new Date(yearNow, monthNow, dayNow).getTime();
        let dateFirst = dateToString(new Date(date));

        if (dateFirst.length === 10) {
            const TIMESTAMP_FIRST_DATE = new Date(date).getTime();
            if (TIMESTAMP_FIRST_DATE < TIMESTAMP_NOW) {
                return 'Дата не может быть указана раньше, чем сегодняшний день';
            }
        }
    }

    /*

    if (date && nextDate) {
        let dateFirst = dateToString(new Date(date));
        let dateSecond = dateToString(new Date(nextDate));

        if (dateFirst.length === 10 && dateSecond.length === 10) {
            const TIMESTAMP_FIRST_DATE = new Date(date).getTime();
            const TIMESTAMP_SECOND_DATE = new Date(nextDate).getTime();

            if (TIMESTAMP_SECOND_DATE < TIMESTAMP_FIRST_DATE) {
                return 'Дата рагрузки не можеть раньше, чем указана на предыдущей точке';
            }
        }
    }
    if (date) {
        const TIMESTAMP_NOW = new Date(yearNow,monthNow,dayNow).getTime();
        let dateFirst = dateToString(new Date(date));

        if (dateFirst.length === 10) {
            const TIMESTAMP_FIRST_DATE = new Date(date).getTime();
            if (TIMESTAMP_FIRST_DATE < TIMESTAMP_NOW) {
                return 'Дата не может быть указана раньше, чем сегодняшний день';
            }

            if (nextDate) {
                let dateSecond = dateToString(new Date(nextDate));

                if (dateSecond.length === 10) {
                    const TIMESTAMP_SECOND_DATE = new Date(nextDate).getTime();
                    console.log(TIMESTAMP_SECOND_DATE, TIMESTAMP_FIRST_DATE);
                    if (TIMESTAMP_SECOND_DATE < TIMESTAMP_FIRST_DATE) {
                        // return 'Дата рагрузки не можеть раньше, чем указана на предыдущей точке';
                    }
                }
            }

            return undefined;
        }
    }
*/


    // if (date) {
    //
    //     let dateNow = dateToString(new Date(Date.now())).split('.'),
    //         currentDate = dateToString(new Date(date)).split('.');
    //
    //     if (nextDate) {
    //         dateNow = dateToString(new Date(date)).split('.');
    //         currentDate = dateToString(new Date(nextDate)).split('.');
    //     }
    //
    //     if (dateNow[2] < currentDate[2]) return undefined;
    //     else if (dateNow[2] === currentDate[2] && dateNow[1] < currentDate[1]) return undefined;
    //     else if (dateNow[2] === currentDate[2] && dateNow[1] === currentDate[1] && dateNow[0] <= currentDate[0]) return undefined;
    //     else return 'Error date';
    //     // TODO: Написать более подходящий текст ошибки или передавать текст отдельно под ситуцию
    // }
};


export const cargoPrice = (price, min) => price < (min * 0.4 ) ? 'Цена слишком занижена' : undefined;
export const maxLength = (max) => (value) =>
    value && value.length > max ? `Max ${max} chars` : undefined;
export const minLength = (min) => (value) =>
    value && value.length < min ? `Min ${min} chars` : undefined;
export const email = (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
        'Email неправильного формата' : undefined;
export const login = (value) => value && (!/[a-zA-Z0-9]{3,}$/.test(value) || value.length > 10) ?
    'Неправильный логин, используйте только англ. буквы или цифры. Колличество символов от трёз до десяти' : undefined;


export const confirmPassword = (password, passwordAgain) => passwordAgain === password ?
    undefined : 'Пароли не совпадают';
export const defaultTime = (value) => {
    if (value && value.length === 5) {
        let path = value.split(':');
        return parseInt(path[0]) > 24 || parseInt(path[1]) > 59 || (parseInt(path[0]) === 24 && parseInt(path[1]) > 0) ?
            'Неверный формат времени' : undefined;
    }
};

export const notZero = (val) => {
  return parseFloat(val) === 0 ? 'Не может быть равно нулю' : undefined;
};

export const cargoesValidate = cargoes => cargoes && cargoes.length && cargoes.height && cargoes.width ? undefined : 'Заполните поле';
