export const UseCustomStyles = route => {
	return route === '/' || route === '/page/becomecarrier' || route === '/page/becomecarrier/'
		|| route === '/feedback/' || route === '/feedback'
		|| route === '/riskfree/' || route === '/riskfree'
		|| route === '/questions/' || route === '/questions'
		|| route === '/mobile/' || route === '/mobile'
		|| route === '/verification/' || route === '/verification'
		|| route === '/tariffs/' || route === '/tariffs'
		|| route === '/how_start_work/' || route === '/how_start_work'
		|| route === '/insurance/' || route === '/insurance';
};
