import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Container from 'components/UI/Wrappers/Container';
import style from '../style.less';

const HorizontalMenu = props => (
	<div className={`${style['horizontal-menu']} ${props.className ? props.className : ''}`}>
		<Container className={props.mainPage ? style.containerMainPage : ''}>
			<ul>
				<li><Link onClick={props.closeMenu} to="/feedback/">Помощь 24/7</Link></li>
				<li><Link onClick={props.closeMenu} to="/riskfree/">Сделка без риска</Link></li>
				<li><Link onClick={props.closeMenu} to="/questions/">Частые вопросы</Link></li>
			</ul>
		</Container>
	</div>
);

HorizontalMenu.propTypes = {
	mainPage: PropTypes.bool,
	className: PropTypes.string,
	closeMenu: PropTypes.func,
};
HorizontalMenu.defaultProps = {
	mainPage: false,
	className: '',
	closeMenu: () => {}
};

export default HorizontalMenu;
