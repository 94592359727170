/**
 * получить все данные по пользователю
 * @param store
 * @returns {*}
 */
export const getProfile = store => store.profile;

/**
 * получить список типов груза
 * @param store
 * @returns {*}
 */
export const getCargoListStore = store => store.serverHelpers.cargoes.list;

export const getCountersStore = store => store.serverHelpers.counters;

/**
 * получить список упаковок груза
 * @param store
 * @returns {*}
 */
export const getPackageListStore = store => store.serverHelpers.packages.list;

/**
 * получить список типов кузова
 * @param store
 * @returns {*}
 */
export const getBodyTypesListStore = store => store.serverHelpers.bodyTypes.list;

/**
 * получить список типов загрузки в кузов
 * @param store
 * @returns {*}
 */
export const getLoadTypesListStore = store => store.serverHelpers.loadTypes.list;

/**
 * получить список доп. услуг
 * @param store
 * @returns {*}
 */
export const getAdditionalsListStore = store => store.serverHelpers.additionals.list;

/**
 * получить тип пользователя
 * @param store
 * @returns {string}
 *  store.profile ? store.profile.role === 'admin' ? 'customer' : store.profile.role : 'guest';
 */
export const getProfileType = store => store.profile ? store.profile.role : 'guest';

/**
 * получить тип пользователя
 * @param store
 * @returns {string}
 */
export const getSearchResult = store => store.search;

/**
 * Получим все нотификаторы
 * @param store
 * @returns {notifications|{add, rm}}
 */
export const getNotifications = store => store.notifications;

/**
 * Получим все точки на/для карт
 * @param store
 * @returns {*}
 */
export const getPoints = store => store.map.points;

/**
 * Получим все дистанцию в км
 * @param store
 * @returns {*}
 */
export const getDistance = store => store.map.distance;

/**
 * Получим все мои машины
 * @param store
 * @returns {*}
 */
export const getMyCars = store => store.driver.cars;

/**
 * Получим все мои маршруты
 * @param store
 * @returns {*}
 */
export const getMyRoutes = store => store.driver.routes;

/**
 * Получим профиль пользователя, которого просматриваем
 * @param store
 * @returns {*}
 */
export const getProfileDetail = store => store.profileDetail.user;

/**
 * Получим отзывы о пользователе, которого просматриваем
 * @param store
 * @returns {*}
 */
export const getProfileReviews = store => store.profileDetail.reviews;

/**
 * Получим все мои заявки
 * @param store
 * @returns {*}
 */
export const getMyCargoes = store => store.customer.cargoes;

/**
 * Получим все мои активные заявки
 * @param store
 * @returns {*}
 */
export const getMyActiveCargoes = store => store.customer.cargoes.active || {items: [], total: 0};

/**
 * Получим все карты водителя
 * @param store
 * @returns {*}
 */
export const getDriverCarts = store => store.driver.carts;

/**
 * Детальный просмотр заказа
 * @param store
 * @returns {*}
 */
export const getDetailOffer = store => store.offers.detail;

/**
 * получить список событий
 * @param store
 * @returns {*}
 */
export const getEvents = store => store.events.list;

/**
 * Детальный просмотр Маршрута
 * @param store
 * @returns {*}
 */
export const getDetailRoute = store => store.driver.detail;

/**
 * Получить предложения отправлениые от меня/мной
 * @param store
 * @returns {*}
 */
export const getProposalsFromMe = store => store.offers.proposalsFromMe;

/**
 * Получить все свои сделки
 * @param store
 * @returns {*}
 */
export const getOfferList = store => store.offers.offerList;

/**
 * Получить топ свежих заявок с сайта
 * @param store
 * @returns {*}
 */
export const getTopOfferList = store => store.offers.top;

/**
 * Получить все диалоги
 * @param store
 * @returns {*}
 */
export const getChatDialogs = store => store.chat.dialogs;

/**
 * Получить историю чата диалогов
 * @param store
 * @returns {*}
 */
export const getChatHistories = store => store.chat.histories;

/**
 * Получить историю чата диалогов
 * @param store
 * @returns {*}
 */
export const getIdSelectedDialog = store => store.chat.selectedDialogId;

/**
 * Получить список избранных водителей
 * @param store
 * @returns {*}
 */
export const getFavoriteDrivers = store => store.customer.favorites;

/**
 * Получить колличество непрочитаных сообщений
 * @param store
 * @returns {*}
 */
export const getCountDontReadMsg = store => store.chat.countDontReadMsg;

/**
 * Получить все маршруты для админа
 * @param store
 * @returns {*}
 */
export const getRoutes = store => store.admin.routes;


/**
 * Получить все арбитражи для админа
 * @param store
 * @returns {*}
 */
export const getArbitrations = store => store.admin.arbitrations;

/**
 * Получить все сделки для админа
 * @param store
 * @returns {*}
 */
export const getTrades = store => store.admin.trades;


/**
 * Просмотр заявки для админа
 */
export const getAdminOffer = store => store.admin.offerDetail;

/**
 * Просмотр маршрута для админа
 */
export const getAdminRoute = store => store.admin.routeDetail;

/**
 * Просмотр сделки для админа
 */
export const getAdminTrade = store => store.admin.tradeDetail;

/**
 * Просмотр арбитража для админа
 */
export const getAdminArbitrate = store => store.admin.arbitrateDetail;


/**
 * Просмотр всех пользователей
 */
export const getUsers = store => store.admin.users;


/**
 * Просмотр всех пользователей
 */
export const getDriverVerifications = store => store.admin.driverVerifications;


/**
 * Просмотр пользователя для админа
 */
export const getAdminUser = store => store.admin.profileDetail;
