import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';

const Loader = props => (
	<div className={`${style.loader_container} ${props.className && props.className} ${props.size === 'small' && style.small}`}>
		<div className={style.loader}>
			<div className={style.cir_blue} />
			<div className={style.cir_red} />
		</div>
		{
		 	!props.justIcon && <React.Fragment>{ props.text } ...</React.Fragment>
		}
	</div>
);

Loader.propTypes = {
	text: PropTypes.string,
	className: PropTypes.string,
	justIcon: PropTypes.bool,
	size: PropTypes.string,
};
Loader.defaultProps = {
	text: 'Идет загрузка, пожалуйста подождите',
	className: '',
	justIcon: false,
	size: 'default',
};

export default Loader;