import React from 'react';
import style from './style.less';

const DocumentRequired = () => (
	<div className={style.documentText}>
		<h3>Обязательные требования<br/> к документам</h3>
		<p>
			Скан-копии или фотографии должны быть сделаны с оригинала, в хорошем качестве, все детали документов четко
			отображены.
		</p>
		<p>
			- цветные скан-копии или фотографии, выполненные на однотонном фоне
		</p>
		<p>
			- отображены целиком; обрезование краев документа не допускается, все 4 (четыре) угла документа должны
			быть видны
		</p>
		<p>
			- при этом не должны содержать посторонних предметов, объектов или надписей, которых нет на оригинале
			документа (например, скрепок, карандашей, пальцев и т.д.)
		</p>
		<p>
			- отредактированные в графическом редакторе или содержащие артефакты сканирования/фотографирования (след
			от вспышки, размытие) - не принимаются.
		</p>
		<p>
			Файлы принимаются в форматах jpg или png, размером от 100kb до 3Mb
		</p>
	</div>
);

export default DocumentRequired;
