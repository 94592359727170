exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2ZP-UOwpY-8DGHPWfyB03p {\n  margin-top: 30px;\n}\n.V9ej-uakLypC9cn3svcmU {\n  text-align: right;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/ReduxForms/RequestEditOffer/style.less"],"names":[],"mappings":"AAAA;EACE,iBAAiB;CAClB;AACD;EACE,kBAAkB;CACnB","file":"style.less","sourcesContent":[".button {\n  margin-top: 30px;\n}\n.rec {\n  text-align: right;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"button": "_2ZP-UOwpY-8DGHPWfyB03p",
	"rec": "V9ej-uakLypC9cn3svcmU"
};