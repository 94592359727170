import PropTypes from 'prop-types';
import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import style from './textareaMessage.less';

export default class TextareaMessage extends React.PureComponent {
    static propTypes = {
        input: PropTypes.object,
        placeholder: PropTypes.string,
        required: PropTypes.bool
    };
    static defaultProps = {
        input: {},
        placeholder: '',
        required: true
    };
    state = {
        focus: false,
    };

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    render() {
        const { input, placeholder, required } = this.props;

        return (
            <TextareaAutosize
                {...input}
                innerRef={ref => this.inputRef = ref}
                placeholder={placeholder}
                className={style.textarea}
                required={required}
            />
        );
    }
}