import { getItem } from 'libs/sessionStorage';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import getAdmin from './admin';
import getChat from './chat';
import getCustomer from './customer';
import getDriver from './driver';
import getEvents from './events';
import getMap from './map';
import getNotifications from './notifications';
import getOffers from './offers';
import getProfile from './profile';
import getProfileDetail from './profileDetail';
import getSearch from './search';
import getServerHelpers from './getServerHelpers';


export const initStore = {
    form: null,
    profile: null,
    profileDetail: {
        user: undefined,
        reviews: { items: [], total: 0 },
    },
    notifications: JSON.parse(getItem('notifications', '[]')),
    map: { points: [{ key: 'A' }, { key: 'B' }], distance: 0 },
    driver: {
        routes: {
            active: { items: [], total: 0, loading: true },
            cancelled: { items: [], total: 0, loading: true },
            unpublished: { items: [], total: 0, loading: true }
        }, cars: [], carts: { meta: {}, total: 0, items: [] }, detail: undefined
    },
    customer: {
        cargoes: {
            active: { items: [], total: 0, loading: false },
            cancelled: { items: [], total: 0, loading: false }
        }, favorites: []
    },
    search: { items: [], total: 0, meta: {} },
    events: { list: [] },
    chat: { dialogs: { items: [], total: 0 }, histories: {}, selectedDialogId: undefined, countDontReadMsg: 0 },
    offers: {
        top: [],
        offerList: {
            active: { items: [], total: 0, loading: false },
            finish: { items: [], total: 0, loading: false },
            arbitrate: { items: [], total: 0, loading: false }
        },
        proposalsToMe: { items: [], total: 0, loading: false },
        proposalsFromMe: { items: [], total: 0, loading: false },
        detail: undefined,
    },
    admin: {
        drivers: { items: [], total: 0, meta: { totalToday: 0 } },
        driversVerification: { items: [], total: 0, meta: { totalToday: 0 } },
        clients: { items: [], total: 0, meta: { totalToday: 0 } },
        offers: {
            detail: { loading: true },
            all: {
                items: [], total: 0, meta: { totalToday: 0 }
            },
            moderation: {
                items: [], total: 0, meta: { totalToday: 0 }
            }
        },
        routes: {
            detail: { loading: true },
            all: {
                items: [], total: 0, meta: { totalToday: 0 }
            },
            moderation: {
                items: [], total: 0, meta: { totalToday: 0 }
            }
        },
        routesModeration: { items: [], total: 0, meta: { totalToday: 0 } },
        trades: { items: [], total: 0, meta: { totalToday: 0 } },
        arbitrations: { items: [], total: 0 },
        users: { items: [], total: 0 }, // потом удалить

        profileDetail: undefined,
        offerDetail: undefined,
        routeDetail: undefined,
        tradeDetail: undefined,
        arbitrateDetail: undefined,
    },
    serverHelpers: {
        cargoes: { list: [], meta: {}, total: 0, loading: true, },
        packages: { list: [], meta: {}, total: 0, loading: true, },
        bodyTypes: { list: [], meta: {}, total: 0, loading: true, },
        loadTypes: { list: [], meta: {}, total: 0, loading: true, },
        additionals: { list: [], meta: {}, total: 0, loading: true, },
        counters: {
            reviews: 0,
            offers: 0,
            favorites: 0,
            routes: 0,
            trades: 0,
            transports: 0,
            proposals: 0,
        }
    }
};

export const reducers = combineReducers({
    form: formReducer,
    profile: getProfile(initStore.profile),
    profileDetail: getProfileDetail(initStore.profileDetail),
    notifications: getNotifications(initStore.notifications),
    map: getMap(initStore.map),
    driver: getDriver(initStore.driver),
    customer: getCustomer(initStore.customer),
    offers: getOffers(initStore.offers),
    search: getSearch(initStore.search),
    events: getEvents(initStore.events),
    chat: getChat(initStore.chat),
    admin: getAdmin(initStore.admin),
    serverHelpers: getServerHelpers(initStore.serverHelpers)
});
