import { dateToString } from 'libs/convertDate';
import { chatActions } from 'actions/chat';
import { reset } from 'redux-form';

export const sendMessageToServer = (msg, myID, dialog, dispatch) => {
	if (msg.offerIds || msg.text) {
		let now = new Date();
		let utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

		msg.senderId = myID;
		msg.createTime = dateToString(utc, 'serverDateFullTime');
		msg.id = (new Date()).getTime();
		msg.status = 'upload';
		msg.isRead = null;
		msg.text = msg.text ? msg.text : null;
		
		let data = { dialogId: dialog.id, msg };
		
		dispatch(chatActions.addMsgToDialog(data));
		
		if (dialog.new) {
        dispatch(chatActions.createDialogForServer({ ...data, dialog }));
		} else
			dispatch(chatActions.sendMsgToServer(data));
		
		dispatch(reset('ChatForm'));
	}
	
};