import React from 'react';
import * as adminActions from 'actions/admin';
import Page from 'components/UI/Wrappers/Page';
import { getUsers } from 'libs/selections';
import { connect } from 'react-redux';
import UserItem from 'components/Admin/Pages/Users/List/UserItem';
import Table from 'components/Admin/UI/Table';
import forwardTo from 'libs/forwardTo';


class AdminUserList extends React.Component {
	
	componentWillMount() {
		this.props.dispatch(adminActions.getUsers());
	}
	
	to = id => {
		forwardTo(`/admin/user/` + id);
	};
	
	render() {
		return (
			<Page title={'Пользователи'}>
				<Table names={['№', 'Тип', 'ФИ', 'Статус верификации']}>
					{
						this.props.users !== undefined && this.props.users.total > 0 &&
						this.props.users.items.map(el => (
							<UserItem
								key={el.id}
								el={el}
								onClick={() => this.to(el.id)}
							/>
						))
					}
				</Table>
			</Page>
		)
	}
}


export default connect(
	state => ({
		users: getUsers(state),
	})
)(AdminUserList);

/*
Примерный остаточный список
1. "/driver/route/my" - по статусам
*/