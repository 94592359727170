import React from 'react';
import PropTypes from 'prop-types'
import style from './style.less';
import Aside from 'components/UI/Wrappers/Aside';
import AsideYourBid from 'components/UI/AsideItems/AsideYourBid';
import AsideItem from 'components/UI/AsideItems/AsideItem';
import AsideOfferStatus from 'components/UI/AsideItems/AsideOfferStatus';
import { connect } from 'react-redux';
import {searchActions} from 'actions/search';
import forwardTo from 'libs/forwardTo';
import { setItem } from 'libs/sessionStorage';

import mapperFromRouteToSearch from 'mappers/mapperFromRouteToSearch';
import mapperFromOfferToSearch from 'mappers/mapperFromOfferToSearch';

class Options extends React.PureComponent {

	static propTypes = {
		offer: PropTypes.object.isRequired,
		handleEdit: PropTypes.func.isRequired,
		handleEditPrice: PropTypes.func.isRequired,
		handleDelete: PropTypes.func.isRequired,
		proposals: PropTypes.number.isRequired,
	};

	params = {};
	state = {findCount: undefined};

	componentDidMount(){
      let {offer} = this.props;
      if (this.props.role === 'driver') this.params = mapperFromRouteToSearch(offer);
      else this.params = mapperFromOfferToSearch(offer);

      new Promise((resolve, reject)=>{
          this.props.dispatch(searchActions.getCountByOffer(this.params,  {resolve, reject}));
      }).then(findCount=>{
          this.setState({findCount})
      }).catch(()=>{
          this.setState({findCount: 0})
      });
	}

	getFindCount = () => this.state.findCount === undefined ? '...' : this.state.findCount;

	goToSearch = () => {
		setItem('searchParams', JSON.stringify(mapperFromOfferToSearch(this.props.offer)));
		forwardTo('/search/');
	};

	render() {
		let {offer, proposals, handleEdit, handleDelete, handleEditPrice} = this.props;
		return (
			<Aside marginBottom={30}>
				<AsideYourBid price={parseInt(offer.route.price)} edit={handleEditPrice} />
				<AsideItem url={`/offer/${offer.id}#proposals`}>Предложений<span className={style.justText}>{ proposals }</span></AsideItem>
				<AsideItem onClick={this.goToSearch}>Найдено водителей<span className={style.justText}>{ this.getFindCount() }</span></AsideItem>
				<AsideItem onClick={handleEdit}>Редактировать заявку</AsideItem>
				<AsideItem onClick={handleDelete}>Удалить заявку</AsideItem>
				<AsideOfferStatus status={offer.status || 'active'} />
			</Aside>
		);
	}
}

export default connect(

)(Options);