import AdminGrid from 'components/Admin/UI/Grid';
import { AdminLabel } from 'components/Admin/UI/Label';
import React, { Fragment } from 'react';
import { dateToString } from "libs/convertDate";

const RouteDataOffer = props => {
    const { items } = props;

    return (
        <>
        <h3> Маршрут </h3>
        <AdminGrid type="passport">
            <div>
                {
                    items.map((el, index) => {
                        return (
                            <Fragment key={index}>
                                <AdminLabel title={`${
                                    index === 0 ? 'Адрес отправления' :
                                        index === (items.length - 1) ? 'Адрес прибытия' :
                                            'Промежуточная точка'}:`
                                }>
                                    { el.address } <br/> {dateToString(new Date(el.startDate), '')}
                                </AdminLabel>
                                <AdminLabel title={index === 0 ? 'Грузоотправитель' : 'Грузополучатель'}>
                                    { el.providerName }, { el.providerPhone }
                                </AdminLabel>
                            </Fragment>
                        )
                    })
                }
            </div>
        </AdminGrid>
        </>
    )
};


export default RouteDataOffer;