import { chatActions } from 'actions/chat';
import * as customerAction from "actions/customer";
import { eventsActions } from 'actions/events';
import { add } from 'actions/notifications';
import { offersActions } from 'actions/offers';
import { dateToString } from 'libs/convertDate';
import request from 'libs/request';
import { getProfile } from 'libs/selections';
import { getItem } from "libs/sessionStorage";
import * as React from 'react';
import { eventChannel } from 'redux-saga';
import { call, put, select, take } from 'redux-saga/effects';
import wsNotifactions from 'mappers/wsNotifications';
import { driverActions } from 'actions/driver';

let ws = undefined;

export function* getDialogs() {
    const response = yield call(() =>
        request('get', '/api/1.0/dialog/dialogues'));

    if (response.data.errors || response.status !== 200) {
        console.error('Error get history chat: ', response.data.errors);
    } else {
        yield put(chatActions.setCountDontReadMsg(response.data.meta ? response.data.meta.unreadCount : 0));
        yield put(chatActions.setDialogs(response.data));
    }
}

export function* markReadMsgToServer(action) {
    const response = yield call(() =>
        request('post', `/api/1.0/dialog/${action.payload.dialogId}/mark-read`, { messageId: action.payload.msgId }));

    if (response.data.errors || response.status !== 200) {
        console.error('Error markReadMsgToServer: ', response.data.errors);
    } else {
        yield put(chatActions.minusCountDontReadMsg());
        yield put(chatActions.minusCountReadMsgForDialog(action.payload.dialogId));
        yield put(chatActions.markReadMsgToClient(action.payload));
    }
}

export function* getHistoryByID(action) {
    const response = yield call(() =>
        request('get', `/api/1.0/dialog/${action.payload.dialogId}/messages`, {
            page: action.payload.page,
            pageSize: 10,
        }));

    if (response.data.errors || response.status !== 200) {
        console.error('Error getHistory: ', response.data.errors);
        yield put(add({
            type: 'error',
            text: 'Произошла ошибка при получении диалога',
            time: 10
        }));

        action.meta && action.meta.reject();
    } else {
        if (action.type === 'chatActions/appendHistoryByID') {
            yield put(chatActions.appendHistory({
                id: action.payload.dialogId,
                data: response.data.items
            }));
            action.meta && action.meta.resolve();
        } else {
            yield put(chatActions.setHistory({
                id: action.payload.dialogId,
                data: response.data.items,
                total: response.data.total
            }));
            action.meta && action.meta.resolve();
        }
        //откроем диалог, если надо
        if (action.payload.open === true) {
            yield put(chatActions.openDialog({
                id: action.payload.dialogId,
            }));
            action.meta && action.meta.resolve();
        }
    }
}

export function* sendMsgToServer(action) {

    let msg = {
        ...action.payload.msg,
        offerIds:
            Array.isArray(action.payload.msg.offerIds) ? action.payload.msg.offerIds.map(it => it.id) : []
    };

    const response = yield call(() =>
        request('post', `/api/1.0/dialog/${action.payload.dialogId}/message`, msg));

    let now = new Date();
    let utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

    if (response.data.errors || response.status !== 200) {
        console.error('Error sendMsgToServer: ', response.data.errors);
        yield put(chatActions.acceptSendMsg({
            dialogId: action.payload.dialogId,
            clientMsgId: action.payload.msg.id,
            serverMsgId: response.data.messageId,
            msg: {
                ...action.payload.msg,
                status: 'error',
                offers: action.payload.msg.offerIds,
                createTime: dateToString(utc, 'serverDateFullTime')
            }
        }));
    } else {
        yield put(chatActions.acceptSendMsg({
            dialogId: action.payload.dialogId,
            clientMsgId: action.payload.msg.id,
            serverMsgId: response.data.messageId,
            msg: {
                ...action.payload.msg,
                id: response.data.messageId,
                status: undefined,
                offers: action.payload.msg.offerIds,
                createTime: dateToString(utc, 'serverDateFullTime')
            }
        }));
    }
}

export function* createDialogForServer(action) {
    const response = yield call(() =>
        request('post', '/api/1.0/dialog/dialog', {
            interlocutorId: action.payload.dialog.user1.id,
            text: action.payload.msg.text,
            offerIds: action.payload.msg.offerIds,
        }));

    if (response.data.errors || response.status !== 200) {
        console.error('Error createDialogForServer: ', response.data.errors);
    } else {
        yield put(chatActions.changeDialogId({
            oldId: action.payload.dialogId,
            newId: response.data.dialogId,
        }));
        yield put(chatActions.getDialogs());
        yield put(chatActions.getHistoryByID({
            dialogId: response.data.dialogId,
            page: 1,
            open: true
        }));
    }
}

export function* disconnectChat() {
    if (ws && typeof (ws.close)) ws.close();
    yield put(chatActions.clearChat());
}

function initWebsocket(profile) {
    return eventChannel(emitter => {
        ws = new WebSocket('wss://hurrylorry.com/ws?streams=user:' + profile.uid);
        ws.onopen = () => {
            // console.log('Соединение открыто');
        };
        ws.onerror = (error) => {
            console.error('Ошибка соединения', error);
        };
        ws.onclose = event => {
            if (event.wasClean) {
                console.log('Соединение закрыто чисто');
            } else {
                console.error('Обрыв соединения');
            }
            console.log('Код: ' + event.code + ' причина: ' + event.reason);
            if (event.code !== 1000) {
                setTimeout(() => initWebsocket(profile), 5000);
                return console.log('Обрыв соединения c сервером');
            }
        };
        ws.onmessage = event => {
            let msg = null,
                actions = [];
            try {
                msg = JSON.parse(event.data)
            } catch (e) {
                console.error('Error parsing :', event.data);
            }
            if (msg) {
                switch (msg.event) {
                    case 'dialog':
                        if (msg.data.sender.id !== profile.id) {
                            let text = msg.data.text;
                            const { open } = JSON.parse(getItem('chat', '{"open": "false"}'));
                            if (text !== null && text.length > 38) text = text.slice(0, 35) + '...';

                            msg.data.user2 = {
                                firstName: profile.firstName,
                                lastName: profile.lastName,
                                id: profile.id,
                            };

                            actions = [
                                chatActions.getMessageFromServer(msg.data),
                                chatActions.plusCountDontReadMsg(),
                                chatActions.plusCountReadMsgForDialog(msg.data.dialogId),
                            ];

                            if (!open)
                                actions = [...actions,
                                    add({
                                        type: 'info',
                                        text: [<b>{msg.data.sender.firstName} {msg.data.sender.lastName.slice(0, 1)}.
                                            отправил(-а) сообщение:</b>, text],
                                        time: 7
                                    })
                                ]
                        }
                        break;
                    case 'notification':
                        const { tradeId, offerId, routeId } = msg.data;
                        const { type, text } = wsNotifactions(msg.data);
                        
                        actions = [
                            add({
                                type,
                                text,
                                time: 10
                            }),
                            eventsActions.addEvents({ ...msg.data, params: msg.data })
                        ];

                        if (offerId)
                            actions = [
                                ...actions,
                                offersActions.getCargoByID(offerId),
                                customerAction.getActiveCargo({ page: getItem('active', 1) }),
                                customerAction.getCancelCargo({ page: getItem('cancelled', 1) })
                            ];
                        if (tradeId)
                            actions = [
                                ...actions,
                                offersActions.updateOfferDetailById({ tradeId }),
                                offersActions.getOffers({ page: getItem('trade.active', 1), status: 'active' }),
                                offersActions.getOffers({ page: getItem('trade.finish', 1), status: 'finish' }),
                                offersActions.getOffers({ page: getItem('trade.arbitrate', 1), status: 'arbitrate' })
                            ];
                        if (routeId)
                            actions = [
                                ...actions,
                                driverActions.getRouteByID(routeId),
                                driverActions.getRoutes({ status: 'active', page: getItem('route.active', 1) }),
                                driverActions.getRoutes({ status: 'cancelled', page: getItem('route.cancelled', 1) }),
                                driverActions.getRoutes({ status: 'unpublished', page: getItem('route.unpublished', 1) }),
                            ];

                        break;
                    case 'markRead':
                        actions = chatActions.markReadMsgToClient({
                            msgId: msg.data.messageId,
                            dialogId: msg.data.dialogId,
                        });
                        break;
                    default:
                        break;
                }
                return emitter(actions);
            }
        };
        // unsubscribe function
        return () => {
            console.log('Отключение сокета')
        }
    })
}

export function* connectToServer() {
    let profile = yield select(getProfile);
    const channel = yield call(initWebsocket, profile);

    while (true) {
        const actions = yield take(channel);
        if (Array.isArray(actions))
            for (let i = 0; i < actions.length; i++) yield put(actions[i]);
        else
            yield put(actions)
    }
}
