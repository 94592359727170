import { chatActions } from 'actions/chat';
import MessageList from 'components/Chat/center/MessageList';
import style from 'components/Chat/style.less';
import { sendMessageToServer } from 'components/Chat/submit';
import ChatForm from 'components/ReduxForms/ChatForm';
import Button from 'components/UI/Form/Button';
import { ArrowLeft, SpeechBubble } from 'components/UI/Icons';
import Avatar from 'components/UI/ProfileUI/Avatar';
import { getChatHistories, getProfile } from 'libs/selections';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';


class CenterDialog extends Component {

    static propTypes = {
        handleClose: PropTypes.func.isRequired,
        dialog: PropTypes.object
    };

    static defaultProps = {
        history: [],
    };

    state = {
        loading: false,
    };


    handleSendMsg = data => sendMessageToServer(data, this.props.profile.id, this.props.dialog, this.props.dispatch);

    loadMore = () => {
        this.setState({ loading: true });
        let { id } = this.props.dialog;
        let length = this.props.history[id].items ? this.props.history[id].items.length : 1;

        return new Promise((resolve, reject) => {
            this.props.dispatch(chatActions.appendHistoryByID({
                dialogId: id, page: Math.ceil(length / 10) + 1
            }, { resolve, reject }))
        }).then(res => {
            this.setState({ loading: false });
        }).catch(err => {
            console.log(err);
        });
    };

    render() {
        let { history, profile, dialog, customStyle } = this.props,
            messageList = { items: [], total: 0 };

        if (dialog && history[dialog.id])
            messageList = { ...history[dialog.id] };

        return (
            <div className={`${style.center__container} ${dialog ? style.mobileDialog : ''}  ${customStyle}`}>

                {
                    !dialog &&
                    <div className={style.notSelectDialog}>
                        <SpeechBubble/>
                        <p>
                            Чтобы начать диалог, выберите пользователя из списка
                            {profile.role === 'customer' ? ' перевозчиков' : ' клиентов'}
                        </p>
                    </div>
                }

                {
                    dialog &&
                    <Fragment>

                        <div className={style.mobileChatHeader}>
                            <Button
                                onClick={this.closeDialog}
                                clearStyle
                            >
                                {ArrowLeft(style.arrow)}
                            </Button>
                            <Avatar size={34} src={dialog.user1.photo} className={style.avatar}/>
                            {dialog.user1.firstName} {dialog.user1.lastName}
                        </div>

                        <MessageList
                            list={messageList}
                            dialog={dialog}
                            profile={profile}
                            handleClose={this.props.handleClose}
                            loadMore={this.loadMore}
                            loading={this.state.loading}
                        />

                        <ChatForm
                            isArbitrate={dialog.arbitrationId !== null}
                            onSubmit={this.handleSendMsg}
                            handleClose={this.props.handleClose}
                            role={profile.role}
                        />

                    </Fragment>
                }

            </div>
        );
    }
}


export default connect(
    state => ({
        history: getChatHistories(state),
        profile: getProfile(state),
    })
)(CenterDialog);