import { createActions } from 'redux-actions';

export const {
    customerActions: {
        sendCargoRequest,
        changePriceCargoRequest,
        rmCargoRequest,
        setMyCargoes,
        getCargoByID,
        getMyCargoes,
        getCargoByUserId,
        getFavoriteDrivers,
        setFavoriteDrivers,
        addToFavorite,
        addToFavoriteFromServer,
        rmFavoriteById,
        addProposalToRoute,
        setActiveCargo,
        setCancelCargo,
        getActiveCargo,
        getCancelCargo,
        loadingActiveCargo,
        loadingCancelCargo,
    }
} = createActions({
    customerActions: {
        sendCargoRequest: [(payload) => payload, (payload, meta) => meta],
        changePriceCargoRequest: [(payload) => payload, (payload, meta) => meta],
        rmCargoRequest: [(payload) => payload, (payload, meta) => meta],
        getMyCargoes: [(payload) => payload, (payload, meta) => meta],
        setMyCargoes: [(payload) => payload, (payload, meta) => meta],
        getCargoByID: [(payload) => payload, (payload, meta) => meta],
        getCargoByUserId: [(payload) => payload, (payload, meta) => meta],
        getFavoriteDrivers: [() => null, (payload, meta) => meta],
        setFavoriteDrivers: [(payload) => payload, (payload, meta) => meta],
        rmFavoriteById: [(payload) => payload, (payload, meta) => meta],
        addToFavorite: [(payload) => payload, (payload, meta) => meta],
        addToFavoriteFromServer: [(payload) => payload, (payload, meta) => meta],
        addProposalToRoute: [(payload) => payload, (payload, meta) => meta],

        getActiveCargo: [(payload) => payload, (payload, meta) => meta],
        getCancelCargo: [(payload) => payload, (payload, meta) => meta],

        setActiveCargo: [(payload) => payload, (payload, meta) => meta],
        setCancelCargo: [(payload) => payload, (payload, meta) => meta],

        loadingActiveCargo: [(payload) => payload, (payload, meta) => meta],
        loadingCancelCargo: [(payload) => payload, (payload, meta) => meta],
    }
});