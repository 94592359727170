exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1tfxHPRRSGLPgWxDe5xt51 {\n  background: #dfe6ef;\n  padding: 10px;\n  border-radius: 4px;\n  display: flex;\n}\n._2XLFSb0Z0dUzvp6BsDCK7F {\n  padding-left: 12px;\n  padding-right: 1px;\n}\n._2XLFSb0Z0dUzvp6BsDCK7F svg {\n  width: 20px;\n  fill: #555;\n}\n._2XLFSb0Z0dUzvp6BsDCK7F:hover svg {\n  fill: #111;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/Admin/ReduxForms/SearchAdminForm/style.less"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,cAAc;EACd,mBAAmB;EACnB,cAAc;CACf;AACD;EACE,mBAAmB;EACnB,mBAAmB;CACpB;AACD;EACE,YAAY;EACZ,WAAW;CACZ;AACD;EACE,WAAW;CACZ","file":"style.less","sourcesContent":[".form {\n  background: #dfe6ef;\n  padding: 10px;\n  border-radius: 4px;\n  display: flex;\n}\n.submit {\n  padding-left: 12px;\n  padding-right: 1px;\n}\n.submit svg {\n  width: 20px;\n  fill: #555;\n}\n.submit:hover svg {\n  fill: #111;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"form": "_1tfxHPRRSGLPgWxDe5xt51",
	"submit": "_2XLFSb0Z0dUzvp6BsDCK7F"
};