import React from 'react';
import PropTypes from 'prop-types';
import { LabelError } from 'components/UI/Form/Labels';
import style from './style.less';

export default class Textarea extends React.PureComponent {
	state = {
		focus: false,
	};

	inputRef = undefined;

	static propTypes = {
		input: PropTypes.object,
		meta: PropTypes.object,
		label: PropTypes.string,
		placeholder: PropTypes.string,
		classNameContainer: PropTypes.string,
		classNameInput: PropTypes.string,
	};

	static defaultProps = {
		input: {},
		meta: {},
		label: '',
		placeholder: '',
		classNameContainer: '',
		classNameInput: '',
	};

	componentDidMount() {
		this.inputRef.addEventListener('focus', this.handleFocus, false);
		this.inputRef.addEventListener('blur', this.handleBlur, false);
	}

	componentWillUnmount() {
		this.inputRef.removeEventListener('focus', this.handleFocus, false);
		this.inputRef.removeEventListener('blur', this.handleBlur, false);
	}

	handleFocus = event => {
		this.setState({ focus: true });
	};

	handleBlur = event => {
		this.setState({ focus: false });
	};

	render() {
		const { input, meta, label, placeholder, classNameContainer, classNameInput } = this.props;
		const { focus } = this.state;

		const container = `
			${style.field__flex_container} ${style.field__flex_container_textarea} ${classNameContainer} 
			${focus ? style.focus : ''}
			${input.value ? style.not_empty : ''}
			${meta.error ? style.error : ''}
		`;
		const container_input = `${style.field__input} ${classNameInput}`;

		return (
			<React.Fragment>
				<div className={container}>
					<div className={container_input}>
						<textarea
							{...input}
							ref={ref => this.inputRef = ref}
							placeholder={placeholder}
							className={input.value ? style.fieldNotEmpty : ''}
						/>
						<label>{ label }</label>
					</div>
					{
						meta.error
						&& <div className={`${style.show_state} ${style.show_error}`}>!</div>
					}
				</div>
				{
					meta.error
					&& <LabelError message={meta.error} />
				}
			</React.Fragment>
		);
	}
}