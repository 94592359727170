import React from 'react';
import PropTypes from 'prop-types';
import * as ReactDOM from 'react-dom';
import Button from '../Form/Button';
import { Close, ArrowLeft, ArrowRight } from '../Icons';
import style from './style.less';

class Gallery extends React.PureComponent {
	state = {target: this.props.target,};

	static propTypes = {
		images: PropTypes.array,
		target: PropTypes.string.isRequired,
		handleClose: PropTypes.func.isRequired,
	};
	static defaultProps = {
		images: [],
	};

	toggle = action => {
		let { images } = this.props;
		let index = images.indexOf(this.state.target);
		if (index === -1) return false;
		let nextIndex = index + (action === 'next' ? 1 : -1);
		this.setState({target: images[nextIndex] ? images[nextIndex] : images[(action === 'next' ? 0 : images.length - 1)]});
	};

	render() {
		let { handleClose } = this.props;

		return ReactDOM.createPortal(
			<div className={style.gallery}>
				{
					this.props.images.length > 1
					&&
					<React.Fragment>
						<Button onClick={() => this.toggle('prev')} className={`${style.arrow} ${style.prev}`} size="small">
							{ ArrowLeft() }
						</Button>
						<Button onClick={() => this.toggle('next')} className={`${style.arrow} ${style.next}`} size="small">
							{ ArrowRight() }
						</Button>
					</React.Fragment>
				}
				<div className={style.content}>
					<Button onClick={handleClose} className={style.close}>{ Close() }</Button>
					<img src={this.state.target} alt=""/>
				</div>
			</div>,
			document.getElementById('gallery')
		);
	}
}

export default Gallery;