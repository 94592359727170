import { offersActions } from 'actions/offers';
import { statusList } from "libs/vars";
import handleActions from 'redux-actions/es/handleActions';

const getOffers = initStore => handleActions({
    [offersActions.setOffers]: (state, action) => {
        if (action.meta && action.meta.status) return {
            ...state,
            offerList: { ...state.offerList, [action.meta.status]: action.payload }
        };
        let offerList = {};
        if (Array.isArray(action.payload)) {
            action.payload.forEach(it => {
                for (let key in statusList) {

                    if (statusList[key].indexOf(it.status) !== -1) {
                        if (Array.isArray(offerList[key])) offerList[key].push(it);
                        else offerList[key] = [it];
                    }
                }
            });
            return { ...state, offerList };
        }
        return state;
    },

    [offersActions.setActiveOffers]: (state, action) => ({
        ...state,
        offerList: { ...state.offerList, active: { ...action.payload, loading: false } }
    }),
    [offersActions.setFinishOffers]: (state, action) => ({
        ...state,
        offerList: { ...state.offerList, finish: { ...action.payload, loading: false } }
    }),
    [offersActions.setArbitrateOffers]: (state, action) => ({
        ...state,
        offerList: { ...state.offerList, arbitrate: { ...action.payload, loading: false } }
    }),
    [offersActions.loadingActiveOffers]: (state, action) => ({
        ...state,
        offerList: { ...state.offerList, active: { ...state.offerList.active, loading: true } }
    }),
    [offersActions.loadingFinishOffers]: (state, action) => ({
        ...state,
        offerList: { ...state.offerList, finish: { ...state.offerList.finish, loading: true } }
    }),
    [offersActions.loadingArbitrateOffers]: (state, action) => ({
        ...state,
        offerList: { ...state.offerList, arbitrate: { ...state.offerList.arbitrate, loading: true } }
    }),
    [offersActions.setProposalsFromMe]: (state, action) => ({ ...state, proposalsFromMe: action.payload }),
    [offersActions.setDetailOffer]: (state, action) => ({ ...state, detail: action.payload }),
    [offersActions.setTopOffers]: (state, action) => ({ ...state, top: action.payload }),
}, initStore);

export default getOffers;