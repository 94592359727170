import React from 'react';
import style from 'components/Admin/UI/Layout/style.less';
import AdminHeader from 'components/Admin/UI/Header';
import AdminMenu from 'components/Admin/UI/Menu';


const AdminLayout = props => (
	<div className={style.adminWrapper}>
		<AdminHeader />
		<AdminMenu />
		{ props.children }
	</div>
);


export default AdminLayout;