import React from 'react';
import Panel from 'components/UI/Wrappers/Panel';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import SelectGroup from 'components/UI/Form/Fields/SelectGroup';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import InputSelectGroup from 'components/UI/Wrappers/InputSelectGroup';
import Select from 'components/UI/Form/Fields/Select';
import Input from 'components/UI/Form/Fields/Input';
import Grid from 'components/UI/Wrappers/Grid';
import { Field } from 'redux-form';
import Dimensions from 'components/ReduxForms/Dimensions';
import style from './style.less';
import { NumberNormalize, NumberNormalizeSlice } from 'libs/normalize';


const Cargo = props => (
	<Panel className={style.afterPanel} title="Информация о грузе" marginBottom={30}>
		<span>Выберите значение из списка, используя поиск по буквам</span>
		<LayoutField className={style.width75}>
			<Field
				name={`cargo[category]`}
				component={Select}
				label="Тип груза"
			>
				{
					props.categoryList.map((el) => (
						<OptionSelect value={el.id} key={el.id}>
							{ el.titleRu }
						</OptionSelect>
					))
				}
			</Field>
		</LayoutField>
		<LayoutField>
			<Field
				name="cargo[title]"
				component={Input}
				left={100}
				label="Название товара"
			/>
		</LayoutField>
		<Grid col="form-3">
			<LayoutField>
				<InputSelectGroup>
					<Field
						name="cargo[weight]"
						component={Input}
						label="Вес"
						containerGroup="right"
						normalize={NumberNormalize}
					/>
					<Field
						name="cargo[weightType]"
						component={Select}
						childGroup="right"
					>
						<OptionSelect value={1000}>т.</OptionSelect>
						<OptionSelect value={1}>кг.</OptionSelect>
					</Field>
				</InputSelectGroup>
			</LayoutField>
			<LayoutField>
				<Field
					name="cargo[weightCount]"
					component={Input}
					label="Грузовых мест"
					normalize={NumberNormalizeSlice}
				/>
			</LayoutField>
			<LayoutField>
				<Field
					name="cargo[package]"
					component={Select}
					label="Упаковка"
				>
					{
						props.packages.map((el) => (
							<OptionSelect value={el.id} key={el.id}>
								{ el.titleRu }
							</OptionSelect>
						))
					}
				</Field>
			</LayoutField>
		</Grid>

		<span>Габариты с упаковкой</span>
		<Dimensions name={'volume'} />

		<LayoutField className={style.width75}>
			<Field
				type="text"
				name="cargo[additionals]"
				component={SelectGroup}
				label="Дополнительные услуги"
				filter
			>
				{
					props.additionals.map((el) => (
						<OptionSelect value={el.id} key={el.id} filterStr={el.titleRu}>
							{ el.titleRu }
						</OptionSelect>
					))
				}
			</Field>
		</LayoutField>

		<div className={style.separator} />

		<span>Параметры транспорта</span>
		<Grid col="form-2">
			<LayoutField>
				<Field
					name="carBodyType"
					component={Select}
					label="Тип кузова"
				>
					{
						props.bodyTypes.map((el) => (
							<OptionSelect value={el.id} key={el.id}>
								{ el.titleRu }
							</OptionSelect>
						))
					}
				</Field>
			</LayoutField>
			<LayoutField>
				<Field
					name="carLoadType"
					component={Select}
					label="Тип погрузки"
				>
					{
						props.loadTypes.map((el) => (
							<OptionSelect value={el.id} key={el.id}>
								{ el.titleRu }
							</OptionSelect>
						))
					}
				</Field>
			</LayoutField>
		</Grid>
	</Panel>
);

export default Cargo;
