import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Textarea from 'components/UI/Form/Fields/Textarea';
import Select from 'components/UI/Form/Fields/Select';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import Button from 'components/UI/Form/Button';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import { compose } from 'redux';

class VerifyUserForm extends Component {
	
	static propTypes = {
		handleSubmit: PropTypes.func,
		error: PropTypes.string,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
	};
	
	static defaultProps = {
		handleSubmit: () => {},
		invalid: false,
		submitting: false,
		error: '',
	};
	
	render() {
		const { handleSubmit } = this.props;
		
		return (
			<form onSubmit={handleSubmit} autoComplete="off">
				<LayoutField>
					<Field
						name="isProfileVerification"
						component={Select}
					>
						<OptionSelect value="0">Отклонить верификацию</OptionSelect>
						<OptionSelect value="1">Одобрить верификацию</OptionSelect>
					</Field>
				</LayoutField>
				<LayoutField>
					<Field
						name="message"
						component={Textarea}
						label="Ваше сообщение для пользователя (* если будет отказ)"
					/>
				</LayoutField>
				<Button
					type="submit"
					color="green"
				>
					Готово
				</Button>
			</form>
		);
	}
}

export default compose(
	reduxForm({
		form: 'verifyUser',
	}),
)(VerifyUserForm);