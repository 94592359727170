export default function languagesForDataPicker() {
	window.__localeData__= {
		ru: {
			formatLong: {},
			localize: {
				day: function(index, key){
					const data = {
						narrow: ['В', 'П', 'В', 'С', 'Ч', 'П', 'С'],
						short: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
						abbreviated: ['Вск', 'Пнд', 'Втр', 'Срд', 'Чет', 'Пят', 'Суб'],
						wide: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота']
					};
					return data[key.width][index];
				},
				month: function(index, key){
					const data = {
						narrow: ['Я', 'Ф', 'М', 'А', 'М', 'И', 'И', 'А', 'С', 'О', 'Н', 'Д'],
						abbreviated: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
						wide: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
					};
					return data[key.width][index];
				}
			}
		},
		en: {
			formatLong: {},
			localize: {
				day: function(index, key) {
					const data = {
						narrow: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
						short: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
						abbreviated: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
						wide: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
					};
					return data[key.width][index];
				},
				month: function(index, key) {
					const data = {
						narrow: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
						abbreviated: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
						wide: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
					};
					return data[key.width][index];
				}
			}
		}
	};
}