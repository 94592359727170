import React, { Component } from 'react';
import AdminPage from 'components/Admin/UI/Page';
import AdminGrid from 'components/Admin/UI/Grid';
import SearchAdminForm from 'components/Admin/ReduxForms/SearchAdminForm';
import AdminList from 'components/Admin/UI/List';
import AdminPagination from 'components/Admin/UI/Pagination';
import LeftMenu from 'components/Admin/Pages/Offers/List/LeftMenu';
import { convertNumberSpaces } from 'libs/convertNumberSpaces';
import { connect } from 'react-redux';
import UpdateButton from 'components/Admin/UI/UpdateButton';
import OfferContent from 'components/Admin/Pages/Offers/List/OfferContent';
import { getOffersState } from 'components/Admin/getStore';
import * as adminActions from "actions/admin";
import { statusListOffers } from 'libs/vars';


class AdminOfferList extends Component {
	
	state = {
		loading: false,
		params: {
			page: 1,
			pageSize: 10,
			status: 'all',
		}
	};

	getOffers = status => {
		this.props.dispatch(adminActions.getOffers({
			status: statusListOffers[status],
			page: 1,
			pageSize: 30,
			type: status
		}));
		this.setState({ params: { ...this.state.params, status } })
	};
	
	setPage = page => {
		const { params: { status, pageSize } } = this.state;
		this.setState({ params: { ...this.state.params, page } });
		this.props.dispatch(adminActions.getOffers({ status: statusListOffers[status], page, pageSize, type: status }));
	};
	
	componentWillMount() {
		this.getOffers('moderation');
		this.getOffers('all');
	}
	
	render() {
		
		const { offers } = this.props,
			{ params: { status, page } } = this.state;
		
		const titles = ['ID', 'Маршрут', 'Информация', 'Статус'];
		let pageCount = Math.ceil(offers[status].total / 30);


		let list = [
			{
				onClick: () => this.getOffers('all'),
				isActive: status === 'all',
				title: 'Список',
				count: convertNumberSpaces(offers.all.total),
				newCount: convertNumberSpaces(offers.all.meta.totalToday)
			},
			{
				onClick: () => this.getOffers('moderation'),
				isActive: status === 'moderation',
				title: 'Модерация',
				count: convertNumberSpaces(offers.moderation.total),
				newCount: convertNumberSpaces(offers.moderation.meta.totalToday)
			},
		];

		return (
			<AdminPage>
				
				<LeftMenu
					list={list}
				/>
				
				<div>
					<AdminGrid type="mainContent">
						<h1>
							Список заявок
							<UpdateButton
								onClick={this.loadOffers}
							/>
						</h1>
						
						<SearchAdminForm />
						
						<AdminList
							titles={titles}
							type="offers"
						>
							<OfferContent
								list={offers[status] ? offers[status] : false}
								loading={this.state.loading}
								setOfferStatus={this.setOfferStatus}
							/>
							{
								pageCount > 1 &&
								<AdminPagination
									pageCount={pageCount}
									activePage={page}
									setPage={e => this.setPage(e)}
								/>
							}
						</AdminList>
						
					</AdminGrid>
				</div>
				
			</AdminPage>
		);
	}
}


export default connect(
	state => ({
		offers: getOffersState(state),
	})
)(AdminOfferList);