import { LabelError } from 'components/UI/Form/Labels';
import { CrossOut } from 'components/UI/Icons';
import getLocationByName from 'libs/getLocationByName';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../Button/index';
import style from './style.less';

export default class AddressGroup extends React.PureComponent {
    static propTypes = {
        label: PropTypes.string,
        input: PropTypes.object,
        meta: PropTypes.object,
        classNameContainer: PropTypes.string,
    };
    static defaultProps = {
        label: '',
        input: {},
        meta: {},
        classNameContainer: '',
    };
    state = {
        options: [],
        focus: false,
        selected: this.props.input.value ? this.props.input.value : [],
        value: '',
    };
    containerRef = undefined;
    handleHideList = event => {
        if (this.containerRef && !this.containerRef.contains(event.target)) {
            this.setState({ focus: false });
        }
    };
    handleFocus = () => {
        this.setState({ focus: true });
    };
    handleInput = event => {
        let name = event.target.value;
        this.setState({ value: name });
        if (name) {
            getLocationByName(name, res => this.setState({ focus: true, options: res }));
        } else {
            this.setState({ options: [], value: name });
        }
    };
    handleSelectOption = option => {
        const components = option.metaDataProperty.GeocoderMetaData.Address.Components;
        const { CountryName, AdministrativeArea } = option.metaDataProperty.GeocoderMetaData.AddressDetails.Country;
        let locality = '';
        let city = null;
        components.forEach(el => {
            if (el.kind === 'locality') city = el.name;
            if (el.kind === 'province' || el.kind === 'locality') locality = el.name;
        });

        let value = {
                pos: option.Point.pos,
                name: option.name + ' ' + option.description,
                region: AdministrativeArea.AdministrativeAreaName,
                locality,
                country: CountryName,
                city
            },
            newSelected = [
                ...this.state.selected,
                value
            ];
        this.props.input.onChange(newSelected);
        this.setState({
            focus: false,
            value: '',
            selected: newSelected
        });
    };
    handleRemove = option => {
        let newSelected = [...this.state.selected];

        newSelected.splice(newSelected.indexOf(option), 1);

        this.props.input.onChange(newSelected);
        this.setState({ selected: newSelected });
    };

    componentDidMount() {
        document.addEventListener('click', this.handleHideList, false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleHideList, false);
    }

    render() {
        const { input, meta, label, classNameContainer } = this.props;
        const { options, focus, selected, value } = this.state;

        const container = `${style.field__flex_container} ${classNameContainer} ${value ? style.not_empty : ''} 
		${focus ? style.focus : ''} ${meta.error ? style.error : ''}`;
        const errorCheck = meta.error !== null && meta.error !== undefined;
        return (
            <React.Fragment>
                <div className={container} ref={ref => this.containerRef = ref}>
                    <div className={style.field__input}>
                        <input
                            {...input}
                            value={value}
                            onClick={this.handleFocus}
                            onInput={this.handleInput}
                            onChange={this.handleInput}
                            type={'text'}
                        />
                        <label>{label}</label>
                    </div>
                    {
                        meta.error
                        && <div className={`${style.show_state} ${style.rIcon} ${style.show_error}`}>!</div>
                    }
                    {
                        options.length > 0 &&
                        <div className={style.list}>
                            {
                                options.map(opt => (
                                    <div
                                        key={opt.Point.pos}
                                        onClick={() => this.handleSelectOption(opt)}
                                        className={style.mapList}
                                    >
                                        {opt.name}
                                        <span>{opt.description}</span>
                                    </div>
                                ))
                            }
                        </div>
                    }
                </div>
                <div className={style.multiselectList}>
                    {
                        selected.map((option, i) => {
                            if (option === undefined) return null;
                            return (
                                <div className={style.multiselectItem} key={i}>
                                    {option.name}
                                    <Button className={style.close} onClick={() => this.handleRemove(option)}>
                                        {CrossOut()}
                                    </Button>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    meta.error
                    && <LabelError message={meta.error} isOn={errorCheck}/>
                }
            </React.Fragment>
        );
    }
}