import React from 'react';
import language from 'language';
import style from 'components/Profile/ProfileView/data.less';
import Avatar from 'components/UI/ProfileUI/Avatar';
import Rating from 'components/UI/ProfileUI/Rating';
import Button from 'components/UI/Form/Button';
import { Comment } from 'components/UI/Icons';
import AboutRow from 'components/Profile/ProfileView/AboutRow';


const ProfileCustomer = props => {
	
	return (
		<div className={style.profile_data}>
			
			<Avatar
				src={props.profile.photo}
				className={style.profile_data_avatar}
				isCircle={false}
				size={270}
			/>
			
			<div className={style.profile_data_content}>
				<div className={style.top_info}>
					
					<div className={style.top_info__offers}>
						Создано заявок { props.offersCount ? props.offersCount : 0 }
					</div>
					
					{
						(props.reviewsCount === 0 || props.reviewsCount === null) &&
						<p className={style.top_info__rating}>Отзывы отсутствуют</p>
					}
					
					{
						props.reviewsCount > 0 &&
						<Rating
							className={style.top_info__rating}
							count={props.rating}
							reviews={{count: props.reviewsCount, url: 'reviews'}}
						/>
					}
					
					<div className={style.actions}>
						<Button
							className={style.actions_send}
							onClick={props.toggleChat}
						>
							{ Comment() } Написать
						</Button>
					</div>
					
					<div className={style.bottom_info}>
						<h4>{ language.AboutCustomer }</h4>
						<div className={style.table}>
							<AboutRow title="Email">{ props.email }</AboutRow>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
};


export default ProfileCustomer;