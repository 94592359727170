import React from 'react';
import style from './style.less';
import PropTypes from 'prop-types';
import Header from 'components/UI/Header';
import Footer from 'components/UI/Footer';
import HeaderMainMenu from 'components/UI/HeaderMainMenu';
import AdminLayout from 'components/Admin/UI/Layout';
import { withRouter } from 'react-router-dom';
import NotVerifyAccountMsg from 'components/UI/NotVerifyAccountMsg';

const url = ['/registration/customer','/registration/customer/','/registration/driver','/registration/driver','/auth','/auth/','/recovery','/recovery/', '/confirm-phone-after-offer/','/confirm-phone-after-offer'];

const MainLayout = props => {

	if (props.role === 'admin')
		return <AdminLayout>{ props.children }</AdminLayout>;

	
	const setFlex = () => {
		return url.indexOf(props.location.pathname) !== -1
	};
	
	return (
		<div className={style['main-container']}>
			<Header />
			<HeaderMainMenu />
			<NotVerifyAccountMsg />
			<div className={`${style['main-content']} ${setFlex() ? style.flexForChildren : ''}`}>
				{ props.children }
			</div>
			<Footer />
		</div>
	)
};

MainLayout.propTypes = {
	children: PropTypes.node,
	role: PropTypes.string,
};
MainLayout.defaultProps = {
	children: '',
	role: 'guest',
};

export default withRouter(MainLayout);
