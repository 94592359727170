import * as adminActions from "actions/admin";
import handleActions from 'redux-actions/es/handleActions';

const getAdmin = (initStore) => handleActions({
    [adminActions.setDrivers]: (state, action) => ({
        ...state,
        drivers: action.payload,
    }),
    [adminActions.setDriversVerification]: (state, action) => ({
        ...state,
        driversVerification: action.payload,
    }),
    [adminActions.setClients]: (state, action) => ({
        ...state,
        clients: action.payload,
    }),
    [adminActions.setOffers]: (state, action) => ({
        ...state,
        offers: {
            ...state.offers,
            [action.payload.type]: action.payload
        }
    }),
    [adminActions.setOfferByID]: (state, action) => ({
        ...state,
        offers: {
            ...state.offers,
            detail: { ...action.payload, loading: false }
        }
    }),
    [adminActions.loadingOfferByID]: (state, action) => ({
        ...state,
        offers: {
            ...state.offers,
            detail: { ...state.offers.detail, loading: true }
        }
    }),
    [adminActions.setRoutes]: (state, action) => ({
        ...state,
        routes: {
            ...state.routes,
            [action.payload.type]: action.payload
        }
    }),
    [adminActions.setRoutesModeration]: (state, action) => ({
        ...state,
        routesModeration: action.payload
    }),
    [adminActions.setTrades]: (state, action) => ({
        ...state,
        trades: action.payload
    }),
    [adminActions.setArbitrations]: (state, action) => ({
        ...state,
        arbitrations: action.payload
    }),
    // [adminActions]: (state, action) => ({
    //     ...state,
    // }),
    [adminActions.changeOfferStatus]: (state, action) => ({
        ...state,
        offers: {
            ...state.offers,
            detail: { ...state.offers.detail, status: action.payload.status }
        }
    }),
    [adminActions.setRouteByID]: (state, action) => ({
        ...state,
        routes: {
            ...state.routes,
            detail: { ...action.payload, loading: false }
        }
    }),
    [adminActions.loadingRouteByID]: (state, action) => ({
        ...state,
        routes: {
            ...state.routes,
            detail: { ...state.routes.detail, loading: true }
        }
    }),
    [adminActions.changeRouteStatus]: (state, action) => ({
        ...state,
        routes: {
            ...state.routes,
            detail: { ...state.routes.detail, status: action.payload.status }
        }
    }),
    [adminActions.setArbitrateByID]: (state, action) => ({
        ...state,
        arbitrateDetail: action.payload
    }),
    [adminActions.setUsers]: (state, action) => ({
        ...state,
        users: action.payload
    }),
    [adminActions.setUserByID]: (state, action) => ({
        ...state,
        profileDetail: action.payload
    }),
}, initStore);

export default getAdmin;