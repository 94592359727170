import React from 'react';
import Panel from 'components/UI/Wrappers/Panel';
import Point from 'components/UI/Point';
import getTimeInterval from 'libs/getTimeInterval';
import { dateToString, serverDateToClientDate } from 'libs/convertDate';
import { LabelInfo } from 'components/UI/Form/Labels';
import { convertPhone } from 'libs/convertNumberSpaces';
import style from './style.less';

const InfoPointOther = props => {
	const ABC = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	let otherPoints = props.route;

	return (
		<Panel title="Информация о разгрузке" marginBottom={30}>
			{otherPoints.map((el, i) => {
				let provider = [];
				let timeInterval = getTimeInterval(el.timeIntervalLoad);
				if(el.providerName) provider.push(<span key="name">{el.providerName}</span>);
				if(el.providerPhone) provider.push(
					<span key="phone">
						{provider.length > 0 && ', '}
						{ convertPhone(el.phone_code + el.providerPhone) }
					</span>
				);
				return (
					<div key={i} className={style.onloadPointOther}>
						<Point point={ABC.charAt(i + 1)} className={style.pointC}/>
						<LabelInfo title="Адрес рагрузки">{el.address.name}</LabelInfo>
						<LabelInfo title="Доставить до">{dateToString(serverDateToClientDate(el.startDate), 'default')}, { timeInterval.toLowerCase() }</LabelInfo>
						{provider.length > 0 &&
							<LabelInfo title="Грузополучатель">{provider}</LabelInfo>
						}
					</div>
				)
			})}
		</Panel>
	)
};

export default InfoPointOther;