import React from 'react';
import style from './style.less';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AddSong, Login } from 'components/UI/Icons';
import Button from 'components/UI/Form/Button';
import { getProfileType, getProfile } from 'libs/selections';
import MobileButton from 'components/UI/Header/mobilebutton';
import HeaderProfile from 'components/UI/HeaderProfile';

class Tools extends React.PureComponent {

	static propTypes = {
		open: PropTypes.bool.isRequired,
		onClick: PropTypes.func.isRequired,
	};

	render() {
		const { profile, role, isCustomStyles, open, dispatch, onClick, closeMenu } = this.props;
		let renderClassName = style.tools;
		if (isCustomStyles) renderClassName += ' ' + style.toolsCustom;
		return (
			<div className={renderClassName}>

				<Link onClick={closeMenu} to={`/${role === 'driver' ? 'new/route' : 'new/offer'}/`}>
					<Button className={style.btnadd}>
						{ AddSong() }<span>Добавить</span>{ role === 'driver' ? 'маршрут' : 'груз' }
					</Button>
				</Link>

				{
					!profile &&
					<Link onClick={closeMenu} to={`/auth`}>
						<Button color={'white'} className={style.login}>{ Login() }<span>Войти</span></Button>
					</Link>
				}

				{
					profile &&
					<HeaderProfile dispatch={dispatch} className={style.headerProfile} />
				}

				<MobileButton onClick={onClick} open={open} className={style.menu} />
			</div>
		);
	}
}

export default connect(
	store => ({
		profile: getProfile(store),
		role: getProfileType(store),
	})
)(Tools);
