import { driverActions } from 'actions/driver';
import { add } from 'actions/notifications';
import { dateToString } from 'libs/convertDate';
import showError from 'libs/showFormErrors';
import { required } from 'libs/validationsForms';
import driverOfferToServer from 'mappers/driverOfferToServer';
import { SubmissionError } from 'redux-form';

export function submitCard(values, hideDialog, dispatch) {
    showError({
        fullName: [required(values.fullName)],
        cardNumber: [required(values.cardNumber)],
        issueDate: [required(values.issueDate)],
    });

    values.issueDate = dateToString(values.issueDate, 'server');

    return new Promise((resolve, reject) => {
        dispatch(driverActions.addCard(values, {
            resolve: resolve,
            reject: reject,
        }));
    }).then(() => {
        dispatch(add({
            type: 'success',
            text: 'Карта успешно сохранена',
            time: 10
        }));
        hideDialog();
    }).catch(err => {
        dispatch(add({
            type: 'error',
            text: 'Произошла ошибка при сохранении карты',
            time: 10
        }));
        throw new SubmissionError({ ...err, _error: 'bad' })
    });
}

export function submitEditedCard(values, hideDialog, dispatch) {
    showError({
        fullName: [required(values.fullName)],
        cardNumber: [required(values.cardNumber)],
        issueDate: [required(values.issueDate)],
    });

    values.issueDate = dateToString(values.issueDate, 'server');

    return new Promise((resolve, reject) => {
        dispatch(driverActions.addCard(values, {
            resolve: resolve,
            reject: reject,
        }));
    }).then(() => {
        dispatch(add({
            type: 'success',
            text: 'Изменения карты сохранены',
            time: 10
        }));
        hideDialog();
    }).catch(err => {
        dispatch(add({
            type: 'error',
            text: 'Произошла ошибка сохранения изменений карты',
            time: 10
        }));
        throw new SubmissionError({ ...err, _error: 'bad' })
    });
}

export function submitDriverOffer(values, dispatch, success) {
    showError({
        message: [required(values.message)],
        price: [required(values.price)],
        payMethodId: [required(values.payMethodId)],
        user2Id: [required(values.user2Id)],
        offerId: [required(values.offerId)],
        transportId: [required(values.transportId)],
    });
    dispatch(driverActions.sendProposal(driverOfferToServer(values), { success }));
}

export function mappingDataAfterSendToServer(form) {
    let values = { ...form };

    if (form.point_a && form.point_a.pos) {
        let fromPos = form.point_a.pos.split(' ');

        values.fromLatitude = fromPos[0];
        values.fromLongitude = fromPos[1];
        values.fromCountry = form.point_a.country;
        values.fromRegion = form.point_a.region;
        values.fromCity = form.point_a.locality;
    }

    if (form.point_b && form.point_b.pos) {
        let toPos = form.point_b.pos.split(' ');

        values.toLatitude = toPos[0];
        values.toLongitude = toPos[1];
        values.toCountry = form.point_b.country;
        values.toRegion = form.point_b.region;
        values.toCity = form.point_b.locality;
    }

    if (values.weightType && values.weightType === 1) values.weight = values.weight / 1000;
    if (values.startDate) values.startDate = dateToString(values.startDate, 'serverDate');
    if (values.volume) values.size = values.volume;

    delete values.point_a;
    delete values.point_b;

    return values;
}