import React from 'react';
import { connect } from 'react-redux';
import { getPoints } from 'libs/selections';
import PropTypes from 'prop-types';
import style from './route.less';
import { getCar } from 'libs/getInfoRoute';
import RequestPoints from 'components/UI/Request/RequestPoints';
import Button from 'components/UI/Form/Button';
import Maps from 'components/UI/Maps';
import { ArrowDownNavigate } from 'components/UI/Icons';


class DriverRoute extends React.Component {

	static propTypes = {
		route: PropTypes.object.isRequired,
		openMapID: PropTypes.number,
		onClick: PropTypes.func,
	};

	static defaultProps = {
		openMapID: 0,
		onClick: () => {},
	};

	render() {
		const { route, openMapID, onClick } = this.props;

		let title = 'Желаемый маршрут';
		if (route.type === 'planned') title = 'Запланированный маршрут';

		const openMap = openMapID === route.id;
		const transport = getCar(route.transport);

		if (route.type === 'desired' && route.geography !== 'country') {
			route.items[0].expireDays = null;
			route.items[0].startDate = null;
		}


		return (
			<div className={style.route}>
				<div className={style.header}>
					<div>{ title }</div>
					<div className={style.transport}>{ transport }</div>
				</div>
				<div className={style.body}>
					<RequestPoints points={route.items} type={1} />
				</div>
				<div className={`${style.map} ${openMap ? style.map_open : ''}`}>
					{
						openMap &&
						<Maps layout={'driver_route'} />
					}
				</div>
				<div className={style.footer}>
					<Button className={openMap ? style.open : ''} onClick={onClick}>
						{ openMap ? 'Скрыть' : 'Смотреть маршрут на карте' }
						{ ArrowDownNavigate() }
					</Button>
				</div>
			</div>
		)
	}
}

export default connect(
	store => ({
		points: getPoints(store),
	})
)(DriverRoute);
