import { driverActions } from 'actions/driver';
import { add } from 'actions/notifications';
import { changePassword, editAvatarData, editContactData, editPassportData, updatePassword, getData } from 'actions/profile';
import { dateToString } from 'libs/convertDate';
import showError from 'libs/showFormErrors';
import { required } from 'libs/validationsForms';
import { func, object } from 'prop-types';
import { SubmissionError } from 'redux-form';
import { normalizeString } from 'libs/normalizeString';

export const saveContactData = (values: object, role: string = 'guest', dispatch: func = () => {
}) => {
    showError({
        firstName: [required(values.firstName.replace)],
        lastName: [required(values.lastName.replace)],
        email: [required(values.email)],
        phone_number: [required(values.phone_number)],
    });

    const phone = values.phoneCode + values.phone_number.replace(/\s/g, ''),
        additionalPhone =
            values.additionalPhone_code +
            values.additionalPhone_number.replace(/\s/g, '');

    const firstName = normalizeString(values.firstName);
    const lastName = normalizeString(values.lastName);
    const middleName = normalizeString(values.middleName);

    return new Promise((resolve, reject) => {
        let data = {
            firstName: firstName,
            lastName: lastName,
            middleName: middleName,
            email: values.email,
            city: values.city ? values.city : null,
            languages: values.languages ? values.languages : null,
            experienceYears: values.experienceYears ? values.experienceYears : null,
            phone: phone,
            additionalPhone: additionalPhone,
        };
        // if (values.photo && role !== 'guest') {
        // 	data.photoId = values.photo.id
        // }
        dispatch(editContactData(data, { resolve, reject }));
    }).then(() => {
        dispatch(add({
            type: 'success',
            text: 'Данные профиля обновлены',
            time: 10
        }));
    }).catch(err => {
        if (err && err.phone)
            err.phone_number = err.phone;
        dispatch(add({
            type: 'error',
            text: 'Произошла ошибка сохранения данных профиля',
            time: 10
        }));
        throw new SubmissionError({ ...err, _error: 'bad' })
    });
};

export const savePhoto = (photo, dispatch: func = () => {
}) => {
    return new Promise((resolve, reject) => {
        const data = { photoId: photo.photoId !== null ? photo.photoId.id : null };
        dispatch(editAvatarData(data, { resolve, reject }));
    }).then(() => {
        dispatch(add({
            type: 'success',
            text: 'Аватар был успешно изменен',
            time: 7
        }));
    }).catch(err => {
        dispatch(add({
            type: 'error',
            text: 'Произошла ошибка при изменении аватара',
            time: 7
        }));
        throw new SubmissionError({ ...err, _error: 'bad' })
    });
};


let isObject = function (a) {
    return (!!a) && (a.constructor === Object);
};


export const savePassport = (values: object, userId: number, dispatch: func = () => {}) => {


    showError({
        number: [required(values.number)],
        issueDate: [required(values.issueDate)],
        issuedBy: [required(values.issuedBy)],
        registrationAddress: [required(values.registrationAddress)],
        scanIds: [required(values.scanIds)],
    });

    values.issueDate = dateToString(values.issueDate, 'server');
    values.userId = userId.toString();
    values.issueCountry = 'ru';

    if (values.scanIds && isObject(values.scanIds)) {
        values.scanIds = values.scanIds.images.map(it => typeof(it) === 'string' ? it : it.id.toString());
    } else {
        values.scanIds = values.scanIds.map(it => typeof(it) === 'string' ? it : it.id.toString());
    }

    return new Promise((resolve, reject) => {
        dispatch(editPassportData(values, {
            resolve: resolve,
            reject: reject,
        }));
    }).then(() => {
        dispatch(add({
            type: 'success',
            text: 'Данные паспорта отправлены на верификацию',
            time: 10
        }));
    }).catch(err => {
        dispatch(add({
            type: 'error',
            text: 'Произошла ошибка сохранения паспортных данных',
            time: 10
        }));
        throw new SubmissionError({ ...err, _error: 'bad' })
    });
};

export const submitChangePassword = (values: object, dispatch: func = () => {}, success) => {

    showError({
        newPassword: [required(values.newPassword)],
        newPasswordAgain: [required(values.newPasswordAgain)],
    });

    return new Promise((resolve, reject) => {
        dispatch(changePassword(values, { resolve, reject, success }));
    }).then(() => {
        dispatch(add({
            type: 'success',
            text: 'Пароль был успешно изменён',
            time: 3
        }));
    }).catch(err => {
        dispatch(add({
            type: 'error',
            text: 'Произошла ошибка при изменении пароля',
            time: 3
        }));
        throw new SubmissionError({ ...err, _error: 'bad' })
    });
};

export const submitUpdatePassword = (values: object, dispatch: func = () => {
}, success) => {

    showError({
        oldPassword: [required(values.oldPassword)],
        newPassword: [required(values.newPassword)],
        newPasswordAgain: [required(values.newPasswordAgain)],
    });

    return new Promise((resolve, reject) => {
        dispatch(updatePassword(values, { resolve, reject, success }));
    }).then(() => {
        dispatch(add({
            type: 'success',
            text: 'Пароль был успешно изменён',
            time: 3
        }));
    }).catch(err => {
        dispatch(add({
            type: 'error',
            text: 'Произошла ошибка при изменении пароля',
            time: 3
        }));
        throw new SubmissionError({ ...err, _error: 'bad' })
    });
};

export const SaveDriverLicense = (values: object, userId: number, dispatch: func = () => {
}) => {

    showError({
        number: [required(values.number)],
        issueCountry: [required(values.issueCountry)],
        issueDate: [required(values.issueDate)],
        scanId: [required(values.scanId)],
    });

    values.issueDate = dateToString(values.issueDate, 'server');
    values.userId = userId.toString();

    if (values.scanId && isObject(values.scanId)) {
        values.scanId = values.scanId.images.map(it => typeof(it) === 'string' ? it : it.id.toString());
    } else {
        values.scanId = values.scanId.map(it => typeof(it) === 'string' ? it : it.id.toString());
    }

    return new Promise((resolve, reject) => {
        dispatch(driverActions.editDriverLicenseData(values, {
            resolve: resolve,
            reject: reject,
        }));
    }).then(() => {
        dispatch(add({
            type: 'success',
            text: 'Данные удостоверения отправлены на верификацию',
            time: 10
        }));
    }).catch(err => {
        dispatch(add({
            type: 'error',
            text: 'Произошла ошибка сохранения удостоверения',
            time: 10
        }));
        throw new SubmissionError({ ...err, _error: 'bad' })
    });
};
