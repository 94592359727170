import React from 'react';
import Page from 'components/UI/Wrappers/Page';
import connect from 'react-redux/es/connect/connect';
import { getProfile, getProfileType } from 'libs/selections';
import { LabelError } from 'components/UI/Form/Labels';
import CustomerForm from './CustomerForm';
import DriverForm from './DriverForm';
import { removeItem } from 'libs/sessionStorage';

class EditProfile extends React.PureComponent {
	componentWillMount() {
		removeItem('searchParams');
	}
	render() {
		switch (this.props.role) {
			case 'customer':
				return (
					<Page title="Мой профиль">
						<CustomerForm />
					</Page>
				);
			case 'driver':
				return (
					<Page title="Мой профиль">
						<DriverForm />
					</Page>
				);
			default:
				return <LabelError message="Нет прав доступа к компоненту" />;
		}
	}
}

export default connect(
	store => ({
		profile: getProfile(store),
		role: getProfileType(store),
	})
)(EditProfile);