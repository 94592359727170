import React from 'react';
import listStyle from 'components/Admin/UI/List/style.less';
import PropTypes from 'prop-types';


const NotFound = ({ text, style }) => (
	<p className={listStyle.notFound} style={style}>{ text ? text : 'Ничего не найдено' }</p>
);

NotFound.propTypes = {
	text: PropTypes.string,
	style: PropTypes.object,
};

NotFound.defaultProps = {
	text: 'Ничего не найдено',
	style: {},
};


export default NotFound;