import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'components/UI/Form/Button';
import style from './style.less';

const AsideItem = props => (
	<div className={`${style.aside_link} ${props.redLnk && style.red}`}>
		{
			props.url
			&& <Link to={props.url}>{ props.children }</Link>
		}
		{
			!props.url
			&& <Button className={style.button} onClick={props.onClick}>{ props.children }</Button>
		}
	</div>
);

AsideItem.propTypes = {
	url: PropTypes.string,
	onClick: PropTypes.func,
	children: PropTypes.node,
	redLnk: PropTypes.bool,
};
AsideItem.defaultProps = {
	url: '',
	onClick: () => {},
	children: '',
	redLnk: false,
};

export default AsideItem;
