import * as customerActions from "actions/customer";
import style from 'components/ReduxForms/ProposalRouteForm/style.less';
import Button from 'components/UI/Form/Button';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import Select from 'components/UI/Form/Fields/Select';
import Textarea from 'components/UI/Form/Fields/Textarea';
import { AddSong } from 'components/UI/Icons';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import language from 'language';
import asyncValidate from 'libs/asyncValidate';
import { dateToString, serverDateToClientDate } from 'libs/convertDate';
import { getMyCargoes } from 'libs/selections';
import { weightConversionShort } from 'libs/weightConversion';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';


class ProposalRouteForm extends Component {

    static propTypes = {
        handleSubmit: PropTypes.func,
        error: PropTypes.string,
        submitting: PropTypes.bool,
        invalid: PropTypes.bool,
    };

    static defaultProps = {
        handleSubmit: () => {
        },
        invalid: false,
        submitting: false,
        error: '',
    };
    isEnabledSubmit = () => {
        const { offerId, message } = this.props.values;
        if (offerId && message)
            if (message.length >= 1) return true;
        return false;
    };

    componentWillMount() {
        this.props.dispatch(customerActions.getActiveCargo({ page: 1 }))
    }

    render() {

        const { handleSubmit, invalid, offers } = this.props,
            isEnabledSubmit = this.isEnabledSubmit();

        let items = [];

        if (offers.active.total !== 0) {
            offers.active.items.forEach(el => {
                if (el.status !== 'moderation') items.push(el);
            });
        }

        return (
            <form
                onSubmit={handleSubmit}
                autoComplete="off"
            >
                <LayoutField className={style.select}>
                    <Field
                        name="offerId"
                        component={Select}
                        label="Выберите заявку"
                        classNameInput={style.selectField}
                    >
                        {
                            items.length > 0 &&
                            items.map(el => {
                                return (
                                    <OptionSelect key={el.id} value={el.id}>
                                        {el.cargo.title}, {language[el.cargo.category]},{' '}
                                        {weightConversionShort(el.cargo.weight)}
                                        <span className={style.offerTitle}>Заявка №{el.id} от{' '}
                                            {dateToString(serverDateToClientDate(el.createTime), 'default')}</span>
                                    </OptionSelect>
                                )
                            })
                        }
                    </Field>
                </LayoutField>

                {
                    !items.length &&
                    <p className={style.notFound}>У вас еще не добавлено ни одного груза на перевозку.{' '}
                        <Link to={'/new/offer'}>Создайте заявку</Link> и ищите водителей
                    </p>
                }

                <LayoutField>
                    <Field
                        name="message"
                        component={Textarea}
                        label="Ваше сообщение перевозчику"
                    />
                </LayoutField>

                <Button
                    disabled={invalid || !isEnabledSubmit}
                    loading={this.props.submitting}
                    size={'large'}
                    type={'submit'}
                    className={style.btnMobileIndent}
                >
                    {AddSong()} Добавить предложение
                </Button>
            </form>
        )
    }
}


const selector = formValueSelector('ProposalRoute');

export default compose(
    reduxForm({
        form: 'ProposalRoute',
        asyncValidate,
        asyncBlurFields: ['message'],
    }),
    connect(
        state => ({
            offers: getMyCargoes(state),
            values: selector(state, 'offerId', 'message')
        })
    )
)(ProposalRouteForm);