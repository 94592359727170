import forwardTo from "libs/forwardTo";
import { getProfileType } from "libs/selections";
import React from 'react';
import Grid from 'components/UI/Wrappers/Grid';
import MainScreen from "components/HelpfulInformations/common/main";
import MainWhiteContainer from "components/UI/Wrappers/MainWhiteContainer";
import styles from "components/HelpfulInformations/style.less";
import Navigation from "components/HelpfulInformations/common/navigation";
import style from "components/MainPage/style.less";
import Button from "components/UI/Form/Button";
import Container from "components/UI/Wrappers/Container";
import bg from 'components/HelpfulInformations/assets/bg_risk.svg';
import step_1_risk from 'components/HelpfulInformations/assets/step_1_risk.svg';
import step_2_risk from 'components/HelpfulInformations/assets/step_2_risk.svg';
import step_3_risk from 'components/HelpfulInformations/assets/step_3_risk.svg';
import { connect } from "react-redux";
import src from 'components/HelpfulInformations/assets/protect.svg';


const RiskFree = props => (
  <MainScreen title={'Сделка без риска'}
              subtitle={'«Сделка без риска» с одной стороны гарантирует оплату \n' +
              'за каждый выполненный заказ, а с другой, — страхует \n' +
              'заказчика от оплаты за невыполненную доставку.'}
    bg={bg}
  >
    <MainWhiteContainer className={`${styles.advantage__screen} ${styles.not_padding}`}>
      <Navigation isActive={1}/>
      <div className={styles.padding}>
        <Container>
          <Grid col="9-3">
            <div>
              <h3 className={styles.h3_risk}><b>Что дает пользователям «Сделка без риска»</b></h3>
              <p className={styles.p_risk} style={{ marginBottom: '35px', maxWidth: '750px' }}>
                Все заказы в пределах Сервиса исполняются исключительно по принципу «Сделки без риска». При этом, расчеты между пользователями проходят через Сервис
                и исключают возможность сторон получить желаемое (доставку или оплату)
                без выполнения встречных обязательств.
              </p>
              <Button color={'red'} size={'large'} onClick={() => {
                forwardTo(`${
                    props.role === 'guest' ? '/new/offer/' :
                        props.role === 'client' ? '/new/offer/' : '/new/route/'
                }`)
              }}>
                Разместить заказ
              </Button>
            </div>
            <div className={style.support_s}>
              <div className={style.icon}><img src={src} alt=""/></div>
              <div>
                <h4 style={{ fontSize: '21px' }}>
                  <b>Страхование груза перед перевозкой</b>
                </h4>
                <p style={{ fontSize: '15px' }}>
                  Вы можете застраховать груз и быть уверенный, что он будет доставлен
                  в идеальном состояниии.
                  В случае порчи груза вам будет выплачена сумма страховки
                </p>
              </div>
            </div>
          </Grid>
        </Container>
      </div>
    </MainWhiteContainer>
    <Container className={styles.container_cards}>
      <h2 style={{ marginTop: '70px', fontSize: '30px' }}><b>Как работает «Сделка без риска»</b></h2>
      <div className={styles.card}>
        <img src={step_1_risk} alt=""/>
        <div className={styles.card__description}>
          <h5 className={styles.title}>
            Как происходит процесс оплаты
          </h5>
          <p className={styles.description}>
            Система работает таким образом, что при заключении сделки, оговоренная сумма списывается с банковской карты заказчика, резервируется на специальном счете Сервиса и сохраняется там до уведомления о выполнении доставки.
            После подтверждения исполнения заказа обеими сторонами, деньги перечисляются на банковскую карту грузоперевозчика. В случае невыполнения задания или отказа  от него, зарезервированная сумма возвращается на карту заказчика.
          </p>
        </div>
      </div>
      <div className={styles.card}>
        <img src={step_2_risk} alt=""/>
        <div className={styles.card__description}>
          <h5 className={styles.title}>
            Что происходит, если товар доставлен, но заказчик не подтверждает этого
          </h5>
          <p className={styles.description}>
            Если после осуществления доставки, заказчик не подтверждает ее выполнение, то решение о статусе сделки принимает Арбитраж Сервиса. В данном случае мы выступаем посредником и изучаем все вопросы, связанные со спорной ситуацией, включая размещения заявки на сайте, обсуждение условий, ход выполнения, доказательства доставки или обратного. Только изучив все факты и доказательства сторон, мы принимает решение об оплате услуги или возвращении денег
            на карту заказчика.
          </p>
        </div>
      </div>
      <div className={styles.card}>
        <img src={step_3_risk} alt=""/>
        <div className={styles.card__description}>
          <h5 className={styles.title}>
            Кто платит за «Сделку без риска»
          </h5>
          <p className={styles.description}>
            Администрирование «Сделки без риска» осуществляется непосредственно Сервисом «HurryLorry». За это мы берем плату в размере 10 % от стоимости заказа. Комиссия взимается с общей суммы сделки после ее завершения одновременно с переводом денег на карту грузоперевозчика. Фактически комиссия уменьшает размер получаемой исполнителем оплаты. Это надо учитывать при обсуждении стоимости сделки.
          </p>
        </div>
      </div>
    </Container>
  </MainScreen>
);

export default connect(
    store => ({
      role: getProfileType(store),
    })
)(RiskFree);
