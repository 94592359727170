exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2_Fbuc502zaV122dor56V6 {\n  margin-bottom: 20px;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/AddRoute/style.less"],"names":[],"mappings":"AAAA;EACE,oBAAoB;CACrB","file":"style.less","sourcesContent":[".marginAfterPanel {\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"marginAfterPanel": "_2_Fbuc502zaV122dor56V6"
};