import React from 'react';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import OptionSelectAction from 'components/UI/Form/Fields/OptionSelectAction';


const getCarsSelect = (cards, addCard) => {
	
	let options = cards.items.map(card => (
		<OptionSelect key={card.id} value={card.id}>
			{`**** **** **** ${card.cardNumber.slice(-4)}`} <b>{card.title}</b>
		</OptionSelect>
	));
	
	options.push(
		<OptionSelectAction key={-1} onClick={addCard}>
			+ Добавить карту
		</OptionSelectAction>
	);
	
	
	return options;
};


export default getCarsSelect;