import React, { PureComponent } from 'react';
import Grid from 'components/UI/Wrappers/Grid';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import { Field } from 'redux-form';
import Input from 'components/UI/Form/Fields/Input';
import ReCAPTCHA from 'react-google-recaptcha';
import { password } from 'libs/validationsForms';


class OfferRegistration extends PureComponent {

	render () {

		return (
			<div style={{ maxWidth: '600px' }}>
				<Grid col="form-2col">
					<LayoutField>
						<Field
							name="firstName"
							component={Input}
							label="Имя"
						/>
					</LayoutField>
					<LayoutField>
						<Field
							name="lastName"
							component={Input}
							label="Фамилия"
						/>
					</LayoutField>
				</Grid>
				<LayoutField>
					<Field
						name="email"
						component={Input}
						label="Email"
					/>
				</LayoutField>
				<Grid col="form-2col">
					<LayoutField>
						<Field
							name="password"
							type="password"
							component={Input}
							hint={'Пароль должен содержать не менее 6 символов'}
							label="Введите пароль"
						/>
					</LayoutField>
					<LayoutField>
						<Field
							name="passwordAgain"
							type="password"
							component={Input}
							label="Повторите пароль"
						/>
					</LayoutField>

					<div style={{ marginBottom: '15px' }}>
						<ReCAPTCHA
							ref={this.props.recaptchaRef}
							sitekey="6Lf5T7kUAAAAALGUCClRc6jZmwEUcGhW9OcZYuHm"
							onChange={this.props.onChange}
						/>
					</div>

				</Grid>
			</div>
		);
	}
}

export default OfferRegistration;
