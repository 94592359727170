import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import AdminInput from 'components/Admin/UI/Forms/Input';
import Button from 'components/UI/Form/Button';
import style from 'components/Admin/ReduxForms/SearchAdminForm/style.less';
import { Search } from 'components/UI/Icons';


class SearchAdminForm extends Component {
	
	static propTypes = {
		handleSubmit: PropTypes.func,
		error: PropTypes.string,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
	};
	
	static defaultProps = {
		handleSubmit: () => {},
		invalid: false,
		submitting: false,
		error: '',
	};
	
	render() {
		
		let { handleSubmit, invalid } = this.props;
		
		return (
			<form
				className={style.form}
				onSubmit={handleSubmit}
				autoComplete="off"
			>
				
				<Field
					name="search"
					component={AdminInput}
					placeholder="Поиск"
				/>
				
				<Button
					className={style.submit}
					disabled={invalid}
					type={'submit'}
					clearStyle
				>
					{ Search() }
				</Button>
				
			</form>
		);
	}
}


export default reduxForm({
	form: 'SearchAdminForm',
})(SearchAdminForm);