import { change } from 'redux-form';

export const getQueryString = queryString => {
	let qs = {};
	const s = queryString.replace( /^\?|#.*$/g, '' );
	if (s) {
		const qsParts = s.split('&');
		let i, nv;
		for (i = 0; i < qsParts.length; i++) {
			nv = qsParts[i].split('=');
			qs[nv[0]] = nv[1];
		}
	}
	if (parseInt(qs.pageSize) > 99) qs.pageSize = 99;
	return qs;
};

export const decodeURL = obj => {
	if (obj.fromCity !== undefined) obj.fromCity = decode(obj.fromCity);
	if (obj.fromRegion !== undefined) obj.fromRegion = decode(obj.fromRegion);
	if (obj.fromCountry !== undefined) obj.fromCountry = decode(obj.fromCountry);
	
	if (obj.toCity !== undefined) obj.toCity = decode(obj.toCity);
	if (obj.toRegion !== undefined) obj.toRegion = decode(obj.toRegion);
	if (obj.toCountry !== undefined) obj.toCountry = decode(obj.toCountry);
	
	return obj;
};

export const setPointData = (obj, dispatch) => {
	if (obj.fromCity !== undefined) setValue('point_a[locality]', obj.fromCity, dispatch);
	if (obj.fromCity !== undefined) setValue('point_a[name]', obj.fromCity, dispatch);
	if (obj.fromRegion !== undefined) setValue('point_a[region]', obj.fromRegion, dispatch);
	if (obj.fromCountry !== undefined) setValue('point_a[country]', obj.fromCountry, dispatch);
	
	// if (obj.toCity !== undefined) obj.toCity = decode(obj.toCity);
	// if (obj.toRegion !== undefined) obj.toRegion = decode(obj.toRegion);
	// if (obj.toCountry !== undefined) obj.toCountry = decode(obj.toCountry);
	
	return obj;
};

const decode = str => {
	return decodeURIComponent(str.replace(/\+/g,  " "));
};

const setValue = (field, value, dispatch) => {
	dispatch(change('searchCustomers', field, value));
};