import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { getProfile, getProfileType } from 'libs/selections';
import PropTypes from 'prop-types';
import ProposalDetail from 'components/UI/_V2/Proposals/Detail';
import style from 'components/UI/_V2/Proposals/List/style.less';
import ProposalListTitle from 'components/UI/_V2/Proposals/List/ProposalListTitle';
import ProposalListSendButton from 'components/UI/_V2/Proposals/List/ProposalListSendButton';
import { showSendButton, showCustomerActions, showProfileLinks, showOffer } from 'components/UI/_V2/Proposals/List/checks';
import language from 'language';


class ProposalList extends PureComponent {
	
	static propTypes = {
		openProposalDialog: PropTypes.func,
		type: PropTypes.string,
		proposals: PropTypes.array,
		proposalsTotalCount: PropTypes.number,
		userId: PropTypes.number,
		status: PropTypes.string,
		openChat: PropTypes.func,
		user: PropTypes.object,
	};
	
	static defaultProps = {
		openProposalDialog: () => {},
		type: '',
		proposals: [],
		proposalsTotalCount: 0,
		userId: 0,
		status: 'undefined',
		openChat: () => {},
		user: { id: 0 },
	};
	
	
	render() {

		const { proposals, proposalsTotalCount, openProposalDialog, status, role, type, profile, userId, user } =
			this.props;
		// console.log(this.props);
		
		return (
			<Fragment>
				
				<ProposalListTitle
					total={proposalsTotalCount}
					role={role}
					type={this.props.type}
				/>
				
				<ProposalListSendButton
					showSendButton={showSendButton(role,type,profile,proposals,status)}
					openProposalDialog={openProposalDialog}
				/>
				
				{/*<form>*/}
					{/*<Checkbox label={`${language.onNotificationProposals}: ${profile.email}`}/>*/}
				{/*</form>*/}
				
				{
					(proposalsTotalCount === 0 || proposalsTotalCount === null) &&
					<p className={style.section__desc}>
						{ language[`Proposal_${role}_${type}_empty`] }
					</p>
				}
			
				{
					proposalsTotalCount > 0 &&
					<div className={style.proposal__list}>
						{
							proposals.map(el =>
								<ProposalDetail
									{ ...el }
									key={el.id}
									isProfileLink={showProfileLinks(role,type)}
									showCustomerActions={showCustomerActions(profile,status,userId)}
									showOffer={showOffer(type,el,profile,user.id)}
									acceptProposal={e => this.props.acceptProposal(e)}
									openChat={(user1, user2) => this.props.openChat(user1, user2)}
									isYou={el.user1.id === this.props.profile.id}
								/>
							)
						}
					</div>
				}
				
			</Fragment>
		);
	}
}


export default connect(
	state => ({
		profile: getProfile(state),
		role: getProfileType(state),
	}),
)(ProposalList);