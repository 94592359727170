import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AddSong } from 'components/UI/Icons';
import Button from 'components/UI/Form/Button';

const UserAddButton = ({ role, className }) => {
	switch (role) {
		case 'driver':
			return (
				<Link to="/new/route/">
					<Button className={className}>{ AddSong() } Добавить маршрут</Button>
				</Link>
			);
		default:
			return (
				<Link to="/new/offer/">
					<Button className={className}>{ AddSong() } Добавить груз</Button>
				</Link>
			);
	}
};

UserAddButton.propTypes = {
	role: PropTypes.string.isRequired,
	className: PropTypes.string,
};
UserAddButton.defaultProps = {
	className: '',
};

export default UserAddButton;
