import React from 'react';
import PropTypes from 'prop-types';
import style from './RequestPoints.less';
import Points from 'components/UI/Points';
import { getPoints } from 'libs/getInfoRoute';
import getWordEnd from 'libs/getWordEnd';


const Content = ({ points, type }) => {

	const p = getPoints(points);
	const length = p.otherPoints.length;
	const address = ['адрес','адреса','адресов'];

	if (type === 1) {
		return (
			<div className={style.addresses}>
				<div className={style.point}>
					<span className={style.date}>Отправление {p.pointA.date}</span>
					<span className={style.title}>{p.pointA.title}</span>
				</div>
				{
					length > 0 &&
					<div className={style.other_points}>
						<div className={style.other_points_title}>Попутные города</div>
						{
							p.otherPoints.map((el, i) => {
								return <span key={i}>{el.title}</span>;
							})
						}
					</div>
				}
				<div className={style.point}>
					<span className={style.date}>Прибытие {p.pointB.date}</span>
					<span className={style.title}>{p.pointB.title}</span>
				</div>
			</div>
		)
	}

	return (
		<div className={style.addresses}>
			<div className={style.point}>
				<span className={style.title}>{ p.pointA.title }</span>
				<span className={style.date}>{ p.pointA.date }</span>
			</div>
			<div className={style.point}>
				<span className={style.title}>
					{ p.pointB.title }
					{ length > 0 && <span className={style.plus_points}> +{ length } { getWordEnd(length, address) }</span> }
				</span>
				<span className={style.date}>{ p.pointB.date }</span>
			</div>
		</div>
	)
};



const RequestPoints = ({ points, type }) => {

	return (
		<div className={style.request_points}>
			<Points />
			<Content points={points} type={type} />
		</div>
	)
};

/*
* values for "type":
* 0 - default, use everywhere
* 1 - use for routes in profile driver
* */
RequestPoints.propTypes = {
	points: PropTypes.array.isRequired,
	type: PropTypes.number,
};
RequestPoints.defaultProps = {
	type: 0,
};


export default RequestPoints;
