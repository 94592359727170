import * as localStorage from './localStorage';
import * as sessionStorage from './sessionStorage';

export const getToken = () => {
	let localToken = localStorage.getItem('token'),
		sessionToken = sessionStorage.getItem('token');

	return localToken ? localToken : sessionToken;
};

export const saveToken = (storeType:string, token: string) => {
	switch (storeType) {
		case 'local':
			return localStorage.setItem('token', token);
		case 'session':
			return sessionStorage.setItem('token', token);
		default:
			console.error('not save token to store');
			return false;
	}
};

export const removeToken = () => {
	localStorage.removeItem('token');
	sessionStorage.removeItem('token');
};