import HeaderPageDescription from "components/UI/HeaderPageDescription/index";
import styles from 'components/UI/Request/requestlayer.less';
import PropTypes from 'prop-types';
import React from 'react';
import { Tab, TabList, Tabs } from "react-tabs";
import style from './style.less';


export class CustomTabs extends React.PureComponent {
    static propTypes = {
        onClickTab: PropTypes.func.isRequired,
        tabList: PropTypes.array.isRequired,
        selectedTab: PropTypes.number.isRequired,
        descriptionLeft: PropTypes.string,
        className: PropTypes.string,
    };

    render() {
        const { tabList, selectedTab, onClickTab, descriptionLeft, children, className } = this.props;
        return (
            <Tabs selectedIndex={selectedTab} onSelect={tabIndex => onClickTab(tabIndex)}>
                <HeaderPageDescription className={`${styles.headerPageDescription} ${className && className}`}>
                    {
                        descriptionLeft &&
                        <div className={styles.descriptionLeft}>
                            {descriptionLeft}
                        </div>
                    }
                    <TabList className={style.tab_list}>
                        {
                            tabList.map((el, i) => (
                                <Tab
                                    key={i}
                                    className={`${style.tab_item} ${i === selectedTab ? style.active : ''}`}
                                    onClick={() => onClickTab(i)}
                                >
                                    {el.title}
                                </Tab>
                            ))
                        }
                    </TabList>
                </HeaderPageDescription>
                {children}
            </Tabs>
        )
    }
}
