import React from 'react';
import { Link } from 'react-router-dom';
import style from './style.less';
import shortName from 'libs/shortName';
import { HeartOutline } from 'components/UI/Icons';
import Avatar from 'components/UI/ProfileUI/Avatar';
import Button from 'components/UI/Form/Button';
import Tooltip from 'components/UI/Tooltip';
import Rating from 'components/UI/ProfileUI/Rating';

const Driver = ({ driver, onClick }) => {
	return (
		<div className={style.driver}>
			<Link className={style.url} to={`/profile/${driver.userId}`} />
			<Avatar
				src={driver.photo}
				size={79}
				className={style.avatar}
			/>
			<div className={style.user}>
				<div className={style.username}>
					{ shortName(driver.firstName, driver.lastName) }
				</div>
				<div className={style.userrating}>
					{
						driver.reviewsCount === 0 && 'Отзывы отсутствуют'
					}
					{
						driver.reviewsCount > 0 && <Rating shortRating count={'4.5'} reviews={{count: 3}} />
						// TODO доделать вывод
					}
				</div>
			</div>
			<div className={style.favorite}>
				<Button
					clearStyle
					onClick={onClick}
				>
					{ HeartOutline() }
					<Tooltip className={style.tooltip} text={'Убрать из избранного'} />
				</Button>
			</div>
		</div>
	)
};


export default Driver;
