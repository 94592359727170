import { compareDate, compareTime } from 'libs/getMessageTime';

export const isUser = (currentMsg, prevMsg) => {
	if (prevMsg === undefined) return true;
	return currentMsg.senderId !== prevMsg.senderId;
};

export const isTime = (currentMsg, prevMsg) => {
	if (prevMsg === undefined) return true;
	if (currentMsg.senderId !== prevMsg.senderId) return true;
	return compareTime(currentMsg.createTime, prevMsg.createTime);
};

export const isDate = (currentMsg, prevMsg) => {
	if (prevMsg === undefined) return true;
	return compareDate(currentMsg.createTime, prevMsg.createTime);
};