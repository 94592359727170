import React from 'react';
import Container from 'components/UI/Wrappers/Container';
import Button from 'components/UI/Form/Button';
// import tarif5 from 'components/BecomeCarrier/tarif5.svg';
import tarif5 from 'components/BecomeCarrier/1.png';
import tarif10 from 'components/BecomeCarrier/2.png';
// import tarif10 from 'components/BecomeCarrier/tarif10.svg';
import style from './style.less';
import forwardTo from 'libs/forwardTo';

const RatesForDriver = props => {

	const link = props.role === 'guest' ? '/registration/driver' : props.role === 'driver' ? '/new/route' : '/new/offer';

	return (
		<div className={style.rates_for_driver}>
			<Container>
				<h3 className={style.section_title}>Тарифы для перевозчиков</h3>
				<div className={style.list}>
					<div>
						<img src={tarif5} alt=""/>
						<h4>Комиссия 3%</h4>
						<p>До 5 выполненных заказов за последние <span>30 дней</span></p>
					</div>
					<div>
						<img src={tarif10} alt=""/>
						<h4>Комиссия 2%</h4>
						<p>Свыше 5 выполненных заказов за последние 30 дней</p>
					</div>
				</div>
				<div className={style.textcenter}>
					<Button
						onClick={() => forwardTo(link)}
						size="large"
					>
						Разместите маршрут прямо сейчас
					</Button>
					<p>и начните получать предложения на перевозку груза</p>
				</div>
			</Container>
		</div>
	);
};

export default RatesForDriver;
