import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AddSong } from '../Icons';
import Container from './Container';
import Button from '../Form/Button';
import style from './style.less';

const PageTransport = props => (
	<Container>
		<div className={style['page-header']}>
			<h1>{ props.title }</h1>
			<div>
				<Link to="/new/car">
					<Button>
						{ AddSong() }
						Добавить транспорт
					</Button>
				</Link>
			</div>
		</div>
		{ props.children }
	</Container>
);

PageTransport.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string,
	children: PropTypes.node,
};
PageTransport.defaultProps = {
	title: '',
	text: '',
	children: null,
};

export default PageTransport;
