import {createActions} from 'redux-actions';

export const {chatActions} = createActions({
	chatActions: {
		getDialogs: [() => null, (payload, meta) => meta],
		connectToServer: [(payload) => payload, (payload, meta) => meta],
		disconnectChat: [() => null, (payload, meta) => meta],
		removeDialogById: [(payload) => payload, (payload, meta) => meta],
		changeDialogId: [(payload) => payload, (payload, meta) => meta],
		getMessageFromServer: [(payload) => payload, (payload, meta) => meta],
		setDialogs: [(payload) => payload, (payload, meta) => meta],
		addDialog: [(payload) => payload, (payload, meta) => meta],
		openDialog: [(payload) => payload, (payload, meta) => meta],
		openDialogByID: [(payload) => payload, (payload, meta) => meta],
		getHistoryByID: [(payload) => payload, (payload, meta) => meta],
		appendHistoryByID: [(payload) => payload, (payload, meta) => meta],
		setHistory: [(payload) => payload, (payload, meta) => meta],
		appendHistory: [(payload) => payload, (payload, meta) => meta],
		addMsgToDialog: [(payload) => payload, (payload, meta) => meta],
		sendMsgToServer: [(payload) => payload, (payload, meta) => meta],
		createDialogForServer: [(payload) => payload, (payload, meta) => meta],
		removeMsgFromClient: [(payload) => payload, (payload, meta) => meta],
		acceptSendMsg: [(payload) => payload, (payload, meta) => meta],
		markReadMsgToServer: [(payload) => payload, (payload, meta) => meta],
		markReadMsgToClient: [(payload) => payload, (payload, meta) => meta],
		setCountDontReadMsg: [(payload) => payload, (payload, meta) => meta],
		plusCountDontReadMsg: [() => null, (payload, meta) => meta],
		minusCountDontReadMsg: [() => null, (payload, meta) => meta],
		minusCountReadMsgForDialog: [(payload) => payload, (payload, meta) => meta],
		plusCountReadMsgForDialog: [(payload) => payload, (payload, meta) => meta],
		clearChat: [() => null, (payload, meta) => meta],
	}
});