exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".sRWfKVqS0AdrhY59vwOR0 {\n  max-width: 820px;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/MyReviews/style.less"],"names":[],"mappings":"AAAA;EACE,iBAAiB;CAClB","file":"style.less","sourcesContent":[".maxWidthForPagination {\n  max-width: 820px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"maxWidthForPagination": "sRWfKVqS0AdrhY59vwOR0"
};