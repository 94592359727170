import React from 'react';
import PropTypes from 'prop-types';
import CustomScroll from 'react-custom-scroll';
import style from 'components/Chat/style.less';
import Loader from 'components/UI/Loader';

export default class InfiniteScroll extends React.PureComponent {
	
	ref = undefined;
	prevHeight = 0;

	static propTypes = {
		loadMore: PropTypes.func.isRequired,
		loading: PropTypes.bool,
		dialog: PropTypes.object,
		total: PropTypes.number,
		current: PropTypes.number,
	};

	state = {
		scroll: 5000,
	};

	componentWillReceiveProps(nextProps, nextContext) {
		const compareDialog = this.props.dialog.id === nextProps.dialog.id;
		
		if (nextProps.loading && compareDialog)
			this.prevHeight = this.ref.contentHeight;
		
		if (!nextProps.loading && compareDialog)
			this.setState({ scroll: this.ref.contentHeight - this.prevHeight });
		
		if (!compareDialog)
			this.setState({ scroll: 5000 });
	}

	handleScroll = event => {
		if (event.target.scrollTop === 0)
			if (this.props.total > this.props.current)
				this.props.loadMore()
	};

	scrollTo = scroll => this.setState({ scroll });

	render() {
		return (
			<CustomScroll
				flex="1"
				keepAtBottom
				onScroll={this.handleScroll}
				ref={ref => this.ref = ref}
				scrollTo={this.state.scroll}
			>
				<Loader
					text={'Загрузка предыдущих сообщений'}
					className={`${style.loader} ${this.props.loading ? style.show : ''}`}
				/>
				{this.props.children}
			</CustomScroll>
		);
	}
};