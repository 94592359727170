import React, { Component } from 'react';
import Grid from 'components/UI/Wrappers/Grid';
import FeedbackForm from 'components/ReduxForms/FeedbackForm';
import Panel from 'components/UI/Wrappers/Panel';
import style from 'components/Verification/style.less';
import showError from 'libs/showFormErrors';
import { required } from 'libs/validationsForms';
import { feedback } from 'actions/user';
import { connect } from 'react-redux';
import { getProfile, getProfileType } from 'libs/selections';
import MainScreen from "components/HelpfulInformations/common/main";
import MainWhiteContainer from "components/UI/Wrappers/MainWhiteContainer";
import styles from './style.less';
import Navigation from "components/HelpfulInformations/common/navigation";
import support_bg from 'components/HelpfulInformations/assets/bg_feedback.svg';
import Container from 'components/UI/Wrappers/Container';


class Feedback extends Component {

	state = {
		isSended: false,
	};

	feedbackSend = form => {
		showError({
			category: [required(form.category)],
			message: [required(form.message)],
			email: [required(form.email)],
			phone_number: [required(form.phone_number)],
			personalInfo: [required(form.personalInfo)],
		});

		let values = form;
		values.category = values.category.label;
		values.phone = `${form.phone_code}${form.phone_number.replace(/\s/g, '')}`;
		delete values.phone_code;
		delete values.phone_number;

		return new Promise((resolve, reject) => {
			this.props.dispatch(feedback(values, { resolve, reject }));
		}).then(() => this.setState({ isSended: true })
		).catch(err => console.log(err))
	};


	render () {

		const { role, profile } = this.props;
		let initialValues = {};

		if (role !== 'guest') {
			initialValues = {
				personalInfo: `${profile.firstName} ${profile.lastName}`,
				email: profile.email,
				phone_number: profile.phone.slice(1)
			}
		}

		return (
			<MainScreen
				title={'Помощь 24/7'}
				subtitle={'Нет связи с Отправителем? Возникли форс-мажорные обстоятельства? Некорректно работает приложение?'}
				bg={support_bg}
			>
				<MainWhiteContainer className={`${styles.advantage__screen} ${styles.not_padding_top}`}>
					<Navigation isActive={0}/>
					<div className={styles.padding}>
						<Container>
							<Grid col="10-2">
								<div>
									<p style={{ marginTop: 0, fontSize: '25px', fontWeight: 'bold' }}>Мы ценим наших клиентов и предоставляем им постоянную круглосуточную поддержку по любым вопросам работы Сервиса:</p>
									<ul className={style.defaultList}>
										<li>помогаем решить проблемы  с регистрацией, входом в систему или верификацией данных;</li>
										<li>отвечаем на вопросы о функциональных возможностях Сервиса;</li>
										<li>предотвращаем конфликты или улаживаем их.</li>
									</ul>
									<p className={style.support}>Наши специалисты хорошо осведомлены, доброжелательны, объективны и справедливы, они с радостью помогут вам в любое время, в любой ситуации.</p>
									<Panel
										title={'Обратная связь'}
										marginBottom={35}
										className={this.state.isSended ? style.hidePanel : ''}
									>
										<FeedbackForm
											initialValues={initialValues}
											onSubmit={this.feedbackSend}
										/>
									</Panel>
									<p className={`${style.always_contact} ${style.gray}`} >Представители HurryLorry всегда на связи — без выходных и праздников, в любое время.</p>
								</div>
							</Grid>
						</Container>

					</div>
				</MainWhiteContainer>
			</MainScreen>
		)
	}
}

export default connect(
	state => ({
		role: getProfileType(state),
		profile: getProfile(state),
	})
)(Feedback);
