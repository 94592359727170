import React from 'react';
import language from 'language';
import PropTypes from 'prop-types';
import styles from 'components/Admin/Pages/Arbitrations/List/styles.less';
import { dateToString, stringToDate } from 'libs/convertDate';


const ArbitrateItem = ({ el, onClick }) => {
	const date = stringToDate(el.createTime);
	const init = el.senderId === el.trade.customer.id ? 'Заказчик' : 'Перевозчик';
	return (
		<tr className={styles.arbitrateItem} onClick={onClick}>
			<td>{ el.id }</td>
			<td>{ language[el.reason] }</td>
			<td>{ init }</td>
			<td>{ dateToString(date, 'default') } в { dateToString(date, 'time') }</td>
		</tr>
	)
};


ArbitrateItem.propTypes = {
	el: PropTypes.object.isRequired,
};


export default ArbitrateItem;