import { createActions } from 'redux-actions';

export const { driverActions } = createActions({
    driverActions: {
        getMarks: [() => null, (payload, meta) => meta],
        getModels: [(payload) => payload, (payload, meta) => meta],
        getSeries: [(payload) => payload, (payload, meta) => meta],
        addCard: [(payload) => payload, (payload, meta) => meta],
        getCards: [() => null, (payload, meta) => meta],
        setCards: [(payload) => payload, (payload, meta) => meta],
        rmCard: [(payload) => payload, (payload, meta) => meta],
        rmCardWithRestore: [(payload) => payload, (payload, meta) => meta],
        addCar: [(payload) => payload, (payload, meta) => meta],
        rmCar: [(payload) => payload, (payload, meta) => meta],
        rmRoute: [(payload) => payload, (payload, meta) => meta],
        finishRoute: [(payload) => payload, (payload, meta) => meta],
        extendRoute: [(payload) => payload, (payload, meta) => meta],
        pushCarToStore: [(payload) => payload, (payload, meta) => meta],
        setCars: [(payload) => payload, (payload, meta) => meta],
        getMyCars: [() => null, (payload, meta) => meta],
        createRoute: [(payload) => payload, (payload, meta) => meta],
        setRoutes: [(payload) => payload, (payload, meta) => meta],
        setRouteByID: [(payload) => payload, (payload, meta) => meta],
        getRouteByID: [(payload) => payload, (payload, meta) => meta],
        getRoutesByUserID: [(payload) => payload, (payload, meta) => meta],
        setRoutesByUserID: [(payload) => payload, (payload, meta) => meta],
        getRoutes: [(payload) => payload, (payload, meta) => meta],
        editDriverLicenseData: [(payload) => payload, (payload, meta) => meta],
        sendProposal: [(payload) => payload, (payload, meta) => meta],


        setActiveRoute: [(payload) => payload, (payload, meta) => meta],
        setCancelledRoute: [(payload) => payload, (payload, meta) => meta],
        setUnpublishedRoute: [(payload) => payload, (payload, meta) => meta],

        loadingActiveRoute: [() => null, (payload, meta) => meta],
        loadingCancelledRoute: [() => null, (payload, meta) => meta],
        loadingUnpublishedRoute: [() => null, (payload, meta) => meta],


    }
});

// active
// cancelled
// unpublished