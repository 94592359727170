import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/UI/Form/Button';
import style from 'components/Admin/UI/Pagination/style.less';

class AdminPagination extends Component {

	static propTypes = {
		setPage: PropTypes.func.isRequired,
		pageCount: PropTypes.number.isRequired,
		activePage: PropTypes.number.isRequired,
	};

	static defaultProps = {
		className: false,
	};

	renderButton = currentPage => {
		const className = this.props.activePage === currentPage ? `${style.btn} ${style.btn_active}` : style.btn;
		return <Button
			className={className}
			key={currentPage}
			size="small"
			color="white"
			onClick={() => this.props.setPage(currentPage)}
		>
			{ currentPage }
		</Button>;
	};

	renderPageButtons = () => {
		const { activePage, pageCount } = this.props;
		let pageButtons = [];

		if (pageCount > 7) {
			for (let i = 0; i < pageCount; i++) {
				let j = i + 1;

				// если активная страница меньше четвертой
				if (activePage < 5) {
					if (j < 6 || j === pageCount)
						pageButtons.push(this.renderButton(j));
					else if (j === 6)
						pageButtons.push(<Button className={style.disabled} key={j} size="small" color="white" disabled>...</Button>);
				}

				// Промежуток от первой и до последней страницы с точками
				if (activePage > 4 && activePage < pageCount - 3) {
					if (j === 1 || j === pageCount || (activePage - 2 < j && activePage + 2 > j))
						pageButtons.push(this.renderButton(j));
					else if (j === 2 || j === pageCount - 1)
						pageButtons.push(<Button className={style.disabled} key={j} size="small" color="white" disabled>...</Button>);
				}

				// если активная страница доходит почти до конца или равна последней
				if (activePage > pageCount - 4) {
					if (j > pageCount - 5 || j === 1)
						pageButtons.push(this.renderButton(j));
					else if (j === pageCount - 5)
						pageButtons.push(<Button className={style.disabled} key={j} size="small" color="white" disabled>...</Button>);
				}

			}
		} else {
			for (let i = 0; i < pageCount; i++) {
				pageButtons.push(this.renderButton(i + 1));
			}
		}

		return pageButtons;
	};

	setPage = crement => {
		const { activePage, setPage, pageCount, setAjaxPage } = this.props;
		if (crement === 'minus')
			if (activePage - 1 !== 0) setPage(activePage - 1);
		if (crement === 'plus')
			if (activePage + 1 !== pageCount + 1) setPage(activePage + 1);

		if (crement === 'ajax')
			if (activePage + 1 !== pageCount + 1) setAjaxPage(activePage + 1);
	};

	render() {

		const { activePage, pageCount } = this.props;

		const renderClass = `${style.pagination} ${pageCount > 5 ? style.lotPages : ''}`;

		return (
			<div className={renderClass}>

				<Button
					disabled={activePage === 1}
					className={style.prevBtn}
					onClick={() => this.setPage('minus')}
				>
					Назад
				</Button>

				<div className={style.page_list}>
					{ this.renderPageButtons() }
				</div>

				<Button
					disabled={activePage === pageCount}
					className={style.nextBtn}
					onClick={() => this.setPage('plus')}
				>
					Вперед
				</Button>
			</div>
		)
	}
}

export default AdminPagination;
