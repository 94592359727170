import showError from 'libs/showFormErrors';
import {required} from 'libs/validationsForms';
import {changePriceCargoRequest} from 'actions/customer';
import {add} from 'actions/notifications';
import {cargoFormToRequestServer} from 'libs/maper';
import {SubmissionError} from "redux-form";


const submitChangePrice = (offer, price, dispatch) => {
	showError({price: [required(price)]});
	offer.route.price = price;

	return new Promise((resolve, reject) => {
		dispatch(changePriceCargoRequest(cargoFormToRequestServer(offer), {
			resolve: resolve,
			reject: reject,
		}));
	}).then(() => {
		dispatch(add({
			time: 100,
			type: 'success',
			text: 'Новая ставка сохранена',
		}));
	}).catch(err => {
		console.error('Error submitChangePrice: ', err);
		dispatch(add({
			time: 100,
			type: 'error',
			text: 'Ошибка при обновлении ставки',
		}));
		throw new SubmissionError({...err, _error: 'bad'});
	});
};

export default submitChangePrice;