import {driverActions} from 'actions/driver';

export const getMarks = (dispatch, success) => {
	return new Promise((resolve, reject) => {
		dispatch(driverActions.getMarks(null, {
			resolve: resolve,
			reject: reject,
		}));
	}).then(marks => {
		success(marks);
	}).catch(err => {
		console.error('Error getMarks: ', err);
		success([]);
	});
};

export const getModels = (markID, dispatch, success) => {
	return new Promise((resolve, reject) => {
		dispatch(driverActions.getModels(markID, {
			resolve: resolve,
			reject: reject,
		}));
	}).then(marks => {
		success(marks);
	}).catch(err => {
		console.error('Error getModels: ', err);
		success([]);
	});
};

export const getSeries = (modelID, dispatch, success) => {
	return new Promise((resolve, reject) => {
		dispatch(driverActions.getSeries(modelID, {
			resolve: resolve,
			reject: reject,
		}));
	}).then(marks => {
		success(marks);
	}).catch(err => {
		console.error('Error getSeries: ', err);
		success([]);
	});
};