const mapperFromRouteToSearch = route => {
	
	const point_a = {
		city: route.items[0].city,
		country: route.items[0].country,
		locality: route.items[0].locality,
		name: route.items[0].address,
		pos: route.items[0].latitude + ' ' + route.items[0].longitude,
		region: route.items[0].region,
	};
	
	let point_b = null;
	
	if (route.geography !== 'city')
		point_b = {
			city: route.items[route.items.length - 1].city,
			country: route.items[route.items.length - 1].country,
			locality: route.items[route.items.length - 1].locality,
			name: route.items[route.items.length - 1].address,
			pos: route.items[route.items.length - 1].latitude + ' ' + route.items[route.items.length - 1].longitude,
			region: route.items[route.items.length - 1].region,
		};
	
	return {
		point_a,
		point_b,
		sort: 'price',
		startDate: route.items[0].startDate,
		volume: route.bodySize,
		carLoadType: route.transport.loadType,
		carBodyType: route.transport.bodyType,
		geography: route.geography,
		page: 1,
		pageSize: 4,
	};
};

export default mapperFromRouteToSearch;