import { NotNotification } from "components/Events/NotNotification";
import Button from 'components/UI/Form/Button';
import PropTypes from 'prop-types';
import React from 'react';
import CustomScroll from 'react-custom-scroll';
import NotificationWS from './NotificationWS';
import style from './style.less';


class EventsList extends React.PureComponent {

    static propTypes = {
        events: PropTypes.any.isRequired,
        readAllNotification: PropTypes.func.isRequired,
        readNotification: PropTypes.func.isRequired
    };

    render() {
        let { events, readAllNotification, readNotification } = this.props,
            amountEvents = events.length;
            events = [...events].reverse();

            // console.log(events);

        return (
            <div className={style.event__list}>
                <div className={style.event__header}>Уведомления</div>
                {
                    amountEvents === 0 &&
                    <NotNotification/>
                }
                {
                    amountEvents > 0 &&
                    <React.Fragment>
                        <div className={style.event__body}>
                            <CustomScroll heightRelativeToParent={'100%'}>
                                {
                                    events.map(el =>
                                        <NotificationWS
                                            el={el}
                                            key={el.id}
                                            read={() => readNotification(el.id)}
                                            role={this.props.role}
                                            profile={this.props.profile}
                                        />
                                    )
                                }
                            </CustomScroll>
                        </div>
                        <div className={style.event__footer}>
                            <Button
                                className={style.btn__read}
                                onClick={readAllNotification}
                                disabled={amountEvents === 0}>
                                Отметить все как прочитано
                            </Button>
                        </div>
                    </React.Fragment>
                }
            </div>
        );

    }
}


export default EventsList;