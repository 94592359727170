import React from 'react';
import Aside from 'components/UI/Wrappers/Aside';
import AsideItem from 'components/UI/AsideItems/AsideItem';

const Controls = props => {
	
	const { changeStatus } = props;
	
	return (
		<Aside>
			<div>
				Что сделать со сделкой?
			</div>
			<AsideItem onClick={() => changeStatus('trade-returned-to-work')}>Вернуть сделку в работу</AsideItem>
			<AsideItem onClick={() => changeStatus('decision-favor-customer')}>Решение в пользу клиента</AsideItem>
			<AsideItem onClick={() => changeStatus('decision-favor-driver')}>Решение в пользу водителя</AsideItem>
		</Aside>
	)
};

export default Controls;