import React from 'react';
import style from 'components/Admin/UI/LeftContent/style.less';
import PropTypes from 'prop-types';


const LeftContent = props => {
	
	return (
		<div className={style.menu}>
			<h3>{ props.title }</h3>
			<div>{ props.children }</div>
		</div>
	)
};


LeftContent.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	title: PropTypes.string,
};

LeftContent.defaultProps = {
	children: '',
	className: '',
	title: '',
};


export default LeftContent;