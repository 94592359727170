import React from 'react';
import listStyle from 'components/Admin/UI/List/style.less';


const StatisticResult = props => {
	
	return (
		<li className={listStyle.item}>
			<div className={listStyle.item__date}>
				Весь период
			</div>
			<div className={listStyle.item__count}>
				219
			</div>
			<div className={listStyle.item__count}>
				25
			</div>
			<div className={listStyle.item__count}>
				29
			</div>
			<div className={listStyle.item__count}>
				210
			</div>
		</li>
	)
};

export default StatisticResult;