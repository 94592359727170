import React from 'react';
import messageStyle from 'components/Chat/center/message.less';


const MessageStatus = ({ status }) => {
	switch (status) {
		case 'upload':
			return <span className={messageStyle.sending} />;
		case 'error':
			return <span className={messageStyle.error} title="Не удалось отправить сообщение">!</span>;
		case null:
			return <span className={messageStyle.notRead} title="Сообщение не прочитано собеседником" />;
		default:
			return null;
	}
};


export default MessageStatus;