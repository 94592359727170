import { createActions } from 'redux-actions';

export const {
    profileDetailActions: {
        getProfileByID,
        setProfileByID,
        getReviewsByID,
        setReviewsByID,
    }
} = createActions({
    profileDetailActions: {
        getProfileByID: [payload => payload, (payload, meta) => meta],
        setProfileByID: [payload => payload, (payload, meta) => meta],
        getReviewsByID: [payload => payload, (payload, meta) => meta],
        setReviewsByID: [payload => payload, (payload, meta) => meta],
    }
});