exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3LWDw8-Wl6GX-ERjZ1Bg77 {\n  margin-top: 30px;\n}\n._2X_wgBT0F_zHoXJ0MOXEdI {\n  text-align: right;\n}\n@media screen and (max-width: 992px) {\n  ._3BwlcIQNLe3HNrEb4h7WSs {\n    grid-template-columns: 50% 50%;\n  }\n}\n@media screen and (max-width: 400px) {\n  ._3BwlcIQNLe3HNrEb4h7WSs {\n    grid-template-columns: 100%;\n  }\n  ._2X_wgBT0F_zHoXJ0MOXEdI {\n    text-align: left;\n    margin-bottom: 20px;\n    grid-row: 1;\n  }\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/ReduxForms/LoginForm/style.less"],"names":[],"mappings":"AAAA;EACE,iBAAiB;CAClB;AACD;EACE,kBAAkB;CACnB;AACD;EACE;IACE,+BAA+B;GAChC;CACF;AACD;EACE;IACE,4BAA4B;GAC7B;EACD;IACE,iBAAiB;IACjB,oBAAoB;IACpB,YAAY;GACb;CACF","file":"style.less","sourcesContent":[".button {\n  margin-top: 30px;\n}\n.rec {\n  text-align: right;\n}\n@media screen and (max-width: 992px) {\n  .passRemember {\n    grid-template-columns: 50% 50%;\n  }\n}\n@media screen and (max-width: 400px) {\n  .passRemember {\n    grid-template-columns: 100%;\n  }\n  .rec {\n    text-align: left;\n    margin-bottom: 20px;\n    grid-row: 1;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"button": "_3LWDw8-Wl6GX-ERjZ1Bg77",
	"rec": "_2X_wgBT0F_zHoXJ0MOXEdI",
	"passRemember": "_3BwlcIQNLe3HNrEb4h7WSs"
};