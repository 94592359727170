exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.pkSHKolZP5ZSxuASzSsVH {\n  position: absolute;\n  top: 100%;\n  left: 50%;\n  margin-top: 15px;\n  transform: translateX(-50%);\n  background: #808c99;\n  color: #fff;\n  padding: 10px 15px;\n  border-radius: 3px;\n  box-shadow: 1px 3px 19px 5px rgba(41, 49, 61, 0.18);\n  opacity: 0;\n  pointer-events: none;\n  transition: all 0.3s;\n  z-index: 10;\n  font-size: 14px;\n}\n.pkSHKolZP5ZSxuASzSsVH:after {\n  position: absolute;\n  content: '';\n  height: 0;\n  width: 0;\n  border: 4px solid transparent;\n  border-bottom-color: #808c99;\n  bottom: 100%;\n  left: 50%;\n  transform: translateX(-50%);\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/UI/Tooltip/style.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,mBAAmB;EACnB,UAAU;EACV,UAAU;EACV,iBAAiB;EACjB,4BAA4B;EAC5B,oBAAoB;EACpB,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;EACnB,oDAAoD;EACpD,WAAW;EACX,qBAAqB;EACrB,qBAAqB;EACrB,YAAY;EACZ,gBAAgB;CACjB;AACD;EACE,mBAAmB;EACnB,YAAY;EACZ,UAAU;EACV,SAAS;EACT,8BAA8B;EAC9B,6BAA6B;EAC7B,aAAa;EACb,UAAU;EACV,4BAA4B;CAC7B","file":"style.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.tooltip {\n  position: absolute;\n  top: 100%;\n  left: 50%;\n  margin-top: 15px;\n  transform: translateX(-50%);\n  background: #808c99;\n  color: #fff;\n  padding: 10px 15px;\n  border-radius: 3px;\n  box-shadow: 1px 3px 19px 5px rgba(41, 49, 61, 0.18);\n  opacity: 0;\n  pointer-events: none;\n  transition: all 0.3s;\n  z-index: 10;\n  font-size: 14px;\n}\n.tooltip:after {\n  position: absolute;\n  content: '';\n  height: 0;\n  width: 0;\n  border: 4px solid transparent;\n  border-bottom-color: #808c99;\n  bottom: 100%;\n  left: 50%;\n  transform: translateX(-50%);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"tooltip": "pkSHKolZP5ZSxuASzSsVH"
};