import React from 'react';
import { connect } from 'react-redux';
import { getChatDialogs, getCountDontReadMsg } from 'libs/selections';
import { setItem } from 'libs/sessionStorage';
import { Comment } from 'components/UI/Icons';
import DialogChat from 'components/Chat';
import Tooltip from 'components/UI/Tooltip';
import styles from 'components/UI/HeaderMainMenu/style.less';


class SmallChat extends React.PureComponent {
	state = {
		open: false
	};
	
	toggleDialog = (open = false) => {
		setItem('chat', JSON.stringify({open}));
		this.setState({ open });
	};
	
	
	render() {
		return (
			<div>
				<span onClick={() => this.toggleDialog(true)} className={styles.badge}>
					{Comment()}
					{
						this.props.count > 0
						&& <span className={styles.notify}>{this.props.count}</span>
					}
					<Tooltip text="Сообщения" className={styles.tooltip}/>
				</span>
				{
					this.state.open &&
					<DialogChat handleClose={() => this.toggleDialog(false)}/>
				}
			</div>
		);
	}
}


export default connect(
	store => ({
		dialogs: getChatDialogs(store),
		count: getCountDontReadMsg(store),
	})
)(SmallChat);