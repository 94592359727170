import React from 'react';
import Container from 'components/UI/Wrappers/Container';
import Button from 'components/UI/Form/Button';
import style from './style.less';
import { Link } from 'react-router-dom';

class HowBeginWork extends React.Component {
	state = {
		list: this.props.list ? this.props.list : [],
	};

	render() {
		
		const link = this.props.role === 'guest' ? '/registration/driver' : this.props.role === 'driver' ? '/new/route' : '/new/offer';
		
		return (
			<div className={style.how_begin_work}>
				<Container>
					<h3 className={style.section_title}>Как начать работу</h3>
					<div className={style.list}>
						{
							this.state.list.map((el, i) => {
								return (
									<div key={i}>
										<div className={style.number}>{ i + 1 }</div>
										<p>{ el.text }</p>
									</div>
								)
							})
						}
					</div>
					<div className={style.textcenter}>
					<Link to={link}><Button size="large">Начать работать с сервисом</Button></Link>
					</div>
				</Container>
			</div>
		)
	}
}

export default HowBeginWork;
