import React from 'react';
import Select from 'components/UI/Form/Fields/Select';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';

const ModelCar = ({models, onChange}) => {
	return (
		<Field
			disabled={models.length === 0}
			onChange={(e, val)=>onChange('model', val)}
			name="carModelId"
			component={Select}
			filter
			label="Модель транспорта"
		>
			{models.map(it => <OptionSelect key={it.id} value={it.id} filterStr={it.title}>{it.title}</OptionSelect>)}
		</Field>
	);
};

ModelCar.propTypes = {
	models: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default ModelCar;