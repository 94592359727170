const russian = {
	// типы груза
	building_materials: 'Строительные материалы',
	furniture: 'Фурнитура',
	consumer_goods: 'ТНП',
	equipment_and_spare_parts: 'Оборудование и запчасти',
	food_stuffs: 'Продукты питания',
	pipes: 'Трубы',
	car: 'Автомобиль(ли)',
	auto_tires: 'Автошины',
	alcoholic_drinks: 'Алкогольные напитки',
	paper: 'Бумага',
	appliances: 'Бытовая техника',
	household_chemicals: 'Бытовая химия',
	clapboard: 'Вагонка',
	gas_silicate_blocks: 'Газосиликатные блоки',
	gypsum: 'Гипс',
	corrugated_cardboard: 'Гофрокартон',
	doors: 'Двери',
	dsp: 'ДСП',
	home_moving: 'Домашний переезд',
	boards: 'Доски',
	wood: 'Древесина',
	charcoal: 'Древесный уголь',
	concrete_goods: 'ЖБИ',
	grain_and_seeds: 'Зерно и семена',
	toys: 'Игрушки',
	leather_products: 'Изделия из кожи',
	metal_products: 'Изделия из металла',
	rubber_products: 'Изделия из резины',
	tool: 'Инструмент',
	cable: 'Кабель',
	casein: 'Казеин',
	stationery: 'Канц. товары',
	brick: 'Кирпич',
	carpets: 'Ковры',
	computers: 'Компьютеры',
	confectionery: 'Кондитерские изделия',
	canned_food: 'Консервы',
	container_20ft: 'Контейнер 20фут',
	container_40ft: 'Контейнер 40фут',
	tank_container_20ft: 'Танк-контейнер 20фут',
	tank_container_40ft: 'Танк-контейнер 40фут',
	chipboard: 'ЛДСП',
	waste_paper: 'Макулатура',
	medicines: 'Медикаменты',
	metal: 'Металл',
	scrap_metal: 'Металлолом',
	metal_rolling: 'Металлопрокат',
	oil_products: 'Нефтепродукты',
	medical_equipment: 'Оборудование медицинское',
	refractory_products: 'Огнеупорная продукция',
	clothing: 'Одежда',
	perfumes_and_cosmetics: 'Парфюмерия и косметика',
	styrofoam: 'Пенопласт',
	sand: 'Песок',
	lumber: 'Пиломатериалы',
	plastic: 'Пластик',
	pallets: 'Поддоны',
	professional_sheet: 'Профлист',
	plubming: 'Сантехника',
	consoldated_cargo: 'Сборный груз',
	glass_and_porcelain: 'Стекло и фарфор',
	glassware: 'Стеклотара (бутылки и другое)',
	sandwich_panels: 'Сэндвич-панели',
	tobacco_products: 'Табачные изделия',
	package_and_packaging: 'Тара и упаковка',
	textile: 'Текстиль',
	vehicles: 'Транспортные средства',
	fertilizers: 'Удобрения',
	heater: 'Утеплитель',
	plywood: 'Фанера',
	chemical_products: 'Хим. продукты',
	household_goods: 'Хозтовары',
	refrigeration_equipment: 'Холодильное оборудование',
	flowers: 'Цветы',
	cement: 'Цемент',
	sleepers: 'Шпалы',
	breakstone: 'Щебень',
	electronics: 'Электроника',

	// типы кузова
	awning: 'Тентованный',
	awningend: 'Тентованный',
	container: 'Контейнер',
	van: 'Фургон',
	allmetal: 'Цельнометалл.',
	isometric: 'Изометрический',
	refrigerator: 'Рефрижератор',
	onboard: 'Бортовой',
	open_container: 'Открытый конт.',
	platform_without_side: 'Площадка без бортов',
	dump_truck: 'Самосвал',
	scow: 'Шаланда',
	low_loader: 'Низкорамный',
	telescopic: 'Телескопический',
	trawl: 'Трал',
	bulk_carrier: 'Балковоз(негабарит)',
	bus: 'Автобус',
	car_transporter: 'Автовоз',
	aerial_platform: 'Автовышка',
	auto_transporter: 'Автотранспортер',
	concrete_truck: 'Бетоновоз',
	bitumen_truck: 'Битумовоз',
	gasoline_tanker: 'Бензовоз',
	grain_truck: 'Зерновоз',
	horse_transport: 'Коневоз',
	feed_truck: 'Кормовоз',
	timber_truck: 'Лесовоз',
	lomovoz: 'Ломовоз',
	manipulator: 'Манипулятор',
	minibus: 'Микроавтобус',
	flour_truck: 'Муковоз',
	panel_truck: 'Панелевоз',
	pickup: 'Пикап',
	harbor_truck: 'Пухтовоз',
	glass_ship: 'Стекловоз',
	pipe_carrier: 'Трубовоз',
	cement_truck: 'Цементовоз',
	tank: 'Цистерна',
	chip_truck: 'Щеповоз',
	tow_truck: 'Эвакуатор',
	garbage_truck: 'Мусоровоз',
	jumbo: 'Джамбо (jumbo)',


	noneend: 'Любой тип кузова',



	// типы погрузки
	manual: 'Задняя', // возможно изменить
	not: 'Нет',
	crane: 'Кран',
	manualSipping: 'Ручная погрузка',



	accepted: 'Принято',
	rejected: 'Отклонено',
	created: 'Создана',
	wait_customer: 'Ожидает рассмотрения пользователем',
	cancelled: 'Отменено',
	customer: 'Заказчик',
	driver: 'Водитель',
	unloading_required: 'Требуется разгрузка',
	none: 'Любой тип кузова',
	box: 'Коробка',
	ru: 'Русский',
	en: 'Английский',
	pay_problem: 'Проблема оплаты',
	deadline_problem: 'Проблема сроков',
	lost: 'Потерял груз',
	other: 'Другое',
	WarningForCustomerOfferAgree: 'Внимательно проверьте все параметры сделки, ранее согласованные с выбранным исполнителем, и нажмите кнопку "Заключить сделку"',
	RequestID: 'Заявка №',
	DataAboutOfferWith: 'Данные по сделке с ',
	LeaveFeedback: 'Оставить отзыв',
	ContactInfo: 'Контактная информация',
	InfoAboutDriver: 'Информация о перевозчике',
	CreatedOffers: 'Создано заявок',
	EndedOffers: 'Выполненных заявок',
	AboutCustomer: 'О клиенте',
	AboutDriver: 'О водителе',
	NotSpecified: 'Не указано',
	period7_days: '7 дней',
	period14_days: '14 дней',
	period30_days: '30 дней',
	onNotificationProposals: 'Включить уведомления о новых предложениях по email',
	Proposal_driver_route: 'Вы можете просмотреть поступающие заявки от клиентов, пообщаться с ними, и выбрать какой груз хотите перевезти.',
	Proposal_customer_route: 'Здесь показаны все предложения от клиентов. Водитель может изучить каждую заявку',
	Proposal_driver_offer: 'Здесь показаны все предложения по данной заявке. Клиент может выбрать любого из списка',
	Proposal_customer_offer: 'Вы можете назначить перевозчиком любого пользователя, который оставил предложение к вашему заданию или подождать еще предложений.',
	notVerifyAccount_offerPage: { one: 'Ваш аккаунт не верифицирован.', two: 'Вы сможете оставлять предложения на перевозку после полного заполнения профиля и подтверждения данных со стороны администрации'},

	Proposal_driver_route_empty: 'Предложений от клиентов еще не поступало, Вы можете подождать, либо воспользоваться поиском грузов и предложить им свои услуги.',
	Proposal_customer_route_empty: 'По данному маршруту еще не было ни одного отклика, предложите свою заявку на перевозку первым.',
	Proposal_driver_offer_empty: 'По данной заявке еще не было ни одного отклика, предложите свои услуги на перевозку первым.',
	Proposal_customer_offer_empty: 'Предложений от водителей еще не поступало, Вы можете подождать, либо воспользоваться поиском маршрутов и предложить водителю перевезти Ваш груз.',


	errorPassword: 'Пароль должен содержать не менее 6 символов',
	errorPasswordConfirm: 'Пароли не совпадают',
	errorPhone: 'Вы ввели номер не полностью, либо неверного формата',
	errorCode: 'Код состоит из четырех цифр',
	errorName: 'Имя должно быть написано кириллицей и содержать не менее трех символов',
	errorCardName: 'Имя должно быть написано латиницией и содержать не менее трех символов',

	ru_passport: 'Россия',

	status_moderation: 'На модерации',
	status_published: 'Опубликовано',
	status_trading_start: 'В сделке',
	status_trading_arbitration: 'В арбитраже',

};
export default russian;
