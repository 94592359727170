exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n._17lP3kwOYp2htQsl9P99pB button {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  color: #747984;\n  width: 100%;\n  transform: none!important;\n  padding: 15px 0;\n  border-bottom: 1px solid #e4f0f9 !important;\n  border-radius: 0;\n}\n._17lP3kwOYp2htQsl9P99pB button svg {\n  fill: #848484;\n  flex: 0 0 9px;\n  margin-right: 10px;\n  transition: all 0.3s;\n}\n._17lP3kwOYp2htQsl9P99pB ._1bjBPamnyHFH1-rUpaIbrg {\n  padding: 15px 0;\n  display: none;\n}\n._17lP3kwOYp2htQsl9P99pB.i2xF9atM0O7e4RT_H-IPg button svg {\n  transform: rotate(180deg);\n}\n._17lP3kwOYp2htQsl9P99pB.i2xF9atM0O7e4RT_H-IPg ._1bjBPamnyHFH1-rUpaIbrg {\n  display: block;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/UI/Accordion/styles.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,cAAc;EACd,+BAA+B;EAC/B,oBAAoB;EACpB,eAAe;EACf,YAAY;EACZ,0BAA0B;EAC1B,gBAAgB;EAChB,4CAA4C;EAC5C,iBAAiB;CAClB;AACD;EACE,cAAc;EACd,cAAc;EACd,mBAAmB;EACnB,qBAAqB;CACtB;AACD;EACE,gBAAgB;EAChB,cAAc;CACf;AACD;EACE,0BAA0B;CAC3B;AACD;EACE,eAAe;CAChB","file":"styles.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.accordion button {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  color: #747984;\n  width: 100%;\n  transform: none!important;\n  padding: 15px 0;\n  border-bottom: 1px solid #e4f0f9 !important;\n  border-radius: 0;\n}\n.accordion button svg {\n  fill: #848484;\n  flex: 0 0 9px;\n  margin-right: 10px;\n  transition: all 0.3s;\n}\n.accordion .content {\n  padding: 15px 0;\n  display: none;\n}\n.accordion.open button svg {\n  transform: rotate(180deg);\n}\n.accordion.open .content {\n  display: block;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"accordion": "_17lP3kwOYp2htQsl9P99pB",
	"content": "_1bjBPamnyHFH1-rUpaIbrg",
	"open": "i2xF9atM0O7e4RT_H-IPg"
};