import React from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm, stopSubmit} from 'redux-form';
import Button from 'components/UI/Form/Button';
import Textarea from 'components/UI/Form/Fields/Textarea';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import style from './style.less';

let RequestEditOffer = (props) => {
	let { handleSubmit, invalid, submitting } = props;
	return (
		<form onSubmit={handleSubmit} autoComplete="off">
			<LayoutField>
				<span>
					Вы увидели неверные данные по сделке? Отправьте клиенту уведомление с помощью формы. После
					исправления вам поступит смс оповещение.
				</span>
				<Field
					name={'text'}
					component={Textarea}
					label={'Ваше сообщение клиенту'}
				/>
			</LayoutField>
			<Button
				className={style.button}
				disabled={invalid}
				loading={submitting}
				size={'large'}
				type={'submit'}
			>
				Отправить
			</Button>
		</form>
	);
};

RequestEditOffer.propTypes = {
	handleSubmit: PropTypes.func,
	error: PropTypes.string,
	submitting: PropTypes.bool,
	invalid: PropTypes.bool,
};

RequestEditOffer.defaultProps = {
	handleSubmit: () => {
	},
	invalid: false,
	submitting: false,
	error: '',
};

RequestEditOffer = reduxForm({
	form: 'RequestEditOffer',
	onChange: (newValues, dispatch) => {
		dispatch(stopSubmit('RequestEditOffer', {}));
	}
})(RequestEditOffer);

export default RequestEditOffer;