import React from 'react';
import { convertNumberSpaces } from 'libs/convertNumberSpaces';
import { dateToString, stringToDate } from 'libs/convertDate';
import { Fire } from 'components/UI/Icons';
import { Link } from 'react-router-dom';
import style from '../style.less';

const OfferItem = props => {
	const { offer, role } = props;
	const cargo = props.offer.cargo;
	let pointOne = offer.route.items[0],
			pointTwo = offer.route.items[offer.route.items.length - 1];
	let titleOne = pointOne.locality ? pointOne.locality : pointOne.address,
			titleTwo = pointTwo.locality ? pointTwo.locality : pointTwo.address;

	return (
		<div className={style.offer_item}>
			<Link to={role === 'guest' ? `/auth` : `/offer/${offer.id}`} />
			<div className={style.items}>
				<div className={style.createTime}>{ dateToString(stringToDate(offer.createTime), 'dayAndMonth') }</div>
				<div className={style.route}>{ titleOne } - { titleTwo }</div>
				<div className={style.cargo}>{cargo.categoryId && `${cargo.categoryId.titleRu},`} {cargo.title} {cargo.weight} кг.</div>
				<div className={style.cargo}>{offer.bodyTypeId && `${offer.bodyTypeId.titleRu},`} {offer.loadTypeId && offer.loadTypeId.titleRu}</div>
				<div className={style.price}>{ Fire() } { convertNumberSpaces(offer.price) } р.</div>
			</div>
		</div>
	)
};

export default OfferItem;
