import React from 'react';
import { Field, reduxForm, stopSubmit } from 'redux-form';
import Button from 'components/UI/Form/Button';
import InputSelectGroup from 'components/UI/Wrappers/InputSelectGroup';
import Input from 'components/UI/Form/Fields/Input';
import Select from 'components/UI/Form/Fields/Select';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import style from './style.less';

class BecomeCarrierRegForm extends React.Component {
	render() {
		let {handleSubmit} = this.props;
		return (
			<form className={style.form} onSubmit={handleSubmit} autoComplete="off">
				<InputSelectGroup>
					<Field
						name="phone_number"
						type="text"
						component={Input}
						label="Телефон"
						mask="9999 99 99 99"
						containerGroup="left"
					/>
					<Field
						name="code"
						component={Select}
						childGroup="left"
					>
						<OptionSelect value='7'>+7</OptionSelect>
						<OptionSelect value='375'>+375</OptionSelect>
						<OptionSelect value='98'>+98</OptionSelect>
					</Field>
				</InputSelectGroup>
				<div className={style.btn}>
					<Button
						color="red"
						size="large"
						type="submit"
					>
						Зарегистрироваться
					</Button>
				</div>
			</form>
		);
	}
}

BecomeCarrierRegForm = reduxForm({
	form: 'becomeCarrierRegForm',
	onChange: (newValues, dispatch) => {
		dispatch(stopSubmit('becomeCarrierRegForm', {}));
	}
})(BecomeCarrierRegForm);

export default BecomeCarrierRegForm;
