import React, { PureComponent } from 'react';
import style from 'components/Chat/style.less';


class ChatFilter extends PureComponent {
	
	
	render() {
		let { value, handleFilter } = this.props;
		
		return (
			<form autoComplete="off">
				<input
					type="text"
					name="search"
					value={value}
					onChange={handleFilter}
					placeholder="Поиск"
					className={style.input}
				/>
			</form>
		)
	}
}


export default ChatFilter;