import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './styles.less';
import PageCargoTitle from 'components/UI/Wrappers/PageCargoTitle';
import ActionMenu from 'components/UI/ActionMenu';
import RequestPoints from 'components/UI/Request/RequestPoints';
import { DeleteDialog } from 'components/Profile/MyCargoDetail/Dialogs';
import { rmCargoRequest } from 'actions/customer';
import cargoServerToClient from 'mappers/cargoServerToClient';
import forwardTo from 'libs/forwardTo';
import { dateToString, stringToDate } from 'libs/convertDate';

class PreviewOffer extends React.Component {

	static propTypes = {
		offer: PropTypes.object,
		type: PropTypes.string,
		actionCancelled: PropTypes.func,
	};
	
	static defaultProps = {
		offer: undefined,
		type: undefined,
		actionCancelled: () => {},
	};

	state = {
		removeOfferID: 0,
	};

	actionEdit = () => {
		sessionStorage.setItem('offerForm', JSON.stringify(cargoServerToClient(this.props.offer)));
		forwardTo('/edit/offer/');
	};

	actionDelete = id => this.setState({ removeOfferID: id });

	removeCustomerRequest = () => {
		this.props.dispatch(rmCargoRequest(this.state.removeOfferID));
		this.actionDelete(0);
	};


	render () {
		let { offer, type } = this.props;
		if (offer === undefined || offer === null)
			return (
				<div className={styles.preview}>
					<div className={styles.header}>Удалено</div>
					<div className={styles.body}>Удалено</div>
					<div className={styles.footer}>Удалено</div>
				</div>
			);
		
		const url = `/offer/${offer.id}`;
		const createTime = dateToString(stringToDate(offer.createTime), 'default');

		return (
			<Fragment>
				<div className={styles.preview}>
					<Link className={styles.url} to={url} />
					<div className={styles.header}>
						<div>
							<PageCargoTitle cargo={offer.cargo} />
						</div>
						<ActionMenu
							type={type === undefined ? 'offer' : type === 'proposal' ? type : ''}
							actionEdit={this.actionEdit}
							actionDelete={() => this.actionDelete(offer.id)}
							actionCancelled={this.props.actionCancelled}
							// строчка выше это отменить предложение
						/>
					</div>
					<div className={styles.body}>
						<RequestPoints points={offer.route.items} />
					</div>
					<div className={styles.footer}>
						<div className={styles.grayColor}>Заявка №{offer.id} от { createTime }</div>
						<Link to={url} className={styles.buttonHover}>Подробнее</Link>
					</div>
				</div>
				{
					this.state.removeOfferID !== 0 &&
					<DeleteDialog
						isRoute={false}
						cancel={() => this.actionDelete(0)}
						remove={this.removeCustomerRequest}
					/>
				}
			</Fragment>
		)
	}
}

export default connect()(PreviewOffer);