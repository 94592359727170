import React from 'react';
import Grid from 'components/UI/Wrappers/Grid';
import Panel from 'components/UI/Wrappers/Panel';
import { getVolume } from 'libs/getVolume';
import { LabelInfo } from 'components/UI/Form/Labels';
import style from './style.less';
import weightConversion from 'libs/weightConversion';

const InfoCargo = props => {
	const length = props.cargo.length,
		width = props.cargo.width,
		height = props.cargo.height,
		volume = { height, width, length };
	return (
		<Panel title="Информация о грузе" marginBottom={30}>
			<Grid col="2" className={style.gridPadding}>
				<div>
					<LabelInfo title="Тип груза">{props.cargo.categoryId && props.cargo.categoryId.titleRu}</LabelInfo>
					<LabelInfo title="Вес">{ weightConversion(props.cargo.weight) }</LabelInfo>
					<LabelInfo title="Габариты с упаковкой">
						Д { length }м <span className={style.gray}>x</span> Ш { width }м <span className={style.gray}>x</span> В { height }м, { getVolume(volume) }м<sup>3</sup>
					</LabelInfo>
					{
						props.cargo.additionIds.length > 0 &&
						<LabelInfo title="Дополнительные услуги">
							{
								props.cargo.additionIds.map((el,i) => {
									return `${el.additionId && el.additionId.titleRu} ${i + 1 !== props.cargo.additionIds.length ? ', ' : ''}`;
								})
							}
						</LabelInfo>
					}
				</div>
				<div>
					<LabelInfo title="Название товара">{ props.cargo.title }</LabelInfo>
					<LabelInfo title="Грузовых мест">
						{ props.cargo.weightCount ? props.cargo.weightCount : 'Не указано' }
					</LabelInfo>
					<LabelInfo title="Упаковка">
						{ props.cargo.packageId ? props.cargo.packageId.titleRu : 'Не указано' }
					</LabelInfo>
				</div>
			</Grid>
		</Panel>
	)
};

export default InfoCargo;
