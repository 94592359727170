import {object} from 'prop-types';
import showError from 'libs/showFormErrors';
import {required} from 'libs/validationsForms';
import {driverActions} from 'actions/driver';
import {add} from 'actions/notifications';
import {SubmissionError} from 'redux-form';


let isObject = function (a) {
    return (!!a) && (a.constructor === Object);
};

export const setCar = (values:object, dispatch, success) => {
	showError({
		vinNumber: [required(values.vinNumber)],
		carMarkId: [required(values.carMarkId)],
		// size: [required(values.size)],
		carrying: [required(values.carrying)],
		carModelId: [required(values.carModelId)],
		carSeriesId: [required(values.carSeriesId)],
		registrationNumber: [required(values.registrationNumber)],
		photoIds: [required(values.photoIds)],
	});

    if (values.photoIds && isObject(values.photoIds)) {
        values.photoIds = values.photoIds.images.map(it => typeof(it) === 'string' ? it : it.id.toString());
    } else {
        values.photoIds = values.photoIds.map(it => typeof(it) === 'string' ? it : it.id.toString());
    }

	// delete in future from form
	values.carType = 'truck';

	return new Promise((resolve, reject) => {
		dispatch(driverActions.addCar(values, {
			resolve: resolve,
			reject: reject,
		}));
	}).then(() => {
		dispatch(add({
			type: 'success',
			text: values.id ? 'Данные о транспорте были успешно изменены' : 'Добавлен новый транспорт',
			time: 10
		}));
		success();
	}).catch(err => {
		dispatch(add({
			type: 'error',
			text: values.id ? 'Произошла ошибка при изменении данных транспорта' : 'Произошла ошибка при добавлении транспорта',
			time: 10
		}));
		throw new SubmissionError({...err, _error: 'bad'})
	});
};

export const submitToCreateRoute = (values, dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(driverActions.createRoute(values, {
			resolve: resolve,
			reject: reject,
		}));
	}).then(() => {
		dispatch(add({
			type: 'success',
			text: 'Маршрут сохранён ',
			time: 10
		}));
	}).catch(errors => {
		dispatch(add({
			type: 'error',
			text: 'Произошла ошибка при сохранении маршрута',
			time: 10
		}));
		throw new SubmissionError({...errors, _error: 'bad'})
	});
};