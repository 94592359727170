import React from 'react';
import styles from '../style.less';
import { weightConversionShort } from 'libs/weightConversion';

const Cargo = ({ data }) => {
	if (data === undefined || data === null) return null;
	return (
		<div className={styles.cargodata}>
			<sup />
			<span>{ data.categoryId && `${data.categoryId.titleRu}, ` }</span>
			<span>{ data.title }</span>,&nbsp;
			<span>{ weightConversionShort(data.weight) }</span>
		</div>
	)
};


export default Cargo;
