import Button from 'components/UI/Form/Button';
import Address2 from "components/UI/Form/Fields/Address2";
import AddressGroup from "components/UI/Form/Fields/AddressGroup";
import Checkbox from 'components/UI/Form/Fields/Checkbox';
import Input from 'components/UI/Form/Fields/Input';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import Select from 'components/UI/Form/Fields/Select';
import Grid from 'components/UI/Wrappers/Grid';
import InputSelectGroup from "components/UI/Wrappers/InputSelectGroup";
import LayoutField from 'components/UI/Wrappers/LayoutField';
import Panel from 'components/UI/Wrappers/Panel';
import { getPoints } from 'libs/selections';
import PropTypes from 'prop-types';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { change, Field, getFormValues, reduxForm, stopSubmit } from 'redux-form';
import getCarsSelect from 'libs/_V2/getCarsSelect';


class AddDesiredRouteForms extends React.PureComponent {

    state = {
        typeGeography: null
    };

    setValue = (field, value) => {
        this.props.dispatch(change('AddDesiredRouteForms', field, value));
    };

    changeItems = points => {
        this.props.values.items.splice(1, 1000);
        points.forEach((el, index) => {
            this.setValue(`items[${index + 1}].point`, el)
        })
    };

    componentDidMount() {
        if (this.props.isEdit) {
            this.setValue('edit' ,true)
        }
    }

    render() {

        let { handleSubmit, cars, invalid, values, openCreateCar } = this.props;

        const isCountry = values.geography === 'country';
        let ItemsCountry = [];
        if (isCountry && Array.isArray(values.items)) {
            ItemsCountry = values.items.map(el => el.point);
            ItemsCountry.splice(0, 1);
        }




        return (
            <form onSubmit={handleSubmit} autoComplete="off">

                <Panel title="Транспорт" marginBottom={30}>
                    <LayoutField>
                        <Field
                            name="transportId"
                            component={Select}
                            label="Выберите из списка"
                        >
                            { getCarsSelect(cars, openCreateCar) }
                        </Field>
                    </LayoutField>
                </Panel>

                <Panel title="География маршрута" marginBottom={30}>

                    <LayoutField>
                        <Field
                            name="geography"
                            component={Select}
                            label="Выберите из списка"
                        >
                            <OptionSelect value={'city'}>По городу (перевозка в пределах 1 города)</OptionSelect>
                            <OptionSelect value={'region'}>По области (перевозка в пределах 1 области)</OptionSelect>
                            <OptionSelect value={'country'}>По стране (перевозка в нескольких областях, городах)</OptionSelect>
                        </Field>
                    </LayoutField>

                    <LayoutField>
                        <span>
                            {isCountry ? 'Точка отправки' : 'Укажите город или область, используя поиск по буквам'}
                        </span>
                        <InputSelectGroup>
                            <Address2
                                name="items[0].point"
                                dispatch={this.props.dispatch}
                                setValue={this.setValue}
                                containerGroup="right-input"
                                label={'Город, населенный пункт'}
                            />
                        </InputSelectGroup>
                    </LayoutField>

                    {
                        isCountry &&
                        <LayoutField>
                            <span> Укажите попутные города, используя поиск по буквам </span>
                            <InputSelectGroup>
                                <AddressGroup
                                    input={{
                                        onChange: this.changeItems,
                                        value: ItemsCountry
                                    }}
                                    label="Город, населенный пункт, область"
                                />
                            </InputSelectGroup>
                        </LayoutField>
                    }


                    <Grid col="form-2">
                        <Field
                            name="typePeriodPublication"
                            component={Select}
                            label="Период публикации"
                        >
                            <OptionSelect value="7_days">7 дней</OptionSelect>
                            <OptionSelect value="14_days">14 дней</OptionSelect>
                            <OptionSelect value="30_days">30 дней</OptionSelect>
                        </Field>
                    </Grid>
                </Panel>
                <Panel title="Ставка на перевозку" marginBottom={30}>
                    <Grid col="form-2">
                        <LayoutField>
                            <span>Ставка</span>
                            <Field
                                name="price"
                                type="number"
                                unit="р/км."
                                component={Input}
                                label="Укажите вашу ставку"
                            />
                        </LayoutField>
                    </Grid>
                    <LayoutField>
                        <Button disabled={invalid} size="large" type="submit">
                            {this.props.isEdit ? 'Редактировать маршрут' : 'Добавить маршрут'}
                        </Button>
                    </LayoutField>
                    <Field
                        name="agree"
                        component={Checkbox}
                        label={['Я согласен с ', <a target="_blank" href="/agreement_hl.pdf">Правилами сайта</a>]}
                    />
                    <p>
                        Нажимая на кнопку «Разместить заявку», вы соглашаетесь с правилами платежного сервиса
                        «Название сервиса» и сервиса «Сделка без риска». Ваш банк может взымать дополнительную комиссию.
                    </p>
                </Panel>
            </form>
        );
    }
}


AddDesiredRouteForms.propTypes = {
    handleSubmit: PropTypes.func,
    openCreateCar: PropTypes.func,
    error: PropTypes.string,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    cars: PropTypes.array.isRequired,
};

AddDesiredRouteForms.defaultProps = {
    handleSubmit: () => {
    },
    openCreateCar: () => {
    },
    invalid: false,
    submitting: false,
    isEdit: false,
    error: '',
};

AddDesiredRouteForms = connect(
    store => ({
        points: getPoints(store),
        values: getFormValues('AddDesiredRouteForms')(store),
    })
)(AddDesiredRouteForms);

AddDesiredRouteForms = reduxForm({
    form: 'AddDesiredRouteForms',
    onChange: (newValues, dispatch) => {
        dispatch(stopSubmit('AddDesiredRouteForms', {}));
    },
})(AddDesiredRouteForms);

export default AddDesiredRouteForms;