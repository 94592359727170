import { driverActions } from 'actions/driver';
import * as mapAction from 'actions/map';
import { add } from 'actions/notifications';
import { getData } from 'actions/profile';
import forwardTo from 'libs/forwardTo';
import request from 'libs/request';
import { getDriverCarts, getProfileType } from 'libs/selections';
import { statusListRoute } from "libs/vars";
import routeServerToClient from 'mappers/routeServerToClient';
import { call, put, select } from 'redux-saga/effects';

export function* changeDriverLicense(action) {
    const response = yield call(() =>
        request('post', '/api/1.0/profile/my/license', action.payload));

    if (response.data.errors || response.status !== 200) {
        yield call(() => action.meta.reject(response.data.errors));
    } else {
        yield put(getData());
        yield call(() => action.meta.resolve(true));
    }
}

export function* getRouteByID(action) {
    const response = yield call(() =>
        request('get', '/api/1.0/driver/route/' + action.payload));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: response.data.errors,
            time: 10
        }));
    } else {
        let fromServerToClient = routeServerToClient(response.data);
        yield put(mapAction.setPoints(fromServerToClient.items.map(el => el.point)));
        yield put(driverActions.setRouteByID(response.data));
    }
}

export function* saveCar(action) {
    let response = {};

    if (action.payload.id) {
        response = yield call(() =>
            request('put', '/api/1.0/driver/transport/' + action.payload.id, action.payload));
    } else {
        response = yield call(() =>
            request('post', '/api/1.0/driver/transport', action.payload));
    }

    if (response.data.errors || response.status !== 200) {
        if (action.meta) {
            yield call(() => action.meta.reject(response.data.errors));
        }
    } else {
        yield put(driverActions.getMyCars());
        if (action.meta) {
            yield call(() => action.meta.resolve(true));
        }
    }
}

export function* getNowMyRoutes(action) {
    let role = yield select(getProfileType);
    if (role !== 'driver') {
        return false;
    }

    let params = { ...action.payload, pageSize: 30 };
    
    if (action.payload.status === 'active')
        yield put(driverActions.loadingActiveRoute());
    if (action.payload.status === 'cancelled')
        yield put(driverActions.loadingCancelledRoute());
    if (action.payload.status === 'unpublished')
        yield put(driverActions.loadingUnpublishedRoute());
    
    if (action.payload && action.payload.status) params.status = statusListRoute[action.payload.status];

    const response = yield call(() =>
        request('get', '/api/1.0/driver/route/my', params));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Произошла ошибка при получении списка ваших маршрутов',
            time: 10
        }));
    } else {
        if (action.payload.status === 'active')
            yield put(driverActions.setActiveRoute(response.data));
        if (action.payload.status === 'cancelled')
            yield put(driverActions.setCancelledRoute(response.data));
        if (action.payload.status === 'unpublished')
            yield put(driverActions.setUnpublishedRoute(response.data));

    }
}

export function* getNowMyCars() {
    let role = yield select(getProfileType);
    if (role !== 'driver') {
        return false;
    }

    const response = yield call(() =>
        request('get', '/api/1.0/driver/transport/my'));

    if (response.data.errors || response.status !== 200) {
        // yield put(add({
        // 	type: 'error',
        // 	text: 'Произошла ошибка при получении списка ваших машин',
        // 	time: 10
        // }));
        console.error('Произошла ошибка при получении списка ваших машин');
    } else {
        yield put(driverActions.setCars(response.data));
    }
}

export function* removeCar(action) {
    const response = yield call(() =>
        request('delete', '/api/1.0/driver/transport/' + action.payload));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Произошла ошибка при удалении машины',
            time: 10
        }));
    } else {
        yield put(driverActions.getMyCars());
        yield put(add({
            type: 'success',
            text: 'Машина упешно удалена',
            time: 10
        }));
    }
}

export function* createNewRoute(action) {
    let response = {};

    if (action.payload.id) {
        response = yield call(() =>
            request('put', '/api/1.0/driver/route/' + action.payload.id, action.payload));
    } else {
        response = yield call(() =>
            request('post', '/api/1.0/driver/route', action.payload));
    }

    if (response.data.errors || response.status !== 200) {
        yield call(() => action.meta.reject(response.data.errors));
    } else {
        if (action.meta) {
            yield put(driverActions.getRoutes());
            yield call(forwardTo, '/routes/');
            yield call(() => action.meta.resolve(true));
        }
    }
}

export function* addCard(action) {
    let response = {};

    action.payload.cardNumber = action.payload.cardNumber.replace(/\s/g, '');

    if (action.payload.id) {
        response = yield call(() =>
            request('put', '/api/1.0/profile/my/pay-method/' + action.payload.id, action.payload));
    } else {
        response = yield call(() =>
            request('post', '/api/1.0/profile/my/pay-method', action.payload));
    }

    if (response.data.errors || response.status !== 200) {
        yield call(() => action.meta.reject(response.data.errors));
    } else {
        if (action.meta) {
            yield put(driverActions.getCards());
            yield call(() => action.meta.resolve(true));
        }
    }
}

export function* getCards() {
    const response = yield call(() =>
        request('get', '/api/1.0/profile/my/pay-methods'));

    if (response.data.errors || response.status !== 200) {
        // yield put(add({
        //     type: 'error',
        //     text: 'При получении банковских карт произошла ошибка',
        //     time: 10
        // }));
    } else {
        yield put(driverActions.setCards(response.data));
    }
}

export function* rmCardWithRestore(action) {
    const cards = yield(select(getDriverCarts));
    const response = yield call(() =>
        request('delete', '/api/1.0/profile/my/pay-method/' + action.payload.id));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Произошла ошибка при удалении карты',
            time: 10
        }));
    } else {
        let arr = [];
        cards.items.forEach(el => {
            if (el.id === action.payload.id)
                el.remove = true;
            arr.push(el);
        });
        yield put(driverActions.setCards({ ...cards, items: arr }));
        yield put(add({
            type: 'success',
            text: 'Карта успешно удалена',
            time: 10
        }));
    }
}

export function* rmCard(action) {
    const response = yield call(() =>
        request('delete', '/api/1.0/profile/my/pay-method/' + action.payload));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Произошла ошибка при удалении карты',
            time: 10
        }));
    } else {
        yield put(driverActions.getCards());
        yield put(add({
            type: 'success',
            text: 'Карта успешно удалена',
            time: 10
        }));
    }
}

export function* getMarks(action) {
    let profileType = yield select(getProfileType);
    if (profileType !== 'driver') return false;

    const response = yield call(() =>
        request('get', '/api/1.0/car/marks'));

    if (response.data.errors || response.status !== 200) {
        yield call(() => action.meta.reject(response.data.errors));
    } else {
        yield call(() => action.meta.resolve(response.data.items));
    }
}

export function* getModels(action) {
    let profileType = yield select(getProfileType);
    if (profileType !== 'driver') return false;

    const response = yield call(() =>
        request('get', `/api/1.0/car/${action.payload}/models`));

    if (response.data.errors || response.status !== 200) {
        yield call(() => action.meta.reject(response.data.errors));
    } else {
        yield call(() => action.meta.resolve(response.data.items));
    }
}

export function* getSeries(action) {
    let profileType = yield select(getProfileType);
    if (profileType !== 'driver') return false;

    const response = yield call(() =>
        request('get', `/api/1.0/car/${action.payload}/series`));

    if (response.data.errors || response.status !== 200) {
        yield call(() => action.meta.reject(response.data.errors));
    } else {
        yield call(() => action.meta.resolve(response.data.items));
    }
}

export function* removeRouteByID(action) {
    let profileType = yield select(getProfileType);
    if (profileType !== 'driver') return false;

    const response = yield call(() =>
        request('delete', '/api/1.0/driver/route/' + action.payload.id));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Произошла ошибка при удалении маршрута',
            time: 10
        }));
    } else {
        yield put(driverActions.getRoutes());
        if (action.payload.route)
            forwardTo('/routes/');

        yield put(add({
            type: 'success',
            text: 'Маршрут успешно удален',
            time: 10
        }));
    }
}

export function* getRoutesByUserID(action) {
    const response = yield call(() =>
        request('get', '/api/1.0/driver/routes/' + action.payload,
            { page:1, pageSize: 10 }));

    console.log(action);

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Произошла ошибка при получении маршрутов водителя',
            time: 10
        }));
    } else {
        yield put(driverActions.setActiveRoute(response.data));
    }
}

export function* finishRoute(action) {
    const response = yield call(() =>
        request('post', '/api/1.0/driver/route/' + action.payload.id + '/finish'));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Произошла ошибка при завершении маршрута',
            time: 10
        }));
    } else {
        yield put(driverActions.getRoutes());
        if (action.payload.route)
            yield put(driverActions.getRouteByID(action.payload.id));

        yield put(add({
            type: 'success',
            text: 'Вы завершили маршрут',
            time: 10
        }));
    }
}

export function* extendRoute(action) {
    const response = yield call(() =>
        request('post', '/api/1.0/driver/route/' + action.payload.id + '/extend'));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Не удалось продлить маршрут',
            time: 10
        }));
    } else {
        yield put(driverActions.getRoutes());
        if (action.payload.route)
            yield put(driverActions.getRouteByID(action.payload.id));

        yield put(add({
            type: 'success',
            text: 'Маршрут успешно продлен и опубликован',
            time: 10
        }));
    }
}