import { offersActions } from 'actions/offers';
import TradeList from 'components/Pages/Trades/List/TradeList';
import RequestLayer from 'components/UI/Request/RequestLayer';
import { getOfferList, getProfileType } from 'libs/selections';
import { getItem, removeItem, setItem } from 'libs/sessionStorage';
import React from 'react';
import { connect } from 'react-redux';
import { TabPanel } from 'react-tabs';


class Trades extends React.Component {
    state = {
        selectedTab: 0, // TODO: желательно переделать табы чтобы при возврате на странице открывался тот таб на котором пользователь остановился getItem('trade.selectedTab', 0)
        list: [
            {
                question: 'Как скоро начнут появляться предложения?',
                answer: 'Сразу же после создания вашего заказа, он становиться доступным для грузоперевозчиков. Если прописанные вами требования по маршруту, стоимости и параметрам транспорта устраивают грузоперевозчика, он может оставить свое предложение, относительно перевозки вашего груза. Чтобы избежать неактуальных предложений, сделайте свой заказ максимально полным и точным.',
                open: false
            },
            {
                question: 'Как увеличить количество просмотров заявок?',
                answer: 'Наш Сервис предлагает равные конкурентные условия для всех пользователей. Популярность вашего заказа будет определяться только его качеством и степенью соответствия запросам грузоперевозчиков. Чтобы увеличить количество просмотра ваших заявок, изучите опыт других пользователей, предложите конкурентную стоимость и добавьте хороший сопроводительный текст.',
                open: false
            },
            {
                question: 'Как разблокировать мою заявку?',
                answer: 'Если по какой-либо причине, ваша заявка была заблокирована, обратитесь в службу поддержки или в службу «Помощь 24/7». При этом укажите обстоятельства, при каких вы обнаружили блокировку. Специалисты HurryLorry, разберутся в проблеме и решат ее, или сообщат причину блокировки и опишут ваши дальнейшие действия.',
                open: false
            },
        ],
        activePage: {
            active: getItem('trade.active', 1),
            finish: getItem('trade.finish', 1),
            arbitrate: getItem('trade.arbitrate', 1)
        }
    };

    handleSelect = selectedTab => {
        setItem(`trade.selectedTab`, selectedTab);
        this.setState({ selectedTab });
    };

    changePage = page => {
        const { selectedTab, activePage } = this.state,
            activeType = selectedTab === 0 ? 'active' : selectedTab === 1 ? 'finish' : 'arbitrate';

        setItem(`trade.${activeType}`, page);

        this.props.dispatch(offersActions.getOffers({ page, status: activeType }));
        this.setState({
            activePage: {
                ...activePage,
                [`${activeType}`]: page
            }
        })
    };

    componentWillMount() {
        removeItem('searchParams');
    }

    componentDidMount() {

        const { activePage: { active, finish, arbitrate } } = this.state;

        this.props.dispatch(offersActions.getOffers({ page: active, status: 'active' }));
        this.props.dispatch(offersActions.getOffers({ page: finish, status: 'finish' }));
        this.props.dispatch(offersActions.getOffers({ page: arbitrate, status: 'arbitrate' }));
    }

    render() {
        const { trades } = this.props,
            { activePage: { active, finish, arbitrate }, list, selectedTab } = this.state;

        let finishTradesCount = trades.finish.total,
            activeTradesCount = trades.active.total,
            arbitrateTradesCount = trades.arbitrate.total,
            requestType = {
                length: finishTradesCount + activeTradesCount + arbitrateTradesCount,
                word: ['сделка', 'сделки', 'сделок']
            },
            tabs = [
                { count: activeTradesCount, title: 'Активные' },
                { count: finishTradesCount, title: 'Завершенные' },
                { count: arbitrateTradesCount, title: 'Арбитраж' },
            ];

        return (
            <RequestLayer
                title="Мои сделки"
                tabList={tabs}
                selectedTab={parseInt(selectedTab)}
                onClickTab={i => this.handleSelect(i)}
                requestType={requestType}
                questions={list}
            >
                <TabPanel>
                    <TradeList
                        type="active"
                        activePage={active}
                        changePage={this.changePage}
                    />
                </TabPanel>
                <TabPanel>
                    <TradeList
                        type="finish"
                        activePage={finish}
                        changePage={this.changePage}
                    />
                </TabPanel>
                <TabPanel>
                    <TradeList
                        type="arbitrate"
                        activePage={arbitrate}
                        changePage={this.changePage}
                    />
                </TabPanel>
            </RequestLayer>
        );
    }
}


export default connect(store => ({
    trades: getOfferList(store),
    role: getProfileType(store),
}))(Trades);