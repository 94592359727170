import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Avatar, ArrowDownNavigate } from 'components/UI/Icons';
import UserAddButton from 'components/UI/UserAddButton';
import VerifyUser from 'components/UI/UserVerify';
import {logOut} from 'actions/profile';
import style from './style.less';
import adaptive from 'components/UI/AdaptiveStyle/style.less';
import OutsideContainer from 'components/UI/Wrappers/OutsideContainer';
import { getProfile } from 'libs/selections';
import { connect } from 'react-redux';


class HeaderProfile extends PureComponent {

	state = {
		mobileOpen: false,
	};

	static propTypes = {
		profile: PropTypes.object.isRequired,
		dispatch: PropTypes.func.isRequired,
		fixed: PropTypes.bool,
		mainPage: PropTypes.bool,
		className: PropTypes.string,
	};

	static defaultProps = {
		fixed: false,
		mainPage: false,
		className: '',
	};


	exit = () => {
		this.props.dispatch(logOut());
	};

	toggleMobileMenu = mobileOpen => this.setState({ mobileOpen });

	render () {
		const { profile, fixed, mainPage, className } = this.props;

		let renderClass = style['profile-menu'];
		if (fixed) renderClass += ' ' + style['fixed-menu'];
		if (mainPage) renderClass += ' ' + style['main-page'];
		if (className) renderClass += ' ' + className;
		if (this.state.mobileOpen) renderClass += ' ' + style.open;

		return (
			<OutsideContainer className={renderClass} onClickOutside={() => this.toggleMobileMenu(false)}>
				<div className={style['profile-button']} onClick={() => this.toggleMobileMenu(true)}>
					{ Avatar(style.avatar) }
					<span className={`${style.profile_xs__fixed_none} ${style.username}`}>
						<span>{ profile.lastName } { profile.firstName ? profile.firstName[0] + '.' : ''}</span>
						{ ArrowDownNavigate(style['arrow-navigate-down']) }
					</span>
				</div>
				<div className={style['profile-list']}>
					<ul>
						<li onClick={() => this.toggleMobileMenu(false)}>
							<Link to="/edit/profile">
								Профиль
								{
									profile.role === 'driver'
									&&
									<VerifyUser isVerify={profile.isProfileVerification}/>
								}
							</Link>
						</li>
						<li onClick={() => this.toggleMobileMenu(false)} className={adaptive.xs__show}>
							<UserAddButton role={profile.role}/>
						</li>
						<li onClick={() => this.toggleMobileMenu(false)}><span onClick={this.exit}>Выход</span></li>
					</ul>
				</div>
			</OutsideContainer>
		);
	}
}


export default connect(
	state => ({
		profile: getProfile(state),
	})
)(HeaderProfile);
