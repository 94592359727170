import 'regenerator-runtime/runtime';
import * as userSaga from './userSaga';
import * as profileSaga from './profileSaga';
import * as driverSaga from './driverSaga';
import * as offersSaga from './offersSaga';
import * as customerSaga from './customerSaga';
import * as searchSaga from './searchSaga';
import * as chatSaga from './chatSaga.js';
import * as eventsSaga from './eventsSaga.js';
import * as adminSaga from './adminSaga.js';
import * as serverHelperSaga from './serverHelperSaga.js';
import { registration, auth, confirm, recovery, testGuestPhone, feedback, sendOfferWithoutReg } from 'actions/user';
import * as adminActions from 'actions/admin';
import * as profileDetailActions from 'actions/profileDetail';
import * as profileActions from 'actions/profile';
import * as customerActions from 'actions/customer';
import * as serverHelpersActions from 'actions/serverHelpersActions';
import {driverActions} from 'actions/driver';
import {chatActions} from 'actions/chat';
import {eventsActions} from 'actions/events';
import {offersActions} from 'actions/offers';
import {searchActions} from 'actions/search';
import {takeEvery} from 'redux-saga/effects';

//список событий, слушаем что попросят
export default function* rootSaga() {
	//покажем каждый(takeEvery), или последний(takeLatest)

	/**
	 * ниже следим за событием, и выполняем соответсвующий алгаритм
	 */


	/**
	 * пользователь зарегистрировался
	 */
	yield takeEvery([registration.toString()], userSaga.registrationUser);


	/**
	 * пользователь которого нет в системе отправил заявку
	 */
	yield takeEvery([sendOfferWithoutReg.toString()], userSaga.sendOfferWithoutReg);


	/**
	 * авторизация
	 */
	yield takeEvery([auth.toString()], userSaga.authUser);

	/**
	 * Востановление паролья
	 */
	yield takeEvery([recovery.toString()], userSaga.recoveryPassword);

	/**
	 * потверждение SMS
	 */
	yield takeEvery([confirm.toString()], userSaga.confirmUser);

	/**
	 * отправка формы обратной связи
	 */
	yield takeEvery([feedback.toString()], userSaga.feedback);

	/**
	 * получения данных профиля
	 */
	yield takeEvery([profileActions.getData.toString()], profileSaga.getProfileData);

	/**
	 * Разлогинился
	 */
	yield takeEvery([profileActions.logOut.toString()], profileSaga.logOutProfile);

	/**
	 * Посмотреть другого пользователя
	 */
	yield takeEvery([profileDetailActions.getProfileByID.toString()], profileSaga.getProfileByID);

	/**
	 * Посмотреть отзывы пользователя
	 */
	yield takeEvery([profileDetailActions.getReviewsByID.toString()], profileSaga.getReviewsByID);

	/**
	 * Получить все отзывы для главной страницы
	 */
	yield takeEvery([profileActions.getClientReviews.toString()], profileSaga.getClientReviews);
	/**
	 * Редактирует контактную информацию
	 */
	yield takeEvery([profileActions.editContactData.toString()], profileSaga.editContactProfile);

	/**
	 * Редактирует аватар
	 */
	yield takeEvery([profileActions.editAvatarData.toString()], profileSaga.editAvatarData);

	/**
	 * восстановление пароля
	 */
	yield takeEvery([profileActions.changePassword.toString()], profileSaga.changeProfilePassword);

	/**
	 * изменение пароля в профиле
	 */
	yield takeEvery([profileActions.updatePassword.toString()], profileSaga.updateProfilePassword);

	/**
	 *  сохранение паспортных данных
	 */
	yield takeEvery([profileActions.editPassportData.toString()], profileSaga.changeProfilePassport);

	/**
	 * сохранение водительских прав
	 */
	yield takeEvery([driverActions.editDriverLicenseData.toString()], driverSaga.changeDriverLicense);

	/**
	 * получить все марки машин
	 */
	yield takeEvery([driverActions.getMarks.toString()], driverSaga.getMarks);

	/**
	 * получить все модели такой марки
	 */
	yield takeEvery([driverActions.getModels.toString()], driverSaga.getModels);

	/**
	 * получить все серии такой модели
	 */
	yield takeEvery([driverActions.getSeries.toString()], driverSaga.getSeries);

	/**
	 * Добавил новую машину
	 */
	yield takeEvery([driverActions.addCar.toString()], driverSaga.saveCar);

	/**
	 * Добавил новую банковсккую карту
	 */
	yield takeEvery([driverActions.addCard.toString()], driverSaga.addCard);

	/**
	 * ПОлучить все свои карты
	 */
	yield takeEvery([driverActions.getCards.toString()], driverSaga.getCards);

	/**
	 * Если запросили тачки, или положили в профиль новые данные, то получим мои машины
	 */
	yield takeEvery([driverActions.getMyCars.toString()], driverSaga.getNowMyCars);

	/**
	 *  Если положили в профиль новую инфу или запросили маршруты, то получим все маршруты
	 */
	yield takeEvery([driverActions.getRoutes.toString()], driverSaga.getNowMyRoutes);

	/**
	 * создание нового маршрута
	 */
	yield takeEvery([driverActions.createRoute.toString()], driverSaga.createNewRoute);

	/**
	 * удаление машины
	 */
	yield takeEvery([driverActions.rmCar.toString()], driverSaga.removeCar);

	/**
	 * отправить заявку на добавление груза
	 */
	yield takeEvery([customerActions.sendCargoRequest.toString()], customerSaga.sendCargoRequest);

	/**
	 * Редактировать ставку у заявки на добавление груза
	 */
	yield takeEvery([customerActions.changePriceCargoRequest.toString()], customerSaga.changePriceCargoRequest);

	/**
	 * Если положили в профиль новую инфу
	 * или запросили собственые заявки,
	 * или создли новый запрос "добавление груза"
	 * то получим все заявки
	 */
	yield takeEvery([customerActions.getMyCargoes.toString()], customerSaga.getMyCargoes);

	/**
	 * Получим активные заявки
	 */
	yield takeEvery([customerActions.getActiveCargo.toString()], customerSaga.getMyActiveCargoes);

	/**
	 * Получим отклоненные заявки
	 */
	yield takeEvery([customerActions.getCancelCargo.toString()], customerSaga.getMyCancelCargoes);

	/**
	 * запрос на проверку телефона
	 */
	yield takeEvery([testGuestPhone.toString()], userSaga.verifyPhone);

	/**
	 * удаление заявки пользователя
	 */
	yield takeEvery([customerActions.rmCargoRequest.toString()], customerSaga.removeCargoByID);

	/**
	 * получить заявки на перевоз груза по id клиента
	 */
	yield takeEvery([customerActions.getCargoByUserId.toString()], customerSaga.getCargoByUserId);

	/**
	 * получить список избранных водителей
	 */
	yield takeEvery([customerActions.getFavoriteDrivers.toString()], customerSaga.getFavoriteDrivers);

	/**
	 * получить список избранных водителей
	 */
	yield takeEvery([customerActions.addToFavorite.toString()], customerSaga.addToFavorite);

	/**
	 * удалить водителя из избранного
	 */
	yield takeEvery([customerActions.rmFavoriteById.toString()], customerSaga.rmFavoriteById);

	/**
	 * удаление маршрута водителя
	 */
	yield takeEvery([driverActions.rmRoute.toString()], driverSaga.removeRouteByID);

	/**
	 * удаление банковской карты водителя
	 */
	yield takeEvery([driverActions.rmCard.toString()], driverSaga.rmCard);

	/**
	 * удаление банковской карты, чтобы потом восстановить
	 */
	yield takeEvery([driverActions.rmCardWithRestore.toString()], driverSaga.rmCardWithRestore);

	/**
	 * отправить предложение по перевозу
	 */
	yield takeEvery([driverActions.sendProposal.toString()], offersSaga.sendProposal);

	/**
	 * получить мои предложения
	 */
	yield takeEvery([offersActions.getMyProposal.toString()], offersSaga.getMyProposal);

	/**
	 * изменить черновик сделки
	 */
	yield takeEvery([offersActions.updateOffer.toString()], offersSaga.updateOffer);

	/**
	 * получить сделку по id (old)
	 */
	yield takeEvery([offersActions.getOfferByID.toString()], offersSaga.getOfferByID);

	/**
	 * получить сделку по id
	 */
	yield takeEvery([offersActions.getTradeByID.toString()], offersSaga.getTradeByID);

	/**
	 * получить список свежих заявок
	 */
	yield takeEvery([offersActions.getTopOffers.toString()], offersSaga.getTopOffers);

	/**
	 * получить заявку по id
	 */
	yield takeEvery([customerActions.getCargoByID.toString()], offersSaga.getCargoByID);

	/**
	 * получить маршрут по id
	 */
	yield takeEvery([driverActions.getRouteByID.toString()], driverSaga.getRouteByID);

	/**
	 * отказать в исполнении
	 */
	yield takeEvery([offersActions.rejectProposal.toString()], offersSaga.rejectProposal);

	/**
	 * согласовать исполнение
	 */
	yield takeEvery([offersActions.acceptProposal.toString()], offersSaga.acceptProposal);

	/**
	 * Создать договор
	 */
	yield takeEvery([offersActions.createContract.toString()], offersSaga.createContract);

	/**
	 * Подписать договор СМС
	 */
	yield takeEvery([offersActions.confirmContractCode.toString()], offersSaga.confirmContractCode);

	/**
	 * удаление собственного предложения
	 */
	yield takeEvery([offersActions.removeMyProposal.toString()], offersSaga.removeMyProposal);

	/**
	 * поиск заказчика/водителя
	 */
	yield takeEvery([searchActions.getResults.toString()], searchSaga.getResults);

	/**
	 * получение всех договоров
	 */
	yield takeEvery([offersActions.getOffers.toString()], offersSaga.getOffers);

	/**
	 * получение колличества подходящих водителей/клиентов
	 */
	yield takeEvery([searchActions.getCountByOffer.toString()], searchSaga.getCountByOffer);

	/**
	 * подтверждение о загрузке товара
	 */
	yield takeEvery([offersActions.acceptUploadOfferById.toString()], offersSaga.acceptUploadOfferById);

	/**
	 * подтверждение оплаты по договору
	 */
	yield takeEvery([offersActions.acceptPayOfferById.toString()], offersSaga.acceptPayOfferById);

	/**
	 * подтверждение начала пути
	 */
	yield takeEvery([offersActions.startRouteById.toString()], offersSaga.startRouteById);

	/**
	 * подтверждении конца пути
	 */
	yield takeEvery([offersActions.endRouteById.toString()], offersSaga.endRouteById);

	/**
	 * подтверждение выполнения заказа
	 */
	yield takeEvery([offersActions.offerCompleteById.toString()], offersSaga.offerCompleteById);

	/**
	 * отравить арбитраж
	 */
	yield takeEvery([offersActions.sendArbitration.toString()], offersSaga.sendArbitration);

	/**
	 * обновим страницу детального просмотра, если пришло уведомление
	 */
	yield takeEvery([offersActions.updateOfferDetailById.toString()], offersSaga.updateOfferDetailById);

    /**
     * обновим страницу детального просмотра, если пришло уведомление <<<<<!!!
     */
    yield takeEvery([offersActions.getCargoByID.toString()], offersSaga.getCargoByID);

	/**
	 * отменить сделку
	 */
	yield takeEvery([offersActions.closeOfferById.toString()], offersSaga.closeOfferById);

	/**
	 * заключаем сделку
	 */
	yield takeEvery([offersActions.acceptOfferById.toString()], offersSaga.acceptOfferById);

	/**
	 * запрос на редактирование договора
	 */
	yield takeEvery([offersActions.sendRequestEditOffer.toString()], offersSaga.sendRequestEditOffer);

	/**
	 * отправить отзыв
	 */
	yield takeEvery([offersActions.sendReview.toString()], offersSaga.sendReview);

	/**
	 * получаем маршруты по id водителя
	 */
	yield takeEvery([driverActions.getRoutesByUserID.toString()], driverSaga.getRoutesByUserID);

	/**
	 * получаем все диалоги
	 */
	yield takeEvery([chatActions.getDialogs.toString()], chatSaga.getDialogs);

	/**
	 * получаем историю диалога по его id
	 */
	yield takeEvery([
		chatActions.getHistoryByID.toString(),
		chatActions.appendHistoryByID.toString()
	], chatSaga.getHistoryByID);

	/**
	 * отправить сообщение
	 */
	yield takeEvery([chatActions.sendMsgToServer.toString()], chatSaga.sendMsgToServer);

	/**
	 * создать диалог
	 */
	yield takeEvery([chatActions.createDialogForServer.toString()], chatSaga.createDialogForServer);

	/**
	 * подключаемся к ws
	 */
	yield takeEvery([chatActions.connectToServer.toString()], chatSaga.connectToServer);

	/**
	 * пометим сообщение как прочитанное
	 */
	yield takeEvery([chatActions.markReadMsgToServer.toString()], chatSaga.markReadMsgToServer);

	/**
	 * оборвём связь с чатом
	 */
	yield takeEvery([chatActions.disconnectChat.toString()], chatSaga.disconnectChat);

	/**
	 * получить уведомления о событиях
	 */
	yield takeEvery([eventsActions.getEvents.toString()], eventsSaga.getEvents);

	/**
	 * отметить событие прочитаным
	 */
	yield takeEvery([eventsActions.readEventById.toString()], eventsSaga.readEventById);

	/**
	 * Модерация заявки, поставить статус
	 */
	yield takeEvery([adminActions.setOfferStatus.toString()], adminSaga.moderationOffer);

	/**
	 * Модерация маршрута, поставить статус
	 */
	yield takeEvery([adminActions.setRouteStatus.toString()], adminSaga.moderationRoute);

	/**
	 * Модерация маршрута, поставить статус
	 */
	yield takeEvery([adminActions.setArbitrateStatus.toString()], adminSaga.moderationArbitrate);

    /**
     * Получение всех маршрутов для админа
     */
    yield takeEvery([adminActions.getRoutes.toString()], adminSaga.getRoutes);

	/**
	 * Получение всех арбитражей для админа
	 */
	yield takeEvery([adminActions.getArbitrations.toString()], adminSaga.getArbitrations);


    /**
     * Получение всех заявок для админа
     */
    yield takeEvery([adminActions.getOffers.toString()], adminSaga.getOffers);

	/**
	 * Получение всех сделок для админа
	 */
	yield takeEvery([adminActions.getTrades.toString()], adminSaga.getTrades);

    /**
     * Получить маршрут для админа
     */
    yield takeEvery([adminActions.getRouteByID.toString()], adminSaga.getRouteByIDAdmin);

	/**
	 * Получить заявку для админа
	 */
	yield takeEvery([adminActions.getOfferByID.toString()], adminSaga.getOfferByIDAdmin);

	/**
	 * Получить статус платежа для сделки
	 */
	yield takeEvery([adminActions.getPayTradeStatus.toString()], adminSaga.getPayTradeStatus);

	/**
	 * Скачать договор
	 */
	yield takeEvery([offersActions.downloadContract.toString()], offersSaga.downloadContract);

	/**
	 * Отменить сделку
	 */
	yield takeEvery([offersActions.cancelTrade.toString()], offersSaga.cancelTrade);

	/**
	 * Завершить маршрут
	 */
	yield takeEvery([driverActions.finishRoute.toString()], driverSaga.finishRoute);


	/**
	 * Продлить маршрут
	 */
	yield takeEvery([driverActions.extendRoute.toString()], driverSaga.extendRoute);

	/**
	 * Добавить предложение по маршруту
	 */
	yield takeEvery([customerActions.addProposalToRoute.toString()], customerSaga.addProposalToRoute);

	/**
	 * Получить все данные по арбитражу
	 */
	yield takeEvery([adminActions.getArbitrateByID.toString()], adminSaga.getArbitrate);

	/**
	 * Отметить предложение прочитанным
	 */
	yield takeEvery([offersActions.readProposal.toString()], offersSaga.readProposal);

	/**
	 * Забанить пользователя
	 */
	yield takeEvery([adminActions.setBan.toString()], adminSaga.setBanToUser);

	/**
	 * Разбанить пользователя
	 */
	yield takeEvery([adminActions.rmBan.toString()], adminSaga.rmBanToUser);

	/**
	 * Изменить статус верификации пользователя
	 */
	yield takeEvery([adminActions.verifyUser.toString()], adminSaga.verifyUser);

	/**
	 * Получить всех пользователей для админа
	 */
	yield takeEvery([adminActions.getUsers.toString()], adminSaga.getUsers);

	/**
	 * Получить список водителей
	 */
	yield takeEvery([adminActions.getDrivers.toString()], adminSaga.getDrivers);

	/**
	 * Получить список клиентов
	 */
	yield takeEvery([adminActions.getClients.toString()], adminSaga.getClients);

	/**
	 * Получить пользователя админа
	 */
	yield takeEvery([adminActions.getUserByID.toString()], adminSaga.getUserByID);

	/**
	 * Застраховать груз
	 */
	yield takeEvery([offersActions.insureTrade.toString()], offersSaga.insureTrade);


	/**
	 * Получить список категорий груза
	 */
	yield takeEvery([serverHelpersActions.getCargoList.toString()], serverHelperSaga.getCargoList);


	/**
	 * Получить список упаковок для груза
	 */
	yield takeEvery([serverHelpersActions.getPackageList.toString()], serverHelperSaga.getPackageList);

	/**
	 * Получить список типов кузова
	 */
	yield takeEvery([serverHelpersActions.getBodyTypeList.toString()], serverHelperSaga.getBodyTypesList);

	/**
	 * Получить список типов загрузки в кузов
	 */
	yield takeEvery([serverHelpersActions.getLoadTypeList.toString()], serverHelperSaga.getLoadTypesList);

	/**
	 * Получить список доп. услуг
	 */
	yield takeEvery([serverHelpersActions.getAdditionalList.toString()], serverHelperSaga.getAdditionalsList);


	yield takeEvery([serverHelpersActions.getCounterList.toString()], serverHelperSaga.getCounterList);

	/**
	 * Застраховать
	 */
	yield takeEvery([adminActions.setInsure.toString()], adminSaga.setInsure);
}
