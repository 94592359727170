exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n._3ChOLxH7xXsnvaqzIHgF4V {\n  display: inline-block;\n  font-size: 13px;\n}\n._3ChOLxH7xXsnvaqzIHgF4V._2s6xV2Q82h3iSywzJ_fUxh {\n  color: #3fb61f;\n}\n._3ChOLxH7xXsnvaqzIHgF4V._3uD8-fOaDmUNyI1E0H7AY2 {\n  color: #f44336;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/UI/UserVerify/style.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,sBAAsB;EACtB,gBAAgB;CACjB;AACD;EACE,eAAe;CAChB;AACD;EACE,eAAe;CAChB","file":"style.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.verify {\n  display: inline-block;\n  font-size: 13px;\n}\n.verify.green {\n  color: #3fb61f;\n}\n.verify.red {\n  color: #f44336;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"verify": "_3ChOLxH7xXsnvaqzIHgF4V",
	"green": "_2s6xV2Q82h3iSywzJ_fUxh",
	"red": "_3uD8-fOaDmUNyI1E0H7AY2"
};