import { showErrorAsync } from "./showFormErrors";
import { phone, password, passwordConfirm, code, name, clientPassport } from 'libs/validationsForms';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const asyncValidate = (values /*, dispatch */) => {
	return sleep(100).then(() => {
		showErrorAsync({
			phone_number: [phone(values.phone_number)],
			phoneNumber: [phone(values.phoneNumber)],
			password: [password(values.password)],
			newPassword: [password(values.newPassword)],
			newPasswordAgain: [passwordConfirm(values.newPassword, values.newPasswordAgain)],
			code: [code(values.code)],
			firstName: [name(values.firstName)],
			lastName: [name(values.lastName)],
			middleName: [name(values.middleName)],
			number: [clientPassport(values.number)],
		})
	});
};

export default asyncValidate;