exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1Q5O-v27xcJTIh7e1f2Lrg {\n  margin-top: 30px;\n  margin-bottom: 30px;\n}\n._2qqnAXSfVoQJeMkOVBkqMH {\n  text-align: right;\n}\n._1-cZRQscXonqJ7-y9CgnJV {\n  max-width: 250px;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/ReduxForms/СonfirmForm/style.less"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,oBAAoB;CACrB;AACD;EACE,kBAAkB;CACnB;AACD;EACE,iBAAiB;CAClB","file":"style.less","sourcesContent":[".button {\n  margin-top: 30px;\n  margin-bottom: 30px;\n}\n.rec {\n  text-align: right;\n}\n.maxWidth150 {\n  max-width: 250px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"button": "_1Q5O-v27xcJTIh7e1f2Lrg",
	"rec": "_2qqnAXSfVoQJeMkOVBkqMH",
	"maxWidth150": "_1-cZRQscXonqJ7-y9CgnJV"
};