import React from 'react';
import Slider from 'react-slick';
import Review from 'components/UI/ProfileUI/Review';
import Button from 'components/UI/Form/Button';
import { ArrowLeft, ArrowRight } from 'components/UI/Icons';
import style from '../style.less';
// import { getClientReviews } from 'actions/profile';
import { connect } from 'react-redux';
import shortName from 'libs/shortName';
import { dateToString, stringToDate } from 'libs/convertDate';

const ArrowPrev = props => {
	const { onClick } = props;
	return <div className={style.prev}><Button color="white" size="small" onClick={onClick}>{ ArrowLeft() }</Button></div>
};
const ArrowNext = props => {
	const { onClick } = props;
	return <div className={style.next}><Button color="white" size="small" onClick={onClick}>{ ArrowRight() }</Button></div>
};

class NewReviews extends React.Component {

	state = {
		// reviews: { items: [], total: 0 },
	};

	componentWillMount() {
		return new Promise((resolve, reject) => {
			// this.props.dispatch(getClientReviews({ page: 1, pageSize: 7 }, { resolve: resolve, reject: reject, }));
		}).then(reviews => {
			this.setState({ reviews });
		}).catch(() => {});
	}

	render() {
		// let { reviews } = this.state;
		// if (reviews === undefined) return null;

		let reviews = { items: [], total: 3 };

		const settings = {
			dots: true,
			dotsClass: style.dots,
			className: style.grid_slider,
			prevArrow: <ArrowPrev />,
			nextArrow: <ArrowNext />,
			infinite: false,
			slidesToShow: 2,
			// autoplay: true,
			appendDots: dots => (
				<ul className={style.dots}>
					{ dots }
				</ul>
			),
			responsive: [
				{
					breakpoint: 1100,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					}
				},
			]
		};

		reviews.items.push({
			id: 1,
			creator: { firstName: 'Василий', lastName: 'Романов' },
			createTime: '2020-01-27 08:54:01',
			user: { photo: null, firstName: 'Григорий', lastName: 'Федоров'  },
			generalRating: 5,
			title: 'Отлично',
			text: 'Переезжал в другой город, нужно было перевести всю мебель. Откликнулось несколько водителей, выбрал самую низкую цену, быстро заключили договор.\n' +
					'Приехал в оговоренное время.\n' +
					'Удобный сайт, порекомендовал уже своим знакомым.\n' +
					'Спасибо за качественно оказанную услугу водителю Григорию',
			trade: {
				offer: {
					id: 5,
					route: [ { locality: 'Самара' }, { locality: 'Кинель' }],
				}
			}
		});

		reviews.items.push({
			id: 2,
			creator: { firstName: 'Андрей', lastName: 'Тихонов' },
			createTime: '2020-01-18 14:45:01',
			user: { photo: null, firstName: 'Михаил', lastName: 'Горбунов'  },
			generalRating: 4.8,
			title: 'Рекомендую этого водителя',
			text: 'Работа была выполнена отлично, водитель Михаил, спасибо ему, довез все в целости и сохранности. Весь переезд включая грузчиков мне обошелся очень дешево. \n' +
					'Приехали во время, очень доволен. Все выполнили быстро и главное бережно.',
			trade: {
				offer: {
					id: 3,
					route: [ { locality: 'Москва' }, { locality: 'Самара' }],
				}
			}
		});

		reviews.items.push({
			id: 3,
			creator: { firstName: 'Михаил', lastName: 'Горбунов' },
			createTime: '2020-01-16 18:16:01',
			user: { photo: null, firstName: 'Иван', lastName: 'Николаевич'  },
			generalRating: 4.5,
			title: 'Очень хорошо!',
			text: 'Спасибо Ивану Николаевичу за доставку стройматериалов, я остался доволен и срокам и ценой. Обращусь к вам еще ни раз.',
			trade: {
				offer: {
					id: 6,
					route: [ { locality: 'Тольятти' }, { locality: 'Жигулевск' }],
				}
			}
		});

		return (
			<div className={style.review__screen}>
				<h3 className={style.section_title}>Отзывы о водителях</h3>
				{
					reviews.total > 0 &&
					<Slider {...settings}>
						{
							reviews.items.map(el => {
								return (
									<div className={`${style.review} ${style.review_slide}`} key={el.id}>
										<div className={style.header}>
											<div className={style.author}>
												Отзыв написал: <span>{ shortName(el.creator.firstName, el.creator.lastName) }</span>
											</div>
											<div className={style.date}>
												{ dateToString(stringToDate(el.createTime), 'stringFullDate') }
											</div>
										</div>
										<Review review={el} />
									</div>
								)
							})
						}
					</Slider>
				}
			</div>
		)
	}
}

export default connect()(NewReviews);
