exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".OoRXYWm5t2En0xOwlr192 {\n  background: #fff;\n  border-radius: 4px;\n  padding: 20px;\n  font-size: 15px;\n}\n.OoRXYWm5t2En0xOwlr192 h3 {\n  font-size: 19px;\n  border-bottom: 2px solid #f0f4f9;\n  padding: 30px 0 15px 0;\n  margin-bottom: 15px;\n}\n.OoRXYWm5t2En0xOwlr192 h3:first-child {\n  padding-top: 0;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/Admin/UI/Container/style.less"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;EACd,gBAAgB;CACjB;AACD;EACE,gBAAgB;EAChB,iCAAiC;EACjC,uBAAuB;EACvB,oBAAoB;CACrB;AACD;EACE,eAAe;CAChB","file":"style.less","sourcesContent":[".container {\n  background: #fff;\n  border-radius: 4px;\n  padding: 20px;\n  font-size: 15px;\n}\n.container h3 {\n  font-size: 19px;\n  border-bottom: 2px solid #f0f4f9;\n  padding: 30px 0 15px 0;\n  margin-bottom: 15px;\n}\n.container h3:first-child {\n  padding-top: 0;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "OoRXYWm5t2En0xOwlr192"
};