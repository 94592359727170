exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n._3uXUpJ6cynIVTEb-tFmmET {\n  display: flex;\n  align-items: center;\n}\n._3uXUpJ6cynIVTEb-tFmmET > div:first-child {\n  width: 9px;\n  height: 9px;\n  border-radius: 50%;\n  display: inline-block;\n}\n._3uXUpJ6cynIVTEb-tFmmET ._1wtZ_KdfW8fbhewBPDcbiR {\n  background: #55bb00;\n}\n._3uXUpJ6cynIVTEb-tFmmET ._1mpK5X6UEhlvnBVpkMQyKF {\n  background: #f4811c;\n}\n._3uXUpJ6cynIVTEb-tFmmET ._3msFS9oAcQ-w6PzpBVt9Sm {\n  color: #9b9b9b;\n  font-size: 13px;\n  margin-left: 6px;\n  margin-top: 2px;\n  line-height: 1;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/UI/OnlineStatus/style.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,cAAc;EACd,oBAAoB;CACrB;AACD;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;CACvB;AACD;EACE,oBAAoB;CACrB;AACD;EACE,oBAAoB;CACrB;AACD;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;CAChB","file":"style.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.status {\n  display: flex;\n  align-items: center;\n}\n.status > div:first-child {\n  width: 9px;\n  height: 9px;\n  border-radius: 50%;\n  display: inline-block;\n}\n.status .online {\n  background: #55bb00;\n}\n.status .offline {\n  background: #f4811c;\n}\n.status .info {\n  color: #9b9b9b;\n  font-size: 13px;\n  margin-left: 6px;\n  margin-top: 2px;\n  line-height: 1;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"status": "_3uXUpJ6cynIVTEb-tFmmET",
	"online": "_1wtZ_KdfW8fbhewBPDcbiR",
	"offline": "_1mpK5X6UEhlvnBVpkMQyKF",
	"info": "_3msFS9oAcQ-w6PzpBVt9Sm"
};