import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/UI/Form/Button';
import Dialog from 'components/UI/Dialog';
import ArbitrationForm from 'components/ReduxForms/ArbitrationForm';
import {offersActions} from 'actions/offers';
import showError from 'libs/showFormErrors';
import {required} from 'libs/validationsForms';
import StepContainer from 'components/Pages/Trades/Detail/TradeSteps/StepContainer';
import style from 'components/Pages/Trades/Detail/styles.less';

export default class Unload extends React.PureComponent {

	static propTypes = {
		isActive: PropTypes.bool.isRequired,
		role: PropTypes.string.isRequired,
		dispatch: PropTypes.func.isRequired,
		tradeID: PropTypes.number.isRequired,
		status: PropTypes.string.isRequired,
	};

	routeEnded = () => this.props.dispatch(offersActions.endRouteById(this.props.tradeID));
	offerComplete = () => this.props.dispatch(offersActions.offerCompleteById(this.props.tradeID));

	sendArbitration = values => {
		showError({
			reason: [required(values.reason)],
			comment: [required(values.comment)],
		});
		this.toggleDialog(false);
		if(values.fileIds) values.fileIds = values.fileIds.images.map(it => it.id);
		this.props.dispatch(offersActions.sendArbitration(values));
	};

	renderDialog = () => (
		<Dialog handleClose={()=>this.toggleDialog(false)}>
			<ArbitrationForm onSubmit={this.sendArbitration} initialValues={{tradeId: this.props.offerId}} />
		</Dialog>
	);

	render() {
		let { isActive, role, status } = this.props;

		return (
			<StepContainer step={3} title={'Разгрузка товара'} isActive={isActive}>
				{
					role === 'driver' &&
					<Fragment>
						{
							status === 'started_route' &&
							<Fragment>
								<p>Сообщите заказчику о том, что груз доставлен</p>
								<div className={style.action__buttons}>
									<Button
										color={'green'}
										onClick={this.routeEnded}
									>
										Груз доставлен
									</Button>
									{/*<Button color={'white'}>Отправить жалобу</Button>*/}
								</div>
							</Fragment>
						}
						{
							status === 'cargo_unloaded' &&
							<p>Ожидаем потверждение выполнения заказа от клиента</p>
						}
						{
							status !== 'cargo_unloaded' && status !== 'started_route' && status !== 'completed' && status !== 'arbitration' &&
							<p>После прибытия груза к месту разгрузки вам поступит sms/email уведомление</p>
						}
					</Fragment>
				}
				{
					role === 'customer' &&
					<Fragment>
						{
							status !== 'cargo_unloaded' && status !== 'completed' && status !== 'arbitration' &&
							<p>После прибытия груза к месту разгрузки вам поступит sms/email уведомление</p>
						}
						{
							status === 'cargo_unloaded' &&
							<Fragment>
								<p>Сообщите исполнителю, о том, что груз принят</p>
								<div className={style.action__buttons}>
									<Button
										color={'green'}
										onClick={this.offerComplete}
									>
										Принял груз
									</Button>
									{/*<Button color={'white'}>Отправить жалобу</Button>*/}
								</div>
							</Fragment>
						}
					</Fragment>
				}
				{
					status === 'completed' &&
					<p>Сделка завершена</p>
				}
				{
					status === 'arbitration' &&
					<p>Ожидается решение по арбитражу</p>
				}
			</StepContainer>
		);
	}
}