import handleActions from 'redux-actions/es/handleActions';
import { eventsActions } from 'actions/events';

const getEvents = (initStore) => handleActions({
	[eventsActions.setEvents]: (state, action) => ({
		...state,
		list: action.payload
	}),
	[eventsActions.addEvents]: (state, action) => ({
		...state,
		list: [action.payload, ...state.list]
	}),
	[eventsActions.removeEventByIdFromClient]: (state, action) => ({
		...state,
		list: state.list.filter(it => it.id !== action.payload)
	}),
}, initStore);

export default getEvents;