import showError from 'libs/showFormErrors';
import {required} from 'libs/validationsForms';
import {auth} from 'actions/user';
import {saveToken} from 'libs/tokenDriver';
import {getData} from 'actions/profile';
import {SubmissionError} from 'redux-form';
import {serverCargoToClient} from 'libs/maper';

const submitSingIn = (values, dispatch, valueToServer) => {
	showError({
		phone_number: [required(values.phone_number)],
		password: [required(values.password)],
	});

	return new Promise((resolve, reject) => {
		dispatch(auth({
			...values,
			phone: values.phone_code + values.phone_number.replace(/\s/g, '')
		}, {
			resolve: resolve,
			reject: reject,
		}));
	}).then(token => {
		saveToken('local', token);
		return new Promise((resolve, reject)  => {
			dispatch(getData(null, {valueToServer, resolve, reject}));
		}).then(() => {
		}).catch(err => showError(serverCargoToClient(err)));
	}).catch(err => {
		if (err.phone)
			err.phone_number = err.phone;

		let errors = err.errors ? err.errors : err;
		throw new SubmissionError({
			...errors,
			_error: 'Ошибка отправки формы'
		})
	});
};

export default submitSingIn;