import React from 'react';
import MainWhiteContainer from 'components/UI/Wrappers/MainWhiteContainer';
import Container from 'components/UI/Wrappers/Container';
import MainScreen from './MainScreen';
import AboutScreen from './AboutScreen';
import ServiceScreen from './ServiceScreen';
import AdvantageScreen from './AdvantageScreen';
import PopularQuestions from './PopularQuestions';
import NewReviews from './NewReviews';
import OfferScreen from './OfferScreen';
import BeginNowScreen from './BeginNowScreen';
import style from './style.less';
import time from 'components/MainPage/time.svg';
import money from 'components/MainPage/money.svg';
import { offersActions } from 'actions/offers';
import { getTopOfferList } from 'libs/selections';
import { connect } from 'react-redux';

class MainPage extends React.Component {

	componentDidMount() {
		this.props.dispatch(offersActions.getTopOffers());
	}

	render() {
		const advantageList = [
			{title: 'Экономия', text: 'Прямое сотрудничество между перевозчиками и отправителями — экономия до 30-40 %.', src: time},
			{title: 'Эффективность', text: 'Поиск транспорта или заказчика по регионам и направлениям. Оптимальные маршруты и условия.'},
			{title: 'Гарантии', text: 'Страховка и гарантия сохранности грузов. Сделки без риска. Рейтинг надежности пользователей.', src: money},
		];

		return (
			<div className={style.main_page}>
				<MainScreen />
				<AboutScreen count={this.props.count} />
				<ServiceScreen />
				<MainWhiteContainer className={style.container}>
					<Container>
						<AdvantageScreen list={advantageList} />
					</Container>
					<div className={style.separator_mainpage} />
					<Container>
						<PopularQuestions />
						<div className={style.separator_without} />
						{/*<div className={style.separator} />*/}
						<NewReviews />
					</Container>
				</MainWhiteContainer>
				<OfferScreen count={this.props.count} />
				<BeginNowScreen />
			</div>
		);
	}
}

export default connect(
		state => ({
			count: getTopOfferList(state).length,
		})
)(MainPage);
