exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1rvFaG9HCEdWw5taGrZ5tk {\n  display: grid;\n}\n._1rvFaG9HCEdWw5taGrZ5tk._3voRM1BV5xxz4zG1mz1S_k {\n  grid-template-columns: 266px auto;\n  grid-column-gap: 45px;\n}\n._1rvFaG9HCEdWw5taGrZ5tk._2sq57SxvhVeoYChd4vFrmr {\n  grid-template-columns: 100%;\n  grid-row-gap: 15px;\n}\n._1rvFaG9HCEdWw5taGrZ5tk._1sN_FnezhAQxru3p6furu4 {\n  grid-template-columns: 100%;\n  grid-row-gap: 15px;\n}\n._1rvFaG9HCEdWw5taGrZ5tk._2dvTCiQfZS16N1-DhmR36W {\n  grid-template-columns: 50% auto;\n  grid-column-gap: 15px;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/Admin/UI/Grid/style.less"],"names":[],"mappings":"AAAA;EACE,cAAc;CACf;AACD;EACE,kCAAkC;EAClC,sBAAsB;CACvB;AACD;EACE,4BAA4B;EAC5B,mBAAmB;CACpB;AACD;EACE,4BAA4B;EAC5B,mBAAmB;CACpB;AACD;EACE,gCAAgC;EAChC,sBAAsB;CACvB","file":"style.less","sourcesContent":[".grid {\n  display: grid;\n}\n.grid.page {\n  grid-template-columns: 266px auto;\n  grid-column-gap: 45px;\n}\n.grid.aside {\n  grid-template-columns: 100%;\n  grid-row-gap: 15px;\n}\n.grid.mainContent {\n  grid-template-columns: 100%;\n  grid-row-gap: 15px;\n}\n.grid.passport {\n  grid-template-columns: 50% auto;\n  grid-column-gap: 15px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"grid": "_1rvFaG9HCEdWw5taGrZ5tk",
	"page": "_3voRM1BV5xxz4zG1mz1S_k",
	"aside": "_2sq57SxvhVeoYChd4vFrmr",
	"mainContent": "_1sN_FnezhAQxru3p6furu4",
	"passport": "_2dvTCiQfZS16N1-DhmR36W"
};