import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';

const VerifyUser = ({ isVerify }) => (
	<span className={`${style['verify']} ${isVerify ? style.green : style.red}`}>
		{isVerify ? 'верифицирован' : 'не верифицирован'}
	</span>
);

VerifyUser.propTypes = {
	isVerify: PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.object]),
};

export default VerifyUser;
