import React, { PureComponent } from 'react';
import style from 'components/Admin/UI/Profile/style.less';
import { ArrowDownNavigate, Avatar } from 'components/UI/Icons';
import { logOut } from 'actions/profile';
import { connect } from 'react-redux';
import { getProfile } from 'libs/selections';
import shortName from 'libs/shortName';


class AdminHeaderProfile extends PureComponent {


	exit = () => {
		this.props.dispatch(logOut());
	};


	render() {

		const { profile } = this.props;

		if (!profile) return null;

		return (
			<div className={style.profile}>

				<div className={style.profileButton}>
					{ Avatar(style.profileAvatarIcon) }

					<div className={style.profileName}>
						<span>{ shortName(profile.firstName, profile.lastName) }</span>
						{ ArrowDownNavigate(style.profileArrowIcon) }
					</div>
				</div>

				<ul className={style.profileList}>
					<li>
						<span onClick={this.exit}>Выйти</span>
					</li>
				</ul>

			</div>
		);
	}
}


export default connect(
	state => ({
		profile: getProfile(state),
	})
)(AdminHeaderProfile);