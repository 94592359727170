import React from 'react';
import style from '../style.less';
import Avatar from 'components/UI/ProfileUI/Avatar';
import Rating from 'components/UI/ProfileUI/Rating';
import UserName from 'components/UI/ProfileUI/UserName';
import Button from 'components/UI/Form/Button';
import Badge from 'components/UI/Badge';
import { Tick } from 'components/UI/Icons';

const ReviewCard = props => {
	const { review, className } = props;

	return (
		<div className={className}>
			<div className={style.containerReview}>
				<Badge color='yellowsaturated' className={style.badge}>Предложение №{ review.badge }</Badge>
				<Avatar src={review.avatar} className={style.avatar} />
				<div className={style.review}>
					<UserName className={style.username} name={review.username} />
					<Rating className={style.rating} count={review.rating} />
					<p className={style.p}>Стоимость <b>{ review.price } р.</b></p>
					<p className={style.p}>{ review.text }</p>
					<div className={style.buttons}>
						<Button color="white">{ review.badge === 4 ? Tick(style.tick) : '' } { review.badge === 4 ? 'Выбран исполнителем' : 'Выбрать исполнителем' }</Button>
						<Button color="white">Ответить</Button>
					</div>
				</div>
			</div>
			<div className={style.pubDate}>{ review.date }</div>
		</div>
	)
};

export default ReviewCard;
