import { Notification } from 'components/UI/Icons';
import React from 'react';
import style from './style.less';

export const NotNotification = () => (
    <div className={style.event__list_empty}>
        <Notification/>
        <p className={style.noNotifications}>Новые уведомления отсутствуют</p>
        <p className={style.goodDay}>Хорошего Вам дня.</p>
    </div>
);
