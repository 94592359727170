exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n._3oWtt3Ok1pM5LgS3WeYIe_ {\n  max-width: 460px;\n}\n._3oWtt3Ok1pM5LgS3WeYIe_ ._3tUQDqb4xA5bwQ7FZotrfC {\n  margin-top: 30px;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/ReduxForms/BecomeCarrierRegForm/style.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,iBAAiB;CAClB;AACD;EACE,iBAAiB;CAClB","file":"style.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.form {\n  max-width: 460px;\n}\n.form .btn {\n  margin-top: 30px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"form": "_3oWtt3Ok1pM5LgS3WeYIe_",
	"btn": "_3tUQDqb4xA5bwQ7FZotrfC"
};