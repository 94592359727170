import AdminGrid from 'components/Admin/UI/Grid';
import { AdminLabel } from 'components/Admin/UI/Label';
import Status from "components/UI/Status/index";
import React from 'react';

import style from './style.less';
import { dateToString } from "libs/convertDate";

const TradeData = props => {
    const { status, price, offer } = props;
    return (
        <>
        <h3> Данные о сделке </h3>
        <AdminGrid type="passport">
            <div>
                <AdminLabel title="Cтатус сделки:">
                    <Status status={status} className={style.center}/>
                </AdminLabel>
                {
                    offer && offer.route && offer.route.items.map((el, index) => {
                        return (
                            <AdminLabel key={index} title={`${
                                index === 0 ? 'Адрес отправления' :
                                    index === (offer.route.items.length - 1) ? 'Адрес прибытия' :
                                        'Промежуточная точка'}:`
                            }>
                                { el.address.name } <br/> {dateToString(new Date(el.startDate), '')}
                            </AdminLabel>
                        )
                    })
                }
                <AdminLabel title="Сумма сделки:">
                    {price} руб/км
                </AdminLabel>
            </div>
        </AdminGrid>
        </>
    )
};


export default TradeData;