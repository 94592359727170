import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.less';
import language from 'language';
import Point from 'components/UI/Point';
import getWordEnd from 'libs/getWordEnd';

export default class DataRoute extends React.Component {
	
	static propTypes = {
		data: PropTypes.object,
	};
	
	render() {
		const { data } = this.props;
		return (
			<div className={styles.container}>
				{
					<Fragment>
						<Point point={'map'} className={styles.point} />
						<div className={styles.address}>
							{
								data.offer.geography === 'city' && data.route[0].locality
							}
							{
								data.offer.geography === 'region' && data.route[0].region
							}
							{
								data.offer.geography === 'country' &&
								<Fragment>
									{ data.route[0].address }{' '}
									<span className={styles.additionalAddress}>
										+{ data.route.length - 1 }{' '}
										{ getWordEnd(data.route.length - 1, ['адрес','адреса','адресов']) }
									</span>
								</Fragment>
							}
							<span className={styles.publicPeriod}>
								Публикация { language[`period${data.offer.typePeriodPublication}`] }
							</span>
						</div>
					</Fragment>
				}
			</div>
		)
	}
}