import React from 'react';
import PropTypes from 'prop-types';
import QuestionItemOneType from './QuestionItemOneType';
import QuestionItemTwoType from './QuestionItemTwoType';
import style from './style.less';

class Questions extends React.Component {
	state = {list: this.props.list};

	static propTypes = {
		list: PropTypes.array.isRequired,
		viewType: PropTypes.number,
	};
	static defaultProps = {
		viewType: 1,
	};

	onOpenQuestion = i => {
		let state = Object.assign({}, this.state);
		state.list[i].open = true;
		this.setState(state);
	};

	onCloseQuestion = i => {
		let state = Object.assign({}, this.state);
		state.list[i].open = false;
		this.setState(state);
	};

	onToggleQuestion = i => {
		let state = Object.assign({}, this.state);
		state.list[i].open = !state.list[i].open;
		this.setState(state);
	};

	render() {
		const { list } = this.state;

		return (
			<div className={style.list}>
				{
					list.map((el, i) => {
						if (this.props.viewType === 1)
							return <QuestionItemOneType
								key={i}
								el={el}
								number={i}
								onToggle={data => {this.onToggleQuestion(data)}}
							/>;

						return <QuestionItemTwoType
							key={i}
							el={el}
							number={i}
							onToggle={data => {this.onToggleQuestion(data)}}
						/>
					})
				}
			</div>
		)
	}
}

export default Questions;
