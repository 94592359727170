import React from 'react';
import { ArrowDownNavigate } from 'components/UI/Icons';
import style from './style.less';
import styleList from 'components/Verification/style.less';

const QuestionItemTwoType = props => {
	return (
		<div className={`${style.item__two_type} ${props.el.open ? style.open : ''}`}>
			<div className={style.title} onClick={() => props.onToggle(props.number)}>
				<div><span>{ props.el.question }</span></div>
				{ ArrowDownNavigate() }
			</div>
			<div className={style.answer}>
				{
					typeof props.el.answer === 'string' && props.el.answer
				}
				{
					typeof props.el.answer !== 'string' &&
					props.el.answer.map((el, i) => {
						if (typeof el === 'string')
							return <p key={i}>{el}</p>;
						else
							return (
								<ul className={styleList.defaultList}>
									{
										el.map((it, j) => <li key={j}>{it}</li>)
									}
								</ul>
							)
					})
				}
			</div>
		</div>
	);
};

export default QuestionItemTwoType;
/*
{
	typeof el === 'string' && <p key={i}>{el}</p>
}
{
	typeof el !== 'string' && el.map((it, j) => <p key={i}>{el}</p>
}
*/