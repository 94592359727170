import { dateToString, dateUTCToClient } from 'libs/convertDate';

const timeInDay = 86400000;
const now = new Date();
const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()).valueOf();
const yesterday = new Date(today - timeInDay).valueOf();


export const getMessageTime = date => {
    if (typeof date === 'object')
        return dateToString(date, 'time');
    return dateToString(dateUTCToClient(date), 'time');
};


/*
* return when was message - today(Сегодня), or yesterday (Вчера), or date (1 Янв), or fullDate (30 Дек 2018г)
* */
export const getMessageDateString = (d, forOnlineStatus) => {
    let date = dateUTCToClient(d);
    let currentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()).valueOf();
    let res = '';
    const time = `в ${dateToString(new Date(d), 'time')}`;

    if (isToday(currentDate)) res = `Сегодня ${forOnlineStatus ? time : ''}`;
    else if (isYesterday(currentDate)) res = `Вчера ${forOnlineStatus ? time : ''}`;
    else if (isThisYear(date)) res = dateToString(date, 'stringShortDate');
    else res = dateToString(date, 'default');
    return res;
};


/*
* true or false - show time message on screen chat
* */
export const compareTime = (time, prevTime) => {
    let timestampCurrent = new Date(time).getTime();
    let timestampPrev = new Date(prevTime).getTime();
    return timestampCurrent - timestampPrev > 300000;
};


/*
* true or false - show date messages on screen chat
* */
export const compareDate = (date, prevDate) => {
    return dateToString(new Date(date), 'default') !== dateToString(new Date(prevDate), 'default');
};


const isToday = d => today - d < timeInDay;
const isYesterday = d => yesterday - d < timeInDay;
const isThisYear = d => now.getFullYear() === d.getFullYear();