import React from 'react';
import BeginPoint from './BeginPoint';
import Point from './Point';
import {change} from 'redux-form';
import AddPoint from "components/ReduxForms/AddRouteForm/AddPointFixed";

export default class LocationPoints extends React.Component{

	setValue = (field, value) => {
		this.props.dispatch(change('addRoute', field, value));
	};

	render(){
		let {dispatch} = this.props,
			fields = {...this.props.fields},
			points = [];

		fields.forEach((field, index)=>{
			if(index === 0){
				points.push(<BeginPoint setValue={this.setValue} dispatch={dispatch} key={field} name={field} />);
			}

			if(index === fields.length - 1){
				points.push(<Point setValue={this.setValue} dispatch={dispatch} key={field} name={field} />);
			}
		});

		points.splice(
			points.length - 1, 0,
			<AddPoint
				points={this.props.points}
				dispatch={dispatch}
				fields={fields}
				key="AddPoint"
			/>
		);
		return (
			<div>
				{points}
			</div>
		);
	}
};