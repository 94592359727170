import showError from 'libs/showFormErrors';
import {required} from 'libs/validationsForms';
import {offersActions} from 'actions/offers';
import {dateToString} from 'libs/convertDate';

export const sendRequestForEditOffer = (values, dispatch, success) => {
	showError({
		text: [required(values.text)],
	});
	success();
	dispatch(offersActions.sendRequestEditOffer(values));
};

export const sendReviewOffer = (values, dispatch, success) => {
	showError({
		title: [required(values.title)],
		text: [required(values.text)],
		priceRating: [required(values.priceRating)],
	});
	success();
	dispatch(offersActions.sendReview(values));
};

export const changeOffer = (values, dispatch, success) => {
	showError({
		price: [required(values.price)],
		startDate: [required(values.startDate)],
		endDate: [required(values.endDate)],
		title: [required(values.cargoes[0].title)],
		weight: [required(values.cargoes[0].weight)],
		width: [required(values.cargoes[0].width)],
		height: [required(values.cargoes[0].height)],
		weightCount: [required(values.cargoes[0].weightCount)],
		category: [required(values.cargoes[0].category)],
		package: [required(values.cargoes[0].package)],
	});
	success();
	values.startDate = dateToString(values.startDate, 'serverDateTime');
	values.endDate = dateToString(values.endDate, 'serverDateTime');
	dispatch(offersActions.updateOffer(values));
};

export const createContract = (values, dispatch, success, error) => {
	showError({
		firstName: [required(values.firstName)],
		middleName: [required(values.middleName)],
		lastName: [required(values.lastName)],
		number: [required(values.number)],
		address: [required(values.address)],
	});
	dispatch(offersActions.createContract(values, {success, error}));
};

export const confirmContractCode = (values, dispatch, success) => {
	showError({
		code: [required(values.code)],
	});

	return new Promise((resolve, reject) => {
		dispatch(offersActions.confirmContractCode(values, {resolve, reject}));
	}).then(result => {
		success();
	}).catch(err => {
		showError({
			code: [err],
		});
	});
};