exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n._3EDvw4Um4j_Qtq5uZHV48F {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px solid #d5deef;\n  margin-bottom: 30px;\n  padding-left: 15px;\n  padding-right: 15px;\n}\n@media screen and (max-width: 768px) {\n  ._3EDvw4Um4j_Qtq5uZHV48F {\n    flex-direction: column;\n    align-items: flex-start;\n    padding-left: 0;\n    padding-right: 0;\n  }\n  ._3EDvw4Um4j_Qtq5uZHV48F > div:first-child {\n    margin-bottom: 20px;\n  }\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/UI/HeaderPageDescription/style.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,cAAc;EACd,+BAA+B;EAC/B,oBAAoB;EACpB,iCAAiC;EACjC,oBAAoB;EACpB,mBAAmB;EACnB,oBAAoB;CACrB;AACD;EACE;IACE,uBAAuB;IACvB,wBAAwB;IACxB,gBAAgB;IAChB,iBAAiB;GAClB;EACD;IACE,oBAAoB;GACrB;CACF","file":"style.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.header_page_container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px solid #d5deef;\n  margin-bottom: 30px;\n  padding-left: 15px;\n  padding-right: 15px;\n}\n@media screen and (max-width: 768px) {\n  .header_page_container {\n    flex-direction: column;\n    align-items: flex-start;\n    padding-left: 0;\n    padding-right: 0;\n  }\n  .header_page_container > div:first-child {\n    margin-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"header_page_container": "_3EDvw4Um4j_Qtq5uZHV48F"
};