import React from 'react';
import PropTypes from 'prop-types';
import { Map, Marker } from 'yandex-map-react';

export default class YandexMap extends React.Component {

	static propTypes = {
		height: PropTypes.number,
	};

	static defaultProps = {
		height: 0,
	};

	state = {
		height: 0,
	};

	updateDimensions() {
		if (window.innerWidth < 1200) {
			this.setState({ height: 300 });
		} else {
			this.setState({ height: 600 });
		}
	}

	componentWillMount() {
		if (this.props.height !== 0) {
			this.setState({ height: this.props.height });
		} else {
			this.updateDimensions();
			window.addEventListener("resize", this.updateDimensions.bind(this));
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions.bind(this));
	}

	equals = (next, prev) => {
		let result = false;

		if(next.length !== prev.length)
			return true;

		next.forEach((point, index) => {
			if(
				!result && (
					(
						point.coordinates[0] !== prev[index].coordinates[0] ||
						point.coordinates[1] !== prev[index].coordinates[1]
					) ||
					(point.key !== prev[index].key) ||
					(point.label !== prev[index].label)
				)
			){
				result = true;
			}
		});
		return result;
	};

	shouldComponentUpdate(nextProps, nextState) {
		return this.equals(nextProps.points, this.props.points);
	}

	render() {
		const {points, loadedMap, loadedApi, renderRoute} = this.props;

		return (
			<Map
				onMapAvailable={loadedMap}
				onAPIAvailable={loadedApi}
				center={[55.754734, 37.583314]}
				apikey="b21467a4-95e1-4e3b-a5bf-3499feacda92"
				zoom={3}
				width="100%"
				height={this.state.height}
			>
				{
					points.length === 1 &&
					points.map(point => (
						<Marker
							key={point.key}
							lat={point.coordinates[0]}
							lon={point.coordinates[1]}
							properties={{
								iconContent: point.key,
								iconCaption: point.label ? point.label : point.key
							}}
						/>
					))
				}
				{
					points.length > 1 && renderRoute(points)
				}
			</Map>
		);
	}
}
