exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".pFV1GQL0fyICOXXZkFt1d {\n  margin-top: 50px;\n}\n._2rU0XXIrYt71Kf0gMSxt8O {\n  color: #7a7a7a;\n  margin: 20px 0 30px;\n  font-size: 15px;\n}\n._3YNo6DTpguMdi0kMfBwUW- {\n  margin-top: 30px;\n}\n._1eBtHOz3RtHbMhYOFk8fKa {\n  margin-top: 60px;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/UI/_V2/Proposals/List/style.less"],"names":[],"mappings":"AAAA;EACE,iBAAiB;CAClB;AACD;EACE,eAAe;EACf,oBAAoB;EACpB,gBAAgB;CACjB;AACD;EACE,iBAAiB;CAClB;AACD;EACE,iBAAiB;CAClB","file":"style.less","sourcesContent":[".section__title {\n  margin-top: 50px;\n}\n.section__desc {\n  color: #7a7a7a;\n  margin: 20px 0 30px;\n  font-size: 15px;\n}\n.action {\n  margin-top: 30px;\n}\n.proposal__list {\n  margin-top: 60px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"section__title": "pFV1GQL0fyICOXXZkFt1d",
	"section__desc": "_2rU0XXIrYt71Kf0gMSxt8O",
	"action": "_3YNo6DTpguMdi0kMfBwUW-",
	"proposal__list": "_1eBtHOz3RtHbMhYOFk8fKa"
};