import { offersActions } from 'actions/offers';
import Dialog from 'components/UI/Dialog';
import Button from 'components/UI/Form/Button';
import Pagination from "components/UI/Pagination/index";
import PreviewOffer from 'components/UI/Previews/PreviewOffer';
import RequestLayer from 'components/UI/Request/RequestLayer';
import RequestProposal from 'components/UI/Request/RequestProposal';
import RequestGridContainer from 'components/UI/Wrappers/RequestGridContainer';
import { getProposalsFromMe } from 'libs/selections';
import { getItem, setItem } from "libs/sessionStorage";
import shortName from 'libs/shortName';
import React from 'react';
import { connect } from 'react-redux';
import { TabPanel } from 'react-tabs';
import styleDialog from '../Cars/style.less';


class DriverProposal extends React.PureComponent {
    state = {
        openForm: false,
        selectedTab: 0,
        idForRemove: 0,
        activePage: getItem('proposal.active', 1),
        list: [
            {
                question: 'Как скоро начнут появляться предложения?',
                answer: 'Сразу же после создания вашего заказа, он становиться доступным для грузоперевозчиков. Если прописанные вами требования по маршруту, стоимости и параметрам транспорта устраивают грузоперевозчика, он может оставить свое предложение, относительно перевозки вашего груза. Чтобы избежать неактуальных предложений, сделайте свой заказ максимально полным и точным.',
                open: false
            },
            {
                question: 'Как увеличить количество просмотров заявок?',
                answer: 'Наш Сервис предлагает равные конкурентные условия для всех пользователей. Популярность вашего заказа будет определяться только его качеством и степенью соответствия запросам грузоперевозчиков. Чтобы увеличить количество просмотра ваших заявок, изучите опыт других пользователей, предложите конкурентную стоимость и добавьте хороший сопроводительный текст.',
                open: false
            },
            {
                question: 'Как разблокировать мою заявку?',
                answer: 'Если по какой-либо причине, ваша заявка была заблокирована, обратитесь в службу поддержки или в службу «Помощь 24/7». При этом укажите обстоятельства, при каких вы обнаружили блокировку. Специалисты HurryLorry, разберутся в проблеме и решат ее, или сообщат причину блокировки и опишут ваши дальнейшие действия.',
                open: false
            },
        ],
    };

    handleSelect = selectedTab => {
        this.setState({ selectedTab });
    };

    removeMyProposal = () => {
        this.props.dispatch(offersActions.removeMyProposal(this.state.idForRemove));
        this.handleDelete(0);
    };

    handleDelete = idForRemove => {
        this.setState({ idForRemove });
    };

    changePage = page => {
        setItem('proposal.active', page);
        this.props.dispatch(offersActions.getMyProposal({ page }));
        this.setState({ activePage: page });
    };

    componentDidMount() {
        this.props.dispatch(offersActions.getMyProposal({ page: this.state.activePage }));
    }

    render() {

        const { contracts } = this.props,
            { activePage } = this.state,
            length = contracts.total,
            requestType = {
                length: length,
                word: ['предложение', 'предложения', 'предложений']
            };

        const tabs = [
            { count: contracts.total, title: 'Активные' }
        ];

        return (
            <RequestLayer
                title={'Мои предложения'}
                tabList={tabs}
                selectedTab={this.state.selectedTab}
                onClickTab={i => this.handleSelect(i)}
                requestType={requestType}
                questions={this.state.list}
            >
                <TabPanel>
                    {
                        contracts.items.map(el => (
                            <RequestGridContainer key={el.id}>
                                <PreviewOffer
                                    offer={el.offer}
                                    type={'proposal'}
                                    actionCancelled={() => this.handleDelete(el.id)}
                                />
                                <RequestProposal
                                    status={el.offer !== null ? el.status : 'deleted'}
                                    price={el.price}
                                    clientName={shortName(el.user2.firstName, el.user2.lastName)}
                                    createTime={el.createTime}
                                />
                            </RequestGridContainer>
                        ))
                    }

                    <Pagination
                        showMore={false}
                        pageCount={Math.ceil(contracts.total / 30)}
                        setPage={this.changePage}
                        activePage={parseInt(activePage)}
                    />

                </TabPanel>

                {
                    this.state.idForRemove !== 0 &&
                    <Dialog handleClose={() => this.handleDelete(0)}>
                        <h3 className={styleDialog.textcenter}>Вы действительно хотите удалить ваше предложение?</h3>
                        <div className={styleDialog.buttonList}>
                            <Button
                                className={styleDialog.buttonFirst}
                                color={'remove'}
                                onClick={this.removeMyProposal}
                            >
                                Удалить
                            </Button>
                            <Button
                                color={'white'}
                                onClick={() => this.handleDelete(0)}
                            >
                                Отменить
                            </Button>
                        </div>
                    </Dialog>
                }

            </RequestLayer>
        );
    }
}


export default connect(store => ({
    contracts: getProposalsFromMe(store)
}))(DriverProposal);
