import React from 'react';
import logo from 'components/UI/Icons/Logo/logo_shortest__color.svg';
import style from 'components/Admin/UI/Header/style.less';


const AdminLogo = () => (
	<div className={style.logo}>
		<img src={logo} alt="logo" />

		<div>
			Добро пожаловать! Админ
		</div>
	</div>
);


export default AdminLogo;