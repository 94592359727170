import React from 'react';
import styleDialog from 'components/Profile/Cars/style.less';
import Button from 'components/UI/Form/Button';
import Dialog from 'components/UI/Dialog';
import PropTypes from 'prop-types';
import cardStyle from 'components/ReduxForms/EditCardForm/style.less';
import EditCardForm from 'components/ReduxForms/EditCardForm';

export const DeleteDialog = ({remove, cancel, isRoute}) => (
	<Dialog handleClose={cancel}>
		<h3 className={styleDialog.textcenter}>Вы действительно хотите удалить {`${isRoute ? 'данный маршрут' : 'данную заявку'}`}?</h3>
		<div className={styleDialog.buttonList}>
			<Button
				className={styleDialog.buttonFirst}
				color="red"
				onClick={remove}
			>
				Удалить
			</Button>
			<Button color="white" onClick={cancel}>
				Отменить
			</Button>
		</div>
	</Dialog>
);

DeleteDialog.propTypes = {
	remove: PropTypes.func.isRequired,
	cancel: PropTypes.func.isRequired,
};

export const AddCardDialog = ({cancel, onSubmit}) => (
	<Dialog className={cardStyle.cardDialog} handleClose={cancel}>
		<EditCardForm onSubmit={onSubmit}/>
	</Dialog>
);

export const EditCardDialog = ({ cancel, onSubmit, initialValues }) => (
	<Dialog className={cardStyle.cardDialog} handleClose={cancel}>
		<EditCardForm initialValues={initialValues} onSubmit={onSubmit}/>
	</Dialog>
);

AddCardDialog.propTypes = {
	cancel: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};