import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';

const Panel = props => {
	const classStyle = { marginBottom: `${props.marginBottom}px` };
	return (
		<div ref={props.customRef} className={style.panel+' '+(props.className || '')} style={classStyle}>
			{
				props.title
				&& <div className={`${style['panel-header']} ${props.action !== null && style.panelHeaderWithAction}`}>
					{ props.title }
					{
						props.action !== null && props.action
					}
				</div>
			}
			<div className={`${style['panel-content']} ${!props.title && style.borderRadiusAll }`}>{props.children}</div>
		</div>
	);
};

Panel.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	className: PropTypes.string,
	marginBottom: PropTypes.number,
	children: PropTypes.node,
	action: PropTypes.node,
};
Panel.defaultProps = {
	title: false,
  	className: '',
	marginBottom: 0,
	children: null,
	action: null,
};

export default Panel;