import React from 'react';
import PropTypes from 'prop-types';
import { driverActions } from 'actions/driver';
import { connect } from 'react-redux';
import { getDriverCarts, getProfileType } from 'libs/selections';
import Panel from 'components/UI/Wrappers/Panel';
import UserCardItem from 'components/Profile/EditProfile/UserCardItem';
import Button from 'components/UI/Form/Button';
import styles from './cardStyle.less';
import { AddSong } from 'components/UI/Icons';
import { AddCardDialog, EditCardDialog } from 'components/Profile/MyCargoDetail/Dialogs';
import { submitCard, submitEditedCard } from 'components/Search/submit';

class UserCardList extends React.Component {

	static propTypes = {
		className: PropTypes.string,
	};

	static defaultProps = {
		className: '',
	};

	state = {
		dialogCard: false,
		dialogCardEdit: false,
		cardEdit: undefined,
	};

	componentWillMount() {
		this.props.dispatch(driverActions.getCards());
	}
	
	componentWillUnmount() {
		this.props.dispatch(driverActions.getCards());
	}

	dialogCardToggle = (dialogCard = true) => this.setState({ dialogCard });
	dialogCardEditToggle = (dialogCardEdit = true) => this.setState({ dialogCardEdit });

	addCard = formValues => {
		let values = { ...formValues };

		if (values.id === undefined) {
			const month = values.issueDate.slice(0, 2),
				  year = 20 + values.issueDate.slice(3, 7);
			values.issueDate = new Date(month + '/01/' + year);
		} else {
			delete values.id;
			delete values.remove;
			delete values.title;
			values.issueDate = new Date(values.issueDate);
		}
		values.fullName = values.fullName.toUpperCase();
		return submitCard(
			values,
			() => this.dialogCardToggle(false),
			this.props.dispatch
		);
	};

	editCard = formValues => {
		let values = { ...formValues };
		const month = values.issueDate.slice(0,2),
			year = 20 + values.issueDate.slice(3,7);
		
		values.issueDate = new Date(month + '/01/' + year);

		delete values.title;
		
		return submitEditedCard(
			values,
			() => this.dialogCardEditToggle(false),
			this.props.dispatch
		);
	};

	restoreCard = card => {
		return this.addCard(card);
	};

	removeCard = card => {
		this.props.dispatch(driverActions.rmCardWithRestore(card));
	};

	dialogOpenEditCard = el => {
		const issueDate = `${el.issueDate.slice(5,7)}/${el.issueDate.slice(2,4)}`;
		this.setState({ dialogCardEdit: true, cardEdit: { ...el, issueDate } });
	};

	dialogCloseEditCard = () => {
		this.setState({ dialogCardEdit: false, cardEdit: undefined });
	};

	render() {
		const { className, cards, role } = this.props;
		const { dialogCard, dialogCardEdit, cardEdit } = this.state;
		if (cards.total === undefined) return null;
		return (
			<Panel
				className={`${className} ${styles.cardItems}`}
				title={'Банковская карта'}
				action={<Button onClick={this.dialogCardToggle}>{ AddSong() } Добавить карту</Button>}
			>
				{
					cards.total > 0 &&
					cards.items.map(el => (
						<UserCardItem
							key={el.id}
							card={el}
							actionDelete={() => this.removeCard(el)}
							actionRestore={() => this.restoreCard(el)}
							actionEdit={() => this.dialogOpenEditCard(el)}
						/>
					))
				}
				{
					cards.total === 0 && role === 'driver' &&
					<p className={styles.p}>Данные карты необходимы для получения оплаты за ваши услуги</p>
				}
				{
					cards.total === 0 && role !== 'driver' &&
					<p className={styles.p}>Данные карты необходимы для оплаты услуг водителя</p>
				}
				{
					dialogCard &&
					<AddCardDialog
						onSubmit={this.addCard}
						cancel={() => this.dialogCardToggle(false)}
					/>
				}
				{
					dialogCardEdit &&
					<EditCardDialog
						onSubmit={this.editCard}
						initialValues={cardEdit}
						cancel={this.dialogCloseEditCard}
					/>
				}
			</Panel>
		)
	}
}

export default connect(
	state => ({
		cards: getDriverCarts(state),
		role: getProfileType(state),
	})
)(UserCardList);