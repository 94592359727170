import React from 'react';
import { getProfileType, getCountersStore } from 'libs/selections';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import style from '../style.less';


class Menu extends React.Component {

    static propTypes = {
        onClick: PropTypes.func,
    };

    static defaultProps = {
        onClick: () => {},
    };

    state = {
		linkActive: '',
        openMenu: false,
    };

    handleClick = linkActive => {
        this.setState({ linkActive, openMenu: false });
    };

    closeMenu = () => this.setState({ openMenu: false });

    toggleOpenMenu = () => this.setState({ openMenu: !this.state.openMenu });

    render() {
        const { pathname, role, counters } = this.props;
        // need to end location
        const userMenu = {
            guest: [// Гость
                { link: '/auth', title: 'Войти' },
                { link: '/registration/', title: 'Регистрация' },
            ],
            customer: [// Заказчик
                { link: '/offers/', title: 'Мои заявки' },
                { link: '/trades/', title: 'Мои сделки' },
                { link: '/favorites/', title: 'Избранные водители' },
            ],
            admin: [// Админ
                { link: '/admin/offers/', title: 'Заявки' },
                { link: '/admin/routes/', title: 'Маршруты' },
                { link: '/admin/trades/', title: 'Сделки' },
                { link: '/admin/arbitrations/', title: 'Арбитраж' },
                { link: '/admin/users/', title: 'Пользователи' },
            ],
            driver: [// Водитель
                { link: '/routes/', title: 'Мои маршруты' },
                { link: '/trades/', title: 'Мои сделки' },
                { link: '/proposals/', title: 'Мои предложения' },
                { link: '/cars/', title: 'Мой транспорт' },
                { link: '/reviews/', title: 'Отзывы' },
            ],
        };

        return (
            <ul>
                {
                    userMenu[role].map(el => {
                        
                        const name = el.link.slice(1,el.link.length - 1);
                        
                        let count = 0;
                        
                        count = counters[name];
                        
                        if (name === 'cars') {
                            count = counters.transports;
                        }
                        
                        return (
                            <li
                                key={el.link}
                                onClick={() => this.handleClick(el.link)}
                            >
                                <Link
                                    to={el.link}
                                    onClick={this.props.onClick}
                                    className={el.link === pathname ? style.active : ''}
                                >
                                    { el.title }
                                    <span className={style.count}>
                                        { count }
                                    </span>
                                </Link>
                            </li>
                        );
                    })
                }
            </ul>
        );
    }
}

export default connect(
    store => ({
        role: getProfileType(store),
        counters: getCountersStore(store),
    })
)(Menu);
