import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ArrowUp } from 'components/UI/Icons';
import MainWhiteContainer from 'components/UI/Wrappers/MainWhiteContainer';
import Container from 'components/UI/Wrappers/Container';
import Button from 'components/UI/Form/Button';
import { getProfileType, getTopOfferList } from 'libs/selections';
// import {offersActions} from 'actions/offers';
import Loader from 'components/UI/Loader';
import style from '../style.less';
import OfferItem from './OfferItem';

class OfferScreen extends React.Component {

	render() {
		let count = this.props.count;
		return (
			<div className={style.offer__screen}>
				<MainWhiteContainer className={style.container}>
					<Container>
						<div className={style.header}>
							<h3 className={style.section_title}>Новые заявки на сайте</h3>
							<div className={style.offers_count}>Заявок на сайте <span>{ ArrowUp() } {count}</span></div>
						</div>
						{
							this.props.topNewOffers.length === 0 && <Loader text={'Идет загрузка новых заявок'} />
						}
						{
							this.props.topNewOffers.length > 0
							&&
							<div className={style.offer_list}>
								<div className={style.items}>
									<div>Дата</div>
									<div>Маршрут</div>
									<div>О грузе</div>
									<div>О транспорте</div>
									<div>Ставка</div>
								</div>
								{
									this.props.topNewOffers.map((el, i) => (
										<OfferItem role={this.props.role} offer={el} key={i}/>
									))
								}
							</div>
						}
						<div className={style.textcenter}>
							<Link to={this.props.role === 'driver' ? '/new/route' : '/new/offer'}>
								<Button size="large">Разместите заявку прямо сейчас</Button>
							</Link>
							<div className={style.come}>и начните получать предложения на перевозку груза</div>
						</div>
					</Container>
				</MainWhiteContainer>
			</div>
		)
	}
}

export default  connect(
	store => ({
		role: getProfileType(store),
		topNewOffers: getTopOfferList(store).slice(0, 6),
	})
)(OfferScreen);
