import React from 'react';
import Container from '../../Wrappers/Container';
import Grid from '../../Wrappers/Grid';
import Company from './Company';
import Menu from './Menu';
import Contacts from './Contacts';
import { connect } from 'react-redux';
import { getProfileType } from 'libs/selections';
import style from '../style.less';

class ColumnContent extends React.PureComponent {

	render() {
		let driver = [
			{ id: 1, link: '/page/becomecarrier/', title: 'Как стать перевозчиком' },
			{ id: 2, link: '/verification/', title: 'Верификация' },
			{ id: 3, link: '/mobile/', title: 'Мобильное приложение' },
			{ id: 5, link: '/tariffs/', title: 'Тарифы' },
		];

		let clients = [
			{ id: 1, link: '/how_start_work/', title: 'Как начать работу' },
			{ id: 2, link: '/insurance/', title: 'Страхование грузов' },
		];

		const guestLinksCustomer = [
			{ id: 10, link: '/registration/customer/', title: 'Регистрация' },
			{ id: 11, link: '/auth/', title: 'Вход' },
		];

		const guestLinksDriver = [
			{ id: 12, link: '/registration/driver/', title: 'Регистрация' },
			{ id: 13, link: '/auth/', title: 'Вход' },
		];

		if (this.props.role === 'guest') {
			driver = [...driver, ...guestLinksDriver];
			clients = [...clients, ...guestLinksCustomer];
		}

		return (
			<Container className={`${style.container} ${this.props.mainPage ? style.containerMain : ''}`}>
				<Grid col="4">
					<Company />
					<Menu title="Перевозчикам" menu={driver} />
					<Menu title="Клиентам" menu={clients} />
					<Contacts />
				</Grid>
			</Container>
		)
	}
}

export default connect(
	store => ({
		role: getProfileType(store),
	})
)(ColumnContent);
