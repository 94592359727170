import React from 'react';
import { Link } from 'react-router-dom';
import style from './style.less';

const Page404 = () => (
	<div className={style.container}>
		<div className={style.error404_circle}>404</div>
		<h1>Такой страницы не существует</h1>
		<p><Link to="/">Перейти на главную страницу</Link></p>
	</div>
);

export default Page404;