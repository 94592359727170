import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getProfileType, getProfile } from 'libs/selections';
import Container from 'components/UI/Wrappers/Container';
import Warning from 'components/UI/Warning';
import style from 'components/UI/NotVerifyAccountMsg/style.less';
// import styleDefault from 'components/Verification/style.less';


class NotVerifyAccountMsg extends Component {
	
	render() {
		
		const { pathname } = this.props.location;
		
		if (this.props.role !== 'driver')
			return null;
		
		if (this.props.profile.isProfileVerification)
			return null;
		
		if (pathname === '' || pathname === '/' || pathname === '/page/becomecarrier' || pathname === '/page/becomecarrier/' )
			return null;
		
		return (
			<Container className={style.indentTop}>
				<Warning type={'warning'}>
					<p>
						Ваш аккаунт не верифицирован. Вам необходимо заполнить паспортные данные и водительское удостоверение.<br/>
						Если вы уже заполнили все формы, то ожидайте проверки со стороны наших модераторов. Обычно это занимает 1 - 4ч.
					</p>
					{/*<p>Ваш аккаунт еще не верифицирован! Для верификации вы обязаны заполнить паспортные данные с фото и*/}
					{/*водительское удостоверение. Как только вы загрузите данные, наши модераторы смогут проверить данные*/}
					{/*и сменить статус верификации. После чего вы сможете полноценно пользоваться сервисом</p>*/}
					{/*<span>После верификации аккаунта вы сможете</span>*/}
					{/*<ul className={styleDefault.defaultList}>*/}
					{/*	<li>Создавать свои маршруты</li>*/}
					{/*	<li>Искать заявки и предлагать свои услуги по перевозке клиентам</li>*/}
					{/*</ul>*/}
				</Warning>
			</Container>
		)
	}
}


export default connect(
	state => ({
		role: getProfileType(state),
		profile: getProfile(state)
	})
)(withRouter(NotVerifyAccountMsg));