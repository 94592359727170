import React from 'react';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import Input from 'components/UI/Form/Fields/Input';
import Button from 'components/UI/Form/Button';
import PropTypes from 'prop-types';
import InputDate from 'components/UI/Form/Fields/InputDate';
import Textarea from 'components/UI/Form/Fields/Textarea';
import {defaultTime} from 'libs/validationsForms';
import { connect } from "react-redux";
import Checkbox from 'components/UI/Form/Fields/Checkbox';
import Select from 'components/UI/Form/Fields/Select';
import style from './style.less';
import Grid from 'components/UI/Wrappers/Grid';
import { convertNumberSpaces } from 'libs/convertNumberSpaces';
import { AddSong } from 'components/UI/Icons';
import { compose } from 'redux';
import asyncValidate from 'libs/asyncValidate';
import getCarsSelect from 'libs/_V2/getCarsSelect';
import getCardsSelect from 'libs/_V2/getCardsSelect';

class ProposalOfferForm extends React.PureComponent {
	
	static propTypes = {
		handleSubmit: PropTypes.func,
		error: PropTypes.string,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
	};
	
	static defaultProps = {
		handleSubmit: () => {},
		invalid: false,
		submitting: false,
		error: '',
	};
	
	setCardPayMethod = () => {
		this.props.dispatch(change('ProposalOffer', 'cardPayMethod', true));
	};
	
	isEnabledSubmit = () => {
		const { payMethodId, cardPayMethod, message, agree, transportId } = this.props.values;
		return payMethodId && cardPayMethod && message && agree && transportId;
	};

	render() {
		
		let { handleSubmit, invalid, cards, addCard, cars, submitting, values } = this.props;
		
		const isEnabledSubmit = this.isEnabledSubmit();
		
		return (
			<form
				onSubmit={handleSubmit}
				autoComplete="off"
			>
				
				<LayoutField className={style.layoutFieldMobile}>
					<Field
						name="message"
						component={Textarea}
						label="Укажите свой опыт работы, преимущества, почему именно вы должны стать исполнителем
						задания"
					/>
				</LayoutField>
				
				<LayoutField className={style.layoutTransport}>
					<Field
						name="transportId"
						component={Select}
						label="Выберите транспорт для перевозки из списка"
					>
						{ getCarsSelect(cars) }
					</Field>
				</LayoutField>
				
				<LayoutField className={style.layoutFieldMobile}>
					
					<Field
						name="cardPayMethod"
						component={Checkbox}
						label={['Оплата на банковскую карту через ', <Link to="/riskfree/" target="_blank">Сделку без риска</Link>]}
					/>
					
					<LayoutField>
						<div className={style.paySelect}>
							<Field
								name="payMethodId"
								component={Select}
								label="Карта для получения оплаты"
								onClick={this.setCardPayMethod}
							>
								{ getCardsSelect(cards, addCard) }
							</Field>
						</div>
					</LayoutField>
					
				</LayoutField>
				
				<LayoutField className={style.layoutFieldMobile}>
					<Field
						name="isOtherSelectNotif"
						component={Checkbox}
						label="Уведомить меня, если исполнителем выберут другого"
					/>
				</LayoutField>
				
				<LayoutField className={style.layoutFieldMobile}>
					<Field
						name="timeOn"
						component={Checkbox}
						label="Указать время актуальности предложения"
						onClick={this.toggleTimeOn}
					/>
					<span className={style.grayDescription}>
						Ваше предложение будет удалено, если за указаное время вас не выберут исполнителем задания
					</span>
				</LayoutField>

				<div className={values.timeOn ? style.openTimeOnTrue : style.openTimeOnFalse}>
					<Grid col={'form-2col'} className={style.time}>
						<LayoutField className={style.layoutFieldMobile}>
							<Field name="expireDate" component={InputDate} label="Дата"/>
						</LayoutField>
						<LayoutField className={style.layoutFieldMobile}>
							<Field
								mask="99:99"
								validate={defaultTime}
								name="expireTime"
								component={Input}
								label="Время"
							/>
						</LayoutField>
					</Grid>
				</div>

				<LayoutField className={style.layoutFieldMobile}>
					<Grid className={style.grid}>
						<div>
							<h3>Стоимость перевозки</h3>
							<p>
								Заказчик указал ставку { convertNumberSpaces(this.props.initialValues.price) } руб.
								<br />Комиссия сервиса 10%
							</p>
						</div>
						<div>
							<Field
								name="price"
								type="number"
								component={Input}
								label="Ваше предложение"
							/>
						</div>
					</Grid>
				</LayoutField>
				
				<Button
					disabled={invalid || !isEnabledSubmit}
					loading={submitting}
					size={'large'}
					type={'submit'}
					className={style.sendButton}
				>
					{ AddSong() } Добавить предложение
				</Button>

				<Field
					name="agree"
					component={Checkbox}
					label={['Я согласен с ', <Link to="/riskfree/" target="_blank">Правилами платежного сервиса «Единая Касса» и сервиса
						<span className={style.displayBlock}>«Сделка без риска»</span></Link>]}
				/>
				
				<p className={`${style.grayDescription} ${style.fullwidth}`}>
					Нажимая на кнопку «Добавить предложение», вы соглашаетесь с правилами платежного сервиса «Название
					сервиса» и сервиса «Сделка без риска». Ваш банк может взымать дополнительную комиссию.
				</p>

			</form>
		);
	}
}


const selector = formValueSelector('ProposalOffer');

export default compose(
	reduxForm({
		form: 'ProposalOffer',
		asyncValidate,
		asyncBlurFields: ['message'],
	}),
	connect(
		state => ({
			values: selector(state, 'payMethodId', 'message', 'cardPayMethod', 'transportId', 'notificationOn',
				'timeOn', 'agree', 'price')
		})
	)
)(ProposalOfferForm);