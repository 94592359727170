exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1nKS8j41JLrt-4_wb3phWi {\n  padding-top: 25px;\n  padding-bottom: 150px;\n}\n._1nKS8j41JLrt-4_wb3phWi h1 {\n  display: flex;\n  justify-content: space-between;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/Admin/UI/Page/style.less"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,sBAAsB;CACvB;AACD;EACE,cAAc;EACd,+BAA+B;CAChC","file":"style.less","sourcesContent":[".container {\n  padding-top: 25px;\n  padding-bottom: 150px;\n}\n.container h1 {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "_1nKS8j41JLrt-4_wb3phWi"
};