import React from 'react';
import listStyle from 'components/Admin/UI/List/style.less';
import language from 'language';

const AdminStatus = ({ status }) => {
	let className = listStyle.false;
	if (status === 'published') className = listStyle.true;
	if (status === 'moderation') className = listStyle.orange;

	return (
		<span className={className}>{ language[`status_${status}`] }</span>
	)
};


export default AdminStatus;