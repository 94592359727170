import React from 'react';
import styles from './cardStyle.less';
import { LabelInfo } from 'components/UI/Form/Labels';
import ActionMenu from 'components/UI/ActionMenu';
import Button from 'components/UI/Form/Button';
import { stringToDate } from 'libs/convertDate';

const UserCardItem = ({ card, actionDelete, actionRestore, actionEdit }) => {
	let cardNumber = `**** **** **** ${card.cardNumber.slice(-4)}`;
	const month = ('0' + (stringToDate(card.issueDate).getMonth() + 1)).slice(-2);
	const year = stringToDate(card.issueDate).getFullYear();
	return (
		<div className={styles.card}>
			<LabelInfo
				className={styles.label}
				title={'Номер карты'}
			>
				{ cardNumber }
			</LabelInfo>
			<LabelInfo
				className={styles.labelUsername}
				title={'Имя владельца'}
			>
				{ card.fullName }
			</LabelInfo>
			<LabelInfo
				className={styles.label}
				title={'Действует до'}
			>
				{ month }/{ String(year).slice(-2) }
			</LabelInfo>
			<div className={`${styles.restore} ${card.remove && styles.show}`}>
				<Button
					clearStyle
					onClick={actionRestore}
				>
					Восстановить
				</Button>
			</div>
			<div className={styles.actionMenu}>
				{
					!card.remove &&
					<ActionMenu
						type={'car'}
						actionDelete={actionDelete}
						actionEdit={actionEdit}
					/>
				}
			</div>
		</div>
	)
};

export default UserCardItem;