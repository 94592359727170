import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Link } from 'react-router-dom';
import Input from 'components/UI/Form/Fields/Input';
import Button from 'components/UI/Form/Button';
import Checkbox from 'components/UI/Form/Fields/Checkbox';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import style from './style.less';
import Grid from 'components/UI/Wrappers/Grid';
import Select from "components/UI/Form/Fields/Select";
import OptionSelect from "components/UI/Form/Fields/OptionSelect";
import InputSelectGroup from "components/UI/Wrappers/InputSelectGroup";
import { connect } from 'react-redux';
import { compose } from 'redux';
import asyncValidate from 'libs/asyncValidate';

const	selector = formValueSelector('login');

export const LoginReduxFormFields = () => (
	<div>
		<LayoutField>
			<InputSelectGroup>
				<Field
					value={1}
					name="phone_number"
					type="text"
					component={Input}
					label="Телефон"
					mask="999 999 99 99"
					containerGroup="left"
				/>
				<Field
					name="phone_code"
					component={Select}
					childGroup="left"
				>
					<OptionSelect value='7'>+7</OptionSelect>
					<OptionSelect value='375'>+375</OptionSelect>
					<OptionSelect value='98'>+98</OptionSelect>
				</Field>
			</InputSelectGroup>
		</LayoutField>
		<LayoutField>
			<Field
				name="password"
				type="password"
				component={Input}
				label="Пароль"
			/>
		</LayoutField>
	</div>
);

class LoginReduxForm extends React.Component {

	static propTypes = {
		handleSubmit: PropTypes.func,
		error: PropTypes.string,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
	};

	static defaultProps = {
		handleSubmit: () => {},
		invalid: false,
		submitting: false,
		error: '',
	};

	isEnabledSubmit = () => {
		const { phone_number, password } = this.props.values;
		if (phone_number && password) return true;
		return false;
	};

	render () {
		let { handleSubmit, invalid, submitting } = this.props;
		const isEnabledSubmit = this.isEnabledSubmit();
		return (
			<form onSubmit={handleSubmit} autoComplete="off">
				<h1>Вход</h1>
				<p>После авторизации вам будет доступeн сервис</p>
				<LoginReduxFormFields/>
				<LayoutField>
					<Grid col="2" className={style.passRemember}>
						<Field
							name="remember"
							type="text"
							component={Checkbox}
							label="Запомнить меня"
						/>
						<div className={style.rec}>
							<Link to="/recovery/">Забыли пароль?</Link>
						</div>
					</Grid>
				</LayoutField>
				<Button
					disabled={invalid || !isEnabledSubmit}
					loading={submitting}
					size={'large'}
					type={'submit'}
				>
					Войти
				</Button>
			</form>
		);
	}
}

export default compose(
	reduxForm({
		form: 'login',
		asyncValidate,
		asyncBlurFields: ['phone_number', 'password'],
	}),
	connect(
		state => ({
			values: selector(state, 'phone_number', 'password')
		})
	)
)(LoginReduxForm);
