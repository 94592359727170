import React from 'react';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import Grid from 'components/UI/Wrappers/Grid';
import style from './style.less';
import InputGroup from 'components/UI/Wrappers/InputGroup';
import {Field} from 'redux-form';
import Input from 'components/UI/Form/Fields/Input';



export default class DimensionsCar extends React.PureComponent {

	state = {
		volume: 0,
		height: 0,
		width: 0,
		length: 0,
	};

	componentDidMount() {
		const height_val = document.querySelector('input[name=height]').value;
		const length_val = document.querySelector('input[name=length]').value;
		const width_val = document.querySelector('input[name=width]').value;

		if (height_val > 0 && length_val > 0 && width_val > 0)
			this.setState({ volume: (height_val * length_val * width_val) });
	}

	setParam = (event, val) => {
		const {height, width, length} = this.state;
		let key = event.target.name,
			volume = 0;
		switch (key) {
			case 'length':
				if (height && width) {
					volume = val * height * width;
				}
				break;
			case 'height':
				if (length && width) {
					volume = val * length * width;
				}
				break;
			case 'width':
				if (length && height) {
					volume = val * length * height;
				}
				break;
			default:
				volume = 0;
				break;
		}
		this.setState({
			[key]: val,
			volume
		});
	};

	render() {
		return (
			<LayoutField>
				<Grid col="form-2-notfull">
					<Grid col="form-3" className={style.grid2}>
						<InputGroup className={style.grid2}>
							<Field
								onChange={this.setParam}
								type="number"
								name="length"
								component={Input}
								label="Длина, м."
							/>
							<Field
								onChange={this.setParam}
								type="number"
								name="width"
								component={Input}
								label="Ширина, м."
							/>
							<Field
								onChange={this.setParam}
								type="number"
								name="height"
								component={Input}
								label="Высота, м."
							/>
						</InputGroup>
						<div className={style.volume}>
							<span>Объем, м<sup>3</sup></span>
							<div>{Math.round(this.state.volume)}</div>
						</div>
					</Grid>
				</Grid>
			</LayoutField>
		);
	}
}