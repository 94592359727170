import React from 'react';
import PropTypes from 'prop-types';
import OfferCheckbox from 'components/UI/Form/Fields/OffersForChat/OfferCheckbox';
import style from './style.less';

const OffersGroup = ({ options, input }) => {
	return (
		<div className={style.checkboxGroup}>
			{
				options.map((option, index) => {
					let style = {transition: 'all 0s', transform: 'none'};
					if (options.length > 1) {
						style = {transitionDelay: index*0.1 + 's'};
					}
					return (
						<OfferCheckbox
							key={index}
							name={`${input.name}[${index}]`}
							value={option.value}
							label={option}
							checked={input.value.indexOf(option.value) !== -1}
							style={style}
							onChange={event => {
								const newValue = [...input.value];
								if (event.target.checked) {
									newValue.push(option.value);
								} else {
									newValue.splice(newValue.indexOf(option.value), 1);
								}
								return input.onChange(newValue);
							}}
						/>
					);
				})
			}
		</div>
	);
};

OffersGroup.propTypes = {
	input: PropTypes.object,
	options: PropTypes.array,
};
OffersGroup.defaultProps = {
	input: {},
	options: [],
};

export default OffersGroup;