import * as adminActions from "actions/admin";
import { getDriver } from 'components/Admin/Pages/Drivers/Detail/getData';
import LeftMenu from 'components/Admin/Pages/Drivers/Detail/LeftMenu';
import LicenseData from "components/Admin/Pages/Drivers/Detail/LicenseData";
import PassportData from 'components/Admin/Pages/Drivers/Detail/PassportData';
import AdminContainer from 'components/Admin/UI/Container';
import AdminGrid from 'components/Admin/UI/Grid';
import NotFound from 'components/Admin/UI/List/NotFound';
import listStyle from 'components/Admin/UI/List/style.less';
import AdminLoader from 'components/Admin/UI/Loader';
import AdminPage from 'components/Admin/UI/Page';
import Gallery from 'components/UI/Gallery';
import { getAdminUser } from 'libs/selections';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PersonalData from "components/Admin/Pages/Drivers/Detail/PersonalData";
import Button from "components/UI/Form/Button/index";

import style from './style.less';


class AdminDriverDetail extends Component {

    state = {
        loading: true,
        photo: '',
        openImage: false,
    };

    loadDriver = async () => {
        this.setState({ loading: true });

        await getDriver(this.props.dispatch, this.props.match.params.id, () => {
            this.setState({ loading: false });
        });
    };

    handleOpenGallery = photo => {
        this.setState({
            openImage: true,
            photo
        });
    };

    handleCloseGallery = () => {
        this.setState({ openImage: false });
    };

    verifyUser = isProfileVerification => {
        this.props.dispatch(adminActions.verifyUser({
            isProfileVerification,
            userId: this.props.match.params.id
        }))
    };

    async componentWillMount() {
        await this.loadDriver();
    }

    render() {
        const { user } = this.props,
            { loading, openImage, photo } = this.state;

        return (
            <AdminPage>
                <LeftMenu/>
                <div>
                    <AdminGrid type="mainContent">
                        <h1>Профиль водителя</h1>
                        <AdminContainer>

                            {
                                loading &&
                                <AdminLoader className={listStyle.loading}/>
                            }

                            {
                                !loading && user &&
                                <>
                                    <PersonalData {...user}/>
                                    <PassportData
                                        passport={user.passport}
                                        onClick={e => this.handleOpenGallery(e)}
                                    />
                                    <LicenseData
                                        license={user.license}
                                        onClick={e => this.handleOpenGallery(e)}
                                    />
                                </>
                            }

                            {
                                !loading && !user &&
                                <NotFound
                                    style={{ marginBottom: 0, marginTop: 0 }}
                                    text={'Водитель не найден'}
                                />
                            }
                            <div className={style.button_group}>
                                <Button color="green" onClick={() => this.verifyUser(true)}>
                                    Верифицировать
                                </Button>
                                &nbsp; &nbsp;
                                <Button onClick={() => this.verifyUser(false)}>
                                    Отказ
                                </Button>
                            </div>
                        </AdminContainer>
                    </AdminGrid>
                </div>
                {
                    openImage &&
                    <Gallery
                        target={photo}
                        handleClose={() => this.handleCloseGallery()}
                    />
                }
            </AdminPage>
        );
    }
}


export default connect(
    state => ({
        user: getAdminUser(state)
    })
)(AdminDriverDetail);