import forwardTo from "libs/forwardTo";
import { getProfileType } from "libs/selections";
import React from 'react';
import Grid from 'components/UI/Wrappers/Grid';
import MainScreen from "components/HelpfulInformations/common/main";
import MainWhiteContainer from "components/UI/Wrappers/MainWhiteContainer";
import styles from "components/HelpfulInformations/style.less";
import Navigation from "components/HelpfulInformations/common/navigation";
import style from "components/MainPage/style.less";
import Button from "components/UI/Form/Button";
import Container from "components/UI/Wrappers/Container";
import bg from 'components/HelpfulInformations/assets/bg_insurance.svg';
import security from 'components/HelpfulInformations/assets/protect.svg';
import AsideSupport from 'components/HelpfulInformations/common/AsideSupport';
import { connect } from "react-redux";


const Insurance = props => (
  <MainScreen title={'Страхование грузов'}
              subtitle={'Поможет защитить груз от повреждений и непредвиденных ситуаций при перевозке. Позволяет не беспокоиться о сохранности и целостности заказа.'}
    bg={bg}
  >
    <MainWhiteContainer className={`${styles.advantage__screen} ${styles.not_padding}`}>
      <Navigation isClient isActive={1}/>
      <div className={styles.padding}>
        <Container>
          <Grid col="10-2">
            <div>
              <h3 className={styles.h3_risk}><b>Что такое страхование грузов</b></h3>
              <p className={styles.p_risk}>
                Доставка грузов автотранспортом сопряжена с риском его полного или частичного повреждения, кражи, воздействия природных сил или третьих лиц, а также технической неисправности транспортного средства или ДТП.
              </p>
              <p className={styles.p_risk}>
                Чтобы защитить себя от связанных с этим убытков, настоятельно рекомендуем оформить страховой полис на автоперевозку груза. Оформление полиса возможно прямо на сайте, одновременно с заключением сделки на грузоперевозку.
              </p>
              <p className={styles.p_risk} style={{ marginBottom: '35px' }}>
                Преимущества страховки на Сервисе HurryLorry в гибких условиях страхования. Так, размер взноса напрямую зависит от стоимости и категории груза, а также срока действия договора, пользователь может воспользоваться страховкой с франшизой или отказаться от нее.
              </p>
              <Button color={'red'} size={'large'} onClick={() => {
                forwardTo(`${
                  props.role === 'guest' ? '/new/offer/' :
                      props.role === 'client' ? '/new/offer/' : '/new/route/'
                }`)
              }}>
                Разместить заказ
              </Button>
            </div>
            <div className={style.support}>
              <div className={style.icon}><img src={security} style={{ maxWidth: '55px', marginBottom: '15px' }} alt=""/></div>
              <div>
                <h4 style={{ fontSize: '21px', fontWeight: 'bold' }}>Гарантируем безопасность сделок</h4>
                <p>
                  Сделка без риска» с одной стороны гарантирует оплату за каждый выполненный заказ, а с другой,
                  – страхует заказчика от оплаты
                  за невыполненную доставку.
                </p>
              </div>
            </div>
          </Grid>
        </Container>
      </div>
    </MainWhiteContainer>
    <Container className={styles.container_cards}>
      <h2 style={{ marginTop: '70px', fontSize: '30px' }}><b>Для заключения страхового договора вам следует:</b></h2>
      <Grid col={'10-2'} className={styles.grid2Col}>
        <ul className={styles.list_card}>
          <li className={styles.list_card__item}>
            <p>
              Подать заявку на оформление страховки, с детальным описанием параметров груза, условий сделки и маршрута;
            </p>
          </li>
          <li className={styles.list_card__item}>
            <p>
              Обсудить вопросы стоимости и условий договора с представителем страховой компании
            </p>
          </li>
          <li className={styles.list_card__item}>
            <p>
              Оформить договор страхования в онлайн режиме прямо на сайте Сервиса.
            </p>
          </li>
        </ul>
  
        <AsideSupport isClient />
      </Grid>
    </Container>
  </MainScreen>
);

export default connect(
    store => ({
      role: getProfileType(store),
    })
)(Insurance);
