import PropTypes from "prop-types";
import React from "react";

const OptionSelect = ({ value, children }) => <div className="item">{children}</div>;
OptionSelect.propTypes = {
	value: PropTypes.any.isRequired,
	filterStr: PropTypes.string,
	children: PropTypes.any.isRequired,
};

export default OptionSelect;