import AdminGrid from 'components/Admin/UI/Grid';
import { AdminLabel } from 'components/Admin/UI/Label';
import React from 'react';
import weightConversion from 'libs/weightConversion';
import style from 'components/UI/RequestDetail/style.less';
import { getVolume } from 'libs/getVolume';

const CargoData = props => {

    const length = props.length,
        width = props.width,
        height = props.height,
        volume = { height, width, length };

    return (
        <>
        <h3> Информация о грузе </h3>
        <AdminGrid type="passport">
            <div>
                <AdminLabel title={'Название товара'}>
                    { props.title }
                </AdminLabel>
                <AdminLabel title={'Тип груза'}>
                    { props.categoryId && props.categoryId.titleRu }
                </AdminLabel>
                <AdminLabel title={'Вес'}>
                    { weightConversion(props.weight) }
                </AdminLabel>
                <AdminLabel title={'Габариты с упаковкой'}>
                    Д { length }м <span className={style.gray}>x</span> Ш { width }м <span className={style.gray}>x</span> В { height }м, { getVolume(volume) }м<sup>3</sup>
                </AdminLabel>
                <AdminLabel title={'Упаковка'}>
                    { props.packageId && props.packageId.titleRu }
                </AdminLabel>
                <AdminLabel title={'Грузовых мест'}>
                    { props.weightCount ? props.weightCount : 'Не указано' }
                </AdminLabel>
                <AdminLabel title={'Дополнительные услуги'}>
                    {
                        props.additionIds.length === 0 && 'Не указано'
                    }
                    {
                        props.additionIds.map((el,i) => {
                            return `${el.titleRu} ${i + 1 !== props.additionIds.length ? ', ' : ''}`;
                        })
                    }
                </AdminLabel>
            </div>
        </AdminGrid>
        </>
    )
};


export default CargoData;
