import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import language from 'language';
import style from 'components/Profile/ProfileView/data.less';
import Avatar from 'components/UI/ProfileUI/Avatar';
import Rating from 'components/UI/ProfileUI/Rating';
import Button from 'components/UI/Form/Button';
import { Comment, Heart } from 'components/UI/Icons';
import AboutRow from 'components/Profile/ProfileView/AboutRow';
import getWordEnd from 'libs/getWordEnd';
import Tooltip from 'components/UI/Tooltip';
import { getFavoriteDrivers } from 'libs/selections';
import { addToFavorite, rmFavoriteById } from 'actions/customer';
import ProfileTransport from 'components/Profile/ProfileView/ProfileTransport';
import ProfileRoutes from 'components/Profile/ProfileView/ProfileRoutes';


class ProfileDriver extends Component {
	
	toggleFavorite = isFavorite => {
		let { id, firstName, lastName } = this.props;
		if (Number.isInteger(isFavorite)) {
			this.props.dispatch(rmFavoriteById({ id: isFavorite }));
		}
		else
			this.props.dispatch(addToFavorite({ id, firstName, lastName }));
	};
	
	render() {
		
		const city = this.props.driver.city || language['NotSpecified'];
		const experienceYears = this.props.driver.experienceYears || language['NotSpecified'];
		
		let isFavorite = false;
		isFavorite = this.props.favorites.filter(el => el.userId === this.props.id);
		isFavorite = isFavorite.length > 0 ? isFavorite[0].id : false;
		
		return (
			<Fragment>
				<div className={style.profile_data}>
					<Avatar
						src={this.props.profile.photo}
						className={style.profile_data_avatar}
						isCircle={false}
						size={270}
					/>
					
					<div className={style.profile_data_content}>
						<div className={style.top_info}>
							
							<div className={style.top_info__offers}>
								Выполненных заявок {this.props.driver.completeTradeCount ?
								this.props.driver.completeTradeCount : 0}
							</div>
							
							{
								(this.props.reviewsCount === 0 || this.props.reviewsCount === null) &&
								<p className={style.top_info__rating}>Отзывы отсутствуют</p>
							}
							
							{
								this.props.reviewsCount > 0 &&
								<Rating
									className={style.top_info__rating}
									count={this.props.rating}
									reviews={{ count: this.props.reviewsCount, url: 'reviews' }}
								/>
							}
							
							<div className={style.actions}>
								<Button
									className={style.actions_send}
									onClick={this.props.toggleChat}
								>
									<Comment/>
									Написать
								</Button>
								<Button
									onClick={() => this.toggleFavorite(isFavorite)}
									className={style.actions_favorite}
									size={'small'}
									color={isFavorite ? "red" : "white"}
								>
									{Heart(style.favorite_icon)}
									<Tooltip
										className={style.tooltip}
										text={isFavorite ? 'Удалить из избранного' : 'Добавить в избранное'}
									/>
								</Button>
							</div>
							
							<div className={style.bottom_info}>
								<h4>{language.AboutDriver}</h4>
								<div className={style.table}>
									<AboutRow title="Email">{this.props.email}</AboutRow>
									<AboutRow title="Стаж вождения">
										{experienceYears} {Number.isInteger(experienceYears)
									&& getWordEnd(experienceYears, ['год', 'года', 'лет'])}
									</AboutRow>
									<AboutRow title="Проживание">{city}</AboutRow>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<ProfileTransport profile={this.props} />
				
				<ProfileRoutes id={this.props.id} />
				
			</Fragment>
			
		)
	}
}


export default connect(
	state => ({
		favorites: getFavoriteDrivers(state),
	}),
)(ProfileDriver);