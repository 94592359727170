import {object} from 'prop-types';
import showError from 'libs/showFormErrors';
import {confirmPassword, required} from 'libs/validationsForms';
import {registration} from 'actions/user';
import {sendCargoRequest} from 'actions/customer';
import * as localStorage from 'libs/localStorage';
import {SubmissionError} from 'redux-form';
import {serverCargoToClient} from 'libs/maper';
import * as sessionStorage from 'libs/localStorage';

export const submitRegistrationUserForCargo = (values:object, showDialogSMS, dispatch) => {
	//если пользователь гость, то зарегистрируем его
	showError({
		firstName: [required(values.firstName)],
		lastName: [required(values.lastName)],
		email: [required(values.email)],
		phone_number: [required(values.phone_number)],
		password: [required(values.password)],
		passwordAgain: [required(values.passwordAgain), confirmPassword(values.password, values.passwordAgain)]
	});
	
	// values.firstName = values.firstName.toUpperCase();
	// values.lastName = values.lastName.toUpperCase();
	
	const phone = values.code + values.phone_number.replace(/\s/g, '');
	return new Promise((resolve, reject) => {
		dispatch(registration({
			...values,
			phone: phone
		}, {
			resolve: resolve,
			reject: reject,
		}));
	}).then(result => {
		if (result) {
			localStorage.setItem('phone', phone);
			if(typeof(showDialogSMS) === 'function')
				showDialogSMS();
		}
	}).catch(err => {
		if (err.phone) {
			err.phone_number = err.phone;
		}
		throw new SubmissionError({
			...err,
			_error: 'Ошибка регистрации пользователя'
		})
	});
};

export const submitRequestCargo = (values, dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch(sendCargoRequest(values, {
			resolve: resolve,
			reject: reject,
		}));
	}).then(() => {
		sessionStorage.removeItem('offerForm');
	}).catch(err => {
		console.log('submitRequestCargo err: ', err);
		showError(serverCargoToClient({
			...err,
			_error: 'Ошибка создания заявки по перевозу груза'
		}));
	});
};