import React from 'react';
import { Link } from 'react-router-dom';
import MainWhiteContainer from 'components/UI/Wrappers/MainWhiteContainer';
import Container from 'components/UI/Wrappers/Container';
import Slider from 'components/UI/Slider';
import style from '../style.less';

class ServiceScreen extends React.Component {


	render() {

		return (
			<div className={style.service__screen}>
				<MainWhiteContainer className={style.container}>
					<Container>
						<h3 className={style.section_title}>Как работает сервис</h3>
						<Slider className={style.slider} />
						<div className={style.textcenter}><Link to="/how_start_work">Узнать больше о работе сервиса</Link></div>
					</Container>
				</MainWhiteContainer>
			</div>
		)
	}
}

export default ServiceScreen;
