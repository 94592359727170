import { createActions } from 'redux-actions';

export const {
    adminActions: {
        getDrivers,
        setDrivers,
        setDriversVerification,

        getClients,
        setClients,

        getOffers,
        setOffers,
        getOfferByID,
        loadingOfferByID,
        setOfferByID,
        setOffersModeration,

        getRoutes,
        setRoutes,
        getRouteByID,
        setRouteByID,
        loadingRouteByID,
        changeRouteStatus,
        setRouteStatus,
        setRoutesModeration,

        getTrades,
        setTrades,

        getArbitrations,
        setArbitrations,

        setOfferStatus,

        setArbitrateStatus,

        changeOfferStatus,
        setUsers,
        getUsers,
        getArbitrateByID,
        setArbitrateByID,
        getUserByID,
        setUserByID,
        getPayTradeStatus,
        setBan,
        rmBan,
        verifyUser,
        setInsure
    }
} = createActions({
    adminActions: {
        getDrivers: [payload => payload, (payload, meta) => meta],
        setDrivers: [payload => payload, (payload, meta) => meta],
        setDriversVerification: [payload => payload, (payload, meta) => meta],

        getClients: [payload => payload, (payload, meta) => meta],
        setClients: [payload => payload, (payload, meta) => meta],

        getOffers: [payload => payload, (payload, meta) => meta],
        setOffers: [payload => payload, (payload, meta) => meta],
        getOfferByID:  [payload => payload, (payload, meta) => meta],
        setOfferByID:  [payload => payload, (payload, meta) => meta],
        loadingOfferByID:  [payload => payload, (payload, meta) => meta],
        setOffersModeration: [payload => payload, (payload, meta) => meta],

        getRoutes: [payload => payload, (payload, meta) => meta],
        setRoutes: [payload => payload, (payload, meta) => meta],
        getRouteByID:  [payload => payload, (payload, meta) => meta],
        setRouteByID:  [payload => payload, (payload, meta) => meta],
        loadingRouteByID:  [payload => payload, (payload, meta) => meta],
        setRoutesModeration: [payload => payload, (payload, meta) => meta],

        getTrades: [payload => payload, (payload, meta) => meta],
        setTrades: [payload => payload, (payload, meta) => meta],

        getArbitrations: [payload => payload, (payload, meta) => meta],
        setArbitrations: [payload => payload, (payload, meta) => meta],


        setOfferStatus: [(payload) => payload, (payload, meta) => meta],
        setRouteStatus: [(payload) => payload, (payload, meta) => meta],
        setArbitrateStatus: [(payload) => payload, (payload, meta) => meta],
        changeRouteStatus: [(payload) => payload, (payload, meta) => meta],
        changeOfferStatus: [(payload) => payload, (payload, meta) => meta],
        setUsers: [(payload) => payload, (payload, meta) => meta],
        getUsers: [(payload) => payload, (payload, meta) => meta],
        getArbitrateByID: [(payload) => payload, (payload, meta) => meta],
        setArbitrateByID: [(payload) => payload, (payload, meta) => meta],
        getUserByID: [(payload) => payload, (payload, meta) => meta],
        setUserByID: [(payload) => payload, (payload, meta) => meta],
        getPayTradeStatus: [(payload) => payload, (payload, meta) => meta],
        setBan: [(payload) => payload, (payload, meta) => meta],
        rmBan: [(payload) => payload, (payload, meta) => meta],
        verifyUser: [(payload) => payload, (payload, meta) => meta],
        setInsure: [(payload) => payload, (payload, meta) => meta],
    }
});
