import handleActions from 'redux-actions/es/handleActions';
import {chatActions} from 'actions/chat';

const getChat = (initStore) => handleActions({
	[chatActions.setDialogs]: (state, action) => ({
		...state,
		dialogs: action.payload
	}),
	[chatActions.clearChat]: () => initStore,
	[chatActions.addDialog]: (state, action) => ({
		...state,
		dialogs: {
			...state.dialogs,
			items: [ ...state.dialogs.items, action.payload ]
		}
	}),
	[chatActions.changeDialogId]: (state, action) => {

		let histories = {...state.histories, [action.payload.newId]: state.histories[action.payload.oldId]};
		delete histories[action.payload.oldId];
		console.log(state);
		return {
			...state,
			histories,
			dialogs: {
				...state.dialogs,
				items: state.dialogs.items.map(it => {
					if(it.id !== action.payload.oldId) return it;
					it.id = action.payload.newId;
					it.new = false;
					return it;
				})
			}
		}
	},
	[chatActions.openDialogByID]: (state, action) => {
		return {
			...state,
			selectedDialogId: action.payload.dialogId,
		}
	},
	[chatActions.openDialog]: (state, action) => {
		const { id, user1, user2 } = action.payload;
		const { items } = state.dialogs;
		
		let selectedDialog = id ? items.filter(el => el.id === id)
			: items.filter(el => ((el.user1.id === user1.id || el.user1.id === user2.id) &&
				(el.user2.id === user2.id || el.user2.id === user1.id)
				&& !el.arbitrationId));

		//если этот диалог только открыли
		if(!action.payload.id) {
			action.payload.id = (new Date()).getTime();
			action.payload.new = true;
		}

		if(selectedDialog[0]) {
			return {
				...state,
				selectedDialogId: selectedDialog[0].id,
			};
		}else{
			if(Object.keys(action.payload).length > 1) {
          return {
              ...state,
              selectedDialogId: action.payload.id,
              dialogs: {
                  ...state.dialogs,
                  items: [  action.payload, ...state.dialogs.items,]
              }
          };
			} else
				return state;
		}
	},
	[chatActions.setHistory]: (state, action) => ({
		...state,
		histories: {
			...state.histories,
			[action.payload.id]: {
				items: action.payload.data,
				total: action.payload.total
			}
		}
	}),
	[chatActions.appendHistory]: (state, action) => ({
		...state,
		histories: {
			...state.histories,
			[action.payload.id]: {
				...state.histories[action.payload.id],
				items: [...state.histories[action.payload.id].items, ...action.payload.data]
			}
		}
	}),
	[chatActions.addMsgToDialog]: (state, action) => ({
		...state,
		histories: {
			...state.histories,
			[action.payload.dialogId]: {
				items: [action.payload.msg, ...state.histories[action.payload.dialogId].items],
				total: state.histories[action.payload.dialogId].total + 1,
			}
		}
	}),
	[chatActions.removeMsgFromClient]: (state, action) => {
		let {dialogId, msgId} = action.payload;
		return {
			...state,
			histories: {
				...state.histories,
				[dialogId]: {
					items: state.histories[dialogId].items.filter(it=>it.id !== msgId),
					total: state.histories[dialogId].total - 1
				}
			}
		};
	},
	[chatActions.removeDialogById]: (state, action) => {
		let newHistories = {...state.histories};
		delete newHistories[action.payload];
		return {
			...state,
			dialogs: {
				...state.dialogs,
				items: state.dialogs.items.filter(it=>it.id !== action.payload),
			},
			histories: newHistories,
		};
	},
	[chatActions.getMessageFromServer]: (state, action) => {
		let dialog = state.dialogs.items.filter(it=>it.id === action.payload.dialogId)[0],
			msg = {
				createTime: action.payload.createTime,
				text: action.payload.text,
				id: action.payload.id,
				isRead: action.payload.isRead,
				senderId: action.payload.sender.id,
			};

		if(dialog){
			let history = state.histories[action.payload.dialogId];
			if(history) history.items = [msg, ...history.items];
			else history = {items: [msg], total: 0};
			return {
				...state,
				dialogs: {
					...state.dialogs,
					items: state.dialogs.items.map(it => {
						if(it.id !== dialog.id) return it;
						return {
							...it,
							lastMessage: msg
						}
					}),
				},
				histories: {
					...state.histories,
					[action.payload.dialogId]: history
				}
			};
		}else{
			dialog = {items: [msg], total: 0};
			return {
				...state,
				dialogs: {
					...state.dialogs,
					items: [{
						lastMessage: msg,
						offers: [],
						id: action.payload.dialogId,
						user1: action.payload.sender,
						user2: action.payload.user2,
						user2UnreadCount: 1,
					},...state.dialogs.items]
				},
				histories: {
					...state.histories,
					[action.payload.dialogId]: dialog
				}
			};
		}
	},
	[chatActions.acceptSendMsg]: (state, action) => {
		let {dialogId, clientMsgId, msg} = action.payload,
			dialog = state.histories[dialogId].items.filter(it=>it.id !== clientMsgId);

		return {
			...state,
			dialogs: {
				...state.dialogs,
				items: state.dialogs.items.map(it => {
					if (it.id !== dialogId) return it;
					it.lastMessage = action.payload.msg;
					return it;
				}),
			},
			histories: {
				...state.histories,
				[dialogId]: {
					...state.histories[dialogId],
					items: [msg, ...dialog],
				}
			}
		};
	},
	[chatActions.markReadMsgToClient]: (state, action) => {
		let { dialogId, msgId } = action.payload;
		return {
			...state,
			histories: {
				[dialogId]: {
					...state.histories[dialogId],
					items: state.histories[dialogId].items.map(msg => {
						if (msg.id !== msgId) return msg;
						msg.isRead = true;
						return msg;
					})
				}
			}
		};
	},
	[chatActions.setCountDontReadMsg]: (state, action) => {
		let count = Number.isInteger(action.payload) ? action.payload : 0;
		return {
			...state,
			countDontReadMsg: count < 0 ? 0 : count
		};
	},
	[chatActions.plusCountDontReadMsg]: (state) => ({
		...state,
		countDontReadMsg: state.countDontReadMsg + 1
	}),
	[chatActions.minusCountDontReadMsg]: (state) => {
		return {
			...state,
			countDontReadMsg: state.countDontReadMsg - 1 < 0 ? 0 : state.countDontReadMsg - 1,
		}
	},
	[chatActions.minusCountReadMsgForDialog]: (state, action) => {
		let items = state.dialogs.items.map(el => {
			let unread1 = el.user1UnreadCount;
			let unread2 = el.user2UnreadCount;
			if (el.id === action.payload) {
				unread1 = unread1 - 1;
				unread2 = unread2 - 1;
			}
			return { ...el, user1UnreadCount: unread1, user2UnreadCount: unread2 };
		});
		return {
			...state,
			dialogs: {
				...state.dialogs,
				items
			},
		}
	},
	[chatActions.plusCountReadMsgForDialog]: (state, action) => {
		let items = state.dialogs.items.map(el => {
			let unread1 = el.user1UnreadCount;
			let unread2 = el.user2UnreadCount;
			if (el.id === action.payload) {
				unread1 = unread1 + 1;
				unread2 = unread2 + 1;
			}
			return { ...el, user1UnreadCount: unread1, user2UnreadCount: unread2 };
		});
		return {
			...state,
			dialogs: {
				...state.dialogs,
				items
			},
		}
	},
}, initStore);

export default getChat;