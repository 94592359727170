import React from 'react';
import style from 'components/Profile/ProfileView/data.less';
import { dateToString, stringToDate } from 'libs/convertDate';
import ChecklistItem from 'components/Profile/ProfileView/ChecklistItem';
import Aside from 'components/UI/Wrappers/Aside';


const ProfileRight = props => {
	const isCustomer = props.role === 'customer';
	return (
		<Aside className={style.profile_check}>
			<p className={style.profile_check_reg}>
				{ isCustomer ? 'Клиент' : 'Водитель' } зарегистрирован <span>
				{ dateToString(stringToDate(props.createTime), 'stringDate') }</span>
			</p>
			
			{
				isCustomer &&
				<div className={style.profile_check_checklist}>
					<ChecklistItem title="Паспорт" status={props.passport} />
					<ChecklistItem title="Телефон" status={props.phone} />
					<ChecklistItem title="Email" status={props.email} />
				</div>
			}
			{
				!isCustomer &&
				<div className={style.profile_check_checklist}>
					<ChecklistItem title="Паспорт" status={props.passport} />
					<ChecklistItem title="Водительское удостоверение" status={props.license} />
					<ChecklistItem title="Телефон" status={props.phone} />
					<ChecklistItem title="Email" status={props.email} />
				</div>
			}
		</Aside>
	)
};


export default ProfileRight;