import AdminGrid from 'components/Admin/UI/Grid';
import { AdminLabel } from 'components/Admin/UI/Label';
import NotFound from 'components/Admin/UI/List/NotFound';
import language from 'language';
import { dateToString, dateUTCToClient } from 'libs/convertDate';
import React from 'react';


const PassportData = ({ passport, onClick }) => {

    const showImage = (e, img) => {
        e.preventDefault();
        onClick(img)
    };

    return (
        <>
            <h3>Паспортные данные</h3>
            {
                passport ?
                    <AdminGrid type="passport">
                        <div>
                            <AdminLabel title="Серия и номер паспорта:">
                                {passport.number}
                            </AdminLabel>

                            <AdminLabel title="Дата выдачи:">
                                {dateToString(dateUTCToClient(passport.issueDate), 'default')}
                            </AdminLabel>

                            <AdminLabel title="Паспорт выдан:">
                                {passport.issuedBy}
                            </AdminLabel>

                            <AdminLabel title="Страна выдачи:">
                                {language[`${passport.issueCountry}_passport`]}
                            </AdminLabel>
                            {
                                passport.scans &&
                                <AdminLabel title="Сканы паспорта:">
                                    {
                                        passport.scans.map((img, index) => (
                                            <a key={index} href={img.images[0].url} onClick={e => showImage(e, img.images[0].url)}>
                                                Скан паспорта {index + 1} &nbsp;
                                            </a>
                                        ))
                                    }
                                </AdminLabel>
                            }
                        </div>
                    </AdminGrid> :
                    <NotFound
                        style={{ marginBottom: 0 }}
                        text={'Пользователь еще не загружал данные паспорта'}
                    />
            }
        </>
    )
};


export default PassportData;