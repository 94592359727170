import AdminGrid from 'components/Admin/UI/Grid';
import { AdminLabel } from 'components/Admin/UI/Label';
import React from 'react';

const TransportDataOffer = props => {


    return (
        <>
        <h3> Информация о транспорте </h3>
        <AdminGrid type="passport">
            <div>
                <AdminLabel title={'Тип кузова'}>
                    { props.bodyTypeId && props.bodyTypeId.titleRu }
                </AdminLabel>
                <AdminLabel title={'Тип погрузки'}>
                    { props.loadTypeId && props.loadTypeId.titleRu }
                </AdminLabel>
            </div>
        </AdminGrid>
        </>
    )
};


export default TransportDataOffer;
