import { eventsActions } from 'actions/events';
import EventsList from "components/Events/EventsList";
import BadgeStyle from 'components/UI/HeaderMainMenu/style.less';
import { Notification } from 'components/UI/Icons';
import Tooltip from 'components/UI/Tooltip';
import OutsideContainer from 'components/UI/Wrappers/OutsideContainer';
import { getEvents, getProfile, getProfileType } from 'libs/selections';
import React from 'react';
import { connect } from 'react-redux';
import style from './style.less';


class Events extends React.PureComponent {

    state = { show: false };

    toggleList = show => this.setState({ show });

    readNotification = id => this.props.dispatch(eventsActions.readEventById(id));

    readAllNotification = () => {
        if (this.props.events.length > 0)
            this.props.events.forEach(el => this.props.dispatch(eventsActions.readEventById(el.id)));
    };

    render() {
        let renderClass = style.event;
        if (this.state.show) renderClass += ` ${style.open}`;

        return (
            <div className={renderClass}>
                <OutsideContainer onClickOutside={() => this.toggleList(false)}>
                    <span onClick={() => this.toggleList(!this.state.show)} className={BadgeStyle.badge}>
                        <Notification/>
                        {
							this.props.events.length > 0
                            && <span className={BadgeStyle.notify}>{this.props.events.length}</span>
                        }
                        <Tooltip text="Уведомления" className={`${BadgeStyle.tooltip} ${style.tooltip}`}/>
                    </span>
                    <EventsList
                        events={this.props.events}
                        role={this.props.role}
                        readAllNotification={this.readAllNotification}
                        readNotification={this.readNotification}
                    />
                </OutsideContainer>
            </div>
        );

    }
}


export default connect(
    store => ({
        events: getEvents(store),
        role: getProfileType(store),
        profile: getProfile(store),
    })
)(Events);