import { chatActions } from 'actions/chat';
import * as customerAction from 'actions/customer';
import { driverActions } from 'actions/driver';
import { eventsActions } from 'actions/events';
import { offersActions } from 'actions/offers';
import { getData, setData, setDataAfterUpdateProfile } from 'actions/profile';
import * as profileDetailActions from 'actions/profileDetail';
import forwardTo from 'libs/forwardTo';
import request from 'libs/request';
import { getProfileType } from 'libs/selections';
import { removeToken } from 'libs/tokenDriver';
import { call, put, select } from 'redux-saga/effects';
import { initStore } from "../reducers/index";
import * as serverHelpersActions from 'actions/serverHelpersActions';

export function* getProfileData(action) {
    let response = yield call(() =>
        request('get', '/api/1.0/profile/my'));

    if (action.meta) {
        if (response.data.errors || response.status !== 200) {
            // yield call(() => action.meta.reject(response.data.errors));
            removeToken();
            yield call(() => action.meta.resolve(true));
            console.log(response.data.errors);
        } else {
            yield put(setData(response.data));
            // yield put(offersActions.getMyProposal({page: 1}));
            yield put(chatActions.connectToServer(response.data.uid));
            yield put(chatActions.getDialogs());
            yield put(eventsActions.getEvents());
            yield put(serverHelpersActions.getCounterList());

            let role = yield select(getProfileType);
            switch (role) {
                case 'driver':
                    // yield put(offersActions.getOffers());
                    // yield put(driverActions.getRoutes());
                    yield put(driverActions.getCards());
                    yield put(driverActions.getMyCars());
                    break;
                case 'customer':
                    // yield put(offersActions.getOffers());
                    // yield put(customerAction.getActiveCargo({ page: 1 }));
                    // yield put(customerAction.getCancelCargo({ page: 1 }));
                    yield put(customerAction.getFavoriteDrivers());
                    break;
                default:
                    break;
            }

            if (action.meta.success) action.meta.success();

            if (action.meta.valueToServer)
                yield put(customerAction.sendCargoRequest(action.meta.valueToServer, action.meta));
            else
                yield call(() => action.meta.resolve(true));
        }
    } else {
        yield put(setData(response.data ? response.data : null));
    }
}

export function* logOutProfile() {
    const response = yield call(() =>
        request('post', '/api/1.0/auth/logout'));

    if (response.status === 200) {
        removeToken('token');
        yield put(setData(null));
        yield call(forwardTo, '/');
        yield put(driverActions.setRoutes(initStore.driver.routes));
        yield put(customerAction.setMyCargoes(initStore.customer.cargoes));
        yield put(chatActions.disconnectChat());
        yield put(offersActions.setOffers(initStore.offers.offerList));
    }
}

export function* editContactProfile(action) {
    const response = yield call(() =>
        request('put', '/api/1.0/profile/my/person', action.payload));

    if (response.data.errors || response.status !== 200) {
        yield call(() => action.meta.reject(response.data.errors));
    } else {
        yield put(setDataAfterUpdateProfile(action.payload));
        yield call(() => action.meta.resolve(true));
    }
}

export function* editAvatarData(action) {
    const response = yield call(() =>
        request('put', '/api/1.0/profile/my/person', action.payload));

    if (response.data.errors || response.status !== 200) {
        yield call(() => action.meta.reject(response.data.errors));
    } else {
        yield call(() => action.meta.resolve(true));
    }
}

export function* changeProfilePassword(action) {
    const response = yield call(() =>
        request('post', '/api/1.0/auth/update-password', action.payload));

    if (response.data.errors || response.status !== 200) {
        yield call(() => action.meta.reject(response.data.errors));
    } else {
        action.meta.success();
        yield call(() => action.meta.resolve(true));
    }
}

export function* updateProfilePassword(action) {
    const response = yield call(() =>
        request('post', '/api/1.0/auth/change-password', action.payload));

    if (response.data.errors || response.status !== 200) {
        yield call(() => action.meta.reject(response.data.errors));
    } else {
        action.meta.success();
        yield call(() => action.meta.resolve(true));
    }
}

export function* changeProfilePassport(action) {
    const response = yield call(() =>
        request('post', '/api/1.0/profile/my/passport', action.payload));

    if (response.data.errors || response.status !== 200) {
        yield call(() => action.meta.reject(response.data.errors));
    } else {
        yield put(getData());
        yield call(() => action.meta.resolve(true));
    }
}

export function* getProfileByID(action) {

    const response = yield call(() =>
        request('get', '/api/1.0/profile/' + action.payload));

    if (response.data.errors || response.status !== 200) {
        yield put(profileDetailActions.setProfileByID(undefined));
        action.meta.reject();
    } else {
        yield put(profileDetailActions.setProfileByID(response.data));
        action.meta && action.meta.resolve(response.data);
    }
}

export function* getReviewsByID(action) {
    const response = yield call(() =>
        request('get', '/api/1.0/profile/' + action.payload.userId + '/reviews', action.payload));

    if (response.data.errors || response.status !== 200) {
        yield put(profileDetailActions.setReviewsByID({ items: [], total: 0 }));
        action.meta.reject();
    } else {
        yield put(profileDetailActions.setReviewsByID(response.data));
        action.meta.resolve(response.data);
    }
}

export function* getClientReviews(action) {
    const response = yield call(() =>
        request('get', '/api/1.0/review/reviews'), action.payload);

    if (response.data.errors || response.status !== 200) {
        yield call(() => action.meta.reject());
    } else {
        yield call(() => action.meta.resolve(response.data));
    }
}
