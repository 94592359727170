import React from 'react';
import Panel from 'components/UI/Wrappers/Panel';
import {Field} from 'redux-form';
import Input from 'components/UI/Form/Fields/Input';
import Grid from '../../UI/Wrappers/Grid';
import style from './style.less';
import { Security } from '../../UI/Icons';
import Textarea from 'components/UI/Form/Fields/Textarea';
import Checkbox from 'components/UI/Form/Fields/Checkbox';

const Price = () => (
	<Panel title="Стоимость перевозки" className={style.customPricePanel}>
		<span>Рекомендованная стоимость перевозки</span>
		<Grid col="form-2">
			<Field
				maxLength={7}
				name={'price'}
				component={Input}
				unit={'руб.'}
			/>
		</Grid>
		<p>* Стоимость перевозки не может быть меньше рекомендованной на 40%</p>
		<div className={style.securityCargo}>
			<div className={style.securityText}>
				<Field
					name="toInsurance"
					component={Checkbox}
					label={['Я хочу застраховать груз при заключении сделки', <p>Размещая заказ, вы принимаете условия <a href="/agreement.pdf" target="_blank">Пользовательского соглашения</a></p>]}
				/>
			</div>
		</div>
		<Field
			name='comment'
			label="Комментарий к заказу"
			component={Textarea}
		/>
	</Panel>
);

export default Price;
