import React from 'react';
import MainWhiteContainer from 'components/UI/Wrappers/MainWhiteContainer';
import Container from 'components/UI/Wrappers/Container';
import MainScreen from './MainScreen';
import AdvantageScreen from 'components/MainPage/AdvantageScreen';
import HowBeginWork from './HowBeginWork';
import PopularQuestions from 'components/MainPage/PopularQuestions';
import BeginNowScreen from 'components/MainPage/BeginNowScreen';
import RatesForDriver from './RatesForDriver';
import style from './style.less';
import start from 'components/BecomeCarrier/1_start.svg';
import garant from 'components/BecomeCarrier/2_garant.svg';
import route from 'components/BecomeCarrier/3_route.svg';
import { connect } from 'react-redux';
import { getProfileType } from 'libs/selections';

class BecomeCarrier extends React.Component {

	render() {
		const advantageList = [
			{title: 'Быстрый старт', text: 'Моментальный доступ к заказам — сразу после регистрации.', src: start},
			{title: 'Гарантия оплаты', text: 'Резервирование средств в Киви-банке — получайте деньги сразу после принятия груза.', src: garant},
			{title: 'Выбор маршрута', text: 'Тысячи направлений по России — найти выгодную работу стало еще проще.', src: route},
		];

		const beginWorkList = [
			{text: 'Регистрация. Верификация. Загрузка документов: водительского удостоверения, карты, свидетельств, в том числе на перевозку спецгрузов.'},
			{text: 'Поиск подходящего груза с помощью встроенных фильтров. Согласование условий с отправителем: сроков, объемов, оплаты. Подтверждение сделки.'},
			{text: 'Прием груза. Проверка на соответствие данных в накладной фактическим. Загрузка. Отправка по маршруту.'},
			{text: 'Передача груза по месту назначения. Уведомление отправителя о выполнении заказа. Перечисление оплаты в полном объеме.'},
		];

		return (
			<div className={style.become_carrier}>
				<MainScreen role={this.props.role} />
				<MainWhiteContainer className={`${style.container} ${style.advantage__screen}`}>
					<Container>
						<AdvantageScreen list={advantageList} />
					</Container>
				</MainWhiteContainer>
				<HowBeginWork role={this.props.role} list={beginWorkList} />
				<MainWhiteContainer className={`${style.container}`}>
					<Container>
						<PopularQuestions />
					</Container>
				</MainWhiteContainer>
				<RatesForDriver role={this.props.role} />
				<BeginNowScreen />
			</div>
		);
	}
}

export default connect(
	state => ({
		role: getProfileType(state),
	})
)(BecomeCarrier);
