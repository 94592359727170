import { setPoints } from 'actions/map';
import AddRouteForm from 'components/ReduxForms/AddRouteForm';
import AddDesiredRouteForms from "components/ReduxForms/AddRouteForm/AddDesiredRouteForms";
import CreateCarReduxForm from 'components/ReduxForms/CreateCar';
import { CustomTabs } from "components/UI/CustomTab/index";
import Dialog from 'components/UI/Dialog';
import Maps from 'components/UI/Maps';
import Grid from 'components/UI/Wrappers/Grid';
import Page from 'components/UI/Wrappers/Page';
import { getVolume } from "libs/getVolume";
import { routeFormToRequestServer } from 'libs/maper';
import { getBodyTypesListStore, getDistance, getLoadTypesListStore, getMyCars, getPoints } from 'libs/selections';
import * as sessionStorage from 'libs/sessionStorage';
import showError from 'libs/showFormErrors';
import { cargoesValidate, required } from 'libs/validationsForms';
import React from 'react';
import { connect } from 'react-redux';
import { TabPanel } from "react-tabs";
import style from './style.less';
import { setCar, submitToCreateRoute } from './submit';
import { getItem } from 'libs/localStorage';


class AddRoute extends React.PureComponent {

    state = {
        showModal: null,
        selectedTab: 0,
        tabList: [{ title: 'Запланированный' }, { title: 'Желаемый' }]
    };

    handleOpenDialog = (showModal = null) => {
        this.setState({ showModal });
    };

    handleCreateCar = values => {
        return setCar(values, this.props.dispatch, () => this.handleOpenDialog());
    };

    showFormToDialog = () => {
        switch (this.state.showModal) {
            case 'car':
                return <CreateCarReduxForm onSubmit={this.handleCreateCar} bodyTypes={this.props.bodyTypes} loadTypes={this.props.loadTypes}/>;
            default:
                return this.handleOpenDialog();
        }
    };

    handleSelect = selectedTab => this.setState({ selectedTab });

    handleSubmit = formValue => {
        const { cargo, transportId } = formValue;
        let values = { ...formValue },
            distance = parseInt(this.props.distance),
            pointFirst = values.items[0].point ? values.items[0].point.pos : values.items[0].point,
            lastI = values.items.length - 1,
            pointLast = values.items[lastI].point ? values.items[lastI].point.pos : values.items[lastI].point;
        values.distance = distance ? distance : values.distance;

        showError({
            'items.0.point': [required(pointFirst)],
            'items.0.startDate': [required(values.items[0].startDate)],
            [`items.${lastI}.point`]: [required(pointLast)],
            [`items.${lastI}.startDate`]: [required(values.items[lastI].startDate)],
            'price': [required(values.price)],
            'cargo': [cargoesValidate(cargo)],
            'transportId': [required(transportId)]
        });

        values.bodyLength = formValue.cargo.length;
        values.bodyHeight = formValue.cargo.height;
        values.bodyWidth = formValue.cargo.width;
        values.bodySize = getVolume(formValue.cargo);
        values.type = 'planned';

        return submitToCreateRoute(routeFormToRequestServer(values), this.props.dispatch);
    };

    handleSubmitTwo = formValue => {

        if (formValue.geography && formValue.geography === "country" && !formValue.edit) {
            formValue.items.splice(1, 1)
        }


        const { transportId, geography, typePeriodPublication } = formValue;

        let values = { ...formValue },
            pointFirst = values.items[0].point ? values.items[0].point.pos : values.items[0].point;

        showError({
            'items.0.point': [required(pointFirst)],
            price: [required(values.price)],
            transportId: [required(transportId)],
            geography: [required(geography)],
            typePeriodPublication: [required(typePeriodPublication)]
        });

        let result = routeFormToRequestServer(values);
        result.distance = 1;
        if (result.geography !== 'country') {
            result.items.splice(1, 1);
        }

        result.type = 'desired';
        return submitToCreateRoute(result, this.props.dispatch);
    };

    componentWillUnmount() {
        sessionStorage.removeItem('addRoute');
    }

    componentDidMount() {
        let values = JSON.parse(sessionStorage.getItem('addRoute', '{}'));

        if (values.type === 'desired') {
            this.setState({ selectedTab: 1 })
        }

        if (Array.isArray(values.items))
            this.props.dispatch(setPoints(values.items.map(it => it.point)));
    }

    render() {
        let valuesFromStorage = JSON.parse(sessionStorage.getItem('addRoute', '{}')),
            initialValues = {},
            isEdit = Object.keys(valuesFromStorage).length > 0;

        if (isEdit)
            initialValues = valuesFromStorage;
        else {
            initialValues = { items: this.props.points };

            if (getItem('points')) {
                let startPoint = JSON.parse(getItem('points'))[0].address;
                let endPoint = JSON.parse(getItem('points'))[1].address;

                if (startPoint) startPoint = { point: startPoint };
                else startPoint = { key: 'A' };

                if (endPoint) endPoint = { point: endPoint };
                else endPoint = { key: 'B' };

                initialValues = {
                    items: [ startPoint, endPoint ]
                }
            }
        }

        if (this.props.cars.length === 1) {
            initialValues.transportId = this.props.cars[0].id;
        }

        return (
            <Page title={isEdit ? 'Редактирование маршрута' : 'Добавление нового маршрута'}>
                <Grid className={style.marginAfterPanel} col="9-3">
                    <div>
                        <CustomTabs
                            onClickTab={this.handleSelect}
                            selectedTab={this.state.selectedTab}
                            tabList={this.state.tabList}
                            descriptionLeft={`${this.state.tabList[this.state.selectedTab].title} маршрут`}
                        >
                            <TabPanel>
                                <AddRouteForm
                                    isEdit={isEdit}
                                    openCreateCar={() => this.handleOpenDialog('car')}
                                    cars={this.props.cars}
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                />
                            </TabPanel>
                            <TabPanel>
                                <AddDesiredRouteForms
                                    isEdit={isEdit}
                                    openCreateCar={() => this.handleOpenDialog('car')}
                                    cars={this.props.cars}
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmitTwo}
                                    type="desired"
                                />
                            </TabPanel>
                        </CustomTabs>
                    </div>
                    <div>
                        <Maps/>
                    </div>
                </Grid>
                {this.state.showModal &&
                    <Dialog handleClose={() => this.handleOpenDialog()}>
                        {this.showFormToDialog()}
                    </Dialog>
                }
            </Page>
        );
    }
}


export default connect(
    store => ({
        cars: getMyCars(store),
        points: getPoints(store),
        distance: getDistance(store),
        bodyTypes: getBodyTypesListStore(store),
        loadTypes: getLoadTypesListStore(store),
    })
)(AddRoute);
