import {createActions} from 'redux-actions';

export const {eventsActions} = createActions({
	eventsActions: {
		setEvents: [(payload) => payload, (payload, meta) => meta],
		getEvents: [() => null, (payload, meta) => meta],
		addEvents: [(payload) => payload, (payload, meta) => meta],
		readEventById: [(payload) => payload, (payload, meta) => meta],
		removeEventByIdFromClient: [(payload) => payload, (payload, meta) => meta],
	}
});