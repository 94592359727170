import React from 'react';
import styles from './styles.less';
import PropTypes from 'prop-types';
import Button from 'components/UI/Form/Button';
import { ArrowDownNavigate } from 'components/UI/Icons';

export default class Accordion extends React.Component {

	static propTypes = {
		title: PropTypes.string.isRequired,
		children: PropTypes.node,
		className: PropTypes.string,
	};

	static defaultProps = {
		children: '',
		className: '',
	};

	state = {
		open: false,
	};

	toggleAccordion = () => this.setState({ open: !this.state.open });

	render () {
		const { className } = this.props;
		return (
			<div className={`${styles.accordion} ${className && className} ${this.state.open && styles.open}`}>
				<Button clearStyle className={styles.title} onClick={this.toggleAccordion}>
					<span>{ this.props.title }</span>
					{ ArrowDownNavigate() }
				</Button>
				<div className={styles.content}>
					{ this.props.children }
				</div>
			</div>
		)
	}
}