import React from 'react';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import { getCar } from 'libs/getInfoRoute';
import OptionSelectAction from 'components/UI/Form/Fields/OptionSelectAction';


const getCarsSelect = (cars, openCreateCar) => {
	let renderCars = cars.map(car => (
			<OptionSelect value={car.id} key={car.id}>
					{getCar(car)} <b style={{ textTransform: 'uppercase' }}>{car.registrationNumber}</b>
			</OptionSelect>
	));
	
	if (cars.length === 0) {
			renderCars.push(
					<OptionSelectAction key={-1} value={-1} onClick={openCreateCar}>
							+ Добавить транспорт
					</OptionSelectAction>
			)
	}
	
	return (
			renderCars
	)
};




export default getCarsSelect;