import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.less';
import { LabelInfo } from 'components/UI/Form/Labels';
import Panel from 'components/UI/Wrappers/Panel';
import Point from 'components/UI/Point';
import { dateToString, stringToDate } from 'libs/convertDate';

const RouteInfo = props => {
	const { point, route, cities } = props;
	const word = point === 'A' ? ['отправлении','отправления','об'] : ['прибытии','прибытия','о'];

	if (point === 'thirdPoint') {
		let citiesRender = cities.slice(1, cities.length - 1);
		if (citiesRender.length === 0) return null;
		return (
			<Panel title={`Попутные города`} marginBottom={30}>
				<div className={styles.container}>
					<Point point={point} className={styles.point}/>
					<p>
						{
							citiesRender.map((el,i) => `${el.locality}${i + 1 < citiesRender.length ? ' — ' : ''}`)
						}
					</p>
				</div>
			</Panel>
		);
	}

	return (
		<Panel title={`Информация ${word[2]} ${word[0]}`} marginBottom={30}>
			<div className={styles.container}>
				<Point point={point} className={styles.point} />
				<LabelInfo title={`Адрес ${word[1]}`}>
					{ route.address }
				</LabelInfo>
				<LabelInfo title={`Дата ${word[1]}`}>
					{ dateToString(stringToDate(route.startDate), 'default') }
				</LabelInfo>
			</div>
		</Panel>
	);
};

RouteInfo.propTypes = {
	point: PropTypes.string,
	route: PropTypes.object,
	cities: PropTypes.array,
};
RouteInfo.defaultProps = {
	point: 'thirdPoint',
	route: { address: '', startDate: '' },
	cities: [],
};

export default RouteInfo;