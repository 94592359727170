import React from 'react';
import PropTypes from 'prop-types';
import { convertNumberSpaces } from 'libs/convertNumberSpaces';
import style from './style.less';

class OfferCheckbox extends React.Component {

	static propTypes = {
		label: PropTypes.any,
		className: PropTypes.string,
	};
	static defaultProps = {
		label: {},
		className: '',
	};

	render () {
		const { label } = this.props;

		return (
			<div className={style.offerCheckbox}>
				<div className={style.description} style={this.props.style}>
					<div className={style.info}>№{ label.id } от { label.date }</div>
					<div className={style.title}>{ label.title }
					<span>{ convertNumberSpaces(label.price) }
						{
							label.role === 'driver' && 'р/км.'
						}
						{
							label.role === 'customer' && 'руб.'
						}
						</span>
					</div>
				</div>
				<label className={style.checkbox} style={this.props.style}>
					<input {...this.props} {...this.props.input} type="checkbox"/>
					<span>
						{ !this.props.checked && 'Выбрать' }
						{ this.props.checked && 'Убрать' }
					</span>
				</label>
			</div>
		)
	}
}

export default OfferCheckbox;