import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';

const InputGroup = ({ children, className }) => (
	<div className={`${style.InputGroup} ${className ? className : ''}`}>
		{ children }
	</div>
);

InputGroup.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};
InputGroup.defaultProps = {
	children: {},
	className: '',
};

export default InputGroup;
