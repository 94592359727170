exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2cx0_wSk7N6jbw1P710hr2 {\n  margin: 0;\n  fill: #555;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/Admin/UI/UpdateButton/style.less"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;CACZ","file":"style.less","sourcesContent":[".refresh {\n  margin: 0;\n  fill: #555;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"refresh": "_2cx0_wSk7N6jbw1P710hr2"
};