import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { UseCustomStyles } from 'libs/UseCustomStyles';
import HorizontalMenu from './HorizontalMenu';
import Copyright from './Copyright';
import ColumnContent from './ColumnContent';
import style from './style.less';

const Footer = props => {
	const isCustomStyles = UseCustomStyles(props.location.pathname);
	return (
		<footer className={isCustomStyles ? style.footer_main : ''}>
			<HorizontalMenu mainPage={isCustomStyles}/>
			<ColumnContent mainPage={isCustomStyles}/>
			<Copyright mainPage={isCustomStyles}/>
		</footer>
	)
};

Footer.propTypes = {
	mainPage: PropTypes.bool,
};
Footer.defaultProps = {
	mainPage: false,
};

export default withRouter(Footer);
