import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { LabelError } from 'components/UI/Form/Labels';
import Input from 'components/UI/Form/Fields/Input';
import Button from 'components/UI/Form/Button';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import style from './style.less';
import { connect } from 'react-redux';
import { compose } from 'redux';
import asyncValidate from 'libs/asyncValidate';

const	selector = formValueSelector('confirm');

class ConfirmReduxForm extends React.Component {

	static propTypes = {
		handleSubmit: PropTypes.func,
		error: PropTypes.string,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
	};

	static defaultProps = {
		handleSubmit: () => {
		},
		invalid: false,
		submitting: false,
		error: '',
	};

	isEnabledSubmit = () => {
		const { code } = this.props;
		if (code !== null && code !== undefined)
			if (/[0-9]{4,}$/.test(code) && code.length === 4)
				return true;
		return false;
	};

	render () {
		let { handleSubmit, invalid, error, submitting, customButton } = this.props;
		const isEnableSubmit = this.isEnabledSubmit();
		return (
			<form onSubmit={handleSubmit} autoComplete="off">
				<LayoutField>
					<Field
						name="code"
						type="text"
						component={Input}
						label="Sms код"
					/>
				</LayoutField>
				{
					error &&
					<LabelError message={error}/>
				}
				{ customButton }
				<Button
					disabled={invalid || !isEnableSubmit}
					loading={submitting}
					size="large"
					type="submit"
				>
					Подтвердить телефон
				</Button>
			</form>
		);
	}
}

export default compose(
	reduxForm({
		form: 'confirm',
		asyncValidate,
		asyncBlurFields: ['code'],
	}),
	connect(
		state => ({
			code: selector(state, 'code')
		})
	)
)(ConfirmReduxForm);