import { handleActions } from 'redux-actions';
import * as profileDetailActions from 'actions/profileDetail';

const getProfileDetail = initStore => handleActions({
	[profileDetailActions.setProfileByID]: (state, action) => ({
		...state,
		user: action.payload,
	}),
	[profileDetailActions.setReviewsByID]: (state, action) => ({
		...state,
		reviews: action.payload,
	}),
}, initStore);

export default getProfileDetail;