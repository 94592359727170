import AdminGrid from 'components/Admin/UI/Grid';
import { AdminLabel } from 'components/Admin/UI/Label';
import NotFound from 'components/Admin/UI/List/NotFound';
import { dateToString, dateUTCToClient } from 'libs/convertDate';
import React from 'react';



const LicenseData = ({ license, onClick }) => {

    const showImage = (e, img) => {
        e.preventDefault();
        onClick(img)
    };

    return (
        <>
        <h3> Данные удостоверения </h3>
        {
            license ?
                <AdminGrid type="passport">
                    <div>
                        <AdminLabel title="Серия, номер копия:">
                            {license.number}
                        </AdminLabel>
                        <AdminLabel title="Категория удостоверения:">
                            {license.categories.map((el, index) => (
                                <span key={index}> {el} &nbsp; </span>
                            ))}
                        </AdminLabel>

                        <AdminLabel title="Действителен до:">
                            {dateToString(dateUTCToClient(license.issueDate), 'default')}
                        </AdminLabel>
                        <AdminLabel title="Страна выдачи документа:">
                            {license.issueCountry}
                        </AdminLabel>
                        {
                            license.scans &&
                            <AdminLabel title="Сканы удостоверения:">
                                {
                                    license.scans.map((img, index) => (
                                        <a key={index} href={img.images[0].url} onClick={e => showImage(e, img.images[0].url)}>
                                            Скан удостоверения {index + 1}
                                        </a>
                                    ))
                                }
                            </AdminLabel>
                        }
                    </div>
                </AdminGrid> :
                <NotFound
                    style={{ marginBottom: 0 }}
                    text={'Пользователь еще не загружал данные удостоверения'}
                />
        }
        </>
    )
};


export default LicenseData;