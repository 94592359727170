import React from 'react';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import Input from 'components/UI/Form/Fields/Input';
import InputDate from "components/UI/Form/Fields/InputDate";
import Button from 'components/UI/Form/Button';
import PropTypes from 'prop-types';
import Grid from 'components/UI/Wrappers/Grid';
import ImageUpload from 'components/UI/Form/Fields/ImageUpload';
import style from './style.less';
import { compose } from 'redux';
import { connect } from 'react-redux';
import asyncValidate from 'libs/asyncValidate';

const selector = formValueSelector('passportData');

class ProfilePassportForm extends React.Component {

	static propTypes = {
		handleSubmit: PropTypes.func,
		error: PropTypes.string,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
		isCustomerData: PropTypes.bool
	};

	static defaultProps = {
		handleSubmit: () => {
		},
		invalid: false,
		submitting: false,
		error: '',
		isCustomerData: false,
	};

	isEnabledSubmit = () => {
		const { values, initialValues } = this.props;
		if (
			values.number !== initialValues.number ||
			values.issueDate !== initialValues.issueDate ||
			values.issuedBy !== initialValues.issuedBy ||
			values.registrationAddress !== initialValues.registrationAddress ||
			// values.additionalPhone_number.replace(/\s/g, '') !== initialValues.additionalPhone_number ||
			values.scanIds !== initialValues.scanIds
		)
			return true;
		return false;
	};

	render() {
		let { handleSubmit, invalid, submitting } = this.props;
		const isEnabledSubmit = this.isEnabledSubmit();
		return (
			<form onSubmit={handleSubmit} autoComplete="off">
				<Grid col="form-2-notfull">
					<LayoutField>
						<Field
							name="number"
							type="text"
							component={Input}
							mask="9999 999999"
							label="Серия и номер паспорта"
						/>
					</LayoutField>
					<LayoutField>
						<Field
							name="issueDate"
							type="text"
							component={InputDate}
							label="Дата выдачи"
						/>
					</LayoutField>
				</Grid>
				<Grid col="form-2-notfull">
					<LayoutField>
						<Field
							name="issuedBy"
							type="text"
							component={Input}
							label="Паспорт выдан"
						/>
					</LayoutField>
					<LayoutField>
						<Field
							name="registrationAddress"
							type="text"
							component={Input}
							label="Адрес регистрации (Город, улица, дом, квартира)"
						/>
					</LayoutField>
				</Grid>
				<LayoutField className={style.uploadImageProfile}>
					<span>Цветной скан паспорта</span>
					<Field
						type='passport'
						name="scanIds"
						component={ImageUpload}
						multiload
						label="Загрузить скан-копию паспорта"
					/>
				</LayoutField>
				<Button
					disabled={invalid || !isEnabledSubmit}
					loading={submitting}
					size={'large'}
					type={'submit'}
				>
					{ this.props.isCustomerData && 'Сохранить изменения' }
					{ !this.props.isCustomerData && 'Отправить на верификацию' }
				</Button>
			</form>
		);
	}
}


export default compose(
	reduxForm({
		asyncValidate,
		asyncBlurFields: ['number','issueDate','issuedBy','registrationAddress','scanIds'],
		form: 'passportData',
	}),
	connect(
		state => ({
			values: selector(state, 'number', 'issueDate', 'issuedBy', 'registrationAddress', 'scanIds')
		})
	)
)(ProfilePassportForm);