import React, { Fragment } from 'react';
import style from 'components/UI/_V2/Proposals/List/style.less';
import language from 'language';


const ProposalListTitle = ({ total, role, type }) => (
	<Fragment>
		<h2 className={style.section__title}>
			Предложений { total ? total : 0 }
		</h2>
		
		<p className={style.section__desc}>
			{ language[`Proposal_${role}_${type}`] }
		</p>
	</Fragment>
);


export default ProposalListTitle;