import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';

const UserName = props => {
	const className = `${style.username} ${props.className ? props.className : ''}`;
	const name = props.name;

	return (
		<div className={className}>
			{ name } { props.isYou && '(Ваше предложение)' }
		</div>
	);
};

UserName.propTypes = {
	name: PropTypes.string.isRequired,
	lastName: PropTypes.string,
	className: PropTypes.string,
	isYou: PropTypes.bool,
};
UserName.defaultProps = {
	lastName: '',
	className: '',
	isYou: false,
};

export default UserName;