import React from 'react';
import style from 'components/Admin/UI/RouteList/style.less';


const RouteList = props => {
	return (
		<div className={style.container}>
			<div className={style.points}>
				<div className={`${style.point} ${style.point_A}`} />
				<div className={`${style.point} ${style.point_B}`} />
			</div>
			<div className={style.route}>
				<div>
					{ props.start }
				</div>
				<div>
					{ props.end }
				</div>
			</div>
		</div>
	)
};


export default RouteList;