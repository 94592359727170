import React from 'react';
import ConfirmForm from 'components/ReduxForms/СonfirmForm';
import { SubmissionError } from 'redux-form';
import showError from 'libs/showFormErrors';
import { required } from 'libs/validationsForms';
import { setItem, getItem } from 'libs/localStorage';
import {confirm} from 'actions/user';
import PropTypes from 'prop-types';
import Button from 'components/UI/Form/Button';
import {recovery} from 'actions/user';
import {add} from 'actions/notifications';
import getWordEnd from 'libs/getWordEnd';
import style from './style.less';


class ConfirmPhone extends React.PureComponent {

	static propTypes = {
		success: PropTypes.func,
		error: PropTypes.func,
		dispatch: PropTypes.func.isRequired,
	};

	static defaultProps = {
		success: () => {},
		error: () => {},
	};

	timer = undefined;

	state = {
		time: 120
	};

	componentDidMount() {
		this.runTimer();
	}

	componentWillUnmount() {
		// removeItem('phone');
	}

	runTimer = () => {
		this.setState({ time: 120 }, () => {
			this.timer = setInterval(() => this.setState({time: this.state.time - 1}), 1000);
		});
	};

	handleSubmitConfirm = values => {
		if(!values.phone)
			values.phone = getItem('phone');

		showError({
			code: [required(values.code)],
			phone: [required(values.phone)],
		});

		return new Promise((resolve, reject) => {
			this.props.dispatch(confirm({...values}, {
				resolve: resolve,
				reject: reject,
			}));
		}).then(token => {
			setItem('recovery_token', token);
			this.props.success();
		}).catch(err => {
			this.initError(err);
		});
	};

	initError = (err:Object = {}) => {
		this.props.error();
		throw new SubmissionError({
			_error: 'Ошибка в подтверждении SMS',
			...err,
		})
	};

	handleSendSMS = () => {
		this.runTimer();
		return new Promise((resolve, reject) => {
			this.props.dispatch(recovery({phone:getItem('phone')}, {
				resolve: resolve,
				reject: reject,
			}));
		}).then(() => {
			this.props.dispatch(add({
				time: 10,
				type: 'success',
				text: 'Новый SMS код успешно отправлен',
			}));
		}).catch(()=>{
			clearInterval(this.timer);
			this.props.dispatch(add({
				time: 10,
				type: 'error',
				text: 'Ошибка отправки нового SMS кода',
			}));
		});
	};

	render() {
		let { time } = this.state,
			labelBtn = '';

		if (time !== 0)
			labelBtn = 'через ' + time + ' ' + getWordEnd(time, ['секунду', 'секунды', 'секунд']);
		else clearInterval(this.timer);

		return(
			<div>
				<h1>Подтверждение телефона</h1>
				<p>На ваш номер +{ getItem('phone') } выслан код с подтверждением</p>
				<ConfirmForm
					onSubmit={this.handleSubmitConfirm}
					customButton={
						<p className={style.againSendCodeP}>
							<Button clearStyle className={style.againSendCode} disabled={time !== 0} onClick={this.handleSendSMS}>Отправить код повторно</Button>
							{' '}{ labelBtn }
						</p>
					}
					initialValues={{ phone: getItem('phone') }}
				/>
			</div>
		)
	}
}


export default ConfirmPhone;