exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n._15j0ZLY73WE5YLZtpiiebu {\n  background: #f1f7fc;\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/Admin/UI/Layout/style.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,oBAAoB;EACpB,cAAc;EACd,uBAAuB;EACvB,kBAAkB;CACnB","file":"style.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.adminWrapper {\n  background: #f1f7fc;\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"adminWrapper": "_15j0ZLY73WE5YLZtpiiebu"
};