import React from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import Input from 'components/UI/Form/Fields/Input';
import Button from 'components/UI/Form/Button';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import { connect } from 'react-redux';
import { compose } from 'redux';
import asyncValidate from 'libs/asyncValidate';

const	selector = formValueSelector('changePassword');

class ChangePassword extends React.Component {

	static propTypes = {
		handleSubmit: PropTypes.func,
		error: PropTypes.string,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
	};

	static defaultProps = {
		handleSubmit: () => {
		},
		invalid: false,
		submitting: false,
		error: '',
	};

	isEnabledSubmit = () => {
		const { newPassword, newPasswordAgain } = this.props.values;
		if (newPassword && newPasswordAgain)
			if (newPassword.length >= 8 && newPasswordAgain.length >= 8) return true;
		return false;
	};

	render () {
		const { handleSubmit, invalid } = this.props;
		const isEnabledSubmit = this.isEnabledSubmit();
		return (
			<form onSubmit={handleSubmit} autoComplete="off">
				<h1>Введите новый пароль</h1>
				<p>Придумайте и запомните новый пароль. После сохранения вы автоматически будете авторизованы на сервисе</p>
				<LayoutField>
					<Field
						name="newPassword"
						type="password"
						component={Input}
						hint={'Пароль должен содержать не менее 6 символов'}
						label="Новый пароль"
					/>
				</LayoutField>
				<LayoutField>
					<Field
						name="newPasswordAgain"
						type="password"
						component={Input}
						label="Повторите новый пароль"
					/>
				</LayoutField>
				<Button
					disabled={invalid || !isEnabledSubmit}
					loading={this.props.submitting}
					size={'large'}
					type={'submit'}
				>
					Сохранить новый пароль
				</Button>
			</form>
		);
	}
}

export default compose(
	reduxForm({
		form: 'changePassword',
		asyncValidate,
		asyncBlurFields: ['newPassword','newPasswordAgain'],
	}),
	connect(
		state => ({
			values: selector(state, 'newPassword', 'newPasswordAgain')
		})
	)
)(ChangePassword);