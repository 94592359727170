import * as customerAction from 'actions/customer';
import { rmCargoRequest } from 'actions/customer';
import { offersActions } from 'actions/offers';
import { setCar } from 'components/AddRoute/submit';
import { submitCard, submitDriverOffer } from 'components/Search/submit';
import Dialog from 'components/UI/Dialog';
import Button from 'components/UI/Form/Button';
import Loader from 'components/UI/Loader';
import Maps from 'components/UI/Maps';
import InfoCargo from 'components/UI/RequestDetail/InfoCargo';
import InfoPointA from 'components/UI/RequestDetail/InfoPointA';
import InfoPointOther from 'components/UI/RequestDetail/InfoPointOther';
import Grid from 'components/UI/Wrappers/Grid';
import Page from 'components/UI/Wrappers/Page';
import { dateToString, stringToDate } from 'libs/convertDate';
import forwardTo from 'libs/forwardTo';
import { getVolume } from 'libs/getVolume';
import { scrollToElement } from 'libs/scrollTo';
import { getDetailOffer, getDriverCarts, getMyCars, getProfile } from 'libs/selections';
import * as sessionStorage from 'libs/sessionStorage';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { AddCardDialog, DeleteDialog } from './Dialogs';
import InfoTransport from './InfoTransport';
import Options from './Options';
import ProposalList from 'components/UI/_V2/Proposals/List';
import dialogStyles from 'components/UI/Dialog/style.less';
import submitChangePrice from './submit';
import shortName from 'libs/shortName';
import { Link } from 'react-router-dom';
import { setItem } from 'libs/sessionStorage';
import { weightConversionShort } from 'libs/weightConversion';
import ProposalDialog from 'components/UI/_V2/Proposals/Dialog';
import ProposalOfferForm from 'components/ReduxForms/ProposalOfferForm';
import { chatActions } from 'actions/chat';
import DialogChat from 'components/Chat';
import Warning from 'components/UI/Warning';
import style from 'components/Profile/MyCargoDetail/style.less';

class MyCargoDetail extends React.PureComponent {

	state = {
		idForRemove: 0,
		openForm: false,
		addCard: false,
		selectDriver: false,
		user: {},
	};

	componentWillMount() {
		this.props.dispatch(customerAction.getCargoByID(this.props.match.params.id));
	}

	componentDidMount() {
		scrollToElement();
	}

	componentWillReceiveProps(nextProps, nextContext) {
		let { offer, profile } = nextProps;
		if (offer !== undefined && profile !== null)
			if (offer.proposalsNewCount > 0 && nextProps.profile.role === 'customer')
				offer.proposals.forEach(el => {
					this.props.dispatch(offersActions.readProposal(el.id));
				});
	}


	handleSubmitCard = formValues => {
		let values = { ...formValues };

		if (values.id === undefined) {
			const month = values.issueDate.slice(0, 2),
				year = 20 + values.issueDate.slice(3, 7);
			values.issueDate = new Date(month + '/01/' + year);
		} else {
			delete values.id;
			delete values.remove;
			delete values.title;
			values.issueDate = new Date(values.issueDate);
		}
		return submitCard(
			values,
			() => this.toggleDialogCardForm(false),
			this.props.dispatch
		);
	};

	handleRejectPersonal = id => {
		this.props.dispatch(offersActions.rejectProposal({
			proposal: id,
			offer: this.props.match.params.id,
		}));
	};

	setUserToDriver = offer => {
		let proposal = offer.proposals.filter(el => el.user1.id === this.state.user.id);
		let offerToTrade = { ...offer, transport: proposal[0].transport };
		setItem('offer', JSON.stringify(offerToTrade));
		setItem('executor', JSON.stringify(this.state.user));
		setItem('proposalId', proposal[0].id);
		setItem('price', proposal[0].price);
		forwardTo(`/trade/demo`);
	};

	removeCustomerRequest = () => {
		this.props.dispatch(rmCargoRequest(this.state.idForRemove));
		forwardTo('/offers/');
		this.handleDelete(0);
	};

	toggleCarForm = addCar => {
		this.setState({ addCar });
	};

	toggleDialogReqForm = openForm => this.setState({ openForm });
	handleDelete = id => this.setState({ idForRemove: id });
	toggleDialogCardForm = addCard => this.setState({ addCard });
	handleEdit = cargoData => {
		sessionStorage.setItem('offerForm', JSON.stringify(cargoData));
		forwardTo('/edit/offer/');
	};

	handleSubmitDriverOffer = values => {
		return submitDriverOffer(values, this.props.dispatch, () => {
			this.props.dispatch(customerAction.getCargoByID(this.props.match.params.id));
			this.toggleDialogReqForm(false);
		});
	};

	handleCreateCar = values => {
		return setCar(values, this.props.dispatch, () => this.toggleCarForm(false));
	};

	handleEditPrice = values => submitChangePrice(this.props.offer, values.price, this.props.dispatch);

	toggleSelectDriverDialog = (selectDriver, user) => this.setState({ selectDriver, user });

	toggleChat = (chat = true, user1, user2) => {
		if (chat) this.props.dispatch(chatActions.openDialog({user1, user2}));
		this.setState({ chat });
	};

	render() {
		let { offer, profile } = this.props;
		let { user } = this.state;
		if (profile === null) return null;
		if (offer === undefined || offer.cargo === undefined)
			return <Page title={'Заявка №' + this.props.match.params.id}><Loader/></Page>;
		
		let initialValuesProposal = {
			offerId: offer.id,
			user2Id: offer.userId,
			price: offer.price,
		};

		if (this.props.cars.length === 1) {
			initialValuesProposal.transportId = this.props.cars[0].id;
		}
		
		if (this.props.cards.total === 1) {
			initialValuesProposal.cardPayMethod = true;
			initialValuesProposal.payMethodId = this.props.cards.items[0].id;
		}

		const volume = { height: offer.cargo.height, width: offer.cargo.width, length: offer.cargo.length },
			name =
				<React.Fragment>{ offer.cargo.categoryId && `${offer.cargo.categoryId.titleRu}, ` }{weightConversionShort(offer.cargo.weight)}, {getVolume(volume)}м<sup>3</sup></React.Fragment>,
			proposals = offer.proposals.length;
		return (
			<Page
				title={name}
				text={`Заявка №${this.props.match.params.id} от ${dateToString(stringToDate(offer.createTime), '')}г`}
				history
			>
				{
					profile.role === 'driver' && offer.status === 'moderation' &&
					<Warning className={style.warning} type={'warning'}>
						<p>Заявка находится на модерации</p>
					</Warning>
				}
				{
					profile.role === 'driver' && offer.status !== 'moderation' && offer.status !== 'published' &&
					<Warning className={style.warning} type={'warning'}>
						<p>Заявка не активна и находится в архиве</p>
					</Warning>
				}

				<Grid col="9-3">
					<div>
						<InfoPointA route={offer.route.items[0]}/>
						<InfoPointOther route={offer.route.items.slice(1)}/>
						<InfoCargo cargo={offer.cargo}/>

						<InfoTransport
							bodyTypeId={offer.bodyTypeId}
							loadTypeId={offer.loadTypeId}
						/>

						<ProposalList
							{ ...offer }
							type={'offer'}
							openProposalDialog={() => this.toggleDialogReqForm(true)}
							acceptProposal={user => this.toggleSelectDriverDialog(true, user)}
							openChat={(user1, user2) => this.toggleChat(true, user1, user2)}
						/>
					</div>
					<div>
						{offer.userId === profile.id &&
						<Options
							proposals={proposals}
							handleEditPrice={this.handleEditPrice}
							handleEdit={() => this.handleEdit(offer)}
							offer={offer}
							handleDelete={() => this.handleDelete(offer.id)}
						/>
						}
						<Maps/>
					</div>
				</Grid>

				{
					profile.role === 'driver' && this.state.openForm &&
					<ProposalDialog close={() => this.toggleDialogReqForm(false)}>
						<ProposalOfferForm
							initialValues={initialValuesProposal}
							onSubmit={this.handleSubmitDriverOffer}
							addCard={() => this.toggleDialogCardForm(true)}
							setCard={this.setCardPayMethod}
							cards={this.props.cards}
							cars={this.props.cars}
						/>
					</ProposalDialog>
				}

				{this.state.idForRemove !== 0 &&
				<DeleteDialog cancel={() => this.handleDelete(0)} remove={this.removeCustomerRequest}/>
				}
				{(this.state.addCard && profile.role === 'driver') &&
				<AddCardDialog onSubmit={this.handleSubmitCard} cancel={() => this.toggleDialogCardForm(false)}/>
				}
				{
					this.state.selectDriver &&
					<Dialog handleClose={() => this.toggleSelectDriverDialog(false)}>
						<h3 className={dialogStyles.textcenter}>
							Выбрать пользователя <Link to={`/profile/${user.id}`}>
							{shortName(user.lastName, user.firstName)}</Link> исполнителем вашей заявки?
						</h3>
						<div className={dialogStyles.actions}>
							<Button
								color={'green'}
								onClick={() => this.setUserToDriver(offer, user)}>
								Выбрать
							</Button>
							<Button
								color={'white'}
								onClick={() => this.toggleSelectDriverDialog(false, {})}>
								Отменить
							</Button>
						</div>
					</Dialog>
				}
				{
					this.state.chat &&
					<DialogChat handleClose={() => this.toggleChat(false)} />
				}
			</Page>
		);
	}
}


export default connect(
	store => ({
		profile: getProfile(store),
		offer: getDetailOffer(store),
		cards: getDriverCarts(store),
		cars: getMyCars(store),
	})
)(MyCargoDetail);
