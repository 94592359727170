import React from 'react';
import PropTypes from 'prop-types';
import style from 'components/UI/_V2/Loader/style.less';


const Loader = props => (
	<div style={props.style} className={`${style.loader_container} ${style[props.size]} ${style[`count-${props.count}`]} ${props.className && props.className}`}>
		<div className={style.row_1}>
			<div className={`${style.placeholder} ${style.placeholder_1}`}>
				<div className={style.animated} />
			</div>
			
			<div className={`${style.placeholder} ${style.placeholder_2}`}>
				<div className={style.animated} />
			</div>
			
			<div className={`${style.placeholder} ${style.placeholder_3}`}>
				<div className={style.animated} />
			</div>
		</div>
	</div>
);


Loader.propTypes = {
	className: PropTypes.string,
	size: PropTypes.string,
	count: PropTypes.number,
	style: PropTypes.object,
};

Loader.defaultProps = {
	className: '',
	size: 'md',
	count: 3,
	style: {}
};

export default Loader;