import React from 'react';
import style from 'components/UI/_V2/Proposals/Detail/style.less';
import PropTypes from 'prop-types';
import shortName from 'libs/shortName';
import { Link } from 'react-router-dom';

const ProposalUsername = ({ id, firstName, lastName, isProfileLink, isYou }) => {
	
	return (
		<div className={style.proposal__username}>
			
			{
				isProfileLink &&
				<Link to={`/profile/${id}`}>
					{ shortName(firstName, lastName) }
				</Link>
			}

			{
				isYou && 'Вы: '
			}

			{
				!isProfileLink &&
				shortName(firstName, lastName)
			}
			
		</div>
	);
};

ProposalUsername.propTypes = {
	className: PropTypes.string,
	isProfileLink: PropTypes.bool,
	isYou: PropTypes.bool,
};

ProposalUsername.defaultProps = {
	className: '',
	isProfileLink: false,
	isYou: false,
};

export default ProposalUsername;