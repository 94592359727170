const getTimeInterval = interval => {
	switch (interval) {
		case '06:00-09:00':
			return '06:00 — 09:00';
		case '09:00-12:00':
			return '09:00 — 12:00';
		case '12:00-15:00':
			return '12:00 — 15:00';
		case '15:00-18:00':
			return '15:00 — 18:00';
		case '18:00-21:00':
			return '18:00 — 21:00';
		case '21:00-23:00':
			return '21:00 — 23:00';
		default:
			return 'В любое время';
	}
};

export default getTimeInterval;

