import React from 'react';
import Grid from 'components/UI/Wrappers/Grid';
import Panel from 'components/UI/Wrappers/Panel';
import ProfileContactDataForm from 'components/ReduxForms/ProfileContactDataForm';
import ProfilePassportForm from 'components/ReduxForms/ProfilePassportForm';
import UpdatePassword from 'components/ReduxForms/UpdatePassword';
import connect from 'react-redux/es/connect/connect';
import { getProfile } from 'libs/selections';
import style from './style.less';
import { saveContactData, savePhoto, savePassport, submitUpdatePassword } from './submit';
import DocumentRequired from 'components/Contents/DocumentRequired';
import {stringToDate} from 'libs/convertDate';
import { reset } from 'redux-form';
import UploadAvatarForm from 'components/ReduxForms/UploadAvatarForm';
import UserCardList from 'components/Profile/EditProfile/UserCardList';

class CustomerForm extends React.PureComponent {

	handleSaveContactData = values => {
		if (values.photoId) values.photoId = values.photoId.id;
		return saveContactData(values, this.props.profile.role, this.props.dispatch);
	};

	handleSavePhoto = photo => {
		return savePhoto({ photoId: photo.images }, this.props.dispatch);
	};

	handleSavePassport = values => {
		return savePassport({...values}, this.props.profile.id, this.props.dispatch);
	};

	handleChangePassword = values => {
		return submitUpdatePassword(values, this.props.dispatch, () => {
			this.props.dispatch(reset('updatePassword'));
		});
	};

	render() {
		const { profile } = this.props;
		const { passport } = profile;
		let passportInit = {};
		if (passport) {
			passportInit = {
				...passport,
				issueDate: stringToDate(passport.issueDate, 'yyyy-MM-dd', '-'),
				scanIds: passport.scans
			};
		}

		let profileInitialValues = {
			...profile,
			photoId: profile.driver.photo,
			phoneCode: profile.phone[0],
			phone_number: profile.phone.slice(1),
			additionalPhone_code: profile.additionalPhone ? profile.additionalPhone[0] : '7',
			additionalPhone_number: profile.additionalPhone ? profile.additionalPhone.slice(1) : '',
		};

		return (
			<Grid className={style.formEditProfileGrid}>
				<Panel className={style.panelOne} title="Контактные данные">
					<ProfileContactDataForm
						onSubmit={this.handleSaveContactData}
						initialValues={profileInitialValues}
					/>
				</Panel>
				<UploadAvatarForm
					className={`${style.panelFour} ${style.panelFourCustomer}`}
					onChangePhoto={photo => this.handleSavePhoto(photo)}
					initialValues={{ photoId: profile.driver.photo }}
				/>
				<Panel className={style.panelTwo} title="Данные паспорта">
					<ProfilePassportForm
						onSubmit={this.handleSavePassport}
						initialValues={passportInit}
						isCustomerData
					/>
				</Panel>
				<DocumentRequired />
				<UserCardList className={style.panelThree} />
				<Panel className={style.panelFive} title="Смена пароля">
					<UpdatePassword onSubmit={this.handleChangePassword} />
				</Panel>
			</Grid>
		);
	}
}

export default connect(
	store => ({
		profile: getProfile(store),
	})
)(CustomerForm);
