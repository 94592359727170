import React from 'react';
import MainWhiteContainer from 'components/UI/Wrappers/MainWhiteContainer';
import Container from 'components/UI/Wrappers/Container';
import { Apple, GooglePlay } from 'components/UI/Icons';
import style from '../style.less';
import phone from 'components/MainPage/BeginNowScreen/phone_static.png';
// import MobilePhoneAnimation from './MobilePhoneAnimation';

const BeginNowScreen = () => {

	return (
		<div className={style.begin__screen}>
			<MainWhiteContainer className={style.container}>
				<Container className={style.containerforImg}>
					<h3 className={style.section_title}>HurryLorry — приложение для комфортной работы водителей</h3>
					<p>Работайте столько же, а получайте в разы больше: без посредников, оптимальные маршруты, полная загрузка
						«туда-обратно»: попутные грузы, догруз, консолидация.</p>
					<div className={style['download-app']}>
						<a href="https://apps.apple.com/us/app/hurrylorry/id1487473412?l=ru&ls=1" target="_blank" rel="noopener noreferrer" className={`${style['app-link']} ${style.appstore}`}>
							<div className={style.icon}>{ Apple() }</div>
							Скачать<br /> для IOS
						</a>

						<a href="https://play.google.com/store/apps/details?id=com.logistmobileapp" target="_blank" rel="noopener noreferrer" className={`${style['app-link']} ${style.googlePlay}`}>
							<div className={style.icon}>{ GooglePlay() }</div>
							Скачать<br /> для Android
						</a>
					</div>
					<div className={style.phone_img}>
						<img src={phone} alt=""/>
					</div>
					{/*<div className={style.img}>*/}
						{/*<MobilePhoneAnimation />*/}
					{/*</div>*/}
				</Container>
			</MainWhiteContainer>
		</div>
	);
};

export default BeginNowScreen;
