import React from 'react';

class PayAccessForm extends React.Component {
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const { acsUrl, pareq, ternUrl, transactionId } = this.props;
		if (transactionId !== '' && acsUrl !== '' && pareq !== '' && ternUrl !== '')
			document.getElementById("submit").click();
	}
	
	render() {
		const { acsUrl, pareq, ternUrl, transactionId } = this.props;
		return (
			<form style={{ display: 'none' }} action={acsUrl} id={'form'} name="downloadForm" method="POST">
				<input name="PaReq" value={pareq} />
				<input name="TermUrl" value={ternUrl} />
				<input name="MD" value={transactionId} />
				<input type="submit" id={'submit'} />
			</form>
		);
	}
}

export default PayAccessForm;