import {call, put} from 'redux-saga/effects';
import request from 'libs/request';
import {eventsActions} from 'actions/events';

export function *getEvents() {
	const response = yield call(()=>
		request('get', '/api/1.0/notifications',  {isRead: 0}));

	if(response.data.errors || response.status !== 200){
		console.error('Error getEvents: ', response.data.errors);
	}else{
		yield put(eventsActions.setEvents(response.data.items));
	}
}

export function *readEventById(action) {
	const response = yield call(()=>
		request('post', '/api/1.0/notifications/mark-read', {
			notificationId: action.payload
		}));

	if(response.data.errors || response.status !== 200){
		console.error('Error readEventById: ', response.data.errors);
	}else{
		yield put(eventsActions.removeEventByIdFromClient(action.payload));
	}
}