import React from 'react';
import PropTypes from 'prop-types';
import svg from './info.svg';
import style from './Notification.less';
import Button from '../Form/Button';
import { Close } from '../Icons';

/*
* type notifications:
* success
* info
* warning
* error
* */
export default class Notification extends React.PureComponent {

	static propTypes = {
		text: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.array,
		]),
		type: PropTypes.string,
		onClick: PropTypes.func,
	};

	static defaultProps = {
		type: 'error',
		text: '',
		onClick: () => {
		},
	};

	state = {
		open: false,
	};

	componentDidMount() {
		setTimeout(() => this.setState({ open: true }), 100);
	}

	render() {
		const { open } = this.state;
		const { type, text } = this.props;

		let classOpen = open ? style.open : '';
		let classType = type ? style[type] : '';
		const notification = `${style.notification} ${classOpen} ${classType}`;

		return (
			<div className={notification}>

				<div className={style.infoBlock}>
					<img src={svg} alt="" />
				</div>


					<div className={style.message}>
						{/*<span>{ text ? text.toString() : 'Уведомление' }</span>*/}
						<span>
							{
								Array.isArray(text) ? text.map((el, i) => {
									return <React.Fragment key={i}>{ el }</React.Fragment>
								}) : text
							}
						</span>
						<Button size="small" className={style.closeButton} onClick={this.props.onClick}>
							{ Close(style.close) }
						</Button>
					</div>


			</div>
		);
	}
}