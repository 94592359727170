import React, { PureComponent } from 'react';
import style from 'components/MainPage/style.less';
import styles from 'components/HelpfulInformations/style.less';
import src from 'components/HelpfulInformations/assets/phone-icon.svg';
import Button from 'components/UI/Form/Button';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


class AsideSupport extends PureComponent {

	static propTypes = {
		isClient: PropTypes.bool,
		isDriver: PropTypes.bool,
		left: PropTypes.bool,
	};

	static defaultProps = {
		isClient: false,
		isDriver: false,
		left: false,
	};



	render() {

		const { isClient, isDriver, left } = this.props;

		return (
			<div className={`${left ? style.support_left : style.support} ${styles.support_white}`}>
				<div className={style.icon}><img src={src} alt=""/></div>
				<div>
					{
						!isDriver && !isClient &&
						<>
							<h4 style={{ fontSize: '21px', fontWeight: 'bold' }}>
								Служба поддержки клиентов
							</h4>
							<p>
								Вы можете обратиться за помощью через приложение или с помощью формы.
							</p>
						</>
					}

					{
						isDriver &&
						<>
							<h4 style={{ fontSize: '21px', fontWeight: 'bold' }}>
								Служба поддержки водителей
							</h4>
							<p>
								Вы можете обратиться за помощью через приложение или с помощью формы.
							</p>
						</>
					}

					{
						isClient &&
						<>
							<h4 style={{ fontSize: '21px', fontWeight: 'bold' }}>
								Служба поддержки клиентов
							</h4>
							<p>
								Вы можете обратиться за помощью через приложение или с помощью формы.
							</p>
						</>
					}

					<Link to={'/feedback/'}>
						<Button>Задать вопрос</Button>
					</Link>
				</div>
			</div>
		);
	}
}


export default AsideSupport;
