import React from 'react';
import Grid from 'components/UI/Wrappers/Grid';
import QuestionsList from 'components/UI/Questions';
import QuestionList from 'components/Questions/list';
import MainWhiteContainer from "components/UI/Wrappers/MainWhiteContainer";
import styles from "components/HelpfulInformations/style.less";
import Navigation from "components/HelpfulInformations/common/navigation";
import MainScreen from "components/HelpfulInformations/common/main";
import bg from 'components/HelpfulInformations/assets/bg_questions.svg';
import AsideSupport from 'components/HelpfulInformations/common/AsideSupport';
import Container from 'components/UI/Wrappers/Container';

class Questions extends React.Component {

  state = {
    listAboutHurryLorry: QuestionList('aboutHurryLorry'),
    listGeneral: QuestionList('general'),
    listChat: QuestionList('chat'),
    listSituations: QuestionList('situations'),
    listSecurity: QuestionList('security'),
    listCustomer: QuestionList('customer'),
    listDriver: QuestionList('driver'),
  };

  render() {

    return (
      <MainScreen title={'Частые вопросы'}
                  subtitle={'Здесь вы найдете ответы на самые популярные вопросы о работе сервиса, создании и ведении аккаунта и сделки без риска'}
        bg={bg}
      >
        <MainWhiteContainer className={`${styles.advantage__screen} ${styles.not_padding}`}>
          <Navigation isActive={2}/>
          <div className={styles.padding}>
            <Container>
              <Grid col="9-3">
                <div className={styles.container_questions}>
                  <h3>Описание HurryLorry</h3>
                  <QuestionsList viewType={2} list={this.state.listAboutHurryLorry}/>
                  <h3 style={{ marginTop: '48px' }}>Общие вопросы</h3>
                  <QuestionsList viewType={2} list={this.state.listGeneral}/>
                  <h3 style={{ marginTop: '48px' }}>Общение на сайте</h3>
                  <QuestionsList viewType={2} list={this.state.listChat}/>
                  <h3 style={{ marginTop: '48px' }}>Спорные ситуации</h3>
                  <QuestionsList viewType={2} list={this.state.listSituations}/>
                  <h3 style={{ marginTop: '48px' }}>Безопасность</h3>
                  <QuestionsList viewType={2} list={this.state.listSecurity}/>
                  <h3 style={{ marginTop: '48px' }}>Для Отправителей (заказчиков)</h3>
                  <QuestionsList viewType={2} list={this.state.listCustomer}/>
                  <h3 style={{ marginTop: '48px' }}>Для Перевозчиков (исполнителей)</h3>
                  <QuestionsList viewType={2} list={this.state.listDriver}/>
                </div>
                <div>
                  <AsideSupport left />
                </div>
              </Grid>
            </Container>
          </div>
        </MainWhiteContainer>
      </MainScreen>
    )
  }
}

export default Questions;
