exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._17UodziY_018hPdf0gs1mF {\n  display: flex;\n  font-size: 14px;\n  margin: 5px 0;\n}\n._17UodziY_018hPdf0gs1mF > div {\n  flex: 0 0 50%;\n}\n._3l-FK0FuD29wsP9hgN36mk {\n  position: relative;\n}\n._3l-FK0FuD29wsP9hgN36mk:before {\n  content: '';\n  position: absolute;\n  height: 1px;\n  bottom: 1px;\n  left: 0;\n  right: 0;\n  background: #f0f4f9;\n}\n._3l-FK0FuD29wsP9hgN36mk span {\n  background: #fff;\n  position: relative;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/Admin/UI/Label/style.less"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,cAAc;CACf;AACD;EACE,cAAc;CACf;AACD;EACE,mBAAmB;CACpB;AACD;EACE,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,QAAQ;EACR,SAAS;EACT,oBAAoB;CACrB;AACD;EACE,iBAAiB;EACjB,mBAAmB;CACpB","file":"style.less","sourcesContent":[".label {\n  display: flex;\n  font-size: 14px;\n  margin: 5px 0;\n}\n.label > div {\n  flex: 0 0 50%;\n}\n.title {\n  position: relative;\n}\n.title:before {\n  content: '';\n  position: absolute;\n  height: 1px;\n  bottom: 1px;\n  left: 0;\n  right: 0;\n  background: #f0f4f9;\n}\n.title span {\n  background: #fff;\n  position: relative;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"label": "_17UodziY_018hPdf0gs1mF",
	"title": "_3l-FK0FuD29wsP9hgN36mk"
};