import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';

const MainWhiteContainer = props => (
	<div className={`${style.main_white__container} ${props.className}`}>{ props.children }</div>
);

MainWhiteContainer.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};
MainWhiteContainer.defaultProps = {
	children: '',
	className: '',
};

export default MainWhiteContainer;
