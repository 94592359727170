import React from 'react';
import style from 'components/Admin/UI/Menu/style.less';
import Container from 'components/UI/Wrappers/Container';
import Sticky from 'react-sticky-fill';
import AdminNav from 'components/Admin/UI/Menu/Nav';


const AdminMenu = () => (
	<Sticky style={{ zIndex: '99' }}>
		<div className={style.menu}>
			<Container className={style.container}>
				<AdminNav />
			</Container>
		</div>
	</Sticky>
);


export default AdminMenu;