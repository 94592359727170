import React  from 'react';
import style from 'components/Chat/style.less';
import PropTypes from 'prop-types';


const UserCounter = ({ role, length, isFilter }) => (
	<div className={style.user_counter}>
		{ isFilter ? 'Найдено' : role === 'driver' ? 'Клиентов' : 'Перевозчиков' } { length }
	</div>
);


UserCounter.propTypes = {
	role: PropTypes.string.isRequired,
	length: PropTypes.number,
	isFilter: PropTypes.bool,
};

UserCounter.defaultProps = {
	length: 0,
	isFilter: false,
};


export default UserCounter;