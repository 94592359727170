import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';

const Tooltip = ({ text, className }) => (
	<div className={`${style.tooltip} ${className ? className : ''}`}>{ text }</div>
);

Tooltip.propTypes = {
	text: PropTypes.string.isRequired,
	className: PropTypes.string,
};
Tooltip.defaultProps = {
	className: '',
};

export default Tooltip;
