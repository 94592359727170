import React, { Component } from 'react';
import { connect } from 'react-redux';
import style from 'components/ReduxForms/AddCargoForm/style.less';
import Panel from 'components/UI/Wrappers/Panel';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import Point from 'components/UI/Point';
import Address2 from 'components/UI/Form/Fields/Address2';
import InputSelectGroup from 'components/UI/Wrappers/InputSelectGroup';
import { change, Field, formValueSelector } from 'redux-form';
import Grid from 'components/UI/Wrappers/Grid';
import InputDate from 'components/UI/Form/Fields/InputDate';
import Select from 'components/UI/Form/Fields/Select';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import Input from 'components/UI/Form/Fields/Input';
import { loadDate, isToday } from 'libs/validationsForms';
import { add } from 'actions/notifications';
import { rmPointByKey } from 'actions/map';
import { getPoints, getProfile, getProfileType } from 'libs/selections';
import Checkbox from 'components/UI/Form/Fields/Checkbox';
import Button from 'components/UI/Form/Button';
import { AddSong } from 'components/UI/Icons';
import NativeSelect from 'components/UI/_V2/Select';


class OfferLocations extends Component {

	ABC = 'ABCDEF';

	setValue = (field, value) => {
		this.props.dispatch(change('offerForm', field, value));
	};

	handleAddPoint = index => {
		const key = this.ABC.charAt(index);
		const fields = this.props.fields.getAll();

		if (fields.filter(el => el.key === key).length > 0) {
			return this.handleAddPoint(++index);
		}

		if (key) {
			this.props.fields.push({ key });
		} else {
			this.props.dispatch(add({
				text: 'Добавлено максимальное колличество точек выгрузки',
				type: 'info',
				time: 5,
			}));
		}
	};

	handleRemovePoint = index => {
		this.props.dispatch(rmPointByKey(this.ABC.charAt(index)));
		this.props.fields.remove(index);
	};

	setMyData = (e,index) => {
		const { profile } = this.props;
		const name = `route.items[${index}].providerName`;
		const phone = `route.items[${index}].providerPhone`;

		if (e) {
			this.props.dispatch(change('offerForm', name, `${profile.firstName} ${profile.lastName}`));
			this.props.dispatch(change('offerForm', phone, profile.phone.slice(1)));
		} else {
			this.props.dispatch(change('offerForm', name, ''));
			this.props.dispatch(change('offerForm', phone, ''));
		}
	};

	render () {

		const { fields, dispatch, role, points } = this.props;

		const text = [['загрузке','Дата загрузки','загрузки'], ['разгрузке','Доставить до','разгрузки']];
		const textProvider = ['Грузоотправитель', 'Грузополучатель'];
		const textProvider2 = ['отправителем', 'получателем'];

		const time = [
			{ value: '00:00-00:00', label: 'В любое время' },
			{ value: '06:00-09:00', label: '06:00 — 09:00' },
			{ value: '09:00-12:00', label: '09:00 — 12:00' },
			{ value: '12:00-15:00', label: '12:00 — 15:00' },
			{ value: '15:00-18:00', label: '15:00 — 18:00' },
			{ value: '18:00-21:00', label: '18:00 — 21:00' },
		];

		return (
			fields.map((location, index) => {

				const field = fields.get(index);
				let choise = 0;

				field.key = field.key ? field.key : field.address ? field.address.key : this.ABC[index];

				if (field.key !== 'A') choise = 1;

				return (
					<Panel
						key={field.key}
						title={`Информация о ${text[choise][0]}`}
						className={style.afterPanel}
						marginBottom={30}
					>
						<LayoutField>
							<span>Укажите адрес {text[choise][2]}, используя поиск по буквам</span>
							<InputSelectGroup>
								<Point point={field.key} className={style.pointLine} absolute />
								<Address2
									setValue={this.setValue}
									dispatch={dispatch}
									renderToMap={field.key}
									name={`${location}.address`}
									label="Город, населёный пункт, улица, дом"
								/>
							</InputSelectGroup>
						</LayoutField>
						<Grid col="form-2">
							<LayoutField>
								<Field
									name={`${location}.startDate`}
									component={InputDate}
									validate={field.key === 'A' ? isToday : loadDate}
									label={text[choise][1]}
								/>
							</LayoutField>
							<LayoutField>
								<Field
									component={NativeSelect}
									name={`${location}.timeIntervalLoad`}
									label={`Время ${text[choise][2]}`}
									options={time}
								/>
							</LayoutField>
						</Grid>
						<Grid col="form-2">
							<LayoutField>
								<Field
									type="text"
									name={`${location}.providerName`}
									component={Input}
									placeholder={'Фамилия Имя Отчество'}
									label={textProvider[choise]}
								/>
							</LayoutField>
							<LayoutField>
								<InputSelectGroup>
									<Field
										name={`${location}.providerPhone`}
										type="text"
										component={Input}
										label="Телефон"
										mask="999 999 99 99"
										containerGroup="left"
									/>
									<Field
										name={`${location}.phone_code`}
										component={Select}
										childGroup="left"
									>
										<OptionSelect value='7'>+7</OptionSelect>
										<OptionSelect value='375'>+375</OptionSelect>
										<OptionSelect value='98'>+98</OptionSelect>
									</Field>
								</InputSelectGroup>
							</LayoutField>
						</Grid>
						{
							role !== 'guest' && !this.props.isOfferID &&
							<Field
								name={`${location}.iam`}
								component={Checkbox}
								label={`Я являюсь ${textProvider2[choise]} груза`}
								onCustomChange={e => this.setMyData(e, index)}
							/>
						}
						{
							fields.length > 2 && index > 1 &&
							<Button color="remove" className={style.buttonDelete} onClick={() => this.handleRemovePoint(index)}>
								Удалить
							</Button>
						}
						{
							fields.length === index + 1 && fields.length < 6 &&
							<div style={role !== 'guest' ? { marginTop: '25px' } : {}}>
								<Button
									onClick={() => this.handleAddPoint(points.length)}
									color="white"
									size="large"
									className={style.addNewAddress}
								>
									{ AddSong() }
									Добавить ещё один адрес
								</Button>
							</div>
						}
					</Panel>
				)
			})
		);
	}
}

const selector = formValueSelector('offerForm');

export default connect(
	state => ({
		profile: getProfile(state),
		role: getProfileType(state),
		points: getPoints(state),
		route: selector(state, 'route'),
	})
)(OfferLocations);
