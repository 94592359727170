import { driverActions } from 'actions/driver';
import RequestLayer from 'components/UI/Request/RequestLayer';
import { getMyRoutes } from 'libs/selections';
import { getItem, removeItem, setItem } from 'libs/sessionStorage';
import React from 'react';
import { connect } from 'react-redux';
import { TabPanel } from 'react-tabs';
import RouteList from './RouteList';


class Routes extends React.Component {
    state = {
        openForm: false,
        selectedTab: 0,
        activePage: {
            active: getItem('route.active', 1),
            cancelled: getItem('route.cancelled', 1),
            unpublished: getItem('route.unpublished', 1)
        },
        list: [
            {
                question: 'Как скоро начнут появляться предложения?',
                answer: 'Сразу же после создания вашего маршрута, он становиться доступным для грузоотправителей. Предложение по маршруту вы можете получить в течении нескольких минут, если кому то из заказчиков подойдут время, маршрут и параметры транспорта.',
                open: false
            },
            {
                question: 'Как увеличить количество просмотров заявок?',
                answer: 'Наш Сервис предлагает равные конкурентные условия для всех пользователей. Популярность вашего маршрута будет определяться только его качеством и степенью соответствия запросам грузоотправителей. Чтобы увеличить количество просмотра ваших маршрутов, изучите опыт других пользователей, предложите конкурентную стоимость и добавьте хороший сопроводительный текст.',
                open: false
            },
            {
                question: 'Как разблокировать мою заявку?',
                answer: 'Если по какой-либо причине, ваша заявка была заблокирована, обратитесь в службу поддержки или в службу «Помощь 24/7». При этом укажите обстоятельства, при каких вы обнаружили блокировку. Специалисты HurryLorry, разберутся в проблеме и решат ее, или сообщат причину блокировки и опишут ваши дальнейшие действия.',
                open: false
            },
        ],
    };

    handleSelect = selectedTab => this.setState({ selectedTab });

    changePage = (page, type) => {
        const { selectedTab, activePage } = this.state,
            activeType = selectedTab === 0 ? 'active' : selectedTab === 1 ? 'cancelled' : 'unpublished';

        setItem(`route.${activeType}`, page);

        this.props.dispatch(driverActions.getRoutes({ page, status: activeType }));
        this.setState({
            activePage: {
                ...activePage,
                [activeType]: page
            }
        })
    };

    componentWillMount() {
        removeItem('searchParams');
    }

    componentDidMount() {
        this.props.dispatch(driverActions.getRoutes({ status: 'active', page: this.state.activePage.active }));
        this.props.dispatch(driverActions.getRoutes({ status: 'cancelled', page: this.state.activePage.cancelled }));
        this.props.dispatch(driverActions.getRoutes({
            status: 'unpublished',
            page: this.state.activePage.unpublished
        }));
    }

    render() {
        const { routes } = this.props,
            { activePage: { active, cancelled, unpublished } } = this.state,
            activeCount = routes.active.total,
            cancelledCount = routes.cancelled.total,
            unpublishedCount = routes.unpublished.total,
            requestType = {
                length: activeCount + cancelledCount + unpublishedCount,
                word: ['маршрут', 'маршрута', 'маршрутов']
            },
            tabs = [
                { count: activeCount, title: 'Активные' },
                { count: cancelledCount, title: 'Отклоненные' },
                { count: unpublishedCount, title: 'Завершенные' },
            ];

        return (
            <RequestLayer
                title={'Мои маршруты'}
                tabList={tabs}
                selectedTab={this.state.selectedTab}
                onClickTab={i => this.handleSelect(i)}
                requestType={requestType}
                questions={this.state.list}
            >
                <TabPanel>
                    <RouteList
                        type={'active'}
                        activePage={active}
                        changePage={this.changePage}
                        count={activeCount}
                        loading={routes.active.loading}
                    />
                </TabPanel>
                <TabPanel>
                    <RouteList
                        type={'cancelled'}
                        activePage={cancelled}
                        changePage={this.changePage}
                        count={cancelledCount}
                        loading={routes.cancelled.loading}
                    />
                </TabPanel>
                <TabPanel>
                    <RouteList
                        type={'unpublished'}
                        activePage={unpublished}
                        changePage={this.changePage}
                        count={unpublishedCount}
                        loading={routes.unpublished.loading}
                    />
                </TabPanel>
            </RequestLayer>
        );
    }
}


export default connect(store => ({
    routes: getMyRoutes(store)
}))(Routes);