import * as adminActions from "actions/admin";
import LeftMenu from 'components/Admin/Pages/Offers/Detail/LeftMenu';
import AdminContainer from 'components/Admin/UI/Container';
import AdminGrid from 'components/Admin/UI/Grid';
import NotFound from 'components/Admin/UI/List/NotFound';
import listStyle from 'components/Admin/UI/List/style.less';
import AdminLoader from 'components/Admin/UI/Loader';
import AdminPage from 'components/Admin/UI/Page';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from "components/UI/Form/Button/index";
import RouteDataOffer from 'components/Admin/Pages/Offers/Detail/RouteDataOffer';
import style from 'components/Admin/Pages/Routes/Detail/style.less';
import PersonalData from 'components/Admin/Pages/Drivers/Detail/PersonalData';
import CargoData from 'components/Admin/Pages/Offers/Detail/CargoData';
import GeneralData from 'components/Admin/Pages/Offers/Detail/GeneralData';
import TransportDataOffer from 'components/Admin/Pages/Offers/Detail/TransportDataOffer';
import ModerationCancelledForm from 'components/ReduxForms/ModerationCancelledForm';


class AdminOfferDetail extends Component {
	
	componentWillMount() {
		this.props.dispatch(adminActions.getOfferByID(this.props.match.params.id));
	}
	
	setOfferStatus = status => {
		this.props.dispatch(adminActions.setOfferStatus({ id: this.props.match.params.id, status, description: '' }))
	};
	
	setCancelledStatus = (values) => {
		this.props.dispatch(adminActions.setOfferStatus({ id: this.props.match.params.id, status: 'cancelled', description: values.description }))
	};
	
	render() {
		
		const { offer, offer: { loading } } = this.props;
		
		return (
			<AdminPage>
				<LeftMenu/>
				<div>
					<AdminGrid type="mainContent">
						<h1>Заявка { offer.id && `№${offer.id}` }</h1>
						<AdminContainer>
							
							{
								loading &&
								<AdminLoader className={listStyle.loading}/>
							}
							
							{
								!loading && offer &&
								<>
								<GeneralData {...offer} />
								<RouteDataOffer {...offer.route} />
								<CargoData {...offer.cargo} />
								<TransportDataOffer {...offer} />
								<PersonalData {...offer.route.user} />
								</>
							}
							
							<h3>Модерирование заявки</h3>
							{
								offer.status !== 'published' &&
								<>
									<p style={{ marginBottom: '-15px' }}>Если вас все устраивет, нажмите ниже</p>
									<div className={style.button_group}>
										<Button color="green" onClick={() => this.setOfferStatus('published')}>
											Опубликовать заявку
										</Button>
									</div>
								</>
							}
							{
								offer.status !== 'cancelled' &&
								<ModerationCancelledForm
										onSubmit={this.setCancelledStatus}
										buttonTxt={'Отклонить заявку'}
								/>
							}
							
							{
								!loading && !offer &&
								<NotFound
									style={{ marginBottom: 0, marginTop: 0 }}
									text={'Заявка не найдена'}
								/>
							}
						</AdminContainer>
					</AdminGrid>
				</div>
			</AdminPage>
		);
	}
}


export default connect(
	state => ({
		offer: state.admin.offers.detail,
	})
)(AdminOfferDetail);