import { removeBetweenPoint, rmPointByKey, setPointsToABC } from 'actions/map';
import dialogStyle from 'components/UI/Dialog/style.less';
import AddressGroup from 'components/UI/Form/Fields/AddressGroup';
import Point from "components/UI/Point/index";
import InputSelectGroup from "components/UI/Wrappers/InputSelectGroup";
import Panel from 'components/UI/Wrappers/Panel';
import PropTypes from 'prop-types';
import React from 'react';

export default class AddPoint extends React.PureComponent {

    static propTypes = {
        fields: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
    };

    panelPoints = null;

    state = {
        show: false,
        initialHeight: 237
    };

    toggleDialog = (status: boolean) => {
        this.setState({ show: status });
    };

    handleChangePoints = points => {
        let firstPoint = this.props.fields.get(0),
            endPoint = this.props.fields.get(this.props.fields.length - 1);

        this.props.fields.removeAll();
        points.forEach((item, index) => {
            let key = 'B' + (index + 1);
            this.props.fields.insert(index, { key: key, point: item });
        });
        this.props.fields.unshift(firstPoint);
        this.props.fields.push(endPoint);

        //надо обновить карту согластно вставленым пунктам
        if (points.length === 0)
            this.props.dispatch(removeBetweenPoint());
        else
            this.props.dispatch(setPointsToABC(points.map((item, index) => ({
                key: 'B' + (index + 1),
                pos: item.pos,
                label: item.name,
            }))));
    };

    handelDeletePoint = (index, key) => {
        this.props.fields.remove(index);
        this.props.dispatch(rmPointByKey(key));
    };

    setHeightPointLine = () => {
        setTimeout(() => {
            const line = document.querySelector('.pointLine');
            if (line) line.style.height = `${624 + parseInt(this.panelPoints.clientHeight - this.state.initialHeight)}px`;
        }, 50)
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.fields.length !== this.props.fields.length) {
            this.setHeightPointLine();
        }
    }

    componentDidMount() {
        this.setHeightPointLine();
    }

    render() {
        // console.log(this.props.fields.getAll());
        let points = this.props.fields.getAll();

           let selectedPoints = points ? points.filter((point, index) => {
                return !(index === 0 || index === points.length - 1)
            }) : [],
            address = selectedPoints.map(item => item.point);

        //Только если пункты А и В заполнены, можно добавить промежуточные
        // const disabledAdd = points.length === 2 && (!points[0].point || !points[1].point);

        return (
            <Panel customRef={ref => this.panelPoints = ref} title="Информация о разгрузке" marginBottom={30}>
                <span className={dialogStyle.gray_span}>Укажите попутные города, используя поиск по буквам </span>
                <InputSelectGroup>
                    <Point point="thirdPoint" absolute/>
                    <AddressGroup
                        input={{ onChange: this.handleChangePoints, value: address }}
                        label="Город, населенный пункт"
                    />
                </InputSelectGroup>
            </Panel>
        );
    }
}