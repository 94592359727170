import React from 'react';
import leftStyle from 'components/Admin/UI/LeftContent/style.less';
import AdminGrid from 'components/Admin/UI/Grid';
import LeftContent from 'components/Admin/UI/LeftContent';


const LeftMenu = props => {
	
	return (
		<div>
			<AdminGrid type="aside">
				<LeftContent title={'Водители'}>
					<ul className={leftStyle.list}>
						{
							props.list.map(el => {
								let className = `${leftStyle.item} ${leftStyle.item__lnk}`;
								if (el.isActive) className += ' ' + leftStyle.item__active;
								
								return (
									<li
										key={el.title}
										className={className}
										onClick={el.onClick}
									>
										{el.title}
										<span className={leftStyle.item__counter}>
											{el.count}{el.newCount !== '0' ? ` (+${el.newCount})` : ''}
										</span>
									</li>
								)
							})
						}
					</ul>
				</LeftContent>
			</AdminGrid>
		</div>
	)
};


export default LeftMenu;