import classesEmpty from 'components/UI/AdaptiveStyle/style.less';
import PreviewOffer from 'components/UI/Previews/PreviewOffer';
import RequestCostumer from 'components/UI/Request/RequestCustomer';
import RequestGridContainer from 'components/UI/Wrappers/RequestGridContainer';
import { getMyCargoes } from 'libs/selections';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Link from 'react-router-dom/es/Link';


class RequestList extends React.PureComponent {

    static propTypes = {
        type: PropTypes.string.isRequired,
    };

    render() {
        const { cargoes } = this.props,
            cargoList = cargoes[this.props.type] || { items: [], total: 0 };

        if (cargoList.total === 0) {
            if (this.props.type === 'active')
                return (
                    <div className={classesEmpty.emptyText}>
                        Активных заявок нет.
                        Вы можете <Link to="/new/offer">создать новую заявку</Link> на перевозку груза.
                    </div>
                );
            return <div className={classesEmpty.emptyText}>Заявок нет</div>;
        }

        return (
            <React.Fragment>
                {
                    cargoList.items.map(it => (
                        <RequestGridContainer key={it.id}>

                            <PreviewOffer offer={it}/>
                            <RequestCostumer
                                proposalsNewCount={it.proposalsNewCount ? it.proposalsNewCount : 0}
                                proposalsTotalCount={it.proposalsTotalCount ? it.proposalsTotalCount : 0}
                                offer={it}
                                route={it.route.items}
                                offerID={it.id}
                                proposals={it.proposals}
                                status={it.status || 'empty'}
                                price={it.price || 0}
                            />
                        </RequestGridContainer>
                    ))
                }
            </React.Fragment>
        )
    }
}


export default connect(
    store => ({
        cargoes: getMyCargoes(store)
    })
)(RequestList);
