exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3N6dHtwVkQy5BAJvfTb5Sc {\n  margin-top: 40px;\n}\n.RMQeru2Z3H1EoQdPl6Gpo {\n  margin-right: 70px;\n}\n@media screen and (max-width: 575px) {\n  .RMQeru2Z3H1EoQdPl6Gpo {\n    margin-right: 0;\n  }\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/ReduxForms/DriverLicenseForm/style.less"],"names":[],"mappings":"AAAA;EACE,iBAAiB;CAClB;AACD;EACE,mBAAmB;CACpB;AACD;EACE;IACE,gBAAgB;GACjB;CACF","file":"style.less","sourcesContent":[".button {\n  margin-top: 40px;\n}\n.uploadImageProfile {\n  margin-right: 70px;\n}\n@media screen and (max-width: 575px) {\n  .uploadImageProfile {\n    margin-right: 0;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"button": "_3N6dHtwVkQy5BAJvfTb5Sc",
	"uploadImageProfile": "RMQeru2Z3H1EoQdPl6Gpo"
};