import React from 'react';
import { Link } from 'react-router-dom';
import LogoSrc from './logo.png';
import style from '../style.less';
import mc from 'components/UI/Footer/ColumnContent/mastercard.svg';
import visa from 'components/UI/Footer/ColumnContent/visa-pay-logo.svg';
import mir from 'components/UI/Footer/ColumnContent/mir-logo.svg';

const Company = () => (
	<div className={style.company}>
		<Link to="/" className={style.logo}><img src={LogoSrc} alt=""/></Link>

		<div className={style.requisites}>ООО "Харри Ларри"</div>
		<div>ИНН/КПП 6324097480/632401001</div>
		<div>ОГРН 1186313106176</div>
		<div>445012, Самарская область, город Тольятти, Коммунистическая улица, дом 8, офис 602</div>
		<div className={style.cards}>
			<img src={mc} alt="" className={style.mc} />
			<img src={visa} alt="" className={style.visa} />
			<img src={mir} alt="" className={style.mir} />
		</div>
		{/*<p>Один из самых посещаемых сайтов грузоперевозок в российском интернете</p>*/}
		{/*<Link to="/requisites/" className={style.requisites}>Реквизиты</Link>*/}
	</div>
);

export default Company;
