import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';
import client from 'components/UI/Wrappers/BgForGuests/client.svg';
import driver from 'components/UI/Wrappers/BgForGuests/driver.svg';
import sms from 'components/UI/Wrappers/BgForGuests/sms.svg';
import bg from 'components/UI/Wrappers/BgForGuests/bg.svg';



const GrayBackground = ({ children, className, left, right }) => (
	<div className={`${style.grayback} ${className}`}>
		{ children }
		
		{
			left &&
			<div className={`${style.leftImg} ${style['left-' + left]}`}>
				{ left === 'client' && <img src={client} alt="" /> }
				{ left === 'driver' && <img src={driver} alt="" /> }
				{ left === 'sms' && <img src={sms} alt="" /> }
			</div>
		}
		{
			right &&
			<div className={style.rightImg}>
				<img src={bg} alt="" />
			</div>
		}
	</div>
);

GrayBackground.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};
GrayBackground.defaultProps = {
	children: '',
	className: '',
};

export default GrayBackground;
