import { createActions } from 'redux-actions';

export const { offersActions } = createActions({
    offersActions: {
        acceptProposal: [(payload) => payload, (payload, meta) => meta],
        createContract: [(payload) => payload, (payload, meta) => meta],
        confirmContractCode: [(payload) => payload, (payload, meta) => meta],
        setProposalsFromMe: [(payload) => payload, (payload, meta) => meta],
        readProposal: [(payload) => payload, (payload, meta) => meta],
        getMyProposal: [(payload) => payload, (payload, meta) => meta],
        rejectProposal: [(payload) => payload, (payload, meta) => meta],
        removeMyProposal: [(payload) => payload, (payload, meta) => meta],
        sendRequestEditOffer: [(payload) => payload, (payload, meta) => meta],
		getTradeByID: [(payload) => payload, (payload, meta) => meta],
        getOfferByID: [(payload) => payload, (payload, meta) => meta],
        sendReview: [(payload) => payload, (payload, meta) => meta],
        acceptOfferById: [(payload) => payload, (payload, meta) => meta],
        acceptUploadOfferById: [(payload) => payload, (payload, meta) => meta],
        acceptPayOfferById: [(payload) => payload, (payload, meta) => meta],
        setDetailOffer: [(payload) => payload, (payload, meta) => meta],
        setOffers: [(payload) => payload, (payload, meta) => meta],
        getOffers: [(payload) => payload, (payload, meta) => meta],
        closeOfferById: [(payload) => payload, (payload, meta) => meta],
        getTopOffers: [() => null, (payload, meta) => meta],
        updateOffer: [(payload) => payload, (payload, meta) => meta],
        setTopOffers: [(payload) => payload, (payload, meta) => meta],
        startRouteById: [(payload) => payload, (payload, meta) => meta],
        endRouteById: [(payload) => payload, (payload, meta) => meta],
        offerCompleteById: [(payload) => payload, (payload, meta) => meta],
        sendArbitration: [(payload) => payload, (payload, meta) => meta],
        updateOfferDetailById: [(payload) => payload, (payload, meta) => meta],
        getCargoByID: [(payload) => payload, (payload, meta) => meta],
        downloadContract: [(payload) => payload, (payload, meta) => meta],
        cancelTrade: [(payload) => payload, (payload, meta) => meta],
        insureTrade: [(payload) => payload, (payload, meta) => meta],


        getActiveOffers: [(payload) => payload, (payload, meta) => meta],
        loadingActiveOffers: [(payload) => payload, (payload, meta) => meta],
        setActiveOffers: [(payload) => payload, (payload, meta) => meta],

        getFinishOffers: [(payload) => payload, (payload, meta) => meta],
        loadingFinishOffers: [(payload) => payload, (payload, meta) => meta],
        setFinishOffers: [(payload) => payload, (payload, meta) => meta],

        getArbitrateOffers: [(payload) => payload, (payload, meta) => meta],
        loadingArbitrateOffers: [(payload) => payload, (payload, meta) => meta],
        setArbitrateOffers: [(payload) => payload, (payload, meta) => meta]

    }
});
