import { driverActions } from 'actions/driver';
import { setPoints } from 'actions/map';
import { getMyRoutes } from "libs/selections";
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import DriverRoute from './DriverRoute';
import style from './style.less';


class ProfileRoutes extends React.Component {

    static propTypes = {
        routes: PropTypes.any,
        roleCustomer: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        routes: { active: { total: 0, items: [] } },
    };

    state = {
        openMap: { id: 0 },
    };

    openMap = openMap => {
        if (openMap === this.state.openMap) this.setState({ openMap: { id: 0 } });
        else {
            this.setState({ openMap }, () => {
                this.props.dispatch(setPoints(this.state.openMap.items.map((el, i) => {
                    let key = i === 0 ? 'A' : 'B';
                    if (i > 1) key = 'B' + (i + 1);
                    return { name: el.address, key, pos: el.latitude + ' ' + el.longitude }
                })));
            });
        }
    };

    componentDidMount() {
        this.props.dispatch(driverActions.getRoutesByUserID(this.props.id))
    }

    render() {
        const { routes, roleCustomer } = this.props;
        if (roleCustomer)
            return null;

        return (
            <div className={style.profileRoutes}>
                <h3> Маршруты </h3>
                {
                    routes.active.total === 0 &&
                    <p> У данного водителя нет опубликованных маршрутов </p>
                }
                {
                    routes.active.total > 0 && routes.active.items.map(el => (
                        <DriverRoute
                            key={el.id}
                            route={el}
                            openMapID={this.state.openMap.id}
                            onClick={() => this.openMap(el)}
                        />
                    ))
                }
            </div>
        )
    }
}


export default connect(
    state => ({
        routes: getMyRoutes(state)
    })
)(ProfileRoutes);
