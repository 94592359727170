import { LabelError } from 'components/UI/Form/Labels';
import { ArrowDownChevron, Tick } from 'components/UI/Icons';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import style from './style.less';

export default class Select extends PureComponent {
    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]).isRequired,
        selected: PropTypes.any,
        input: PropTypes.object,
        meta: PropTypes.object,
        label: PropTypes.string,
        classNameContainer: PropTypes.string,
        classNameInput: PropTypes.string,
        childGroup: PropTypes.string,
        filter: PropTypes.bool,
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
    };
    static defaultProps = {
        selected: undefined,
        input: {
            value: '',
            onChange: () => {}
        },
        meta: {},
        label: '',
        classNameContainer: '',
        classNameInput: '',
        childGroup: 'none',
        filter: false,
        disabled: false,
        onClick: () => {},
    };

    state = {
        focus: false,
        filterStr: '',
        selected: '',
        filter: ''
    };

    inputRef = undefined;
    inputFilterRef = null;

    setDefaultValue = options => {
        if (options)
            for (let opt of options) {
                if (opt.props.value === this.props.input.value) {
                    this.setState(this.props.filter ? {
                        selected: opt,
                        filterStr: opt.props.filterStr
                    } : { selected: opt });
                    this.props.input.onChange(opt.props.value);
                    break;
                }
            }
    };
    handleHideList = event => {
        if (this.inputRef && !this.inputRef.contains(event.target))
            this.setState({ focus: false });
    };
    handleToggle = () => {
        this.setState({ focus: !this.state.focus }, () => {
            if (this.state.focus && this.props.filter) this.inputFilterRef.focus();
        });
    };
    handleChangeFilter = event => {
        this.setState({ filter: event.target.value });
    };
    handleSelected = option => {
        this.props.onClick();
        const newState = { selected: option, focus: false, filter: '' };
        if (option.props && option.props.value)
            this.props.input.onChange(option.props.value);
        this.setState(newState);
    };
    filteringOptions = options => options.filter(opt => (
        (opt.props.filterStr && typeof (opt.props.filterStr.toLowerCase) === 'function' &&
            opt.props.filterStr.toLowerCase().indexOf(this.state.filter.toLowerCase()) !== -1)
        || typeof (opt.props.onClick) === 'function'));

    renderOptions = options => {
        if (this.props.filter && this.state.filter) options = this.filteringOptions(options);

        if (options.length === 0 || !options) return <div className={style.not_found}>Ничего не найдено</div>;

        return options.map((opt, i) => {
            const active = opt.props.value === this.props.input.value,
                action = opt.props.onClick ? () => { opt.props.onClick(); this.props.onClick()} : () => this.handleSelected(opt);
            return (
                <div onClick={action} key={i}>
                    {opt}
                    {
                        active &&
                        <div className={`${style.show_state} ${style.show_success}`}>
                            {Tick(style.icon_success)}
                        </div>
                    }
                </div>
            );
        });
    };

    componentDidMount() {
        document.addEventListener('click', this.handleHideList, false);
        this.setDefaultValue(this.props.children);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleHideList, false);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.filter && nextProps.input.value) {
            nextProps.children.forEach(opt => {
                if (opt.props.value === nextProps.input.value) {
                    this.setState(nextProps.filter ? {
                        selected: opt,
                        filterStr: opt.props.filterStr
                    } : { selected: opt });
                }
            });
            return true;
        }

        if (!nextProps.input.value)
            return this.setState({ selected: '', filterStr: '' });
    }

    render() {
        const { children, input, meta, label, classNameContainer, classNameInput, childGroup, filter, disabled } = this.props,
            { focus, selected } = this.state;

        if (!filter && !selected && !label && input.value === '')
            input.onChange(children[0].props.value);

        const container = `
			${style.field__flex_container} 
			${style.field__flex_container_pointer}
			${classNameContainer} 
			${focus ? style.focus : ''}
			${label ? '' : style.noLabel }
			${disabled ? style.disabled : ''}
			${input.value ? style.not_empty : ''}
			${meta.error ? style.error : ''}
			${childGroup !== 'none' ? `${style.sub_container} ${style[childGroup]}` : ''}
		`;
        const container_input = `${style.field__input} ${classNameInput} ${style.selectHiddenString}`,
            isFilter = this.state.focus && filter,
            isHideValue = this.state.filter && this.state.focus;
		const errorCheck = meta.error !== null && meta.error !== undefined;
        return (
            <React.Fragment>
                <div
                    className={container}
                    onClick={this.props.disabled ? () => {
                    } : this.handleToggle}
                    ref={ref => this.inputRef = ref}
                >
                    <div className={container_input}>

                        {
                            isFilter &&
                            <input
                                className={style.filter}
                                value={this.state.filter}
                                onChange={this.handleChangeFilter}
                                ref={ref => this.inputFilterRef = ref}
                            />
                        }

                        {
                            filter && !this.props.disabled ?
                                <Fragment>
                                    <input
                                        className={`${isHideValue && style.hide}`}
                                        value={this.state.filterStr}
                                        disabled={true}
                                    />
                                </Fragment>
                                :
                                <div className={style.input}>
                                    {selected || label ? selected : children[0]}
                                </div>
                        }
                        <label>{label}</label>
                    </div>
                    {meta.error && <div className={`${style.show_state} ${style.rIcon} ${style.show_error}`}>!</div>}
                    <div className={style.arrow_state}>{ArrowDownChevron(style.arrow)}</div>
                    <div className={style.list}>{this.renderOptions(children)}</div>
                </div>
				{meta.error && <LabelError message={meta.error} isOn={errorCheck} />}
            </React.Fragment>
        );
    }
}
