import AdminGrid from 'components/Admin/UI/Grid';
import LeftContent from 'components/Admin/UI/LeftContent';
import leftStyle from 'components/Admin/UI/LeftContent/style.less';
import React from 'react';
import { Link } from 'react-router-dom';


const LeftStatisticMenu = props => {
    let { link } = props;
    let menu = [
        { href: '/admin/statistic/', title: 'Общая статистика', className: link === 'main' ? `${leftStyle.item__lnk} ${leftStyle.item__active}` : leftStyle.item__lnk }
    ];
    
    return (
        <div>
            <AdminGrid type="aside">
                <LeftContent title={'Статистика'}>
                    <ul className={leftStyle.list}>
                        {
                            menu.map(el => (
                                <li key={el.href} className={leftStyle.item}>
                                    <Link className={el.className} to={el.href}>{ el.title }</Link>
                                </li>
                            ))
                        }
                    </ul>
                </LeftContent>
            </AdminGrid>
        </div>
    )
};


export default LeftStatisticMenu;