import React from 'react';
import styles from '../style.less';
import { getCar } from 'libs/getInfoRoute';

const Transport = ({ data }) => {
	if (data === undefined || data === null) return null;
	let volume = data.height * data.width * data.length;
	return (
		<div className={styles.transportdata}>
			{ data.hasOwnProperty('id') && <React.Fragment>{ getCar(data)},&nbsp;</React.Fragment> }
			{ data.hasOwnProperty('id') && <span>{data.carrying}т,&nbsp;</span> }
			<span>{ volume }м<sup>3</sup>,&nbsp;</span>
			<span className={styles.lowercase}>{ data.bodyTypeId && data.bodyTypeId.titleRu }</span>
		</div>
	)
};


export default Transport;
