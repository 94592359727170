import React from 'react';
import PropTypes from 'prop-types';
import { Notification } from 'components/UI/Icons';
import style from './style.less';
// import Button from 'components/UI/Form/Button';

class Warning extends React.Component {
	state = {
		open: true,
	};

	static propTypes = {
		type: PropTypes.string,
		children: PropTypes.node,
		className: PropTypes.string,
	};
	static defaultProps = {
		type: 'info',
		children: '',
		className: '',
	};

	closeNotification = () => this.setState({ open: false });

	render() {
		const { className, type, children } = this.props;

		if (!this.state.open) return null;

		return (
			<div className={`${style.notification} ${className ? className : ''} ${style[type]}`}>
				<div className={style.type} />
				<div className={style.icon}>{ Notification() }</div>
				<div className={style.content}>{ children }</div>
				{/*<Button onClick={this.closeNotification} className={style.close}>{ Close() }</Button>*/}
			</div>
		)
	}
}

export default Warning;
