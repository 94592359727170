import React, { Fragment } from 'react';
import * as profileDetailActions from 'actions/profileDetail';
import { getProfile } from 'libs/selections';
import { connect } from 'react-redux';
import Review from 'components/Profile/ProfileView/Review';
import Page from 'components/UI/Wrappers/Page';
import Pagination from 'components/UI/Pagination';
import Loader from 'components/UI/Loader';
import style from './style.less';


class MyReviews extends React.PureComponent {

	state = {
		reviews: undefined,
		total: undefined,
		page: 1,
		pageSize: 2,
	};

	componentWillMount() {
		const { id } = this.props.profile;
		const { page, pageSize } = this.state;
		return new Promise((resolve, reject) => {
			this.props.dispatch(profileDetailActions.getReviewsByID({ userId: id, page, pageSize }, { resolve, reject }));
		}).then(res => this.setState({ reviews: res.items, total: res.total }));
	}

	setPage = (page, append = false) => {
		this.setState({ searchParams: { ...this.state.searchParams, page }, loading: true }, () => {
			// if (append) updateSearchAjaxData(this.state.searchParams, this.props.dispatch, () => this.setHistory());
			// else updateSearchData(this.state.searchParams, this.props.dispatch, () => this.setHistory());
		});
	};

	render() {
		const { reviews, total } = this.state;

		if (reviews === undefined || total === undefined)
			return <Page><Loader /></Page>

		const pageTitle = total > 0 ? `Отзывы ${total}` : 'Отзывы';

		let pageCount = 1;
		if (total > 0) pageCount = Math.ceil(total / 2);

		return (
			<Page title={pageTitle}>
				{
					total === 0
					&& 'Отзывы пока отсутствуют, сотрудничайте с клиентами и зарабатывайте себе репутацию.'
				}
				{
					total > 0 &&
					<Fragment>
						{
							reviews.map(el => <Review key={el.id} review={el} isUrlShow />)
						}
					</Fragment>
				}
				{
					pageCount > 1 &&
					<Pagination
						className={style.maxWidthForPagination}
						setPage={p => this.setPage(p)}
						setAjaxPage={p => this.setPage(p, true)}
						pageCount={pageCount}
						activePage={parseInt(this.state.page)}
					/>
				}
			</Page>
		);
	}
}


export default connect(
	store => ({
		profile: getProfile(store),
	})
)(MyReviews);