import showError from 'libs/showFormErrors';
import { required } from 'libs/validationsForms';
import { offersActions } from 'actions/offers';
import { dateToString } from 'libs/convertDate';

export const changeTrade = (values, dispatch, success) => {
	showError({
		price: [required(values.price)],
		startDate: [required(values.startDate)],
		endDate: [required(values.endDate)],
		title: [required(values.cargo.title)],
		weight: [required(values.cargo.weight)],
		width: [required(values.cargo.width)],
		height: [required(values.cargo.height)],
		weightCount: [required(values.cargo.weightCount)],
		category: [required(values.cargo.category)],
		package: [required(values.cargo.package)],
	});
	values.startDate = dateToString(values.startDate, 'serverDateTime');
	values.endDate = dateToString(values.endDate, 'serverDateTime');

	values = {
		...values,
		cargo: {
			...values.cargo,
			categoryId: values.cargo.category,
			packageId: values.cargo.package,
		}
	};

	new Promise((resolve, reject) => {
		dispatch(offersActions.updateOffer(values, { resolve, reject}));
	}).then(() => {
		success();
	});

};
