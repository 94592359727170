import Status from 'components/UI/Status';
import { dateToString, stringToDate } from 'libs/convertDate';
import { convertNumberSpaces } from 'libs/convertNumberSpaces';
import PropTypes from 'prop-types';
import React from 'react';
import style from './style.less';


class RequestProposal extends React.PureComponent {

    static propTypes = {
        price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        status: PropTypes.string,
        clientName: PropTypes.string,
    };

    static defaultProps = {
        price: 0,
        status: 'undefined',
        clientName: '',
    };

    render() {
        const createTime = dateToString(stringToDate(this.props.createTime), 'default');
        return (
            <div className={`${style.requestCommon} ${style.requestDriver}`}>
                <div className={style.price}>
                    {
                        this.props.price &&
                        <>
                        <div className={style.title}>
                            Ставка
                        </div>
                        <div className={style.value}>
                            {convertNumberSpaces(this.props.price)} <span className={style.currency}>р.</span>
                        </div>
                        </>
                    }
                </div>
                <div className={style.item}>
                    <div className={style.gray}>Клиент</div>
                    <span style={{ fontSize: '15px' }}>{this.props.clientName}</span>
                </div>
                <div className={style.item}>
                    <div className={style.gray}>Ваше предложение</div>
                    <span style={{ fontSize: '15px' }}>от {createTime}г.</span>
                </div>
                <div className={`${style.item} ${style.status}`}>
                    <Status status={this.props.status}/>
                </div>
            </div>
        );
    }
}


export default RequestProposal;