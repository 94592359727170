exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2VsK41DhphXhrj3jqHaM4f {\n  width: 100%;\n  max-width: 900px;\n  align-items: center;\n}\n._2VsK41DhphXhrj3jqHaM4f h3 {\n  margin-bottom: 20px;\n}\n@media screen and (max-width: 575px) {\n  ._2VsK41DhphXhrj3jqHaM4f h3 {\n    font-size: 17px;\n  }\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/UI/_V2/Proposals/Dialog/style.less"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,oBAAoB;CACrB;AACD;EACE,oBAAoB;CACrB;AACD;EACE;IACE,gBAAgB;GACjB;CACF","file":"style.less","sourcesContent":[".proposalDialog {\n  width: 100%;\n  max-width: 900px;\n  align-items: center;\n}\n.proposalDialog h3 {\n  margin-bottom: 20px;\n}\n@media screen and (max-width: 575px) {\n  .proposalDialog h3 {\n    font-size: 17px;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"proposalDialog": "_2VsK41DhphXhrj3jqHaM4f"
};