import React from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm, stopSubmit} from 'redux-form';
import Input from 'components/UI/Form/Fields/Input';
import Button from 'components/UI/Form/Button';

let ChangeCargoPrice = (props) => {
	let { handleSubmit, invalid } = props;

	return (
		<form onSubmit={handleSubmit} autoComplete="off">
			<Field
				name="price"
				type="text"
				component={Input}
				label="Ставка"
			/>
			<Button disabled={invalid} size="large" type="submit">OK</Button>
		</form>
	);
};

ChangeCargoPrice.propTypes = {
	handleSubmit: PropTypes.func,
	error: PropTypes.string,
	submitting: PropTypes.bool,
	invalid: PropTypes.bool,
};

ChangeCargoPrice.defaultProps = {
	handleSubmit: () => {
	},
	invalid: false,
	submitting: false,
	error: '',
};

ChangeCargoPrice = reduxForm({
	form: 'ChangeCargoPrice',
	onChange: (newValues, dispatch) => {
		dispatch(stopSubmit('ChangeCargoPrice', {}));
	}
})(ChangeCargoPrice);

export default ChangeCargoPrice;