import StartWork from 'components/HelpfulInformations/StartWork';
import { history } from 'components/../App';
import Page404 from 'components/404';
import AddCargo from 'components/AddCargo';
import AddRoute from 'components/AddRoute';
import AdminArbitrateItem from 'components/Admin/Pages/Arbitrations/Item';
import AdminArbitrateList from 'components/Admin/Pages/Arbitrations/List';
import AdminClientDetail from "components/Admin/Pages/Clients/Detail/index";
import AdminClientList from 'components/Admin/Pages/Clients/List';
import AdminDriverDetail from 'components/Admin/Pages/Drivers/Detail';
import AdminOfferDetail from 'components/Admin/Pages/Offers/Detail';
import AdminDriverList from 'components/Admin/Pages/Drivers/List';
import AdminOfferList from 'components/Admin/Pages/Offers/List';
import AdminRouteList from 'components/Admin/Pages/Routes/List';
import AdminTradeList from 'components/Admin/Pages/Trades/List';
import AdminUserItem from 'components/Admin/Pages/Users/Item';
import AdminUserList from 'components/Admin/Pages/Users/List';
import Auth from 'components/Auth';
import BecomeCarrier from 'components/BecomeCarrier';
import Feedback from 'components/HelpfulInformations/Support';
import Helps from 'components/Helps';
// import HowStartWork from 'components/HowStartWork';
import Insurance from 'components/HelpfulInformations/Insurance';
import MainPage from 'components/MainPage';
// import Mobile from 'components/Mobile';
import MyReviews from 'components/MyReviews';
import Notifications from 'components/Notifications';
import CreateOffer from 'components/Pages/Offers/Create';
import TradeDetail from 'components/Pages/Trades/Detail';
import Trades from 'components/Pages/Trades/List';
import TradeEdit from 'components/Pages/Trades/TradeEdit';
import Cars from 'components/Profile/Cars';
import CreateCar from 'components/Profile/Cars/Create';
import ProfileDetail from 'components/Profile/Detail';
// import ProfileView from 'components/Profile/ProfileView';
import DriverProposal from 'components/Profile/DriverProposal';
import EditProfile from 'components/Profile/EditProfile';
import Favorite from 'components/Profile/Favorite';
import MyCargo from 'components/Profile/MyCargo';
import OfferDetail from 'components/Profile/MyCargoDetail';
import RouteDetail from 'components/Profile/MyRouteDetail';
import Routes from 'components/Profile/Routes';
import Recovery from 'components/Recovery';
import RegistrationCustomer from 'components/Registration/RegistrationCustomer';
import RegistrationDriver from 'components/Registration/RegistrationDriver';
import Requisites from 'components/Requisites';
import Search from 'components/Search';
import Tariffs from 'components/HelpfulInformations/Tariffs';
import MainLayout from 'components/UI/Layouts/MainLayout';
import ConfirmPhoneAfterSendOffer from 'components/Pages/Offers/Confirm';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import style from './style.less';
import AdminRouteDetail from "components/Admin/Pages/Routes/Detail/index";
import AdminTradeDetail from "components/Admin/Pages/Trades/Detail/index";
import AdminStatistic from 'components/Admin/Pages/Statistic';
import Questions from "components/HelpfulInformations/Questions";
import RiskFree from "components/HelpfulInformations/Risk";
import Verification from "components/HelpfulInformations/Verification";
import MobileApp from "components/HelpfulInformations/MobileApp";

export default class Main extends React.Component {

    state = {
        step: false
    };

    componentWillMount() {
        history.listen((location) => {
            if (location.pathname !== '/search')
                this.setState({ step: true }, () => setTimeout(() => this.setState({ step: false }), 300));
        });
    }

    render() {
        const links = [
            { url: '/admin/statistic/', component: AdminStatistic, access: ['admin'] },
            { url: '/admin/drivers/', component: AdminDriverList, access: ['admin'] },
            { url: '/admin/driver/:id', component: AdminDriverDetail, access: ['admin'] },
            { url: '/admin/clients/', component: AdminClientList, access: ['admin'] },
            { url: '/admin/client/:id', component: AdminClientDetail, access: ['admin'] },
            { url: '/admin/offers/', component: AdminOfferList, access: ['admin'] },
	        { url: '/admin/offer/:id', component: AdminOfferDetail, access: ['admin'] },
            { url: '/admin/routes/', component: AdminRouteList, access: ['admin'] },
            { url: '/admin/route/:id', component: AdminRouteDetail, access: ['admin'] },
            { url: '/admin/trades/', component: AdminTradeList, access: ['admin'] },
            { url: '/admin/trade/:id', component: AdminTradeDetail, access: ['admin'] },
            { url: '/admin/users/', component: AdminUserList, access: ['admin'] },
            { url: '/admin/arbitrations/', component: AdminArbitrateList, access: ['admin'] },
            { url: '/admin/arbitrate/:id', component: AdminArbitrateItem, access: ['admin'] },
            { url: '/admin/user/:id', component: AdminUserItem, access: ['admin'] },
            { url: '/offers/', component: MyCargo, access: ['customer'] },
            { url: '/routes/', component: Routes, access: ['driver'] },
            { url: '/trades/', component: Trades, access: ['customer', 'driver'] },
            { url: '/favorites/', component: Favorite, access: ['customer'] },
            { url: '/proposals/', component: DriverProposal, access: ['driver'] },
            { url: '/reviews/', component: MyReviews, access: ['driver'] },
            { url: '/cars/', component: Cars, access: ['driver'] },
            { url: '/recovery/', component: Recovery, access: ['guest'] },
            { url: '/auth/', component: Auth, access: ['guest'] },
            { url: '/registration/customer/', component: RegistrationCustomer, access: ['guest'] },
            { url: '/registration/driver/', component: RegistrationDriver, access: ['guest'] },
            { url: '/offer/:id', component: OfferDetail, access: ['customer', 'driver'] },
            { url: '/route/:id', component: RouteDetail, access: ['customer', 'driver'] },
            { url: '/trade/:id', component: TradeDetail, access: ['customer', 'driver'] },
            // { url: '/profile/view/:id', component: ProfileView, access: ['customer', 'driver'] },
            { url: '/profile/:id', component: ProfileDetail, access: ['customer', 'driver'] },
            { url: '/search/', component: Search, access: ['guest', 'customer', 'driver'] },
            { url: '/new/offer/', component: CreateOffer, access: ['guest', 'customer'] },
            { url: '/new/route/', component: AddRoute, access: ['guest', 'driver'] },
            { url: '/new/car/', component: CreateCar, access: ['driver'] },
            { url: '/edit/offer/', component: AddCargo, access: ['customer'] },
            { url: '/edit/route/', component: AddRoute, access: ['driver'] },
            { url: '/edit/trade/', component: TradeEdit, access: ['customer'] },
            { url: '/edit/profile/', component: EditProfile, access: ['customer', 'driver'] },
            { url: '/edit/car/', component: CreateCar, access: ['driver'] },
            { url: '/help', component: Helps, access: ['admin', 'guest', 'driver', 'customer'] },
            { url: '/riskfree/', component: RiskFree, access: ['admin', 'guest', 'driver', 'customer'] },
            { url: '/verification/', component: Verification, access: ['driver', 'guest', 'customer'] },
            { url: '/mobile/', component: MobileApp, access: ['admin', 'guest', 'driver', 'customer'] },
            { url: '/tariffs/', component: Tariffs, access: ['admin', 'guest', 'driver', 'customer'] },
            { url: '/how_start_work/', component: StartWork, access: ['admin', 'guest', 'driver', 'customer'] },
            { url: '/insurance/', component: Insurance, access: ['admin', 'guest', 'driver', 'customer'] },
            { url: '/questions/', component: Questions, access: ['admin', 'guest', 'driver', 'customer'] },
            { url: '/feedback/', component: Feedback, access: ['admin', 'guest', 'driver', 'customer'] },
            { url: '/page/becomecarrier/', component: BecomeCarrier, access: ['admin', 'guest', 'customer', 'driver'] },
            { url: '/requisites/', component: Requisites, access: ['admin', 'guest', 'driver', 'customer'] },
            { url: '/confirm-phone-after-offer/', component: ConfirmPhoneAfterSendOffer, access: ['guest'] },
        ].filter(link => link.access.indexOf(this.props.role) !== -1);

        return (
            <MainLayout role={this.props.role}>
                <div className={this.state.step ? style.nextPage : ''}>
                    <Switch>
                        {
                            links.map(route => (
                                <Route
                                    key={route.url}
                                    path={route.url}
                                    component={route.component}
                                />
                            ))
                        }
                        <Route
                            exact={true}
                            path="/"
                            component={MainPage}
                        />
                        <Route
                            path="/"
                            component={Page404}
                        />
                    </Switch>
                </div>
                <Notifications/>
            </MainLayout>
        );
    }
}
