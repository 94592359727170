import React, { Fragment } from 'react';
import styles from './styles.less';
import PropTypes from 'prop-types';
import OutsideContainer from 'components/UI/Wrappers/OutsideContainer';
import Button from 'components/UI/Form/Button';


class ActionMenu extends React.Component {
	/*
	* type: offer, trade, tradeArbitrate route, car, card, proposal
	* */
	static propTypes = {
		type: PropTypes.string,
		actionEdit: PropTypes.func,
		actionDelete: PropTypes.func,
		actionCancelled: PropTypes.func,
		actionArbitrate: PropTypes.func,
	};

	static defaultProps = {
		type: '',
		actionEdit: () => {},
		actionDelete: () => {},
		actionCancelled: () => {},
	};

	state = {
		menu: false,
	};

	toggleMenu = (menu = true) => this.setState({ menu });

	render () {
		const { type, actionEdit, actionDelete, actionCancelled, actionArbitrate } = this.props;
		if (type === '') return null;

		const actEdit = () => {this.toggleMenu(false); actionEdit()};
		const actDelete = () => {this.toggleMenu(false); actionDelete()};
		return (
			<OutsideContainer onClickOutside={() => this.toggleMenu(false)}>
				<Button
					clearStyle
					className={`${styles.button} ${this.state.menu && styles.open}`}
					onClick={() => this.toggleMenu(!this.state.menu)}
					size={'small'}
					color={'white'}
				>
					<span>...</span>
				</Button>

				<ul
					className={`${styles.additionalMenu} ${this.state.menu && styles.open} ${type === 'proposal' && styles.wideMenu}`}
				>
					{
						type === 'offer' &&
						<Fragment>
							<li onClick={actionEdit}>Редактировать</li>
							<li onClick={actionDelete}>Удалить</li>
						</Fragment>
					}
					{
						type === 'proposal' &&
						<Fragment>
							<li onClick={actionCancelled}>Удалить предложение</li>
						</Fragment>
					}
					{
						type === 'route' &&
						<Fragment>
							<li onClick={actionEdit}>Редактировать</li>
							<li onClick={actionArbitrate}>Завершить</li>
							<li onClick={actionDelete}>Удалить</li>
						</Fragment>
					}
					{
						type === 'routePlannedEnd' &&
						<Fragment>
							<li onClick={actionEdit}>Редактировать</li>
							<li onClick={actionDelete}>Удалить</li>
						</Fragment>
					}
					{
						type === 'routeDesiredEnd' &&
						<Fragment>
							<li onClick={actionCancelled}>Продлить маршрут</li>
							<li onClick={actionEdit}>Редактировать</li>
							<li onClick={actionDelete}>Удалить</li>
						</Fragment>
					}
					{
						type === 'trade' &&
						<Fragment>
							<li onClick={actionCancelled}>Отменить сделку</li>
						</Fragment>
					}
					{
						type === 'tradeArbitrate' &&
						<Fragment>
							<li onClick={actionArbitrate}>Отправить жалобу</li>
						</Fragment>
					}
					{
						(type === 'car' || type === 'card') &&
						<Fragment>
							<li onClick={actEdit}>Редактировать</li>
							<li onClick={actDelete}>Удалить</li>
						</Fragment>
					}
				</ul>
			</OutsideContainer>
		)
	}
}


export default ActionMenu;
