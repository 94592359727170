export const statusList = {
    active: ['created', 'accepted', 'edit_request', 'edited', 'cargo_loaded', 'money_reserved', 'started_route', 'contract_signed', 'cargo_unloaded'],
    arbitrate: ['arbitration'],
    finish: ['failure', 'canceled', 'completed'],
    all: ['created', 'accepted', 'edit_request', 'edited', 'cargo_loaded', 'money_reserved', 'started_route', 'contract_signed', 'cargo_unloaded', 'arbitration', 'failure', 'canceled', 'completed']
};

export const statusListCargo = {
    active: ['moderation', 'published'],
    cancelled: ['cancelled'],
};

export const statusListOffers = {
    all: ['moderation', 'cancelled', 'trading_start', 'trading_arbitration', 'trading_end', 'published'],
    moderation: ['moderation'],
};

export const statusListRoute = {
    active: ['moderation', 'published'],
    cancelled: ['cancelled'],
    unpublished: ['unpublished'],
    moderation: ['moderation'],
    all: ['moderation', 'published', 'cancelled', 'unpublished']
};