import React from 'react';

const wsNotifactions = (data) => {
  let res = { text: data.title, type: 'info' };
  
  const { eventName, title } = data;
  
  if (eventName === 'app\\dialog\\notifications\\OfferNotification') {
    let description = data.description.slice(0,40);
    let more = data.description.length >= 12 ? '...' : '';
    
    switch (title) {
      case 'Заявка отменена':
        res = { text: [<span>Заявка №{data.offerId} отклонена. Причина: </span>, <br/>, <b>{ description }{more}</b>], type: 'error' };
        break;
      case 'Заявка опубликована':
        res = { text: `Заявка №${data.offerId} опубликована`, type: 'success' };
        break;
      default:
        break;
    }
  } else if (eventName === 'app\\dialog\\notifications\\RouteNotification') {
    let description = data.description.slice(0,40);
    let more = data.description.length >= 12 ? '...' : '';
  
    switch (title) {
      case 'Маршрут отменен':
        res = { text: [<span>Маршрут №{data.routeId} отклонен. Причина: </span>, <br/>, <b>{ description }{more}</b>], type: 'error' };
        break;
      case 'Маршрут опубликован':
        res = { text: `Маршрут №${data.routeId} опубликован`, type: 'success' };
        break;
      default:
        break;
    }
  }
  
  
  return res;
};


export default wsNotifactions;