import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import Button from 'components/UI/Form/Button';
import PropTypes from 'prop-types';
import Input from 'components/UI/Form/Fields/Input';
// import { clientPassport } from 'libs/validationsForms';
import { stringToDate, dateToString } from 'libs/convertDate';
import { convertNumberSpaces } from 'libs/convertNumberSpaces';
import Grid from 'components/UI/Wrappers/Grid';
import styles from './style.less';
import CustomScroll from 'react-custom-scroll';
import { getFullCar } from 'libs/getInfoRoute';
import language from 'language';
import { getVolume } from 'libs/getVolume';

class ContractForm extends React.Component {

	static propTypes = {
		handleSubmit: PropTypes.func,
		error: PropTypes.string,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
		trade: PropTypes.object.isRequired,
	};

	static defaultProps = {
		handleSubmit: () => {
		},
		invalid: false,
		submitting: false,
		error: '',
	};

	renderRouteList = () => {
		let route = '';
		this.props.trade.offer.route.items.forEach((el, i) => {
			route += el.address.name;
			if (this.props.trade.offer.route.items.length !== i+1) route += ' — ';
		});
		return route;
	};

	renderLicenseList = () => {
		let license = '';
		this.props.trade.executor.license.categories.forEach((el, i) => {
			license += el.toUpperCase();
			if (this.props.trade.executor.license.categories.length !== i+1) license += ', ';
		});
		return license;
	};

	render () {
		let { trade, handleSubmit, invalid, values } = this.props;

		const date = dateToString(stringToDate(trade.offer.createTime), 'stringDate');

		const getDate = dateToString(new Date(), 'getDate');
		const getMonth = dateToString(new Date(), 'getMonthString');
		const getYear = dateToString(new Date(), 'getYear');
		const departure = trade.offer.route.items[0].address.locality;
		const endDate = trade.offer.route.items[trade.offer.route.items.length - 1].startDate;
		const endDateContract = {
			date: dateToString(stringToDate(endDate), 'getDate'),
			month: dateToString(stringToDate(endDate), 'getMonthString'),
			year: dateToString(stringToDate(endDate), 'getYear'),
		};
		const { cargo } = trade.offer;
		const volume = getVolume({ length: cargo.length, width: cargo.width, height: cargo.height });
		
		const pointStart = trade.offer.route.items[0];
		const pointEnd = trade.offer.route.items[trade.offer.route.items.length - 1];
		
		return (
			<form onSubmit={handleSubmit} autoComplete="off">
				<h3>
					Заключение договора перевозки груза по <span className={styles.blue}>заявке №{ trade.offer.id } от { date }</span>
				</h3>
				<div className={styles.offerContainer}>
					<CustomScroll heightRelativeToParent="100%">
						<p style={{ marginTop: 0 }}>Для заключения договора необходимо внести ваши данные</p>
						<Grid col={'form-3'}>
							<LayoutField>
								<Field
									name="lastName"
									type="text"
									component={Input}
									label="Фамилия"
								/>
							</LayoutField>
							<LayoutField>
								<Field
									name="firstName"
									type="text"
									component={Input}
									label="Имя"
								/>
							</LayoutField>
							<LayoutField>
								<Field
									name="middleName"
									type="text"
									component={Input}
									label="Отчество"
								/>
							</LayoutField>
						</Grid>
						<Grid col={'form-2'}>
							<LayoutField>
								<Field
									name="number"
									type="text"
									component={Input}
									mask="9999 999999"
									label="Серия и номер паспорта"
								/>
							</LayoutField>
							<LayoutField>
								<Field
									name="address"
									type="text"
									component={Input}
									label="Город, улица, дом, квартира"
								/>
							</LayoutField>
						</Grid>
						<div>
							<div className={styles.offerMainText}>
								<h2 className={styles.h2}>ДОГОВОР ПЕРЕВОЗКИ ГРУЗА</h2>
								<p>г. { departure }</p>
								<p>«{ getDate }» { getMonth } { getYear }г.</p>
								<p>Гр. <b>{ trade.executor.lastName } { trade.executor.firstName }{ trade.executor.middleName !== null && trade.executor.middleName !== undefined && <React.Fragment> { trade.executor.middleName }</React.Fragment> }</b>, паспорт <b>{ trade.executor.passport.number }</b>, зарегистрирован по адресу: <b>{ trade.executor.passport.registrationAddress }</b>, в дальнейшем именуемый "Перевозчик", с одной стороны</p>
								<p>и</p>
								<p>Гр. <b>{ values.lastName } { values.firstName }{ values.middleName !== null && values.middleName !== '' && values.middleName !== '___________________' && <React.Fragment> { values.middleName }</React.Fragment> }</b>, паспорт <b>{ values.number }</b>, зарегистрирован по адресу: <b>{ values.address }</b>, в дальнейшем именуемый "Заказчик", с другой стороны,</p>
								<p>именуемые в дальнейшем "Стороны", а по отдельности "Сторона" заключили настоящий Договор о нижеследующем:</p>
								<h3 className={styles.h3}>1. ПРЕДМЕТ ДОГОВОРА</h3>
								<ol className={styles.tradeOlOne}>
									<li><p>1.1. В соответствии с настоящим договором Перевозчик обязуется доставить вверенный ему Заказчиком груз в пункт назначения и выдать его уполномоченному на получение груза лицу (грузополучателю) на условиях, указанных в настоящем договоре, а Заказчик обязуется уплатить за перевозку груза установленную плату в порядке и на условиях, определенных в настоящем договоре.</p></li>
									<li>
										<p>1.2. Перевозчик при оказании услуг по настоящему Договору обязуется соблюдать:</p>
										<ol>
											<li><p>Гражданский кодекс РФ,</p></li>
											<li><p>Правила перевозки грузов автомобильным транспортом,</p></li>
											<li><p>Общие правила перевозки грузов автомобильным транспортом,</p></li>
											<li><p>Устав автомобильного транспорта и городского наземного электрического транспорта,</p></li>
											<li><p>иные нормы действующего законодательства Российской Федерации.</p></li>
										</ol>
									</li>
									<li><p>1.3. Маршрут перевозки груза Заказчика: <b>{ this.renderRouteList() }</b>.</p></li>
									<li><p>1.4. Перевозчик не вправе привлечь к исполнению своих обязательств третьих лиц.</p></li>
									<li><p>1.5. Сервис HurryLorry - сервис, обеспечивающий Заказчикам и Перевозчикам грузов полный спектр услуг по поиску и оформлению Сделок по перевозке/транспортировке грузов.</p></li>
								</ol>

								<h3 className={styles.h3}>2. УСЛОВИЯ ДОСТАВКИ. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h3>

								<p><strong>2.1. Условия доставки.</strong></p>
								<ol>
									<li><p>2.1.1. Настоящий Договор заключается посредством принятия Перевозчиком к исполнению Ордера Заказчика, размещенного с использованием СервисаHurryLorry. (далее по тексту – Сервис).</p></li>
									<li><p>2.1.2. После заключения настоящего Договора Заказчиком оформляется транспортная накладная, автоматически формируемая с использованием Сервиса.</p></li>
									<li><p>2.1.3. Грузоперевозчик вправе осуществлять деятельность по перевозке грузов на основании водительских прав категории <b>{ this.renderLicenseList() }</b>, действительных на момент осуществления перевозки грузов Заказчика.</p></li>
									<li><p>2.1.4. Погрузка (выгрузка) груза осуществляется Перевозчиком, Заказчиком, а также Грузополучателем в порядке, предусмотренном настоящим Договором, с соблюдением положений, установленных транспортными уставами и кодексами и издаваемыми в соответствии с ними правилами.</p></li>
								</ol>

								<p className={styles.firstP}><strong>2.2. Права и обязанности Перевозчика.</strong></p>
								<ol>
									<li>
										<p>2.2.1. Перевозчик обязуется своевременно, в срок, указанный в Ордере Заказчика, размещенном на Сервисе, предоставить транспортное средство, пригодное для перевозок соответствующего вида груза, а именно:</p>
										<p style={{ marginLeft: '20px' }}>Автомобиль <b>{ getFullCar(trade.offer.transport) }</b>,</p>
										<p style={{ marginLeft: '20px' }}>Год выпуска <b>{ trade.offer.transport.publishYear }</b>,</p>
										<p style={{ marginLeft: '20px' }}>Гос.рег. знак <b>{ trade.offer.transport.registrationNumber.toUpperCase() }</b>,</p>
										<p style={{ marginLeft: '20px' }}>VIN <b>{ trade.offer.transport.vinNumber }</b>.</p>
										<p>При согласовании с Заказчиком Перевозчик обязуется произвести замену непригодных для перевозки транспортных средств за свой счет.</p>
									</li>
									<li><p>2.2.2. Ответственность за надлежащее техническое состояние Транспортного средства, подтверждающее пригодность Транспортного средства к эксплуатации и за состояние своего здоровья, позволяющее выполнять управление Транспортным средством, а также за наличие средств обеспечения безопасности грузоперевозок и за соблюдение режима труда и отдыха несет непосредственно сам Перевозчик.</p></li>
									<li><p>2.2.3. В случае несвоевременной подачи транспортного средства, наступившей вследствие действия непредвиденных обстоятельств Перевозчик обязан, по возможности, проинформировать об этом Заказчика, а при невозможности подачи транспортного средства в силу вышеуказанных причин, предоставить его в другое, согласованное с Заказчиком время без дополнительной оплаты.</p></li>
									<li><p>2.2.4. Заказчик обязан за свой счет организовать проведение работ по упаковке, маркировке, взвешиванию, хранению, пересчету, погрузки груза и при выгрузке груза.</p></li>
									<li><p>2.2.5. Перевозчик подтверждает наличие и оформление всех необходимых для осуществления доставки груза перевозочных документов (санитарный паспорт, разрешение на перевозку негабаритного, опасного груза и иных).</p></li>
									<li><p>2.2.6. При приеме груза для перевозки Перевозчик предъявляет грузоотправителю документ, удостоверяющий личность.</p></li>
									<li><p>2.2.7. При выдаче груза Перевозчик обязан проверить полномочия должностных лиц Грузополучателя груза и вправе запросить у Заказчика подтверждение необходимых данных для подтверждения правомерности своих действий по выдаче груза указанным уполномоченным лицам Грузополучателя.</p></li>
									<li><p>2.2.8. Время подачи транспортного средства под погрузку исчисляется с момента предъявления Перевозчиком Заказчику документа, удостоверяющего личность, в пункте погрузки, а время подачи транспортного средства под выгрузку – с момента предъявления Перевозчиком грузополучателю транспортной накладной в пункте выгрузки.</p></li>
									<li><p>2.2.9. Перевозчик по завершению погрузки подписывает транспортную накладную с использованием Сервиса и в случае необходимости указывает в транспортной накладной свои замечания и оговорки при приеме груза.</p></li>
									<li><p>2.3.10. Перевозчик обязан обеспечить своими силами загрузку автотранспорта, приняв меры по обеспечению невозможности перемещения грузов внутри грузового отсека автотранспорта. Погрузка грузов в транспортное средство осуществляется таким образом, чтобы обеспечить безопасность перевозок грузов и их сохранность, а также не допустить повреждение транспортного средства.</p></li>
									<li><p>2.2.11. Перевозчик обязуется прибыть в конечный пункт назначения в дату и ко времени, указанному в Ордере Заказчика. При этом Перевозчик обязуется обеспечить доставку груза строго по адресу, указанному в транспортной накладной. О задержке доставки груза Перевозчик обязан проинформировать Заказчика и Грузополучателя любым доступным способом.</p></li>
								</ol>

								<p className={styles.firstP}><strong>2.3. Права и обязанности Заказчика.</strong></p>
								<ol>
									<li><p>2.3.1. Заказчик обязуется в течение срока действия настоящего Договора предъявить перевозчику к отправке груз по маршруту, согласованному на условиях, указанных в настоящем Договоре.</p></li>
									<li><p>2.3.2. Заказчик обязан подготовить груз к перевозке таким образом, чтобы обеспечить безопасность его перевозки и сохранность груза во время его транспортировки, в том числе, не допуская перегруза транспортного средства.</p></li>
									<li><p>2.3.3. Заказчик обязан подготовить, упаковать и поместить в соответствующую тару груз в соответствии со стандартами, техническими условиями и нормативными документами на груз, тару и упаковку. При предъявлении для перевозки груза в таре или упаковке Заказчик обязан маркировать каждое грузовое место в соответствии с правилами перевозок грузов.</p></li>
									<li><p>2.3.4. Приспособления, необходимые для погрузки, выгрузки и перевозки груза, должны предоставляться и устанавливаться на транспортном средстве Перевозчиком после предварительного согласования с Заказчиком на Сервисе и сниматься с транспортного средства Грузоперевозчиком в момент погрузки и в момент выгрузки.</p></li>
									<li><p>2.3.5. Заказчик по требованию Перевозчика обязан устранить нарушения установленного порядка погрузки груза в транспортное средство, за исключением случая, если погрузка груза осуществляется Перевозчиком. В случае невыполнения Заказчиком требований об устранении недостатков в погрузке груза Перевозчик вправе отказаться от осуществления перевозки.</p></li>
									<li><p>2.3.11. Запрещается предоставлять Перевозчику к перевозке опасные грузы (яды, едкие кислоты, взрывчатые, легковоспламеняющиеся, отравляющие, радиоактивные вещества, медикаменты, содержащие наркотические и инфекционные вещества).</p></li>
									<li><p>2.3.12. Заказчик обязуется обеспечивать Перевозчика специальными инструкциями по перевалке, хранению и перевозке отдельных видов грузов, требующих особых условий перевозки.</p></li>
									<li><p>2.3.13. При оформлении транспортной накладной в присутствии Перевозчика Заказчик фиксирует фактические дату и время подачи транспортного средства под выгрузку, а также состояние груза, тары, упаковки, маркировки и опломбирования, массу груза и количество грузовых мест.</p></li>
									<li><p>2.3.14. Заказчик обязуется оплатить Перевозчику стоимость услуг по перевозке груза и вознаграждение в размере и порядке, установленными настоящим договором.</p></li>
									<li><p>2.3.15. Заказчик несет ответственность за достоверность сведений, указанных им в транспортной накладной.</p></li>
								</ol>

								<h3 className={styles.h3}>3. СТОИМОСТЬ УСЛУГ И ПОРЯДОК РАСЧЕТОВ</h3>

								<ol>
									<li><p>3.1. За выполнение обязательств, предусмотренных настоящим Договором, Заказчик оплачивает Перевозчику согласованную стоимость оказанных услуг, размер которой определяется в Ордере Заказчика, размещенном на Сервисе.</p></li>
									<li><p>3.2. Стоимость оказанных услуг Перевозчиком по договору составляет <b>{ convertNumberSpaces(trade.offer.price) } руб.</b></p></li>
									<li><p>3.3. Денежные средства, переводимые на счет Перевозчика, уменьшаются на сумму <b>{ convertNumberSpaces(parseInt(trade.offer.price * 0.1)) }</b>, перечисляемую в пользу Сервиса, и составляют <b>{ convertNumberSpaces(trade.offer.price - parseInt(trade.offer.price * 0.1)) } руб.</b></p></li>
									<li><p>3.4. По завершению работ по доставке груза Заказчик вносит информацию о принятии (непринятии) груза в соответствующем разделе Сервиса.</p></li>
									<li><p>3.5. Оплата оказанных услуг производится в соответствии с Пользовательским соглашением, размещенным на Сервисе.</p></li>
								</ol>

								<h3 className={styles.h3}>4. ОТВЕТСТВЕННОСТЬ СТОРОН</h3>

								<ol>
									<li><p>4.1. Заказчик и Перевозчик несут ответственность за ненадлежащее исполнение или неисполнение обязательств, принятых на себя по настоящему договору, в соответствии с действующим законодательством РФ.</p></li>
									<li><p>4.2. Согласно ст. 796 Гражданского Кодекса Российской Федерации предусмотрена следующая ответственность перевозчика за утрату, недостачу и повреждение (порчу) груза или багажа:</p></li>
								</ol>
								<p>1. Перевозчик несет ответственность за несохранность груза или багажа, происшедшую после принятия его к перевозке и до выдачи грузополучателю, управомоченному им лицу или лицу, управомоченному на получение багажа, если не докажет, что утрата, недостача или повреждение (порча) груза или багажа произошли вследствие обстоятельств, которые перевозчик не мог предотвратить и устранение которых от него не зависело.</p>
								<p>2. Ущерб, причиненный при перевозке груза или багажа, возмещается перевозчиком:</p>
								<p style={{ marginLeft: '20px' }}>в случае утраты или недостачи груза или багажа - в размере стоимости утраченного или недостающего груза или багажа;</p>
								<p style={{ marginLeft: '20px' }}>в случае повреждения (порчи) груза или багажа - в размере суммы, на которую понизилась его стоимость, а при невозможности восстановления поврежденного груза или багажа - в размере его стоимости;</p>
								<p style={{ marginLeft: '20px' }}>в случае утраты груза или багажа, сданного к перевозке с объявлением его ценности, - в размере объявленной стоимости груза или багажа.</p>
								<p>Стоимость груза или багажа определяется исходя из его цены, указанной в счете продавца или предусмотренной договором, а при отсутствии счета или указания цены в договоре исходя из цены, которая при сравнимых обстоятельствах обычно взимается за аналогичные товары.</p>
								<p>3. Перевозчик наряду с возмещением установленного ущерба, вызванного утратой, недостачей или повреждением (порчей) груза или багажа, возвращает отправителю (получателю) провозную плату, взысканную за перевозку утраченного, недостающего, испорченного или поврежденного груза или багажа, если эта плата не входит в стоимость груза.</p>
								<p>4. Документы о причинах несохранности груза или багажа (коммерческий акт, акт общей формы и т.п.), составленные перевозчиком в одностороннем порядке, подлежат в случае спора оценке судом наряду с другими документами, удостоверяющими обстоятельства, которые могут служить основанием для ответственности перевозчика, отправителя либо получателя груза или багажа».</p>
								<ol>
									<li><p>4.3. Заказчик несет ответственность за достоверность сведений, указанных в документации, предоставляемой Перевозчику для выполнения Перевозки.</p></li>
									<li><p>4.4. Заказчик несет полную ответственность за сведения об адресатах и контактных данных фактических представителей Грузополучателей перевозимого груза, а также проинструктировать Перевозчика о порядке оформления соответствующих актов в процессе грузоперевозки, в том числе в экстренных случаях.</p></li>
									<li><p>4.5. Заказчик возмещает Перевозчику расходы по перевозке партии груза по маршруту с неверно указанным адресом.</p></li>
									<li><p>4.6. В случае обнаружения сотрудниками внутренних дел и/или ГИБДД несоответствия груза оформленной на него транспортной накладной или возникновения обоснованных подозрений о наличии в нем предметов, запрещенных к перевозке, всю полноту ответственности за это несет Перевозчик.</p></li>
									<li><p>4.7. Перевозчик не несет ответственности за внутритарную недостачу содержимого грузовых мест, принятых (переданных) в исправной таре.</p></li>
									<li><p>4.8. Перевозчик не несет ответственности за недостачу груза, не превышающую норму естественной убыли и значение предельного расхождения в результате определения массы нетто груза.</p></li>
									<li><p>4.9. Перевозчик не несет ответственности за утрату, повреждение, ухудшение качества, недостачу груза, произошедшую в результате предоставления Заказчиком недостоверных, неточных, неполных сведений, относящихся к исполнению Перевозчиком обязательств по настоящему Договору.</p></li>
									<li><p>4.10. Перевозчик не несет ответственности за ухудшение качества груза, вследствие предъявления к перевозке некачественного груза, в случае соблюдения Перевозчиком заявленных условий перевозки.</p></li>
									<li><p>4.11. Перевозчик не несет ответственность за утрату, повреждение, ухудшение качества продовольственных и скоропортящихся грузов, перевозка которых осуществлялась под ответственность Заказчика, если они были доставлены в срок.</p></li>
									<li><p>4.12. Перевозчик не несет ответственности за ухудшение качества грузов, произошедшее в период перевозки в силу естественных причин (окончания срока годности груза и пр.).</p></li>
									<li><p>4.13. Перевозчик не несет ответственности за задержку отправки, принадлежащих Заказчику грузов, если задержка связана с действиями правоохранительных и иных государственных органов, которые не были вызваны нарушением со стороны Перевозчика действующего законодательства.</p></li>
									<li><p>4.14. Перевозчик не несет ответственности за убытки, понесенные Заказчиком в случае недостатков тары (упаковки) груза, которые не могли быть замечены при наружном осмотре во время приема груза к перевозке.</p></li>
									<li><p>4.15. Перевозчик не несет ответственности за ущерб, нанесенный грузу, в случае отказа Заказчика от осуществления дополнительной упаковки груза, необходимой для безопасности грузоперевозки.</p></li>
								</ol>

								<h3 className={styles.h3}>5. ПРЕТЕНЗИИ. ПОРЯДОК РАССМОТРЕНИЯ СПОРОВ</h3>

								<ol>
									<li><p>5.1. Обстоятельства, являющиеся основанием для возникновения ответственности Перевозчика, Заказчика, Грузополучателей, при перевозках грузов или предоставлении транспортных средств для перевозок грузов, удостоверяются Актами, отметками в транспортных накладных, фото- и видео- отчетами.</p></li>
									<li><p>5.2. Акт составляется в присутствии Сторон настоящего Договора: Заказчика (или Грузополучателя) и Перевозчика в день обнаружения обстоятельств, подлежащих оформлению Актом.</p></li>
									<li><p>5.3. При невозможности составить Акт в указанный срок он составляется в течение следующих суток. Акт об утрате, недостаче или порче груза (об установленном расхождении данным в товаро-сопроводительных документах), составленный в отсутствии одной из Сторон, не может являться основанием для выставления претензии и к рассмотрению не принимается.</p></li>
									<li><p>5.4. Акт должен содержать дату, место составления, точное и подробное описание состояния груза и тех обстоятельств, при которых обнаружена несохранность груза, точное количество поврежденного и утраченного груза. Ф.И.О. представителей Сторон, составляющих акт, а также независимых сторонних свидетелей, присутствовавших при составлении Акта.</p></li>
									<li><p>5.5. В случае утраты или недостачи груза, повреждения (порчи) груза к Акту прилагаются результаты проведения экспертизы для определения размера фактических недостачи и повреждения (порчи) груза, при этом составление указанного Акта и проведение экспертизы должно быть проведено в присутствии Перевозчика.</p></li>
									<li><p>5.6. В случае отказа от подписи лица, участвующего в составлении Акта, в Акте указывается причина отказа.</p></li>
									<li><p>5.7. Акт составляется в количестве экземпляров, соответствующем числу участвующих в его составлении лиц, но не менее чем в 3 (Трех) экземплярах. Исправления в составленном Акте не допускаются.</p></li>
									<li><p>5.8. В транспортной накладной должна быть сделана отметка о составлении Акта, содержащая краткое описание обстоятельств, послуживших основанием для ее проставления.</p></li>
									<li><p>5.7. До предъявления к Перевозчику иска, вытекающего из договора перевозки грузов, к нему в обязательном порядке предъявляются претензия.</p></li>
									<li><p>5.8.Право на предъявление к Перевозчику претензий в досудебном порядке имеют только Заказчик, заключивший настоящий Договор, а также страховщики, выплатившие страховое возмещение в связи с ненадлежащим исполнением Перевозчиком, своих обязательств по перевозкам грузов, предоставлению транспортных средств для перевозок грузов.</p></li>
									<li><p>5.9. Претензии к Перевозчику могут быть предъявлены в сроки, в порядке и в соответствии с действующим законодательством о конкретном виде грузоперевозок. Срок исковой давности по требованиям, вытекающим из перевозки груза, устанавливается в 1 (Один) год с момента, определяемого в соответствии с транспортными уставами и кодексами.</p></li>
									<li><p>5.10. Перевозчик обязан рассмотреть предъявленную ему претензию и о результатах ее рассмотрения уведомить в письменной форме Заявителю в течение 30(Тридцати) дней со дня получения соответствующей претензии.</p></li>
									<li><p>5.11. При частичном удовлетворении или отклонении Перевозчиком претензии Заявителя в уведомлении должно быть указано основание принятия ими такого решения. В этом случае представленные вместе с претензией документы возвращаются Заявителю.</p></li>
									<li><p>5.12. При недостижения соглашения в претензионном порядке споры между сторонами подлежат рассмотрению в суде по месту нахождения ответчика.</p></li>
								</ol>

								<h3 className={styles.h3}>6. ОСНОВАНИЕ ОСВОБОЖДЕНИЯ ОТ ОТВЕТСТВЕННОСТИ</h3>

								<ol>
									<li><p>6.1. Перевозчик, Заказчик, Грузополучатель, освобождаются от ответственности, предусмотренной настоящим Договором, если неисполнение ими своих обязательств произошло вследствие:</p></li>
									<ol>
										<li><p>непреодолимой силы;</p></li>
										<li><p>временных ограничения или запрета движения транспортных средств по автомобильным дорогам, введенных в порядке, установленном законодательством Российской Федерации, по не зависящим от Перевозчика, Заказчика, Грузополучателя, причинам;</p></li>
										<li><p>иных не зависящих от Перевозчика, Заказчика, Грузополучателя, причин.</p></li>
									</ol>
								</ol>

								<h3 className={styles.h3}>7. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>

								<ol>
									<li><p>7.1. Настоящий Договор вступает в силу с момента его подписания и действует до { endDateContract.date } { endDateContract.month } { endDateContract.year } года.</p></li>
									<li><p>7.2. Настоящий Договор может быть изменен или расторгнут по соглашению Сторон.</p></li>
									<li><p>7.3. Настоящий Договор составлен в электронном виде и подписан Сторонами в порядке, предусмотренном функционалом Сервиса.</p></li>
								</ol>

								<h3 className={styles.h3}>8. АДРЕСА, РЕКВИЗИТЫ И ПОДПИСИ СТОРОН</h3>

								<p style={{ textAlign: 'right' }}><span style={{ fontSize: 'small' }}>Приложение № 1<br/>к Правилам перевозок грузов автомобильным транспортом</span></p>
								<p style={{ textAlign: 'right' }}><span style={{ fontSize: 'small' }}>(в ред. Постановлений Правительства РФ от 30.12.2011 № 1208,<br/>от 03.12.2015 № 1311, от 12.12.2017 № 1529)</span></p>
								<p style={{ textAlign: 'right' }}><span style={{ fontSize: 'small' }}>Форма</span></p>
								<p style={{ textAlign: 'center' }}><span style={{ fontSize: 'small' }}>ТРАНСПОРТНАЯ НАКЛАДНАЯ</span></p>

								<table className={styles.table_paddings}>
									<tbody>
										<tr className={styles.cols_2}>
											<td colSpan={6}>Транспортная накладная</td>
											<td colSpan={6}>Заказ (заявка)</td>
										</tr>
									</tbody>
									<tbody>
										<tr>
											<td colSpan={1}>Экземпляр №</td>
											<td colSpan={5}>&nbsp;</td>
											<td>Дата</td>
											<td colSpan={2}>25.04.2019</td>
											<td>№</td>
											<td colSpan={2}>{ trade.offer.id }</td>
										</tr>
									</tbody>
									<tbody>
										<tr>
											<td colSpan={6}>1. Грузоотправитель (грузовладелец)</td>
											<td colSpan={6}>2. Грузополучатель</td>
										</tr>
										<tr>
											<td colSpan={1}/>
											<td colSpan={4}>{ pointStart.providerName }, { pointStart.providerPhone }</td>
											<td colSpan={1}/>
											<td colSpan={1}/>
											<td colSpan={4}>{ pointEnd.providerName }, { pointEnd.providerPhone }</td>
											<td colSpan={1}/>
										</tr>
										<tr>
											<td colSpan={1}/>
											<td colSpan={4}>
												(фамилия, имя, отчество, адрес места жительства,<br/>номер телефона – для физического лица (уполномоченного лица))
											</td>
											<td colSpan={1}/>
											<td colSpan={1}/>
											<td colSpan={4}>
												(фамилия, имя, отчество, адрес места жительства,<br/>номер телефона – для физического лица (уполномоченного лица))
											</td>
											<td colSpan={1}/>
										</tr>
										<tr>
											<td colSpan={1}/>
											<td colSpan={4}/>
											<td colSpan={1}/>
											<td colSpan={1}/>
											<td colSpan={4}/>
											<td colSpan={1}/>
										</tr>
										<tr>
											<td colSpan={1}/>
											<td colSpan={4}>
												(полное наименование, адрес места нахождения,<br/>номер телефона – для юридического лица)
											</td>
											<td colSpan={1}/>
											<td colSpan={1}/>
											<td colSpan={4}>
												(полное наименование, адрес места нахождения,<br/>номер телефона – для юридического лица)
											</td>
											<td colSpan={1}/>
										</tr>
									</tbody>
									<tbody>
									<tr>
										<td colSpan={12}>3. Наименование груза</td>
									</tr>
									</tbody>
									<tbody>
										<tr>
											<td/>
											<td colSpan={10}>{ trade.offer.cargo.title }</td>
											<td/>
										</tr>
										<tr>
											<td/>
											<td colSpan={10}>
												(отгрузочное наименование груза (для опасных грузов – в соответствии с ДОПОГ),
												<br/>его состояние и другая необходимая информация о грузе)
											</td>
											<td/>
										</tr>
										<tr>
											<td/>
											<td colSpan={10}>
												Грузовых мест - { trade.offer.cargo.weightCount }, упаковка - { language[trade.offer.cargo.package] }
											</td>
											<td/>
										</tr>
										<tr>
											<td/>
											<td colSpan={10}>(количество грузовых мест, маркировка, вид тары и способ упаковки)</td>
											<td/>
										</tr>
										<tr>
											<td/>
											<td colSpan={10}>
												{ cargo.weight }кг., Д { cargo.length }м X Ш { cargo.width }м X В { cargo.height }м, { volume }м<sup>3</sup>
											</td>
											<td/>
										</tr>
										<tr>
											<td/>
											<td colSpan={10}>(масса нетто (брутто) грузовых мест в килограммах, размеры (высота, ширина и длина) в метрах,<br/>объем грузовых мест в кубических метрах)</td>
											<td/>
										</tr>
										<tr>
											<td/>
											<td colSpan={10}/>
											<td/>
										</tr>
										<tr>
											<td/>
											<td colSpan={10}>(в случае перевозки опасного груза – информация по каждому опасному веществу,<br/>материалу или изделию в соответствии с пунктом 5.4.1 ДОПОГ)</td>
											<td/>
										</tr>
									</tbody>
									<tbody>
										<tr>
											<td/>
											<td colSpan={10}>4. Сопроводительные документы на груз</td>
											<td/>
										</tr>
									</tbody>
									<tbody>
										<tr>
											<td/>
											<td colSpan={10}/>
											<td/>
										</tr>
										<tr>
											<td/>
											<td colSpan={10}>(перечень прилагаемых к транспортной накладной документов, предусмотренных ДОПОГ, санитарными, таможенными, карантинными, иными правилами в соответствии с законодательством Российской Федерации, либо регистрационные номера указанных документов, если такие документы (сведения о таких документах) содержатся<br/>в государственных информационных системах)</td>
											<td/>
										</tr>
										<tr>
											<td/>
											<td colSpan={10}/>
											<td/>
										</tr>
										<tr>
											<td/>
											<td colSpan={10}>
												(перечень прилагаемых к грузу сертификатов, паспортов качества, удостоверений, разрешений, инструкций, товарораспорядительных и других документов, наличие которых установлено законодательством Российской Федерации, либо регистрационные номера указанных документов, если такие документы (сведения о таких документах) содержатся в государственных информационных системах)
											</td>
											<td/>
										</tr>
									</tbody>
									<tbody>
										<tr>
											<td colSpan={12}>5. Указания грузоотправителя</td>
										</tr>
									</tbody>
									<tbody>
										<tr>
											<td/>
											<td colSpan={10}>
												{ getFullCar(trade.offer.transport) }, {trade.offer.transport.carrying}т., Д{trade.offer.transport.length}м x Ш{trade.offer.transport.width}м x В{trade.offer.transport.height}м, { trade.offer.transport.length * trade.offer.transport.width * trade.offer.transport.height }м<sup>3</sup>
											</td>
											<td/>
										</tr>
										<tr>
											<td/>
											<td colSpan={10}>(параметры транспортного средства, необходимые для осуществления перевозки груза (тип, марка, грузоподъемность, вместимость и др.))</td>
											<td/>
										</tr>
										<tr>
											<td/>
											<td colSpan={10}/>
											<td/>
										</tr>
										<tr>
											<td/>
											<td colSpan={10}>(указания, необходимые для выполнения фитосанитарных, санитарных, карантинных, таможенных и прочих требований, установленных законодательством Российской Федерации)</td>
											<td/>
										</tr>
										<tr>
											<td/>
											<td colSpan={10}/>
											<td/>
										</tr>
										<tr>
											<td/>
											<td colSpan={10}>(рекомендации о предельных сроках и температурном режиме перевозки, сведения о запорно-пломбировочных устройствах (в случае их предоставления грузоотправителем), объявленная стоимость (ценность) груза, запрещение перегрузки груза)</td>
											<td/>
										</tr>
									</tbody>
									<tbody>
										<tr>
											<td colSpan={6}>6. Прием груза</td>
											<td colSpan={6}>7. Сдача груза</td>
										</tr>
									</tbody>
									<tbody>
										<tr>
											<td colSpan={1}/>
											<td colSpan={4}>{ pointStart.address.name }</td>
											<td colSpan={1}/>
											<td colSpan={1}/>
											<td colSpan={4}>{ pointEnd.address.name }</td>
											<td colSpan={1}/>
										</tr>
									<tr>
										<td colSpan={1}/>
										<td colSpan={4}>(адрес места погрузки)</td>
										<td colSpan={1}/>
										<td colSpan={1}/>
										<td colSpan={4}>(адрес места выгрузки)</td>
										<td colSpan={1}/>
									</tr>
									<tr>
										<td colSpan={1}/>
										<td colSpan={4}/>
										<td colSpan={1}/>
										<td colSpan={1}/>
										<td colSpan={4}/>
										<td colSpan={1}/>
									</tr>
									<tr>
										<td colSpan={1}/>
										<td colSpan={4}>(дата и время подачи транспортного средства под погрузку)</td>
										<td colSpan={1}/>
										<td colSpan={1}/>
										<td colSpan={4}>(дата и время подачи транспортного средства под выгрузку)</td>
										<td colSpan={1}/>
									</tr>
									<tr>
										<td colSpan={1}/>
										<td colSpan={4}/>
										<td colSpan={1}/>
										<td colSpan={1}/>
										<td colSpan={4}/>
										<td colSpan={1}/>
									</tr>
									<tr>
										<td/>
										<td>(фактические дата и время прибытия)</td>
										<td/>
										<td>(фактические дата и время убытия)</td>
										<td/>
										<td/>
										<td>(фактические дата и время прибытия)</td>
										<td/>
										<td>(фактические дата и время убытия)</td>
										<td/>
									</tr>
									<tr>
										<td colSpan={1}/>
										<td colSpan={4}/>
										<td colSpan={1}/>
										<td colSpan={1}/>
										<td colSpan={4}/>
										<td colSpan={1}/>
									</tr>
									<tr>
										<td colSpan={1}/>
										<td colSpan={4}>(фактическое состояние груза, тары, упаковки, маркировки и опломбирования)</td>
										<td colSpan={1}/>
										<td colSpan={1}/>
										<td colSpan={4}>(фактическое состояние груза, тары, упаковки, маркировки и опломбирования)</td>
										<td colSpan={1}/>
									</tr>
									<tr>
										<td/>
										<td/>
										<td/>
										<td/>
										<td/>
										<td/>
										<td/>
										<td/>
										<td/>
										<td/>
										<td/>
										<td/>
									</tr>
									<tr>
										<td/>
										<td>(масса груза)</td>
										<td/>
										<td>(количество грузовых мест)</td>
										<td/>
										<td/>
										<td>(масса груза)</td>
										<td/>
										<td>(количество грузовых мест)</td>
										<td/>
									</tr>
									<tr>
										<td colSpan={1}/>
										<td colSpan={4}/>
										<td colSpan={1}/>
										<td colSpan={1}/>
										<td colSpan={4}/>
										<td colSpan={1}/>
									</tr>
									<tr>
										<td colSpan={1}/>
										<td colSpan={4}>(должность, подпись, расшифровка подписи<br/>грузоотправителя (уполномоченного лица))</td>
										<td colSpan={1}/>
										<td colSpan={1}/>
										<td colSpan={4}>(должность, подпись, расшифровка подписи<br/>грузополучателя (уполномоченного лица))</td>
										<td colSpan={1}/>
									</tr>
									<tr>
										<td colSpan={1}/>
										<td colSpan={4}/>
										<td colSpan={1}/>
										<td colSpan={1}/>
										<td colSpan={4}/>
										<td colSpan={1}/>
									</tr>
									<tr>
										<td/>
										<td colSpan="4">(подпись, расшифровка подписи водителя,<br/>принявшего груз для перевозки)</td>
										<td/>
										<td/>
										<td colSpan="4">(подпись, расшифровка подписи водителя,<br/>сдавшего груз)</td>
										<td/>
									</tr>
									</tbody>
								</table>

								<table className={styles.table_paddings}>
									<tbody>
									<tr>
										<td colSpan="7"><p><span style={{ fontSize: 'small' }}>8. Условия перевозки</span></p></td>
									</tr>
									</tbody>
									<tbody>
									<tr>
										<td/>
										<td colSpan="5"/>
										<td/>
									</tr>
									<tr>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
										<td colSpan="5" style={{ verticalAlign: 'TOP' }}><p><span style={{ fontSize: 'small' }}>(сроки, по истечении которых грузоотправитель и грузополучатель вправе считать груз утраченным, форма уведомления о проведении экспертизы для определения размера фактических недостачи, повреждения (порчи) груза)</span>
										</p></td>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
									</tr>
									<tr>
										<td/>
										<td colSpan="5"/>
										<td/>
									</tr>
									<tr>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
										<td colSpan="5" style={{ verticalAlign: 'TOP' }}><p><span style={{ fontSize: 'small' }}>(размер платы и предельный срок хранения груза в терминале перевозчика, сроки погрузки (выгрузки) груза, порядок предоставления и установки приспособлений, необходимых для погрузки, выгрузки и перевозки груза)</span>
										</p></td>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
									</tr>
									<tr>
										<td/>
										<td colSpan="5"/>
										<td/>
									</tr>
									<tr>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
										<td colSpan="5" style={{ verticalAlign: 'TOP' }}><p><span style={{ fontSize: 'small' }}>(порядок внесения в транспортную накладную записи о массе груза и способе ее определения, опломбирования крытых транспортных средств и контейнеров, порядок осуществления погрузо-разгрузочных работ, выполнения работ по промывке и дезинфекции транспортных средств)</span>
										</p></td>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
									</tr>
									<tr>
										<td/>
										<td colSpan="5"/>
										<td/>
									</tr>
									<tr>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
										<td colSpan="5" style={{ verticalAlign: 'TOP' }}><p><span style={{ fontSize: 'small' }}>(размер штрафа за невывоз груза по вине перевозчика, несвоевременное предоставление транспортного средства, контейнера и просрочку доставки груза;<br/>порядок исчисления срока просрочки)</span>
										</p></td>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
									</tr>
									<tr>
										<td/>
										<td colSpan="5"/>
										<td/>
									</tr>
									<tr>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
										<td colSpan="5" style={{ verticalAlign: 'TOP' }}><p><span style={{ fontSize: 'small' }}>(размер штрафа за непредъявление транспортных средств для перевозки груза, за задержку (простой) транспортных средств, поданных под погрузку, выгрузку, за простой специализированных транспортных средств и задержку (простой) контейнеров)</span>
										</p></td>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
									</tr>
									</tbody>
									<tbody>
									<tr>
										<td colSpan="7"><p><span style={{ fontSize: 'small' }}>9. Информация о принятии заказа (заявки) к исполнению</span>
										</p></td>
									</tr>
									</tbody>
									<tbody>
									<tr>
										<td/>
										<td/>
										<td/>
										<td/>
										<td/>
										<td/>
										<td/>
									</tr>
									<tr>
										<td/>
										<td><p><span style={{ fontSize: 'small' }}>(дата принятия заказа<br/>(заявки) к исполнению)</span>
										</p>
										</td>
										<td/>
										<td><p><span style={{ fontSize: 'small' }}>(фамилия, имя, отчество, должность лица,<br/>принявшего заказ (заявку) к исполнению)</span>
										</p></td>
										<td/>
										<td><p><span style={{ fontSize: 'small' }}>(подпись)</span></p></td>
										<td/>
									</tr>
									</tbody>
								</table>
								
								<p><span style={{ fontSize: 'small' }}>Продолжение приложения № 4</span></p><p><span
									style={{ fontSize: 'small' }}>Оборотная сторона</span>
								</p>
								
								<table className={styles.table_paddings}>
									<tbody>
									<tr>
										<td colSpan="22"><p><span style={{ fontSize: 'small' }}>10. Перевозчик</span></p></td>
									</tr>
									</tbody>
									<tbody>
									<tr>
										<td/>
										<td colSpan="20">{ trade.executor.lastName } { trade.executor.firstName }{ trade.executor.middleName !== null && trade.executor.middleName !== undefined && <React.Fragment> { trade.executor.middleName }</React.Fragment> }, { trade.executor.passport.registrationAddress }, { trade.executor.phone }</td>
										<td/>
									</tr>
									<tr>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
										<td colSpan="20" style={{ verticalAlign: 'TOP' }}><p><span style={{ fontSize: 'small' }}>(фамилия, имя, отчество, адрес места жительства, номер телефона – для физического лица (уполномоченного лица))</span>
										</p></td>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
									</tr>
									<tr>
										<td/>
										<td colSpan="20"/>
										<td/>
									</tr>
									<tr>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
										<td colSpan="20" style={{ verticalAlign: 'TOP' }}><p><span style={{ fontSize: 'small' }}>(наименование и адрес места нахождения, номер телефона – для юридического лица)</span>
										</p></td>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
									</tr>
									<tr>
										<td/>
										<td colSpan="20"/>
										<td/>
									</tr>
									<tr>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
										<td colSpan="20" style={{ verticalAlign: 'TOP' }}><p><span style={{ fontSize: 'small' }}>(фамилия, имя, отчество, данные о средствах связи (при их наличии) водителя (водителей))</span>
										</p></td>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
									</tr>
									</tbody>
									<tbody>
									<tr>
										<td colSpan="22"><p><span style={{ fontSize: 'small' }}>11. Транспортное средство</span></p></td>
									</tr>
									</tbody>
									<tbody>
									<tr>
										<td/>
										<td colSpan="13"/>
										<td/>
										<td/>
										<td colSpan="5"/>
										<td/>
									</tr>
									<tr>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
										<td colSpan="13" style={{ verticalAlign: 'TOP' }}><p><span style={{ fontSize: 'small' }}>(количество, тип, марка, грузоподъемность (в тоннах),<br/>вместимость (в кубических метрах))</span>
										</p></td>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
										<td colSpan="5" style={{ verticalAlign: 'TOP' }}><p><span
											style={{ fontSize: 'small' }}>(регистрационные номера)</span></p></td>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
									</tr>
									<tr>
										<td/>
										<td colSpan="13"/>
										<td/>
										<td/>
										<td colSpan="5"/>
										<td/>
									</tr>
									<tr>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
										<td colSpan="13" style={{ verticalAlign: 'TOP' }}/>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
										<td colSpan="5" style={{ verticalAlign: 'TOP' }}/>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
									</tr>
									</tbody>
									<tbody>
									<tr>
										<td colSpan="22"><p><span style={{ fontSize: 'small' }}>12. Оговорки и замечания перевозчика</span>
										</p>
										</td>
									</tr>
									</tbody>
									<tbody>
									<tr>
										<td/>
										<td colSpan="9"/>
										<td/>
										<td colSpan="2"/>
										<td colSpan="8"/>
										<td/>
									</tr>
									<tr>
										<td/>
										<td colSpan="9"><p><span style={{ fontSize: 'small' }}>(фактическое состояние груза, тары, упаковки, маркировки<br/>и опломбирования при приеме груза)</span>
										</p></td>
										<td/>
										<td colSpan="2"/>
										<td colSpan="8"><p><span style={{ fontSize: 'small' }}>(фактическое состояние груза, тары, упаковки, маркировки<br/>и опломбирования при сдаче груза)</span>
										</p></td>
										<td/>
									</tr>
									<tr>
										<td/>
										<td colSpan="9"/>
										<td/>
										<td colSpan="2"/>
										<td colSpan="8"/>
										<td/>
									</tr>
									<tr>
										<td/>
										<td colSpan="9"><p><span
											style={{ fontSize: 'small' }}>(изменение условий перевозки при движении)</span></p>
										</td>
										<td/>
										<td colSpan="2"/>
										<td colSpan="8"><p><span
											style={{ fontSize: 'small' }}>(изменение условий перевозки при выгрузке)</span></p>
										</td>
										<td/>
									</tr>
									</tbody>
									<tbody>
									<tr>
										<td colSpan="22"><p><span style={{ fontSize: 'small' }}>13. Прочие условия</span></p></td>
									</tr>
									</tbody>
									<tbody>
									<tr>
										<td/>
										<td colSpan="20"/>
										<td/>
									</tr>
									<tr>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
										<td colSpan="20" style={{ verticalAlign: 'TOP' }}><p><span style={{ fontSize: 'small' }}>(номер, дата и срок действия специального разрешения, установленный маршрут движения тяжеловесного и (или) крупногабаритного транспортного средства,<br/>транспортного средства, перевозящего опасный груз)</span>
										</p></td>
										<td style={{ verticalAlign: 'BOTTOM' }}/>
									</tr>
									<tr>
										<td/>
										<td colSpan="20"/>
										<td/>
									</tr>
									<tr>
										<td/>
										<td colSpan="20"><p><span style={{ fontSize: 'small' }}>(режим труда и отдыха водителя в пути следования, сведения о коммерческих и иных актах)</span>
										</p></td>
										<td/>
									</tr>
									</tbody>
									<tbody>
									<tr>
										<td colSpan="22"><p><span style={{ fontSize: 'small' }}>14. Переадресовка</span></p></td>
									</tr>
									</tbody>
									<tbody>
									<tr>
										<td/>
										<td colSpan="9"/>
										<td/>
										<td colSpan="2"/>
										<td colSpan="8"/>
										<td/>
									</tr>
									<tr>
										<td/>
										<td colSpan="9"><p><span
											style={{ fontSize: 'small' }}>(дата, форма переадресовки (устно или письменно))</span></p></td>
										<td/>
										<td colSpan="2"/>
										<td colSpan="8"><p><span style={{ fontSize: 'small' }}>(адрес нового пункта выгрузки, дата и время подачи<br/>транспортного средства под выгрузку)</span>
										</p></td>
										<td/>
									</tr>
									<tr>
										<td/>
										<td colSpan="9"/>
										<td/>
										<td colSpan="2"/>
										<td colSpan="8"/>
										<td/>
									</tr>
									<tr>
										<td/>
										<td colSpan="9"><p><span style={{ fontSize: 'small' }}>(сведения о лице, от которого получено указание<br/>на переадресовку (наименование, фамилия, имя, отчество и др.))</span>
										</p></td>
										<td/>
										<td colSpan="2"/>
										<td colSpan="8"><p><span style={{ fontSize: 'small' }}>(при изменении получателя груза – новое наименование грузополучателя<br/>и место его нахождения)</span>
										</p></td>
										<td/>
									</tr>
									</tbody>
									<tbody>
									<tr>
										<td colSpan="22"><p><span style={{ fontSize: 'small' }}>15. Стоимость услуг перевозчика и порядок расчета провозной платы</span>
										</p></td>
									</tr>
									</tbody>
									<tbody>
									<tr>
										<td/>
										<td colSpan="5"/>
										<td/>
										<td/>
										<td colSpan="13"/>
										<td/>
									</tr>
									<tr>
										<td/>
										<td colSpan="5"><p><span
											style={{ fontSize: 'small' }}>(стоимость услуги в рублях, порядок (механизм)<br/>расчета (исчислений) платы)</span>
										</p></td>
										<td/>
										<td/>
										<td colSpan="13"><p><span style={{ fontSize: 'small' }}>(расходы перевозчика и предъявляемые грузоотправителю платежи<br/>за проезд по платным автомобильным дорогам,</span>
										</p></td>
										<td/>
									</tr>
									<tr>
										<td/>
										<td colSpan="5"/>
										<td/>
										<td/>
										<td colSpan="13"/>
										<td/>
									</tr>
									<tr>
										<td/>
										<td colSpan="5"><p><span
											style={{ fontSize: 'small' }}>(размер провозной платы (заполняется после<br/>окончания перевозки) в рублях)</span>
										</p></td>
										<td/>
										<td/>
										<td colSpan="13"><p><span style={{ fontSize: 'small' }}>за перевозку опасных грузов, грузов, перевозимых тяжеловесными и (или) крупногабаритными транспортными средствами, уплату таможенных пошлин и сборов,</span>
										</p></td>
										<td/>
									</tr>
									<tr>
										<td/>
										<td colSpan="5"/>
										<td/>
										<td/>
										<td colSpan="13"/>
										<td/>
									</tr>
									<tr>
										<td/>
										<td colSpan="5"/>
										<td/>
										<td/>
										<td colSpan="13"><p><span
											style={{ fontSize: 'small' }}>выполнение погрузо-разгрузочных работ, а также работ<br/>по промывке и дезинфекции транспортных средств)</span>
										</p></td>
										<td/>
									</tr>
									</tbody>
									<tbody>
									<tr>
										<td/>
										<td colSpan="20"/>
										<td/>
									</tr>
									<tr>
										<td/>
										<td colSpan="20"><p><span style={{ fontSize: 'small' }}>(полное наименование организации плательщика (грузоотправителя), адрес, банковские реквизиты<br/>организации плательщика (грузоотправителя))</span>
										</p></td>
										<td/>
									</tr>
									</tbody>
									<tbody>
									<tr>
										<td colSpan="22"><p><span style={{ fontSize: 'small' }}>16. Дата составления, подписи сторон</span>
										</p>
										</td>
									</tr>
									</tbody>
									<tbody>
									<tr>
										<td/>
										<td/>
										<td/>
										<td/>
										<td/>
										<td colSpan="4"/>
										<td colSpan="3"/>
										<td colSpan="5"/>
										<td/>
										<td/>
										<td/>
										<td/>
										<td/>
									</tr>
									<tr>
										<td/>
										<td><p><span style={{ fontSize: 'small' }}>(грузоотправитель</span><span
											style={{ fontSize: 'small' }}> </span><br/><span
											style={{ fontSize: 'small' }}>(грузовладелец)<br/>(уполномоченное лицо))</span>
										</p></td>
										<td/>
										<td><p><span style={{ fontSize: 'small' }}>(дата)</span></p></td>
										<td/>
										<td colSpan="4"><p><span style={{ fontSize: 'small' }}>(подпись)</span></p></td>
										<td colSpan="3"/>
										<td colSpan="5"><p><span
											style={{ fontSize: 'small' }}>(перевозчик<br/>(уполномоченное лицо))</span>
										</p></td>
										<td/>
										<td><p><span style={{ fontSize: 'small' }}>(дата)</span></p></td>
										<td/>
										<td><p><span style={{ fontSize: 'small' }}>(подпись)</span></p></td>
										<td/>
									</tr>
									</tbody>
								</table>

								<table border="1" cellPadding="0" cellSpacing="0" style={{ width: '100%' }}>
									<tbody>
									<tr>
										<td colSpan="3"><p><span style={{ fontSize: 'small' }}>17. Отметки грузоотправителей, грузополучателей, перевозчиков</span>
										</p></td>
									</tr>
									<tr>
										<td><p><span
											style={{ fontSize: 'small' }}>Краткое описание обстоятельств, послуживших основанием для отметки</span>
										</p>
										</td>
										<td><p><span style={{ fontSize: 'small' }}>Расчет и размер штрафа</span></p></td>
										<td><p><span style={{ fontSize: 'small' }}>Подпись, дата</span></p></td>
									</tr>
									<tr>
										<td/>
										<td/>
										<td/>
									</tr>
									<tr>
										<td/>
										<td/>
										<td/>
									</tr>
									<tr>
										<td/>
										<td/>
										<td/>
									</tr>
									<tr>
										<td/>
										<td/>
										<td/>
									</tr>
									<tr>
										<td/>
										<td/>
										<td/>
									</tr>
									<tr>
										<td/>
										<td/>
										<td/>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
					</CustomScroll>
				</div>
				<Button className={styles.buttonContract} disabled={invalid} size="large" type="submit">Подписать договор</Button>
			</form>
		);
	}
}

const selector = formValueSelector('ContractForm');

export default compose(
	reduxForm({
		form: 'ContractForm',
	}),
	connect(
		state => ({
			values: selector(state, 'firstName', 'lastName', 'middleName', 'number', 'address')
		})
	)
)(ContractForm);