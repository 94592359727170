import React from 'react';
import {change, Field, reduxForm, stopSubmit, formValueSelector} from 'redux-form';
import { Exchange } from 'components/UI/Icons';
import { connect } from "react-redux";
import Address2 from 'components/UI/Form/Fields/Address2';
import InputDate from 'components/UI/Form/Fields/InputDate';
import InputGroup from 'components/UI/Wrappers/InputGroup';
import Button from 'components/UI/Form/Button';
import {Lorry} from 'components/UI/Icons';
import style from './style.less';
import { getProfileType } from 'libs/selections';

const selector = formValueSelector('searchForm');

class MainPageSearchForm extends React.Component {

	togglePoints = () => {
		let {point_a, point_b, dispatch} = this.props;
		dispatch(change('searchForm', 'point_b', point_a));
		dispatch(change('searchForm', 'point_a', point_b));
	};

	setValue = (field, value) => {
		this.props.dispatch(change('searchForm', field, value));
	};

	render() {
		
		let { handleSubmit, dispatch, role } = this.props;
		
		return (
			<form className={style.form} onSubmit={handleSubmit} autoComplete="off">
				<div className={style.grid}>
					<InputGroup className={style.relative}>
						<Address2
							setValue={this.setValue}
							dispatch={dispatch}
							name="point_a"
							label="Откуда забрать?"
							onFocus={this.props.onStepOne}
						/>
						<Button onClick={this.togglePoints} className={style.togglePoints}>{ Exchange() }</Button>
						<Address2
							setValue={this.setValue}
							dispatch={dispatch}
							name="point_b"
							label="Куда доставить?"
							onFocus={this.props.onStepTwo}
						/>
					</InputGroup>
					<Field
						name={`date`}
						component={InputDate}
						label="Дата отправления"
					/>
				</div>
				<div className={style.btn}>
					<Button
						color="red"
						size="large"
						type="submit"
					>
						<Lorry/> Найти { role === 'driver' ? 'груз' : 'перевозчика' }
					</Button>
				</div>
			</form>
		);
	}
}

MainPageSearchForm = connect(
	store => ({
		point_a: selector(store, 'point_a'),
		point_b: selector(store, 'point_b'),
		role: getProfileType(store),
	})
)(MainPageSearchForm);

MainPageSearchForm = reduxForm({
	form: 'searchForm',
	onChange: (newValues, dispatch) => {
		dispatch(stopSubmit('searchForm', {}));
	}
})(MainPageSearchForm);

export default MainPageSearchForm;
