import React from 'react';
import language from 'language';
import PropTypes from 'prop-types';
import styles from 'components/Admin/Pages/Arbitrations/List/styles.less';
import { dateToString, dateUTCToClient } from 'libs/convertDate';


const UserItem = ({ el, onClick }) => {
	const date = dateUTCToClient(el.createTime);
	return (
		<tr className={styles.arbitrateItem} onClick={onClick}>
			<td>{ el.id }</td>
			<td>{ language[el.role] }</td>
			<td>{ el.lastName } { el.firstName }</td>
			<td>
				{
					el.isProfileVerification ?
						<span className={styles.verifySuccess}>Верифицирован</span> :
						<span className={styles.verifyFailed}>Не верифицирован</span>
				}
			</td>
			<td>{ dateToString(date, 'default') } в { dateToString(date, 'time') }</td>
		</tr>
	)
};


UserItem.propTypes = {
	el: PropTypes.object.isRequired,
};


export default UserItem;