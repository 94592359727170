exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.M9vC4XH3K0cf5pbppW5Ay {\n  max-width: 270px;\n  grid-column: 2;\n  grid-row-start: 2;\n  grid-row-end: 4;\n}\n.M9vC4XH3K0cf5pbppW5Ay p {\n  margin: 1.5em 0;\n  font-size: 14px;\n}\n@media screen and (max-width: 1200px) {\n  .M9vC4XH3K0cf5pbppW5Ay {\n    display: none;\n  }\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/Contents/style.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;CACjB;AACD;EACE,gBAAgB;EAChB,gBAAgB;CACjB;AACD;EACE;IACE,cAAc;GACf;CACF","file":"style.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.documentText {\n  max-width: 270px;\n  grid-column: 2;\n  grid-row-start: 2;\n  grid-row-end: 4;\n}\n.documentText p {\n  margin: 1.5em 0;\n  font-size: 14px;\n}\n@media screen and (max-width: 1200px) {\n  .documentText {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"documentText": "M9vC4XH3K0cf5pbppW5Ay"
};