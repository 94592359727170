import React from 'react';
import PropTypes from 'prop-types';
import CustomScroll from 'react-custom-scroll';
import { Field } from 'redux-form';
import OffersGroup from 'components/UI/Form/Fields/OffersForChat/OffersGroup';
import { dateToString, serverDateToClientDate } from 'libs/convertDate';
import style from 'components/Chat/style.less';
import { Link } from 'react-router-dom';

export default class LocationPoints extends React.PureComponent {
	static propTypes = {
		offers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
		role: PropTypes.string.isRequired,
		className: PropTypes.string.isRequired,
	};

	render() {
		let { role, offers, className } = this.props,
			options = [];

		switch (role) {
			case 'driver':
				if (offers.active.length > 0)
					options = offers.active.map(el => ({
						value: el,
						id: el.id,
						title: 'Маршрут ' + el.id,
						date: dateToString(serverDateToClientDate(el.createTime), 'default'),
						price: el.price,
						role: 'driver',
					}));
				break;
				case 'customer':
				options = offers.items.map(el => {
					return {
						value: el,
						id: el.id,
						title: `${el.cargo.title}, ${el.cargo.categoryId.titleRu}, ${el.cargo.weight}кг.`,
						date: dateToString(serverDateToClientDate(el.createTime), 'default'),
						price: el.price,
						role: 'customer',
					};
				});
				break;
			default:
				offers = [];
				break;
		}

		// костыль для customScroll - явно указать высоту, чтобы скролл знал точную высоту родителя
		let height = 97 * options.length;
		if (options.length > 2) height = 250;

		let noOffers = { text: 'У вас нет активных маршрутов.', textUrl: 'Добавить новый маршрут', url: '/new/route' };
		if (role === 'customer')
			noOffers = { text: 'У вас нет активных заявок.', textUrl: 'Добавить новую заявку', url: '/new/offer' };

		return (
			<div className={className} style={{height: height + 'px'}} ref={this.props.ref}>
				<CustomScroll flex="1">
				{
					options.length > 0
					&& <Field name="offerIds" component={OffersGroup} options={options} />
				}
				{
					options.length === 0
					&&
					<div className={style.noOffers}>
						<span>{ noOffers.text }</span><Link onClick={this.props.handleClose} to={noOffers.url}>{noOffers.textUrl}</Link>
					</div>
				}
				</CustomScroll>
			</div>
		);
	}
}