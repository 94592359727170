import * as customerActions from 'actions/customer';
import Pagination from "components/UI/Pagination/index";
import RequestLayer from 'components/UI/Request/RequestLayer';
import { getMyCargoes } from 'libs/selections';
import { getItem, removeItem, setItem } from 'libs/sessionStorage';
import React from 'react';
import { connect } from 'react-redux';
import { TabPanel } from 'react-tabs';
import RequestList from './RequestList';
import Loader from 'components/UI/_V2/Loader';


class MyCargo extends React.Component {
    state = {
        selectedTab: 0,
        idForRemove: 0,
        activePage: {
            active: getItem('offer.active', 1),
            cancelled: getItem('offer.cancelled', 1)
        },
        list: [
            {
                question: 'Как скоро начнут появляться предложения?',
                answer: 'Сразу же после создания вашего заказа, он становиться доступным для грузоперевозчиков. Если прописанные вами требования по маршруту, стоимости и параметрам транспорта устраивают грузоперевозчика, он может оставить свое предложение, относительно перевозки вашего груза. Чтобы избежать неактуальных предложений, сделайте свой заказ максимально полным и точным.',
                open: false
            },
            {
                question: 'Как увеличить количество просмотров заявок?',
                answer: 'Наш Сервис предлагает равные конкурентные условия для всех пользователей. Популярность вашего заказа будет определяться только его качеством и степенью соответствия запросам грузоперевозчиков. Чтобы увеличить количество просмотра ваших заявок, изучите опыт других пользователей, предложите конкурентную стоимость и добавьте хороший сопроводительный текст.',
                open: false
            },
            {
                question: 'Как разблокировать мою заявку?',
                answer: 'Если по какой-либо причине, ваша заявка была заблокирована, обратитесь в службу поддержки или в службу «Помощь 24/7». При этом укажите обстоятельства, при каких вы обнаружили блокировку. Специалисты HurryLorry, разберутся в проблеме и решат ее, или сообщат причину блокировки и опишут ваши дальнейшие действия.',
                open: false
            },
        ],
    };
    handleSelect = selectedTab => this.setState({ selectedTab });

    changePage = page => {
        const { selectedTab, activePage } = this.state,
            activeType = selectedTab === 0 ? 'active' : 'cancelled';

        setItem(`offer.${activeType}`, page);

        if (activeType === 'active')
            this.props.dispatch(customerActions.getActiveCargo({ page }));
        else
            this.props.dispatch(customerActions.getCancelCargo({ page }));

        this.setState({
            activePage: {
                ...activePage,
                [`${activeType}`]: page
            }
        })
    };

    componentWillMount() {
        removeItem('searchParams');
    }

    componentDidMount() {
        this.props.dispatch(customerActions.getActiveCargo({ page: this.state.activePage.active }));
        this.props.dispatch(customerActions.getCancelCargo({ page: this.state.activePage.cancelled }))
    }

    render() {
        const { list, selectedTab, activePage } = this.state;
        let activeCargoCount = this.props.cargoes.active.total,
            cancelledCargoCount = this.props.cargoes.cancelled.total,
            requestType = {
                length: activeCargoCount + cancelledCargoCount,
                word: ['заявка', 'заявки', 'заявок']
            },
            tabs = [
                { count: activeCargoCount, title: 'Активные' },
                { count: cancelledCargoCount, title: 'Отклоненные' },
            ];

        return (
            <RequestLayer
                title="Мои заявки"
                tabList={tabs}
                selectedTab={selectedTab}
                onClickTab={i => this.handleSelect(i)}
                requestType={requestType}
                questions={list}
            >
                <TabPanel>
                    {
                        this.props.loadingActive ? <Loader /> : <RequestList type="active"/>
                    }
                </TabPanel>
                <TabPanel>
                    {
                        this.props.loadingCancelled ? <Loader /> : <RequestList type="cancelled"/>
                    }
                </TabPanel>

                {
                    ((selectedTab === 0 && Math.ceil(activeCargoCount / 30) > 1) ||
                    (selectedTab !== 0 && Math.ceil(cancelledCargoCount / 30) > 1)) &&
                    <Pagination
                      showMore={false}
                      pageCount={Math.ceil(selectedTab === 0 ? activeCargoCount / 30 : cancelledCargoCount / 30)}
                      setPage={this.changePage}
                      activePage={parseInt(selectedTab === 0 ? activePage.active : activePage.cancelled)}
                    />
                }

            </RequestLayer>
        );
    }
}


export default connect(
    store => ({
        cargoes: getMyCargoes(store),
        loadingActive: getMyCargoes(store).active.loading,
        loadingCancelled: getMyCargoes(store).cancelled.loading,
    })
)(MyCargo);
