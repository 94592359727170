import React from 'react';
import style from './style.less';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getProfileType } from 'libs/selections';

class Nav extends React.PureComponent {

	static propTypes = {
		className: PropTypes.string,
		isCustomStyles: PropTypes.bool,
		isMobileStyles: PropTypes.bool,
	};

	static defaultProps = {
		className: '',
		isCustomStyles: false,
		isMobileStyles: false,
	};

	render() {
		let userMenu = [];

		if (this.props.profileType === 'driver')
			userMenu = [
				{ id: 1, link: '/search/', title: 'Найти груз' },
				{ id: 2, link: '/feedback/', title: 'Помощь 24/7' },
			];
		else if (this.props.profileType === 'customer')
			userMenu = [
				{ id: 1, link: '/search/', title: 'Найти перевозчика' },
				{ id: 2, link: '/feedback/', title: 'Помощь 24/7' },
			];
		else
			userMenu = [
				{ id: 1, link: '/search/', title: 'Найти перевозчика' },
				{ id: 2, link: '/page/becomecarrier/', title: 'Стать перевозчиком' },
				{ id: 3, link: '/feedback/', title: 'Помощь 24/7' },
			];

		let renderClassName = style.nav;
		if (this.props.isCustomStyles) renderClassName += ' ' + style.navCustom;
		if (this.props.isMobileStyles) renderClassName += ' ' + style.navMobile;
		if (this.props.className) renderClassName += ' ' + this.props.className;

		return (
			<nav className={renderClassName}>
				<ul>
					{
						userMenu.map(el => (
							<li className={style.item} key={el.id}>
								<Link
									to={el.link}
									onClick={this.props.closeMenu}
									className={style.link}
								>
									{ el.title }
								</Link>
							</li>
						))
					}
				</ul>
			</nav>
		);
	}
}
export default connect(
	store => ({
		profileType: getProfileType(store)
	})
)(Nav);