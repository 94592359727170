import { chatActions } from 'actions/chat';
import * as mapAction from 'actions/map';
import { add } from 'actions/notifications';
import { offersActions } from 'actions/offers';
import { saveAs } from 'file-saver';
import forwardTo from 'libs/forwardTo';
import request from 'libs/request';
import { getDetailOffer, getProfileType } from 'libs/selections';
import { statusList } from 'libs/vars';
import cargoServerToClient, { routeServerToRouteClient } from 'mappers/cargoServerToClient';
import offerServerToClient, { TradeServerToClient } from 'mappers/offerServerToClient';
import { call, put, select } from 'redux-saga/effects';
import { getItem } from "libs/sessionStorage";
import * as serverHelpersActions from 'actions/serverHelpersActions';

export function* sendProposal(action) {
    const response = yield call(() =>
        request('post', '/api/1.0/trucking/proposal', action.payload));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'При отправке предложения произошел сбой, либо вы уже отправляли предложение ранее',
            time: 10
        }));
    } else {
        if (action.meta && action.meta.success) action.meta.success();
        yield put(offersActions.getMyProposal({ page: getItem('proposal.active', 1) }));
        yield put(serverHelpersActions.getCounterList());
        yield put(add({
            type: 'success',
            text: 'Предложение отправлено',
            time: 10
        }));
    }
}

export function* readProposal(action) {
    yield call(() =>
        request('post', '/api/1.0/trucking/proposal/' + action.payload + '/mark-read'));

    // if (response.data.errors || response.status !== 200) {
    // 	console.log('Произошла ошибка при отметке предложения');
    // } else {
    //  console.log('Отмечено успешно');
    // }
}

export function* sendRequestEditOffer(action) {
    let role = yield select(getProfileType);

    if (!action.payload.id || role !== 'driver') return false;

    const response = yield call(() =>
        request('post', `/api/1.0/trucking/trade/${action.payload.id}/edit-request`, action.payload));

    if (response.data.errors || response.status !== 200) {
        console.error('Error sendRequestEditOffer: ', response.data.errors);
        yield put(add({
            type: 'error',
            text: 'При отправке замечаний произошел сбой',
            time: 10
        }));
    } else {
        yield put(offersActions.getOfferByID(action.payload.id));
        action.meta.resolve();
        yield put(add({
            type: 'success',
            text: 'Запрос отправлен клиенту',
            time: 10
        }));
    }
}

export function* updateOfferDetailById(action) {
    let detailOffer = yield select(getDetailOffer);
    if (action.payload.tradeId !== undefined) {
        if (parseInt(detailOffer.id) === parseInt(action.payload.tradeId)) {
            yield put(offersActions.getTradeByID(detailOffer.id));
        }
    }
}

export function* getOfferByID(action) { // old получить сделку на странице /profile/offer/{ID}
    const response = yield call(() =>
        request('get', '/api/1.0/trucking/trade/' + action.payload));

    if (response.data.errors || response.status !== 200) {
        forwardTo('/trades/');
        yield put(add({
            type: 'error',
            text: `Сделка №${action.payload} не найдена`,
            time: 10
        }));
    } else {
        let offer = offerServerToClient(response.data);
        offer.customerId = response.data.offer.userId;
        offer.driverId = response.data.userId;
        yield put(offersActions.setDetailOffer(offer));
        yield put(mapAction.setPoints(offer.route.items.map(it => it.address)));
    }
}

export function* getTradeByID(action) {
    const response = yield call(() =>
        request('get', '/api/1.0/trucking/trade/' + action.payload));

    if (response.data.errors || response.status !== 200) {
        // forwardTo('/trades/');
        yield put(add({
            type: 'error',
            text: `Сделка №${action.payload} не найдена`,
            time: 5
        }));
    } else {
        yield put(offersActions.setDetailOffer({
            ...response.data,
            offer: { ...cargoServerToClient(response.data.offer) }
        }));
        let items = routeServerToRouteClient(response.data.offer.route);
        yield put(mapAction.setPoints(items.map(el => el.address)));
    }
}

export function* sendReview(action) {
    const response = yield call(() =>
        request('post', '/api/1.0/review/review', action.payload));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Произошла ошибка при публикации отзыва',
            time: 10
        }));
    } else {
        yield put(add({
            type: 'success',
            text: 'Отзыв успешно опубликован',
            time: 10
        }));
        yield getTradeByID({ payload: action.payload.tradeId }); // need check
    }
}

export function* acceptUploadOfferById(action) {
    const response = yield call(() =>
        request('post', `/api/1.0/trucking/trade/${action.payload}/cargo-load`));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Ошибка подтверждения погрузки',
            time: 10
        }));
    } else {
        yield put(add({
            type: 'success',
            text: 'Погрузка подтверждена',
            time: 10
        }));
        yield getTradeByID({ payload: action.payload });
    }
}

export function* createContract(action) {
    const response = yield call(() =>
        request('post', `/api/1.0/trucking/trade/${action.payload.proposalId}/contract`, action.payload));
    if (response.data.errors || response.status !== 200) {
        action.meta.error();
        yield put(add({
            type: 'error',
            text: 'Ошибка создания договора',
            time: 10
        }));
    } else {
        action.meta.success();
        yield put(add({
            type: 'success',
            text: `Договор по заявке №${action.payload.id} создан и требует подписания`,
            time: 10
        }));
        // yield getOfferByID({payload: action.payload.id});
    }
}

export function* confirmContractCode(action) {
    const response = yield call(() =>
        request('post', `/api/1.0/trucking/trade/${action.payload.proposalId}/contract-confirm`, action.payload));

    if (response.data.errors || response.status !== 200) {
        yield call(() => action.meta.reject(response.data.errors.code));
        yield put(add({
            type: 'error',
            text: 'Ошибка подписи договора',
            time: 10
        }));
    } else {
        yield call(() => action.meta.resolve(true));
        yield put(serverHelpersActions.getCounterList());
        yield put(add({
            type: 'success',
            text: `Сделка по заявке №${action.payload.id} успешно заключена`,
            time: 10
        }));
        forwardTo('/trades/');
    }
}

export function* acceptPayOfferById(action) {
    const response = yield call(() =>
        request('post', `/api/1.0/trucking/trade/${action.payload.tradeId}/pay`, action.payload));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Ошибка подтверждения оплаты',
            time: 10
        }));
        action.meta.reject();
    } else {
        action.meta.resolve(response.data);
    }
}

export function* startRouteById(action) {
    const response = yield call(() =>
        request('post', `/api/1.0/trucking/trade/${action.payload}/start-route`));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Ошибка уведомления о начале поездки',
            time: 10
        }));
    } else {
        yield put(add({
            type: 'success',
            text: 'Поездка начата',
            time: 10
        }));
        yield getTradeByID({ payload: action.payload });
    }
}

export function* endRouteById(action) {
    const response = yield call(() =>
        request('post', `/api/1.0/trucking/trade/${action.payload}/cargo-unload`));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Ошибка уведомления о доставке',
            time: 10
        }));
    } else {
        yield put(add({
            type: 'success',
            text: 'Поездка окончена',
            time: 10
        }));
        yield getTradeByID({ payload: action.payload });
    }
}

export function* offerCompleteById(action) {
    const response = yield call(() =>
        request('post', `/api/1.0/trucking/trade/${action.payload}/complete`));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Ошибка в подтверждении о выполнении заказа',
            time: 10
        }));
    } else {
        yield put(add({
            type: 'success',
            text: 'Выполнение заказа подтверждено',
            time: 10
        }));
        yield getTradeByID({ payload: action.payload });
    }
}

export function* closeOfferById(action) {
    const response = yield call(() =>
        request('post', `/api/1.0/trucking/trade/${action.payload}/cancel`));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Ошибка при отмене сделки',
            time: 10
        }));
    } else {
        yield put(add({
            type: 'success',
            text: 'Сделка отменена',
            time: 10
        }));
        yield getTradeByID({ payload: action.payload });
    }
}

export function* sendArbitration(action) {
    const response = yield call(() =>
        request('post', '/api/1.0/trucking/arbitration', action.payload));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Ошибка создания арбитража',
            time: 10
        }));
    } else {
        yield put(add({
            type: 'success',
            text: 'Арбитраж создан',
            time: 10
        }));
        yield getTradeByID({ payload: action.payload.tradeId });
        yield put(offersActions.getOffers({ page: getItem('trade.active', 1), status: 'active' }));
        yield put(offersActions.getOffers({ page: getItem('trade.arbitrate', 1), status: 'arbitrate' }));
        yield put(chatActions.getDialogs());
    }
}

export function* acceptOfferById(action) {
    const response = yield call(() =>
        request('post', `/api/1.0/trucking/trade/${action.payload}/accept`));

    if (response.data.errors || response.status !== 200) {
        forwardTo('/trades/');
        yield put(add({
            type: 'error',
            text: 'Не удалось заключить сделку',
            time: 10
        }));
    } else {
        yield put(add({
            type: 'success',
            text: 'Сделка заключена',
            time: 10
        }));
        yield getTradeByID({ payload: action.payload });
    }
}

export function* updateOffer(action) {
    if (!action.payload.id) return false;
    // console.log(action.payload);
    // return ;
    const response = yield call(() =>
        request('post', `/api/1.0/trucking/trade/${action.payload.id}/edit`, action.payload));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Не удалось обновить сделку',
            time: 10
        }));
    } else {
        yield put(add({
            type: 'success',
            text: 'Сделка обновлена',
            time: 10
        }));
        action.meta.resolve();
        yield getTradeByID({ payload: action.payload.id });
    }
}

export function* getCargoByID(action) {
    const response = yield call(() =>
        request('get', '/api/1.0/trucking/offer/' + action.payload));

    if (response.data.errors || response.status !== 200) {
        forwardTo('/offers/');
        yield put(add({
            type: 'error',
            text: `Заявка №${action.payload} не найдена`,
            time: 10
        }));
    } else {
        let cargo = cargoServerToClient(response.data);
        yield put(offersActions.setDetailOffer(cargo));
        yield put(mapAction.setPoints(cargo.route.items.map(it => it.address)));
    }
}

export function* getAllOffers(action) {
    let params = {};

    // yield put(offersActions.getActiveOffers({ page: 1 }));
    // yield put(offersActions.getFinishOffers({ page: 1 }));
    // yield put(offersActions.getArbitrateOffers({ page: 1 }));


    if (action.payload && action.payload.status) params.status = statusList[action.payload.status];

    const response = yield call(() =>
        request('get', '/api/1.0/trucking/trade/my', params));

    if (response.data.errors || response.status !== 200) {
        console.error('Error getOffers: ', response.data.errors);
        yield put(offersActions.setOffers([], action.payload));
        yield put(add({
            type: 'error',
            text: 'Ошибка при получении сделок',
            time: 10
        }));
    } else {
        yield put(offersActions.setOffers(response.data.items.map(it => TradeServerToClient(it)), action.payload));
    }
}

export function* getOffers(action) {
    let params = { ...action.payload };
    if (action.payload && action.payload.status) params.status = statusList[action.payload.status];

    const response = yield call(() =>
        request('get', '/api/1.0/trucking/trade/my', params));

    if (response.data.errors || response.status !== 200) {
        console.error('Error getOffers: ', response.data.errors);
        yield put(add({
            type: 'error',
            text: 'Ошибка при получении сделок',
            time: 10
        }));
    } else {
        if (action.payload.status === 'active')
            yield put(offersActions.setActiveOffers({
                ...response.data,
                items: response.data.items.map(it => TradeServerToClient(it))
            }));
        if (action.payload.status === 'finish')
            yield put(offersActions.setFinishOffers({
                ...response.data,
                items: response.data.items.map(it => TradeServerToClient(it))
            }));

        if (action.payload.status === 'arbitrate')
            yield put(offersActions.setArbitrateOffers({
                ...response.data,
                items: response.data.items.map(it => TradeServerToClient(it))
            }));
    }
}


export function* rejectProposal(action) {
    const response = yield call(() =>
        request('post', `/api/1.0/trucking/proposal/${action.payload.proposal}/reject`));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Ошибка при отклонении предложения',
            time: 10
        }));
    } else {
        yield put(offersActions.getOfferByID(action.payload.offer));
        yield put(add({
            type: 'success',
            text: 'Предложение отклонено',
            time: 10
        }));
    }
}

export function* acceptProposal(action) {
    let role = yield select(getProfileType),
        response = undefined;

    if (role === 'customer')
        response = yield call(() =>
                request('post', `/api/1.0/trucking/proposal/${action.payload.proposal}/accept-from-executor`),
            action.payload.data);

    if (role === 'driver')
        response = yield call(() =>
            request('post', `/api/1.0/trucking/proposal/${action.payload.proposal}/accept-from-customer`));


    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Ошибка запроса согласования работы',
            time: 10
        }));
    } else {
        yield put(offersActions.getOffers({ page: getItem('trade.active', 1), status: 'active' }));
        yield call(forwardTo, '/profile/offers/');
        yield put(add({
            type: 'success',
            text: 'Предложение одобрено',
            time: 10
        }));
    }
}

export function* getMyProposal(action) {
    let role = yield select(getProfileType);
    if (role !== 'driver') return false;
    let params = { ...action.payload, status: ['wait_driver','wait_customer'] };

    const response = yield call(() =>
        request('get', '/api/1.0/driver/proposals/my', params));

    if (response.data.errors || response.status !== 200) {
        // yield put(add({
        // 	type: 'error',
        // 	text: 'Ошибка получения заявок',
        // 	time: 10
        // }));
        console.error('Ошибка получения заявок');
    } else {
        yield put(offersActions.setProposalsFromMe(response.data));
    }
}

export function* getTopOffers() {
    const response = yield call(() =>
        request('get', '/api/1.0/trucking/offers'));

    if (response.data.errors || response.status !== 200) {

    } else {
        yield put(offersActions.setTopOffers(response.data.items));
    }
}

export function* removeMyProposal(action) {
    let role = yield select(getProfileType);
    if (role !== 'driver') return false;

    const response = yield call(() =>
        request('post', `/api/1.0/trucking/proposal/${action.payload}/cancel`));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Ошибка отмены предложения',
            time: 10
        }));
    } else {
        yield put(add({
            type: 'success',
            text: 'Ваше предложение было успешно удалено',
            time: 10
        }));
        yield put(offersActions.getMyProposal(offersActions.getMyProposal({ page: getItem('proposal.active', 1) })));
    }
}

export function* downloadContract(action) {
    const response = yield call(() =>
        request('get', `/api/1.0/trucking/trade/${action.payload}/download-contract`, {}, 'blob'));

    if (response) {
        saveAs(response, `contract№${action.payload}.pdf`);
    }
}

export function* cancelTrade(action) {
    const response = yield call(() =>
        request('post', `/api/1.0/trucking/trade/${action.payload}/cancel`));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Произошла ошибка при расторжении сделки',
            time: 10
        }));
    } else {
        yield put(add({
            type: 'success',
            text: 'Сделка успешно отменена',
            time: 10
        }));
        yield put(offersActions.getOffers({ page: getItem('trade.active', 1), status: 'active' }));
        yield put(offersActions.getOffers({ page: getItem('trade.finish', 1), status: 'finish' }));
        yield put(offersActions.getTradeByID(action.payload));
    }
}

export function* insureTrade(action) {
    const response = yield call(() =>
      request('post', `/api/1.0/trucking/trade/${action.payload}/create-new-insurance`));
    console.log(action);
    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Произошла ошибка при страховании груза',
            time: 10
        }));
    } else {
        yield put(add({
            type: 'success',
            text: 'Данные о сделке переданы в страховую компанию',
            time: 10
        }));
        yield put(offersActions.getTradeByID(action.payload));
    }
}
