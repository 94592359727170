import {createActions} from 'redux-actions';

export const {notifications: {add, rm}} = createActions({
	notifications: {
		add: [(payload) => {
			payload.id = new Date().getTime();
			return payload;
		}, (payload, meta) => meta],
		rm: [(payload) => payload, (payload, meta) => meta],
	}
});