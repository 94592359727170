import React, { Fragment } from 'react';
import AdminLoader from 'components/Admin/UI/Loader';
import listStyle from 'components/Admin/UI/List/style.less';
import NotFound from 'components/Admin/UI/List/NotFound';
import Driver from 'components/Admin/Pages/Drivers/List/Driver';


const DriverContent = props => (
	<Fragment>
		{
			props.loading &&
			<AdminLoader className={listStyle.loading} />
		}
		{
			!props.loading && props.list.total > 0 &&
			<Driver users={props.list.items} isVerification={props.isVerification} />
		}
		{
			!props.loading && props.list.total === 0 &&
			<NotFound style={{ paddingLeft: '20px' }} />
		}
	</Fragment>
);


export default DriverContent;