import React from 'react';
import Page from 'components/UI/Wrappers/Page';
import { getItem } from 'libs/localStorage';
import forwardTo from 'libs/forwardTo';
import Warnings from 'components/Pages/Trades/Detail/Warnings';
import ChangeOffer from 'components/ReduxForms/ChangeOffer';
import { getInitialValues } from 'components/Pages/Trades/TradeEdit/lib';
import Grid from 'components/UI/Wrappers/Grid';
import { changeTrade } from 'components/Pages/Trades/TradeEdit/submit';
import { connect } from 'react-redux';

class TradeEdit extends React.Component {

	state = {
		trade: undefined
	};

	componentWillMount() {
		if (getItem('tradeEdit') !== undefined)
			this.setState({ trade: JSON.parse(getItem('tradeEdit')) });
		else
			forwardTo('/trades/');
	}

	returnToTrade = () => {
		forwardTo(`/trade/${this.state.trade.id}`);
	};

	updateOffer = values => {
		changeTrade(values, this.props.dispatch, this.returnToTrade);
	};

	render () {
		let { trade } = this.state;
		let initialValues = getInitialValues(trade);
		return (
			<Page title={`Редактирование сделки №${trade.id}`} history>
				<Warnings
					status={trade.status}
					tradeEdit={this.returnToTrade}
					isTradeEdit
					editMessage={trade.editMessage}
				/>
				<Grid col={'9-3'}>
					<ChangeOffer onSubmit={this.updateOffer} initialValues={initialValues} />
				</Grid>
			</Page>
		)
	}
}

export default connect()(TradeEdit);
