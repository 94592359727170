exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n._3UXP9Pc51LWqa0C3JDS5m6 {\n  cursor: pointer;\n}\n._3UXP9Pc51LWqa0C3JDS5m6:hover {\n  background: #e0e3e6 !important;\n}\n._36zgg2ODFt8MhVryOTVwbV {\n  color: #3fb61f;\n}\n.nk1pOGhjF_JL4QAXA8KSM {\n  color: #cc1c12;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/Admin/Pages/Arbitrations/List/styles.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,gBAAgB;CACjB;AACD;EACE,+BAA+B;CAChC;AACD;EACE,eAAe;CAChB;AACD;EACE,eAAe;CAChB","file":"styles.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.arbitrateItem {\n  cursor: pointer;\n}\n.arbitrateItem:hover {\n  background: #e0e3e6 !important;\n}\n.verifySuccess {\n  color: #3fb61f;\n}\n.verifyFailed {\n  color: #cc1c12;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"arbitrateItem": "_3UXP9Pc51LWqa0C3JDS5m6",
	"verifySuccess": "_36zgg2ODFt8MhVryOTVwbV",
	"verifyFailed": "nk1pOGhjF_JL4QAXA8KSM"
};