import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';

const RequestGridContainer = props => (
	<div className={`${style.RequestGridContainer} ${props.className || ''}`}>{ props.children }</div>
);

RequestGridContainer.propTypes = {
	children: PropTypes.node,
};
RequestGridContainer.defaultProps = {
	children: {},
};

export default RequestGridContainer;
