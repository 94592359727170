import React from 'react';
import styles from '../style.less';
import { convertNumberSpaces } from 'libs/convertNumberSpaces';
import { Link } from 'react-router-dom';
import Button from 'components/UI/Form/Button';
import { ArrowDownNavigate } from 'components/UI/Icons';

const DataForDriver = ({ data, id }) => {
	if (data === undefined || data === null || data.price === undefined || data.distance === undefined) return null;
	return (
		<div className={`${styles.generaldata} ${styles.driverdata}`}>
			<span className={styles.priceSpan}>{ convertNumberSpaces(data.price) } руб.</span>
			{
				data.distance !== null
				&& <span className={styles.distance}>за { convertNumberSpaces(data.distance) } км</span>
			}
			<Link className={styles.more} to={`/cargo/${id}`}>
				<Button color={'white'}>Подробнее { ArrowDownNavigate() }</Button>
			</Link>
		</div>
	)
};


export default DataForDriver;