import PropTypes from 'prop-types';
// import Resizable from "re-resizable";
import React from 'react';
import * as ReactDOM from 'react-dom';
import Button from '../Form/Button';
import { Close } from '../Icons';
import style from './style.less';


export default class Dialog extends React.PureComponent {

    static propTypes = {
        children: PropTypes.node,
        handleClose: PropTypes.func.isRequired,
        className: PropTypes.string,
    };
    
    static defaultProps = {
        children: '',
    };

    componentDidMount() {
        document.body.style.overflow = 'hidden';
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto';
    }

    render() {
        const { children, handleClose, className, customDialogClass } = this.props;
            // height = window.innerHeight * 0.8,
            // width = window.innerWidth;

        return ReactDOM.createPortal(
            <div className={`${style.dialog} ${customDialogClass ? customDialogClass : ''}`}>
                <div onClick={handleClose} className={style.overlay}/>
                {/*<Resizable*/}
                    {/*minWidth={800 > width ? width : 800}*/}
                    {/*maxWidth={width}*/}
                    {/*minHeight={400}*/}
                    {/*maxHeight={height}*/}
                    {/*defaultSize={{ width: 910, height }}*/}
                {/*>*/}
                    <div className={`${style.content} ${className ? className : ''}`}>
                        <Button onClick={handleClose} className={style.close}>{Close()}
                            <span>Закрыть чат</span></Button>
                        <div className={style.body}>
                            {children}
                        </div>
                    </div>
                {/*</Resizable>*/}
            </div>,
            document.getElementById('dialog')
        );
    }
}