import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getReviews } from 'components/Profile/Detail/getData';
import style from 'components/Profile/ProfileView/review.less';
import Review from 'components/Profile/ProfileView/Review';
import { getProfileReviews } from 'libs/selections';
import Loader from 'components/UI/_V2/Loader';


class ProfileReviews extends Component {
	
	state = {
		loading: true,
		params: {
			page: 1,
			pageSize: 10,
			userId: this.props.id,
		}
	};
	
	async componentWillMount() {
		await this.loadReviews();
	}
	
	loadReviews = async () => {
		this.setState({ loading: true });
		
		await getReviews(this.props.dispatch, this.state.params, () => {
			this.setState({ loading: false });
		});
	};
	
	render() {
		
		const { reviews } = this.props;
		const isCustomer = this.props.role === 'customer';
		
		const text = isCustomer ?
			'Вы сможете оставить отзыв после того как вы закончите сотрудничество с клиентом.' :
			'Вы сможете оставить отзыв после того как вы закончите сотрудничество с исполнителем.';
		// todo доделать пагинацию
		return (
			<div id="reviews" className={style.reviews}>
				<h3 className={style.title}>
					Отзывов { this.state.loading ? <Loader size="xl" count={1} /> : reviews.total }
				</h3>
				<p className={style.description}>{text}</p>
				{
					this.state.loading &&
					<Loader />
				}
				{
					!this.state.loading && reviews.total === 0 &&
					<p className={style.empty}>Отзывы отсутствуют.</p>
				}
				{
					!this.state.loading && reviews.total > 0 &&
					reviews.items.map(el => <Review key={el.id} review={el}/>)
				}
			</div>
		)
	}
}


export default connect(
	state => ({
		reviews: getProfileReviews(state),
	})
)(ProfileReviews);