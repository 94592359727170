import * as adminActions from 'actions/admin';
import Page from 'components/UI/Wrappers/Page';
import { getArbitrations } from 'libs/selections';
import React from 'react';
import { connect } from 'react-redux';
import ArbitrateItem from 'components/Admin/Pages/Arbitrations/List/ArbitrateItem';
import Table from 'components/Admin/UI/Table';
import forwardTo from 'libs/forwardTo';


class AdminArbitrateList extends React.Component {

    to = url => {
        forwardTo(url);
    };

    componentWillMount() {
        this.props.dispatch(adminActions.getArbitrations());
    }

    render() {
        return (
            <Page title={'Арбитраж'}>
                <Table names={['№', 'Причина', 'Инициатор', 'Дата создания']}>
                    {
                        this.props.arbitrations !== undefined && this.props.arbitrations.total > 0 &&
                        this.props.arbitrations.items.map(el => (
                            <ArbitrateItem
                                key={el.id}
                                el={el}
                                onClick={() => this.to(`/admin/arbitrate/${el.id}`)}
                            />
                        ))
                    }
                </Table>
            </Page>
        )
    }
}


export default connect(
    state => ({
        arbitrations: getArbitrations(state),
    })
)(AdminArbitrateList);