import React from 'react';
import styles from '../style.less';
// import language from 'language';

const CargoLoading = ({ data }) => {
	if (data === undefined || data === null) return null;
	return (
		<div className={styles.loadingdata}>
			{ data }
			&nbsp;{ data === 'manual' && 'загрузка' }
			{/* доправить на продакшн */}
			<sup />
		</div>
	)
};


export default CargoLoading;