import React from 'react';
import Avatar from '../Avatar';
import UserName from '../UserName';
import Rating from '../Rating';
import style from './style.less';
import shortName from 'libs/shortName';

const renderRouteList = items => {
	// let route = '';
	// items.forEach((el, i) => {
	// 	route += el.locality;
	// 	if (items.length !== i+1) route += ' — ';
	// });
	return items[0].locality + ' — ' + items[items.length - 1].locality;
};

const Review = props => {
	const { review } = props;
	const { user } = review;
	return (
		<div className={style.review}>
			<div className={style.header}>
				<Avatar className={style.avatar} src={user.photo} />
				<div>
					<UserName className={style.username} name={shortName(user.firstName, user.lastName)} />
					<Rating text="Оценка" count={review.generalRating} />
				</div>
			</div>
			<div className={style.body}>
				<p>{ review.title }</p>
				<p>{ review.text }</p>
			</div>
			<div className={style.footer}>
				<div className={style.route}>Маршрут: { renderRouteList(review.trade.offer.route) }</div>
				<div className={style.info}>
					<div className={style.requestID}>Заявка №{ review.trade.offer.id }</div>
				</div>
			</div>
		</div>
	)
};

export default Review;