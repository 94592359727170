import React from 'react';
import { connect } from 'react-redux';
import { setCar } from 'components/AddRoute/submit';
import Page from 'components/UI/Wrappers/Page';
import Grid from 'components/UI/Wrappers/Grid';
import {LabelError} from 'components/UI/Form/Labels';
import Panel from 'components/UI/Wrappers/Panel';
import CreateCarReduxForm from 'components/ReduxForms/CreateCar';
import { Redirect } from 'react-router-dom';
import { getBodyTypesListStore, getLoadTypesListStore, getProfile } from 'libs/selections';

class CreateCar extends React.PureComponent {
	state = {
		carInit: window.editCar ? window.editCar : { },
		success: false,
	};

	componentDidMount() {
		window.editCar = undefined;
	}

	redirectToCars = () => {
		this.setState({ success: true });
	};

	handleCreateCar = values => {
		return setCar(values, this.props.dispatch, this.redirectToCars);
	};

	render() {
		if (this.state.success)
			return <Redirect to="/cars" />;

		const title = this.state.carInit.id ? 'Редактирование транспорта' : 'Добавление нового транспорта',
			{profile} = this.props;

		let initialState = {
			...this.state.carInit,
			loadTypeId: this.state.carInit.loadTypeId && this.state.carInit.loadTypeId.id,
			bodyTypeId: this.state.carInit.bodyTypeId && this.state.carInit.bodyTypeId.id,
		};

		return (
			<Page title={title}>
				<Grid col="10-2">
					<Panel title="Информация о транспорте">
						{(!profile || !profile.license || !profile.driver || !profile.driver.city || !profile.driver.experienceYears) &&
							<LabelError message="Перед созданием машины заполните свой профиль"/>
						}
						<CreateCarReduxForm
							bodyTypes={this.props.bodyTypes}
							loadTypes={this.props.loadTypes}
							initialValues={initialState}
							onSubmit={this.handleCreateCar}
						/>
					</Panel>
				</Grid>
			</Page>
		);
	}
}

export default connect(
	store => ({
		profile: getProfile(store),
		bodyTypes: getBodyTypesListStore(store),
		loadTypes: getLoadTypesListStore(store),
	})
)(CreateCar);
