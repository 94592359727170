import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';

const ScaleFill = props => {
	let percent = Math.round(props.percent);

	if (percent > 100) percent = 100;
	if (percent < 0) percent = 0;

	return (
		<div className={style.progress_bar}>
			<div className={style.scale} style={{width: `${percent}%`}} />
			<div className={style.text}>{percent}% из 100%</div>
		</div>
	)
};

ScaleFill.propTypes = {
	percent: PropTypes.number.isRequired,
};

export default ScaleFill;
