import {createActions} from 'redux-actions';

export const {map:
	{
		setPoint,
		setPoints,
		setDistance,
		rmPointByKey,
		removeAll,
		removeBetweenPoint,
		setPointsToABC,
	}
} = createActions({
	map: {
		setPoint: [(payload) => payload, (payload, meta) => meta],
		setDistance: [(payload) => payload, (payload, meta) => meta],
		setPoints: [(payload) => payload, (payload, meta) => meta],
		setPointsToABC: [(payload) => payload, (payload, meta) => meta],
		rmPointByKey: [(payload) => payload, (payload, meta) => meta],
		removeBetweenPoint: [() => null, (payload, meta) => meta],
		removeAll: [() => null, (payload, meta) => meta],
	}
});