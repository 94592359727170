import React from 'react';
import style from 'components/UI/_V2/Proposals/Detail/style.less';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Unlink } from 'components/UI/Icons';
import { dateToString, serverDateToClientDate } from 'libs/convertDate';

const ProposalOffer = ({ offer }) => {
	
	if (offer === null)
		return (
			<div className={style.proposal__offer}>
				<div className={style.proposal__offer_deleted}>
					Заявка удалена
				</div>
			</div>
		);
	
	return (
		<div className={style.proposal__offer}>
			<Link to={`/offer/${offer.id}`}>
				<Unlink customClass={style.proposal__attachment}/>
				<span>
					Заявка №{offer.id} от{' '}
					{ dateToString(serverDateToClientDate(offer.createTime), 'default') },{' '}
					{ offer.cargo.title }, { offer.cargo.categoryId && offer.cargo.categoryId.titleRu }, { offer.cargo.weight }кг.
				</span>
			</Link>
		</div>
	);
};


ProposalOffer.propTypes = {
	className: PropTypes.string,
	isShowLink: PropTypes.bool,
};

ProposalOffer.defaultProps = {
	className: '',
	isShowLink: false,
};

export default ProposalOffer;