import React from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import Textarea from 'components/UI/Form/Fields/Textarea';
import Button from 'components/UI/Form/Button';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import { connect } from 'react-redux';
import { compose } from 'redux';
import asyncValidate from 'libs/asyncValidate';


class ModerationCancelledForm extends React.Component {
  
  static propTypes = {
    handleSubmit: PropTypes.func,
    error: PropTypes.string,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    buttonTxt: PropTypes.string,
  };
  
  static defaultProps = {
    handleSubmit: () => {},
    invalid: false,
    submitting: false,
    error: '',
    buttonTxt: 'Отклонить'
  };
  
  isEnabledSubmit = () => {
    return this.props.description !== '' && this.props.description !== undefined
  };
  
  render () {
    
    const { handleSubmit, invalid } = this.props;
    
    const isEnabledSubmit = this.isEnabledSubmit();
    
    return (
        <form
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{ maxWidth: '600px', marginTop: '40px' }}
        >
          <p style={{ marginBottom: '5px' }}>Если вас что-то не устраивет, отпишите здесь</p>
          <LayoutField>
            <Field
                name="description"
                component={Textarea}
                label="Напишите причину отказа"
            />
          </LayoutField>
          
          <Button
              disabled={invalid || !isEnabledSubmit}
              loading={this.props.submitting}
              type={'submit'}
              color={'red'}
          >
            { this.props.buttonTxt }
          </Button>
        </form>
    );
  }
}

const selector = formValueSelector('cancelledForm');

export default compose(
    reduxForm({
      form: 'cancelledForm',
      asyncValidate,
      asyncBlurFields: ['description'],
    }),
    connect(
        state => ({
          description: selector(state, 'description')
        })
    )
)(ModerationCancelledForm);

/*
* Форма содержит:
1. Выберите отдел - select с одним из возможных значений:
    1.1. Отдел по работе с клиентами
    1.2. Отдел по работе с перевозчиками
    1.3. Отдел технической поддержки
    1.4. Отдел маркетинга
    1.5. Сотрудничество
2. Напишите сообщение - textarea
3. Ваши данные - input (string),
4. Телефон с маской
5. Email
*/
