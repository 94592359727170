const QuestionList = type => {
	
	const list = {
		aboutHurryLorry: [
			{
				question: 'Что такое сервис HurryLorry',
				answer: [
					'Сервис HurryLorry – это Интернет-ресурс, транспортная биржа, на которой независимые перевозчики и грузоотправители договариваются между собой предоставлении транспортных услуг.',
					'HurryLorry обеспечивает содействие в поиске необходимого транспорта или груза, создает возможность для коммуникации сторон, и сопровождает весь процесс сделки, включая оплату и обратную связь по результатам доставки.'
				],
				open: false
			},
			{
				question: 'Возможности отправителя',
				answer: [
					'HurryLorry предоставляет отправителю груза набор таких возможностей:',
					[
						'создание заявки на перевозку груза и ее размещение на бирже;',
						'поиск перевозчиков и способов доставки с требуемыми параметрами по ценам, срокам и маршруту;',
						'определение расстояний между пунктами маршрута доставки;',
						'прямой активный чат между пользователями сервиса;',
						'формирование рейтинга надежности грузоперевозчиков по отзывам заказчиков;',
						'гарантия безопасности сделки.'
					]
				],
				open: false
			},
			{
				question: 'Возможности грузоперевозчика',
				answer: [
					'HurryLorry предоставляет следующий комплекс возможностей для грузоперевозчиков:',
					[
						'доступ к заказам на перевозку грузов сразу после регистрации на сервисе;',
						'быстрый поиск подходящего заказа через систему встроенных фильтров;',
						'возможность согласования условий сделки (сроки, оплата, объемы, маршрут) непосредственно с отправителем;',
						'онлайн подтверждение принятия условий сделки;',
						'гарантия оплаты за доставку груза.',
					]
				],
				open: false
			},
		],
		general: [
			{
				question: 'Как зарегистрироваться на сайте',
				answer: [
					'На HurryLorry отправители и перевозчики грузов подлежат обязательной регистрации. Эта процедура бесплатна.',
					'Для регистрации аккаунта следует кликнуть «Войти» в правом верхнем углу. Откроется окно с активной кнопкой «Зарегистрироваться».',
					'Нажав на нее, вы перейдете в форму создания аккаунта отправителя. Для регистрации в качестве грузоперевозчика, нажмите на надписи «Я водитель и хочу заняться перевозкой грузов» внизу формы регистрации. Формы – идентичны, в любом статусе вы должны указать имя, фамилию, email, номер телефона, пароль.',
					'После заполнения всех полей, нажмите на кнопку «Зарегистрироваться». Сервис направит вас на страницу подтверждения регистрации путем введения кода, полученного в смс-сообщении по указанному номеру телефона. Введите полученный в смс-сообщении код. Вы – зарегистрированы.',
				],
				open: false
			},
			{
				question: 'Не могу зайти в свой аккаунт',
				answer: [
					'Проблемы входа в аккаунт могут иметь несколько причин:',
					[
						'некорректный пароль – убедитесь, что вы вводите правильный пароль, проверьте раскладку клавиатуры и регистр, если такие действия не помогают – восстановите пароль;',
						'заблокированный профиль – вход в систему ограничен по причине нарушения правил сервиса. Чтобы разблокировать аккаунт, обратитесь в техподдержку, кликнув на «Помощь 24/7».',
					],
					'Если вы не нашли решения проблемы входа на сервис, обращайтесь на почту SUPPORT@HURRYLORRY.COM или на страницу техподдержки «Помощь 24/7».'
				],
				open: false
			},
			{
				question: 'Как восстановить или изменить пароль',
				answer: [
					'Возможность восстановления пароля доступна в любой форме авторизации на сервисе. Для этого используется кнопка «Забыли пароль?». Нажав на нее, вы переходите в форму, где можете ввести новый пароль.',
					'Также вы можете изменить пароль в любой момент после окончания регистрации на сайте, используя форму изменения пароля в своем Личном кабинете.'
				],
				open: false
			},
			{
				question: 'Как изменить номер телефона указанный при регистрации',
				answer: 'Аккаунт пользователя сервиса HurryLorry привязан к номеру его телефона. Для изменения номера телефона при работе с сервисом обратитесь с соответствующим запросом на почту SUPPORT@HURRYLORRY.COM или воспользуйтесь формой обратной связи на странице «Помощь 24/7».',
				open: false
			},
			{
				question: 'Как удалить профиль',
				answer: 'Пользователь сервиса HurryLorry может удалить свой профиль, заполнив соответствующий запрос на почту SUPPORT@HURRYLORRY.COM или через страницу обратной связи «Помощь 24/7».',
				open: false
			},
			{
				question: 'В каких случаях предусмотрена блокировка пользователя?',
				answer: [
					'Администрация сервиса HurryLorry имеет право частично или полностью блокировать (ограничивать) доступ пользователей к функционалу сервиса или удалять их аккаунт.',
					'Основания для блокировки или удаления аккаунта пользователя:',
					[
						'нарушение условий Пользовательского Соглашения между HurryLorry и пользователем сервиса;',
						'нарушение законодательства Российской Федерации;',
						'нарушение норм нравственности и морали;',
						'сговор с другими пользователями Сервиса HurryLorry с целью нарушения законодательства Российской Федерации или условий Пользовательского Соглашения.'
					]
				],
				open: false
			},
			{
				question: 'Как связаться со службой поддержки',
				answer: 'Для оперативной связи пользователей с Администрацией Сервиса HurryLorry используйте почту службы поддержки SUPPORT@HURRYLORRY.COM или страницу обратной связи «Помощь 24/7».',
				open: false
			},
			{
				question: 'Куда обратиться, если Вы направили запрос в службу Поддержки, но не получили ответа?',
				answer: [
					'Служба поддержки Сервиса HurryLorry всегда отвечает на вопросы пользователей, адресованные через почту SUPPORT@HURRYLORRY.COM или форму обратной связи на странице «Помощь 24/7».',
					'В течение 7 дней от даты отправления вопроса или запроса, ответ придет на вашу почту, указанную при регистрации Вашего аккаунта на Сервисе.',
					'Если по истечении 7 дней после отправки вопроса или запроса, Вы не получили ответ, проверьте правильность адреса электронной почты, указанного при регистрации на Сервисе.',
					'У Вас нет необходимости повторять или дублировать вопрос, адресованный Службе поддержки. Все Ваши вопросы автоматически регистрируются и обязательно рассматриваются в порядке очереди.'
				],
				open: false
			},
			{
				question: 'Где прочитать Правила сайта HurryLorry',
				answer: 'Правила работы сайта HurryLorry прописаны в Пользовательском соглашении, доступном по ссылке http://hurrylorry.com/agreement_hl.pdf.',
				open: false
			},
			{
				question: 'Гарантия безопасности банковской карты',
				answer: [
					'Все финансовые операции при работе с Сервисом HurryLorry производятся с использованием банковской карты.',
					'Обработка платежа по любому заказу происходит на сертифицированной странице процессинговой системы, защищенной от внешнего влияния.',
					'Исходя из этого, конфиденциальные платежные данные Пользователя (регистрационные данные, реквизиты карты и коды подтверждения) не проходят через Сервис HurryLorry. Они обрабатываются в защищенной системе, изолированной от Сервиса. Это значит, что никто, включая Администрацию Сервиса HurryLorry, не может получить доступ к персональным и банковским данным Пользователя.',
					'В работе с банковскими данными Пользователя, используется стандарт защиты информации, разработанный международными платёжными системами Visa и MasterCard - Payment Card Industry Data Security Standard (PCI DSS). Это гарантирует безопасность обработки реквизитов Банковской карты Держателя. Применяемая технология передачи данных гарантирует безопасность по сделкам с Банковскими картами путем использования протоколов Secure Sockets Layer (SSL), Verified by Visa, Secure Code, и закрытых банковских сетей, имеющих высшую степень защиты.'
				],
				open: false
			},
		],
		chat: [
			{
				question: 'Как работает личный чат',
				answer: [
					'Чат позволяет обеспечивать коммуникацию между исполнителем, заказчиком и модератором, а также служит информационным каналом для ознакомления пользователей с новостями Сервиса.',
					'Доступ к чату Вы получаете в любом из следующих случаев:',
					[
						'перевозчик ответил на ваш заказ, размещенный на Сервисе, создав свое предложение. Начинать общение может только заказчик;',
						'заказ перешел в статус «выполняется», после подтверждения условий обеими сторонами. В этом случае начать общение может как выбранный перевозчик, так и заказчик;',
						'после первого сообщения от модератора, Вы можете в любой момент начать с ним чат по любым интересующим Вас вопросам работы Сервиса.'
					]
				],
				open: false
			},
		],
		situations: [
			{
				question: 'Что такое арбитраж и почему задания могут в него попасть',
				answer: [
					'Если, после заключения сделки на Сервисе, отправитель и грузоперевозчик не могут достичь согласия в вопросе установки факта выполнения заказа, то решение относительно его статуса принимается Арбитражем Сервиса. Роль Арбитража выполняют сотрудники службы поддержки. Для этого они изучают условия утвержденного сторонами заказа, их переписку, аргументы, и выносят окончательное решение. За результатами решения заказ закрывается со статусом «выполнен» или «не выполнен».',
					'Работа Арбитража производится в соответствии с Арбитражной процедурой Арбитражного суда РФ.',
					'В случае невозможности связаться с одним из участников, Арбитраж принимает решение на основании информации, которую удалось собрать.',
				],
				open: false
			},
			{
				question: 'Отрицательные отзывы',
				answer: [
					'По результатам выполнения заказа доставки груза на Сервисе, отправитель и грузоперевозчик оставляют отзыв, в котором излагают свою оценку действий друг друга. Отзыв является объектом интеллектуальной собственности пользователя и не может изменяться без его ведома и согласия. Модерация и корректировка отзыва допускается только в том случае, если будет доказана неправдивая информация, оскорбление или заведомая ложь, содержащаяся вотзыве.',
					'Если по каким-то причинам, вы получили отрицательный отзыв, но вы не согласны с ним, сделайте следующее:',
					[
						'свяжитесь с заказчиком и попробуйте объяснить свою позицию;',
						'если заказчик не идет на контакт, обратитесь в службу поддержки и получите консультацию относительно дальнейших действий;',
						'в отдельных, особо сложных случаях, Вашу информацию могут переадресовать в Арбитраж Сервиса.'
					],
					'Все отрицательные отзывы на Сервисе проходят обязательную модерацию, в процессе которой мы связываемся с адресатом и уточняем детали. Чтобы отстоять свое право на исправление негативного отзыва, максимально документируйте процесс выполнения сделки – делайте скриншоты переписки, фотографируйте факт и качество выполнения заказа.'
				],
				open: false
			},
		],
		security: [
			{
				question: 'Сделка без риска',
				answer: [
					'Сделка без риска – один из главных принципов работы Сервиса HurryLorry. Для его реализации мы используем систему расчета, в которой выступаем посредниками в финансовых вопросах и гарантируем справедливое перечисление денег без рисков для сторон соглашения.',
					'При этом, после согласования условий сделки, оговоренная сумма списывается со счета заказчика на специальный счет нашего Сервиса, где деньги сохраняются до выполнения заказа. После подтверждения выполнения заказа обеими сторонами, зарезервированные деньги поступают на счет грузоперевозчика.',
					'В случае невыполнения доставки, зарезервированная сумма возвращается на счет заказчика.',
					'Спорные ситуации рассматриваются Арбитражем Сервиса.',
				],
				open: false
			},
			{
				question: 'Проверки на сервисе',
				answer: [
					'Для повышения надежности работы Сервиса и доверия пользователей друг к другу, администрация HurryLorry осуществляет процедуру верификации – проверки подлинности данных пользователей, оказывающих транспортные услуги.',
					'Проверке подлежат документы подтверждающие личность и водительские навыки пользователя оказывающего транспортные услуги (паспорт, водительские права), а также оставленные им контактные данные (номер телефона, e-mail).',
				],
				open: false
			},
			{
				question: 'Советы исполнителям',
				answer: [
					'Зарегистрированные на сервисе грузоперевозчики защищены от недобросовестных заказчиков, за счет реализуемого на Сервисе принципа Сделки без риска. Поэтому избегайте дополнительных сделок с заказчиками вне Сервиса.',
					'Если заказчик просит предоставить ему дополнительную услугу, не прописанную в условиях опубликованной на Сервисе сделки, попросите его оформить дополнительную сделку через Сервис HurryLorry или же осуществить предоплату на Вашу банковскую карту. Если заказчик не соглашается и шантажирует Вас негативным отзывом, Вам следует обратиться в службу поддержки Сервиса.',
					'Будьте осторожны с заданиями, которые требуют установки мобильных приложений, отправки смс-сообщений, передачи Ваших личных или банковских данных.',
					'Перед заключением сделки изучайте профиль заказчика. Обращайте внимание на следующее:',
					[
						'время его регистрации на Сервисе (чем дольше он на сервисе, тем больше можно ему доверять);',
						'отзывы других исполнителей о нем;',
						'заказы, которые он создает.'
					]
				],
				open: false
			},
			{
				question: 'Советы заказчикам',
				answer: [
					'HurryLorry создает условия для защиты заказчиков от недобросовестных грузоперевозчиков и мошенников, поэтому следуйте нашим рекомендациям при работе на Сервисе:',
					'Изучайте профиль исполнителя до его утверждения, обращайте внимание на:',
					[
						'его статус (проверен или непроверен);',
						'количество позитивных и негативных отзывов, их содержание;',
						'дату регистрации на Сервисе (чем раньше зарегистрирован, тем больше опыт его работы с заказами).'
					],
					'Статус «проверен» свидетельствует о том, что Администрация Сервиса подтверждает реальность его паспортных данных, действительность его водительского удостоверения, а также его отсутствие в базе разыскиваемых преступников. Непроверенный перевозчик подтвердил только свои контактные данные (номер телефона и e-mail).',
					'По возможности фиксируйте условия сотрудничества и процесс выполнения заказа на бумаге в форме договора или расписки. При создании заказа Вы можете дополнить условия, указав необходимость наличия паспорта исполнителя для оформления таких документов.',
					'Если выполнять заказ приехал не тот перевозчик, с которым Вы заключили соглашение, откажитесь от сделки и сообщите в службу поддержки Сервиса.',
				],
				open: false
			},
		],
		customer: [
			{
				question: 'Ознакомление с условиями',
				answer: 'Перед началом работы на Сервисе HurryLorry ознакомьтесь с «Правилами работы сайта» и «Политикой конфиденциальности». После прохождения процедуры регистрации на Сервисе, пользователь автоматически принимает условия «Пользовательского Соглашения» и указанные в нем права и обязанности, связанные с использованием функционала сервиса.',
				open: false
			},
			{
				question: 'Прохождение регистрации',
				answer: [
					'На HurryLorry отправители грузов подлежат обязательной регистрации. Эта процедура бесплатна.',
					'Для регистрации аккаунта следует кликнуть «Войти» в правом верхнем углу. Откроется окно с активной кнопкой «Зарегистрироваться».',
					'Нажав на нее, вы перейдете в форму создания аккаунта отправителя, где следует указать имя, фамилию, email, номер телефона, пароль.',
					'После заполнения всех полей, нажмите на кнопку «Зарегистрироваться». Сервис направит вас на страницу подтверждения регистрации путем введения кода, полученного в смс-сообщении по указанному номеру телефона. Введите полученный в смс-сообщении код. Вы – зарегистрированы.',
				],
				open: false
			},
			{
				question: 'Заполнение личных данных',
				answer: [
					'После успешной регистрации на Сервисе, система направит вас на страницу «Мой профиль».',
					'Заполните все поля профиля:',
					[
						'дополните контактные данные;',
						'загрузите свою фотографию;',
						'заполните паспортные данные, включая адрес регистрации;',
						'загрузите скан-копию паспорта;',
						'добавьте реквизиты банковской карты, с которой будет осуществляться оплата услуг перевозчиков.',
					],
				],
				open: false
			},
			{
				question: 'Создание заявки',
				answer: [
					'Авторизуйтесь на Сервисе. Для создания заявки, нажмите кнопку «+ Добавить груз» на странице Сервиса. Вам откроется форма создания заявки на перевозку. Она состоит из нескольких блоков:',
					[
						'Информация о загрузке – обозначается буквой «А» (пункт А) и требует заполнения полей с адресом места загрузки, датой и временем загрузки, названием и номером телефона грузоотправителя. Если Вы являетесь отправителем груза, проставьте отметку в соответствующем поле, тогда название и номер телефона грузоотправителя будут заполнены автоматически;',
						'Информация о разгрузке – обозначается буквами B-F (максимально доступное количество пунктов разгрузки – 5). В начальной форме доступен только пункт В. Для добавления следующего пункта разгрузки, нажмите кнопку «+ Добавить еще один адрес». В полях информации о разгрузке каждого пункта, Вы должны указать адрес разгрузки, дату и время, до которого требуется осуществить доставку, название и номер телефона грузополучателя;',
						'Информация о грузе – требует заполнения таких полей: тип груза, название товара, вес груза, количество грузовых мест, вид упаковки, габариты груза, дополнительные услуги (разгрузка, раскомплектация и т.д.).',
						'Параметры транспорта – выберете требуемый тип кузова (открытый, тентованный) и тип погрузки (с использованием крана или ручная погрузка);',
						'Стоимость перевозки с указанием рекомендованной стоимости в рублях. Вы можете согласиться с предложенной суммой или подкорректировать ее, но не больше, чем на 40 %. В этом же блоке размещена отметка о том, что Ваш груз застрахован. Для более четкого понимания и уточнения задания, оставьте детальный комментарий к заданию в соответствующем поле.',
					],
					'Внимательно заполняйте все поля, чтобы избежать недоразумений. Проверяйте контактные данные, чтобы грузоперевозчик мог связаться с отправителем или получателями в процессе выполнения заказа.',
					'После проставления отметки в поле «Я согласен с правилами сайта» и нажатия на кнопку «Разместить заявку», Ваша заявка будет создана.',
					'После создания заявки, она станет доступна для грузоперевозчиков, и они смогут оставлять свои предложения, относительно перевозки Вашего груза.',
				],
				open: false
			},
			{
				question: 'Поиск перевозчиков',
				answer: [
					'На Сервисе доступно два варианта поиска перевозчиков:',
					[
						'выбрать из тех перевозчиков, кто оставил свое предложение на опубликованную вами заявку;',
						'воспользоваться формой поиска перевозчиков.',
					],
					'При выборе перевозчика из числа тех, кто оставил свое предложение на вашу заявку, Вы можете просматривать их условия, профиля, отзывы, а также написать в чат и уточнять интересующие детали. После получения необходимой информации, если среди полученных предложений есть вариант, который Вас устраивает, вы нажимаете на кнопку «Выбрать исполнителем».',
					'Для поиска перевозчика через специальную форму, нажмите надпись «Найти перевозчика» на странице Сервиса. Вам откроется форма, в которой следует указать адреса отправки и доставки груза, а также дату загрузки. Для более точной детализации поиска нажмите надпись «Расширенный поиск». В полях, которые появятся, Вы сможете указать дополнительные требования к транспортному средству (грузоподъемность, объем кузова, тип кузова, тип погрузки, диапазон транспортных тарифов).',
					'После подтверждения параметров поиска, Сервис подберет для Вас грузоперевозчиков, которые соответствуют указанным требованиям. Нажав на любой из вариантов, Вы можете просмотреть их параметры и связаться с перевозчиком. Для этого нажмите на кнопку «Отправить предложение о перевозке». В форме, которая появится, выберите необходимую заявку (или создайте ее), а также оставьте свой комментарий. После согласования условий, Вы сможете выбрать одного из перевозчиков исполнителем заказа.',
				],
				open: false
			},
			{
				question: 'Как предпочтительнее искать перевозчика груза: путем публикации задания или по заданным параметрам?',
				answer: [
					'Вы можете искать исполнителя заказа любым из указанных способов, учитывая их различия.',
					'При поиске перевозчика груза путем публикации заказа, Вы сможете выбрать исполнителя только из тех, кто откликнется на ваше предложение. При этом, среди полученных предложений могут быть такие, которые не соответствуют вашему запросу.',
					'Поиск по заданным параметрам предложит Вам только те варианты, которые отвечают указанным Вами требованиям к маршруту и транспортному средству.',
					'В любом случае необходимо создавать заявку на перевозку, поэтому мы предлагаем совмещать два варианта поиска. Это повышает Ваши шансы найти исполнителя для своего заказа.',
				],
				open: false
			},
			{
				question: 'На что обратить внимание при поиске перевозчика?',
				answer: [
					'Выбирая перевозчика для своего заказа, обращайте внимание на следующие аспекты:',
					[
						'статус профиля (проверен, непроверен) – проверенные исполнители подтвердили свои паспортные данные, наличие и категорию водительского удостоверения, отсутствие проблем с законом;',
						'наличие опыта перевозок определенных категорий грузов;',
						'отзывы о перевозчике – каждый перевозчик получает отзыв от заказчика только после реального выполнения заявки на транспортировку. Поэтому, отзывы реально характеризуют перевозчиков и их умение работать на Сервисе;',
						'время регистрации перевозчика – чем дольше пользователь на сайте, тем больше вероятность того, что он хорошо знает правила работы сервиса и умеет работать с заказами.',
					]
				],
				open: false
			},
			{
				question: 'Как внести изменения в опубликованное задание?',
				answer: [
					'До момента выбора исполнителя, Вы можете внести изменения в заказ, откорректировав его стоимость или условия. Для этого воспользуйтесь опцией «Редактировать» в форме заказа, внесите необходимые корректировки и сохраните актуальную версию.',
				],
				open: false
			},
			{
				question: 'Перевозчик не приступил к выполнению задания.',
				answer: [
					'Если после утверждения исполнителя заказа и резервирования оплаты на Сервисе, перевозчик не приступил к выполнению задания в условленное время, обратитесь в Арбитраж.',
					'После аннулирования сделки, оплата за выполнение заказа будет возвращена на ваш счет и Вы сможете выбрать нового исполнителя.'
				],
				open: false
			},
			{
				question: 'Повреждение груза в результате транспортировки.',
				answer: [
					'Размещая заказ на транспортировку на Сервисе, в соответствии с Пользовательским соглашением, Вы подтверждаете, что Ваш груз застрахован. Исходя из этого, повреждения груза в результате транспортировки или другой ущерб, компенсируются страховой компанией в соответствии с условиями договора.',
				],
				open: false
			},
		],
		driver: [
			{
				question: 'Ознакомление с условиями',
				answer: 'Перед началом работы на Сервисе HurryLorry ознакомьтесь с «Правилами работы сайта» и «Политикой конфиденциальности». После прохождения процедуры регистрации на Сервисе, пользователь автоматически принимает условия «Пользовательского Соглашения» и указанные в нем права и обязанности, связанные с использованием функционала сервиса.',
				open: false
			},
			{
				question: 'Прохождение регистрации',
				answer: [
					'На HurryLorry отправители грузов подлежат обязательной регистрации. Эта процедура бесплатна.',
					'Для регистрации аккаунта следует кликнуть «Войти» в правом верхнем углу. Откроется окно с активной кнопкой «Зарегистрироваться».',
					'Нажав на нее, вы перейдете в форму создания аккаунта отправителя.',
					'Для регистрации в качестве грузоперевозчика, нажмите на надписи «Я водитель и хочу заняться перевозкой грузов» внизу формы регистрации.',
					'В открывшейся форме укажите свои имя, фамилию, email, номер телефона, пароль.',
					'После заполнения всех полей, нажмите на кнопку «Зарегистрироваться». Сервис направит вас на страницу подтверждения регистрации путем введения кода, полученного в смс-сообщении по указанному номеру телефона. Введите полученный в смс-сообщении код. Вы – зарегистрированы.',
				],
				open: false
			},
			{
				question: 'Заполнение личных данных',
				answer: [
					'После успешной регистрации на Сервисе, система направит вас на страницу «Мой профиль». Вам необходимо заполнить три вкладки профиля:',
					[
						'личные данные – контактные данные, город базирования транспорта, стаж вождения, знание языков, реквизиты банковской карты для получения оплаты от заказчиков;',
						'данные паспорта, включая адрес регистрации;',
						'данные удостоверения, включая категории прав.',
					],
					'В первой вкладке необходимо загрузить свою фотографию. В остальных – добавить скан-копию документов подтверждающих введенную информацию (паспорта и водительского удостоверения).',
					'После заполнения всех данных профиля, нажмите кнопку «Отправить на верификацию», доступную во вкладках «Данные паспорта» и «Данные удостоверения».',
				],
				open: false
			},
			{
				question: 'Прохождение верификации',
				answer: [
					'Верификация – это проверка, которую проводит Администрация Сервиса с целью подтверждения подлинности данных пользователя, оказывающего транспортные услуги. В результате прохождения процедуры верификации перевозчику присваивается статус «Проверен».',
					'В процессе верификации проверяются документы, предоставленные пользователем (паспорт, водительское удостоверение), а также актуальность контактных данных. Проверка помогает защитить заказчиков от недобросовестных водителей и транспортных компаний.',
					'Не верифицированному перевозчику доступны ограниченные возможности Сервиса – он может только просматривать заявки, но не может откликаться на них. Также, ему не доступны опции добавления своего маршрута и транспорта.',
					'Если, после прохождения верификации перевозчик изменил данные паспорта или удостоверения, то он теряет свой статус «Проверенный» и доступ к указанным выше опциям. Для возобновления статуса, ему необходимо снова запросить процедуру верификации.',
					'В процессе проверки Администрация может обратить внимание водителя на несоответствие данных или другие ошибки при заполнении профиля. Получив соответствующее сообщение, водитель должен откорректировать введенную информацию.'
				],
				open: false
			},
		],
	};
	
	return list[type];
};

export default QuestionList;