import React from 'react';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import {Field, reduxForm, stopSubmit} from 'redux-form';
import Input from 'components/UI/Form/Fields/Input';
import InputDate from "components/UI/Form/Fields/InputDate";
import Button from 'components/UI/Form/Button';
import PropTypes from 'prop-types';
import Grid from 'components/UI/Wrappers/Grid';
import Select from 'components/UI/Form/Fields/Select';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import ImageUpload from 'components/UI/Form/Fields/ImageUpload';
import style from './style.less';
import SelectGroup from "../../UI/Form/Fields/SelectGroup";

let DriverLicenseForm = props => {
	let { handleSubmit, invalid } = props;
	
	let driver_categories = ['B', 'BE', 'C', 'CE', 'C1', 'C1E', 'D', 'DE', 'D1', 'D1E'];

	return (
		<form onSubmit={handleSubmit} autoComplete="off">
			<Grid col="form-2-notfull">
				<LayoutField>
					<Field
						name="number"
						type="text"
						component={Input}
						mask="9999 999999"
						label="Серия и номер удостоверения"
					/>
				</LayoutField>
				<LayoutField>
					<Field
						name="issueDate"
						type="text"
						component={InputDate}
						label="Действителен до"
					/>
				</LayoutField>
			</Grid>
			<Grid col="form-2-notfull">
				<LayoutField>
					<Field
						name="issueCountry"
						component={Select}
						label="Страна выдачи документа"
					>
						<OptionSelect value="ru">Россия</OptionSelect>
						<OptionSelect value="en">USA</OptionSelect>
					</Field>
				</LayoutField>
			</Grid>
			<LayoutField className={style.uploadImageProfile}>
				<span>Цветной скан удостоверения</span>
				<Field
					type='license'
					name="scanId"
					multiload
					component={ImageUpload}
					label="Загрузить скан-копию удостоверения"
				/>
			</LayoutField>
			<Grid col="form-2-notfull">
				<LayoutField>
					<Field
						name="categories"
						component={SelectGroup}
						label="Категории прав"
					>
						{
							driver_categories.map((el, i) => (
								<OptionSelect key={i} value={el} filterStr={el}>{ el }</OptionSelect>
							))
						}
					</Field>
				</LayoutField>
			</Grid>
			<Button
				disabled={invalid}
				size="large"
				type="submit"
			>
				Отправить на верификацию
			</Button>
		</form>
	);
};

DriverLicenseForm.propTypes = {
	handleSubmit: PropTypes.func,
	error: PropTypes.string,
	submitting: PropTypes.bool,
	invalid: PropTypes.bool,
};
DriverLicenseForm.defaultProps = {
	handleSubmit: () => {
	},
	invalid: false,
	submitting: false,
	error: '',
};

DriverLicenseForm = reduxForm({
	form: 'DriverLicense',
	onChange: (newValues, dispatch) => {
		dispatch(stopSubmit('DriverLicense', {}));
	}
})(DriverLicenseForm);

export default DriverLicenseForm;