exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n._8Jx9RlBZWMods3zUQ-1uI {\n  border-color: red;\n}\n._8Jx9RlBZWMods3zUQ-1uI:hover {\n  border-color: red!important;\n}\n._8Jx9RlBZWMods3zUQ-1uI:focus {\n  border-color: red!important;\n}\n._8Jx9RlBZWMods3zUQ-1uI:active {\n  border-color: red!important;\n}\n._1BVKTLvTT2Er7_YZNtqjqJ {\n  color: #cc1c12;\n  font-size: 13px;\n}\n._3FojYxDAD3jvf_Y4_6t07W {\n  grid-column-start: 1;\n  grid-column-end: 3;\n}\n.vnMFrr6fLzUO52rWXTjMr {\n  padding: 8px 25px;\n}\n.vnMFrr6fLzUO52rWXTjMr span {\n  color: #848484;\n  font-size: 13px;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/ReduxForms/Dimensions/style.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,kBAAkB;CACnB;AACD;EACE,4BAA4B;CAC7B;AACD;EACE,4BAA4B;CAC7B;AACD;EACE,4BAA4B;CAC7B;AACD;EACE,eAAe;EACf,gBAAgB;CACjB;AACD;EACE,qBAAqB;EACrB,mBAAmB;CACpB;AACD;EACE,kBAAkB;CACnB;AACD;EACE,eAAe;EACf,gBAAgB;CACjB","file":"style.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.error {\n  border-color: red;\n}\n.error:hover {\n  border-color: red!important;\n}\n.error:focus {\n  border-color: red!important;\n}\n.error:active {\n  border-color: red!important;\n}\n.error_label {\n  color: #cc1c12;\n  font-size: 13px;\n}\n.grid2 {\n  grid-column-start: 1;\n  grid-column-end: 3;\n}\n.volume {\n  padding: 8px 25px;\n}\n.volume span {\n  color: #848484;\n  font-size: 13px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"error": "_8Jx9RlBZWMods3zUQ-1uI",
	"error_label": "_1BVKTLvTT2Er7_YZNtqjqJ",
	"grid2": "_3FojYxDAD3jvf_Y4_6t07W",
	"volume": "vnMFrr6fLzUO52rWXTjMr"
};