import AdminGrid from 'components/Admin/UI/Grid';
import { AdminLabel } from 'components/Admin/UI/Label';
import React from 'react';

import style from './style.less';

const PersonalData = props => {
    const { lastName, firstName, middleName, phone, email, additionalPhone, role } = props;
    return (
        <>
        <h3> Личные данные {`${role === 'driver' ? 'водителя' : 'клиента'}`}</h3>
        <AdminGrid type="passport">
            <div>
                <AdminLabel title="ФИО:">
                    {lastName} {firstName} {middleName}
                </AdminLabel>
                <AdminLabel title="Телефон:">
                    {phone}
                </AdminLabel>
                <AdminLabel title="Дополнительный телефон:">
                    {additionalPhone ? additionalPhone : 'отсутствует'}
                </AdminLabel>
                {
                    email &&
                    <AdminLabel title="Email:">
                        {email}
                    </AdminLabel>
                }
            </div>

            {
                props.profile && props.profile.photo && props.profile.photo.images &&
                <img
                    className={style.profile_photo}
                    src={props.profile.photo.images[0].url}
                    alt=""
                />
            }

        </AdminGrid>
        </>
    )
};


export default PersonalData;