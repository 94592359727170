exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1UpUjmxUR5cQrLdoKIHFpp {\n  justify-content: center;\n}\n.MDhRONWhp0IaAtrJcAt6o {\n  display: flex;\n  margin-top: 20px;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/Admin/Pages/Trades/Detail/style.less"],"names":[],"mappings":"AAAA;EACE,wBAAwB;CACzB;AACD;EACE,cAAc;EACd,iBAAiB;CAClB","file":"style.less","sourcesContent":[".center {\n  justify-content: center;\n}\n.button_group {\n  display: flex;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"center": "_1UpUjmxUR5cQrLdoKIHFpp",
	"button_group": "MDhRONWhp0IaAtrJcAt6o"
};