import { dateToString } from './convertDate';

export const cargoFormToRequestServer = values => {
	let items = [];
	let additionIds = [];

	if (values.cargo.additionals !== undefined && values.cargo.additionals.length > 0) {
		console.log(values.cargo.additionals);
		values.cargo.additionals.forEach(el => {
			additionIds.push({ additionId: el });
		});
	}
	delete values.cargo.additionals;

	values.route.items.forEach(el => {
		if (!el.address.pos)
			return items.push({});

		const coord = el.address.pos.split(' '),
			time = el.startTime ? el.startTime : '00:00';

		let providerPhone = '';
		if (el.providerPhone)
			providerPhone = el.providerPhone.replace(/( |_)/ig, '');
		if (providerPhone)
			providerPhone = providerPhone ? el.phone_code + '' + providerPhone : '';

		if (el.startDate) {
			if (typeof (el.startDate) === 'string' && el.startDate.indexOf('Z') !== -1) {
				el.startDate = dateToString(new Date(el.startDate), 'serverDate') + ' ' + time + ':00';
			} else if (typeof (el.startDate) === 'object') {
				el.startDate = dateToString(el.startDate, 'serverDate') + ' ' + time + ':00';
			}
		}

		items.push({
			address: el.address.name,
			country: el.address.country,
			region: el.address.region,
			latitude: coord[0],
			longitude: coord[1],
			providerName: el.providerName,
			providerPhone,
			expireDays: el.startDate,
			startDate: el.startDate,
			isReady: null,
			locality: el.address.locality,
			timeIntervalLoad: el.timeIntervalLoad
		});
	});

	return {
		...values,
		route: {
			...values.route,
			items,
		},
		cargo: {
			...values.cargo,
			weight: values.cargo.weight * values.cargo.weightType,
			categoryId: values.cargo.category,
			packageId: values.cargo.package,
			additionIds
		},
		cargoId: null,
		comment: values.comment,
		isEnableNotifications: null,
		deadlineDays: null,
		price: parseInt(values.price),
		bodyTypeId: values.carBodyType,
		loadTypeId: values.carLoadType,
	};
};

const map = {
	carBodyType: 'transport.type_body',
	carLoadType: 'transport.type_shipment',
	weightCount: 'place',
	weight: 'weightCount'
};
export const serverCargoToClient = values => {
	const result = {};
	for (let key in values) {
		switch (key) {
			default:
				if (map[key])
					result[map[key]] = values[key];
				else
					result[key] = values[key];
				break;
			case 'cargoes':
				result['cargoes'] = serverCargoToClient(values['cargoes'][0]);
				if (result['cargoes']['weight'])
					result['cargoes']['weightCount'] = result['cargoes']['weight'];

				break;
		}
	}
	return result;
};

export const routeFormToRequestServer = values => {
	const getItem = item => {
		if (item.point === undefined) return { ...item };
		let coord = item.point.pos ? item.point.pos.split(' ') : [],
			result = {
				...item,
				address: item.point.name,
				providerName: null,
				providerPhone: null,
				// isReady: item.isReady,
				latitude: coord[0],
				longitude: coord[1],
				locality: item.point.locality,
				country: item.point.country,
				region: item.point.region,
				city: item.point.city
			};

		if (result.startDate) {
			if (typeof (result.startDate) === 'string' && result.startDate.indexOf('Z') !== -1) {
				result.startDate =
					result.expireDays =
						dateToString(new Date(result.startDate), 'serverDateTime');
			} else if (typeof (result.startDate) === 'object') {
				result.startDate =
					result.expireDays =
						dateToString(result.startDate, 'serverDateTime');
			}
		} else {
			result.startDate =
				result.expireDays =
					dateToString(new Date(), 'serverDateTime');
		}
		return result;
	};

	return {
		...values,
		carType: null,
		bodyType: null,
		loadType: null,
		type: 'planned',
		distance: values.distance,
		price: parseInt(values.price),
		items: (values.items.map((item, index) => getItem(item, index))).filter(el => el !== null),
	};
};
