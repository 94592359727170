import React from 'react';
import src from './selection.svg';
import style from '../style.less';

class AdvantageScreen extends React.Component {
  state = {
    list: this.props.list ? this.props.list : [],
  };

  render() {
    const className = `${style.advantage__screen} ${this.props.className ? this.props.className : ''}`;
    return (
      <div className={className}>
        {
          this.state.list.map((el, i) => {
            return (
              <div key={i}>
                <img src={el.src ? el.src : src} alt="" />
                <div className={style.content}>
                  <h4>{ el.title }</h4>
                  <p>{ el.text }</p>
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }
}

export default AdvantageScreen;
