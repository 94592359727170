import React from 'react';
import PropTypes from 'prop-types';
import { Close, PhotoCamera } from 'components/UI/Icons';
import Button from 'components/UI/Form/Button';
import style from '../style.less';

const OneImage = props => {
	const {img, label, handleRemoveImg, id} = props;
	if(img.id)
		return (
			<div className={`${style.imagesForm} ${style.imagesNotEmpty}`}>
				<div className={style.imagesList}>
					<div className={style.imageUpload}>
						<img src={img.images[0] ? img.images[0].url : '#'} alt={'image#'+img.id} />
						<Button
							className={style.imageCloseButton}
							onClick={() => handleRemoveImg(img.id)}
						>
							{ Close() }
						</Button>
					</div>
				</div>
			</div>
		);
	else
		return (
			<label className={`${style.imagesForm} ${style.imagesEmpty}`} htmlFor={id}>
				<h5>
					{ PhotoCamera() }
					<b>{label}</b>
				</h5>
				<p>Размер файла не должен превышать 3Мб</p>
			</label>
		);
};

OneImage.propTypes = {
	img: PropTypes.object.isRequired,
	handleRemoveImg: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
};

export default OneImage;