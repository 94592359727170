import forwardTo from "libs/forwardTo";
import { getProfileType } from "libs/selections";
import React from 'react';
import Grid from 'components/UI/Wrappers/Grid';
import MainScreen from "components/HelpfulInformations/common/main";
import MainWhiteContainer from "components/UI/Wrappers/MainWhiteContainer";
import styles from "components/HelpfulInformations/style.less";
import Navigation from "components/HelpfulInformations/common/navigation";
import style from "components/MainPage/style.less";
import Button from "components/UI/Form/Button";
import { connect } from "react-redux";
import security from './assets/protect.svg';
import Container from "components/UI/Wrappers/Container";
import bg from 'components/HelpfulInformations/assets/bg_verification.svg';
import passport from 'components/HelpfulInformations/assets/passport.svg';
import driverlicense from 'components/HelpfulInformations/assets/driverlicense.svg';
import contacts from 'components/HelpfulInformations/assets/contacts.svg';
import AsideSupport from 'components/HelpfulInformations/common/AsideSupport';

const Verification = props => (
  <MainScreen title={'Верификация аккаунта перевозчика'}
              subtitle={'Проверка и подтверждение подлинности данных пользователей, оказывающих транспортные услуги. '}
    bg={bg}
  >
    <MainWhiteContainer className={`${styles.advantage__screen} ${styles.not_padding}`}>
      <Navigation isCarrier isActive={0}/>
      <div className={styles.padding}>
        <Container>
          <Grid col="9-3">
            <div>
              <h3 className={styles.h3_risk}><b>Зачем нужна верификация?</b></h3>
              <p className={styles.p_risk} style={{ marginBottom: '35px' }}>
                При выборе перевозчика необходимо проверить надежность потенциального партнера. На рынке транспортных
                услуг есть недобросовестные транспортные компании и водители, которые умышленно идут на нарушение закона
                и мошенничество.
                <br/><br/>
                Верификация пользователей необходима для того, чтобы связать аккаунт с личностью владельца, что
                существенно упрощает решение проблемных вопросов, а также повышает доверие сторон сделки друг к другу.
              </p>
              <Button color={'red'} size={'large'} onClick={() => {
                forwardTo(`${
                    props.role === 'guest' ? '/auth/' :
                        props.role === 'client' ? '/edit/profile' : '/edit/profile'
                }`)
              }}>
                Пройти верификацию
              </Button>
            </div>
            <div className={style.support_s}>
              <div className={style.icon}><img src={security} alt=""/></div>
              <div>
                <h4 style={{ fontSize: '21px' }}>Гарантируем безопасность сделок</h4>
                <p>
                  Сделка без риска» с одной стороны гарантирует оплату за каждый выполненный заказ, а с другой,
                  – страхует заказчика от оплаты
                  за невыполненную доставку.
                </p>
              </div>
            </div>
          </Grid>
        </Container>
      </div>
    </MainWhiteContainer>
    <Container className={`${styles.container_cards} ${styles.container_cards2}`}>
      <h2 style={{ marginTop: '70px', fontSize: '30px' }}><b>Данные для верификации</b></h2>
      <Grid className={`${styles.grid3} ${styles.grid3_adaptive}`} col="3">
        <div className={styles.card_verification}>
          <div>
            <img src={contacts} alt=""/>
          </div>
          <div className={styles.title}>
            Контактные данные
          </div>
          <p className={styles.description}>
            Для проверки профиля, пожалуйста, заполните анкету с Вашими персональными данными
          </p>
        </div>
        <div className={styles.card_verification}>
          <div>
            <img src={passport} alt=""/>
          </div>
          <div className={styles.title}>
            Паспорт
          </div>
          <p className={styles.description}>
            Заполните анкету и загрузите фотографии разворота с 3 и 4 страницами Вашего паспорта, а также страницу с действующей пропиской
          </p>
        </div>
        <div className={styles.card_verification}>
          <div>
            <img src={driverlicense} alt=""/>
          </div>
          <div className={styles.title}>
            Водительское удостоверение
          </div>
          <p className={styles.description}>
            Заполните анкету с данными, и загрузите фотографию Вашего водительского удостоверения
          </p>
        </div>
      </Grid>
    </Container>

    <Container className={styles.padding_bottom_big}>
      <h2 style={{ marginTop: '20px', fontSize: '30px' }}><b>Как начать работу</b></h2>
      <Grid col={'10-2'} className={styles.grid2Col}>
        <ul className={styles.list_card}>
          <li className={styles.list_card__item}>
            <p>
              Зарегистрируйтесь в качестве водителя на сервисе
            </p>
          </li>
          <li className={styles.list_card__item}>
            <p>
              Откройте вкладку «Мой профиль» и заполните: личные данные, данные паспорта, данные удостоверения водителя
            </p>
          </li>
          <li className={styles.list_card__item}>
            <p>
              Отправьте заполненные данные на верификацию
            </p>
          </li>
          <li className={styles.list_card__item}>
            <p>
              Ожидайте уведомление на email с подтверждением или отклонением верификации
            </p>
          </li>
        </ul>

        <AsideSupport isDriver />
      </Grid>
    </Container>
  </MainScreen>
);


export default connect(
    store => ({
      role: getProfileType(store),
    })
)(Verification);
