import React from 'react';
import listStyle from 'components/Admin/UI/List/style.less';
import { Link } from 'react-router-dom';
import { shortFullName } from 'libs/shortName';
import { dateToString, dateUTCToClient } from 'libs/convertDate';


const Driver = props => {
	return (
		<ul>
			{
				props.users.map(el => {
					const date = dateUTCToClient(el.createTime);
					return (
						<li key={el.id} className={listStyle.item}>
							<Link to={`/admin/driver/${el.id}`} className={listStyle.link}/>

							<div className={listStyle.item__id}>
								{ el.id }
							</div>

							<div className={listStyle.item__username}>
								{ shortFullName(el.lastName, el.firstName, el.middleName) }
							</div>

							<div className={listStyle.item__createTime}>
								{ dateToString(date, 'fullDateShortYear') }, { dateToString(date, 'time') }
							</div>

							<div className={listStyle.item__verification}>
								{
									props.isVerification ?
										<span className={listStyle.true}>пройдена</span> :
										<span className={listStyle.false}>не пройдена</span>
								}
							</div>

						</li>
					)
				})
			}
		</ul>
	)
};


export default Driver;