import React from 'react';
import PropTypes from 'prop-types';
import { LabelError } from 'components/UI/Form/Labels';
import { add } from 'actions/notifications';
import MultiloadImages from './MultiloadImages';
import OneImage from './OneImage';
import style from '../style.less';
import { getItem } from 'libs/localStorage';
import JustFileUpload from 'components/UI/Form/Fields/ImageUpload/JustFileUpload';

export default class ImageUpload extends React.PureComponent {

	state = {
		images: this.props.input.value ? this.props.input.value : (this.props.multiload ? [] : {})
	};

	static propTypes = {
		label: PropTypes.string,
		multiload: PropTypes.bool,
		type: PropTypes.string,
		meta: PropTypes.object,
		className: PropTypes.string,
		justFile: PropTypes.bool,
	};

	static defaultProps = {
		label: 'Загрузить фотографию',
		multiload: false,
		meta: {},
		type: '',
		className: '',
		justFile: false,
	};

	inputForFile = undefined;

	handleRemoveImg = id => {
		if (this.props.multiload) {
			let images = [...this.state.images].filter(image => (image.id !== id));
			this.props.input.onChange({ images: images }); // may be fix
			this.setState({ images });
		} else {
			this.props.input.onChange({ images: null });
			this.setState({ images: {} });
		}
	};

	handleSaveFile = event => {
		const { type, multiload } = this.props,
			urlMap = {
				passport: '/api/1.0/my/passport-scan',
				photo: '/api/1.0/my/person-photo',
				license: '/api/1.0/my/license-scan',
				carPhoto: '/api/1.0/driver/transport-photo',
				arbitration: '/api/1.0/trucking/arbitration/scan',
			};
		if (event.target.files[0] === undefined || !type) return false;

		const xhr = new XMLHttpRequest();
		xhr.open('PUT', urlMap[type], true);
		xhr.setRequestHeader('If-None-Match', '*');
		xhr.setRequestHeader('If-Modified-Since', 'Mon, 26 Jul 1997 05:00:00 GMT');
		xhr.setRequestHeader('Cache-Control', 'no-cache');
		xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
		xhr.setRequestHeader('Content-Disposition', 'attachment; filename="' + encodeURI(event.target.files[0].name) + '"');
		xhr.setRequestHeader('Authorization', 'Bearer ' + getItem('token'));
		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				if (xhr.status === 200) {
					if (multiload) {
						let images = [...this.state.images, JSON.parse(xhr.responseText)];
						this.setState({ images }, () => {
							this.props.input.onChange({ images: images });
						})
					} else {
						let image = JSON.parse(xhr.responseText);
						this.setState({
							images: image,
						}, () => {
							this.props.input.onChange({ images: image });
						})
					}

				} else {
					this.props.meta.dispatch(add({
						time: 5,
						type: 'error',
						text: 'Ошибка загрузки файла',
					}));

				}
			}
		};
		xhr.send(event.target.files[0]);
	};

	render() {
		const { label, meta, multiload, className, justFile } = this.props,
			{ images } = this.state,
			id = 'upload:image:to:server:' + (new Date()).getMilliseconds();

		if (justFile) {
			return (
				<div className={style.justFileContainer}>
					<JustFileUpload id={id} images={images} handleRemoveImg={this.handleRemoveImg} />
					{
						meta.error && <LabelError message={meta.error} />
					}
					<input
						accept="image/*"
						id={id}
						style={{ display: 'none' }}
						type="file"
						ref={el => this.inputForFile = el}
						onChange={this.handleSaveFile}
					/>
				</div>
			);
		}

		return (
			<div className={`${style.imageContainer} ${className} ${meta.error ? ` ${style.error}` : ''}`}>
				{
					multiload
						?
						<MultiloadImages meta={meta} id={id} images={images} label={label} handleRemoveImg={this.handleRemoveImg}/>
						: <OneImage id={id} img={images} label={label} handleRemoveImg={this.handleRemoveImg}/>
				}
				{
					!multiload && meta.error &&
					<LabelError isOn={meta.error} message={meta.error}/>
				}
				<input
					accept="image/*"
					id={id}
					style={{ display: 'none' }}
					type="file"
					ref={it => this.inputForFile = it}
					onChange={this.handleSaveFile}
				/>
			</div>
		);
	}
}
