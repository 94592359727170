import React, { Component } from 'react';
import AdminPage from 'components/Admin/UI/Page';
import AdminGrid from 'components/Admin/UI/Grid';
import LeftStatisticMenu from 'components/Admin/Pages/Statistic/LeftMenu';
import AdminList from 'components/Admin/UI/List';
import StatisticResult from 'components/Admin/Pages/Statistic/StatisticResult';
import Periods from 'components/Admin/UI/Periods';
import style from 'components/Admin/UI/List/style.less';


class AdminStatistic extends Component {
	
	render() {
		
		const titles = ['Дата', 'Сделки', 'Заявки', 'Водители', 'Клиенты'];
		
		return (
			<AdminPage>
				
				<LeftStatisticMenu
					link={'main'}
				/>
				
				<div>
					<AdminGrid type="mainContent">
						<h1> Статистика </h1>
						
						<div className={style.container}>
							<Periods />
						
							<AdminList
								titles={titles}
								type="statistic"
							>
								<StatisticResult />
							</AdminList>
						</div>
						
					</AdminGrid>
				</div>
			</AdminPage>
		)
	}
}

export default AdminStatistic;