import React from 'react';
import PropTypes from 'prop-types';
import { Close, PhotoCamera } from 'components/UI/Icons';
import Button from 'components/UI/Form/Button';
import style from '../style.less';
import { LabelError } from 'components/UI/Form/Labels';

const renderMultiloadImages = props => {
	let { images, label, handleRemoveImg, id, meta } = props;
	if (images.length === 0) {
		return (
			<label className={`${style.imagesForm} ${style.imagesEmpty}`} htmlFor={id}>
				<h5>
					{PhotoCamera()}
					<b>{label}</b>
				</h5>
				<p>Размер файла не должен превышать 3Мб</p>
				{
					meta.error &&
					<LabelError isOn={meta.error} message={meta.error}/>
				}
			</label>
		);
	}
	
	if (images.length > 0 && images.length < 3) {
		return (
			<div className={`${style.imagesForm} ${style.imagesNotEmpty}`}>
				<div className={style.imagesList}>
					{
						images.map(img => (
							<div className={style.imageUpload} key={img.id}>
								<img src={img.images[0] ? img.images[0].url : '#'} alt={'image#' + img.id}/>
								<Button
									className={style.imageCloseButton}
									onClick={() => handleRemoveImg(img.id)}
								>
									{Close()}
								</Button>
							</div>
						))
					}
					<label className={style.imageLabel} htmlFor={id}>
						{PhotoCamera()}
						<b>Добавить фото</b>
					</label>
				</div>
				{
					meta.error &&
					<LabelError isOn={meta.error} message={meta.error}/>
				}
			</div>
		);
	}
	
	if (images.length === 3) {
		return (
			<div className={`${style.imagesForm} ${style.imagesNotEmpty}`}>
				<div className={style.imagesList}>
					{
						images.map(img => (
							<div className={style.imageUpload} key={img.id}>
								<img src={img.images[0] ? img.images[0].url : '#'} alt={'image#' + img.id}/>
								<Button
									className={style.imageCloseButton}
									onClick={() => handleRemoveImg(img.id)}
								>
									{Close()}
								</Button>
							</div>
						))
					}
				</div>
				{
					meta.error &&
					<LabelError isOn={meta.error} message={meta.error}/>
				}
			</div>
		);
	}
};

renderMultiloadImages.propTypes = {
	images: PropTypes.array.isRequired,
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	handleRemoveImg: PropTypes.func.isRequired,
};

export default renderMultiloadImages;