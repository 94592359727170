import React from 'react';
import MainPageSearchForm from 'components/ReduxForms/MainPageSearchForm';
import bg from './Icons/bg.svg';
import cargo_1 from './Icons/cargo_1.svg';
import cargo_2 from './Icons/cargo_2.svg';
import cargo_3 from './Icons/cargo_3.svg';
import transport from './Icons/transport_without_wheels.svg';
import wheel from './Icons/wheel.svg';
import style from '../style.less';
import forwardTo from 'libs/forwardTo';
import { setItem } from 'libs/localStorage';
import { getProfileType } from 'libs/selections';
import { connect } from 'react-redux';

class MainScreen extends React.Component {
	state = {
		stepOne: true,
		stepTwo: false,
	};
	
	handleSubmit = values => {
		let params = [];
		
		if (values.point_a) {
			params.push({
				address: {
					key: 'A',
					name: values.point_a.name,
					pos: values.point_a.pos,
					city: values.point_a.city,
					country: values.point_a.country,
					locality: values.point_a.locality,
					region: values.point_a.region,
				},
				startDate: values.date ? values.date : '',
			});
		}
		else params.push({
			key: 'A',
			startDate: values.date ? values.date : '',
		});
		if (values.point_b) {
			params.push({
				address: {
					key: 'B',
					name: values.point_b.name,
					pos: values.point_b.pos,
					city: values.point_b.city,
					country: values.point_b.country,
					locality: values.point_b.locality,
					region: values.point_b.region,
				},
			});
		}
		else params.push({
			key: 'B',
		});
		
		setItem('points', JSON.stringify(params));
		if (this.props.role === 'driver')
			forwardTo('/new/route');
		else
			forwardTo('/new/offer');
	};
	
	onStepOne = () => this.setState({ stepOne: true });
	
	onStepTwo = () => {
		if (this.state.stepOne) this.setState({ stepTwo: true });
	};
	
	render() {
		const { stepOne, stepTwo } = this.state,
			animationSteps = `${stepOne ? style.stepOne : ''} ${stepTwo ? style.stepTwo : ''}`;
		return (
			<div className={style.main__screen}>
				<div className={style.animation_container}>
					<div className={`${style.animations} ${animationSteps}`}>
						<img src={bg} alt="" className={style.bg}/>
						
						<div className={style.transportContainer}>
							<img src={transport} alt="" className={style.transport}/>
							<div className={style.wheels}>
								<img src={wheel} alt="" className={style.wheel_01}/>
								<img src={wheel} alt="" className={style.wheel_02}/>
								<img src={wheel} alt="" className={style.wheel_03}/>
							</div>
							
							<div className={style.cargoes}>
								<img src={cargo_1} alt="" className={style.cargo_1}/>
								<img src={cargo_2} alt="" className={style.cargo_2}/>
								<img src={cargo_3} alt="" className={style.cargo_3}/>
							</div>
						</div>
					
					</div>
				</div>
				<h1>Cервис для быстрого поиска перевозчиков и грузов</h1>
				<p>Выгодное взаимодействие перевозчиков и отправителей грузов</p>
				<MainPageSearchForm
					onStepOne={this.onStepOne}
					onStepTwo={this.onStepTwo}
					onSubmit={this.handleSubmit}
				/>
			</div>
		);
	}
}

export default connect(
	state => ({
		role: getProfileType(state),
	})
)(MainScreen);
