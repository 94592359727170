import React from 'react';
import style from './style.less';
import PropTypes from 'prop-types';
import Nav from 'components/UI/Header/nav';
import HorizontalMenu from 'components/UI/Footer/HorizontalMenu';
import { Link } from 'react-router-dom';
import Accordion from 'components/UI/Accordion';

class MobileMenu extends React.PureComponent {

	static propTypes = {
		className: PropTypes.string.isRequired,
	};

	render () {
		const { closeMenu } = this.props;
		return (
			<div className={style.mobileMenu}>
				<Nav closeMenu={closeMenu} isMobileStyles />
				<div className={style.footer}>
					<HorizontalMenu
						className={style.footerHorizontalMenu}
						closeMenu={closeMenu}
					/>
					<Accordion className={style.accordion} title={'ПЕРЕВОЗЧИКАМ'}>
						<ul className={style.accordion_menu}>
							<li><Link onClick={closeMenu} to={`/page/becomecarrier/`}>Как стать перевозчиком</Link></li>
							<li><Link onClick={closeMenu} to={`/verification/`}>Верификация</Link></li>
							<li><Link onClick={closeMenu} to={`/mobile/`}>Мобильное приложение</Link></li>
							<li><Link onClick={closeMenu} to={`/tariffs/`}>Тарифы</Link></li>
						</ul>
					</Accordion>
					<Accordion className={style.accordion} title={'КЛИЕНТАМ'}>
						<ul className={style.accordion_menu}>
							<li><Link onClick={closeMenu} to={`/how_start_work/`}>Как начать работу</Link></li>
							<li><Link onClick={closeMenu} to={`/insurance/`}>Страхование грузов</Link></li>
						</ul>
					</Accordion>
					<div className={style.separator} />
					<div className={style.contacts}>
						<p>По вопросам сервиса звоните 9:00–21:00 (Иск, без выходных)</p>
						<Link className={style.phone} to={'tel:8800000000'}>8 800 000 000</Link>
					</div>
				</div>
			</div>
		)
	}
}


export default MobileMenu;