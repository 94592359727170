import * as customerAction from 'actions/customer';
import { driverActions } from 'actions/driver';
import { add } from 'actions/notifications';
import forwardTo from 'libs/forwardTo';
import request from 'libs/request';
import { getProfileType } from 'libs/selections';
import { getItem } from "libs/sessionStorage";
import { statusListCargo } from 'libs/vars';
import { call, put, select } from 'redux-saga/effects';
import * as serverHelpersActions from 'actions/serverHelpersActions';

export function* sendCargoRequest(action) {
    let profileType = yield select(getProfileType);

    if (profileType !== 'customer') return false;
    let response = {};
    if (action.payload.id) {
        response = yield call(() =>
            request('put', '/api/1.0/trucking/offer/' + action.payload.id, action.payload));
    } else {
        response = yield call(() =>
            request('post', '/api/1.0/trucking/offer', action.payload));
    }

    if (response.data.errors || response.status !== 200) {
        yield call(() => action.meta.reject(response.data.errors));
        yield put(add({ type: 'error', text: 'При сохранении заявки произошел сбой', time: 10 }));
    } else {
        yield call(() => action.meta.resolve(true));
        yield put(add({ type: 'success', text: 'Заявка успешно сохранена', time: 10 }));
        yield put(serverHelpersActions.getCounterList());
        yield put(customerAction.getActiveCargo({ page: getItem('active', 1) }));
        yield call(forwardTo, '/offers/');
    }
}


export function* changePriceCargoRequest(action) {
    let profileType = yield select(getProfileType);

    if (profileType !== 'customer') return false;

    let response = {};
    if (action.payload.id) {
        response = yield call(() =>
            request('put', '/api/1.0/trucking/offer/' + action.payload.id, action.payload));
    } else {
        response = yield call(() =>
            request('post', '/api/1.0/trucking/offer', action.payload));
    }

    if (response.data.errors || response.status !== 200) {
        yield call(() => action.meta.reject(response.data.errors));
    } else {
        yield put(customerAction.getCargoByID(action.payload.id));
        yield put(customerAction.getActiveCargo({ page: getItem('active', 1) }));
        yield call(() => action.meta.resolve(true));
    }
}

export function* removeCargoByID(action) {
    let profileType = yield select(getProfileType);
    if (profileType !== 'customer') return false;

    const response = yield call(() =>
        request('delete', '/api/1.0/trucking/offer/' + action.payload));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'Произошла ошибка при удалении заявки',
            time: 10
        }));
    } else {
        yield put(customerAction.getActiveCargo({ page: getItem('active', 1) }));
        yield put(serverHelpersActions.getCounterList());
        yield put(add({
            type: 'success',
            text: 'Заявка была успешно удалена',
            time: 10
        }));
    }
}

export function* getMyCargoes(action) {
    let profileType = yield select(getProfileType),
        params = {};

    if (action.payload) params.status = statusListCargo[action.payload];
    if (profileType !== 'customer') return false;

    const response = yield call(() =>
        request('get', '/api/1.0/trucking/offer/my', params));

    if (response.data.errors || response.status !== 200) {
        if (action.meta) yield call(() => action.meta.reject(response.data.errors));
        yield put(customerAction.setMyCargoes([], action.payload));
        yield put(add({
            type: 'error',
            text: 'При получении заявок произошел сбой',
            time: 10
        }));
    } else {
        yield put(customerAction.setMyCargoes(response.data.items, action.payload));
    }
    
    yield put(serverHelpersActions.getCounterList());
}

export function* getMyActiveCargoes(action) {
    let profileType = yield select(getProfileType),
        params = { ...action.payload, status: statusListCargo.active, pageSize: 30 };
    if (profileType !== 'customer') return false;

    yield put(customerAction.loadingActiveCargo());

    const response = yield call(() =>
        request('get', '/api/1.0/trucking/offer/my', params));

    if (response.data.errors || response.status !== 200) {
        if (action.meta) yield call(() => action.meta.reject(response.data.errors));
        yield put(customerAction.setActiveCargo({ items: [], total: 0 }));
        yield put(add({
            type: 'error',
            text: 'При получении заявок произошел сбой',
            time: 10
        }));
    } else {
        yield put(customerAction.setActiveCargo(response.data));
        yield put(serverHelpersActions.getCounterList());
    }
}

export function* getMyCancelCargoes(action) {
    let profileType = yield select(getProfileType),
        params = { ...action.payload, status: statusListCargo.cancelled, pageSize: 30 };
    if (profileType !== 'customer') return false;

    yield put(customerAction.loadingCancelCargo());

    const response = yield call(() =>
        request('get', '/api/1.0/trucking/offer/my', params));

    if (response.data.errors || response.status !== 200) {
        if (action.meta) yield call(() => action.meta.reject(response.data.errors));
        yield put(customerAction.setCancelCargo({ items: [], total: 0 }));
        yield put(add({
            type: 'error',
            text: 'При получении заявок произошел сбой',
            time: 10
        }));
    } else {
        yield put(customerAction.setCancelCargo(response.data));
        yield put(serverHelpersActions.getCounterList());
    }
}

export function* getCargoByUserId(action) {
    const response = yield call(() =>
        request('get', '/api/1.0/trucking/offers/' + action.payload));

    if (response.data.errors || response.status !== 200) {
        yield call(() => action.meta.reject(response.data.errors));
    } else {
        yield call(() => action.meta.resolve(response.data.items));
    }
}

export function* getFavoriteDrivers(action) {
    let profileType = yield select(getProfileType);

    if (profileType !== 'customer') return false;

    let response = yield call(() =>
        request('get', '/api/1.0/trucking/drivers/my'));

    if (response.data.errors || response.status !== 200) {
        if (action.meta)
            yield put(add({
                type: 'error',
                text: 'При получении списка избранных водителей произошла ошибка',
                time: 10
            }));
    } else {
        yield put(customerAction.setFavoriteDrivers(response.data));
    }
}

export function* addToFavorite(action) {
    const response = yield call(() =>
        request('post', `/api/1.0/trucking/driver/${action.payload.id}/favorite`));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'При добавлении водителя в избранное произошла ошибка',
            time: 10
        }));
    } else {
        yield put(customerAction.getFavoriteDrivers());
        yield put(serverHelpersActions.getCounterList());
        yield put(add({
            type: 'success',
            text: 'Водитель успешно добавлен в избранное',
            time: 10
        }));
    }
}

export function* rmFavoriteById(action) {
    const response = yield call(() =>
        request('delete', `/api/1.0/trucking/driver/${action.payload.id}/favorite`));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'При удалении водителя из избранного произошла ошибка',
            time: 10
        }));
    } else {
        yield put(customerAction.getFavoriteDrivers());
        yield put(serverHelpersActions.getCounterList());
        yield put(add({
            type: 'success',
            text: 'Водитель успешно удалён из избранного',
            time: 10
        }));
    }
}

export function* addProposalToRoute(action) {
    const response = yield call(() =>
        request('post', `/api/1.0/trucking/proposal`, action.payload));

    if (response.data.errors || response.status !== 200) {
        yield put(add({
            type: 'error',
            text: 'При добавлении предложения произошла ошибка',
            time: 10
        }));
    } else {
        yield put(driverActions.getRouteByID(action.payload.routeId));
        yield put(serverHelpersActions.getCounterList());
        yield put(add({
            type: 'success',
            text: 'Ваше предложение успешно добавлено',
            time: 10
        }));
    }
}