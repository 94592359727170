import listStyle from 'components/Admin/UI/List/style.less';
import RouteList from 'components/Admin/UI/RouteList';
import Status from "components/UI/Status/index";
import { dateToString, dateUTCToClient } from 'libs/convertDate';
import { shortFullName } from 'libs/shortName';
import React from 'react';
import { Link } from 'react-router-dom';

import style from './style.less';


const TradeItem = props => {
    return (
        <ul>
            {
                props.trade.map(el => {
                    const date = dateUTCToClient(el.createTime);
                    return (
                        <li key={el.id} className={listStyle.item}>
                            <Link to={`${el.status === 'arbitration' ? `/admin/arbitrate/${el.arbitrationId}` : `/admin/trade/${el.id}`}`}
                                  className={listStyle.link}/>

                            <div className={listStyle.item__id}>
                                {el.id}
                            </div>

                            <div className={listStyle.item__route}>
                                <RouteList
                                    start={el.startPlace}
                                    end={el.finishPlace}
                                />
                            </div>

                            <div className={listStyle.item__createTime}>
                                <div className={listStyle.item__description}>
                                    <span className={listStyle.false}>ФИО водителя:</span>{' '}
                                    {shortFullName(el.executor.lastName, el.executor.firstName, el.executor.middleName)}
                                </div>
                                <div className={listStyle.item__description}>
                                    <span className={listStyle.false}>ФИО клиента:</span>{' '}
                                    {shortFullName(el.customer.lastName, el.customer.firstName, el.customer.middleName)}
                                </div>
                                <div className={listStyle.item__description}>
                                    <span className={listStyle.false}>Дата создания:</span>{' '}
                                    {dateToString(date, 'fullDateShortYear')}, {dateToString(date, 'time')}
                                </div>
                            </div>

                            <div className={listStyle.item__verification}>
                                <Status status={el.status} isAdmin className={style.admin_status}/>
                            </div>

                        </li>
                    )
                })
            }
        </ul>
    )
};


export default TradeItem;