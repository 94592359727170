import cargoServerToClient from './cargoServerToClient';
import { TradeRouteServerToClient } from 'mappers/cargoServerToClient';

const offerServerToClient = serverData => {
	return {
		...serverData,
		tradeID: serverData.id,
		offer: cargoServerToClient(serverData.offer),
		status: serverData.status,
		price: serverData.price,
		transport: { ...serverData.transport }
	};
	// delete result.offer;
	// return result;
};

export const TradeServerToClient = serverData => {
	return {
		...serverData,
		tradeID: serverData.id,
		offer: TradeRouteServerToClient(serverData.offer),
		status: serverData.status,
		price: serverData.price,
		transport: { ...serverData.transport }
	};
	// delete result.offer;
	// return result;
};

export default offerServerToClient;