import React from 'react';
import style from './review.less';
import shortName from 'libs/shortName';
import { dateToString, dateUTCToClient } from 'libs/convertDate';
import Avatar from 'components/UI/ProfileUI/Avatar';
import Rating from 'components/UI/ProfileUI/Rating';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';


const Review = ({ review, isUrlShow }) => {
	const { firstName, lastName } = review.creator;
	return (
		<div className={style.reviews_item}>
			<Avatar className={style.avatar} src={review.creator.photo} />
			<div className={style.content}>
				<div className={style.content_header}>
					<h4>
						{
							isUrlShow &&
							<Link to={`/profile/${review.creator.id}`}>{ shortName(firstName, lastName) }</Link>
						}
						{
							!isUrlShow && shortName(firstName, lastName)
						}
					</h4>
					<p>Оценка { review.generalRating }</p>
				</div>
				<div className={style.content_container}>
					<div className={style.body}>
						<h5>{ review.title }</h5>
						<p>{ review.text }</p>
					</div>
					<div className={style.footer}>
						<Rating className={style.rating} justText text={'Пунктуальность'} count={String(review.punctualityRating)} />
						<Rating className={style.rating} justText text={'Цена'} count={String(review.priceRating)} />
						<Rating className={style.rating} justText text={'Адекватность'} count={String(review.adequacyRating)} />
					</div>
				</div>
				<div className={style.content_footer}>
					{ dateToString(dateUTCToClient(review.createTime), 'stringFullDate') }
				</div>
			</div>
		</div>
	)
};

Review.propTypes = {
	isUrlShow: PropTypes.bool,
};

Review.defaultProps = {
	isUrlShow: false,
};

export default Review;
