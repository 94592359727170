import React from 'react';
import { Link } from 'react-router-dom';
import MainWhiteContainer from 'components/UI/Wrappers/MainWhiteContainer';
import Container from 'components/UI/Wrappers/Container';
import Button from 'components/UI/Form/Button';
import { ArrowUp, Fire } from 'components/UI/Icons';
import style from '../style.less';
import ReviewCard from './ReviewCard';
import avatar_01 from './avatar_01.jpeg';
import avatar_02 from './avatar_02.jpeg';
import avatar_03 from './avatar_03.jpeg';
import avatar_04 from './avatar_04.jpeg';
import { connect } from 'react-redux';
import { getProfileType } from 'libs/selections';

class AboutScreen extends React.Component {
	reviews = [
		{
			avatar: avatar_01,
			badge: 1,
			username: 'Дмитрий М.',
			rating: '4.5',
			price: '10 500',
			text: 'Здравствуйте! Готов перевезти груз в необходимом направлении. Буду рад сотрудничеству.',
			date: '5 Октября, 11:35'
		},
		{
			avatar: avatar_02,
			badge: 2,
			username: 'Павел А.',
			rating: '4.1',
			price: '12 700',
			text: 'Имею опыт в грузоперевозках, могу помочь вам',
			date: '12 Ноября, 12:09'
		},
		{
			avatar: avatar_03,
			badge: 3,
			username: 'Анастасия П.',
			rating: '3.4',
			price: '21 000',
			text: 'Имею опыт в грузоперевозках, могу помочь вам',
			date: '12 Ноября, 12:09'
		},
		{
			avatar: avatar_04,
			badge: 4,
			username: 'Андрей К.',
			rating: '4.8',
			price: '16 200',
			text: 'Здравствуйте! Готов перевезти груз в необходимом направлении. Буду рад сотрудничеству.',
			date: '14 Ноября, 14:34'
		},
	];


	render() {
		return (
			<div className={style.about__screen}>
				<MainWhiteContainer className={style.container}>
					<Container>
						<h3 className={style.section_title}>
							Выбор оптимальной цены, маршрута и транспорта.
							<br />Сделки без риска. Четкая система репутации пользователей.
						</h3>
						<div className={style.grid_2}>
							<div>
								<h2 className={style.section_title}>
									HurryLorry — территория комфортного сотрудничества, где в приоритете надежность, честность и доверие.
								</h2>
								<p>
									Лучшие условия, надежные перевозчики, гарантия доставки и оплаты. Быстрый поиск подходящих предложений
									и заказов, подбор оптимальных вариантов доставки грузов по заданным параметрам: срокам, цене, маршруту.
								</p>
							</div>
							<div className={style.card__show_service}>
								<span className={style.count}>Заявок на сайте <span>{ ArrowUp() } { this.props.count ? this.props.count : 'определяем...' }</span></span>

								<div className={style.containerRoute}>
									<div className={style.date}>05.11</div>
									<div className={style.route}>
										<div>Москва - Самара</div>
										<div>Грунт, 10 тонн, 31м<sup>3</sup></div>
									</div>
									<div className={style.price}>{ Fire() } 12 000 р.</div>
								</div>

								<div className={`${style.review_listAnimation} ${style.playAnimation}`}>
									<ReviewCard className={style.review_01} review={this.reviews[0]} />
									<ReviewCard className={style.review_02} review={this.reviews[1]} />
									<ReviewCard className={style.review_03} review={this.reviews[2]} />
									<ReviewCard className={style.review_04} review={this.reviews[3]} />
								</div>

							</div>
						</div>

						{
							this.props.role === 'guest' &&
							<div className={style.btnLink}>
								<Link to="/registration/customer">
									<Button size="large">Стать участником сервиса</Button>
								</Link>
							</div>
						}
					</Container>
				</MainWhiteContainer>
			</div>
		)
	}
}

export default connect(
	state => ({
		role: getProfileType(state)
	})
)(AboutScreen);
