import React from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm, stopSubmit, change} from 'redux-form';
import Input from 'components/UI/Form/Fields/Input';
import Button from 'components/UI/Form/Button';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import Select from 'components/UI/Form/Fields/Select';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import ImageUpload from 'components/UI/Form/Fields/ImageUpload';
import Grid from 'components/UI/Wrappers/Grid';
import DimensionsCar from './DimensionsCar';
import MarkCar from './MarkCar';
import ModelCar from './ModelCar';
import SeriesCar from './SeriesCar';
import { getMarks, getModels, getSeries } from 'libs/cars';
import style from './style.less';

class CreateCarReduxForm extends React.PureComponent{

	state = {marks: [], models: [], series: []};

	componentDidMount() {
		let {initialValues, dispatch} = this.props;

		getMarks(dispatch, marks=>{
			this.setState({marks}, ()=>{
				if(initialValues && initialValues.carMarkId){
					dispatch(change('setCar', 'carMarkId', initialValues.carMarkId));
					if(initialValues.carModelId){
						getModels(initialValues.carMarkId, dispatch, models=>{
							this.setState({models}, ()=>dispatch(change('setCar', 'carModelId', initialValues.carModelId)));
							if(initialValues.carSeriesId)
								getSeries(initialValues.carModelId, dispatch, series=>{
									this.setState({series}, ()=>dispatch(change('setCar', 'carSeriesId', initialValues.carSeriesId)));
								});
						});
					}
				}
			});
		});
	}

	handleChange = (type, id) => {
		let {dispatch} = this.props;
		dispatch(change('setCar', 'carSeriesId', null));
		if(type === 'mark'){
			dispatch(change('setCar', 'carModelId', null));
			getModels(id, this.props.dispatch, models=>this.setState({models}));
		}
		if(type === 'model')
			getSeries(id, this.props.dispatch, series=>this.setState({series}));
	};

	render() {
		let { handleSubmit, invalid } = this.props;
		return (
			<form onSubmit={handleSubmit} autoComplete="off">
				<span className={style.span}>Укажите марку транспорта, используя поиск по буквам</span>
				<Grid col="form-2-notfull">
					<LayoutField>
						<MarkCar marks={this.state.marks} onChange={this.handleChange} />
					</LayoutField>
					<LayoutField>
						<ModelCar models={this.state.models} onChange={this.handleChange} />
					</LayoutField>
				</Grid>
				<Grid col="form-2-notfull">
					<LayoutField>
						<SeriesCar series={this.state.series} />
					</LayoutField>
					<LayoutField>
						<Field name="publishYear" mask="9999" component={Input} label="Год выпуска" />
					</LayoutField>
				</Grid>
				<Grid col="form-2-notfull">
					<LayoutField>
						<Field
							name="bodyTypeId"
							component={Select}
							label="Тип кузова"
						>
							{
								this.props.bodyTypes.map((el) => (
									<OptionSelect value={el.id} key={el.id}>
										{ el.titleRu }
									</OptionSelect>
								))
							}
						</Field>
					</LayoutField>
					<LayoutField>
						<Field
							name="loadTypeId"
							component={Select}
							label="Тип погрузки"
						>
							{
								this.props.loadTypes.map((el) => (
									<OptionSelect value={el.id} key={el.id}>
										{ el.titleRu }
									</OptionSelect>
								))
							}
						</Field>
					</LayoutField>
				</Grid>
				<span className={style.span}>Укажите габариты кузова</span>
				<DimensionsCar />
				<Grid col="form-2-notfull">
					<LayoutField>
						<Field
							name="carrying"
							type="number"
							component={Input}
							label="Вес"
							unit="т."
						/>
					</LayoutField>
				</Grid>
				<Grid col="form-2-notfull">
					<LayoutField>
						<Field name="registrationNumber" component={Input} label="Гос. номер" />
					</LayoutField>
					<LayoutField>
						<Field name="vinNumber" component={Input} label="VIN код" />
					</LayoutField>
				</Grid>
				<LayoutField className={style.uploadImage}>
					<span>Фотографии транспортного средства</span>
					<Field
						type='carPhoto'
						name="photoIds"
						component={ImageUpload}
						multiload
						label="Загрузить фотографию транспорта"
					/>
				</LayoutField>
				{/*<Field*/}
					{/*name="size"*/}
					{/*type="hidden"*/}
					{/*component={Input}*/}
					{/*label="Обьём"*/}
					{/*unit="м."*/}
				{/*/>*/}
				<Button className={style.button} disabled={invalid} size="large" type="submit">Сохранить</Button>
			</form>
		);
	}
}

CreateCarReduxForm.propTypes = {
	handleSubmit: PropTypes.func,
	error: PropTypes.string,
	submitting: PropTypes.bool,
	invalid: PropTypes.bool,
};

CreateCarReduxForm.defaultProps = {
	handleSubmit: () => {
	},
	invalid: false,
	submitting: false,
	error: '',
};

CreateCarReduxForm = reduxForm({
	form: 'setCar',
	onChange: (newValues, dispatch) => {
		dispatch(stopSubmit('setCar', {}));
	}
})(CreateCarReduxForm);

export default CreateCarReduxForm;
