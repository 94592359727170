exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2GM-gHl150iYQDucfCNHPX {\n  width: 100%;\n  min-width: 690px;\n  border-collapse: collapse;\n}\n._2GM-gHl150iYQDucfCNHPX thead {\n  border-bottom: 1px solid #eee;\n  font-weight: 600;\n  color: #545454 !important;\n  font-size: 15px;\n}\n._2GM-gHl150iYQDucfCNHPX td {\n  padding: 10px;\n}\n._2GM-gHl150iYQDucfCNHPX tbody tr:nth-child(2n) {\n  background: #f6f9fc;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/Admin/UI/Table/styles.less"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,0BAA0B;CAC3B;AACD;EACE,8BAA8B;EAC9B,iBAAiB;EACjB,0BAA0B;EAC1B,gBAAgB;CACjB;AACD;EACE,cAAc;CACf;AACD;EACE,oBAAoB;CACrB","file":"styles.less","sourcesContent":[".table {\n  width: 100%;\n  min-width: 690px;\n  border-collapse: collapse;\n}\n.table thead {\n  border-bottom: 1px solid #eee;\n  font-weight: 600;\n  color: #545454 !important;\n  font-size: 15px;\n}\n.table td {\n  padding: 10px;\n}\n.table tbody tr:nth-child(2n) {\n  background: #f6f9fc;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"table": "_2GM-gHl150iYQDucfCNHPX"
};