import showError from 'libs/showFormErrors';
import { required } from 'libs/validationsForms';
import { offersActions } from 'actions/offers';
// import { add } from 'actions/notifications';
// import {dateToString} from 'libs/convertDate';

export const createContract = (values, dispatch, success, error) => {
	showError({
		firstName: [required(values.firstName)],
		middleName: [required(values.middleName)],
		lastName: [required(values.lastName)],
		number: [required(values.number)],
		address: [required(values.address)],
		proposalId: [required(values.proposalId)],
	});
	dispatch(offersActions.createContract(values, { success, error }));
};

export const confirmContractCode = (values, dispatch, success) => {
	showError({
		code: [required(values.code)],
	});

	return new Promise((resolve, reject) => {
		dispatch(offersActions.confirmContractCode(values, {resolve, reject}));
	}).then(result => {
		success();
	}).catch(err => {
		showError({
			code: [err],
		});
	});
};

export const sendRequestForEditOffer = (values, dispatch, success) => {
	showError({
		text: [required(values.text)],
	});
	return new Promise((resolve,reject) => {
		dispatch(offersActions.sendRequestEditOffer(values, { resolve, reject }));
	}).then(() => {
		success();
	});
};