import React, { Component } from 'react';
import AdminPage from 'components/Admin/UI/Page';
import AdminGrid from 'components/Admin/UI/Grid';
import SearchAdminForm from 'components/Admin/ReduxForms/SearchAdminForm';
import AdminList from 'components/Admin/UI/List';
import AdminPagination from 'components/Admin/UI/Pagination';
import LeftMenu from 'components/Admin/Pages/Drivers/List/LeftMenu';
import { convertNumberSpaces } from 'libs/convertNumberSpaces';
import { getDrivers } from 'components/Admin/Pages/Drivers/List/getData';
import { connect } from 'react-redux';
import { getDriversState, getDriversVerificationState } from 'components/Admin/getStore';
import { setItem, getItem } from 'libs/localStorage';
import UpdateButton from 'components/Admin/UI/UpdateButton';
import DriverContent from 'components/Admin/Pages/Drivers/List/DriverContent';


class AdminDriverList extends Component {
	
	state = {
		loading: true,
		params: getItem('driverListParams') ? JSON.parse(getItem('driverListParams')) : {
			page: 1,
			pageSize: 10,
			isProfileVerification: 1, // для смены список / верификация, подумать над другой реализацией
		}
	};

	async componentWillMount() {
		if (this.props.drivers.total === 0)
			await this.loadDrivers(true);
		else
			this.setState({ loading: false });
	}
	
	componentWillUnmount() {
		// removeItem('driverListParams');
	}
	
	loadDrivers = async (isInit = false) => {
		this.setState({ loading: true });

		await getDrivers(this.props.dispatch, this.state.params, () => {
			setItem('driverListParams', JSON.stringify(this.state.params));
			this.setState({ loading: false });
		});

		if (isInit)
			await getDrivers(this.props.dispatch, { ...this.state.params, isProfileVerification: 0},
				() => {});
	};
	
	setPage = async page => {
		this.setState({ params: { ...this.state.params, page } }, async () => {
			await this.loadDrivers();
		});
	};
	
	onHandleData = async isProfileVerification => {
		this.setState({ params: { ...this.state.params, page: 1, isProfileVerification } }, async () => {
			await this.loadDrivers();
		});
	};
	
	
	render() {
		
		const { drivers, driversVerification } = this.props;
		
		const isAll = this.state.params.isProfileVerification === 1;
		
		const titles = ['ID', 'Фио', 'Дата регистрации', 'Верификация'];
		
		let pageCount = 1;
		
		if (isAll) {
			if (drivers.total > 0)
				pageCount = Math.ceil(drivers.total / this.state.params.pageSize);
		}
		else
			if (driversVerification.total > 0)
				pageCount = Math.ceil(driversVerification.total / this.state.params.pageSize);
		
			
		let list = [
			{
				onClick: () => this.onHandleData(1),
				isActive: isAll,
				title: 'Список',
				count: convertNumberSpaces(drivers.total),
				newCount: convertNumberSpaces(drivers.meta.totalToday)
			},
			{
				onClick: () => this.onHandleData(0),
				isActive: !isAll,
				title: 'Верификация',
				count: convertNumberSpaces(driversVerification.total),
				newCount: convertNumberSpaces(driversVerification.meta.totalToday)
			},
		];

		return (
			<AdminPage>
				
				<LeftMenu
					list={list}
				/>
				
				<div>
					<AdminGrid type="mainContent">
						<h1>
							Список водителей
							<UpdateButton
								onClick={this.loadDrivers}
							/>
						</h1>
						
						<SearchAdminForm />
						
						<AdminList
							titles={titles}
							type="drivers"
						>
							<DriverContent
								loading={this.state.loading}
								list={isAll ? drivers : driversVerification}
								isVerification={isAll}
							/>
							{
								pageCount > 1 &&
								<AdminPagination
									pageCount={pageCount}
									activePage={this.state.params.page}
									setPage={e => this.setPage(e)}
								/>
							}
						</AdminList>
						
					</AdminGrid>
				</div>
				
			</AdminPage>
		);
	}
}


export default connect(
	state => ({
		drivers: getDriversState(state),
		driversVerification: getDriversVerificationState(state),
	})
)(AdminDriverList);