import React from 'react';
import Grid from 'components/UI/Wrappers/Grid';
import MainScreen from "components/HelpfulInformations/common/main";
import MainWhiteContainer from "components/UI/Wrappers/MainWhiteContainer";
import styles from "components/HelpfulInformations/style.less";
import Navigation from "components/HelpfulInformations/common/navigation";
import Container from "components/UI/Wrappers/Container";
import ios from './assets/ios.png';
import android from './assets/android.png';
import bg from './assets/bg_mobile.svg';
import mobile1 from 'components/HelpfulInformations/assets/icon_mobile_1.svg';
import mobile2 from 'components/HelpfulInformations/assets/icon_mobile_2.svg';
import mobile3 from 'components/HelpfulInformations/assets/icon_mobile_3.svg';
import AsideSupport from 'components/HelpfulInformations/common/AsideSupport';

const MobileApp = () => (
  <MainScreen title={'Мобильное приложение'}
              subtitle={'Легкая установка и полная совместимость с любыми версиями iOS и Android. Для этого не потребуются профессиональные навыки или дополнительное оборудование.'}
    bg={bg}
  >
    <MainWhiteContainer className={`${styles.advantage__screen} ${styles.not_padding}`}>
      <Navigation isCarrier isActive={1}/>
      <div className={styles.padding}>
        <Container>
          <div style={{ maxWidth: '840px' }}>
            <h3 className={styles.h3_risk}><b>Зачем устанавливать мобильное приложение?</b></h3>
            <p style={{ marginBottom: '35px' }} className={styles.p_risk}>
              Для успешной работы с заказами и оперативного общения между участниками биржи рекомендуем использовать мобильное приложение HurryLorry. Программа легко устанавливается на ваш мобильный или планшет, а ее доступный интерфейс позволяет быстро разобраться с функционалом, который включает:
            </p>
          </div>

          <Grid className={`${styles.grid3} ${styles.mobile_plus_container}`} col="3">
            <div className={styles.mobile_pluses}>
              <img src={mobile1} alt=""/>
              <p>Удобный сервис общения в онлайн-режиме с разными настройками приватности (вы всегда сможете уточнить детали доставки или передать необходимую информацию);</p>
            </div>
            <div className={styles.mobile_pluses}>
              <img src={mobile2} alt=""/>
              <p>Круглосуточный сервис отслеживания движения груза (на всех этапах доставки у вас будет полная информация о месте пребывания заказа);</p>
            </div>
            <div className={styles.mobile_pluses}>
              <img src={mobile3} alt=""/>
              <p>Полное информационное сопровождение сделки (утверждение условий заказа, принятие
                к исполнению, мониторинг, оплата).
              </p>
            </div>
          </Grid>
        </Container>
      </div>
    </MainWhiteContainer>

    <Container className={styles.padding_bottom_big}>
      <h2 style={{ marginTop: '90px', fontSize: '30px' }}><b>
        Как установить приложение
      </b></h2>
      <Grid col={'10-2'} className={styles.grid2Col}>
        <ul className={styles.list_card}>
          <li className={styles.list_card__item}>
            <p>
              Для установки приложения HurryLorry пользователям IOS необходимо зайти в App Store, а пользователям Android зайти в Google Play, ввести в строке поиска «Hurry Lorry» или «HurryLorry». После обнаружения приложения, выберете его и нажмите «Установить». Либо с мобильного устройства перейти по ссылкам:
              <br/> <br/>
              <a href="https://apps.apple.com/us/app/hurrylorry/id1487473412?l=ru&ls=1" target="_blank"> <img style={{ maxWidth: '125px' }} src={ios} alt=""/> </a>
              <a href="https://play.google.com/store/apps/details?id=com.logistmobileapp" target="_blank" style={{ marginLeft: '10px' }}> <img style={{ maxWidth: '138px' }} src={android} alt=""/> </a>
            </p>
            <div>

            </div>
          </li>
          <li className={styles.list_card__item}>
            <p>
              После установки приложения, Вы можете войти в Сервис, введя свой логин и пароль, или зарегистрироваться, пройдя соответствующую процедуру. Приложение работает при включенном мобильном интернете или Wi-Fi.
            </p>
          </li>
        </ul>
  
        <AsideSupport isDriver />
      </Grid>
    </Container>
  </MainScreen>
);

export default MobileApp;
