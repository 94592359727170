exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2LOsKea26X6bCKaUzzbxv1 {\n  animation: _2QDKG5Ubnb1z0j6RKxyf50 0.3s;\n}\n@keyframes _2QDKG5Ubnb1z0j6RKxyf50 {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/Main/style.less"],"names":[],"mappings":"AAAA;EACE,wCAAuB;CACxB;AACD;EACE;IACE,WAAW;GACZ;EACD;IACE,WAAW;GACZ;CACF","file":"style.less","sourcesContent":[".nextPage {\n  animation: fadein 0.3s;\n}\n@keyframes fadein {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"nextPage": "_2LOsKea26X6bCKaUzzbxv1",
	"fadein": "_2QDKG5Ubnb1z0j6RKxyf50"
};