import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Badge from 'components/UI/Badge';
import Button from 'components/UI/Form/Button';
import style from './style.less';

const Slide = props => {
	const className = `${style.slide} ${props.active ? style.active : ''}`;

	return (
		<div className={className} onClick={() => props.toggle(props.step - 1)}>
			<img src={props.content.img} alt="" />
			<Badge color={props.active ? 'yellowsaturated' : 'gray'} className={style.step}>{props.step} ШАГ</Badge>
			<div className={style.content}>
				<h3>{ props.content.title }</h3>
				<p>{ props.content.text }</p>
				{
					props.content.button &&
					<Link to={props.content.button.url}>
						<Button size="large">{ props.content.button.title }</Button>
					</Link>
				}
			</div>
		</div>
	);
};

Slide.propTypes = {
	content: PropTypes.object.isRequired,
	active: PropTypes.bool.isRequired,
};

export default Slide;