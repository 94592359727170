import React from 'react';
import PropTypes from 'prop-types';
import Container from './Container';
import Button from '../Form/Button';
import { ArrowLeft } from '../Icons';
import style from './style.less';
import {history} from 'components/../App';
import Loader from 'components/UI/_V2/Loader';

const returnBack = () => {
	return history.goBack();
};

const Page = props => (
	<Container>
		<div className={style['page-header']}>
			{props.history &&
				<Button
					onClick={returnBack}
					className={style.back}
					size="small"
					color="white"
				>
					{ArrowLeft()}
				</Button>
			}
			<h1>
				{
					props.title === null ? <Loader size="xl" count={2} /> : props.title
				}
			</h1>
			<span>{ props.text }</span>
		</div>
		{ props.children }
	</Container>
);

Page.propTypes = {
	title: PropTypes.any,
	text: PropTypes.string,
	history: PropTypes.bool,
	children: PropTypes.node,
};
Page.defaultProps = {
	title: null,
	text: '',
	history: false,
	children: null,
};

export default Page;
