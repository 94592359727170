import React from 'react';
import style from 'components/Admin/UI/Grid/style.less';
import PropTypes from 'prop-types';


const AdminGrid = ({ children, className, type }) => {
	
	let renderClassName = style.grid;
	
	renderClassName += ' ' + style[type];
	
	/*
	* type:
	* page
	* aside
	* mainContent
	* */
	
	if (className) renderClassName += ' ' + className;
	
	return (
		<div className={renderClassName}>
			{ children }
		</div>
	)
};


AdminGrid.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	type: PropTypes.string,
};

AdminGrid.defaultProps = {
	children: '',
	className: '',
	type: 'page',
};


export default AdminGrid;