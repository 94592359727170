import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/UI/Form/Button';
import { offersActions } from 'actions/offers';
import StepContainer from 'components/Pages/Trades/Detail/TradeSteps/StepContainer';
import style from 'components/Pages/Trades/Detail/styles.less';

export default class Route extends React.PureComponent {

	static propTypes = {
		isActive: PropTypes.bool.isRequired,
		role: PropTypes.string.isRequired,
		status: PropTypes.string.isRequired,
		tradeID: PropTypes.number.isRequired,
		dispatch: PropTypes.func.isRequired,
	};

	startRoute = () => this.props.dispatch(offersActions.startRouteById(this.props.tradeID));

	render() {
		let { isActive, role, status } = this.props;
		const routeEnd = ['cargo_unloaded', 'completed', 'arbitration'];
		return (
			<StepContainer step={2} title={'Маршрут'} isActive={isActive}>
				{
					role === 'driver' &&
					<Fragment>
						{
							(status === 'money_reserved' || status === 'cargo_loaded') &&
							<p>Местоположение определится после того как вы начнете поездку в приложении</p>
						}
						{
							status === 'money_reserved' &&
							<div className={style.action__buttons}>
								<Button onClick={this.startRoute}>Начать поездку через мобильное приложение</Button>
							</div>
						}
						{
							status === 'started_route' &&
							<p>Вы начали поездку</p>
						}
					</Fragment>
				}
				{
					role === 'customer' &&
					<Fragment>
						{
							status === 'cargo_loaded' &&
							<p>Водитель ещё не начал маршрут</p>
						}
						{
							status === 'started_route' &&
							<p>Водитель в пути</p>
							// внизу показать карту
						}
						{
							status === 'money_reserved' &&
							<p>
								Ожидайте отправления груза. Как только водитель начнет маршрут, вы сможете следить за
								передвижением.
							</p>
						}
					</Fragment>
				}
				{
					(status === 'accepted' || status === 'edit_request' || status === 'created' || status === 'edited') &&
					<p>Груз находится в месте загрузки</p>
				}
				{
					routeEnd.indexOf(status) !== -1 &&
					<p>Маршрут завершен</p>
				}
			</StepContainer>
		);
	}
}