import React, { Fragment } from 'react';
import styles from 'components/Pages/Trades/Detail/styles.less';
import language from 'language';
import PropTypes from 'prop-types';
import Warning from 'components/UI/Warning';
import Button from 'components/UI/Form/Button';

const Warnings = ({ status, role, toggleChat, tradeEdit, isTradeEdit, toggleDialog, feedback, editMessage }) => {

	return (
		<div className={styles.warning}>
			{
				role === 'customer' && status === 'demo' &&
				<Warning><p>{language['WarningForCustomerOfferAgree']}</p></Warning>
			}
			{
				role === 'customer' && status === 'edit_request' &&
				<Warning type={'warning'}>
					<b>Запрос на редактирование договора от перевозчика:</b>
					<p>{ editMessage }</p>
					<div className={styles.flexButtons}>
						{
							!isTradeEdit &&
							<Fragment>
								<Button color={'green'} onClick={tradeEdit}>Редактировать договор</Button>
								<Button color={'white'} onClick={toggleChat}>Обсудить в чате</Button>
							</Fragment>
						}
						{
							isTradeEdit &&
							<Button color={'white'} onClick={tradeEdit}>Вернуться назад</Button>
						}
					</div>
				</Warning>
			}
			{
				status === 'completed' && feedback &&
				<Warning type={'warning'}>
					<p>Оставьте отзыв о { role === 'driver' ? 'клиенте' : 'перевозчике' }. Это поможет нам составить рейтинг</p>
					<div className={styles.flexButtons}>
						<Button
							onClick={() => toggleDialog('dialogReview')}
						>
							{ language['LeaveFeedback'] }
						</Button>
					</div>
				</Warning>
			}
		</div>
	);
};

Warnings.propTypes = {
	status: PropTypes.string.isRequired,
	role: PropTypes.string.isRequired,
	isTradeEdit: PropTypes.bool,
};

Warnings.defaultProps = {
	role: 'customer',
	isTradeEdit: false,
};

export default Warnings;