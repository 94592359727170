import React from 'react';
import { ArrowDownNavigate } from 'components/UI/Icons';
import style from './style.less';

const QuestionItemOneType = props => {
	return (
		<div className={`${style.item__one_type} ${props.el.open ? style.open : ''}`}>
			<div className={style.title} onClick={() => props.onToggle(props.number)}>
				<span>{ props.el.question }</span>
				{ ArrowDownNavigate() }
			</div>
			<div className={style.answer}>
				{ props.el.answer }
			</div>
		</div>
	);
};

export default QuestionItemOneType;
