import React from 'react';
import { connect } from 'react-redux';
import { getProfile, getProfileType } from 'libs/selections';
import { offersActions } from 'actions/offers';
import Dialog from 'components/UI/Dialog';
import DialogChat from 'components/Chat';
import RequestEditOffer from 'components/ReduxForms/RequestEditOffer';
import ContractForm from 'components/ReduxForms/ContractForm';
import ContractSMSForm from 'components/ReduxForms/ContractSMSForm';
import contractStyles from 'components/ReduxForms/ContractForm/style.less';
import ArbitrationForm from 'components/ReduxForms/ArbitrationForm';
import { confirmContractCode, createContract, sendRequestForEditOffer } from 'components/Pages/Trades/Detail/submit';
import { dateToString, stringToDate } from 'libs/convertDate';
import style from 'components/Pages/Trades/Detail/styles.less';
import showError from 'libs/showFormErrors';
import { required } from 'libs/validationsForms';
import { CancelTrade } from 'components/UI/TradeDialogs';
import ReviewForm from 'components/ReduxForms/ReviewForm';
import { sendReviewOffer } from 'components/Profile/OfferDetail/submit';
import PayForm from 'components/ReduxForms/PayForm';
import { AddCardDialog } from 'components/Profile/MyCargoDetail/Dialogs';
import { submitCard } from 'components/Search/submit';
import PayAccessForm from 'components/Pages/Trades/Detail/PayAccessForm';

class DialogsForTrade extends React.Component {
	
	state = {
		data: {
			acsUrl: '',
			pareq: '',
			ternUrl: '',
			transactionId: '',
		}
	};

	submitContractFrom = values => createContract(
		{ ...values, id: this.props.trade.offer.id }, this.props.dispatch,
		() => {
			this.props.toggleDialog('dialogTrade');
			this.props.toggleDialog('dialogTradeSMS');
		},
		() => this.props.toggleDialog('dialogTrade')
	);

	submitContractSMSFrom = values => confirmContractCode(
		{ ...values, id: this.props.trade.offer.id, proposalId: this.props.trade.executor.proposalId },
		this.props.dispatch,
		() => this.props.toggleDialog('dialogTradeSMS'),
	);

	sendRequest = formValues => {
		let values = { ...formValues };
		values.id = this.props.trade.id;
		return sendRequestForEditOffer(values, this.props.dispatch, () => this.props.toggleDialog('dialogChangeContract'));
	};

	sendArbitration = values => {
		showError({
			reason: [required(values.reason)],
			comment: [required(values.comment)],
		});
		this.props.toggleDialog('dialogArbitrate');
		if (values.fileIds) values.fileIds = values.fileIds.images.map(it => it.id);
		this.props.dispatch(offersActions.sendArbitration(values));
	};
	
	handleSubmitCard = formValues => {
		let values = { ...formValues };
		
		if (values.id === undefined) {
			const month = values.issueDate.slice(0, 2),
				year = values.issueDate.slice(3, 7);
			values.issueDate = new Date(month + '/01/' + year);
		} else {
			delete values.id;
			delete values.remove;
			delete values.title;
			values.issueDate = new Date(values.issueDate);
		}
		return submitCard(
			values,
			() => this.props.toggleDialog('dialogAddcard'),
			this.props.dispatch
		);
	};
	
	sendPayAccept = values => {
		let sendValues = {
			...values,
			tradeId: this.props.trade.id,
			completePayUrl: window.location.href,
		};
		return new Promise((resolve, reject) => {
			this.props.dispatch(offersActions.acceptPayOfferById(sendValues, { resolve, reject }));
		}).then(data => {
			this.setState({ data });
		}).catch(err => {
			console.log(err);
		});
	};

	cancelTrade = () => {
		this.props.dispatch(offersActions.cancelTrade(this.props.trade.id));
		this.props.toggleDialog('dialogCancelTrade');
	};

	reviewOffer = values => {
		let sendValues = {
			...values,
			tradeId: this.props.trade.id,
			userId: this.props.role === 'driver' ? this.props.trade.customer.id : this.props.trade.executor.id,
		};
		sendReviewOffer(sendValues, this.props.dispatch, () => this.props.toggleDialog('dialogReview'));
	};

	renderRouteList = () => {
		let route = '';
		this.props.trade.offer.route.items.forEach((el, i) => {
			route += el.address.name;
			if (this.props.trade.offer.route.items.length !== i+1) route += ' — ';
		});
		return route;
	};

	render() {
		const { state, toggleChat, toggleDialog, profile, trade } = this.props;

		if (profile === undefined || profile === null) return null;

		let ContractFormInitialValues = {
			firstName: profile.firstName,
			lastName: profile.lastName,
			middleName: profile.middleName,
			number: profile.passport !== null ? profile.passport.number : '',
			address: profile.passport !== null ? profile.passport.registrationAddress : '',
			proposalId: trade.executor.proposalId,
		};

		return (
			<div>
				{
					state.dialogChat
					&& <DialogChat handleClose={() => toggleChat(false)}/>
				}
				{
					state.dialogTrade
					&&
					<Dialog className={contractStyles.contractDialog} handleClose={() => toggleDialog('dialogTrade')}>
						<ContractForm
							trade={trade}
							onSubmit={this.submitContractFrom}
							initialValues={ContractFormInitialValues}
						/>
					</Dialog>
				}
				{
					state.dialogTradeSMS
					&&
					<Dialog handleClose={() => toggleDialog('dialogTradeSMS')}>
						<ContractSMSForm onSubmit={this.submitContractSMSFrom}/>
					</Dialog>
				}
				{
					state.dialogChangeContract
					&&
					<Dialog className={style.editForm} handleClose={() => toggleDialog('dialogChangeContract')}>
						<h3>
							Запрос на исправление Договора №{trade.id} от&nbsp;
							{dateToString(stringToDate(trade.startDate), 'stringDate')}
						</h3>
						<RequestEditOffer onSubmit={this.sendRequest}/>
					</Dialog>
				}
				{
					state.dialogArbitrate
					&&
					<Dialog className={style.arbitrate} handleClose={() => toggleDialog('dialogArbitrate')}>
						<h3>Арбитраж по <span className={style.blue}>заявке №{ trade.offer.id }</span></h3>
						<ArbitrationForm
							onSubmit={this.sendArbitration}
							initialValues={{ tradeId: trade.id }}
						/>
					</Dialog>
				}
				{
					state.dialogCancelTrade
					&&
					<CancelTrade
						agreeAction={this.cancelTrade}
						cancelAction={() => toggleDialog('dialogCancelTrade')}
					/>
				}
				{
					state.dialogReview
					&&
					<Dialog className={style.review} handleClose={() => toggleDialog('dialogReview')}>
						<ReviewForm
							onSubmit={this.reviewOffer}
							role={this.props.role}
							initialValues={{ route: this.renderRouteList() }}
							user={this.props.role === 'driver' ? trade.customer : trade.executor }
						/>
					</Dialog>
				}
				{
					state.dialogPay
					&&
					<Dialog className={style.payform} handleClose={() => toggleDialog('dialogPay')}>
						<PayForm
							onSubmit={this.sendPayAccept}
							dialogAddcard={() => toggleDialog('dialogAddcard')}
						/>
					</Dialog>
				}
				{
					state.dialogAddcard
					&&
					<AddCardDialog
						onSubmit={this.handleSubmitCard}
						cancel={() => toggleDialog('dialogAddcard')}
					/>
				}
				<PayAccessForm { ...this.state.data } />
			</div>
		);
	}
}

export default connect(
	state => ({
		profile: getProfile(state),
		role: getProfileType(state),
	})
)(DialogsForTrade);