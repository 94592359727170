import React from 'react';
import PropTypes from 'prop-types';
import Status from 'components/UI/Status';
import style from './style.less';

const AsideOfferStatus = props => (
	<div className={style.aside_status}>
		<div>Статус { props.isRoute ? 'маршрута' : 'заявки' }:</div>
		<Status status={props.status} role={props.role} />
	</div>
);

AsideOfferStatus.propTypes = {
	status: PropTypes.string.isRequired,
	isRoute: PropTypes.bool,
};

AsideOfferStatus.defaultProps = {
	isRoute: false,
};

export default AsideOfferStatus;
