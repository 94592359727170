import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import InfoCargo from 'components/UI/RequestDetail/InfoCargo';
import InfoPointA from 'components/UI/RequestDetail/InfoPointA';
import InfoPointOther from 'components/UI/RequestDetail/InfoPointOther';

const AboutTrade = ({ offer }) => {
	return (
	  <Fragment>
		  <InfoPointA route={offer.route.items[0]} />
		  <InfoPointOther route={offer.route.items.slice(1)} />
		  <InfoCargo cargo={offer.cargo} />
	  </Fragment>
	);
};

AboutTrade.propTypes = {
	offer: PropTypes.object.isRequired,
};

export default AboutTrade;