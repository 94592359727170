import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import style from '../style.less';

const Menu = ({ title, menu }) => (
	<div className={style.menu}>
		<h5>{ title }</h5>
		<ul>
			{
				menu.map(el => {
					return (
						<li key={el.id}><Link to={el.link}>{ el.title }</Link></li>
					)
				})
			}
		</ul>
	</div>
);

Menu.propTypes = {
	title: PropTypes.string.isRequired,
	menu: PropTypes.array.isRequired,
};

export default Menu;
