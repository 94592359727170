import Dimensions from 'components/ReduxForms/Dimensions';
import Button from 'components/UI/Form/Button';
import Checkbox from 'components/UI/Form/Fields/Checkbox';
import Input from 'components/UI/Form/Fields/Input';
import Select from 'components/UI/Form/Fields/Select';
import Grid from 'components/UI/Wrappers/Grid';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import Panel from 'components/UI/Wrappers/Panel';
import { getPoints } from 'libs/selections';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, FieldArray, formValueSelector, reduxForm, stopSubmit } from 'redux-form';
import LocationPoints from './LocationPoints';
import getCarsSelect from 'libs/_V2/getCarsSelect';
import { setPoints } from 'actions/map';


class AddRouteForm extends React.PureComponent {
    
    componentWillMount() {
        if (this.props.initialValues.items[0].point)
            this.props.dispatch(setPoints(this.props.initialValues.items.map(el => el.point)));
    }
    
    setValue = (field, value) => {
        this.props.dispatch(change('addRoute', field, value));
    };

    render() {
        let { handleSubmit, dispatch, cars, invalid, openCreateCar } = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="off">
                <Panel title="Транспорт" marginBottom={30}>
                    <LayoutField>
                        <Field
                            name="transportId"
                            component={Select}
                            label="Выберите из списка"
                        >
                            { getCarsSelect(cars, openCreateCar) }
                        </Field>
                    </LayoutField>
                    <span>Свободное место в кузове</span>
                    <Dimensions name="cargo" nameForm="addRoute"/>
                </Panel>
                <FieldArray component={LocationPoints} name='items' dispatch={dispatch}/>
                <Panel title="Ставка на перевозку" marginBottom={30}>
                    <Grid col="form-2">
                        <LayoutField>
                            <span>Ставка</span>
                            <Field
                                name="price"
                                type="number"
                                unit="р/км."
                                component={Input}
                                label="Укажите вашу ставку"
                            />
                        </LayoutField>
                    </Grid>
                    <LayoutField>
                        <Button disabled={invalid} size="large" type="submit">
                            {this.props.isEdit ? 'Редактировать маршрут' : 'Добавить маршрут'}
                        </Button>
                    </LayoutField>
                    <Field
                        name="agree"
                        component={Checkbox}
                        label={['Я согласен с ', <a target="_blank" href="/agreement_hl.pdf">Правилами сайта</a>]}
                    />
                    <p>
                        Нажимая на кнопку «Разместить заявку», вы соглашаетесь с правилами платежного сервиса
                        «Название сервиса» и сервиса «Сделка без риска». Ваш банк может взымать дополнительную комиссию.
                    </p>
                </Panel>
            </form>
        );
    }
}

const selector = formValueSelector('addRoute');

AddRouteForm.propTypes = {
    handleSubmit: PropTypes.func,
    openCreateCar: PropTypes.func,
    error: PropTypes.string,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    cars: PropTypes.array.isRequired,
};

AddRouteForm.defaultProps = {
    handleSubmit: () => {
    },
    openCreateCar: () => {
    },
    invalid: false,
    submitting: false,
    isEdit: false,
    error: '',
};

AddRouteForm = connect(
    store => ({
        values: selector(store, 'items'),
        points: getPoints(store),
    })
)(AddRouteForm);

AddRouteForm = reduxForm({
    form: 'addRoute',
    onChange: (newValues, dispatch) => {
        dispatch(stopSubmit('addRoute', {}));
    },
    // enableReinitialize: true
})(AddRouteForm);

export default AddRouteForm;