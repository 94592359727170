import React from 'react';
import styles from '../style.less';
import Points from 'components/UI/Points';
import { getPoints } from 'libs/getInfoRoute';


const Addresses = ({ route }) => {
	if (route === undefined || route === null) return null;
	let p = getPoints(route);
	return (
		<div className={styles.routedata}>
			<Points className={styles.point_component} />
			<div className={styles.routes_container}>
				<div className={styles.route_A}>
					<div className={styles.title}><sup />{ p.pointA.title }</div>
					<span className={styles.date}>{ p.pointA.date }</span>
				</div>
				{
					p.otherPoints.length > 0
					&&
					<div className={styles.other_points}>
						{ p.otherPoints.map((el, i) => <span key={i}>{ el.title }</span>) }
					</div>
				}
				<div className={styles.route_B}>
					<div className={styles.title}>{ p.pointB.title }</div>
					<span className={styles.date}>{ p.pointB.date }</span>
				</div>
			</div>
		</div>
	)
};


export default Addresses;