import React from 'react';
import Select from 'components/UI/Form/Fields/Select';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';

const SeriesCar = ({series}) => {
	return (
		<Field
			disabled={series.length === 0}
			name="carSeriesId"
			component={Select}
			filter
			label="Серия транспорта"
		>
			{series.map(it => <OptionSelect key={it.id} value={it.id} filterStr={it.title}>{it.title}</OptionSelect>)}
		</Field>
	);
};

SeriesCar.propTypes = {
	series: PropTypes.array.isRequired,
};

export default SeriesCar;