import AdminGrid from 'components/Admin/UI/Grid';
import { AdminLabel } from 'components/Admin/UI/Label';
import React from 'react';

const TransportData = props => {
    const { carMark, carModel, carSeries, carrying, bodyTypeId, loadTypeId, registrationNumber, width, height, length } = props;

    return (
        <>
        <h3> Транспорт </h3>
        <AdminGrid type="passport">
            <div>
                <AdminLabel title="Модель:">
                    {carMark.title} / {carModel.title} / {carSeries.title}
                </AdminLabel>
                <AdminLabel title="Грузоподьемность:">
                    {carrying}т.
                </AdminLabel>
                <AdminLabel title="Обьем:">
                    { width * height * length }м<sup>3</sup> &nbsp;
                    (Ш{ width }*В{ height }*Д{ length })
                </AdminLabel>

                <AdminLabel title="Тип кузова:">
                    {bodyTypeId && bodyTypeId.titleRu}
                </AdminLabel>
                <AdminLabel title="Тип погрузки:">
                    {loadTypeId && loadTypeId.titleRu}
                </AdminLabel>
                <AdminLabel title="Гос. номер:">
                    {registrationNumber}
                </AdminLabel>



            </div>
        </AdminGrid>
        </>
    )
};


export default TransportData;
