import React from 'react';
import { Apple, GooglePlay } from '../../Icons';
import style from '../style.less';

const Contacts = () => (
	<div className={style.contacts}>

		<div className={style.phone}>
			<a href="tel:88002015241">8 800 201 52 41</a>
			<span>Бесплатный звонок по России</span>
		</div>

		<p>
			По вопросам работы сервиса звоните
			<br />
			9:00 - 21:00 (Мск, без выходных)
		</p>

		<div className={style.mail}>
			<a href="mailto:support@hurrylorry.com">support@hurrylorry.com</a>
		</div>

		<div className={style['download-app']}>
			<a href="https://apps.apple.com/us/app/hurrylorry/id1487473412?l=ru&ls=1" target="_blank" rel="noopener noreferrer" className={`${style['app-link']} ${style.appstore}`}>
				{ Apple(style.icon) }
				Скачать<br /> для IOS
			</a>

			<a href="https://play.google.com/store/apps/details?id=com.logistmobileapp" target="_blank" rel="noopener noreferrer" className={`${style['app-link']} ${style.googlePlay}`}>
				{ GooglePlay(style.icon) }
				Скачать<br /> для Android
			</a>
		</div>

	</div>
);

export default Contacts;
