exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1f505Am4QEUOqW8Q2qnXR0 {\n  margin-top: 30px;\n}\n._1KgAxei8AXH6eeMTStGAis {\n  text-align: right;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/ReduxForms/RecoveryPassword/style.less"],"names":[],"mappings":"AAAA;EACE,iBAAiB;CAClB;AACD;EACE,kBAAkB;CACnB","file":"style.less","sourcesContent":[".button {\n  margin-top: 30px;\n}\n.rec {\n  text-align: right;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"button": "_1f505Am4QEUOqW8Q2qnXR0",
	"rec": "_1KgAxei8AXH6eeMTStGAis"
};