import { serverDateToClientDate } from 'libs/convertDate';

export const getInitialValues = trade => {
	return {
		id: trade.id,
		price: trade.price,
		startDate: trade.startDate ? serverDateToClientDate(trade.startDate) : null,
		endDate: trade.endDate ? serverDateToClientDate(trade.endDate) : null,
		cargo: {
			...trade.offer.cargo,
			category: trade.offer.cargo.categoryId.id,
			package: trade.offer.cargo.packageId ? trade.offer.cargo.packageId.id : null,
		}
	}
};
