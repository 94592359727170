import React from 'react';
import styles from 'components/Pages/Trades/Detail/styles.less';
import language from 'language';
import Aside from 'components/UI/Wrappers/Aside';
import Rating from 'components/UI/ProfileUI/Rating';
import { Link } from 'react-router-dom';
import { convertPhone } from 'libs/convertNumberSpaces';
import { getCar, getVolumeCar } from 'libs/getInfoRoute';
// import Avatar from 'components/UI/ProfileUI/Avatar';

const ExecutorInfo = ({ user, transport }) => {
	const volume = getVolumeCar(transport);
	const transportRender = getCar(transport);
	return (
		<Aside marginBottom={30}>
			<div className={styles.header}>{ language['InfoAboutDriver'] }</div>
			<div className={styles.body}>
				<div className={styles.body_profile}>
					<div className={styles.avatar}>
						{/*<Avatar src={user.driver.photo} />*/}
					</div>
					<div className={styles.body}>
						<div className={styles.username}>
							<Link to={`/profile/${user.id}`}>
								{ user.firstName } { user.lastName }
							</Link>
						</div>
						{
							user.reviewsCount > 0
							&& <Rating
								className={styles.rating}
								count={user.rating}
								reviews={ { count: user.reviewsCount } }
							/>
						}
						<div className={styles.info_2}>
							{ convertPhone(user.phone) }{ user.email ? `, ${user.email}` : '' }
						</div>
						<div className={styles.transport}>
							<h4>Транспорт</h4>
							{ transportRender }
							<p className={styles.volume}>
								{ transport.carrying }т., { volume }м<sup>3</sup> <span>(Д{transport.length * 100} x Ш{transport.width * 100} x В{transport.height * 100})</span>
							</p>
							<p className={styles.registerNumber}>
								{ transport.registrationNumber !== undefined && transport.registrationNumber.toUpperCase() }
							</p>
						</div>
					</div>
				</div>
			</div>
		</Aside>
	);
};

export default ExecutorInfo;