exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3iJ9w0CgSsysMpkJpPGsfp {\n  margin: 0;\n  border: 0;\n  background: none;\n  padding: 0;\n  display: grid;\n}\n._3iJ9w0CgSsysMpkJpPGsfp:hover {\n  box-shadow: none;\n}\n._3k_f5x6IVz-4qlMqt6SyfU {\n  margin-top: 50px;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/Profile/MyRouteDetail/style.less"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,UAAU;EACV,iBAAiB;EACjB,WAAW;EACX,cAAc;CACf;AACD;EACE,iBAAiB;CAClB;AACD;EACE,iBAAiB;CAClB","file":"style.less","sourcesContent":[".custom_transport_view {\n  margin: 0;\n  border: 0;\n  background: none;\n  padding: 0;\n  display: grid;\n}\n.custom_transport_view:hover {\n  box-shadow: none;\n}\n.proposal__list {\n  margin-top: 50px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"custom_transport_view": "_3iJ9w0CgSsysMpkJpPGsfp",
	"proposal__list": "_3k_f5x6IVz-4qlMqt6SyfU"
};