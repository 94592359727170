import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import style from './transport.less';
import { ArrowLeft, ArrowRight } from 'components/UI/Icons';
import Car from 'components/Profile/Cars/Car';
import Button from 'components/UI/Form/Button';

const ArrowPrev = props => {
	const { onClick } = props;
	return <div className={style.prev}><Button color="white" size="small" onClick={onClick}>{ ArrowLeft() }</Button></div>
};
const ArrowNext = props => {
	const { onClick } = props;
	return <div className={style.next}><Button color="white" size="small" onClick={onClick}>{ ArrowRight() }</Button></div>
};

class ProfileTransport extends React.Component {

	static propTypes = {
		profile: PropTypes.object.isRequired,
	};

	render() {
		const { profile } = this.props,
			isCustomer = profile.role === 'customer';

		if (isCustomer) return null;

		const transports = profile.driver.transports;
		const settings = {
			dots: false,
			dotsClass: style.dots,
			className: `${style.grid_slider} ${transports.length < 3 ? style.noTopMargin : ''}`,
			prevArrow: <ArrowPrev />,
			nextArrow: <ArrowNext />,
			infinite: false,
			slidesToShow: 2,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						className: `${style.grid_slider} ${transports.length < 2 ? style.noTopMargin : ''}`,
						slidesToShow: 1,
						slidesToScroll: 1,
					}
				},
			]
		};

		return (
			<div className={style.transport}>
				<h3>Транспорт { transports.length }</h3>
				{
					transports.length === 0
					&& <p>Водитель еще не добавил свой транспорт</p>
				}
				{
					transports.length > 0 &&
					<Slider {...settings}>
						{ transports.map(el => <Car {...el} key={el.id} justShow />) }
					</Slider>
				}
			</div>
		)
	}
}

export default ProfileTransport;
