exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.R6q_gYsExsQQT_xiRt7dh {\n  font-size: 15px;\n  color: #848484;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/ReduxForms/PayForm/styles.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,gBAAgB;EAChB,eAAe;CAChB","file":"styles.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.p {\n  font-size: 15px;\n  color: #848484;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"p": "R6q_gYsExsQQT_xiRt7dh"
};