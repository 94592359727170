import { showErrorAsync } from "./showFormErrors";
import { phone, password, passwordConfirm, name, email } from 'libs/validationsForms';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const asyncValidateRegistration = (values /*, dispatch */) => {
	return sleep(100).then(() => {
		showErrorAsync({
			firstName: [name(values.firstName)],
			lastName: [name(values.lastName)],
			email: [email(values.email)],
			phone_number: [phone(values.phone_number)],
			password: [password(values.password)],
			passwordAgain: [passwordConfirm(values.password, values.passwordAgain)],
		})
	});
};

export default asyncValidateRegistration;