import React from "react";
import LayoutField from "components/UI/Wrappers/LayoutField";
import { Field, reduxForm, formValueSelector } from "redux-form";
import Input from "components/UI/Form/Fields/Input";
import {email, phone} from "libs/validationsForms";
import InputSelectGroup from "components/UI/Wrappers/InputSelectGroup";
import Select from 'components/UI/Form/Fields/Select';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import Button from 'components/UI/Form/Button';
import PropTypes from "prop-types";
import Grid from 'components/UI/Wrappers/Grid';
import SelectGroup from 'components/UI/Form/Fields/SelectGroup';
import { compose } from 'redux';
import { connect } from 'react-redux';
import asyncValidate from 'libs/asyncValidate';
import { NumberNormalizeSlice } from 'libs/normalize';
import style from './style.less';

class DriverContactDataForm extends React.Component {

	static propTypes = {
		handleSubmit: PropTypes.func,
		error: PropTypes.string,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
	};

	static defaultProps = {
		handleSubmit: () => {
		},
		invalid: false,
		submitting: false,
		error: '',
	};

	isEnabledSubmit = () => {
		const { values, initialValues } = this.props;
		return (
			values.firstName !== initialValues.firstName ||
			values.lastName !== initialValues.lastName ||
			values.middleName !== initialValues.middleName ||
			values.email !== initialValues.email ||
			values.additionalPhone_number.replace(/\s/g, '') !== initialValues.additionalPhone_number ||
			values.additionalPhone_code !== initialValues.additionalPhone_code ||
			values.city !== initialValues.city ||
			values.experienceYears !== initialValues.experienceYears ||
			values.languages !== initialValues.languages
		);
	};

	render () {
		let { handleSubmit, invalid, submitting } = this.props;
		const isEnabledSubmit = this.isEnabledSubmit();
		return (
			<form onSubmit={handleSubmit} autoComplete="off">
				<Grid col="form-2-notfull">
					<LayoutField>
						<Field
							name="firstName"
							type="text"
							component={Input}
							label="Имя"
						/>
					</LayoutField>
					<LayoutField>
						<Field
							name="lastName"
							type="text"
							component={Input}
							label="Фамилия"
						/>
					</LayoutField>
				</Grid>
				<Grid col="form-2-notfull">
					<LayoutField>
						<Field
							name="middleName"
							type="text"
							component={Input}
							label="Отчество"
						/>
					</LayoutField>
					<LayoutField>
						<Field
							name="email"
							type="text"
							component={Input}
							validate={email}
							label="Email"
						/>
					</LayoutField>
				</Grid>
				<Grid col="form-2-notfull">
					<LayoutField>
						<InputSelectGroup>
							<Field
								name="phone_number"
								type="text"
								component={Input}
								validate={phone}
								disabled
								label="Телефон"
								mask="9999 99 99 99"
								containerGroup="left"
							/>
							<Field
								name="phoneCode"
								component={Select}
								childGroup="left"
								disabled
							>
								{[
									{ value: '7', name: '+7' },
									{ value: '375', name: '+375' },
									{ value: '98', name: '+98' },
								].map(el =>
									<OptionSelect key={el.value} value={el.value}>{el.name}</OptionSelect>
								)}
							</Field>
						</InputSelectGroup>
					</LayoutField>
				</Grid>
				<Grid col="form-2-notfull">
					<LayoutField>
						<span>Дополнительный телефон</span>
						<InputSelectGroup>
							<Field
								name="additionalPhone_number"
								type="text"
								component={Input}
								validate={phone}
								label="Телефон"
								mask="9999 99 99 99"
								containerGroup="left"
							/>
							<Field
								name="additionalPhone_code"
								component={Select}
								childGroup="left"
							>
								{[
									{ value: '7', name: '+7' },
									{ value: '375', name: '+375' },
									{ value: '98', name: '+98' },
								].map(el =>
									<OptionSelect key={el.value} value={el.value}>{el.name}</OptionSelect>
								)}
							</Field>
						</InputSelectGroup>
					</LayoutField>
				</Grid>

				<span className={style.spanFix}>Дополнительная информация</span>
				<Grid col="form-2-notfull">
					<LayoutField>
						<Field
							name="city"
							type="text"
							component={Input}
							label="Город вашего базирования"
						/>
					</LayoutField>
					<LayoutField>
						<Field
							type="number"
							name="experienceYears"
							component={Input}
							label="Стаж вождения, лет"
							normalize={NumberNormalizeSlice}
						/>
					</LayoutField>
				</Grid>
				<Grid col="form-2-notfull">
					<LayoutField>
						<Field
							name="languages"
							component={SelectGroup}
							label="Знание языков"
						>
							<OptionSelect value="ru" filterStr='Русский'>Русский</OptionSelect>
							<OptionSelect value="en" filterStr='Английский'>Английский</OptionSelect>
						</Field>
					</LayoutField>
				</Grid>
				<Button
					disabled={invalid || !isEnabledSubmit}
					loading={submitting}
					size="large"
					type="submit"
				>
					Сохранить изменения
				</Button>
			</form>
		);
	}
}

const selector = formValueSelector('contactData');

export default compose(
	reduxForm({
		form: 'contactData',
		asyncValidate,
		asyncBlurFields: ['firstName', 'lastName', 'middleName', 'email', 'additionalPhone_number'],
	}),
	connect(
		state => ({
			values: selector(state, 'firstName', 'lastName', 'middleName', 'email', 'additionalPhone_number', 'additionalPhone_code', 'city', 'experienceYears','languages'),
		})
	)
)(DriverContactDataForm);
