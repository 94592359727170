import {createActions} from 'redux-actions';

export const {profile: {
				getData,
				setData,
				setDataAfterUpdateProfile, // если было сохранение профиля
				getClientReviews,
				logOut,
				editContactData,
				editAvatarData,
				editPassportData,
				changePassword,
				updatePassword, // update password with old pass
			}} = createActions({
				profile: {
					getData: [() => null, (payload, meta) => meta],
					setData: [(payload) => payload, (payload, meta) => meta],
					setDataAfterUpdateProfile: [(payload) => payload, (payload, meta) => meta],
					getClientReviews: [() => null, (payload, meta) => meta],
					logOut: [() => null, (payload, meta) => meta],
					editContactData: [(payload)=> payload, (payload, meta) => meta],
					editAvatarData: [(payload)=> payload, (payload, meta) => meta],
					editPassportData: [(payload)=> payload, (payload, meta) => meta],
					changePassword: [(payload)=> payload, (payload, meta) => meta],
					updatePassword: [(payload)=> payload, (payload, meta) => meta],
				}
			});
