import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginReduxForm from 'components/ReduxForms/LoginForm';
import GrayBackground from 'components/UI/Wrappers/GrayBackground';
import Container from 'components/UI/Wrappers/Container';
import Grid from 'components/UI/Wrappers/Grid';
import Button from 'components/UI/Form/Button';
// import { MailRu, Ok, Facebook, Vk } from 'components/UI/Icons';
import submitSingIn from './submit';
import { getProfileType } from 'libs/selections';
import style from './style.less';

class Auth extends React.PureComponent {

	handleSubmit = values => {
		return submitSingIn(values, this.props.dispatch);
	};

	render() {
		if (this.props.role !== 'guest') {
			if (this.props.role === 'admin')
				return <Redirect to="/admin/drivers/" />;
			return <Redirect to="/edit/profile" />
		}

		return (
			<GrayBackground left={'client'} right>
				<Container>
					<Grid className={style.container} col="2">
						<div className={style.content}>
							<h2>Вы впервые на сайте?</h2>
							<p>
								Пройдите регистрацию и вам откроется доступ к сервису грузоперевозок с базой водителей, она займет 30
								секунд.
							</p>
							<Link to="/registration/customer" className={style.link}>
								<Button color="white-reg" size="large">Зарегистрироваться</Button>
							</Link>
						</div>
						<div className={`${style.content} ${style.form}`}>
							<LoginReduxForm onSubmit={this.handleSubmit} />

							{/*<div className={style['separator-container']}>*/}
							{/*	<span className={style.separator}>или продолжить через</span>*/}
							{/*</div>*/}
							
							{/*<ul className={style['social']}>*/}
							{/*	<li><Button size="small" className={`${style.socialButton} ${style.mailRu}`}>{ MailRu() }</Button></li>*/}
							{/*	<li><Button size="small" className={`${style.socialButton} ${style.ok}`}>{ Ok() }</Button></li>*/}
							{/*	<li><Button size="small" className={`${style.socialButton} ${style.fb}`}>{ Facebook() }</Button></li>*/}
							{/*	<li><Button size="small" className={`${style.socialButton} ${style.vk}`}>{ Vk() }</Button></li>*/}
							{/*</ul>*/}
						</div>
					</Grid>
				</Container>
			</GrayBackground>
		);
	}
}

export default connect(
	store => ({
		role: getProfileType(store),
	})
)(Auth);