import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import Button from 'components/UI/Form/Button';
import Input from 'components/UI/Form/Fields/Input';
import asyncValidate from 'libs/asyncValidate';

class ContractSMSForm extends React.Component {

	static propTypes = {
		handleSubmit: PropTypes.func,
		error: PropTypes.string,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
	};

	static defaultProps = {
		handleSubmit: () => {
		},
		invalid: false,
		submitting: false,
		error: '',
	};

	isEnabledSubmit = () => {
		const { code } = this.props;
		if (code) return true;
		return false;
	};

	render () {
		let { handleSubmit, invalid, submitting } = this.props;
		const isEnabledSubmit = this.isEnabledSubmit();

		return (
			<form onSubmit={handleSubmit} autoComplete="off">
				<h3 style={{ marginBottom: '20px', fontSize: '18px' }}>
					Для заключения сделки необходимо подтвердить действие.
				</h3>
				<LayoutField>
					<Field
						component={Input}
						name={'code'}
						label={'Введите код из SMS'}
					/>
				</LayoutField>
				<Button
					disabled={invalid || !isEnabledSubmit}
					loading={submitting}
					size={'large'}
					type={'submit'}
				>
					Подписать договор
				</Button>
			</form>
		);
	}
}

const selector = formValueSelector('ContractSMSForm');

export default compose(
	reduxForm({
		form: 'ContractSMSForm',
		asyncValidate,
		asyncBlurFields: ['code'],
	}),
	connect(
		state => ({
			code: selector(state, 'code')
		})
	)
)(ContractSMSForm);