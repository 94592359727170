import React from 'react';
import style from '../style.less';
import { CustomTabs } from 'components/UI/CustomTab';
import { TabPanel } from 'react-tabs';
import Intercity from 'components/ReduxForms/Search/SearchCargoes/Intercity';
import Button from 'components/UI/Form/Button';
import { ArrowDownNavigate } from 'components/UI/Icons';
import { formValueSelector, reduxForm, reset, stopSubmit, change } from 'redux-form';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import CityForm from 'components/ReduxForms/Search/SearchCargoes/CityForm';
import styles from 'components/Search/style.less';
import Panel from 'components/UI/Wrappers/Panel';

class SearchCargoes extends React.PureComponent {
	
	static propTypes = {
		handleSubmit: PropTypes.func,
		error: PropTypes.string,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
	};
	
	static defaultProps = {
		handleSubmit: () => {
		},
		invalid: false,
		submitting: false,
		error: '',
	};
	
	state = {
		showAllParams: false,
		tabList: [{ title: 'Междугородние' }, { title: 'По городу' }],
		selectedTab: this.props.initialValues ? this.props.initialValues.geography ? this.props.initialValues.geography === 'city' ? 1 : 0 : 0 : 0,
	};
	
	componentWillMount() {
		this.props.dispatch(change('searchCustomers', 'geography', 'country'));
	}
	
	toggleParams = () => this.setState({ showAllParams: !this.state.showAllParams });
	
	handleSelect = selectedTab => {
		let val = 'country';
		if (selectedTab === 1) val = 'city';
		
		this.props.dispatch(change('searchCustomers', 'geography', val));
		this.setState({ selectedTab });
	};
	
	reset = () => { this.props.dispatch(reset('searchCustomers')); this.props.resetData() };

	render() {
		let { handleSubmit, invalid } = this.props;

		return (
			<Panel
				className={styles.panelForm}
				marginBottom={30}
			>
				<CustomTabs
					onClickTab={this.handleSelect}
					selectedTab={this.state.selectedTab}
					tabList={this.state.tabList}
					className={style.tabs}
				>
					
					<TabPanel>
						
						<form onSubmit={handleSubmit} autoComplete="off">
							
							<Intercity showAllParams={this.state.showAllParams} toggleParams={this.toggleParams} />
							
							<div className={style.footer}>
								<Button
									className={`${style.extendedSearchBtn} ${this.state.showAllParams ? style.openButton : ''}`}
									onClick={this.toggleParams}
								>
									<span>{ this.state.showAllParams ? 'Скрыть поиск' : 'Расширенный поиск' }</span>
									{ ArrowDownNavigate() }
								</Button>
								<div className={style.searchIn}>
									{/*<Button onClick={this.reset} size={'large'} color={'remove'}>*/}
									{/*	Сброс*/}
									{/*</Button>*/}
									<Button disabled={invalid} size={'large'} type={'submit'}>
										Показать грузы
									</Button>
								</div>
							</div>
						</form>
						
					</TabPanel>
					
					<TabPanel>
						
						<form onSubmit={handleSubmit} autoComplete="off">
							<CityForm showAllParams={this.state.showAllParams} toggleParams={this.toggleParams} />
							
							<div className={style.footer}>
								<Button
									className={`${style.extendedSearchBtn} ${this.state.showAllParams ? style.openButton : ''}`}
									onClick={this.toggleParams}
								>
									<span>{ this.state.showAllParams ? 'Скрыть поиск' : 'Расширенный поиск' }</span>
									{ ArrowDownNavigate() }
								</Button>
								<div className={style.searchIn}>
									{/*<Button onClick={this.reset} size={'large'} color={'remove'}>*/}
									{/*	Сброс*/}
									{/*</Button>*/}
									<Button disabled={invalid} size={'large'} type={'submit'}>
										Показать грузы
									</Button>
								</div>
							</div>
						</form>
						
					</TabPanel>
					
				</CustomTabs>
			</Panel>
		);
	}
}

const selector = formValueSelector('searchCustomers');

export default compose(
	connect(
		store => ({
			point_a: selector(store, 'point_a'),
			point_b: selector(store, 'point_b'),
		})
	),
	reduxForm({
		form: 'searchCustomers',
		onChange: (newValues, dispatch) => {
			dispatch(stopSubmit('searchCustomers', {}));
		}
	})
)(SearchCargoes);