import shortName from 'libs/shortName';
import React from 'react';
import { Link } from 'react-router-dom';

// OfferNotification => [ 'Заявка отменена'],
export const CancellRequest = ({ params }) => {
    const { offerId } = params;
    return (
        <React.Fragment>
            Предложение на <Link to={`/offer/${offerId}`}>заявку №{offerId}</Link> отклонено
        </React.Fragment>
    );
};

// OfferNotification => [ 'Заявка отменена'],
export const CancellRequestAdmin = ({ params }) => {
    const { offerId } = params;
  return (
        <React.Fragment>
          <Link to={`/offer/${offerId}`}>Заявка №{offerId}</Link> отклонена. <br/>
          <b>Причина:</b><br/>{ params.description }
        </React.Fragment>
    );
};

export const RoutePublished = ({ params }) => {
  const { routeId } = params;
  return (
      <React.Fragment>
        <Link to={`/route/${routeId}`}>Маршрут №{routeId}</Link> опубликован.
      </React.Fragment>
  );
};

export const RouteCancelled = ({ params }) => {
  const { routeId } = params;
  return (
      <React.Fragment>
        <Link to={`/route/${routeId}`}>Маршрут №{routeId}</Link> отклонен. <br/>
        <b>Причина:</b><br/>{ params.description }
      </React.Fragment>
  );
};


export const VerifyFalse = ({ params }) => {
    return (
        <React.Fragment>
            Верификация отклонена.
            {
                params.message &&
                <>
                    <br/>
                    <b>Причина:</b><br/>{ params.message }
                </>
            }
        </React.Fragment>
    );
};

export const VerifyTrue = ({ params }) => {
    return (
        <React.Fragment>
            Верификация профиля пройдена.
        </React.Fragment>
    );
};

// ProposalNotification
// Ваше предложение принято
export const AcceptOffer = ({ params }) => {
    // const { offerId } = params;
    return (
        <React.Fragment>
            Ваше предложение по заявке принято.<br/> <Link
            to={'/trades'}>Посмотреть сделки</Link>
        </React.Fragment>
    );
};

// Предложение отменено
export const CancellOffer = ({ params }) => {
    const { offerId } = params;
    return (
        <React.Fragment>
            Предложение на <Link to={`/offer/${offerId}`}>заявку №{offerId}</Link> было отменено
        </React.Fragment>
    );
};

// Новое предложение
export const NewOffer = ({ params, role }) => {
    const { offerId, executor } = params;
    if (role === 'driver')
        return (
            <React.Fragment>
                Вы отправили предложение на <Link to={`/offer/${offerId}`}>заявку №{offerId}</Link>
            </React.Fragment>
        );

    return (
        <React.Fragment>
            Поступило предложение на <Link to={`/offer/${offerId}`}>заявку №{offerId}</Link> от {' '}
            <Link to={`/profile/${executor.id}`}>{shortName(executor.lastName, executor.firstName)}</Link>
        </React.Fragment>
    );
};

// Предложение отклонено
export const RejectedOffer = ({ params }) => {
    const { offerId } = params;
    return (
        <React.Fragment>
            Предложение на <Link to={`/offer/${offerId}`}>заявку №{offerId}</Link> было отклонено
        </React.Fragment>
    );
};


// TradeNotification =>
// Договор подписан
export const ContractSigned = ({ params }) => {
    const { tradeId } = params;
    return (
        <React.Fragment>
            Договор по <Link to={`/trade/${tradeId}`}>сделке №{tradeId}</Link> подписан клиентом
        </React.Fragment>
    );
};

// Исполнитель подтвердил заключение сделки
export const ExecutorConfirmMakeDeal = ({ params }) => {
    const { tradeId, executor } = params;
    return (
        <React.Fragment>
            Перевозчик <Link
            to={`/profile/${executor.id}`}>{shortName(executor.lastName, executor.firstName)}</Link>
            подтвердил заключение <Link to={`/trade/${tradeId}`}>сделки №{tradeId}</Link>
        </React.Fragment>
    );
};

// Сделка отменена
export const CancelledDeal = ({ params }) => {
    const { tradeId } = params;
    return (
        <React.Fragment>
            <Link to={`/trade/${tradeId}`}>Сделка №{tradeId}</Link> отменена
        </React.Fragment>
    );
};

// Отправлен запрос на редактирование условий сделки
export const EditRequest = ({ params }) => {
    const { tradeId, executor } = params;
    return (
        <React.Fragment>
            Перевозчик <Link
            to={`/profile/${executor.id}`}>{shortName(executor.lastName, executor.firstName)}</Link>
            отправил запрос на редактирование данных по <Link to={`/trade/${tradeId}`}>сделке №{tradeId}</Link>
        </React.Fragment>
    );
};

// Заказчик отредактировал условия сделки
export const EditedDealTerms = ({ params }) => {
    const { customer } = params;
    return (
        <React.Fragment>
            Клиент <Link to={`/profile/${customer.id}`}>{shortName(customer.lastName, customer.firstName)}</Link>
            отредактировал данные о грузе
        </React.Fragment>
    );
};

// Исполнитель принял груз
export const CustomerAcceptedCargo = ({ params }) => {
    const { tradeId, executor } = params;
    return (
        <React.Fragment>
            Перевозчик <Link
            to={`/profile/${executor.id}`}>{shortName(executor.lastName, executor.firstName)}</Link>
            принял груз и готов к перевозке по <Link to={`/trade/${tradeId}`}>сделке №{tradeId}</Link>
        </React.Fragment>
    );
};

// Исполнитель начал маршрут
export const CustomerBeganRoute = ({ params }) => {
    const { tradeId, executor } = params;
    return (
        <React.Fragment>
            Перевозчик <Link
            to={`/profile/${executor.id}`}>{shortName(executor.lastName, executor.firstName)}</Link>
            начал перевозку груза по <Link to={`/trade/${tradeId}`}>сделке №{tradeId}</Link>
        </React.Fragment>
    );
};

// Разгрузка - ?????
export const Unloading = ({ params }) => {
    // const { tradeId, executor } = params;
    return (
        <React.Fragment>
            test
        </React.Fragment>
    );
};

// Сделка расторгнута
export const DealTerminated = ({ params }) => {
    const { tradeId } = params;
    return (
        <React.Fragment>
            <Link to={`/trade/${tradeId}`}>Сделка №{tradeId}</Link> расторгнута
        </React.Fragment>
    );
};

// Создан  арбитраж по сделке
export const ArbitrationCreated = ({ params }) => {
    const { tradeId } = params;
    return (
        <React.Fragment>
            Создан арбитраж по <Link to={`/trade/${tradeId}`}>сделке №{tradeId}</Link>
        </React.Fragment>
    );
};

// Средства зарезервированы
export const FundsReserved = ({ params }) => {
    const { tradeId } = params;
    return (
        <React.Fragment>
            Оплата по <Link to={`/trade/${tradeId}`}>сделке №{tradeId}</Link> поступила на счет сервиса и была
            зарезервирована до окончания поездки
        </React.Fragment>
    );
};

// Сделка завершена
export const DealComplited = ({ params }) => {
    const { tradeId } = params;
    return (
        <React.Fragment>
            <Link to={`/trade/${tradeId}`}>Сделка №{tradeId}</Link> завершена
        </React.Fragment>
    );
};

// Заявка опубликована§
export const RequestPubliched = ({ params }) => {
    const { offerId } = params;
    return (
        <React.Fragment>
            <Link to={`/offer/${offerId}`}>Заявка №{offerId}</Link> была опубликована
        </React.Fragment>
    );
};
