import React from 'react';
import PropTypes from 'prop-types';
import {change, Field} from 'redux-form';
import Address2 from 'components/UI/Form/Fields/Address2';
import Button from 'components/UI/Form/Button';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import InputSelectGroup from "components/UI/Wrappers/InputSelectGroup";
import Panel from 'components/UI/Wrappers/Panel';
import {rmPointByKey} from 'actions/map';
import {add} from 'actions/notifications';
import Input from 'components/UI/Form/Fields/Input';
import InputDate from 'components/UI/Form/Fields/InputDate';
import { defaultTime } from 'libs/validationsForms';
import Select from 'components/UI/Form/Fields/Select';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import Grid from 'components/UI/Wrappers/Grid';
import { AddSong } from 'components/UI/Icons';
import Point from 'components/UI/Point';
import style from './style.less';
import Checkbox from 'components/UI/Form/Fields/Checkbox';

export default class LocationPoints extends React.Component{

	ABC = 'ABCDEF';

	static propTypes = {
		points: PropTypes.array.isRequired,
		dispatch: PropTypes.func.isRequired,
	};

	setValue = (field, value) => {
		this.props.dispatch(change('addCargo', field, value));
	};

	handleAddPoint = index => {
		let key = this.ABC.charAt(index),
			fields = this.props.fields.getAll();

		if(fields.filter(it=>it.key === key).length > 0)
			return this.handleAddPoint(++index);
		if(key){
			this.props.fields.push({key});
		} else {
			this.props.dispatch(add({
				text: 'Добавлено максимальное колличество точек выгрузки',
				type: 'info',
				time: 5,
			}));
		}
	};

	handleRemovePoint = index => {
		this.props.dispatch(rmPointByKey(this.ABC.charAt(index)));
		this.props.fields.remove(index);
	};

	setMyData = e => {
		const { profile } = this.props;
		const name = 'route.items[0].providerName';
		const phone = 'route.items[0].providerPhone';

		if (this.props.profile !== null)
			if (e) {
				this.props.dispatch(change('addCargo', name, `${profile.firstName} ${profile.lastName}`));
				this.props.dispatch(change('addCargo', phone, profile.phone.slice(1)));
			} else {
				this.props.dispatch(change('addCargo', name, ''));
				this.props.dispatch(change('addCargo', phone, ''));
			}
	};

	render() {
		const { points, fields, dispatch, role } = this.props;

		return (
			<div>
				{fields.map((location, index) => {
					const text = [['загрузке','Дата загрузки','загрузки'], ['разгрузке','Доставить до','разгрузки']],
						textProvider = ['Грузоотправитель', 'Грузополучатель'];
					let field = fields.get(index),
						choise = 0;
					field.key = field.key ? field.key : field.address ? field.address.key : this.ABC[index];
					if (field.key !== 'A') choise = 1;
					return (
						<Panel
							key={field.key}
							title={`Информация о ${text[choise][0]}`}
							className={style.afterPanel}
							marginBottom={30}
						>
							<LayoutField>
								<span>Укажите адрес {text[choise][2]}, используя поиск по буквам</span>
								<InputSelectGroup>
									<Point point={field.key} className={style.pointLine} absolute />
									<Address2
										setValue={this.setValue}
										dispatch={dispatch}
										renderToMap={field.key}
										name={`${location}.address`}
										label="Город, населёный пункт, улица, дом"
									/>
								</InputSelectGroup>
							</LayoutField>
							<Grid col="form-2">
								<LayoutField>
									<Field
										name={`${location}.startDate`}
										component={InputDate}
										label={text[choise][1]}
									/>
								</LayoutField>
								<LayoutField>
									<Field
										validate={defaultTime}
										name={`${location}.timeIntervalLoad`}
										component={Select}
										label={`Время ${text[choise][2]}`}
									>
										<OptionSelect value='00:00-00:00'>В любое время</OptionSelect>
										<OptionSelect value='06:00-09:00'>06:00 — 09:00</OptionSelect>
										<OptionSelect value='09:00-12:00'>09:00 — 12:00</OptionSelect>
										<OptionSelect value='12:00-15:00'>12:00 — 15:00</OptionSelect>
										<OptionSelect value='15:00-18:00'>15:00 — 18:00</OptionSelect>
										<OptionSelect value='18:00-21:00'>18:00 — 21:00</OptionSelect>
									</Field>
								</LayoutField>
							</Grid>
							<Grid col="form-2">
								<LayoutField>
									<Field
										type="text"
										name={`${location}.providerName`}
										component={Input}
										label={textProvider[choise]}
									/>
								</LayoutField>
								<LayoutField>
									<InputSelectGroup>
										<Field
											name={`${location}.providerPhone`}
											type="text"
											component={Input}
											label="Телефон"
											mask="999 999 99 99"
											containerGroup="left"
										/>
										<Field
											name={`${location}.phone_code`}
											component={Select}
											childGroup="left"
										>
											<OptionSelect value='7'>+7</OptionSelect>
											<OptionSelect value='375'>+375</OptionSelect>
											<OptionSelect value='98'>+98</OptionSelect>
										</Field>
									</InputSelectGroup>
								</LayoutField>
							</Grid>
							{
								field.key === 'A' && role !== 'guest' && !this.props.isOfferID &&
								<Field
									name="iam"
									component={Checkbox}
									label={'Я являюсь отправителем груза'}
									onCustomChange={e => this.setMyData(e)}
								/>
							}
							{
								(fields.length > 2 && index > 1) &&
								<Button color="remove" className={style.buttonDelete} onClick={()=>this.handleRemovePoint(index)}>
									Удалить
								</Button>
							}
							{
								fields.length === index + 1 && fields.length < 6 &&
								<Button
									onClick={()=>this.handleAddPoint(points.length)}
									color="white"
									size="large"
									className={style.addNewAddress}
								>
									{ AddSong() }
									Добавить ещё один адрес
								</Button>
							}
						</Panel>
					);
				})}
			</div>
		);
	}
};
