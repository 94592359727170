import React from 'react';
import PropTypes from 'prop-types';
import style from 'components/UI/_V2/Proposals/Dialog/style.less';
import Dialog from 'components/UI/Dialog';


const ProposalDialog = props => {

	const { close, children } = props;

	return (
		<Dialog
			handleClose={close}
			className={style.proposalDialog}
		>
			<h3>Добавить предложение</h3>
			{ children }
		</Dialog>
	)
};


ProposalDialog.propTypes = {
	dialogClose: PropTypes.func,
	children: PropTypes.node,
};

ProposalDialog.defaultProps = {
	dialogClose: () => {},
	children: '',
};



export default ProposalDialog;
