import React, { Fragment } from 'react';
import { compose } from 'redux';
import { reduxForm, formValueSelector, Field } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'components/UI/Form/Button';
import { driverActions } from 'actions/driver';
import { getDriverCarts } from 'libs/selections';
import Select from 'components/UI/Form/Fields/Select';
import Input from 'components/UI/Form/Fields/Input';
import getCardOptions from 'components/ReduxForms/PayForm/cardLib';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import styles from './styles.less';

class PayForm extends React.Component {
	
	static propTypes = {
		handleSubmit: PropTypes.func,
		error: PropTypes.string,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
	};
	
	static defaultProps = {
		handleSubmit: () => {},
		invalid: false,
		submitting: false,
		error: '',
	};
	
	state = {
		dialogAddcard: false,
	};
	
	componentWillMount() {
		this.props.dispatch(driverActions.getCards());
	}
	
	render() {
		const { invalid, handleSubmit, submitting, cards, dialogAddcard } = this.props;
		
		return (
			<Fragment>
				<form onSubmit={handleSubmit} autoComplete="off">
					<h3>Оплата перевозки</h3>
					<p className={styles.p}>
						Выберите карту и введите cvv код от банковской карты. После нажатия кнопки вы будете
						перенаправлены на страницу подтверждения оплаты
					</p>
					<LayoutField>
						<Field
							name={'payMethodId'}
							component={Select}
							label={'Выберите карту для оплаты'}
						>
							{ getCardOptions(cards, dialogAddcard) }
						</Field>
					</LayoutField>
					
					<LayoutField>
						<Field
							name={'cvv'}
							component={Input}
							label={'CVV код'}
							mask={'999'}
						/>
					</LayoutField>
					
					<Button
						disabled={invalid}
						loading={submitting}
						color={'green'}
						size={'large'}
						type={'submit'}
					>
						Произвести оплату
					</Button>
				</form>
			</Fragment>
		)
	}
}

const selector = formValueSelector('PayTradeForm');

export default compose(
	reduxForm({
		form: 'PayTradeForm',
	}),
	connect(
		state => ({
			cards: getDriverCarts(state),
			values: selector(state, '')
		})
	)
)(PayForm);