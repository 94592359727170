import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';
import { MapMarker } from 'components/UI/Icons';

const Point = props => {
	const classPoint = style[`point_${props.point}`];
	return (
		<div className={`${style.point} ${classPoint} ${props.absolute ? style.absolute : ''} ${props.className}`}>
			<div className={`pointLine ${style.pointLine} ${props.pointLineClass}`} />
			{
				props.point === 'thirdPoint' && '...'
			}
			{
				props.point !== 'thirdPoint' && props.point !== 'map' && props.point
			}
			{
				props.point === 'map' && <MapMarker />
			}
		</div>
	);
};

Point.propTypes = {
	point: PropTypes.string,
	className: PropTypes.string,
	absolute: PropTypes.bool,
};
Point.defaultProps = {
	point: '',
	className: '',
	absolute: false,
};

export default Point;
