import React, { Component } from 'react';
import RatesForDriver from 'components/BecomeCarrier/RatesForDriver';
import { connect } from 'react-redux';
import { getProfileType } from 'libs/selections';
import bg from 'components/HelpfulInformations/assets/bg_tariffs.svg';
import MainWhiteContainer from 'components/UI/Wrappers/MainWhiteContainer';
import styles from 'components/HelpfulInformations/style.less';
import Navigation from 'components/HelpfulInformations/common/navigation';
import MainScreen from 'components/HelpfulInformations/common/main';

class Tariffs extends Component {

	render() {
		return (
			<MainScreen title={'Тарифы'}
						subtitle={'Сервис берет фиксированную комиссию за выполнение определенного количества заказов'}
						bg={bg}
			>
				<MainWhiteContainer className={`${styles.advantage__screen} ${styles.not_padding} ${styles.noBorder}`}>
					<Navigation isCarrier isActive={2}/>
				</MainWhiteContainer>
				<RatesForDriver role={this.props.role} />
				<div className={styles.container_cards2} />
			</MainScreen>
		);
	}
}

export default connect(
	state => ({
		role: getProfileType(state),
	})
)(Tariffs);
