import React, { Fragment } from 'react';
import AdminLoader from 'components/Admin/UI/Loader';
import listStyle from 'components/Admin/UI/List/style.less';
import OfferItem from 'components/Admin/Pages/Offers/List/OfferItem';
import NotFound from 'components/Admin/UI/List/NotFound';


const OfferContent = props => (
	<Fragment>
		{
			props.loading &&
			<AdminLoader className={listStyle.loading} />
		}
		{
			!props.loading && props.list.total > 0 &&
			<OfferItem users={props.list.items} link={props.link} setOfferStatus={props.setOfferStatus}/>
		}
		{
			!props.loading && props.list.total === 0 &&
			<NotFound style={{ paddingLeft: '20px' }} />
		}
	</Fragment>
);


export default OfferContent;