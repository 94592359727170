import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { required } from 'libs/validationsForms';
import Input from 'components/UI/Form/Fields/Input';
import Button from 'components/UI/Form/Button';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import InputDate from 'components/UI/Form/Fields/InputDate';
import Select from 'components/UI/Form/Fields/Select';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import style from 'components/Profile/OfferDetail/style.less';
import cargoForm from 'components/ReduxForms/AddCargoForm/style.less';
import Grid from 'components/UI/Wrappers/Grid';
import Panel from 'components/UI/Wrappers/Panel';
import Dimensions from 'components/ReduxForms/Dimensions';
import {
	getCargoListStore,
	getPackageListStore
} from 'libs/selections';

let ChangeOffer = (props) => {
	let { handleSubmit, invalid } = props;

	return (
		<form onSubmit={handleSubmit} autoComplete="off">
			<Panel className={style.afterPanel} title="Информация о грузе" marginBottom={30}>
				<LayoutField>
					<Field
						name="price"
						type="number"
						component={Input}
						validate={required}
						label="Укажите сумму сделки"
						unit={'р.'}
					/>
				</LayoutField>
				<LayoutField className={cargoForm.width75}>
					<Field
						name="cargo[category]"
						component={Select}
						label="Тип груза"
					>
						{
							props.categoryList.map((el) => (
								<OptionSelect value={el.id} key={el.id}>
									{ el.titleRu }
								</OptionSelect>
							))
						}
					</Field>
				</LayoutField>
				<LayoutField>
					<Field
						name="cargo[title]"
						type="text"
						component={Input}
						validate={required}
						left={100}
						label="Название груза"
					/>
				</LayoutField>
				<Grid col="form-3">
					<LayoutField>
						<Field
							name="cargo[weight]"
							type="number"
							component={Input}
							validate={required}
							label="Вес груза"
							unit={'кг.'}
						/>
					</LayoutField>
					<LayoutField>
						<Field
							name="cargo[weightCount]"
							type="number"
							component={Input}
							validate={required}
							label="Грузовых мест"
						/>
					</LayoutField>
					<LayoutField>
						<Field
							name="cargo[package]"
							component={Select}
							label="Упаковка"
						>
							{
								props.packages.map((el) => (
									<OptionSelect value={el.id} key={el.id}>
										{ el.titleRu }
									</OptionSelect>
								))
							}
						</Field>
					</LayoutField>
				</Grid>
				<Dimensions nameForm={'changeOffer'} />
				<Grid col="form-2">
					<LayoutField>
						<Field
							name="startDate"
							component={InputDate}
							label="Дата загрузки"
						/>
					</LayoutField>
					<LayoutField>
						<Field
							name="endDate"
							component={InputDate}
							label="Дата разгрузки"
						/>
					</LayoutField>
				</Grid>
				<Button
					disabled={invalid}
					size={'large'}
					type={'submit'}
				>
					Сохранить изменения
				</Button>
				<p>
					После сохранения изменений клиенту придет уведомление. Далее он сможет проверить данные о сделке и
					подтвердить условия договора.
				</p>
			</Panel>
		</form>
	);
};

ChangeOffer.propTypes = {
	handleSubmit: PropTypes.func,
	error: PropTypes.string,
	submitting: PropTypes.bool,
	invalid: PropTypes.bool,
};

ChangeOffer.defaultProps = {
	handleSubmit: () => {
	},
	invalid: false,
	submitting: false,
	error: '',
};

export default compose(
	connect(
		store => ({
			categoryList: getCargoListStore(store),
			packages: getPackageListStore(store),
		})
	),
	reduxForm({
		form: 'ChangeOffer',
	})
)(ChangeOffer);
