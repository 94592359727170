exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1XTGoEn0zDzDj7Uqpqko09 {\n  padding-left: 40px;\n  position: relative;\n}\n._1XTGoEn0zDzDj7Uqpqko09 p {\n  padding-top: 8px;\n  font-size: 16px;\n  color: #353c4b;\n  margin: 0;\n}\n._2z3BKsbkom27pKi8cuE2Kw {\n  left: 0;\n  margin-left: -10px;\n  position: absolute;\n}\n._2kA_fYao74J4GiKXmca1dQ {\n  margin-top: 6px;\n}\n._3yOTVRY5SLhXlpv9TgkWfR {\n  color: #848484;\n  display: block;\n  font-size: 15px;\n  margin-top: 3px;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/UI/RouteDetail/styles.less"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;CACpB;AACD;EACE,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,UAAU;CACX;AACD;EACE,QAAQ;EACR,mBAAmB;EACnB,mBAAmB;CACpB;AACD;EACE,gBAAgB;CACjB;AACD;EACE,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,gBAAgB;CACjB","file":"styles.less","sourcesContent":[".container {\n  padding-left: 40px;\n  position: relative;\n}\n.container p {\n  padding-top: 8px;\n  font-size: 16px;\n  color: #353c4b;\n  margin: 0;\n}\n.point {\n  left: 0;\n  margin-left: -10px;\n  position: absolute;\n}\n.address {\n  margin-top: 6px;\n}\n.publicPeriod {\n  color: #848484;\n  display: block;\n  font-size: 15px;\n  margin-top: 3px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "_1XTGoEn0zDzDj7Uqpqko09",
	"point": "_2z3BKsbkom27pKi8cuE2Kw",
	"address": "_2kA_fYao74J4GiKXmca1dQ",
	"publicPeriod": "_3yOTVRY5SLhXlpv9TgkWfR"
};