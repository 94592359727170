import React from 'react';
import leftStyle from 'components/Admin/UI/LeftContent/style.less';
import AdminGrid from 'components/Admin/UI/Grid';
import LeftContent from 'components/Admin/UI/LeftContent';
import { Link } from 'react-router-dom';


const LeftMenu = props => {
	
	return (
		<div>
			<AdminGrid type="aside">
				<LeftContent title={'Меню'}>
					<ul className={leftStyle.list}>
						<li className={leftStyle.item}>
							<Link to={'/admin/offers/'}>Вернуться назад</Link>
						</li>
					</ul>
				</LeftContent>
			</AdminGrid>
		</div>
	)
};


export default LeftMenu;