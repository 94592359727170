// import { driverActions } from 'actions/driver';
import * as adminActions from 'actions/admin';
import LeftMenu from 'components/Admin/Pages/Offers/List/LeftMenu';
import OfferContent from 'components/Admin/Pages/Offers/List/OfferContent';
import SearchAdminForm from 'components/Admin/ReduxForms/SearchAdminForm';
import AdminGrid from 'components/Admin/UI/Grid';
import AdminList from 'components/Admin/UI/List';


import AdminPage from 'components/Admin/UI/Page';
import AdminPagination from 'components/Admin/UI/Pagination';
import UpdateButton from 'components/Admin/UI/UpdateButton';
import { convertNumberSpaces } from "libs/convertNumberSpaces";
import { getRoutes } from 'libs/selections';
import { statusListRoute } from "libs/vars";
import React from 'react';
import { connect } from 'react-redux';


class AdminRouteList extends React.Component {

    state = {
        params: {
            status: 'all',
            page: 1,
            pageSize: 30,
        },
        loading: false
    };

    getRoutes = status => {
        this.props.dispatch(adminActions.getRoutes({
            status: statusListRoute[status],
            page: 1,
            pageSize: 30,
            type: status
        }));
        this.setState({ params: { ...this.state.params, status } })
    };

    setPage = page => {
        const { params: { status, pageSize } } = this.state;
        this.setState({ params: { ...this.state.params, page } });
        this.props.dispatch(adminActions.getRoutes({ status: statusListRoute[status], page, pageSize, type: status }));
    };

    componentWillMount() {
        this.getRoutes('moderation');
        this.getRoutes('all');
    }

    render() {

        const { routes } = this.props,
            { params: { status, page } } = this.state;

        const titles = ['ID', 'Маршрут', 'Информация', 'Статус'];
        let pageCount = Math.ceil(routes[status].total / 30);


        let list = [
            {
                onClick: () => this.getRoutes('all'),
                isActive: status === 'all',
                title: 'Список',
                count: convertNumberSpaces(routes.all.total),
                newCount: convertNumberSpaces(routes.all.meta.totalToday)
            },
            {
                onClick: () => this.getRoutes('moderation'),
                isActive: status === 'moderation',
                title: 'Модерация',
                count: convertNumberSpaces(routes.moderation.total),
                newCount: convertNumberSpaces(routes.moderation.meta.totalToday)
            }
        ];

        return (
            <AdminPage>
                <LeftMenu
                    title="Маршруты"
                    list={list}
                />
                <div>
                    <AdminGrid type="mainContent">
                        <h1>
                            Список маршрутов
                            <UpdateButton
                                onClick={() => this.getRoutes(status)}
                            />
                        </h1>
                        <SearchAdminForm/>
                        <AdminList
                            titles={titles}
                            type="offers"
                        >
                            <OfferContent
                                list={routes[status] ? routes[status] : false}
                                loading={this.state.loading}
                                setOfferStatus={this.setOfferStatus}
                                link="/admin/route/"
                            />
                            {
                                pageCount > 1 &&
                                <AdminPagination
                                    pageCount={pageCount}
                                    activePage={page}
                                    setPage={e => this.setPage(e)}
                                />
                            }
                        </AdminList>
                    </AdminGrid>
                </div>
            </AdminPage>
        );
    }


}


export default connect(
    state => ({
        routes: getRoutes(state)
    })
)(AdminRouteList);


// render() {
//     return (
// 			<Page title={'Список маршрутов'}>
//
//
//           {
//               this.props.routes.published.total > 0 &&
//               this.props.routes.published.items.map(el => {
//                   return (
// 										<div key={el.id}>
// 											<div>Маршрут №{el.id}</div>
// 											<div style={{ display: 'flex', alignItems: 'center' }}>
// 												Статус: <Status status={el.status} />
// 											</div>
// 											<div>
//                           {
//                               el.status !== 'published' &&
// 															<Button
// 																color={'green'}
// 																onClick={() => this.changeStatus({ id: el.id, status: 'published' })}
// 															>
// 																Опубликовать
// 															</Button>
//                           }
//                           {
//                               el.status !== 'cancelled' &&
// 															<Button
// 																color={'remove'}
// 																onClick={() => this.changeStatus({ id: el.id, status: 'cancelled' })}
// 															>
// 																Отклонить
// 															</Button>
//                           }
//
// 											</div>
// 										</div>
//                   )
//               })
//           }
// 			</Page>
//     )
// }