import React, { Component } from 'react';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Input from 'components/UI/Form/Fields/Input';
import Button from 'components/UI/Form/Button';
import InputSelectGroup from 'components/UI/Wrappers/InputSelectGroup';
import Select from 'components/UI/Form/Fields/Select';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import {password} from "libs/validationsForms";
import style from './style.less';
import { compose } from 'redux';
import Grid from 'components/UI/Wrappers/Grid';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import asyncValidateRegistration from 'libs/asyncValidateRegistration';
import asyncValidate from 'libs/asyncValidate';


class RegistrationReduxForm extends Component {

	static propTypes = {
		handleSubmit: PropTypes.func,
		error: PropTypes.string,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
	};

	static defaultProps = {
		handleSubmit: () => {},
		invalid: false,
		submitting: false,
		error: '',
	};

	onChange = token => {
		this.props.dispatch(change('registration', 'recaptchaValue', token));
	};

	isEnabledSubmit = () => {
		const { firstName, lastName, email, phone_number, password, passwordAgain, recaptchaValue } = this.props.values;
		return firstName && lastName && email && phone_number && password && passwordAgain && recaptchaValue
	};

	render() {

		let { handleSubmit, invalid, initialValues } = this.props;

		const isEnabledSubmit = this.isEnabledSubmit();

		return(
			<form onSubmit={handleSubmit} autoComplete="off">
				<h1>Регистрация { initialValues.driver ? 'водителя' : 'клиента' }</h1>
				<p>После регистрации вам будет доступeн сервис</p>
				
				<Grid col="form-2col">
					<LayoutField>
						<Field
							name="firstName"
							component={Input}
							label="Имя"
						/>
					</LayoutField>
					<LayoutField>
						<Field
							name="lastName"
							component={Input}
							label="Фамилия"
						/>
					</LayoutField>
				</Grid>
				<LayoutField>
					<Field
						name="email"
						component={Input}
						label="Email"
					/>
				</LayoutField>
				<LayoutField>
					<InputSelectGroup>
						<Field
							name="phone_number"
							component={Input}
							label="Телефон"
							mask="999 999 99 99"
							containerGroup="left"
						/>
						<Field
							name="code"
							component={Select}
							childGroup="left"
						>
							<OptionSelect value='7'>+7</OptionSelect>
							<OptionSelect value='375'>+375</OptionSelect>
							<OptionSelect value='98'>+98</OptionSelect>
						</Field>
					</InputSelectGroup>
				</LayoutField>
				<Grid col="form-2col">
					<LayoutField>
						<Field
							name="password"
							type="password"
							component={Input}
							hint={'Пароль должен содержать не менее 6 символов'}
							label="Введите пароль"
						/>
					</LayoutField>
					<LayoutField>
						<Field
							name="passwordAgain"
							type="password"
							component={Input}
							label="Повторите пароль"
						/>
					</LayoutField>
					
					<div style={{ marginBottom: '15px' }}>
						<ReCAPTCHA
							sitekey="6Lf5T7kUAAAAALGUCClRc6jZmwEUcGhW9OcZYuHm"
							ref={this.props.recaptchaRef}
							onChange={this.onChange}
						/>
					</div>
				</Grid>
				
				<Button
					disabled={invalid || !isEnabledSubmit}
					className={style.button}
					size="large"
					type="submit"
				>
					Зарегистрироваться
				</Button>
				{
					initialValues.driver ?
						<Link style={{ marginTop: '20px' }} to={'/registration/customer'}>Я клиент и хочу перевезти груз</Link> :
						<Link style={{ marginTop: '20px' }} to={'/registration/driver'}>Я водитель и хочу заняться перевозкой грузов</Link>
				}
			</form>
		)
	}
}

export const RegistrationReduxFormFields = props => (
	<div>
		<Grid col="form-2col">
			<LayoutField>
				<Field
					name="firstName"
					component={Input}
					label="Имя"
				/>
			</LayoutField>
			<LayoutField>
				<Field
					name="lastName"
					component={Input}
					label="Фамилия"
				/>
			</LayoutField>
		</Grid>
		<LayoutField>
			<Field
				name="email"
				component={Input}
				label="Email"
			/>
		</LayoutField>
		<LayoutField>
			<InputSelectGroup>
				<Field
					name="phone_number"
					component={Input}
					label="Телефон"
					mask="999 999 99 99"
					containerGroup="left"
				/>
				<Field
					name="code"
					component={Select}
					childGroup="left"
				>
					<OptionSelect value='7'>+7</OptionSelect>
					<OptionSelect value='375'>+375</OptionSelect>
					<OptionSelect value='98'>+98</OptionSelect>
				</Field>
			</InputSelectGroup>
		</LayoutField>
		<Grid col="form-2col">
			<LayoutField>
				<Field
					name="password"
					type="password"
					component={Input}
					hint={'Пароль должен содержать не менее 6 символов'}
					label="Введите пароль"
				/>
			</LayoutField>
			<LayoutField>
				<Field
					name="passwordAgain"
					type="password"
					component={Input}
					label="Повторите пароль"
				/>
			</LayoutField>

			<div style={{ marginBottom: '15px' }}>
				<ReCAPTCHA
					sitekey="6Lf5T7kUAAAAALGUCClRc6jZmwEUcGhW9OcZYuHm"
					ref={props.recaptchaRef}
					onChange={props.onChange}
				/>
			</div>

		</Grid>
	</div>
);

const selector = formValueSelector('registration');

export default compose(
	reduxForm({
		form: 'registration',
		asyncValidate: asyncValidateRegistration,
		asyncBlurFields: ['firstName','lastName','email','phone_number', 'password','passwordAgain'],
	}),
	connect(
		state => ({
			values: selector(state, 'firstName', 'lastName', 'email', 'phone_number', 'password', 'passwordAgain', 'recaptchaValue')
		})
	)
)(RegistrationReduxForm);
