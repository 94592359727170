import {dateToString} from 'libs/convertDate';

export default function driverOfferToServer(clientData) {
	let date = dateToString(clientData.expireDate, 'server') + ' ' + clientData.expireTime + ':00';
	if (clientData.expireDate === undefined)
		return clientData;
	else {
		if (clientData.expireDate !== undefined && clientData.expireTime === undefined)
			return date = dateToString(clientData.expireDate, 'server') + '00:00';
	}
	return {
		...clientData,
		expireTime: date
	};
}