const ABC = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const cargoServerToClient = serverData => {
	let result = {
		...serverData,
		id: serverData.id,
		proposals: serverData.proposals,
		comment: serverData.comment,
		distance: serverData.route.distance,
		route: {items: [], price: 0},
		transport: {
			type_body: serverData.carBodyType,
			type_shipment: serverData.carLoadType
		}
	};

	serverData.route.items.forEach((point, index)=>{
		let phone = '', code = 7;

		if(point.providerPhone){
			phone = point.providerPhone.substr(-10,10);
			code = point.providerPhone.replace(phone, '');
		}

		result.route.items.push({
			timeIntervalLoad: point.timeIntervalLoad,
			providerName: point.providerName,
			providerPhone: phone.replace(/ /gi, ''),
			phone_code: code,
			startDate: point.startDate,
			address: {
				pos: point.latitude + ' ' + point.longitude,
				name: point.address,
				key: ABC.charAt(index),
				locality: point.locality,
				country: point.country,
				region: point.region,
			}
		});
	});

	result.route.price = serverData.price;
	return result;
};

export default cargoServerToClient;

export const TradeRouteServerToClient = serverData => {
	const ABC = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	let result = {
		...serverData,
		id: serverData.id,
		proposals: serverData.proposals,
		comment: serverData.comment,
		distance: serverData.route.distance,
		route: {items: [], price: 0},
		transport: {
			type_body: serverData.carBodyType,
			type_shipment: serverData.carLoadType
		}
	};

	serverData.route.items.forEach((point, index)=>{
		let phone = '', code = 7;

		if(point.providerPhone){
			phone = point.providerPhone.substr(-10,10);
			code = point.providerPhone.replace(phone, '');
		}

		result.route.items.push({
			timeIntervalLoad: point.timeIntervalLoad,
			providerName: point.providerName,
			providerPhone: phone.replace(/ /gi, ''),
			phone_code: code,
			startDate: point.startDate,
			address: point.address,
			pos: point.latitude + ' ' + point.longitude,
			locality: point.locality,
			country: point.country,
			region: point.region,
			key: ABC.charAt(index),
		});
	});

	result.route.price = serverData.price;
	return result;
};

export const routeServerToRouteClient = server => {
	const clientRoute = [];

	server.items.forEach((point, index) => {
		clientRoute.push({
			address: {
				pos: point.latitude + ' ' + point.longitude,
				name: point.address,
				key: ABC.charAt(index),
				locality: point.locality,
				country: point.country,
				region: point.region,
			}
		});
	});

	return clientRoute;
};
