import React from 'react';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import {Field, reduxForm, stopSubmit} from 'redux-form';
import { cardName } from 'libs/validationsForms';
import Input from 'components/UI/Form/Fields/Input';
import Button from 'components/UI/Form/Button';
import PropTypes from 'prop-types';
import getBankByNumberCard from 'libs/getBankByNumberCard';
import Grid from 'components/UI/Wrappers/Grid';
import style from './style.less';
import { toUpperCaseNormalize } from 'libs/normalize';


class EditCardForm extends React.PureComponent{

	state = {
		payment: undefined
	};

	handleInput = event => {
		this.setState({payment: getBankByNumberCard(event.target.value)});
	};

	render() {
		let { handleSubmit, invalid, initialValues } = this.props;
		return (
			<form onSubmit={handleSubmit} autoComplete="off">
				<h3>{ initialValues !== undefined ? 'Редактирование данных карты' : 'Добавление новой карты' }</h3>
				<LayoutField>
					<Field
						onInput={this.handleInput}
						name="cardNumber"
						type="text"
						component={Input}
						mask="9999 9999 9999 9999"
						label="Номер карты"
						placeholder="0000 0000 0000 0000"
					/>
				</LayoutField>
				<Grid className={style.grid}>
					<LayoutField>
						<Field
							name="fullName"
							type="text"
							component={Input}
							label="Имя владельца"
							validate={cardName}
							normalize={toUpperCaseNormalize}
						/>
					</LayoutField>
					<LayoutField>
						<Field
							name="issueDate"
							component={Input}
							mask="99/99"
							label="Действительна до"
						/>
					</LayoutField>
				</Grid>
				<p>
					Нажимая кнопку «Сохранить» вы соглашаетесь с условиями <a target="_blank" href="/agreement_hl.pdf">Оферты</a> оказания
					информационно-технических услуг при осуществлении переводов денежных средств.
				</p>
				<Button
					disabled={invalid}
					size={'large'}
					type={'submit'}
				>
					Сохранить
				</Button>
			</form>
		);
	}
}

EditCardForm.propTypes = {
	handleSubmit: PropTypes.func,
	error: PropTypes.string,
	submitting: PropTypes.bool,
	invalid: PropTypes.bool,
};
EditCardForm.defaultProps = {
	handleSubmit: () => {
	},
	invalid: false,
	submitting: false,
	error: '',
};

EditCardForm = reduxForm({
	form: 'editCard',
	onChange: (newValues, dispatch) => {
		dispatch(stopSubmit('editCard', {}));
	}
})(EditCardForm);

export default EditCardForm;