import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import style from 'components/ReduxForms/AddCargoForm/style.less';
import Panel from 'components/UI/Wrappers/Panel';
import NativeSelect from 'components/UI/_V2/Select';
import {
	getAdditionalsListStore,
	getBodyTypesListStore,
	getCargoListStore, getLoadTypesListStore,
	getPackageListStore
} from 'libs/selections';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import Input from 'components/UI/Form/Fields/Input';
import InputSelectGroup from 'components/UI/Wrappers/InputSelectGroup';
import Grid from 'components/UI/Wrappers/Grid';
import { NumberNormalize, NumberNormalizeSlice } from 'libs/normalize';
import Select from 'components/UI/Form/Fields/Select';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import InputGroup from 'components/UI/Wrappers/InputGroup';
import { getVolume } from 'libs/getVolume';
import { notZero } from 'libs/validationsForms';
import SelectGroup from 'components/UI/Form/Fields/SelectGroup';
import { LabelError } from 'components/UI/Form/Labels';


class OfferCargo extends PureComponent {

	calcVolume = () => {
		if (this.props.cargo) {
			if (this.props.cargo.height && this.props.cargo.length && this.props.cargo.width) {
				const { height, length, width } = this.props.cargo;
				return getVolume({ height, length, width });
			}
		}
		return 0
	};

	render () {

		const { categories, packages, additionals, bodyTypes, loadTypes } = this.props;

		return (
			<Panel className={style.afterPanel} title="Информация о грузе" marginBottom={30}>
				<span>Выберите значение из списка, используя поиск по буквам</span>
				<LayoutField className={style.width75}>
					<Field
						component={NativeSelect}
						name='cargo.categoryId'
						label='Тип груза'
						isSearchable
						options={
							categories.map(el => ({ value: el.id, label: el.titleRu }))
						}
					/>
				</LayoutField>
				<LayoutField>
					<Field
						name="cargo.title"
						component={Input}
						left={100}
						label="Название товара"
						placeholder={'Например, диван и телевизор'}
					/>
				</LayoutField>
				<Grid col="form-3">

					<LayoutField>
						<InputSelectGroup>
							<Field
								name="cargo.weight"
								component={Input}
								label="Вес"
								containerGroup="right"
								normalize={NumberNormalize}
								validate={notZero}
							/>
							<Field
								name="cargo.weightType"
								component={Select}
								childGroup="right"
							>
								<OptionSelect value={1000}>т.</OptionSelect>
								<OptionSelect value={1}>кг.</OptionSelect>
							</Field>
						</InputSelectGroup>
					</LayoutField>

					<LayoutField>
						<Field
							name="cargo.weightCount"
							component={Input}
							label="Грузовых мест"
							normalize={NumberNormalizeSlice}
						/>
					</LayoutField>

					<LayoutField>
						<Field
							component={NativeSelect}
							name='cargo.packageId'
							label='Упаковка'
							isSearchable
							options={
								packages.map(el => ({ value: el.id, label: el.titleRu }))
							}
						/>
					</LayoutField>

				</Grid>

				<span>Габариты с упаковкой</span>
				<LayoutField>
					<Grid col="form-3" className={style.grid2}>
						<InputGroup className={style.grid2}>
							<Field
								name="cargo.length"
								component={Input}
								label="Длина, м."
								normalize={NumberNormalizeSlice}
								showError={false}
								validate={notZero}
							/>
							<Field
								name="cargo.width"
								component={Input}
								label="Ширина, м."
								normalize={NumberNormalizeSlice}
								showError={false}
								validate={notZero}
							/>
							<Field
								name="cargo.height"
								component={Input}
								label="Высота, м."
								normalize={NumberNormalizeSlice}
								showError={false}
								validate={notZero}
							/>
						</InputGroup>
						<div className={style.volume}>
							<span>Объем, м<sup>3</sup></span>
							<div>{ this.calcVolume() }</div>
						</div>
					</Grid>
					{
						this.props.volumeError &&
						<div style={{ marginTop: '-5px' }}>
							<LabelError message={'Вы не указали объем'} isOn={this.props.volumeError} />
						</div>
					}
				</LayoutField>


				<LayoutField className={style.width75}>
					<Field
						type="text"
						name="cargo[additionIds]"
						component={SelectGroup}
						label="Дополнительные услуги"
						filter
					>
						{
							additionals.map((el) => (
								<OptionSelect value={el.id} key={el.id} filterStr={el.titleRu}>
									{ el.titleRu }
								</OptionSelect>
							))
						}
					</Field>
				</LayoutField>

				<div className={style.separator} />

				<span>Параметры транспорта</span>

				<Grid col="form-2">
					<LayoutField>
						<Field
							component={NativeSelect}
							name='bodyTypeId'
							label='Тип кузова'
							isSearchable
							options={
								bodyTypes.map(el => ({ value: el.id, label: el.titleRu }))
							}
						/>
					</LayoutField>

					<LayoutField>
						<Field
							component={NativeSelect}
							name='loadTypeId'
							label='Тип погрузки'
							isSearchable
							options={
								loadTypes.map(el => ({ value: el.id, label: el.titleRu }))
							}
						/>
					</LayoutField>
				</Grid>
			</Panel>
		)
	}
}

const selector = formValueSelector('offerForm');

export default connect(
	state => ({
		cargo: selector(state, 'cargo'),
		categories: getCargoListStore(state),
		packages: getPackageListStore(state),
		additionals: getAdditionalsListStore(state),
		bodyTypes: getBodyTypesListStore(state),
		loadTypes: getLoadTypesListStore(state),
	})
)(OfferCargo);
