import React, { Component } from 'react';
import style from 'components/Admin/UI/List/style.less';
import PropTypes from 'prop-types';


class AdminList extends Component {
	
	static propTypes = {
		titles: PropTypes.array,
		type: PropTypes.string,
	};
	
	static defaultProps = {
		titles: [],
		type: 'drivers',
	};
	
	render() {
		
		return (
			<div className={style.container}>
				
				<div className={`${style.header} ${style[this.props.type]}`}>
					{
						this.props.titles.map(el => (
							<div key={el}>{ el }</div>
						))
					}
				</div>
				
				{ this.props.children }
			</div>
		);
	}
}


export default AdminList;