import { searchActions } from 'actions/search';
import { mappingDataAfterSendToServer } from 'components/Search/submit';
import request from 'libs/request';
import { getProfileType } from 'libs/selections';
import { call, put, select } from 'redux-saga/effects';

let listEmpty = { items: [], total: 0, meta: {} };

export function* getResults(action) {
    console.log(action);
    let role = yield select(getProfileType),
        url = '/api/1.0/search/' + (role === 'driver' ? 'customers' : 'executors');

    const isAppend = action.payload.isAppend;
    delete action.payload.isAppend;
    const values = mappingDataAfterSendToServer(action.payload);
    const response = yield call(() => request('post', url, values));

    if (response.data.errors || response.status !== 200) {
        yield put(searchActions.setResults(listEmpty));
        action.meta.reject();
    } else {
        if (isAppend)
            yield put(searchActions.appendResults(response.data.items));
        else
            yield put(searchActions.setResults(response.data));

        action.meta.resolve();
    }
}


export function* getCountByOffer(action) {
    let role = yield select(getProfileType),
        url = role === 'driver' ? '/api/1.0/search/customers/count' : '/api/1.0/search/executors/count';

    if (action.payload.priceFrom) delete action.payload.priceFrom;
    if (action.payload.priceTo) delete action.payload.priceTo;

    //  TODO: Разобраться с ставкой по маршруту!!!!!

    const values = mappingDataAfterSendToServer(action.payload);
    const response = yield call(() =>
        request('post', url, values));

    if (response.data.errors || response.status !== 200) {
        console.error('Error getCountByOffer: ', response);
        yield call(() => action.meta.reject());
    } else {
        yield call(() => action.meta.resolve(response.data.total));
    }
}