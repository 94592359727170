const mapperFromOfferToSearch = offer => {
	
	let point_a = null;
	let point_b = null;
	
	if (typeof offer.route.items[0].address  === 'object')
		point_a = {
			city: offer.route.items[0].address.city,
			country: offer.route.items[0].address.country,
			locality: offer.route.items[0].address.locality,
			name: offer.route.items[0].address.name,
			pos: offer.route.items[0].address.pos,
			region: offer.route.items[0].address.region,
		};
	else
		point_a = {
			city: offer.route.items[0].city,
			country: offer.route.items[0].country,
			locality: offer.route.items[0].locality,
			name: offer.route.items[0].address,
			pos: offer.route.items[0].latitude + ' ' + offer.route.items[0].longitude,
			region: offer.route.items[0].region,
		};
	
	if (typeof offer.route.items[offer.route.items.length - 1].address  === 'object')
		point_b = {
			city: offer.route.items[offer.route.items.length - 1].address.city,
			country: offer.route.items[offer.route.items.length - 1].address.country,
			locality: offer.route.items[offer.route.items.length - 1].address.locality,
			name: offer.route.items[offer.route.items.length - 1].address.name,
			pos: offer.route.items[offer.route.items.length - 1].address.pos,
			region: offer.route.items[offer.route.items.length - 1].address.region,
		};
	else
		point_b = {
			city: offer.route.items[offer.route.items.length - 1].city,
			country: offer.route.items[offer.route.items.length - 1].country,
			locality: offer.route.items[offer.route.items.length - 1].locality,
			name: offer.route.items[offer.route.items.length - 1].address,
			pos: offer.route.items[offer.route.items.length - 1].latitude + ' ' + offer.route.items[offer.route.items.length - 1].longitude,
			region: offer.route.items[offer.route.items.length - 1].region,
		};
	
	return {
		point_a,
		point_b,
		sort: '!price',
		startDate: offer.route.items[0].startDate,
		volume: offer.cargo.width * offer.cargo.height * offer.cargo.length,
		geography: offer.geography,
		weight: offer.cargo.weight / 1000,
		size: offer.cargo.size,
		priceFrom: offer.price * .8,
		priceTo: offer.price * 1.2,
		weightType: 1,
		carBodyType: offer.carBodyType,
		carLoadType: offer.carLoadType,
		page: 1,
		pageSize: 4,
	};
};

export default mapperFromOfferToSearch;