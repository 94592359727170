import {
    AcceptOffer,
    ArbitrationCreated,
    CancelledDeal,
    CancellOffer,
    CancellRequestAdmin,
    ContractSigned,
    CustomerAcceptedCargo,
    CustomerBeganRoute,
    DealComplited,
    DealTerminated,
    EditedDealTerms,
    EditRequest,
    ExecutorConfirmMakeDeal,
    FundsReserved,
    NewOffer,
    RejectedOffer, RequestPubliched,
    RoutePublished,
    RouteCancelled,
    VerifyFalse,
    VerifyTrue
} from "components/Events/DetailedDescriptionNotifications";
import Button from 'components/UI/Form/Button';
import { Check, Pencil, Writing } from 'components/UI/Icons';
import { dateToString, stringToDate } from 'libs/convertDate';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.less';


const NotificationWS = ({ el, read, role }) => {

    let text = el.title, icon = Writing(styles.writingIcon);

    switch (el.title) {
        case 'Заявка отменена':
            text = <CancellRequestAdmin params={el.params}/>;
            icon = Pencil(styles.cancelIcon);
            break;
        case 'Новое предложение':
            text = <NewOffer role={role} params={el.params}/>;
            icon = Writing(styles.writingIcon);
            break;
        case 'Ваше предложение принято':
            text = <AcceptOffer params={el.params}/>;
            icon = Check(styles.acceptIcon);
            break;
        case 'Предложение отменено':
            text = <CancellOffer params={el.params}/>;
            icon = Pencil(styles.cancelIcon);
            break;
        case 'Предложение отклонено':
            text = <RejectedOffer params={el.params}/>;
            icon = Pencil(styles.cancelIcon);
            break;
        case 'Договор подписан':
            text = <ContractSigned params={el.params}/>;
            icon = Check(styles.acceptIcon);
            break;
        case 'Исполнитель подтвердил заключение сделки':
            text = <ExecutorConfirmMakeDeal params={el.params}/>;
            icon = Check(styles.acceptIcon);
            break;
        case 'Сделка отменена':
            text = <CancelledDeal params={el.params}/>;
            icon = Check(styles.acceptIcon);
            break;
        case 'Отправлен запрос на редактирование условий сделки':
            text = <EditRequest params={el.params}/>;
            icon = Writing(styles.writingIcon);
            break;
        case 'Заказчик отредактировал условия сделки':
            text = <EditedDealTerms params={el.params}/>;
            icon = Writing(styles.writingIcon);
            break;
        case 'Исполнитель принял груз':
            text = <CustomerAcceptedCargo params={el.params}/>;
            icon = Writing(styles.writingIcon);
            break;
        case 'Исполнитель начал маршрут':
            text = <CustomerBeganRoute params={el.params}/>;
            icon = Writing(styles.writingIcon);
            break;
        case 'Сделка расторгнута':
            text = <DealTerminated params={el.params}/>;
            icon = Pencil(styles.cancelIcon);
            break;
        case 'Создан арбитраж по сделке':
            text = <ArbitrationCreated params={el.params}/>;
            icon = Writing(styles.writingIcon);
            break;
        case 'Средства зарезервированы':
            text = <FundsReserved params={el.params}/>;
            icon = Check(styles.acceptIcon);
            break;
        case 'Сделка завершена':
        case 'Разгрузка':
            text = <DealComplited params={el.params}/>;
            icon = Check(styles.acceptIcon);
            break;
        case 'Заявка опубликована':
            text = <RequestPubliched params={el.params}/>;
            icon = Check(styles.acceptIcon);
            break;
        case 'Маршрут опубликован':
            text = <RoutePublished params={el.params}/>;
            icon = Check(styles.acceptIcon);
            break;
        case 'Маршрут отменен':
            text = <RouteCancelled params={el.params}/>;
            icon = Pencil(styles.cancelIcon);
            break;
        case 'Верификация профиля не пройдена':
            text = <VerifyFalse params={el.params}/>;
            icon = Pencil(styles.cancelIcon);
            break;
        case 'Профиль верифицирован':
            text = <VerifyTrue params={el.params}/>;
            icon = Check(styles.acceptIcon);
            break;

        default:
            break;
    }


    if (text === null) return null;



    return (
        <div className={styles.container}>
            <div className={styles.icon}>{icon}</div>
            <div className={styles.content}>
                {text}
                <div className={styles.footer}>
                    {dateToString(stringToDate(el.createTime), 'stringFullDate')},{' '}
                    <Button className={styles.read} onClick={read}>отметить прочитанным</Button>
                </div>
            </div>
        </div>
    );
};


NotificationWS.propTypes = {
    el: PropTypes.object.isRequired,
    read: PropTypes.func.isRequired,
};

export default NotificationWS;
