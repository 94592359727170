import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';
import infoImage from 'components/UI/Notification/info.svg';
import Tooltip from 'components/UI/Tooltip';

const Status = props => {
	let statusText = '',
		statusClass = '',
		statusInfo = '';

	switch (props.status) {
		case 'moderation':
			statusText = 'На модерации';
			statusClass = style.moderation;
			break;
		case 'published':
		case 'public':
			statusText = 'Опубликовано';
			statusClass = style.active;
			break;
		case 'cancelled':
			statusText = 'Отклонено'; // также добавляется комментарий через props.info почему отклонено
			statusClass = style.cancel;
			break;
		case 'canceled':
			statusText = 'Отменено';
			statusClass = style.cancel;
			break;
		case 'unpublished':
			statusText = 'Завершено';
			statusClass = style.active;
			break;
		case 'demo':
			statusText = 'Черновик';
			statusClass = style.draft;
			break;
		case 'created':
		case 'edited': // подписан договор, создалась сделка. Либо было редактирование
			statusText = 'Черновик';
			statusInfo = props.role === 'driver' ? 'Клиент ожидает подтверждения договора' : 'Ожидает подтверждения перевозчиком';
			statusClass = style.draft;
			break;
		case 'edit_request': // водитель запросил изменения договора, внутри сделки сверху уведомление с текстом от исполнителя, что поменять
			statusText = 'Черновик';
			statusInfo = props.role === 'driver' ? 'Ожидает правки от клиента' : 'Перевозчик ожидает редактирования договора';
			statusClass = style.draft;
			break;
		case 'accepted': // водитель согласился с условиями договора, внутри сделки сверху уведомлени с текстом как в info статуса
			statusText = props.role === 'driver' ? 'Получение груза' : 'Передача груза';
			statusInfo = props.role === 'driver' ? 'Вы должны принять груз, внимательно его осмотрите и в случае несостыковки с договором отправить запрос на его редактирование' : 'Вы должны передать груз перевозчику, согласно вашей с ним договоренности, после чего он сможет его осмотреть, подтвердить данные, указанные в договоре и совершить погрузку';
			statusClass = style.active;
			break;
		case 'cargo_loaded': // водитель согласился со всеми условиями по грузу и завершил погрузку в транспорт
			statusText = props.role === 'driver' ? 'Холдирование средств' : 'Ожидает оплаты';
			statusClass = style.active;
			break;
		case 'money_reserved': // клиент оплатил и ждет отправки груза. Водитель может нажать старт по маршруту
			statusText = props.role === 'driver' ? 'Оплата произведена' : 'Отправка груза';
			statusClass = style.active;
			break;
		case 'started_route': // Водитель едет по маршруту, в радиусе 5 км от точки выгрузки по адресу доступна кнопки разгрузки
			statusText = props.role === 'driver' ? 'В пути' : 'Груз в пути';
			statusClass = style.active;
			break;
		case 'cargo_unloaded':
			statusText = props.role === 'driver' ? 'Ожидает проверки клиентом' : 'Ожидает проверки';
			statusClass = style.active;
			break;
		case 'completed':
		case 'trading_end':
			statusText = 'Выполнено';
			statusClass = style.active;
			break;
		case 'arbitration':
			statusText = 'Арбитраж';
			statusClass = style.moderation;
			break;
		case 'wait_customer': // статус для предложения
			statusText = 'На рассмотрении';
			statusClass = style.draft;
			break;
		case 'rejected': // статус для предложения
			statusText = 'Отменено';
			statusInfo = 'Выбран другой перевозчик';
			statusClass = style.cancel;
			break;
		case 'deleted': // статус для предложения
			statusText = 'Отменено';
			statusInfo = 'Заявка была удалена клиентом';
			statusClass = style.cancel;
			break;
		default:
			statusText = 'Не определено';
			statusClass = style.draft;
			break;
	}

	/*
	статусы арбитража для чата:
	- Ожидание ответа клиента
	- Ожидание ответа перевозчика
	- Рассмотрение (с этим статусом создается арбитраж)
	- Вынесено решение в пользу клиента
	- Вынесено решение в пользу перевозчика
	- Отменен
	- Сделка возвращена в работу
	 */

	if (props.text) statusText = props.text;
	if (props.info) statusInfo = props.info;

	return (
		<div className={`${style.status} ${!props.isAdmin && statusClass} ${props.className} ${statusInfo !== '' ? style.infoTrue : ''}`}>
			<div className={`${props.isAdmin && statusClass} ${style.text}`}>
				{ statusText }
			</div>
			{
				statusInfo !== '' &&
				<div className={style.info}>
					<img src={infoImage} alt=""/>
					<Tooltip text={statusInfo} className={style.content} />
				</div>
			}
		</div>
	);
};

Status.propTypes = {
	status: PropTypes.string.isRequired,
	text: PropTypes.string,
	info: PropTypes.string,
	role: PropTypes.string,
	className: PropTypes.string,
};
Status.defaultProps = {
	text: '',
	info: '',
	role: '',
	className: '',
};

export default Status;
