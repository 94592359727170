import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, FieldArray, change as setValue, formValueSelector } from 'redux-form';
import Button from 'components/UI/Form/Button';
import LocationPoints from './LocationPoints';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
	getPoints,
	getDistance,
	getCargoListStore,
	getPackageListStore,
	getAdditionalsListStore, getBodyTypesListStore, getLoadTypesListStore
} from 'libs/selections';
import Price from './Price';
import { getProfileType, getProfile } from 'libs/selections';
import { setPoints } from 'actions/map';
import Panel from 'components/UI/Wrappers/Panel';
import Checkbox from 'components/UI/Form/Fields/Checkbox';
import Cargo from './Cargo';
import GuestPhone from './GuestPhone';
import { LabelError } from 'components/UI/Form/Labels';
import style from './style.less';
// import asyncValidate from 'libs/asyncValidate';

class AddCargoForm extends React.PureComponent {

	static propTypes = {
		handleSubmit: PropTypes.func,
		setSubmitType: PropTypes.func,
		error: PropTypes.string,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
	};

	static defaultProps = {
		handleSubmit: () => {
		},
		invalid: false,
		submitting: false,
		error: '',
	};

	componentWillReceiveProps(nextProps) {
		if (this.props.distance !== nextProps.distance && !(nextProps.initialValues.route.price > 0))
			this.props.dispatch(setValue('addCargo', 'price', Math.round(nextProps.distance * 18)));
	}

	componentWillMount() {
		if (this.props.initialValues.route.items[0].address)
			this.props.dispatch(setPoints(this.props.initialValues.route.items.map(el => el.address)));
		else
			this.props.dispatch(setPoints([{ key: 'A' }, { key: 'B' }]));
	}

	render() {
		let { profileRole, profile, handleSubmit, points, invalid, dispatch, error, submitting } = this.props;

		return (
			<form onSubmit={handleSubmit} autoComplete="off">
				<FieldArray
					component={LocationPoints}
					name='route.items'
					points={points}
					dispatch={dispatch}
					role={profileRole}
					profile={profile}
					isOfferID={this.props.initialValues.id !== undefined}
				/>
				<Cargo
					categoryList={this.props.categoryList}
					packages={this.props.packages}
					additionals={this.props.additionals}
					bodyTypes={this.props.bodyTypes}
					loadTypes={this.props.loadTypes}
				/>
				<Price />
				<Panel className={style.customAgreePanel}>
					<Button
						className={style.buttonAgree}
						disabled={invalid}
						loading={submitting}
						size={'large'}
						type={'submit'}
					>
						Сохранить изменения
					</Button>
					{ error && <LabelError message={error} /> }
				</Panel>
			</form>
		);
	}
}

const selector = formValueSelector('addCargo');

export default compose(
	reduxForm({
		form: 'addCargo',
		// asyncValidate,
		asyncBlurFields: [],
	}),
	connect(
		store => ({
			points: getPoints(store),
			distance: getDistance(store),
			profileRole: getProfileType(store),
			profile: getProfile(store),
			values: selector(store, 'route', 'cargo', 'transport'),
			categoryList: getCargoListStore(store),
			packages: getPackageListStore(store),
			additionals: getAdditionalsListStore(store),
			bodyTypes: getBodyTypesListStore(store),
			loadTypes: getLoadTypesListStore(store),
		})
	)
)(AddCargoForm);
