import * as adminActions from 'actions/admin';

export const getDriver = (dispatch, qs, success) => {
	
	return new Promise((resolve, reject) => {
		dispatch(adminActions.getUserByID(qs, { resolve, reject }));
	}).then(() => {
		success();
	}).catch(() => {
		success();
	});
	
};