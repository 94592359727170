import React from 'react';
import PropTypes from 'prop-types';
import step1 from './Icons/step1.svg';
import step2 from './Icons/step2.svg';
import step3 from './Icons/step3.svg';
import Slide from './Slide';
import Button from 'components/UI/Form/Button';
import { ArrowLeft, ArrowRight } from 'components/UI/Icons';
import style from './style.less'
import { connect } from 'react-redux';
import { getProfileType } from 'libs/selections';

class Slider extends React.Component {
	state = {
		active: 0,
		slides: [
			{title: 'Создать заявку', text: 'Перейдите по ссылке и заполните поля онлайн-формы.', button: {url: this.props.role === 'driver' ? '/new/route' : '/new/offer', title: 'Создать заявку'}, img: step1},
			{title: 'Выбрать исполнителя', text: 'Из списка откликов на заявку выберите подходящий вариант.', img: step2},
			{title: 'Заключить сделку', text: 'Через сервис сайта заключите сделку во избежание рисков.', img: step3},
		]
	};

	static propTypes = {
		className: PropTypes.string,
	};
	static defaultProps = {
		className: '',
	};

	onHandleToggleSlide = toggle => {
		const count = this.state.slides.length - 1;
		const prev = this.state.active - 1;
		const next = this.state.active + 1;
		if (toggle === 'prev') {
			this.setState({ active: prev === -1 ? count : prev });
		} else {
			this.setState({ active: next > count ? 0 : next });
		}
	};

	onHandleDot = active => {
		this.setState({ active });
	};




	render() {
		const { active, slides } = this.state;
		let activeSlide = false;

		return (
			<div className={`${style.slider__container} ${this.props.className}`}>
				<div className={style.slides}>
					{
						slides.map((el, i) => {
							if (active === i) {
								activeSlide = true;
							} else {
								activeSlide = false;
							}
							return (
								<Slide key={i} content={el} step={i+1} toggle={data => this.onHandleDot(data)} active={activeSlide} />
							)
						})
					}
				</div>

				<div className={style.nav}>
					<Button className={style.prev} color="white-arrow" size="small" onClick={() => this.onHandleToggleSlide('prev')}>{ ArrowLeft() }</Button>
					<Button className={style.next} color="white-arrow" size="small" onClick={() => this.onHandleToggleSlide('next')}>{ ArrowRight() }</Button>
				</div>

				<div className={style.dots}>
					{
						slides.map((el, i) => {
							if (active === i) {
								activeSlide = true;
							} else {
								activeSlide = false;
							}
							return (
								<div
									key={i}
									className={`${style.dot} ${activeSlide ? style.active : ''}`}
									onClick={() => this.onHandleDot(i)}
								/>
							)
						})
					}
				</div>
			</div>
		)
	}
}

export default connect(
	state => ({
		role: getProfileType(state)
	})
)(Slider);