import React, { PureComponent } from 'react';
import styles from './style.less';
import { Link } from 'react-router-dom';
import { logOut } from 'actions/profile';
import shortName from 'libs/shortName';
import { Avatar, ArrowDownNavigate } from 'components/UI/Icons';
import Button from 'components/UI/Form/Button';
import OutsideContainer from 'components/UI/Wrappers/OutsideContainer';
import Menu from 'components/UI/HeaderMainMenu/Menu';
import svgProfile from 'components/UI/Icons/svg/male-user-shadow.svg';
import svgLogout from 'components/UI/Icons/svg/logout-3.svg';
import { getProfile } from 'libs/selections';
import { connect } from 'react-redux';

class MobileProfile extends PureComponent {

	exit = () => {
		this.props.onToggleMenu(false);
		this.props.dispatch(logOut());
	};

	render () {
		const { profile, onToggleMenu, openMobileMenu } = this.props;
		let renderClass = styles.mobileMenuContainer;
		if (openMobileMenu) renderClass += ` ${styles.open}`;


		return (
			<div className={renderClass}>
				<OutsideContainer onClickOutside={() => onToggleMenu(false)}>
					<Button
						clearStyle
						className={styles.profile}
						onClick={() => onToggleMenu(!openMobileMenu)}
					>
						{ Avatar(styles.avatar) }
						{ shortName(profile.lastName, profile.firstName) }
						{ ArrowDownNavigate(styles.arrow) }
					</Button>
					<div className={styles.content}>
						<ul>
							<li>
								<Link to={'/edit/profile'} onClick={() => onToggleMenu(false)}>
									<span className={styles.img}><img src={svgProfile} alt="" /></span>
									Мой профиль
								</Link>
							</li>
							<li>
								<span onClick={this.exit}>
									<span className={styles.img}><img src={svgLogout} alt="" /></span>
									Выход
								</span>
							</li>
						</ul>

						<h3>Разделы</h3>
						<Menu onClick={() => onToggleMenu(false)} />
					</div>
				</OutsideContainer>
			</div>
		)
	}
}

export default connect(
	state => ({
		profile: getProfile(state),
	})
)(MobileProfile);