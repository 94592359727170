import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';

const Aside = props => {
	const { marginBottom, children, className } = props;

	let styles = {
		marginBottom: `${marginBottom}px`,
		width: props.width,
	};

	return (
		<div className={`${style.aside} ${className}`} style={styles}>
			{ children }
		</div>
	)
};

Aside.propTypes = {
	marginBottom: PropTypes.number,
	className: PropTypes.string,
	children: PropTypes.node,
};
Aside.defaultProps = {
	marginBottom: 0,
	className: '',
	children: '',
};

export default Aside;
