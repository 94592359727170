import React from 'react';
import messageStyle from 'components/Chat/center/message.less';
import { dateToString, serverDateToClientDate } from 'libs/convertDate';
import { Link } from 'react-router-dom';


const MessageStatus = ({ offers, handleClose }) => {
	
	if (offers === undefined) return null;
	
	if (offers.length === null) return null;

	return (
		<div className={messageStyle.offers}>
			{
				offers.map(el => (
					<Link
						key={el.id}
						to={'/offer/' + el.id}
						className={messageStyle.offer}
						onClick={() => handleClose}
						title="Нажмите, чтобы открыть"
					>
						<div className={messageStyle.info}>
							№{el.id} от {dateToString(serverDateToClientDate(el.createTime), 'default')}</div>
						<div className={messageStyle.title}>
							{el.cargo.title}, {el.cargo.categoryId.titleRu}, {el.cargo.weight}кг.
						</div>
					</Link>
				))
			}
		</div>
	)
};


export default MessageStatus;