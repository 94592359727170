exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n._1nBk-NW5ss-Xz0l_majcl1 {\n  display: flex;\n  height: 70px;\n  background: #fff;\n}\n@media screen and (max-width: 768px) {\n  ._1nBk-NW5ss-Xz0l_majcl1 {\n    height: 60px;\n  }\n}\n._3UEZo_FTQ4l3Yq_SFlO223 {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n._3q9ezFR4QbvZjvgf78JmIg {\n  display: flex;\n  align-items: center;\n}\n._3q9ezFR4QbvZjvgf78JmIg div {\n  margin-left: 30px;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/Admin/UI/Header/style.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,cAAc;EACd,aAAa;EACb,iBAAiB;CAClB;AACD;EACE;IACE,aAAa;GACd;CACF;AACD;EACE,cAAc;EACd,oBAAoB;EACpB,+BAA+B;CAChC;AACD;EACE,cAAc;EACd,oBAAoB;CACrB;AACD;EACE,kBAAkB;CACnB","file":"style.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.header {\n  display: flex;\n  height: 70px;\n  background: #fff;\n}\n@media screen and (max-width: 768px) {\n  .header {\n    height: 60px;\n  }\n}\n.container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.logo {\n  display: flex;\n  align-items: center;\n}\n.logo div {\n  margin-left: 30px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"header": "_1nBk-NW5ss-Xz0l_majcl1",
	"container": "_3UEZo_FTQ4l3Yq_SFlO223",
	"logo": "_3q9ezFR4QbvZjvgf78JmIg"
};