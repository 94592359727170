import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.less';

class Table extends React.Component {
	
	static propTypes = {
		names: PropTypes.array,
		className: PropTypes.string,
		children: PropTypes.node,
	};
	
	static defaultProps = {
		names: [],
		className: 'null',
		children: '',
	};
	
	
	render() {
		
		const { names, className, children } = this.props;
		
		return (
			<table className={`${styles.table} ${className}`}>
				{
					names.length > 0 &&
					<thead>
						<tr>
						{
							names.map((el,i) => <td key={i}>{ el }</td>)
						}
						</tr>
					</thead>
				}
				<tbody>
					{ children }
				</tbody>
			</table>
		)
	}
}

export default Table;