const shortName = (nameLong, nameShort) => {
	const short = nameShort ? nameShort.charAt(0).toUpperCase() : '';
	return `${nameLong} ${short}.`
};

export const shortFullName = (lastName, firstName, middleName) => {
	const firstNameShort = firstName ? firstName.charAt(0).toUpperCase() : '';
	const middleNameShort = middleName ? `${middleName.charAt(0).toUpperCase()}.` : '';
	return `${lastName} ${firstNameShort}.${middleNameShort}`;
};

export default shortName;