import React from 'react';
import PropTypes from 'prop-types';
import style from 'components/UI/_V2/Proposals/Detail/style.less';
import ProposalUsername from 'components/UI/_V2/Proposals/Detail/ProposalUsername';
import ProposalRating from 'components/UI/_V2/Proposals/Detail/ProposalRating';
import ProposalOffer from 'components/UI/_V2/Proposals/Detail/ProposalOffer';
import Avatar from 'components/UI/ProfileUI/Avatar';
import { getFullDateWithTime } from 'libs/_V2/getDateFormat';
import { convertNumberSpaces } from 'libs/convertNumberSpaces';
import Button from 'components/UI/Form/Button';


const ProposalDetail = props => {
	
	const { user1, user2, message, createTime, isYou, className, price, offer, isProfileLink,
		acceptProposal, openChat, showCustomerActions, showOffer } = props;
	
	let renderClassName = style.proposal;
	
	if (className) renderClassName += ' ' + className;
	if (isYou) renderClassName += ' ' + style.proposal__your;
	
	return (
		<div className={renderClassName}>
			
			{
				<div className={style.proposal__avatar}>
					{
						user1.profile !== null && <Avatar size={80} src={user1.profile.photo} />
					}
					{
						user1.profile === null && <Avatar size={80} src={null} />
					}
				</div>
			}
			
			<div className={style.proposal__content}>
				
				<div className={style.proposal__header}>
					
					<ProposalUsername
						isProfileLink={isProfileLink}
						isYou={isYou}
						{ ...user1 }
					/>
					
					<ProposalRating { ...user1 } />

				</div>
				
				<div className={style.proposal__body}>
					
					{
						price !== null &&
						<p className={style.proposal__price}>
							Стоимость { convertNumberSpaces(price) } руб.
						</p>
					}
					
					<p>{ message }</p>
					
					{
						offer !== undefined && showOffer &&
						<ProposalOffer offer={offer} />
					}
					
					{
						showCustomerActions &&
						<div className={style.proposal__actions}>
							<Button
								onClick={() => acceptProposal(user1)}
							>
								Выбрать исполнителем
							</Button>
							
							<Button
								onClick={() => openChat(user1, user2)}
								color="white"
							>
								Ответить
							</Button>
						</div>
					}
					
				</div>
				
				<div
					className={style.proposal__footer}
				>
					{ getFullDateWithTime(createTime) }
				</div>
				
			</div>
			
		</div>
	)
};


ProposalDetail.propTypes = {
	showCustomerActions: PropTypes.bool,
	acceptProposal: PropTypes.func,
	showOffer: PropTypes.bool,
};

ProposalDetail.defaultProps = {
	showCustomerActions: false,
	acceptProposal: () => {},
	showOffer: false,
};


export default ProposalDetail;