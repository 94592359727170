import { getItem } from 'libs/sessionStorage';
import { offersActions } from 'actions/offers';
import * as profileDetailActions from 'actions/profileDetail';
import * as mapAction from 'actions/map';
import cargoServerToClient from 'mappers/cargoServerToClient';

export const getSessionData = async dispatch => {
	try {
		const executor = await getUserData(JSON.parse(getItem('executor')).id, dispatch);
		const offer = JSON.parse(getItem('offer'));
		const price = parseInt(getItem('price'));
		const proposalId = parseInt(getItem('proposalId'));
		dispatch(offersActions.setDetailOffer({ offer: { ...offer, price }, status: 'demo', executor: { ...executor, proposalId } }));
		dispatch(mapAction.setPoints(offer.route.items.map(el => el.address)));
	}
	catch(err) {
		console.log(err);
	}
};

export const getUserData = (userID, dispatch) => {
	return new Promise ((resolve,reject) => {
		dispatch(profileDetailActions.getProfileByID(userID, { resolve, reject }));
	}).then(res => {
		return res;
	}).catch(err => {
		console.log(err);
	});
};

export const getServerData = (id, dispatch, res) => {

	return new Promise((resolve,reject) => {
		dispatch(offersActions.getTradeByID(id, { resolve, reject }));
	}).then(data => {
		let trade = { ...data, offer: { ...cargoServerToClient(data.offer) }  };
		dispatch(mapAction.setPoints(trade.offer.route.items.map(el => el.address)));
		res(trade)
	}).catch(err => console.log(err));
};