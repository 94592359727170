import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {getMyCars} from 'libs/selections';
import PageTransport from 'components/UI/Wrappers/PageTransport';
import Car from './Car';
import {LabelError} from "components/UI/Form/Labels";
import {driverActions} from 'actions/driver';
import Dialog from 'components/UI/Dialog';
import Button from 'components/UI/Form/Button';
import CarContainer from 'components/UI/Wrappers/CarContainer';
import style from './style.less';
import forwardTo from "libs/forwardTo";

class Cars extends React.PureComponent {
	state = {
		showDeleteDialog: false,
		goToEditCar: false,
		carInit: {},
		action: ()=>{},
	};

	//удаляем машину, закром окно
	deleteCar = id => {
		this.props.dispatch(driverActions.rmCar(id));
		this.setState({
			showDeleteDialog: false,
			action: ()=>{},
		});
	};

	//управление окнами
	handleToggleDialog = (type, showDeleteDialog = false) => {
		this.setState({[type]: showDeleteDialog, action: ()=>{}, carInit: {}});
	};

	//выбрали машину, готовы удалить
	handleRemoveCar = action => {
		this.setState({
			showDeleteDialog: true,
			action: action,
		});
	};

	handleEditCar = carData => {
		carData.carMarkId = carData.carMark.id;
		carData.carModelId = carData.carModel.id;
		carData.carSeriesId = carData.carSeries.id;
		this.setState({
			goToEditCar: true,
			carInit: carData,
		})
	};

	render() {
		const cars = this.props.cars ? this.props.cars : [];

		if (this.state.goToEditCar) {
			window.editCar = this.state.carInit;
			forwardTo('/edit/car');
		}

		return (
			<PageTransport title="Мой транспорт">
				<CarContainer>
					{
						cars.map(car => (
							<Car
								{...car}
								key={car.id}
								actionEdit={this.handleEditCar}
								actionRemove={()=>this.handleRemoveCar(()=>this.deleteCar(car.id))}
							/>
						))
					}
				</CarContainer>
				{cars.length === 0 && <LabelError message="Транспорт отсутствует. Добавьте свой транспорт."/>}
				{this.state.showDeleteDialog &&
					<Dialog handleClose={()=>this.handleToggleDialog('showDeleteDialog')}>
						<h3 className={style.textcenter}>Вы действительно хотите удалить данный транспорт?</h3>

						<div className={style.buttonList}>
							<Button className={style.buttonFirst} color="remove" onClick={this.state.action}>Удалить</Button>
							<Button color="white" onClick={()=>this.handleToggleDialog('showDeleteDialog')}>Отменить</Button>
						</div>
					</Dialog>
				}
			</PageTransport>
		);
	}
}

export default connect(
	store => ({
		cars: getMyCars(store),
	})
)(Cars);