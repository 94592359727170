import React from 'react';
import styles from './style.less';
import Sticky from 'react-sticky-fill';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getProfile, getProfileType } from 'libs/selections';
import Container from 'components/UI/Wrappers/Container';
import MobileProfile from 'components/UI/HeaderMainMenu/MobileProfile';
import SmallChat from 'components/UI/HeaderMainMenu/SmallChat';
import Events from 'components/Events';
import HeaderProfile from 'components/UI/HeaderProfile';
import UserAddButton from 'components/UI/UserAddButton';
import Menu from 'components/UI/HeaderMainMenu/Menu';
import { UseCustomStyles } from 'libs/UseCustomStyles';

class HeaderMainMenu extends React.Component {

	state = {
		openMobileMenu: false,
		showActions: false,
	};

	componentWillMount() {
		window.addEventListener('scroll', this.handleScroll, false);
		if (window.scrollY > 70) {
			this.setState({ showActions: true });
		}
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll, false);
	}

	handleScroll = () => {
		if (window.scrollY > 70) this.setState({ showActions: true });
		else this.setState({ showActions: false });
	};

	onToggleMenu = openMobileMenu => this.setState({ openMobileMenu });

	render() {
		const { role, dispatch } = this.props;
		const { openMobileMenu, showActions } = this.state;
		
		const isCustomStyles = UseCustomStyles(this.props.location.pathname);
		let brd = styles.brd;
		if (isCustomStyles) brd = styles.brdCustom;
		
		
		if (role === 'guest') return <Container className={styles.customContainer}><div className={brd} /></Container>;
		
		return (
			<Sticky style={{ zIndex: '99' }}>
				<div className={styles.menu}>
					<Container className={styles.container}>
						<div className={styles.pullLeft}>
							<MobileProfile
								dispatch={dispatch}
								openMobileMenu={openMobileMenu}
								onToggleMenu={e => this.onToggleMenu(e)}
							/>
							<Menu pathname={this.props.location.pathname} />
						</div>
						<div className={styles.pullRight}>
							{
								showActions
								&&
								<UserAddButton
									role={role}
									className={styles.btn}
								/>
							}
							<SmallChat />
							<Events />
							{
								showActions
								&&
								<HeaderProfile
									dispatch={dispatch}
									className={styles.profileRight}
									fixed
								/>
							}
						</div>
					</Container>
				</div>
			</Sticky>
		);
	}
}

export default compose(
	withRouter,
	connect(
		state => ({
			role: getProfileType(state),
		})
	)
)(HeaderMainMenu);
