import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { change as setValue, Field, FieldArray, formValueSelector, getFormSubmitErrors, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import style from 'components/ReduxForms/AddCargoForm/style.less';
import Button from 'components/UI/Form/Button';
import Checkbox from 'components/UI/Form/Fields/Checkbox';
import OfferLocations from 'components/ReduxForms/OfferForm/OfferLocations';
import OfferCargo from 'components/ReduxForms/OfferForm/OfferCargo';
import OfferOther from 'components/ReduxForms/OfferForm/OfferOther';
import OfferGuest from 'components/ReduxForms/OfferForm/OfferGuest';
import Panel from 'components/UI/Wrappers/Panel';
import { getDistance } from 'libs/selections';
import { setPoints } from 'actions/map';


class OfferForm extends PureComponent {

	static propTypes = {
		handleSubmit: PropTypes.func,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
	};

	static defaultProps = {
		handleSubmit: () => {},
		submitting: false,
		invalid: false,
	};

	componentWillReceiveProps(nextProps) {
		if (this.props.distance !== nextProps.distance && !(nextProps.initialValues.route.price > 0))
			this.props.dispatch(setValue('offerForm', 'price', Math.round(nextProps.distance * 23)));
	}

	componentDidMount() {
		if (this.props.initialValues.route.items[0].address)
			this.props.dispatch(setPoints(this.props.initialValues.route.items.map(el => el.address)));
		else
			this.props.dispatch(setPoints([{ key: 'A' }, { key: 'B' }]));
	}

	scrollUp = (el) => {
		let scrolling = () => {
			const scrollStep = -window.scrollY / 40;
			let scrollTimeout = setTimeout(() => {
				if (window.scrollY > el) {
					window.scrollBy( 0, scrollStep );
					scrolling();
				} else {
					clearTimeout(scrollTimeout);
				}
			}, 10);
		};
		scrolling();
	};

	componentDidUpdate(prevProps) {
		if (prevProps.submitting && !this.props.submitting && this.props.invalid) {
			setTimeout(() => {
				const el = document.querySelector('[data-scroll-anchor="1"]');
				if (el !== null) {
					const elRect = el.getBoundingClientRect().top;
					this.scrollUp(elRect - 140 + window.pageYOffset);
				}
			},100);
		}
	}

	isEnabledSubmit = () => {
		return this.props.values;
	};

	render () {

		const { handleSubmit, submitting, invalid, initialValues } = this.props;

		const isEnabledSubmit = this.isEnabledSubmit();
		const errors = this.props.submitErrors['cargo'] ? this.props.submitErrors['cargo'] : { width: false, height: false, length: false };

		return (
			<form onSubmit={handleSubmit} autoComplete="off">

				<FieldArray
					component={OfferLocations}
					name='route.items'
					isEditOffer={initialValues.id}
				/>

				<OfferCargo volumeError={errors.width || errors.height || errors.length} />

				<OfferOther />

				<OfferGuest recaptchaRef={this.props.recaptchaRef} />

				<Panel className={style.customAgreePanel}>
					{
						!initialValues.id &&
						<>
							<Field
								name="agree"
								component={Checkbox}
								required
								label={['Я согласен с ', <a href="/agreement_hl.pdf" target="_blank">Правилами сайта</a>]}
							/>
							<p>
								Нажимая на кнопку «Разместить заявку», вы соглашаетесь с правилами платежного сервиса
								«HurryLorry» и сервиса «Сделка без риска». Ваш банк может взымать дополнительную комиссию.
							</p>
						</>
					}

					<Button
						className={style.buttonAgree}
						disabled={invalid || !isEnabledSubmit}
						loading={submitting}
						size={'large'}
						type={'submit'}
					>
						{ initialValues.id ? 'Сохранить изменения' : 'Разместить заявку' }
					</Button>
				</Panel>

			</form>
		)
	}

}

const selector = formValueSelector('offerForm');


export default compose(
	reduxForm({
		form: 'offerForm',
	}),
	connect(
		state => ({
			values: selector(state, 'agree'),
			submitErrors: getFormSubmitErrors('offerForm')(state),
			distance: getDistance(state),
		})
	)
)(OfferForm)
