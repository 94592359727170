import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/UI/Form/Button';
import { offersActions } from 'actions/offers';
import StepContainer from 'components/Pages/Trades/Detail/TradeSteps/StepContainer';
import style from 'components/Pages/Trades/Detail/styles.less';

export default class Upload extends React.PureComponent {

	static propTypes = {
		isActive: PropTypes.bool.isRequired,
		role: PropTypes.string.isRequired,
		dispatch: PropTypes.func.isRequired,
		tradeID: PropTypes.number.isRequired,
		status: PropTypes.string.isRequired,
	};

	sendUploadAccept = () => this.props.dispatch(offersActions.acceptUploadOfferById(this.props.tradeID));

	render() {
		let { isActive, role, status, toggleDialog } = this.props;
		const moneyReseverd = ['money_reserved', 'started_route', 'cargo_unloaded', 'completed', 'arbitration'];
		const started = ['demo', 'created', 'edited','edit_request'];

		return (
			<StepContainer step={1} title="Загрузка товара" isActive={isActive}>
				{
					role === 'driver' &&
					<Fragment>
						{
							started.indexOf(status) !== -1 &&
							<p>После подтверждения условий договора, вы сможете забрать груз от клиента</p>
						}
						{
							status === 'cargo_loaded' &&
							<p>Ожидайте оплаты перевозки клиентом. После оплаты вы сможете начать свой маршрут.</p>
						}
						{
							status === 'accepted' &&
							<p>После погрузки клиент сможет оплатить перевозку</p>
						}
						{
							status === 'accepted' &&
							<div className={style.action__buttons}>
								<Button
									color={'green'}
									onClick={this.sendUploadAccept}
								>
									Груз принял
								</Button>
								<Button
									color={'white'}
									onClick={() => toggleDialog('dialogChangeContract')}
								>
									Редактировать договор
								</Button>
							</div>
						}
					</Fragment>
				}
				{
					role === 'customer' &&
					<Fragment>
						{
							(started.indexOf(status) !== -1 || status === 'accepted' || status === 'edit_request') &&
							<p>После проверки веса вашего груза появится возможность оплатить перевозку</p>
						}
						{
							status === 'cargo_loaded' &&
							<Fragment>
								<p>Погрузка завершена, пожалуйста произведите оплату</p>
								<div className={style.action__buttons}>
									<Button
										color={'green'}
										onClick={() => toggleDialog('dialogPay')}
									>
										Оплатить перевозку
									</Button>
								</div>
							</Fragment>
						}
					</Fragment>
				}
				{
					moneyReseverd.indexOf(status) !== -1 &&
					<p>Оплата успешно произведена</p>
				}
			</StepContainer>
		);
	}
}