import showError from 'libs/showFormErrors';
import {
	datePickerValue,
	isToday,
	prevDate,
	required,
	notZero,
	name,
	email,
	confirmPassword,
	password
} from 'libs/validationsForms';
import { sendCargoRequest } from 'actions/customer';
import { dateToString } from 'libs/convertDate';
import { removeItem } from 'libs/localStorage';
import { auth, sendOfferWithoutReg } from 'actions/user';
import { saveToken } from 'libs/tokenDriver';
import { SubmissionError } from 'redux-form';
import { getData } from 'actions/profile';

export const sendNewOfferToServer = (values, dispatch, role, afterReg, resetCaptcha) => {
	return new Promise((resolve, reject) => {
		validateOffer(values, role);
		let sendValues = mapperOfferToServer(values);
		if (values.isGuest === 'SignIn') {
			let phone = values.phoneCode + values.phoneNumber.replace(/\s/g, '');
			let authValues = { phone, password: values.password };

			new Promise ((resolve, reject) => {
				dispatch(auth(authValues, { resolve, reject }));
			}).then(token => {
				saveToken('local', token);
				new Promise((resolve, reject) => {
					dispatch(getData(null, { resolve, reject }));
				}).then(() => {
					dispatch(sendCargoRequest(sendValues, { resolve, reject }));
				});
			}).catch((errors) => {
				reject(new SubmissionError(errors));
			});
		}

		if (values.isGuest === 'SignUp') {
			let regValues = {
				...sendValues,
				firstName: values.firstName,
				lastName: values.lastName,
				email: values.email,
				phone: values.phoneCode + values.phoneNumber.replace(/\s/g, ''),
				password: values.password,
				passwordAgain: values.passwordAgain,
				recaptchaValue: values.recaptchaValue
			};

			new Promise ((resolve, reject) => {
				dispatch(sendOfferWithoutReg(regValues, { resolve, reject }));
			}).then(() => {
				dispatch(sendCargoRequest(sendValues, { resolve, reject }));
				afterReg(regValues.phone);
			}).catch((errors) => {
				resetCaptcha();
				if (errors.email && typeof errors.email === 'object') {
					if (errors.email[0].search('has already been taken') !== -1) {
						errors.email = 'Указанный email уже зарегистрирован в системе';
					}
				}
				reject(new SubmissionError(errors));
			});
		}

		if (values.isGuest === false) {
			if (role === 'guest') {
				// window.blur();
				if (document.querySelector('input[name="phoneNumber"]') !== null)
					document.querySelector('input[name="phoneNumber"]').blur();
				reject();
			}

			dispatch(sendCargoRequest(sendValues, { resolve, reject }));
		}
	}).then(() => {

	}).catch(errors => {
		return Promise.reject(errors)
	}).finally(() => {
		removeItem('points');
	});
};


export const validateOffer = (values, role) => {
	let validateValues = {};

	if (values.isGuest === 'SignUp') {
		validateValues = {
			firstName: [required(values.firstName), name(values.firstName)],
			lastName: [
				required(values.lastName),
				name(values.firstName),
			],
			email: [
				required(values.email),
				email(values.email)
			],
			password: [
				required(values.password),
				password(values.password)
			],
			passwordAgain: [
				required(values.passwordAgain),
				confirmPassword(values.password, values.passwordAgain)
			],
			recaptchaValue: [required(values.recaptchaValue)]
		};
	}

	validateValues = {
		...validateValues,
		'cargo.title': [required(values.cargo.title)],
		'cargo.categoryId': [required(values.cargo.categoryId)],
		'cargo.weight': [required(values.cargo.weight)],
		'cargo.weightCount': [required(values.cargo.weightCount)],
		'cargo.packageId': [required(values.cargo.packageId)],
		'cargo.width': [
			required(values.cargo.width),
			notZero(values.cargo.width),
		],
		'cargo.height': [
			required(values.cargo.height),
			notZero(values.cargo.height)
		],
		'cargo.length': [
			required(values.cargo.length),
			notZero(values.cargo.length)
		],
		'bodyTypeId': [required(values.bodyTypeId)],
		'loadTypeId': [required(values.loadTypeId)],
	};

	validateValues.agree = [required(values.agree)];
	if (role === 'guest') validateValues.phoneNumber = [required(values.phoneNumber)];

	values.route.items.forEach((point, index) => {
		validateValues[`route.items.${index}.address`] = [required(values.route.items[index].address)];
		validateValues[`route.items.${index}.timeIntervalLoad`] = [required(values.route.items[index].timeIntervalLoad)];
		validateValues[`route.items.${index}.providerName`] = [required(values.route.items[index].providerName)];
		validateValues[`route.items.${index}.providerPhone`] = [required(values.route.items[index].providerPhone)];

		if (index === 0) {
			validateValues[`route.items.${index}.startDate`] = [
				required(values.route.items[index].startDate),
				datePickerValue(values.route.items[index].startDate),
				isToday(values.route.items[index].startDate)
			]
		} else {
			validateValues[`route.items.${index}.startDate`] = [
				required(values.route.items[index].startDate),
				datePickerValue(values.route.items[index].startDate),
				prevDate(values.route.items[index - 1].startDate, values.route.items[index].startDate)
			]
		}
	});

	showError(validateValues);
};

export const mapperOfferToServer = (values) => {
	let items = [];
	let additionIds = [];

	if (values.cargo.additionIds !== undefined && values.cargo.additionIds.length > 0) {
		values.cargo.additionIds.forEach(el => {
			additionIds.push({ additionId: el });
		});
	}
	delete values.cargo.additionals;

	values.route.items.forEach(el => {
		if (!el.address.pos)
			return items.push({});

		const coord = el.address.pos.split(' '),
			time = el.startTime ? el.startTime : '00:00';

		let providerPhone = '';
		if (el.providerPhone)
			providerPhone = el.providerPhone.replace(/( |_)/ig, '');
		if (providerPhone)
			providerPhone = providerPhone ? el.phone_code + '' + providerPhone : '';

		if (el.startDate) {
			if (typeof (el.startDate) === 'string' && el.startDate.indexOf('Z') !== -1) {
				el.startDate = dateToString(new Date(el.startDate), 'serverDate') + ' ' + time + ':00';
			} else if (typeof (el.startDate) === 'object') {
				el.startDate = dateToString(el.startDate, 'serverDate') + ' ' + time + ':00';
			}
		}

		items.push({
			address: el.address.name,
			country: el.address.country,
			region: el.address.region,
			latitude: coord[0],
			longitude: coord[1],
			providerName: el.providerName,
			providerPhone,
			expireDays: el.startDate,
			startDate: el.startDate,
			isReady: null,
			locality: el.address.locality,
			timeIntervalLoad: el.timeIntervalLoad.value
		});
	});

	return {
		...values,
		route: {
			...values.route,
			items,
		},
		cargo: {
			...values.cargo,
			weight: values.cargo.weight * values.cargo.weightType,
			categoryId: values.cargo.categoryId.value,
			packageId: values.cargo.packageId.value,
			additionIds
		},
		comment: values.comment,
		isEnableNotifications: null,
		deadlineDays: null,
		price: parseInt(values.price),
		bodyTypeId: values.bodyTypeId.value,
		loadTypeId: values.loadTypeId.value,
	};
};
