import React from 'react';
import PropTypes from 'prop-types';
import { Star } from 'components/UI/Icons';
import style from './style.less';

const SelectedStar = props => {
	let { input, label, defaultValue } = props,
		starsMap = 5,
		stars = [],
		handelSelect = index => input.onChange(index + 1);

	const isChecked = (index, defaultValue, value, starsMap) => {
		const checked = value ? value === index + 1 : defaultValue === index + 1;

		if(checked && !value) {
			handelSelect(index);
		}

		if(starsMap) {}

		return checked;
	};

	for (let i = 0; i < starsMap; i++) {
		stars.push(
			<label key={i}>
				<input
					checked={isChecked(i, defaultValue, input.value, starsMap)}
					onChange={() => handelSelect(i)}
					name={input.name}
					type="radio"
				/>
				{ Star(i + 1 <= input.value ? style.gold : undefined) }
			</label>
		);
	}

	return (
		<div className={style.starContainer}>
			<span className={style.label}>{ label }</span>
			<span className={style.starBgGray}>{ stars }</span>
		</div>
	);
};

SelectedStar.propTypes = {
	input: PropTypes.object,
	defaultValue: PropTypes.any,
	label: PropTypes.string,
};

SelectedStar.defaultProps = {
	label: '',
	defaultValue: 0,
	input: {
		onChange: ()=>{},
	},
};

export default SelectedStar;