exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2jNIbEFpnUta_R3rVAFaK {\n  position: relative;\n}\n._2jNIbEFpnUta_R3rVAFaK ._3thBpTrQGB64IPDvbJ8Fwp {\n  position: absolute;\n  right: 0;\n  bottom: 0;\n  margin-right: 0;\n  margin-bottom: 0;\n}\n._2jNIbEFpnUta_R3rVAFaK ._3thBpTrQGB64IPDvbJ8Fwp div {\n  border: 2px solid #fff;\n  width: 13px;\n  height: 13px;\n  border-radius: 50%;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/UI/Wrappers/AvatarOnlineContainer/style.less"],"names":[],"mappings":"AAAA;EACE,mBAAmB;CACpB;AACD;EACE,mBAAmB;EACnB,SAAS;EACT,UAAU;EACV,gBAAgB;EAChB,iBAAiB;CAClB;AACD;EACE,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,mBAAmB;CACpB","file":"style.less","sourcesContent":[".container {\n  position: relative;\n}\n.container .status {\n  position: absolute;\n  right: 0;\n  bottom: 0;\n  margin-right: 0;\n  margin-bottom: 0;\n}\n.container .status div {\n  border: 2px solid #fff;\n  width: 13px;\n  height: 13px;\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "_2jNIbEFpnUta_R3rVAFaK",
	"status": "_3thBpTrQGB64IPDvbJ8Fwp"
};