import { setPoint } from 'actions/map';
import { LabelError } from 'components/UI/Form/Labels';
import getLocationByName from 'libs/getLocationByName';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import style from './style.less';

export default class Address2 extends React.PureComponent {
    static propTypes = {
        label: PropTypes.string,
        setValue: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        renderToMap: PropTypes.string,
        classNameContainer: PropTypes.string,
        containerGroup: PropTypes.string,
        point: PropTypes.string,
    };
    static defaultProps = {
        label: '',
        renderToMap: '',
        containerGroup: '',
        classNameContainer: '',
    };
    state = {
        options: [],
        focus: false,
        value: '',
        err: false,
        activeOption: null,
    };
    containerRef = React.createRef();
    handleHideList = event => {
        if (this.containerRef && !this.containerRef.contains(event.target)) {
			this.setState({ focus: false });
			if (!this.state.activeOption && this.state.options.length > 0) {
			    this.handleSelectOption(this.state.options[0]);
            }
		}
    };
    handleFocus = e => {
        if (typeof(this.props.onFocus) === 'function') this.props.onFocus(e);
        this.setState({ focus: true, err: undefined });
    };
    handleInput = event => {
        let location = event.target.value,
            { setValue, name } = this.props;
        setValue(`${name}[pos]`, null);
        this.setState({ value: location });
        if (location)
            getLocationByName(location, res => this.setState({ focus: true, options: res }));
        else
            this.setState({ options: [] });
    };
    handleSelectOption = option => {
        let { setValue, name, dispatch } = this.props;
        let locality = '';
        let address_name = [];
        let nextData = { kind: '', name: '' };
        let prevData = { kind: '', name: '' };
        let components = option.metaDataProperty.GeocoderMetaData.Address.Components;
        const { CountryName, AdministrativeArea } = option.metaDataProperty.GeocoderMetaData.AddressDetails.Country;
        let city = null;
        components.forEach((el, i) => {
            if (components.length > i + 1) nextData = { kind: components[i + 1].kind, name: components[i + 1].name };
            else nextData = { kind: '', name: '' };
            if (el.kind === 'locality') city = el.name;
            if (el.kind === 'province' || el.kind === 'locality') locality = el.name;
            if (el.kind !== 'country' && el.kind !== 'area') {
                if (nextData.kind !== el.kind && prevData.name !== el.name)
                    address_name.push(el.name);
                prevData = { kind: el.kind, name: el.name };
            }
        });

        let showed_name = '';

        if (address_name.length === 2) address_name.reverse();
        address_name.forEach((el, i) => {
            showed_name += el;
            if (address_name.length !== i + 1) showed_name += ', ';
        });

        setValue(`${name}[locality]`, locality);
        setValue(`${name}[name]`, showed_name);
        setValue(`${name}[pos]`, option.Point.pos);
        setValue(`${name}[country]`, CountryName);
        setValue(`${name}[region]`, AdministrativeArea.AdministrativeAreaName);
        setValue(`${name}[city]`, city);

        this.setState({ focus: false, activeOption: option, value: option.name + ' ' + option.description });
        if (this.props.renderToMap) {
            dispatch(setPoint({
                key: this.props.renderToMap,
                pos: option.Point.pos,
                label: option.name
            }));
        }
    };

    componentDidMount() {
        document.addEventListener('click', this.handleHideList, false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleHideList, false);
    }

    render() {
        let { name, label, classNameContainer, containerGroup } = this.props,
            { options, focus, value, err } = this.state;

        const container = `${style.field__flex_container} ${classNameContainer}
			${value ? style.not_empty : ''} ${containerGroup !== 'none' ? style[containerGroup] : ''} 
			${focus ? style.focus : ''} ${err ? style.error : ''}`;
        const errorCheck = err !== null && err !== undefined;
        return (
            <React.Fragment>
                <div className={container} ref={ref => this.containerRef = ref}>
                    <div className={style.field__input} onFocus={this.handleFocus}>
                        <Field
                            name={`${name}[name]`}
                            component={'input'}
                            onInput={this.handleInput}
                        />
                        <Field
                            name={`${name}[pos]`}
                            component="input"
                            type="hidden"
                        />
                        <Field
                            name={`${name}[locality]`}
                            component="input"
                            type="hidden"
                        />
                        <Field
                            name={`${name}[country]`}
                            component="input"
                            type="hidden"
                        />
                        <Field
                            name={`${name}[region]`}
                            component="input"
                            type="hidden"
                        />
                        <label>{label}</label>
                    </div>
                    {err && <div className={`${style.show_state} ${style.show_error}`}>!</div>}
                    {
                        (options.length > 0 && focus) &&
                        <div className={style.list}>
                            {
                                options.map(opt => (
                                    <div
                                        key={opt.Point.pos + opt.name}
                                        onClick={() => this.handleSelectOption(opt)}
                                        className={style.mapList}
                                    >
                                        {opt.name}
                                        <span>{opt.description}</span>
                                    </div>
                                ))
                            }
                        </div>
                    }
                    <Field
                        name={name}
                        component={({ input, meta }) => {
                            this.setState({ err: meta.error, value: input.value });
                            return <div/>;
                        }}
                    />
                </div>
                {err && <LabelError message={this.state.err} isOn={errorCheck}/>}
            </React.Fragment>
        );
    }
}
