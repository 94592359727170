import AdminGrid from 'components/Admin/UI/Grid';
import { AdminLabel } from 'components/Admin/UI/Label';
import Status from "components/UI/Status/index";
import React from 'react';

import style from './style.less';
import { dateToString } from "libs/convertDate";

const RouteData = props => {
    const { type, status, geography, items, price } = props;

    let title = 'Желаемый маршрут';
    if (type === 'planned') title = 'Запланированный маршрут';
    return (
        <>
        <h3> Маршрут </h3>
        <AdminGrid type="passport">
            <div>
                <AdminLabel title="Тип маршрута:">
                    {title}
                </AdminLabel>
                <AdminLabel title="Cтатус маршрута:">
                    <Status status={status} className={style.center}/>
                </AdminLabel>
                <AdminLabel title="Георграфия маршрута:">
                    По {geography === 'city' ? 'городу' : geography === 'region' ? 'области' : 'стране'}
                </AdminLabel>

                {
                    items.map((el, index) => {
                        return (
                            <AdminLabel key={index} title={`${
                                index === 0 ? 'Адрес отправления' :
                                    index === (items.length - 1) ? 'Адрес прибытия' :
                                        'Промежуточная точка'}:`
                            }>
                                { el.address } <br/> {dateToString(new Date(el.startDate), '')}
                            </AdminLabel>
                        )
                    })
                }
                <AdminLabel title="Ставка по маршруту:">
                    {price} руб/км
                </AdminLabel>



            </div>
        </AdminGrid>
        </>
    )
};


export default RouteData;