import React from 'react';
import style from './style.less';
import { withRouter } from 'react-router-dom';
import { UseCustomStyles } from 'libs/UseCustomStyles';
import Container from 'components/UI/Wrappers/Container';
import Logo from 'components/UI/Header/logo';
import Nav from 'components/UI/Header/nav';
import Tools from 'components/UI/Header/tools';
import MobileMenu from 'components/UI/Header/mobilemenu';

class Header extends React.Component {

	state = { open: false };

	onHandleMobileButton = () => {
		this.setState({ open: !this.state.open }, () => {
			if (this.state.open) document.body.style.overflow = 'hidden';
			else document.body.style.overflow = 'auto';
		});
	};

	onCloseMobileButton = () => {
		if (this.state.open)
			this.setState({ open: false }, () => {
				document.body.style.overflow = 'auto';
			});
	};

	render () {
		const isCustomStyles = UseCustomStyles(this.props.location.pathname);
		let renderClassName = style.header;
		if (isCustomStyles) renderClassName += ' ' + style.headerCustom;
		if (this.state.open) renderClassName += ' ' + style.headerOpen;
		return (
			<header className={renderClassName}>
				<Container className={style.container}>
					<Logo
						isCustomStyles={isCustomStyles}
						closeMenu={this.onCloseMobileButton}
					/>
					<Nav
						isCustomStyles={isCustomStyles}
						closeMenu={this.onCloseMobileButton}
					/>
					<Tools
						isCustomStyles={isCustomStyles}
						closeMenu={this.onCloseMobileButton}
						onClick={this.onHandleMobileButton}
						open={this.state.open}
					/>
				</Container>
				<MobileMenu
					className={style.menu}
					closeMenu={this.onCloseMobileButton}
				/>
			</header>
		)
	}
}

export default withRouter(Header);
