import PropTypes from 'prop-types';
import React from 'react';
import style from './style.less';

const OnlineStatus = props => {
    const { status, justIcon, className, text } = props,
        showText = !justIcon || (!status && !justIcon);

    return (
        <div className={`${style.status} ${className}`}>
            <div className={status ? style.online : style.offline}/>
            {
                showText
                && <div className={style.info}>{ text }</div>
            }
        </div>
    )
};

OnlineStatus.propTypes = {
    status: PropTypes.bool.isRequired,
    justIcon: PropTypes.bool,
    className: PropTypes.string,
    text: PropTypes.any,
};
OnlineStatus.defaultProps = {
    justIcon: false,
    className: '',
    text: 'В сети',
};

export default OnlineStatus;
