import { rmCargoRequest } from 'actions/customer';
import { driverActions } from 'actions/driver';
import { DeleteDialog } from 'components/Profile/MyCargoDetail/Dialogs';
import ActionMenu from 'components/UI/ActionMenu';
import language from 'language';
import { dateToString, stringToDate } from 'libs/convertDate';
import forwardTo from 'libs/forwardTo';
import { getCar } from 'libs/getInfoRoute';
import { getVolume } from 'libs/getVolume';
import { weightConversionShort } from 'libs/weightConversion';
import cargoServerToClient from 'mappers/cargoServerToClient';
import routeServerToClient from "mappers/routeServerToClient";
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import RequestPoints from './RequestPoints';
import style from './style.less';
import DataRoute from 'components/UI/Previews/DataRoute';


class RequestRouteData extends React.Component {

    state = {
        removeOfferID: 0,
    };

    edit = () => {
        if (this.props.isRoute) {
            const { offer } = this.props;
            if (offer.bodyHeight) {
                offer.cargo = {
                    width: offer.bodyWidth,
                    height: offer.bodyHeight,
                    length: offer.bodyLength
                }
            }
            sessionStorage.setItem('addRoute', JSON.stringify(routeServerToClient(offer)));
            forwardTo('/edit/route/');
        } else {
            sessionStorage.setItem('offerForm', JSON.stringify(cargoServerToClient(this.props.offer)));
            forwardTo('/edit/offer/');
        }
    };

    actionDelete = id => this.setState({ removeOfferID: id });

    removeCustomerRequest = () => {
        let event = null;
        if (this.props.isRoute) {
            event = driverActions.rmRoute({ id: this.state.removeOfferID, route: false});
        } else {
            event = rmCargoRequest(this.state.removeOfferID);
        }

        this.props.dispatch(event);
        this.actionDelete(0);
    };

    handleFinish = () => {
        this.props.dispatch(driverActions.finishRoute({ id: this.props.offer.id, route: false }));
    };

    handleExtend = () => {
        this.props.dispatch(driverActions.extendRoute({ id: this.props.offer.id, route: false }));
    };

    render() {
        const { props } = this,
            { transport } = props;

        let createTime = dateToString(stringToDate(props.createTime), 'default');

        let showTransport = '',
            volume = {},
            title = '',
            type = 'route';

        if ((props.offer.status === 'unpublished' && props.type === 'planned') || props.offer.status === 'cancelled')
            type = 'routePlannedEnd';
        if (props.offer.status === 'unpublished' && props.type === 'desired')
            type = 'routeDesiredEnd';


        if (props.isRoute) {
            showTransport = getCar(transport);
            title = props.type === 'planned' ? 'Запланированный маршрут' : 'Желаемый маршрут';
        } else {
            volume = { height: props.cargo.height, width: props.cargo.width, length: props.cargo.length };
            title =
                <React.Fragment>{language[props.cargo.category]}, {weightConversionShort(props.cargo.weight)}, {getVolume(volume)}м<sup>3</sup></React.Fragment>;
        }

        return (
            <div className={style.requestRoute}>
                <Link className={style.url} to={props.url}/>
                <div className={style.header}>
                    <div>{title} &nbsp;
                        {
                            props.isRoute &&
                            <span
                                style={{ display: 'inline-block' }}
                                className={style.footer}
                            >№{props.offerID} от {createTime}</span>
                        }
                    </div>

                    <ActionMenu
                        type={type}
                        actionEdit={this.edit}
                        actionDelete={() => this.actionDelete(props.offerID)}
                        actionCancelled={this.handleExtend}
                        actionArbitrate={this.handleFinish}
                    />

                </div>
                <div className={style.body}>
                    {
                        props.type === 'planned' && <RequestPoints points={props.route}/>
                    }
                    {
                        props.type !== 'planned' && <DataRoute data={props} />
                    }
                </div>
                <div className={style.footer}>
                    {
                        !props.isRoute &&
                        <div className={style.transport}>
                            { props.isTrade ? 'Сделка ' : 'Заявка ' }
                            №{props.offerID} { !props.isTrade && <Fragment>от { createTime }</Fragment> }
                        </div>
                    }
                    {
                        props.isRoute
                        && <div className={style.transport}>{showTransport}</div>
                    }
                    <Link to={props.url} className={style.buttonHover}>Подробнее</Link>
                </div>
                {
                    this.state.removeOfferID !== 0 &&
                    <DeleteDialog
                        isRoute={props.isRoute}
                        cancel={() => this.actionDelete(0)}
                        remove={this.removeCustomerRequest}
                    />
                }
            </div>
        );
    }
}


RequestRouteData.propTypes = {
    createTime: PropTypes.string.isRequired,
    offerID: PropTypes.any.isRequired,
    route: PropTypes.array.isRequired,
    url: PropTypes.string.isRequired,
    transport: PropTypes.object,
    cargo: PropTypes.object,
    offer: PropTypes.object,
    isRoute: PropTypes.bool,
    isTrade: PropTypes.bool,
	actionMenuType: PropTypes.string,
};
RequestRouteData.defaultProps = {
    cargo: {},
    transport: {},
    offer: {},
    isRoute: false,
    isTrade: false,
    actionMenuType: '',
};

export default connect()(RequestRouteData);
