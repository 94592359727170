import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { convertNumberSpaces } from 'libs/convertNumberSpaces';
import Status from 'components/UI/Status';
import Badge from 'components/UI/Badge';
import style from './style.less';
import connect from "react-redux/es/connect/connect";
import {searchActions} from 'actions/search';
import {getProfileType} from 'libs/selections';
import forwardTo from 'libs/forwardTo';
import mapperFromRouteToSearch from 'mappers/mapperFromRouteToSearch';
import mapperFromOfferToSearch from 'mappers/mapperFromOfferToSearch';
import { setItem } from 'libs/sessionStorage';

class RequestCostumer extends React.PureComponent {
	static defaultProps = {
		proposals: [],
		route: [],
		isRoute: false,
	};

	static propTypes = {
		price: PropTypes.number.isRequired,
		isRoute: PropTypes.bool,
		offerID: PropTypes.number.isRequired,
		offer: PropTypes.object.isRequired,
		proposals: PropTypes.array,
		route: PropTypes.array,
		status: PropTypes.string.isRequired,
	};
	params = {};
	state = {
		findCount: undefined,
	};

	componentDidMount(){
		let {offer} = this.props;
		if (this.props.role === 'driver') this.params = mapperFromRouteToSearch(offer);
		else this.params = mapperFromOfferToSearch(offer);

		new Promise((resolve, reject)=>{
			this.props.dispatch(searchActions.getCountByOffer(this.params,  {resolve, reject}));
		}).then(findCount=>{
			this.setState({findCount})
		}).catch(()=>{
			this.setState({findCount: 0})
		});
	}

	getFindCount = () => this.state.findCount === undefined ? '...' : this.state.findCount;

	goToSearch = () => {
		setItem('searchParams', JSON.stringify(this.params));
		forwardTo('/search/');
	};

	render() {
		const currency = this.props.isRoute ? 'р/км' : 'р.';
		const findClients = this.props.isRoute ? 'Найдено клиентов' : 'Найдено водителей';
		
		const { proposalsNewCount, proposalsTotalCount } = this.props;
		
		const link = this.props.isRoute ? `/route/${this.props.offerID}#proposals` : `/offer/${this.props.offerID}#proposals`;

		return (
			<div className={`${style.requestCommon} ${style.requestCostumer}`}>
				<div className={style.price}>
					<div className={style.title}>
						{ this.props.isRoute ? 'Ставка на перевозку' : 'Ваша ставка' }
					</div>
					{
						this.props.price ?
							<div className={style.value}>
								{convertNumberSpaces(this.props.price)} <span className={style.currency}>{currency}</span>
							</div>
							:
							<span>Не указана</span>
					}
				</div>
				<div className={style.item}>
					<Link to={link}>
						Предложений <span>{ proposalsTotalCount }</span>
						{' '}
						{
							proposalsNewCount > 0
							&& <Badge>+{ proposalsNewCount }</Badge>
						}
					</Link>
				</div>
				<div className={style.item}>
					<span className={style.a} onClick={this.goToSearch}>
						{ findClients } <span> {this.getFindCount()}</span>
					</span>
				</div>
				<div className={`${style.item} ${style.status}`}>
					<Status status={this.props.status}/>
				</div>
			</div>
		);
	}
}

export default connect(store=>({
	role: getProfileType(store)
}))(RequestCostumer);