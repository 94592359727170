import React from 'react';
import { Facebook, Vk } from 'components/UI/Icons';
import Container from 'components/UI/Wrappers/Container';
import style from './style.less';

const Copyright = props => (
	<Container className={props.mainPage ? style.containerMain : ''}>
		<div className={style.copyright}>

			<div className={style.company}>
				&copy; 2020, Сервис грузоперевозок HurryLorry
			</div>

			<div className={style.social} style={{ display: 'none' }}>
				<ul>
					<li>
						<a href="/" target="_blank" className={style['social-network-link']}>
							{ Facebook(style.icon) } Facebook
						</a>
					</li>
					<li>
						<a href="/" target="_blank" className={style['social-network-link']}>
							{ Vk(style.icon) } ВКонтакте
						</a>
					</li>
				</ul>
			</div>

			<div className={style['other-links']}>
				<ul>
					<li><a target="_blank" href="/agreement_hl.pdf">Правила сайта</a></li>
					<li><a target="_blank" href="/agreement.pdf">Политика конфиденциальности</a></li>
				</ul>
			</div>

		</div>
	</Container>
);

export default Copyright;