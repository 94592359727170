import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/UI/Form/Button';
import { LabelError } from 'components/UI/Form/Labels';
import { ArrowDownChevron, CrossOut } from 'components/UI/Icons';
import style from './style.less';

export default class SelectGroup extends React.PureComponent {
	state = {
		focus: false,
		filterStr: '',
		selected:
			this.props.input.value ?
				(Array.isArray(this.props.children) ? this.props.children : [this.props.children]).filter(opt => (
					this.props.input.value.indexOf(opt.props.value) !== -1
				)) : [],
		selectedValue: this.props.input.value ? this.props.input.value : [],
	};

	inputRef = undefined;

	static propTypes = {
		label: PropTypes.string,
		input: PropTypes.object,
		meta: PropTypes.object,
		children: PropTypes.oneOfType([
			PropTypes.array,
			PropTypes.object,
		]),
	};

	static defaultProps = {
		label: '',
		input: {value: '', onChange: () => {}},
		meta: {},
		children: [],
	};

	componentDidMount() {
		document.addEventListener('click', this.handleHideList, false);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleHideList, false);
	}

	handleHideList = event => {
		if (this.inputRef && !this.inputRef.contains(event.target)) {
			this.setState({ focus: false });
		}
	};

	handleToggle = () => {
		this.setState({ focus: !this.state.focus });
	};

	renderOptions = options => {
		if(!Array.isArray(options))
			options = [options];

		let selectedValue = [...this.state.selectedValue];
		let returnOptions = options.filter(opt => (
				opt.props.filterStr &&
				typeof(opt.props.filterStr.toLowerCase) === 'function' &&
				selectedValue.indexOf(opt.props.value) === -1 &&
				opt.props.filterStr.toLowerCase().indexOf(this.state.filterStr.toLowerCase()) !== -1
			)
		).map((opt, i) => <div onClick={() => this.handleAdd(opt)} key={i}>{opt}</div>);

		if (returnOptions.length === 0) {
			returnOptions = <div>Ничего не найдено</div>;
		}
		return returnOptions;
	};

	handleAdd = option => {
		let newState = {
			selected: [...this.state.selected, option],
			selectedValue: [...this.state.selectedValue, option.props.value],
			filterStr: '',
		};

		this.setState(newState);
		return this.props.input.onChange(newState.selectedValue);
	};

	handleRemove = option => {
		let newState = {
			selected: [...this.state.selected],
			selectedValue: [...this.state.selectedValue],
			filterStr: '',
		};

		newState.selected.splice(newState.selected.indexOf(option), 1);
		newState.selectedValue.splice(newState.selectedValue.indexOf(option.props.value), 1);

		this.props.input.onChange(newState.selectedValue);
		this.setState(newState);
	};

	handleInput = event => {
		this.setState({ filterStr: event.target.value });
	};

	render() {
		const { children, input, meta, label } = this.props;
		const { focus, selected, filterStr } = this.state;

		if (!selected && input.value !== '')
			input.onChange(input.value);

		const container = `${style.field__flex_container} ${style.field__flex_container_pointer} ${focus ? style.focus : ''} 
			${filterStr ? style.not_empty : ''} ${meta.error ? style.error : ''}`;

		return (
			<React.Fragment>
				<div className={container} onClick={this.handleToggle} ref={ref => this.inputRef = ref}>
					<div className={style.field__input}>
						<input value={filterStr} onInput={this.handleInput} onChange={this.handleInput} type="text" />
						<label>{ label }</label>
					</div>
					{
						meta.error
						&& <div className={`${style.show_state} ${style.rIcon} ${style.show_error}`}>!</div>
					}
					<div className={style.arrow_state}>{ ArrowDownChevron(style.arrow) }</div>
					<div className={style.list}>
						{ this.renderOptions(children) }
					</div>
				</div>
				<div className={style.multiselectList}>
					{
						selected.map((option, i) => (
							<div className={style.multiselectItem} key={i}>
								{option}
								<Button className={style.close} onClick={() => this.handleRemove(option)}>{CrossOut()}</Button>
							</div>
						))
					}
				</div>
				{
					meta.error
					&& <LabelError message={meta.error} />
				}
			</React.Fragment>
		);
	}
}