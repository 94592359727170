import React from 'react';
import PropTypes from 'prop-types';
import OnlineStatus from 'components/UI/OnlineStatus';
import Avatar from 'components/UI/ProfileUI/Avatar';
import style from './style.less';

const AvatarOnlineContainer = props => {
	const { src, size, darkBG, className, status } = props;

	return (
		<div className={`${style.container} ${className}`}>
			<Avatar size={size} src={src} darkBG={darkBG} />
			<OnlineStatus status={status} justIcon className={style.status} />
		</div>
	);
};


AvatarOnlineContainer.propTypes = {
	src: PropTypes.any,
	size: PropTypes.number,
	darkBG: PropTypes.bool,
	className: PropTypes.string,
	status: PropTypes.bool.isRequired,
};

AvatarOnlineContainer.defaultProps = {
	src: null,
	size: 60,
	darkBG: false,
	className: '',
};

export default AvatarOnlineContainer;
