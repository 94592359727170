import React, { Component } from 'react';
import AdminPage from 'components/Admin/UI/Page';
import AdminGrid from 'components/Admin/UI/Grid';
import SearchAdminForm from 'components/Admin/ReduxForms/SearchAdminForm';
import AdminList from 'components/Admin/UI/List';
import UserItem from 'components/Admin/Pages/Clients/List/UserItem';
import LeftMenu from 'components/Admin/Pages/Clients/List/LeftMenu';
import { convertNumberSpaces } from 'libs/convertNumberSpaces';
import AdminLoader from 'components/Admin/UI/Loader';
import listStyle from 'components/Admin/UI/List/style.less';
import { getData } from 'components/Admin/Pages/Clients/List/getData';
import { connect } from 'react-redux';
import AdminPagination from 'components/Admin/UI/Pagination';
import { getClientsState } from 'components/Admin/getStore';
import { getItem, setItem } from 'libs/localStorage';


class AdminClientList extends Component {
	
	state = {
		loading: true,
		params: getItem('clientListParams') ? JSON.parse(getItem('clientListParams')) : {
			page: 1,
			pageSize: 10,
		}
	};

	async componentWillMount() {
		if (this.props.clients.total === 0)
			await this.loadClients();
		else
			this.setState({ loading: false });
	}

	loadClients = async () => {
		this.setState({ loading: true });

		await getData(this.props.dispatch, this.state.params, () => {
			setItem('clientListParams', JSON.stringify(this.state.params));
			this.setState({ loading: false });
		});
	};
	
	setPage = async page => {
		this.setState({ params: { ...this.state.params, page } }, async () => {
			await this.loadClients();
		});
	};
	
	
	render() {

		const { total, items } = this.props.clients;
		
		const titles = ['ID', 'Фио', 'Дата регистрации'];

		let pageCount = 1;
		if (total > 0) pageCount = Math.ceil(total / this.state.params.pageSize);
		
		let list = [
			{
				title: 'Список',
				count: convertNumberSpaces(total),
				newCount: convertNumberSpaces(0)
			},
		];

		return (
			<AdminPage>
				
				<LeftMenu
					list={list}
				/>
				
				<div>
					<AdminGrid type="mainContent">
						<h1>Список клиентов</h1>
						
						<SearchAdminForm />
						
						<AdminList
							titles={titles}
							type="clients"
						>
							{
								this.state.loading &&
								<AdminLoader className={listStyle.loading} />
							}
							{
								!this.state.loading && total > 0 &&
								<UserItem
									users={items}
								/>
							}
							{
								!this.state.loading && total === 0 &&
								<p>Ничего не найдено</p>
							}
							{
								pageCount > 1 &&
								<AdminPagination
									pageCount={pageCount}
									activePage={this.state.params.page}
									setPage={e => this.setPage(e)}
								/>
							}
						</AdminList>
						
					</AdminGrid>
				</div>
				
			</AdminPage>
		);
	}
}


export default connect(
	state => ({
		clients: getClientsState(state),
	})
)(AdminClientList);