import { getToken } from 'libs/tokenDriver';

const request = (method, url, data: Object, type: String = 'json') => {
	//если получили авторизацию, добавим
	const headers = new Headers(),
		token = getToken(),
		serializeQuery = (params, prefix) => {
			const query = Object.keys(params).map((key) => {
				const value = params[key];

				if (params.constructor === Array)
					key = `${prefix}[]`;
				else if (params.constructor === Object)
					key = (prefix ? `${prefix}[${key}]` : key);

				if (typeof value === 'object')
					return serializeQuery(value, key);
				else
					return `${key}=${encodeURIComponent(value)}`;
			});

			return [].concat.apply([], query).join('&');
		};
	if (token) headers.append('Authorization', 'Bearer ' + token);

	const init = { method: method };

	//добавим body если есть данные запроса
	if (data) {
		let requestData = undefined;
		switch (method.toLocaleLowerCase()) {
			case 'get':
				if (Object.keys(data).length > 0) {
					url += url.indexOf('?') > -1 ? '&' : '?';
					url += serializeQuery(data);
				}
				break;
			case 'put':
			case 'post':
				requestData = JSON.stringify(data);
				headers.append('Content-Type', 'application/json');
				break;
			default:
				requestData = new FormData();
				for (let name in data) {
					requestData.append(name, data[name]);
				}
				break;
		}
		init.body = requestData;
	}

	init.headers = headers;
	return fetch(url, init)
		.catch(err => {
			console.error('Request error: ', err);
			if (typeof(err.text) === 'function')
				err.text().then(errorMessage => ({
					data: { errors: errorMessage },
					status: 500
				}));
			else
				return {
					data: { errors: err.toString() },
					status: 500
				}
		})
		.then((response) => {
			if (type === 'blob') {
				return response.blob()
			} else {
				if (typeof(response.json) === 'function') {
					return response.json()
						.then(res => {
							return {
								data: res,
								status: response.status
							}
						}).catch(() => {
							return {
								data: { errors: 'Ошибка: неожиданный ответ от сервера' },
								status: 500
							};
						});
				}
			}
			return {
				data: { errors: 'Некорректный запрос или ответ от сервера' },
				status: 500
			};
		});
};

export default request;