import React, { Fragment } from 'react';
import styles from '../style.less';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { convertNumberSpaces } from 'libs/convertNumberSpaces';
import { connect } from 'react-redux';
import Button from 'components/UI/Form/Button';
import Tooltip from 'components/UI/Tooltip';
import { ArrowDownNavigate, Fire, HeartOutline } from 'components/UI/Icons';
import { addToFavorite, rmFavoriteById } from 'actions/customer';

class DataForOthers extends React.Component {

	static propTypes = {
		id: PropTypes.number.isRequired,
		user: PropTypes.object.isRequired,
		price: PropTypes.number.isRequired,
		isFavorite: PropTypes.bool.isRequired,
		role: PropTypes.string.isRequired,
	};

	handleFavoriteButton = () => {
		const { id } = this.props.user;
		if (this.props.isFavorite) {
			return new Promise((resolve, reject) => {
				this.props.dispatch(rmFavoriteById({ id }, { resolve, reject }));
			}).then(() => this.props.updateData());
		} else {
			return new Promise((resolve, reject) => {
				this.props.dispatch(addToFavorite({ id }, { resolve, reject }));
			}).then(() => this.props.updateData());
		}
	};

	render() {
		const { price, id, isFavorite, role } = this.props;
		if (price === undefined || price === null || isFavorite === undefined) return null;
		const text = isFavorite ? 'Убрать из избранных водителей' : 'Добавить в избранные водители';
		return (
			<div className={`${styles.generaldata} ${styles.othersdata}`}>
				<div className={styles.price}>
					<span className={styles.priceSpan}>
						{ isFavorite && Fire(styles.fireIcon) }
						<span>{ convertNumberSpaces(price) } руб/км</span>
					</span>
				</div>

				{
					role === 'driver' &&
					<Fragment>
						<Button
							clearStyle
							className={`${styles.favorite} ${isFavorite ? styles.true : ''}`}
							onClick={this.handleFavoriteButton}
						>
							{ HeartOutline() } <span className={styles.favoriteText}>{ text }</span>
							<Tooltip className={styles.tooltip} text={text} />
						</Button>
						<Link className={styles.more} to={`/route/${id}`}>
							<Button color={'white'}>Подробнее { ArrowDownNavigate() }</Button>
						</Link>
					</Fragment>
				}
				{
					role === 'customer' &&
					<Link className={styles.more} to={`/route/${id}`}>
						<Button color={'white'}>Подробнее { ArrowDownNavigate() }</Button>
					</Link>
				}
			</div>
		)
	}
}


export default connect(

)(DataForOthers);