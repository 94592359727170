import * as adminActions from 'actions/admin';

export const getData = (dispatch, qs, success) => {

	return new Promise((resolve, reject) => {
		dispatch(adminActions.getClients(qs, { resolve, reject }));
	}).then(() => {
		success();
	});

};