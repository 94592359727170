import React from 'react'
import { insureCargoTrade } from 'libs/statusActions';
import Aside from 'components/UI/Wrappers/Aside';
import Button from 'components/UI/Form/Button';
import { Security } from 'components/UI/Icons';
import styles from 'components/Pages/Trades/Detail/styles.less';


const InsureStatus = ({
	insuranceStatus,
	insuranceNumber,
	insureCargo,
	status
}) => {
	
	if (insuranceStatus === null && insureCargoTrade.indexOf(status) === -1) {
		return false;
	}

	return (
		<Aside marginBottom={30}>

			<div className={`${styles.header} ${styles.header_insurance}`}>
				Страхование груза
				<div className={styles.securityLogo}>
					{ Security(styles.icon) }
				</div>
			</div>

			<div className={styles.body}>
				<div className={styles.insurance_cargo_button}>
					{
						insureCargoTrade.indexOf(status) !== -1 && insuranceStatus === null &&
						<>
							<span>
								Вы можете застраховать груз, через какое-то время с вами свяжется страховая
								компания для подтверждения
							</span>
							<Button onClick={insureCargo}>
								Застраховать груз
							</Button>
						</>
					}

					{
						insuranceStatus === 'LOADING' &&
						<>
							<span>Данные отправлены в страховую компанию, после обработки будет отображен код</span>
						</>
					}

					{
						insuranceStatus === 'OK' &&
						<>
							<span>Код страхования</span><br />
							<span className={styles.codeInsurance}>{ insuranceNumber }</span>
						</>
					}
				</div>
			</div>
		</Aside>
	)
};


export default InsureStatus;
