import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPoints } from 'libs/selections';
import { setDistance, removeAll } from 'actions/map';
import getWordEnd from 'libs/getWordEnd';
import YandexMap from './YandexMap';
import Badge from 'components/UI/Badge';
import style from './style.less';

class Maps extends React.PureComponent {

	api = undefined;
	map = undefined;
	route = undefined;

	state = {
		distance: '',
		start: '',
		finish: '',
		showMap: false,
	};

	static propTypes = {
		layout: PropTypes.string,
		found: PropTypes.number,
		height: PropTypes.number,
	};

	static defaultProps = {
		layout: 'default',
		found: -1,
		height: 600
	};

	componentWillUnmount() {
		this.props.dispatch(removeAll());
		if(this.map) this.map.destroy();
	}

	renderRoute = points => {
		if(!this.api || !this.map) return false;

		let multiRoute = new this.api.multiRouter.MultiRoute({
			referencePoints: points.map(point => point.coordinates)
		}, {
			boundsAutoApply: true,
			wayPointVisible: true,
		});
		//удаляем старый маршрут
		if(this.route && this.map) this.map.geoObjects.remove(this.route);

		multiRoute.events.add('balloonopen', event => {
			let route = event.get("originalTarget");
			if(route){
				let distance = route.properties.get('distance');
				this.props.dispatch(setDistance(distance.value / 1000));
				this.setState({ distance:distance.text });
			}
		});

		multiRoute.model.events.add("requestsuccess", () => {
			let distance = multiRoute.getRoutes().get(0).properties.get('distance');
			this.props.dispatch(setDistance(distance.value / 1000));
			this.setState({
				distance: distance.text,
				start: points[0].label,
				finish: points[points.length - 1].label,
			});
		});

		this.route = multiRoute;
		this.map.geoObjects.add(multiRoute);
	};

	loadedMap = map => {
		this.map = map;
		const arr = this.getPoints();
		if(arr.length > 1) this.renderRoute(arr);
	};

	getPoints = () => {
		const arr = [];
		this.props.points.forEach(it => {
			if(it && it.pos !== undefined){
				let point = it.pos.split(' ');
				arr.push({
					key: it.key,
					coordinates: [parseFloat(point[1]), parseFloat(point[0])],
					label: it.label || it.name,
				});
			}
		});

		return arr;
	};

	loadedApi = api =>{
		this.api = api;
		const arr = this.getPoints();
		if(arr.length > 1) this.renderRoute(arr);
	};

	render() {
		const arr = this.getPoints(),
			{distance, start, finish} = this.state;

		if(arr.length === 0 && this.map) this.map.geoObjects.removeAll();

		if (this.props.layout === 'driver_route')
			return <YandexMap points={arr} loadedMap={this.loadedMap} loadedApi={this.loadedApi} renderRoute={this.renderRoute} height={305} />;


		return (
			<div className={style.mapContainer}>
				<YandexMap
					points={arr}
					loadedMap={this.loadedMap}
					loadedApi={this.loadedApi}
					renderRoute={this.renderRoute}
					height={this.props.height}
				/>
				{
					distance &&
					<div className={style.mapContent}>
						<p>{start} – {finish}</p>
						<Badge className={style.bold}>{distance}.</Badge>
						{
							!this.props.found &&
							<p className={style.margin10}>
								По маршруту найдено: { this.props.found } { getWordEnd(this.props.found, ['машина', 'машины', 'машин'])}
							</p>
						}
					</div>
				}
			</div>
		);
	}
}

export default connect(
	store => ({
		points: getPoints(store),
	})
)(Maps);
