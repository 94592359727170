import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { driverActions } from 'actions/driver';
import * as customerActions from 'actions/customer';
import Loader from 'components/UI/Loader';
import Page from 'components/UI/Wrappers/Page';
import Panel from 'components/UI/Wrappers/Panel';
import forwardTo from 'libs/forwardTo';
import { getDetailRoute, getProfile } from 'libs/selections';
import * as sessionStorage from 'libs/sessionStorage';
import routeServerToClient from 'mappers/routeServerToClient';
import Car from 'components/Profile/Cars/Car';
import style from './style.less';
import Grid from 'components/UI/Wrappers/Grid';
import RouteInfo from 'components/UI/RouteDetail/RouteInfo';
import { getDataPage, getRouteTitle } from 'libs/getTitleAndTextFromPageHeader';
import Maps from 'components/UI/Maps';
import RouteOptions from 'components/Profile/MyRouteDetail/RouteOptions';
import ProposalRouteForm from 'components/ReduxForms/ProposalRouteForm';
import { RouteDelete } from 'components/Profile/MyRouteDetail/DeleteRoute';
import RouteDesired from 'components/UI/RouteDetail/RouteDesired';
import ProposalList from 'components/UI/_V2/Proposals/List';
import ProposalDialog from 'components/UI/_V2/Proposals/Dialog';
import { offersActions } from 'actions/offers';
import { required } from 'libs/validationsForms';
import showError from 'libs/showFormErrors';


class MyRouteDetail extends React.PureComponent {
    
    state = {
        dialogDelete: false,
        dialogProposal: false,
    };
    
    componentWillMount() {
        this.props.dispatch(driverActions.getRouteByID(this.props.match.params.id));
    }
    
    componentWillReceiveProps(nextProps, nextContext) {
        let { route } = nextProps;
        if (route !== undefined && nextProps.profile !== null)
            if (route.proposalsNewCount > 0 && nextProps.profile.role === 'driver')
                route.proposals.forEach(el => {
                    this.props.dispatch(offersActions.readProposal(el.id));
                });
    }
    
    toggleDialog = (state, val = false) => this.setState({ [state]: val });

    closeProposal = () => this.setState({ dialogProposal: false });
    
    handleEdit = () => {
        sessionStorage.setItem('addRoute', JSON.stringify(routeServerToClient(this.props.route)));
        forwardTo('/edit/route/');
    };
    
    handleDelete = () => {
        this.toggleDialog('dialogDelete', false);
        this.props.dispatch(driverActions.rmRoute({ id: this.props.match.params.id, route: true}));
    };
    
    handleFinish = () => {
        this.props.dispatch(driverActions.finishRoute({ id: this.props.match.params.id, route: true}));
    };
    
    handleExtend = () => {
        this.props.dispatch(driverActions.extendRoute({ id: this.props.match.params.id, route: true }));
    };
    
    sendProposal = values => {
        showError({
            'offerId': [required(values.offerId)],
            'message': [required(values.message)],
        });
        let value = { ...values };
        value.routeId = parseInt(this.props.match.params.id);
        value.user2Id = this.props.route.user.id;
        this.props.dispatch(customerActions.addProposalToRoute(value));
        this.closeProposal();
    };

    render() {
        const { route, match, dispatch } = this.props;

        if (route === undefined)
            return <Page title={'Маршрут №' + match.params.id}><Loader/></Page>;

        return (
            <Page
                title={getRouteTitle(route)}
                text={getDataPage(match.params.id, route.createTime, 'route')}
                history
            >
                <Grid col={'9-3'}>
                    <div>
                        <Panel title={'Транспорт'} marginBottom={30}>
                            <Car
                                {...route.transport}
                                justShow
                                className={style.custom_transport_view}
                            />
                        </Panel>
                        {
                            route.type === 'planned' &&
                            <Fragment>
                                <RouteInfo point={'A'} route={route.items[0]} />
                                <RouteInfo cities={route.items} />
                                <RouteInfo point={'B'} route={route.items[route.items.length - 1]} />
                            </Fragment>
                        }
                        {
                            route.type === 'desired' &&
                            <Fragment>
                                <RouteDesired route={route} />
                            </Fragment>
                        }
                        
                        <ProposalList
                            { ...route }
                            type={'route'}
                            openProposalDialog={() => this.toggleDialog('dialogProposal', true)}
                        />

                    </div>
                    <div>
                        <RouteOptions
                            dispatch={dispatch}
                            route={route}
                            role={this.props.profile.role}
                            actionEdit={this.handleEdit}
                            actionFinish={this.handleFinish}
                            actionDelete={() => this.toggleDialog('dialogDelete', true)}
                            actionExtend={this.handleExtend}
                        />
                        <Maps/>
                    </div>
                </Grid>
                {
                    this.state.dialogDelete &&
                    <RouteDelete
                        acceptAction={this.handleDelete}
                        cancelAction={() => this.toggleDialog('dialogDelete')}
                    />
                }

                {
                    this.state.dialogProposal &&
                    <ProposalDialog close={this.closeProposal}>
                        <ProposalRouteForm
                            onSubmit={this.sendProposal}
                        />
                    </ProposalDialog>
                }

            </Page>
        );
    }
}


export default connect(
    state => ({
        profile: getProfile(state),
        route: getDetailRoute(state)
    })
)(MyRouteDetail);