import React from 'react';
import Select from 'components/UI/Form/Fields/Select';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';

const MarkCar = ({marks, onChange}) => {
	return (
		<Field
			disabled={marks.length === 0}
			onChange={(e, val)=>onChange('mark', val)}
			name="carMarkId"
			component={Select}
			filter
			label="Марка транспорта"
		>
			{marks.map(it => <OptionSelect key={it.id} value={it.id} filterStr={it.title}>{it.title}</OptionSelect>)}
		</Field>
	);
};

MarkCar.propTypes = {
	marks: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default MarkCar;