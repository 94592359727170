import * as profileDetailActions from 'actions/profileDetail';
import { driverActions } from 'actions/driver';

export const getProfileData = (dispatch, qs, success) => {
	
	return new Promise((resolve, reject) => {
		dispatch(profileDetailActions.getProfileByID(qs, { resolve, reject }));
	}).then(() => {
		success();
	}).catch(() => {
		success();
	});
	
};

export const getReviews = (dispatch, qs, success) => {
	
	return new Promise((resolve, reject) => {
		dispatch(profileDetailActions.getReviewsByID(qs, { resolve, reject }));
	}).then(() => {
		success();
	}).catch(() => {
		success();
	});
	
};

export const getRoutes = (dispatch, qs, success) => {
	
	return new Promise((resolve, reject) => {
		dispatch(driverActions.getRoutesByUserID(qs, { resolve, reject }));
	}).then(() => {
		success();
	}).catch(() => {
		success();
	});
	
};