import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import style from 'components/Admin/UI/Forms/Input/style.less';


export default class AdminInput extends PureComponent {
	
	static propTypes = {
		input: PropTypes.object,
		meta: PropTypes.object,
		type: PropTypes.string,
		label: PropTypes.string,
		hint: PropTypes.string,
		unit: PropTypes.any,
		onInput: PropTypes.func,
		placeholder: PropTypes.string,
		disabled: PropTypes.bool,
		showError: PropTypes.bool
	};
	
	static defaultProps = {
		input: {},
		meta: {},
		type: 'text',
		label: '',
		placeholder: '',
		disabled: false,
		showError: true
	};
	
	render() {
		
		const { input, type, placeholder, disabled } = this.props;
		
		return (
			<input
				{...input}
				type={type}
				disabled={disabled}
				placeholder={placeholder}
				className={style.input}
			/>
		);
	}
}