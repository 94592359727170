import { createActions } from 'redux-actions';

export const {
	serverHelpersActions: {
		getCargoList,
		setCargoList,
		getPackageList,
		setPackageList,
		getBodyTypeList,
		setBodyTypeList,
		getLoadTypeList,
		setLoadTypeList,
		getAdditionalList,
		setAdditionalList,
		getCounterList,
		setCounterList,
	}
} = createActions({
	serverHelpersActions: {
		getCargoList: [() => null, (payload, meta) => meta],
		setCargoList: [payload => payload, (payload, meta) => meta],
		getPackageList: [() => null, (payload, meta) => meta],
		setPackageList: [payload => payload, (payload, meta) => meta],
		getBodyTypeList: [() => null, (payload, meta) => meta],
		setBodyTypeList: [payload => payload, (payload, meta) => meta],
		getLoadTypeList: [() => null, (payload, meta) => meta],
		setLoadTypeList: [payload => payload, (payload, meta) => meta],
		getAdditionalList: [() => null, (payload, meta) => meta],
		setAdditionalList: [payload => payload, (payload, meta) => meta],
		getCounterList: [() => null, (payload, meta) => meta],
		setCounterList: [payload => payload, (payload, meta) => meta],
	}
});
