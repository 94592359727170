import React from 'react';
import PropTypes from 'prop-types';
import RequestGridContainer from 'components/UI/Wrappers/RequestGridContainer';
import RequestRouteData from 'components/UI/Request/RequestRouteData';
import RequestCostumer from 'components/UI/Request/RequestCustomer';
import { getMyRoutes } from 'libs/selections';
import { connect } from 'react-redux';
import Pagination from "components/UI/Pagination/index";
import Loader from 'components/UI/_V2/Loader';

class RouteList extends React.Component {
	
	static propTypes = {
		type: PropTypes.string.isRequired,
      activePage: PropTypes.number,
      changePage: PropTypes.func
  };

    static defaultProps = {
        activePage: 1
    };
	
	render() {
		const { routes, type, changePage, activePage, count, loading } = this.props,
			list = routes[type] || [];
		
		if (list.total === 0 && !loading) {
			if (type === 'active')
				return <div>Активных маршрутов нет. Вы можете создать новый маршрут.</div>;
			if (type === 'cancelled')
				return <div>Отклоненных маршрутов нет.</div>;
			return <div>Вы не завершили ни один свой маршрут.</div>;
		}
		
		return (
			<React.Fragment>
				{
					loading && <Loader />
				}
				
				{
					!loading &&
					list.items.map(el => (
						<RequestGridContainer key={el.id}>
							<RequestRouteData
								offer={el}
								createTime={el.createTime}
								offerID={el.id}
								route={el.items}
								type={el.type}
								transport={el.transport}
								url={"/route/" + el.id}
								isRoute
							/>
							<RequestCostumer
								offer={el}
								offerID={el.id}
								proposalsNewCount={el.proposalsNewCount ? el.proposalsNewCount : 0}
								proposalsTotalCount={el.proposalsTotalCount ? el.proposalsTotalCount : 0}
								status={el.status || 'empty'}
								price={el.price || 0}
								isRoute
							/>
						</RequestGridContainer>
					))
				}


				{
					Math.ceil(count / 30) > 1 &&
					<Pagination
							showMore={false}
							pageCount={Math.ceil(list.total / 30)}
							setPage={(page) => changePage(page, type)}
							activePage={parseInt(activePage)}
					/>
				}
				
			</React.Fragment>
		)
	}
}

export default connect(
	state => ({
		routes: getMyRoutes(state)
	})
)(RouteList);