exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n._2di88J8AL60ASKfLg8uhrW {\n  border: 1px solid #d5deef;\n  border-radius: 4px;\n}\n.U_Wal_M0ADnJO1diC5Wjd {\n  padding: 25px 30px;\n  font-size: 17px;\n}\n.U_Wal_M0ADnJO1diC5Wjd p {\n  margin: 0;\n}\n.U_Wal_M0ADnJO1diC5Wjd ._2EKqMCgRCT3kzUrVRgM881 {\n  margin-top: 20px;\n}\n.U_Wal_M0ADnJO1diC5Wjd ._2nDHCVDOPmTaWbllhNEion {\n  font-weight: 700;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/UI/Maps/style.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,0BAA0B;EAC1B,mBAAmB;CACpB;AACD;EACE,mBAAmB;EACnB,gBAAgB;CACjB;AACD;EACE,UAAU;CACX;AACD;EACE,iBAAiB;CAClB;AACD;EACE,iBAAiB;CAClB","file":"style.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.mapContainer {\n  border: 1px solid #d5deef;\n  border-radius: 4px;\n}\n.mapContent {\n  padding: 25px 30px;\n  font-size: 17px;\n}\n.mapContent p {\n  margin: 0;\n}\n.mapContent .margin10 {\n  margin-top: 20px;\n}\n.mapContent .bold {\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mapContainer": "_2di88J8AL60ASKfLg8uhrW",
	"mapContent": "U_Wal_M0ADnJO1diC5Wjd",
	"margin10": "_2EKqMCgRCT3kzUrVRgM881",
	"bold": "_2nDHCVDOPmTaWbllhNEion"
};