import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getProfileType, getSearchResult } from 'libs/selections';
import Page from 'components/UI/Wrappers/Page';
import { getSearchData } from 'components/Search/getData';
import { getItem, setItem } from 'libs/sessionStorage';
import Pagination from 'components/UI/Pagination';
import styles from 'components/Search/style.less';
import SearchDrivers from 'components/ReduxForms/Search/SearchDrivers';
import SearchCargoes from 'components/ReduxForms/Search/SearchCargoes';
import SearchHeader from 'components/Search/SearchHeader';
import Loader from 'components/UI/Loader';
import SearchResultItem from 'components/Search/SearchResultItem';

class Search extends Component {

	state = {
		loading: true,
		params: getItem('searchParams') ? JSON.parse(getItem('searchParams')) : {
			page: 1,
			pageSize: 10,
			sort: ['price'],
		},
	};

	async componentWillMount() {
		await this.loadResults();
	}
	
	loadResults = async (isAppend = false) => {
		this.setState({ loading: true });

		await getSearchData(this.props.dispatch, this.state.params, isAppend, () => {
			setItem('searchParams', JSON.stringify(this.state.params));
			this.setState({ loading: false });
		});
	};
	
	setPage = async page => {
		this.setState({ params: { ...this.state.params, page } }, async () => {
			await this.loadResults();
		});
	};
	
	appendResults = async page => {
		this.setState({ params: { ...this.state.params, page } }, async () => {
			await this.loadResults(true);
		});
	};
	
	handleSubmit = form => {
		this.setState({ params: { ...this.state.params, ...form, page: 1 }, loading: true },
			async () => {
			await this.loadResults();
		});
	};
	
	reset = () => {
		this.setState({ params: { page: 1, pageSize: 10, sort: 'price' }, loading: true }, async () => {
			await this.loadResults();
		});
	};
	
	handleSort = sort => {
		this.setState({ params: { ...this.state.params, page: 1, sort: [sort], } }, async () => {
			await this.loadResults();
		});
	};
	
	render() {
		
		const { total, items } = this.props.search;
		const { loading, params } = this.state;
		
		let pageCount = 1;
		if (total > 0) pageCount = Math.ceil(total / params.pageSize);

		return (
			<Page
				title={this.props.role === 'driver' ? 'Поиск груза' : 'Поиск перевозчика'}
				history
			>
				{
					this.props.role === 'driver' ?
					<SearchCargoes
						onSubmit={this.handleSubmit}
						resetData={this.reset}
						initialValues={params}
						enableReinitialize
					/> :
					<SearchDrivers
						onSubmit={this.handleSubmit}
						resetData={this.reset}
						initialValues={params}
						enableReinitialize
					/>
				}
				
				<SearchHeader
					role={this.props.role}
					loading={loading}
					count={total}
					sortVal={params.sort[0]}
					changeSort={val => this.handleSort(val)}
				/>
				
				<div
					className={`${styles.result_body} ${pageCount < 2 && styles.fixBottom}`}
					id="results"
				>
					
					<Loader
						className={`${styles.loader} ${loading && styles.active}`}
						justIcon
					/>
					
					{
						!loading && total === 0 &&
						<div className={styles.empty}>Ничего не найдено</div>
					}
					
					{
						total > 0 &&
						items.map(el =>
							<SearchResultItem
								key={el.id}
								role={this.props.role}
								data={el}
								updateData={this.updateData}
							/>
						)
					}
					
				</div>
				{
					pageCount > 1 &&
					<Pagination
						className={styles.pagination}
						setPage={e => this.setPage(e)}
						setAjaxPage={e => this.appendResults(e)}
						pageCount={pageCount}
						activePage={this.state.params.page}
					/>
				}
			</Page>
		)
	}
}

export default connect(
	store => ({
		role: getProfileType(store),
		search: getSearchResult(store),
	})
)(Search);


