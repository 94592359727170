import React from 'react';
import style from 'components/Admin/UI/UpdateButton/style.less';
import { Refresh } from 'components/UI/Icons';
import Button from 'components/UI/Form/Button';


const UpdateButton = ({ onClick }) => (
	<Button
		clearStyle
		title="Нажмите, чтобы обновить данные"
		onClick={onClick}
	>
		{ Refresh(style.refresh) }
	</Button>
);


export default UpdateButton;