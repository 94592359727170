exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "@media screen and (max-width: 1200px) {\n  ._5EZ8U2QH5rVFb95xT7i4E {\n    margin-top: -45px;\n    margin-bottom: -45px;\n  }\n}\n._1rhWvYIn-_pSgzK5dIrP8- {\n  width: 270px;\n  height: 349px;\n}\n._1rhWvYIn-_pSgzK5dIrP8- b {\n  font-size: 14px;\n}\n._1rhWvYIn-_pSgzK5dIrP8- p {\n  font-size: 13px;\n  margin-top: 0;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/ReduxForms/UploadAvatarForm/style.less"],"names":[],"mappings":"AAAA;EACE;IACE,kBAAkB;IAClB,qBAAqB;GACtB;CACF;AACD;EACE,aAAa;EACb,cAAc;CACf;AACD;EACE,gBAAgB;CACjB;AACD;EACE,gBAAgB;EAChB,cAAc;CACf","file":"style.less","sourcesContent":["@media screen and (max-width: 1200px) {\n  .mobileLoadPhoto {\n    margin-top: -45px;\n    margin-bottom: -45px;\n  }\n}\n.imageAvatar {\n  width: 270px;\n  height: 349px;\n}\n.imageAvatar b {\n  font-size: 14px;\n}\n.imageAvatar p {\n  font-size: 13px;\n  margin-top: 0;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mobileLoadPhoto": "_5EZ8U2QH5rVFb95xT7i4E",
	"imageAvatar": "_1rhWvYIn-_pSgzK5dIrP8-"
};