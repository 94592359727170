exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".kKpIeg9HWiJ4VbNJhnSKQ {\n  justify-content: center;\n}\n._1CGBiDSV9HfNP-r7b5GL5c {\n  display: flex;\n  margin-top: 20px;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/Admin/Pages/Routes/Detail/style.less"],"names":[],"mappings":"AAAA;EACE,wBAAwB;CACzB;AACD;EACE,cAAc;EACd,iBAAiB;CAClB","file":"style.less","sourcesContent":[".center {\n  justify-content: center;\n}\n.button_group {\n  display: flex;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"center": "kKpIeg9HWiJ4VbNJhnSKQ",
	"button_group": "_1CGBiDSV9HfNP-r7b5GL5c"
};