exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n._14PBqYXMgmIWmgBnL9Be8w {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #353c4b;\n  width: 115px;\n  font-size: 13px;\n  height: 27px;\n  border-radius: 14px;\n  background: #e8eef4;\n  position: relative;\n  overflow: hidden;\n  z-index: 1;\n}\n._14PBqYXMgmIWmgBnL9Be8w ._90MKvEDTORdQDDAO2AtdZ {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  background: #9ce794;\n}\n._14PBqYXMgmIWmgBnL9Be8w ._37E0KzIPpyVujTVSMo9xqd {\n  position: relative;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/UI/ScaleFill/style.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,cAAc;EACd,oBAAoB;EACpB,wBAAwB;EACxB,eAAe;EACf,aAAa;EACb,gBAAgB;EAChB,aAAa;EACb,oBAAoB;EACpB,oBAAoB;EACpB,mBAAmB;EACnB,iBAAiB;EACjB,WAAW;CACZ;AACD;EACE,mBAAmB;EACnB,OAAO;EACP,UAAU;EACV,QAAQ;EACR,oBAAoB;CACrB;AACD;EACE,mBAAmB;CACpB","file":"style.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.progress_bar {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #353c4b;\n  width: 115px;\n  font-size: 13px;\n  height: 27px;\n  border-radius: 14px;\n  background: #e8eef4;\n  position: relative;\n  overflow: hidden;\n  z-index: 1;\n}\n.progress_bar .scale {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  background: #9ce794;\n}\n.progress_bar .text {\n  position: relative;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"progress_bar": "_14PBqYXMgmIWmgBnL9Be8w",
	"scale": "_90MKvEDTORdQDDAO2AtdZ",
	"text": "_37E0KzIPpyVujTVSMo9xqd"
};