import AdminGrid from 'components/Admin/UI/Grid';
import { AdminLabel } from 'components/Admin/UI/Label';
import React from 'react';
import { convertNumberSpaces } from 'libs/convertNumberSpaces';
import { dateToString, stringToDate } from 'libs/convertDate';
import style from 'components/Admin/Pages/Routes/Detail/style.less';
import Status from 'components/UI/Status';

const GeneralData = props => {
    
    
    return (
        <>
        <h3> Общее </h3>
        <AdminGrid type="passport">
            <div>
                <AdminLabel title={'Цена перевозки'}>
                    { convertNumberSpaces(parseInt(props.price)) } р.
                </AdminLabel>
                <AdminLabel title={'Дата создания'}>
                    { dateToString(stringToDate(props.createTime), '') }
                </AdminLabel>
                <AdminLabel title={'Статус заявки'}>
                    <Status status={props.status} className={style.center} />
                </AdminLabel>
            </div>
        </AdminGrid>
        </>
    )
};


export default GeneralData;