import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';

const LayoutField = ({ children, className }) => (
	<div className={`${style['layout-field']} ${className ? className : ''}`}>{ children }</div>
);

LayoutField.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};
LayoutField.defaultProps = {
	children: {},
	className: '',
};

export default LayoutField;
