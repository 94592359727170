import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import OptionSelectAction from 'components/UI/Form/Fields/OptionSelectAction';
import React from 'react';

const getCardOptions = (cards, addCard) => {
	let cardList = [];
	
	if (cards.items !== undefined && cards.total !== undefined && cards.total > 0)
		cardList = cards.items.map(card => (
			<OptionSelect key={card.id} value={card.id}>
				{ `**** **** **** ${card.cardNumber.slice(-4)}` } <b>{ card.title }</b>
			</OptionSelect>
		));
	
	cardList.push(<OptionSelectAction onClick={addCard} key={-1}>+ Добавить карту</OptionSelectAction>);
	
	return cardList;
};

export default getCardOptions;