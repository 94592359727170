import React from 'react';
import PropTypes from 'prop-types';
import { User } from 'components/UI/Icons';
import style from './style.less';


class Avatar extends React.Component {

	static propTypes = {
		src: PropTypes.any,
		sizeXS: PropTypes.oneOfType([PropTypes.number, PropTypes.object]), // < 575px
		sizeSM: PropTypes.oneOfType([PropTypes.number, PropTypes.object]), // < 768px
		sizeMD: PropTypes.oneOfType([PropTypes.number, PropTypes.object]), // < 992px
		size: PropTypes.number,   // > 992px
		darkBG: PropTypes.bool,
		isCircle: PropTypes.bool,
		className: PropTypes.string,
	};

	static defaultProps = {
		src: null,
		sizeXS: null,
		sizeSM: null,
		sizeMD: null,
		size: 60,
		darkBG: false,
		isCircle: true,
		className: '',
	};

	state = {
		size: this.props.size,
	};


	render () {
		let { src, darkBG, isCircle, className } = this.props,
			{ size } = this.state,
			url = '',
			renderClass = style.avatar;

		const isEmpty = src === null;
		const property = {
			maxWidth: size,
			width: size,
			height: isCircle ? size : size * 1.24,
		};

		if (!isEmpty) {
			if (typeof src === 'object') url = src.images[0].url;
			if (typeof src === 'string') url = src;
		}

		if (className) renderClass += ` ${className}`;
		if (isCircle) renderClass += ` ${style.circle}`;
		if (isEmpty) renderClass += ` ${style.no_photo}`;
		if (darkBG) renderClass += ` ${style.dark}`;

		return (
			<div className={renderClass} style={property}>
				{isEmpty && <div className={style.container} style={property}>{User()}</div>}
				{!isEmpty && <img src={url} style={property} alt=""/>}
			</div>
		);
	}
}

export default Avatar;