import classesEmpty from 'components/UI/AdaptiveStyle/style.less';
import Pagination from "components/UI/Pagination/index";
import PreviewTrade from 'components/UI/Previews/PreviewTrade';
import RequestContract from 'components/UI/Request/RequestContract';
import RequestGridContainer from 'components/UI/Wrappers/RequestGridContainer';
import { getOfferList } from 'libs/selections';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';


class TradeList extends React.PureComponent {

    static propTypes = {
        type: PropTypes.string.isRequired,
        activePage: PropTypes.number,
        changePage: PropTypes.func
    };

    static defaultProps = {
        activePage: 1
    };

    render() {
        const { type, activePage, changePage } = this.props;

        let trades = this.props.trades[type] || [];

        if (trades.total === 0) {
            if (type === 'active')
                return <div className={classesEmpty.emptyText}>Активных сделок нет</div>;
            if (type === 'arbitrate')
                return <div className={classesEmpty.emptyText}>Сделки, требующие решения вопросов, отсутствуют</div>;
            return <div className={classesEmpty.emptyText}>Вы не завершили ни одной сделки, они появятся после успешного
                выполнения одного вашего заказа.</div>;
        }

        return (
            <React.Fragment>
                {
                    trades.items.map(el => (
                        <RequestGridContainer key={el.tradeID}>
                            <PreviewTrade trade={el}/>
                            <RequestContract
                                price={el.price}
                                offerID={el.id}
                                contract={el.offer.proposals}
                                createTime={el.startDate}
                                status={el.status || 'empty'}
                            />
                        </RequestGridContainer>
                    ))
                }

                <Pagination
                    showMore={false}
                    pageCount={Math.ceil(trades.total / 30)}
                    setPage={() => changePage(type)}
                    activePage={parseInt(activePage)}
                />
            </React.Fragment>
        );
    }
}


export default connect(store => ({
    trades: getOfferList(store)
}))(TradeList);
