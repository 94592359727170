import style from 'components/Chat/style.less';
import Button from 'components/UI/Form/Button';
import { Arbitrate } from 'components/UI/Icons';
import AvatarOnlineContainer from 'components/UI/Wrappers/AvatarOnlineContainer';
import { dateToString } from 'libs/convertDate';
import { getMessageDateString } from 'libs/getMessageTime';
import shortName from 'libs/shortName';
import React from 'react';


const Companion = props => {

    if (props.dialog === undefined) return null;

    const { dialog, profileID, selectDialog, selected } = props;

    let renderClassName = style.chat__message,
        you = '',
        user = {},
        newMessageCount = 0,
        isActive = false;

    if (selected !== undefined && dialog.id === selected.id) isActive = true;
    if (isActive) renderClassName += ' ' + style.open;

    if (dialog.user1.id !== profileID) {
        user = dialog.user1;
        newMessageCount = dialog.user2UnreadCount;
    } else {
        user = dialog.user2;
        newMessageCount = dialog.user1UnreadCount;
    }

    if (dialog.lastMessage && dialog.lastMessage.senderId !== user.id) you = 'Вы: ';

    let avatarClass = !isActive ? style.notActive : '';
    return (
        <Button className={renderClassName} onClick={isActive ? () => {
        } : () => selectDialog(dialog)}>

            {
                !!dialog.arbitrationId ?
                    <div className={style.arbitrate}>
                        <Arbitrate/>
                    </div> :
                    <AvatarOnlineContainer
                        size={50}
                        src={user.photo}
                        status={user.status === 'online'}
                        className={avatarClass}
                        darkBG
                    />
            }

            <div className={style.message}>

                <h4 className={style.username}>
                    {
                        !!dialog.arbitrationId ?
                            `Арбитраж №${dialog.arbitrationId}` :
                            shortName(user.firstName, user.lastName)
                    }
                </h4>
                {
                    dialog.lastMessage &&
                    <>
                    <span className={style.time}>
                            {getMessageDateString(dialog.lastMessage.date || dateToString(new Date(), 'serverDateFullTime'))}
                        </span>
                    <p className={style.text}>
                        {you}{dialog.lastMessage.text ? dialog.lastMessage.text : '[ссылка на заявку]'}
                    </p>
                    </>
                }


                {
                    newMessageCount > 0 &&
                    <span className={style.newCount}>
                        +{newMessageCount}
                    </span>
                }

            </div>
        </Button>
    )

};


export default Companion;