import React from 'react';
import PropTypes from 'prop-types';
import style from 'components/Admin/UI/Loader/style.less';


const AdminLoader = props => (
	<div className={`${style.loader_container} ${props.className && props.className}`}>
		<div className={style.row_1}>
			<div className={`${style.placeholder} ${style.placeholder_1}`}>
				<div className={style.animated} />
			</div>

			<div className={`${style.placeholder} ${style.placeholder_2}`}>
				<div className={style.animated} />
			</div>

			<div className={`${style.placeholder} ${style.placeholder_3}`}>
				<div className={style.animated} />
			</div>
		</div>

		<div className={style.row_2}>
			<div className={`${style.placeholder} ${style.placeholder_1}`}>
				<div className={style.animated} />
			</div>

			<div className={`${style.placeholder} ${style.placeholder_2}`}>
				<div className={style.animated} />
			</div>

			<div className={`${style.placeholder} ${style.placeholder_3}`}>
				<div className={style.animated} />
			</div>
		</div>
	</div>
);


AdminLoader.propTypes = {
	className: PropTypes.string,
};

AdminLoader.defaultProps = {
	className: '',
};

export default AdminLoader;