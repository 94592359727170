import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';

const Button = ({ children, size, color, disabled, type, isActive, loading, onClick, className, title, clearStyle }) => {
	let renderClass = `${style.btn} ${style[`btn-${size}`]} ${style[`btn-${color}`]}`;
	if (isActive) renderClass += ` ${style.active}`;
	if (loading) renderClass += ` ${style.loading}`;
	if (clearStyle) renderClass += ` ${style.clear}`;
	if (className !== null) renderClass += ` ${className}`;

	return (
		<button
			title={title}
			onClick={onClick}
			className={renderClass}
			disabled={disabled || loading}
			type={type}
		>
			{ !loading && children }
			{
				loading &&
				<React.Fragment><div className={style.loaderBig} /> Загрузка</React.Fragment>
			}
		</button>
	);
};

Button.propTypes = {
	children: PropTypes.node,
	size: PropTypes.string,
	color: PropTypes.string,
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	disabled: PropTypes.bool,
	type: PropTypes.string,
	isActive: PropTypes.bool,
	loading: PropTypes.bool,
	onClick: PropTypes.func,
	clearStyle: PropTypes.bool,
};
Button.defaultProps = {
	children: {},
	size: 'middle',
	color: 'blue',
	title: '',
	className: null,
	disabled: false,
	type: 'button',
	isActive: false,
	loading: false,
	onClick: () => {},
	clearStyle: false,
};

export default Button;
