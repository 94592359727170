exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".SntHN7SnHtTNis3cjmp2v {\n  margin-top: 20px;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/UI/NotVerifyAccountMsg/style.less"],"names":[],"mappings":"AAAA;EACE,iBAAiB;CAClB","file":"style.less","sourcesContent":[".indentTop {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"indentTop": "SntHN7SnHtTNis3cjmp2v"
};