import React from 'react';
import Panel from 'components/UI/Wrappers/Panel';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import Address2 from 'components/UI/Form/Fields/Address2';
import Input from 'components/UI/Form/Fields/Input';
import InputDate from 'components/UI/Form/Fields/InputDate';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import InputSelectGroup from 'components/UI/Wrappers/InputSelectGroup';
import Grid from 'components/UI/Wrappers/Grid';
import PointB from 'components/UI/Point';

const Point = props => {
	const {name} = props;
	return (
		<Panel title="Информация о разгрузке" marginBottom={30}>
			<LayoutField>
				<span>Укажите адрес используя поиск по буквам</span>
				<InputSelectGroup>
					<PointB point="B" absolute />
					<Address2
						renderToMap="B"
						name={`${name}.point`}
						dispatch={props.dispatch}
						containerGroup="right-input"
						label="Город, населёный пункт, улица, дом"
						setValue={props.setValue}
					/>
					<Field
						placeholder="Радиус"
						type="number"
						unit="км"
						name={`${name}.radius`}
						childGroup="right"
						component={Input}
					/>
				</InputSelectGroup>
			</LayoutField>
			<Grid col="form-2">
				<Field
					name={`${name}.startDate`}
					component={InputDate}
					label="Дата окончания маршрута"
				/>
			</Grid>
		</Panel>
	);
};

Point.propTypes = {
	name: PropTypes.string.isRequired,
	dispatch: PropTypes.func.isRequired,
	setValue: PropTypes.func.isRequired,
};

export default Point;