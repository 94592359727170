import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/UI/Form/Fields/Input';
import { change, Field } from 'redux-form';
import {phone} from 'libs/validationsForms';
import Select from 'components/UI/Form/Fields/Select';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import InputSelectGroup from 'components/UI/Wrappers/InputSelectGroup';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import {testGuestPhone} from 'actions/user';
import {LoginReduxFormFields} from 'components/ReduxForms/LoginForm'
import {RegistrationReduxFormFields} from 'components/ReduxForms/RegistrationForm'
import Grid from 'components/UI/Wrappers/Grid';

export default class GuestPhone extends React.PureComponent {

	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		setValue: PropTypes.func.isRequired,
		setSubmitType: PropTypes.func.isRequired,
	};

	state = {
		loading: false,
		guest_code: '',
		guest_number: '',
		formType: '',
	};

	handleChangePhone = (event, val) => {
		if(
			event.target === undefined ||
			(event.target.name === 'guest_number' && phone(val) === undefined)
		){
			this.setState({[event.target === undefined ? 'guest_code' : 'guest_number']: val});
		}
	};

	testPhone = () => {
		let phone = this.state.guest_code + this.state.guest_number.replace(/\s/g, '');

		return new Promise((resolve, reject) => {
			this.props.dispatch(testGuestPhone({phone}, {
				resolve: resolve,
				reject: reject,
			}));
		}).then(() =>{
			this.setState({formType: 'SingIn'}, this.props.setSubmitType('SingIn'));
		}).catch(() => {
			this.setState({formType: 'SingUp'}, this.props.setSubmitType('SingUp'));
		});
	};

	setDefaultValue = () => {
		const {setValue, dispatch} = this.props;
		dispatch(setValue('addCargo', 'phone_number', this.state.guest_number));
		dispatch(setValue('addCargo', 'code', this.state.guest_code));
	};
	
	onChange = token => {
		this.props.dispatch(change('addCargo', 'recaptchaValue', token));
	};

	render() {
		switch (this.state.formType) {
			case 'SingIn':
				this.setDefaultValue();
				return <LoginReduxFormFields />;
			case 'SingUp':
				this.setDefaultValue();
				return <RegistrationReduxFormFields onChange={this.onChange} />;
			default:
				let {guest_number, guest_code, loading} = this.state;

				if(guest_number && guest_code && !loading){
					this.setState({loading: true});
				}

				if(loading){
					this.testPhone();
					return <div>Подождите...</div>;
				}

				return (
					<Grid col="form-2">
						<LayoutField>
							<InputSelectGroup>
								<Field
									name="guest_number"
									onBlur={this.handleChangePhone}
									type="text"
									component={Input}
									validate={phone}
									label="Телефон"
									mask="9999 99 99 99"
									containerGroup="left"
								/>
								<Field
									onChange={this.handleChangePhone}
									name="guest_code"
									component={Select}
									childGroup="left"
								>
									<OptionSelect value="7">+7</OptionSelect>
									<OptionSelect value="375">+375</OptionSelect>
									<OptionSelect value="98">+98</OptionSelect>
								</Field>
							</InputSelectGroup>
						</LayoutField>
					</Grid>
				);
		}
	}
}