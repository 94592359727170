import React from 'react';
import PropTypes from 'prop-types';
import Upload from 'components/Pages/Trades/Detail/TradeSteps/Upload';
import Route from 'components/Pages/Trades/Detail/TradeSteps/Route';
import Unload from 'components/Pages/Trades/Detail/TradeSteps/Unload';
import styles from 'components/Pages/Trades/Detail/styles.less';

export default class TradeSteps extends React.PureComponent {

	static propTypes = {
		status: PropTypes.string.isRequired,
		role: PropTypes.string.isRequired,
		dispatch: PropTypes.func.isRequired,
		tradeID: PropTypes.number.isRequired,
	};

	render() {
		let { status, role, dispatch, tradeID } = this.props;

		if (tradeID === 0) return null;

		const stepOne = ['accepted', 'cargo_loaded'];
		const stepTwo = ['money_reserved', 'started_route'];
		const stepThree = ['cargo_unloaded', 'arbitration'];

		return (
			<div className={styles.offer_steps}>
				<h2>Этапы сделки</h2>
				<Upload
					status={status}
					tradeID={tradeID}
					dispatch={dispatch}
					role={role}
					isActive={stepOne.indexOf(status) !== -1}
					toggleDialog={this.props.toggleDialog}
				/>
				<Route
					dispatch={dispatch}
					tradeID={tradeID}
					status={status}
					role={role}
					isActive={stepTwo.indexOf(status) !== -1}
				/>
				<Unload
					tradeID={tradeID}
					dispatch={dispatch}
					status={status}
					role={role}
					isActive={stepThree.indexOf(status) !== -1}
				/>
			</div>
		);
	}
}