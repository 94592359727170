
export const showSendButton = (role, type, profile, proposals, status) => {
	
	let res = 1; // показать кнопку
	
	if ((role === 'customer' && type === 'offer') || (role === 'driver' && type === 'route'))
		res = 0; // убрать кнопку
	
	if (role === 'driver' && type === 'offer' && profile.isProfileVerification === 0)
		res = 2; // показать сообщение для водителя
	
	proposals.forEach(el => {
		if (el.user1.id === profile.id) res = 0;
	});
	
	if (status !== 'published') res = 0;
	
	return res;
};


export const showCustomerActions = (profile, status, userId) => {
	return userId !== 0 && profile.id === userId && status === 'published';
};


export const showProfileLinks = (role, type) => {
	return (role === 'customer' && type === 'offer') || (role === 'driver' && type === 'route');
};


export const showOffer = (type, proposal, profile, userId) => {
	return (profile.id === userId || proposal.user1.id === profile.id) && type === 'route';
};

// let isActions = profile.id === creatorId && type === 'offer' && status === 'published';
// let isProfileLink = (role === 'driver' && type === 'route') || (role === 'customer' && type === 'offer');