exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1fLDvEA5_wdinCYS5Iy2PH {\n  margin-top: 40px;\n}\n.FRly4LKIkRGaheVb3O6Xy {\n  margin-top: -5px;\n  display: flex;\n  justify-content: space-between;\n}\n@media screen and (max-width: 575px) {\n  .FRly4LKIkRGaheVb3O6Xy {\n    margin-top: 10px;\n    flex-direction: column;\n    align-items: flex-start;\n  }\n  .FRly4LKIkRGaheVb3O6Xy ._1dguZBQ1rOcAMrD4_8ZEKK {\n    margin-bottom: 10px;\n  }\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/ReduxForms/ArbitrationForm/style.less"],"names":[],"mappings":"AAAA;EACE,iBAAiB;CAClB;AACD;EACE,iBAAiB;EACjB,cAAc;EACd,+BAA+B;CAChC;AACD;EACE;IACE,iBAAiB;IACjB,uBAAuB;IACvB,wBAAwB;GACzB;EACD;IACE,oBAAoB;GACrB;CACF","file":"style.less","sourcesContent":[".button {\n  margin-top: 40px;\n}\n.flexFilesButton {\n  margin-top: -5px;\n  display: flex;\n  justify-content: space-between;\n}\n@media screen and (max-width: 575px) {\n  .flexFilesButton {\n    margin-top: 10px;\n    flex-direction: column;\n    align-items: flex-start;\n  }\n  .flexFilesButton .uploadImageProfile {\n    margin-bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"button": "_1fLDvEA5_wdinCYS5Iy2PH",
	"flexFilesButton": "FRly4LKIkRGaheVb3O6Xy",
	"uploadImageProfile": "_1dguZBQ1rOcAMrD4_8ZEKK"
};