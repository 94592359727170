import React from 'react';
import style from 'components/UI/_V2/Proposals/Detail/style.less';
import PropTypes from 'prop-types';
import Rating from 'components/UI/ProfileUI/Rating';

const ProposalRating = ({ rating }) => {
	
	return (
		<div className={style.proposal__rating}>

			{
				rating === null && <div className={style.no__rating}>Отзывы отсутствуют</div>
			}

			{
				rating !== null && <Rating className={style.rating} count={rating} />
			}
			
		</div>
	);
};

ProposalRating.propTypes = {
	className: PropTypes.string,
	isShowLink: PropTypes.bool,
};

ProposalRating.defaultProps = {
	className: '',
	isShowLink: false,
};

export default ProposalRating;