import React from 'react';
import PropTypes from 'prop-types';
import { Check } from 'components/UI/Icons';
import style from './data.less';


const ChecklistItem = props => {
	const iconClass = props.status ? style.true_icon : style.false_icon;

	return (
		<div className={style.profile_check_checklist_item}>
			{ Check(iconClass) } { props.title }
		</div>
	)
};


ChecklistItem.propTypes = {
	title: PropTypes.string,
	status: PropTypes.any,
};
ChecklistItem.defaultProps = {
	title: '',
	status: false,
};

export default ChecklistItem;