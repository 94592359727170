import React from 'react';
import messageStyle from 'components/Chat/center/message.less';


const MessageType = ({ type }) => {
	
	if (type === '' || type === 'simple') return null;
	
	return (
		<div className={messageStyle.type}>
			{
				type === 'proposal' && 'Предложение по заявке'
			}
		</div>
	)
};


export default MessageType;