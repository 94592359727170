import React, { PureComponent } from 'react';
import messageStyle from 'components/Chat/center/message.less';
import Avatar from 'components/UI/ProfileUI/Avatar';
import { Link } from 'react-router-dom';
import shortName from 'libs/shortName';
import { getMessageTime } from 'libs/getMessageTime';
import { chatActions } from 'actions/chat';
import { connect } from 'react-redux';
import MessageOffers from 'components/Chat/center/MessageOffers';
import MessageType from 'components/Chat/center/MessageType';


class CompanionMessage extends PureComponent {
	
	componentDidMount() {
		const { messageData, dialogID } = this.props;

		if (!messageData.isRead) {
			this.props.dispatch(chatActions.markReadMsgToServer({
				dialogId: dialogID,
				msgId: messageData.id,
			}));
		}
	}
	
	render() {
		const { messageData, user, handleClose, isTime, isUser } = this.props;
		
		if (user === undefined) return null;
		
		const isAdmin = user.role === 'admin';
		
		let text = messageData.text,
			renderClassName = `${messageStyle.messageContainer} ${messageStyle.companion}`;
		if (typeof messageData.text === 'string')
			text = messageData.text.split('\n').map((i, k) => <div key={k}>{i}</div>);
		
		if (isAdmin) renderClassName += ` ${messageStyle.admin}`;
		// if (type === 'proposal') renderClassName += ` ${messageStyle.admin}`;
		
		
		return (
			<div className={renderClassName}>
				
				{
					!isAdmin && isUser
					&&
					<Avatar
						size={38}
						src={user.photo}
						className={messageStyle.avatar}
					/>
				}
				
				{
					isAdmin && isUser
					&&
					<Avatar
						size={38}
						className={messageStyle.avatar}
					/>
				}
				
				<div className={messageStyle.content}>
					
					<div className={messageStyle.info}>
						{
							isAdmin && isUser &&
							<span className={messageStyle.admin}>Администратор сети</span>
						}
						{
							!isAdmin && isUser &&
							<Link
								to={`/profile/${user.id}`}
								className={messageStyle.username}
								onClick={handleClose}
							>
								{ shortName(user.firstName, user.lastName) }
							</Link>
						}
						{' '}
						{
							isTime &&
							<span className={messageStyle.time}>
								{ getMessageTime(messageData.createTime) }
							</span>
						}
					</div>
					
					{
						text !== null &&
						<div className={messageStyle.text}>
							{ text }
							<MessageType type={messageData.type} />
						</div>
					}
					
					
					<MessageOffers handleClose={this.props.handleClose} offers={messageData.offers} />
				
				</div>
			</div>
		)
	}
}


export default connect()(CompanionMessage);