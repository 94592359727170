exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n._88u0zv7P9Ym262DNRaiRT {\n  display: inline-block;\n  border-radius: 20px;\n  padding: 5px 10px;\n  color: #353c4b;\n}\n._3qdmN8sMRSX7TEExMn4sYs {\n  background: #fff3bd;\n}\n._7lT8TDtkFx2tZkvxGZn0N {\n  background: #ffea8c;\n}\n.RkUr8rkHXLTQBZYiu91_X {\n  background: #f0f4f9;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/UI/Badge/style.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,sBAAsB;EACtB,oBAAoB;EACpB,kBAAkB;EAClB,eAAe;CAChB;AACD;EACE,oBAAoB;CACrB;AACD;EACE,oBAAoB;CACrB;AACD;EACE,oBAAoB;CACrB","file":"style.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.badge {\n  display: inline-block;\n  border-radius: 20px;\n  padding: 5px 10px;\n  color: #353c4b;\n}\n.yellow {\n  background: #fff3bd;\n}\n.yellowsaturated {\n  background: #ffea8c;\n}\n.gray {\n  background: #f0f4f9;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"badge": "_88u0zv7P9Ym262DNRaiRT",
	"yellow": "_3qdmN8sMRSX7TEExMn4sYs",
	"yellowsaturated": "_7lT8TDtkFx2tZkvxGZn0N",
	"gray": "RkUr8rkHXLTQBZYiu91_X"
};