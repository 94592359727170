import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';

const Container = ({ children, className }) => (
	<div className={`${style.container} ${className}`}>{ children }</div>
);

Container.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};
Container.defaultProps = {
	children: '',
	className: '',
};

export default Container;
