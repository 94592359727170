const getLocationByName = (name: string, success: ()=>{}) => {
    return fetch(`https://geocode-maps.yandex.ru/1.x/?format=json&geocode=${name}&apikey=b21467a4-95e1-4e3b-a5bf-3499feacda92`, {
        method: 'get'
    })//AGp79lwBAAAA0aW1FgIATc-ozMJJoYXQ5fCposGJdpVRLooAAAAAAAAAAACEhhnrwQsGT6e04t-63eB0CFNVtA==
        .then(
            (response) => response.json()
                .then(res => {
                    if (
                        res.response &&
                        res.response.GeoObjectCollection &&
                        Array.isArray(res.response.GeoObjectCollection.featureMember)
                    ) {
                        success(res.response.GeoObjectCollection.featureMember.map(el => {
                                return el.GeoObject
                            }
                        ));
                    }
                })
                .catch(err => {
                    console.error('getLocationByName error: ', {
                        err: err,
                        response: response
                    });
                }))
};
export default getLocationByName;