import { offersActions } from 'actions/offers';
import * as adminActions from 'actions/admin';
import LeftMenu from 'components/Admin/Pages/Drivers/Detail/LeftMenu';
import PersonalData from 'components/Admin/Pages/Drivers/Detail/PersonalData';
import TransportData from 'components/Admin/Pages/Routes/Detail/TransportData';
import TradeData from 'components/Admin/Pages/Trades/Detail/TradeDate';
import AdminContainer from 'components/Admin/UI/Container';
import AdminGrid from 'components/Admin/UI/Grid';
import NotFound from 'components/Admin/UI/List/NotFound';
import listStyle from 'components/Admin/UI/List/style.less';
import AdminLoader from 'components/Admin/UI/Loader';
import AdminPage from 'components/Admin/UI/Page';
import Gallery from 'components/UI/Gallery';
import { getProfile } from 'libs/selections';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AdminLabel } from 'components/Admin/UI/Label';
import InsureAdminForm from 'components/ReduxForms/InsureAdminForm';


class AdminTradeDetail extends Component {

	state = {
		loading: false,
		photo: '',
		openImage: false,
	};


	handleOpenGallery = photo => {
		this.setState({
			openImage: true,
			photo
		});
	};

	handleCloseGallery = () => {
		this.setState({ openImage: false });
	};

	componentWillMount() {
		this.props.dispatch(offersActions.getTradeByID(this.props.match.params.id))
	}

	sendInsurance = values => {
		this.props.dispatch(adminActions.setInsure({ id: this.props.match.params.id, ...values }))
	};

	render() {

		const { trade } = this.props;
		const	{ openImage, photo } = this.state;

		let loading = !trade;
		return (
			<AdminPage>
				<LeftMenu link="/admin/trades/"/>
				<div>
					<AdminGrid type="mainContent">
						<h1> Сделка {trade && `№${trade.id}`} </h1>
						<AdminContainer>
							{
								loading &&
								<AdminLoader className={listStyle.loading}/>
							}

							{
								!loading && trade &&
								<>
									<TradeData {...trade}/>

									<h3>Страхование груза</h3>
									<AdminGrid type="passport">
										<div>
											<AdminLabel title="Статус у клиента">
												{ trade.customerInsuranceStatus === null && 'Не поступала заявка на страхование' }
												{ trade.customerInsuranceStatus === 'LOADING' && 'Ожидание кода страхования' }
												{ trade.customerInsuranceStatus === 'SEND_ERROR' && 'Ошибка страхования' }
												{ trade.customerInsuranceStatus === 'OK' && trade.customerInsuranceNumber }
											</AdminLabel>
											<AdminLabel title="Статус у водителя">
												{ trade.driverInsuranceStatus === null && 'Не поступала заявка на страхование' }
												{ trade.driverInsuranceStatus === 'LOADING' && 'Ожидание кода страхования' }
												{ trade.driverInsuranceStatus === 'SEND_ERROR' && 'Ошибка страхования' }
												{ trade.driverInsuranceStatus === 'OK' && trade.driverInsuranceNumber }
											</AdminLabel>

											<InsureAdminForm
												initialValues={{
													driverInsuranceNumber: trade.driverInsuranceNumber,
													customerInsuranceNumber: trade.customerInsuranceNumber
												}}
												onSubmit={this.sendInsurance}
												enableReinitialize
											/>

										</div>
									</AdminGrid>
									<PersonalData {...trade.customer}/>
									<PersonalData {...{ ...trade.executor, role: 'driver' }}/>
									<TransportData {...trade.transport}/>
								</>
							}


							{
								!loading && !trade &&
								<NotFound
									style={{ marginBottom: 0, marginTop: 0 }}
									text={'Сделка не найдена'}
								/>
							}
						</AdminContainer>
					</AdminGrid>
				</div>
				{
					openImage &&
					<Gallery
						target={photo}
						handleClose={() => this.handleCloseGallery()}
					/>
				}
			</AdminPage>
		);
	}
}


export default connect(
	state => ({
		profile: getProfile(state),
		trade: state.offers.detail
	})
)(AdminTradeDetail);
