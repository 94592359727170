import handleActions from 'redux-actions/es/handleActions';
import {searchActions} from 'actions/search';

const getSearch = (initStore) => handleActions({
	[searchActions.setResults]: (state, action) => ({
		...state,
		...action.payload,
	}),
	[searchActions.appendResults]: (state, action) => ({
		...state,
		items: [
			...state.items,
			...action.payload
		]
	}),
}, initStore);

export default getSearch;