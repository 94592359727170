export const scrollToTop = scrollDuration => {
	let scrollStep = -window.scrollY / (scrollDuration / 15),
		scrollInterval = setInterval(function(){
			if ( window.scrollY !== 0 ) window.scrollBy( 0, scrollStep );
			else clearInterval(scrollInterval);
		},15);
};

export const scrollToElement = () => {
	window.location.hash = window.decodeURIComponent(window.location.hash);
	const hashParts = window.location.hash.split('#');
	if (hashParts.length >= 2) {
		const hash = hashParts.slice(-1)[0],
			element = document.querySelector(`#${hash}`);
		if(element) element.scrollIntoView();
	}
};
