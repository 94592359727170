exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".UsMWey7k8pKVstMqK9Qgj {\n  margin-top: 40px;\n}\n._2rbs24_IpIFfkazXJHL1c {\n  margin-right: 70px;\n}\n@media screen and (max-width: 575px) {\n  ._2rbs24_IpIFfkazXJHL1c {\n    margin-right: 0;\n  }\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/ReduxForms/ProfilePassportForm/style.less"],"names":[],"mappings":"AAAA;EACE,iBAAiB;CAClB;AACD;EACE,mBAAmB;CACpB;AACD;EACE;IACE,gBAAgB;GACjB;CACF","file":"style.less","sourcesContent":[".button {\n  margin-top: 40px;\n}\n.uploadImageProfile {\n  margin-right: 70px;\n}\n@media screen and (max-width: 575px) {\n  .uploadImageProfile {\n    margin-right: 0;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"button": "UsMWey7k8pKVstMqK9Qgj",
	"uploadImageProfile": "_2rbs24_IpIFfkazXJHL1c"
};