import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './styles.less';
import PageCargoTitle from 'components/UI/Wrappers/PageCargoTitle';
import ActionMenu from 'components/UI/ActionMenu';
import RequestPoints from 'components/UI/Request/RequestPoints';
import { cancelTrade, arbitrationTrade } from 'libs/statusActions';
import { CancelTrade } from 'components/UI/TradeDialogs';
import { offersActions } from 'actions/offers';
import Dialog from 'components/UI/Dialog';
import style from 'components/Pages/Trades/Detail/styles.less';
import ArbitrationForm from 'components/ReduxForms/ArbitrationForm';
import showError from 'libs/showFormErrors';
import { required } from 'libs/validationsForms';

class PreviewTrade extends React.Component {

	static propTypes = {
		trade: PropTypes.object.isRequired,
	};

	state = {
		dialogCancelTrade: false,
		dialogArbitrate: false,
	};

	toggleDialog = dialog => this.setState({ [dialog]: !this.state[dialog] });

	cancelTrade = () => {
		this.props.dispatch(offersActions.cancelTrade(this.props.trade.id));
		this.toggleDialog('dialogCancelTrade');
	};
	
	sendArbitration = values => {
		showError({
			reason: [required(values.reason)],
			comment: [required(values.comment)],
		});
		this.toggleDialog('dialogArbitrate');
		if (values.fileIds) values.fileIds = values.fileIds.images.map(it => it.id);
		this.props.dispatch(offersActions.sendArbitration(values));
	};
	
	
	render () {
		let { trade } = this.props;
		const url = `/trade/${trade.id}`;
		
		const type = arbitrationTrade.indexOf(trade.status) !== -1 ?
			'tradeArbitrate' : cancelTrade.indexOf(trade.status) !== -1 ? 'trade' : '';

		return (
			<Fragment>
				<div className={styles.preview}>
					<Link className={styles.url} to={url} />
					<div className={styles.header}>
						<div>
							<PageCargoTitle cargo={trade.offer.cargo} />
						</div>
						<ActionMenu
							type={type}
							actionArbitrate={() => this.toggleDialog('dialogArbitrate')}
							actionCancelled={() => this.toggleDialog('dialogCancelTrade')}
						/>
					</div>
					<div className={styles.body}>
						<RequestPoints points={trade.offer.route.items} />
					</div>
					<div className={styles.footer}>
						<div className={styles.grayColor}>Сделка №{trade.id}</div>
						<Link to={url} className={styles.buttonHover}>Подробнее</Link>
					</div>
				</div>
				{
					this.state.dialogCancelTrade
					&&
					<CancelTrade
						agreeAction={this.cancelTrade}
						cancelAction={() => {this.toggleDialog('dialogCancelTrade')}}
					/>
				}
				{
					this.state.dialogArbitrate
					&&
					<Dialog className={style.arbitrate} handleClose={() => this.toggleDialog('dialogArbitrate')}>
						<h3>Арбитраж по <span className={style.blue}>заявке №{ trade.offer.id }</span></h3>
						<ArbitrationForm
							onSubmit={this.sendArbitration}
							initialValues={{ tradeId: trade.id }}
						/>
					</Dialog>
				}
			</Fragment>
		)
	}
}

export default connect()(PreviewTrade);