import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';

const Grid = ({ children, className, col }) => (
	<div className={`${style['grid-container']} ${style[`grid-container-${col}`]} ${className}`}>
		{ children }
	</div>
);

Grid.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	col: PropTypes.string,
};
Grid.defaultProps = {
	children: '',
	className: '',
	col: '1',
};

export default Grid;
