import React from 'react';
import Grid from 'components/UI/Wrappers/Grid';
import MainScreen from "components/HelpfulInformations/common/main";
import MainWhiteContainer from "components/UI/Wrappers/MainWhiteContainer";
import styles from "components/HelpfulInformations/style.less";
import Navigation from "components/HelpfulInformations/common/navigation";
import Container from "components/UI/Wrappers/Container";
import { Link } from "react-router-dom";
import bg from 'components/HelpfulInformations/assets/bg_start.svg';
import AsideSupport from 'components/HelpfulInformations/common/AsideSupport';

const StartWork = () => (
    <MainScreen title={'Как начать работать с сервисом'}
                subtitle={'Ознакомьтесь с инструкцией о том как работать с сервисом, это займет не более 5 минут.'}
        bg={bg}
    >
        <MainWhiteContainer className={`${styles.advantage__screen} ${styles.not_padding} ${styles.noBorder}`}>
            <Navigation isClient isActive={0}/>
        </MainWhiteContainer>

        <Container className={styles.container_cards}>
            <h2 style={{ marginTop: '50px', fontSize: '30px' }}><b>
                Пошаговая инструкция
            </b></h2>
            <Grid col={'10-2'} className={styles.grid2Col}>
                <ul className={styles.list_card}>
                    <li className={styles.list_card__item}>
                        <p>
                            <h3> <b> Ознакомление с условиями </b> </h3>
                            На странице <Link to={'/registration/customer'}>Регистрация</Link> ознакомьтесь с “Правилами сайта” и “Политикой конфиденциальности”. Регистрация пользователя на сервисе означает, что пользователь соглашается с условиями соглашения и принимает на себя указанные в нем права и обязанности, связанные с использованием функционала сервиса.
                        </p>
                    </li>
                    <li className={styles.list_card__item}>
                        <p>
                            <h3> <b> Прохождение регистрации </b> </h3>
                            Для регистрации аккаунта следует кликнуть <Link to={'/auth'}>«Войти»</Link> в правом верхнем углу. Откроется окно с активной кнопкой «Зарегистрироваться».
                            <br/><br/>
                            Нажав на нее, вы перейдете в форму создания аккаунта отправителя. Для регистрации в качестве грузоперевозчика, нажмите на надписи «Я водитель и хочу заняться перевозкой грузов» внизу формы регистрации. Формы – идентичны, в любом статусе вы должны указать имя, фамилию, email, номер телефона, пароль.
                            <br/><br/>
                            После заполнения всех полей, нажмите на кнопку «Зарегистрироваться». Сервис направит вас на страницу подтверждения регистрации путем введения кода, полученного в смс-сообщении по указанному номеру телефона. Введите полученный в смс-сообщении код. Вы – зарегистрированы.
                        </p>
                    </li>
                    <li className={styles.list_card__item}>
                        <p>
                            <h3> <b> Заполнение личных данных </b> </h3>
                            В профиле укажите ваши данные и дополнительную информацию: контакты - для связи с другими пользователями, банковская карта (данные карты необходимы для оплаты услуг водителя), данные паспорта (подтверждают вашу личность, указываются в договоре).
                        </p>
                    </li>
                    <li className={styles.list_card__item}>
                        <p>
                            <h3> <b> Создание заявки </b> </h3>
                            На странице “Мои заявки” доступна функция добавления новых заказов. Дополнительно, создать заявку возможно с помощью кнопки «Добавить груз» в правом верхнем углу экрана.

                        </p>
                    </li>
                    <li className={styles.list_card__item}>
                        <p>
                            <h3> <b> Поиск перевозчиков </b> </h3>
                            Вы можете сами найти подходящего перевозчика не дожидаясь предложений в разделе «Поиск». Воспользуйтесь функционалом раздела “Найти перевозчика”:
                            <br/><br/>
                            <b>Доступны 2 варианта поиска:</b>
                            <br/><br/>

                            1. По заданным параметрам (пункты отправки и назначения, регион, дальность транспортировки,  объем груза)
                            <br/><br/>
                            2. Путем создания заказа (После публикации заявки вам будут поступать на почту и в личный кабинет предложения от перевозчиков.)
                            <br/><br/>
                            Выберите исполнителя, согласуйте условия сотрудничества в чате и оформите Безопасную сделку, после чего перечислите оплату (до момента подтверждения деньги будут холдированы) , после доставки груза и подтверждения получения деньги спишутся с вашей карты

                        </p>
                    </li>
                </ul>
    
                <AsideSupport isClient />
            </Grid>
        </Container>
    </MainScreen>
);

export default StartWork;
