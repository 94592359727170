import React from 'react';
import styles from './style.less';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Userdata from 'components/Search/Result/Userdata';
import Addresses from 'components/Search/Result/Addresses';
import Transport from 'components/Search/Result/Transport';
import CargoLoading from 'components/Search/Result/CargoLoading';
import Cargo from 'components/Search/Result/Cargo';
import DataForDriver from 'components/Search/Result/DataForDriver';
import DataForOthers from 'components/Search/Result/DataForOthers';


class SearchResultItem extends React.PureComponent {

	render () {
		const { role, data } = this.props;
		if (data === undefined || role === undefined) return null;
		let transport = null;

		let route = data.items ? data.items : data.route ? data.route.items : undefined;

		if (role === 'driver') {
			transport = {
				height: data.cargo.height,
				width: data.cargo.width,
				length: data.cargo.length,
				carrying: data.cargo.weight,
				bodyType: data.bodyTypeId.titleRu,
			};
		} else {
			transport = data.transport;
		}
		if (transport === undefined || route === undefined) return null;


		return (
			<div className={`${styles.result_item} ${role === 'driver' && styles.driver_item}`}>
				<Link
					// className={role === 'guest' ? styles.linkGuest : ''}
					to={role === 'guest' ? `/auth` : role === 'driver' ? `/offer/${data.id}` : `/route/${data.id}`}
				/>
				<div className={styles.content}>
					<Userdata user={data.user} />
					<Addresses route={route} />
					<Transport data={transport} />
					{ role === 'driver' && <Cargo data={data.cargo} /> }
					<CargoLoading data={ role === 'driver' ? data.carLoadType : transport.loadTypeId ? transport.loadTypeId.titleRu : 'Тип загрузки не указан' } />
					{
						role === 'driver' ?
							<DataForDriver data={{ price: data.price, distance: data.route.distance }} id={data.id} /> :
							<DataForOthers
								price={data.price}
								id={data.id}
								user={data.user}
								isFavorite={data.isFavorite}
								role={role}
								updateData={this.props.updateData}
							/>
					}
				</div>
			</div>
		)
	}
}


SearchResultItem.propTypes = {
	role: PropTypes.string.isRequired,
	data: PropTypes.object.isRequired,
};

export default SearchResultItem;
