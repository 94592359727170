const getWordEnd = (number, words) => {
	if (typeof(number) !== 'number') {
		return null;
	}

	number = Math.abs(number) % 100;
	let number1 = number % 10;
	if (number > 10 && number < 20) { return words[2]; }
	if (number1 > 1 && number1 < 5) { return words[1]; }
	if (number1 === 1) { return words[0]; }
	return words[2];
};

export default getWordEnd;