import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';

export const LabelError = ({ message, isOn = false }) => {
	const renderClass = `${style.containerError} ${isOn ? style.activeError : ''}`;
	if (!isOn) return false;
	if(message !== null) {
		if (message[0] && typeof (message[0]) !== "string") {
			let text = '';
			message = message.map(msg => {
				for (let key in msg) {
					text += msg[key] + ';'
				}
				return text;
			});
			return <span className={renderClass} data-scroll-anchor={1}>{message}</span>;
		}
		return <span className={renderClass} data-scroll-anchor={1}>{message}</span>;
	}

	return '';
};

export const LabelInfo = props => (
	<div className={`${style.label_info} ${props.className ? props.className : ''}`}>
		<div className={style.title}>{ props.title }</div>
		<div className={style.text}>{ props.children }</div>
	</div>
);

LabelError.propTypes = {
	message: PropTypes.any,
};
LabelError.defaultProps = {
	message: '',
};

LabelInfo.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
};
LabelInfo.defaultProps = {
	title: '',
	children: '',
};
