import React from 'react';
import style from './style.less';
import Button from 'components/UI/Form/Button';
import { Unlink, Close } from 'components/UI/Icons';

const JustFileUpload = props => {
	let { images, handleRemoveImg, id } = props;
	return (
		<div className={style.justFileImages}>
			<label className={style.justFileSend} htmlFor={id}>
				<span><Unlink customClass={style.unlink} /> Прикрепить файл</span>
			</label>
			<div className={style.imagesList}>
				{
					images !== undefined && images.length > 0 &&
					images.map(img => (
						<div className={style.imageUpload} key={img.id}>
							<img src={img.images[0] ? img.images[0].url : '#'} alt={'image#'+img.id} />
							<Button
								className={style.imageCloseButton}
								onClick={() => handleRemoveImg(img.id)}
								clearStyle
							>
								{Close()}
							</Button>
						</div>
					))
				}
			</div>
		</div>

	)
};

export default JustFileUpload;