import React from 'react';
import { connect } from 'react-redux';
import { rmFavoriteById } from 'actions/customer';
import { getFavoriteDrivers } from 'libs/selections';
import Page from 'components/UI/Wrappers/Page';
import Driver from './Driver';
import style from './style.less';
import styleDialog from 'components/Profile/Cars/style.less';
import Dialog from 'components/UI/Dialog';
import Button from 'components/UI/Form/Button';

class Favorite extends React.PureComponent {

	state = {
		showDialog: false,
		id: 0,
	};

	openDialog = id => {
		this.setState({ showDialog: true, id });
	};

	closeDialog = () => this.setState({ showDialog: false, id: 0 });

	deleteFavorite = () => {
		this.props.dispatch(rmFavoriteById({ id: this.state.id }));
		this.closeDialog();
	};

	render() {
		const { favorites } = this.props;
		return (
			<Page title={'Избранные водители'}>
				<div className={style.driver_list}>
					{
						favorites.length === 0 &&
						<p>Список избранных водителей пуст.</p>
					}
					{
						favorites.length > 0 &&
						favorites.map(el => <Driver key={el.id} onClick={() => this.openDialog(el.id)} driver={el} />)
					}
				</div>
				{
					this.state.showDialog &&
					<Dialog handleClose={this.closeDialog}>
						<h3 className={styleDialog.textcenter}>
							Вы действительно хотите удалить водителя из избранного?
						</h3>
						<div className={styleDialog.buttonList}>
							<Button
								className={styleDialog.buttonFirst}
								color={'remove'}
								onClick={this.deleteFavorite}
							>
								Да
							</Button>
							<Button
								color={'white'}
								onClick={this.closeDialog}
							>
								Нет
							</Button>
						</div>
					</Dialog>
				}
			</Page>
		);
	}
}

export default connect(
	store => ({
		favorites: getFavoriteDrivers(store),
	})
)(Favorite);