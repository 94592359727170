import React from 'react';
import style from '../../BecomeCarrier/style.less';
import Container from 'components/UI/Wrappers/Container';

const MainScreen = props => {

  const { title, subtitle, bg } = props;

  return (
    <div className={style.become_carrier}>
      <div className={`${style.main__screen} ${style.internal_pages}`}>
        <Container>
          <div className={style.bg}>
            {
              bg &&
              <img src={bg} alt=""/>
            }
          </div>
          <div className={style.internal_pages_text}>
            <h1>{title}</h1>
            <p> {subtitle} </p>
          </div>
        </Container>
      </div>
      { props.children }
    </div>
  )
};

export default MainScreen;
