export const NumberNormalize = value => {
	// eslint-disable-next-line
	return value.replace(/^\.|\d+\..*\.|[^\d\.{1}]/, '');
};

export const toUpperCaseNormalize = value => {
	return value.toUpperCase();
};

export const NumberNormalizeSlice = value => {
	if (value.indexOf('.') !== -1 || value.indexOf(',') !== -1) {
		// eslint-disable-next-line
		return value.replace(/^\.|\d+\..*\.|[^\d\.{1}]/, '').slice(0, 4);
	}
	// eslint-disable-next-line
	return value.replace(/^\.|\d+\..*\.|[^\d\.{1}]/, '').slice(0,2);
};
