import { dateToString, stringToDate } from './convertDate';

export const getCar = transport => {
	const carMark = transport.carMark.titleRu !== 'NULL' ? transport.carMark.titleRu : transport.carMark.title;
	const carModel = transport.carModel.titleRu !== 'NULL' ? transport.carModel.titleRu : transport.carModel.title;
	return `${carMark}, ${carModel}`;
};

export const getFullCar = transport => {
	if (transport === undefined || transport.carMark === undefined || transport.carModel === undefined || transport.carSeries === undefined) return null;
	const carMark = transport.carMark.titleRu !== 'NULL' ? transport.carMark.titleRu : transport.carMark.title;
	const carModel = transport.carModel.titleRu !== 'NULL' ? transport.carModel.titleRu : transport.carModel.title;
	const carSeries = transport.carSeries.titleRu ? transport.carSeries.titleRu : transport.carSeries.title;
	if (transport.publishYear !== null)
		return `${carMark}, ${carModel}, ${carSeries}, ${transport.publishYear}г.`;

	return `${carMark}, ${carModel}, ${carSeries}`;
};

export const getVolumeCar = transport => {
	return transport.width * transport.height * transport.length;
};

export const getLoadType = transport => {
	// const carMark = transport.carMark.titleRu !== 'NULL' ? transport.carMark.titleRu : transport.carMark.title;
	// const carModel = transport.carModel.titleRu !== 'NULL' ? transport.carModel.titleRu : transport.carModel.title;
	// return `${carMark}, ${carModel}`;
};

export const getPoints = points => {
	if (points === undefined) return {
		pointA: {
			title: 'Загрузка...',
			date: 'Загрузка...',
		},
		pointB: {
			title: 'Загрузка...',
			date: 'Загрузка...',
		},
		otherPoints: 'Загрузка...',
	};

	const startPoint = points[0];
	const	endPoint = points[points.length - 1];
	let otherPoints = [];
	let startDate = '';
	let endDate = '';

	const compareDateTypeof = typeof startPoint.startDate === 'object';
	// const compareTitleOneTypeof = typeof startPoint.address === 'object';
	// const compareTitleTwoTypeof = typeof endPoint.address === 'object';

	if (compareDateTypeof) {
		startDate = dateToString(startPoint.startDate, 'default');
		endDate = dateToString(endPoint.startDate, 'default');
	} else {
		startDate = dateToString(stringToDate(startPoint.startDate), 'default');
		endDate = dateToString(stringToDate(endPoint.startDate), 'default');
	}

	if (points.length > 2)
		points.forEach((el, i) => {
			if (i !== 0 && i !== points.length - 1)
				otherPoints.push({
					title: el.locality ? el.locality : el.address,
				});
		});

	// let titleA = startPoint.locality ? startPoint.locality : compareTitleOneTypeof ? startPoint.address.name : startPoint.address;
	// let titleB = endPoint.locality ? endPoint.locality : compareTitleTwoTypeof ? endPoint.address.name : endPoint.address;
	let titleA = startPoint.address;
	let titleB = endPoint.address;

	return {
		pointA: {
			title: titleA,
			date: startDate,
		},
		pointB: {
			title: titleB,
			date: endDate,
		},
		otherPoints,
	};
};