import * as adminActions from 'actions/admin';
import LeftMenu from 'components/Admin/Pages/Drivers/Detail/LeftMenu';
import PersonalData from "components/Admin/Pages/Drivers/Detail/PersonalData";
import RouteData from "components/Admin/Pages/Routes/Detail/RouteData";
import TransportData from "components/Admin/Pages/Routes/Detail/TransportData";
import AdminContainer from 'components/Admin/UI/Container';
import AdminGrid from 'components/Admin/UI/Grid';
import NotFound from 'components/Admin/UI/List/NotFound';
import listStyle from 'components/Admin/UI/List/style.less';
import AdminLoader from 'components/Admin/UI/Loader';
import AdminPage from 'components/Admin/UI/Page';
import Button from "components/UI/Form/Button/index";
import Gallery from 'components/UI/Gallery';
import { getProfile } from 'libs/selections';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import style from './style.less';
import ModerationCancelledForm from 'components/ReduxForms/ModerationCancelledForm';


class AdminRouteDetail extends Component {

    state = {
        loading: false,
        photo: '',
        openImage: false,
    };


    handleOpenGallery = photo => {
        this.setState({
            openImage: true,
            photo
        });
    };

    handleCloseGallery = () => {
        this.setState({ openImage: false });
    };

    setRouteStatus = status => {
        this.props.dispatch(adminActions.setRouteStatus({ id: this.props.match.params.id, status, description: '' }))
    };
    
    setCancelledStatus = values => {
        this.props.dispatch(adminActions.setRouteStatus({ id: this.props.match.params.id, status: 'cancelled', description: values.description }))
    };

    componentWillMount() {
        this.props.dispatch(adminActions.getRouteByID(this.props.match.params.id));
    }

    render() {

        const { route, route: { loading } } = this.props,
            { openImage, photo } = this.state;
    
        console.log(route);
        return (
            <AdminPage>
                <LeftMenu link="/admin/routes/"/>
                <div>
                    <AdminGrid type="mainContent">
                        <h1> Маршрут {route && `№${route.id}`} </h1>
                        <AdminContainer>
                            {
                                loading &&
                                <AdminLoader className={listStyle.loading}/>
                            }

                            {
                                !loading && route &&
                                <>
                                <RouteData {...route}/>
                                <TransportData {...route.transport}/>
                                <PersonalData {...route.user}/>
                                </>
                            }
    
                            <h3>Модерирование маршрута</h3>
                            {
                                route.status !== 'published' &&
                                <>
                                    <p style={{ marginBottom: '-15px' }}>Если вас все устраивет, нажмите ниже</p>
                                    <div className={style.button_group}>
                                        <Button color="green" onClick={() => this.setRouteStatus('published')}>
                                            Опубликовать маршрут
                                        </Button>
                                    </div>
                                </>
                            }
                            {
                                route.status !== 'cancelled' &&
                                <ModerationCancelledForm
                                    onSubmit={this.setCancelledStatus}
                                    buttonTxt={'Отклонить маршрут'}
                                />
                            }

                            {
                                !loading && !route &&
                                <NotFound
                                    style={{ marginBottom: 0, marginTop: 0 }}
                                    text={'Маршрут не найден'}
                                />
                            }
                        </AdminContainer>
                    </AdminGrid>
                </div>
                {
                    openImage &&
                    <Gallery
                        target={photo}
                        handleClose={() => this.handleCloseGallery()}
                    />
                }
            </AdminPage>
        );
    }
}


export default connect(
    state => ({
        profile: getProfile(state),
        route: state.admin.routes.detail
    })
)(AdminRouteDetail);