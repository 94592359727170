import React from 'react';
import { connect } from 'react-redux';
import Page from 'components/UI/Wrappers/Page';
import Loader from 'components/UI/Loader';
import { getAdminUser } from 'libs/selections';
import * as adminAction from 'actions/admin';
import Grid from 'components/UI/Wrappers/Grid';
import VerifyUserForm from 'components/ReduxForms/VerifyUserForm';


class AdminUserItem extends React.Component {
	
	componentWillMount() {
		return new Promise((resolve, reject) => {
			this.props.dispatch(adminAction.getUserByID(this.props.match.params.id, { resolve, reject }));
		}).then(() => {
		}).catch(() => {
		});
	}
	
	handleSubmit = values => {
		let send = {
			isProfileVerification: values.isProfileVerification === '1',
			message: values.message,
			userId: this.props.match.params.id,
		};
		
		this.props.dispatch(adminAction.verifyUser(send));
	};
	
	render() {
		const { user } = this.props;
		
		if (user === undefined)
			return <Page title={`Пользователь #${this.props.match.params.id}`}><Loader /></Page>;
		
		return (
			<Page title={`#${user.id} ${user.lastName} ${user.firstName}`}>
				<Grid col={'9-3'}>
					<VerifyUserForm onSubmit={this.handleSubmit} />
				</Grid>
			</Page>
		)
	}
}

export default connect(
	state => ({
		user: getAdminUser(state)
	})
)(AdminUserItem);