import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {getProfile} from 'libs/selections';
import DriverContactDataForm from 'components/ReduxForms/DriverContactDataForm';
import DriverLicenseForm from 'components/ReduxForms/DriverLicenseForm';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import Panel from "components/UI/Wrappers/Panel";
import UpdatePassword from 'components/ReduxForms/UpdatePassword';
import ProfilePassportForm from "components/ReduxForms/ProfilePassportForm";
import Grid from 'components/UI/Wrappers/Grid';
import {saveContactData, savePassport, SaveDriverLicense, savePhoto, submitUpdatePassword } from './submit';
import style from "./style.less";
import tabStyle from 'components/UI/CustomTab/style.less';
import HeaderPageDescription from 'components/UI/HeaderPageDescription/index';
import { Tick } from 'components/UI/Icons';
import ScaleFill from 'components/UI/ScaleFill';
import DocumentRequired from 'components/Contents/DocumentRequired';
import {stringToDate} from 'libs/convertDate'
import UploadAvatarForm from 'components/ReduxForms/UploadAvatarForm';
import { reset } from 'redux-form';
import UserCardList from 'components/Profile/EditProfile/UserCardList';

class DriverForm extends React.PureComponent {
	state = {
		selectedTab: 0,
	};

	handleSaveContactData = values => saveContactData({...values}, this.props.profile.role, this.props.dispatch);

	handleSavePhoto = photo => savePhoto({ photoId: photo.images }, this.props.dispatch);

	handleChangePassword = values => {
		return submitUpdatePassword(values, this.props.dispatch, () => {
			this.props.dispatch(reset('updatePassword'));
		});
	};

	handleSavePassport = values => savePassport({...values}, this.props.profile.id, this.props.dispatch);

	handleSaveDriverLicense = values => SaveDriverLicense({...values},  this.props.profile.id, this.props.dispatch);

	handleSelect = selectedTab => {
		this.setState({ selectedTab });
	};

	getProfilePercent = () => {
		let profile = {
				...this.props.profile,
				...this.props.profile.license,
				...this.props.profile.passport,
				...this.props.profile.driver,
			},
			allCountFields = Object.keys(profile).length - 3, //потому что name и rating и status
			countValue = 0;
		for(let key in profile)
			countValue += profile[key] ? 1 : 0;
		return countValue * 100 / allCountFields;
	};

	render() {
		const { profile } = this.props;
		const { passport, license } = profile;
		const tabs = [
			{isFilled: true, title: 'Личные данные'},
			{isFilled: !!passport, title: 'Данные паспорта'},
			{isFilled: !!license, title: 'Данные удостоверения'},
		];

		let passportInit = {};
		if(passport){
			passportInit = {
				...passport,
				issueDate: stringToDate(passport.issueDate, 'yyyy-MM-dd', '-'),
				scanIds: passport.scans
			};
		}

		let driverLicenseInit = {};
		if(license){
			driverLicenseInit = {
				...license,
				issueDate: stringToDate(license.issueDate, 'yyyy-MM-dd', '-'),
				scanId: license.scans
			};
		}

		let profileInitialValues = {
			firstName: profile.firstName,
			lastName: profile.lastName,
			middleName: profile.middleName,
			email: profile.email,
			city: profile.city,
			experienceYears: profile.experienceYears,
			languages: profile.languages,
			phoneCode: profile.phone[0],
			phone_number: profile.phone.slice(1),
			additionalPhone_code: profile.additionalPhone ? profile.additionalPhone[0] : '',
			additionalPhone_number: profile.additionalPhone ? profile.additionalPhone.slice(1) : '', ...profile.driver
		};

		return (
			<Tabs>
				<HeaderPageDescription>
					<div className={style.fillProfile}>
						<span className={style.grayText}>Заполнено</span>
						<ScaleFill percent={this.getProfilePercent()} />
					</div>
					<TabList className={tabStyle.tab_list}>
						{
							tabs.map((el, i) => (
								<Tab
									key={i}
									className={`${tabStyle.tab_item} ${i === this.state.selectedTab ? tabStyle.active : ''} ${tabStyle.tab_profile}`}
									onClick={() => this.handleSelect(i)}
								>
									{
										el.isFilled ?
											<div className={`${tabStyle.icon} ${tabStyle.true}`}>{ Tick() }</div> :
											<div className={tabStyle.icon}>-</div>
									}
									{ el.title }
								</Tab>
							))
						}
					</TabList>
				</HeaderPageDescription>
				<TabPanel>
					<Grid className={style.formEditProfileDriverGrid}>
						<Panel className={style.panelOne} title="Персональные данные">
							<DriverContactDataForm
								onSubmit={this.handleSaveContactData}
								initialValues={profileInitialValues}
							/>
						</Panel>
						<UploadAvatarForm
							className={style.panelFour}
							onChangePhoto={photo => this.handleSavePhoto(photo)}
							initialValues={{ photoId: this.props.profile.driver.photo }}
						/>
						<UserCardList className={style.panelTwo}/>
						<Panel className={style.panelThree} title="Смена пароля">
							<UpdatePassword onSubmit={this.handleChangePassword} />
						</Panel>
					</Grid>
				</TabPanel>
				<TabPanel>
					<Grid className={style.formEditProfileDriverGrid}>
						<div>
							<Panel title="Данные паспорта">
								<ProfilePassportForm
									onSubmit={this.handleSavePassport}
									initialValues={passportInit}
								/>
							</Panel>
						</div>
						<div>
							<DocumentRequired />
						</div>
					</Grid>
				</TabPanel>
				<TabPanel>
					<Grid className={style.formEditProfileDriverGrid}>
						<div>
							<Panel title="Данные удостоверения">
								<DriverLicenseForm
									initialValues={driverLicenseInit}
									onSubmit={this.handleSaveDriverLicense}
								/>
							</Panel>
						</div>
						<div>
							<DocumentRequired />
						</div>
					</Grid>
				</TabPanel>
			</Tabs>
		)
	}
}

export default connect(
	store => ({
		profile: getProfile(store),
	})
)(DriverForm);