import React from 'react';
import PropTypes from 'prop-types';
import graystar from 'components/UI/Icons/svg/stargray.svg';
import goldstar from 'components/UI/Icons/svg/stargold.svg';
import getWordEnd from 'libs/getWordEnd';
import style from './style.less';

const Rating = props => {
	const className = `${style.rating} ${props.className ? props.className : ''}`;
	const count = props.count;
	const width = Number(count) * 10 * 2;

	const reviewWord = ['отзыв','отзыва','отзывов'];

	if (props.shortRating)
		return (
			<div className={`${className} ${style.shortRating}`}>
				<img src={goldstar} alt="" /> { props.count } <span className={style.reviewUserCount}><span className={style.dash}>—</span> { props.reviews.count } { getWordEnd(props.reviews.count, reviewWord) }</span>
			</div>
		);

	return (
		<div className={className}>
			{ props.text } { !props.justText && count }
			<div className={style.stars} style={{background: `url(${graystar})`}}>
				<div className={style.gold} style={{background: `url(${goldstar})`, width: `${width}%`}}/>
			</div>
			{
				props.reviews !== undefined && props.reviews.url !== undefined &&
				<div className={style.reviews}>
					<a href={`#${props.reviews.url}`}>{ props.reviews.count } { getWordEnd(props.reviews.count, reviewWord) }</a>
				</div>
			}
		</div>
	);
};

Rating.propTypes = {
	count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	text: PropTypes.string,
	className: PropTypes.string,
	reviews: PropTypes.object,
	justText: PropTypes.bool,
	shortRating: PropTypes.bool,
};
Rating.defaultProps = {
	text: 'Рейтинг',
	className: '',
	justText: false,
	shortRating: false,
};

export default Rating;