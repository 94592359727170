exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2nMgzCveHsJWEr_IDxTvNO {\n  padding: 20px;\n}\n._210ma564mBJqyI9_nzCjdG {\n  padding-right: 20px;\n}\n._1FMp3PsKMyMpMHmmUoNp07 {\n  color: #7f848d;\n  font-size: 14px;\n  display: flex;\n  align-items: center;\n}\n.eXqRENVp8mIDOQ-sCyzVD {\n  margin-top: 20px;\n}\n._2mPQ3grk2tlStXDqkgnmpY {\n  justify-content: flex-start;\n  text-align: left;\n  padding: 0;\n}\n._2mPQ3grk2tlStXDqkgnmpY div {\n  width: 100%;\n  -webkit-text-fill-color: transparent;\n  -webkit-background-clip: text;\n  justify-content: flex-start;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/Admin/Pages/Trades/List/style.less"],"names":[],"mappings":"AAAA;EACE,cAAc;CACf;AACD;EACE,oBAAoB;CACrB;AACD;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,oBAAoB;CACrB;AACD;EACE,iBAAiB;CAClB;AACD;EACE,4BAA4B;EAC5B,iBAAiB;EACjB,WAAW;CACZ;AACD;EACE,YAAY;EACZ,qCAAqC;EACrC,8BAA8B;EAC9B,4BAA4B;CAC7B","file":"style.less","sourcesContent":[".p20 {\n  padding: 20px;\n}\n.pr20 {\n  padding-right: 20px;\n}\n.label {\n  color: #7f848d;\n  font-size: 14px;\n  display: flex;\n  align-items: center;\n}\n.mt20 {\n  margin-top: 20px;\n}\n.admin_status {\n  justify-content: flex-start;\n  text-align: left;\n  padding: 0;\n}\n.admin_status div {\n  width: 100%;\n  -webkit-text-fill-color: transparent;\n  -webkit-background-clip: text;\n  justify-content: flex-start;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"p20": "_2nMgzCveHsJWEr_IDxTvNO",
	"pr20": "_210ma564mBJqyI9_nzCjdG",
	"label": "_1FMp3PsKMyMpMHmmUoNp07",
	"mt20": "eXqRENVp8mIDOQ-sCyzVD",
	"admin_status": "_2mPQ3grk2tlStXDqkgnmpY"
};