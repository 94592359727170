import { createActions } from 'redux-actions';

export const { user: { registration, auth, confirm, recovery, testGuestPhone, feedback, sendOfferWithoutReg } } = createActions({
	user: {
		registration: [(payload) => payload, (payload, meta) => meta],
		auth: [(payload) => payload, (payload, meta) => meta],
		confirm: [(payload) => payload, (payload, meta) => meta],
		recovery: [(payload) => payload, (payload, meta) => meta],
		testGuestPhone: [(payload) => payload, (payload, meta) => meta],
		feedback: [payload => payload, (payload, meta) => meta],
		sendOfferWithoutReg: [payload => payload, (payload, meta) => meta],
	}
});
