import React from 'react';
import LayoutField from 'components/UI/Wrappers/LayoutField';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import Button from 'components/UI/Form/Button';
import PropTypes from 'prop-types';
import Input from 'components/UI/Form/Fields/Input';
import SelectedStar from 'components/UI/Form/Fields/SelectedStar';
import Textarea from 'components/UI/Form/Fields/Textarea';
import shortName from 'libs/shortName';
import getWordEnd from 'libs/getWordEnd';
import style from './style.less';
import { compose } from 'redux';
import { connect } from 'react-redux';

let ReviewForm = props => {
	let { handleSubmit, invalid, role, user, values } = props;
	const about = role === 'driver' ? 'клиенте' : 'перевозчике';
	const count = parseFloat(((values.punctualityRating + values.adequacyRating + values.priceRating) / 3).toFixed(1));
	return (
		<form onSubmit={handleSubmit} autoComplete="off">
			<h3>Отзыв о { about } { shortName(user.lastName, user.firstName) }</h3>
			<LayoutField>
				<Field
					name={'route'}
					component={Input}
					label={'Маршрут'}
					disabled
				/>
			</LayoutField>
			<LayoutField>
				<Field
					name={'title'}
					component={Input}
					label={'Заголовок отзыва'}
				/>
			</LayoutField>
			<LayoutField>
				<Field
					name={'text'}
					component={Textarea}
					label={`Ваше мнение о ${about}`}
				/>
			</LayoutField>
			<p className={style.p}>Средняя оценка: { count ? count : 0 } { getWordEnd(count ? count : 0, ['балл','балла','баллов']) }</p>
			<div className={style.ratingTemplate}>
				<LayoutField>
					<Field
						name="punctualityRating"
						component={SelectedStar}
						label={'Пунктуальность'}
					/>
				</LayoutField>
				<LayoutField>
					<Field
						name="adequacyRating"
						component={SelectedStar}
						label={'Адекватность'}
					/>
				</LayoutField>
				<LayoutField>
					<Field
						name="priceRating"
						component={SelectedStar}
						label={'Цена'}
					/>
				</LayoutField>
			</div>
			<Button
				disabled={invalid}
				size={'large'}
				type={'submit'}
			>
				Добавить отзыв
			</Button>
		</form>
	);
};

ReviewForm.propTypes = {
	handleSubmit: PropTypes.func,
	error: PropTypes.string,
	submitting: PropTypes.bool,
	invalid: PropTypes.bool,
	role: PropTypes.string.isRequired,
	user: PropTypes.object.isRequired,
};
ReviewForm.defaultProps = {
	handleSubmit: () => {},
	invalid: false,
	submitting: false,
	error: '',
};

const selector = formValueSelector('ReviewForm');

export default compose(
	reduxForm({
		form: 'ReviewForm',
	}),
	connect(
		state => ({
			values: selector(state, 'punctualityRating', 'adequacyRating', 'priceRating')
		})
	)
)(ReviewForm);