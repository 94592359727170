import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import style from 'components/ReduxForms/AddCargoForm/style.less';
import Panel from 'components/UI/Wrappers/Panel';
import { getProfileType } from 'libs/selections';
import InputSelectGroup from 'components/UI/Wrappers/InputSelectGroup';
import Grid from 'components/UI/Wrappers/Grid';
import Input from 'components/UI/Form/Fields/Input';
import { phone } from 'libs/validationsForms';
import { Field, change } from 'redux-form';
import Select from 'components/UI/Form/Fields/Select';
import OptionSelect from 'components/UI/Form/Fields/OptionSelect';
import { testGuestPhone } from 'actions/user';
import Loader from 'components/UI/_V2/Loader';
import Button from 'components/UI/Form/Button';
import OfferRegistration from 'components/ReduxForms/OfferForm/OfferRegistration';


class OfferGuest extends PureComponent {

	state = {
		step: 'test',
		loading: false,
		phoneNumber: '',
		phoneCode: '',
	};

	testPhone = () => {
		let phone = this.state.phoneCode + this.state.phoneNumber.replace(/\s/g, '');

		return new Promise((resolve, reject) => {
			this.props.dispatch(testGuestPhone({phone}, {
				resolve: resolve,
				reject: reject,
			}));
		}).then(() =>{
			this.setState({ step: 'SignIn', loading: false });
			this.props.dispatch(change('offerForm', 'isGuest', 'SignIn'));
		}).catch(() => {
			this.setState({ step: 'SignUp', loading: false });
			this.props.dispatch(change('offerForm', 'isGuest', 'SignUp'));
		});
	};

	handleChangePhone = (event, val) => {
		if (
			event.target === undefined ||
			(event.target.name === 'phoneNumber' && phone(val) === undefined)
		) {
			this.setState({[event.target === undefined ? 'phoneCode' : 'phoneNumber']: val}, () => {
				if (this.state.phoneNumber.length >= 10 && this.state.phoneCode && !this.state.loading) {
					this.setState({ loading: true });
					this.testPhone();
				}
			});
		}
	};

	onChange = token => {
		this.props.dispatch(change('offerForm', 'recaptchaValue', token));
	};

	toStarted = () => {
		this.setState({
			step: 'test',
			loading: false,
			phoneNumber: '',
			phoneCode: ''
		});
	};

	render () {

		const { role } = this.props;

		const { step, loading, phoneCode, phoneNumber } = this.state;

		if (role !== 'guest') return false;

		return (
			<Panel className={style.customAuthPanel}>

				{
					loading &&
					<Loader className={style.loaderNoMargin} />
				}

				{
					!loading && step === 'test' &&
					<Grid col="form-2">
						<InputSelectGroup>
							<Field
								name="phoneNumber"
								onBlur={this.handleChangePhone}
								type="text"
								component={Input}
								label="Телефон"
								mask="999 999 99 99"
								containerGroup="left"
							/>
							<Field
								onChange={this.handleChangePhone}
								name="phoneCode"
								component={Select}
								childGroup="left"
							>
								<OptionSelect value="7">+7</OptionSelect>
								<OptionSelect value="375">+375</OptionSelect>
								<OptionSelect value="98">+98</OptionSelect>
							</Field>
						</InputSelectGroup>
					</Grid>
				}

				{
					!loading && step !== 'test' &&
					<div style={{ marginBottom: '15px' }}>
						Ваш номер: <b>+{ phoneCode } { phoneNumber }</b>.{' '}{' '}
						<Button
							clearStyle
							className={style.buttonReturn}
							onClick={this.toStarted}
						>
							Изменить
						</Button>
					</div>
				}

				{
					!loading && step === 'SignIn' &&
					<div style={{ maxWidth: '360px' }}>
						<Field
							name="password"
							type="password"
							component={Input}
							label="Пароль"
						/>
					</div>
				}

				{
					!loading && step === 'SignUp' &&
					<OfferRegistration recaptchaRef={this.props.recaptchaRef} onChange={this.onChange} />
				}

			</Panel>
		)
	}
}


export default connect(
	state => ({
		role: getProfileType(state)
	})
)(OfferGuest);

/*
state = {
		loading: false,
		guest_code: '',
		guest_number: '',
		formType: '',
	};

	testPhone = () => {
		let phone = this.state.guest_code + this.state.guest_number.replace(/\s/g, '');

		return new Promise((resolve, reject) => {
			this.props.dispatch(testGuestPhone({phone}, {
				resolve: resolve,
				reject: reject,
			}));
			this.setDefaultValue();
		}).then(() =>{
			this.setState({ formType: 'SignIn', loading: false });
			this.props.dispatch(change('offerForm', 'isGuest', 'SignIn'));
		}).catch(() => {
			this.setState({ formType: 'SignUp', loading: false });
			this.props.dispatch(change('offerForm', 'isGuest', 'SignUp'));
		});
	};

	handleChangePhone = (event, val) => {
		if (
			event.target === undefined ||
			(event.target.name === 'guest_number' && phone(val) === undefined)
		){
			this.setState({[event.target === undefined ? 'guest_code' : 'guest_number']: val}, () => {
				if (this.state.guest_number.length >= 10 && this.state.guest_code && !this.state.loading) {
					this.setState({ loading: true });
					this.testPhone();
				}
			});
		}
	};

	setDefaultValue = () => {
		this.props.dispatch(change('offerForm', 'phone_number', this.state.guest_number));
		this.props.dispatch(change('offerForm', 'code', this.state.guest_code));
	};

	onChange = token => {
		this.props.dispatch(change('addCargo', 'recaptchaValue', token));
	};

	render () {

		const { role } = this.props;

		let { loading, formType } = this.state;

		if (role !== 'guest') return false;

		return (
			<Panel className={style.customAuthPanel}>

				{
					// !loading && formType !== '' &&
					<div style={{ marginBottom: '15px' }}>{ Refresh(style.refresh_icon) } Вернуться ко вводу телефона</div>
				}

				{
					loading &&
					<Loader className={style.loaderNoMargin} />
				}

				{
					formType === 'SignIn' &&
					<div style={{ maxWidth: '360px', marginBottom: '-20px' }}>
						<OfferAuth/>
					</div>
				}

				{
					formType === 'SignUp' &&
					<div style={{ maxWidth: '600px' }}>
						<OfferRegistration onChange={this.onChange} />
					</div>
				}

				{
					!loading && formType === '' &&
					<Grid col="form-2">
						<InputSelectGroup>
							<Field
								name="guest_number"
								onBlur={this.handleChangePhone}
								type="text"
								component={Input}
								label="Телефон"
								mask="9999 99 99 99"
								containerGroup="left"
							/>
							<Field
								onChange={this.handleChangePhone}
								name="guest_code"
								component={Select}
								childGroup="left"
							>
								<OptionSelect value="7">+7</OptionSelect>
								<OptionSelect value="375">+375</OptionSelect>
								<OptionSelect value="98">+98</OptionSelect>
							</Field>
						</InputSelectGroup>
					</Grid>
				}

			</Panel>
		)
	}
 */
