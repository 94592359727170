import { LabelError } from 'components/UI/Form/Labels';
import Tooltip from 'components/UI/Tooltip';
import getWordEnd from 'libs/getWordEnd';
import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';
import style from './style.less';

export default class Input extends React.PureComponent {
    static propTypes = {
        input: PropTypes.object,
        meta: PropTypes.object,
        type: PropTypes.string,
        label: PropTypes.string,
        mask: PropTypes.string,
        hint: PropTypes.string,
        unit: PropTypes.any,
        onInput: PropTypes.func,
        left: PropTypes.number,
        placeholder: PropTypes.string,
        classNameContainer: PropTypes.string,
        classNameInput: PropTypes.string,
        isGroup: PropTypes.string,
        childGroup: PropTypes.string,
        disabled: PropTypes.bool,
        showError: PropTypes.bool
    };
    static defaultProps = {
        input: {},
        meta: {},
        type: 'text',
        label: '',
        mask: '',
        hint: '',
        unit: [],
        left: -1,
        placeholder: '',
        classNameContainer: '',
        classNameInput: '',
        containerGroup: 'none',
        childGroup: 'none',
        disabled: false,
        showError: true
    };
    state = { focus: false };
    onChange = e => {
        const { input, left } = this.props;
        if (!(left !== -1 && Number(left - e.target.value.length) < 0))
            input.onChange(e);
    };
    inputRef = undefined;
    handleFocus = () => {
        this.setState({ focus: true });
    };
    handleBlur = () => {
        this.setState({ focus: false });
    };

    componentDidMount() {
        this.inputRef.addEventListener('focus', this.handleFocus, false);
        this.inputRef.addEventListener('blur', this.handleBlur, false);
    }

    componentWillUnmount() {
        this.inputRef.removeEventListener('focus', this.handleFocus, false);
        this.inputRef.removeEventListener('blur', this.handleBlur, false);
    }

    render() {
        const {
                input, meta, type, label, mask, placeholder, classNameContainer, classNameInput, unit, hint,
                containerGroup, childGroup, onInput, maxLength, disabled, showError
            } = this.props,
            { focus } = this.state;

        if (typeof(onInput) === 'function') input.onInput = onInput;
        if (maxLength && Number.isInteger(maxLength)) input.maxLength = maxLength;

        const container = `
			${style.field__flex_container} 
			${classNameContainer} 
			${focus ? style.focus : ''}
			${input.value ? style.not_empty : ''}
			${meta.error ? style.error : ''}
			${label === '' ? style.noLabel : ''}
			${disabled ? style.disabled : ''}
			${containerGroup !== 'none' ? style[containerGroup] : ''}
			${childGroup !== 'none' ? `${style.sub_container} ${style.input_sub_container} ${style[childGroup]}` : ''}
		`;
        const container_input = `${style.field__input} ${classNameInput}`;
        let leftSymbols = 0;
        if (this.props.left !== -1)
            leftSymbols = Number(this.props.left - input.value.length);

        const errorCheck = meta.error !== null && meta.error !== undefined;

        return (
            <React.Fragment>
                <div className={container}>
                    {hint.length > 0 && <Tooltip text={hint} className={style.tooltip}/>}
                    <div className={container_input}>
                        <InputMask
                            {...input}
                            inputRef={ref => this.inputRef = ref}
                            onChange={value => this.onChange(value)}
                            value={input.value}
                            type={type}
                            mask={mask}
                            disabled={disabled}
                            placeholder={placeholder}
                        />
                        <label>{label}</label>
                    </div>
					{
					    childGroup === 'none' &&
                        <React.Fragment>
                            {
                                (meta.error && showError)
                                && <div className={`${style.show_state} ${(unit.length > 0 || this.props.left !== -1) && style.rIcon} ${style.show_error}`}>!</div>
                            }
                        </React.Fragment>
					}
                    {unit.length > 0 &&
                    <div className={style.unit}>
                        {
                            typeof (unit) === 'string' ? unit : unit.map((el, i) => {
                                return <React.Fragment key={i}>{el}</React.Fragment>
                            })
                        }
                    </div>
                    }
                    {this.props.left !== -1 &&
                    <div className={style.leftSymbols}>
                        {leftSymbols} {getWordEnd(leftSymbols, ['символ', 'символа', 'символов'])}
                    </div>
                    }
                </div>
                {
                    showError
                    &&  <LabelError message={errorCheck && meta.error} isOn={errorCheck}/>
                }

            </React.Fragment>
        );
    }
}