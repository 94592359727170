exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._396LGZ6y7fUeZi84EzeDh7 {\n  font-size: 20px;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/UI/ProfileUI/UserName/style.less"],"names":[],"mappings":"AAAA;EACE,gBAAgB;CACjB","file":"style.less","sourcesContent":[".username {\n  font-size: 20px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"username": "_396LGZ6y7fUeZi84EzeDh7"
};