import React from 'react';
import styles from './styles.less';
import PropTypes from 'prop-types';
import { LabelError } from 'components/UI/Form/Labels';
import { getItem } from 'libs/localStorage';
import { getItem as sGetItem } from 'libs/sessionStorage';
import { add } from 'actions/notifications';
import Button from 'components/UI/Form/Button';
import { PhotoCamera, PhotoCameraEdit, Rubbish } from 'components/UI/Icons';


export default class AvatarUpload extends React.Component {

	inputForFile = undefined;

	state = {
		image: this.props.input.value ? this.props.input.value : {},
	};

	static propTypes = {
		meta: PropTypes.object,
	};

	static defaultProps = {
		meta: {},
	};

	handleDeleteFile = () => {
		this.props.input.onChange({ images: null });
		this.setState({ image: {} });
	};

	handleSaveFile = event => {
		if (event.target.files[0] === undefined) return false;
		let token = getItem('token', false) ? getItem('token') : sGetItem('token');
		const xhr = new XMLHttpRequest();
		xhr.open('PUT', '/api/1.0/my/person-photo', true);
		xhr.setRequestHeader('If-None-Match', '*');
		xhr.setRequestHeader('If-Modified-Since', 'Mon, 26 Jul 1997 05:00:00 GMT');
		xhr.setRequestHeader('Cache-Control', 'no-cache');
		xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
		xhr.setRequestHeader('Content-Disposition', 'attachment; filename="' + encodeURI(event.target.files[0].name) + '"');
		xhr.setRequestHeader('Authorization', 'Bearer ' + token);
		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				if (xhr.status === 200) {
					let image = JSON.parse(xhr.responseText);
					this.setState({ image, }, () => {this.props.input.onChange({ images: image })})
				} else {
					this.props.meta.dispatch(add({
						time: 5,
						type: 'error',
						text: 'Ошибка загрузки файла',
					}));

				}
			}
		};
		xhr.send(event.target.files[0]);
	};

	render() {
		let { meta } = this.props;
		let { image } = this.state;
		const id = 'upload:image:to:server:' + (new Date()).getMilliseconds();

		return (
			<div className={styles.container}>
				{
					image.id &&
					<div className={styles.image}>
						<img src={image.images[0] ? image.images[0].url : '#'} alt={'image#' + image.id} />
						<div className={styles.actions}>
							<Button
								onClick={this.handleDeleteFile}
								clearStyle
								className={styles.btn}
							>
								{ Rubbish() }
							</Button>
							<label
								className={styles.btn}
								htmlFor={id}
							>
								{ PhotoCameraEdit() }
							</label>
						</div>
					</div>
				}
				{
					!image.id &&
					<label className={styles.imageEmpty} htmlFor={id}>
						{ PhotoCamera() }
						<b>Загрузить фотографию</b>
						<p>Размер файла не должен превышать 3Мб</p>
					</label>
				}
				{
					meta.error
					&& <LabelError message={meta.error} />
				}
				<input
					id={id}
					type={'file'}
					accept={'image/*'}
					onChange={this.handleSaveFile}
					ref={el => this.inputForFile = el}
				/>
			</div>
		)
	}
}