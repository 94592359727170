import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import GrayBackground from 'components/UI/Wrappers/GrayBackground';
import Container from 'components/UI/Wrappers/Container';
import Grid from 'components/UI/Wrappers/Grid';
import showError from 'libs/showFormErrors';
import { required } from 'libs/validationsForms';
import { recovery } from 'actions/user';
import { add } from 'actions/notifications';
import { SubmissionError } from 'redux-form';
import RecoveryPassword from 'components/ReduxForms/RecoveryPassword';
import ChangePassword from 'components/ReduxForms/ChangePassword';
import { getProfileType } from 'libs/selections';
import style from './style.less';
import ConfirmPhone from './ConfirmPhone';
import Button from 'components/UI/Form/Button';
import { setItem, getItem, removeItem } from 'libs/localStorage';
import forwardTo from 'libs/forwardTo';
import { submitChangePassword } from 'components/Profile/EditProfile/submit';
import { getDataAfterPassword } from './submit';

class Recovery extends React.PureComponent {

	state = {
		step: getItem('recovery_token', false) ? 3 : getItem('phone', false) ? 2 : 1
		// step: 2
	};

	handleSubmitEmail = values => {
		showError({
			phoneNumber: [required(values.phoneNumber)],
		});

		const phone = values.recovery_code + values.phoneNumber.split(' ').join('');
		return new Promise((resolve, reject) => {
			this.props.dispatch(recovery({ phone, recaptchaValue: values.recaptchaValue }, {
				resolve: resolve,
				reject: reject,
			}));
		}).then(() => {
			this.setState({ step: 2 });
			setItem('phone', phone);
		}).catch(err => {
			this.props.dispatch(add({
				time: 10,
				type: 'error',
				text: 'Не удалось отправить код востановления пароля',
			}));
			throw new SubmissionError({
				...err,
				_error: 'bad'
			})
		});
	};

	handleSubmitPassword = values => {
		showError({
			newPassword: [required(values.newPassword)],
			newPasswordAgain: [required(values.newPasswordAgain)],
		});

		setItem('token', getItem('recovery_token'));
		return submitChangePassword(values, this.props.dispatch, () => {
			return getDataAfterPassword(this.props.dispatch, () => {
				removeItem('recovery_token');
				removeItem('phone');
				forwardTo('/edit/profile/');
			});
		});
	};

	goToForm = () => {
		removeItem('phone');
		this.setState({ step: 1 });
	};

	render() {
		if (this.props.role !== 'guest')
			return <Redirect to="/edit/profile" />;

		const { step } = this.state;

		return (
			<GrayBackground>
				<Container>
					<Grid className={style.container} col="2">
						<div className={style.content}>
							<h2>
								{ (step === 1 || step === 3) && 'Вспомнили пароль?' }
								{ step === 2 && 'Неправильно ввели телефон?' }
							</h2>
							<p>
								{ (step === 1 || step === 3) && 'Войдите с помощью регистрационных данных и получите доступ к сервису грузоперевозок' }
								{ step === 2 && 'Вы можете изменить его на предыдущем шаге и снова отправить sms код' }
							</p>
							{
								(step === 1 || step === 3) &&
									<Link to={'/auth'} className={style.link}>
										<Button color="white-reg" size="large">Войти</Button>
									</Link>
							}
							{
								step === 2 &&
								<Button color="white-reg" size="large" onClick={this.goToForm}>Назад</Button>
							}
						</div>
						<div className={`${style.content} ${style.form}`}>
							{
								step === 1 &&
								<RecoveryPassword onSubmit={this.handleSubmitEmail} />
							}
							{
								step === 2 &&
								<ConfirmPhone dispatch={this.props.dispatch} success={() => this.setState({ step: 3 })} />
							}
							{
								step === 3 &&
								<ChangePassword onSubmit={this.handleSubmitPassword} />
							}
						</div>
					</Grid>
				</Container>
			</GrayBackground>
		);
	}
}

export default connect(
	store => ({
		role: getProfileType(store),
	})
)(Recovery);
