import { dateToString } from 'libs/convertDate';

export const getRouteTitle = route => {
	let title = 'Запланированный маршрут';
	if (route.type === 'desired')
		title = 'Желаемый маршрут';
	return title;
};

export const getDataPage = (id, date, type) => {
	return `${type === 'route' ? 'Маршрут' : 'Заявка'} №${id} от ${dateToString(new Date(date), '')}г`;
};