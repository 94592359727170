import React from 'react';
import BecomeCarrierRegForm from 'components/ReduxForms/BecomeCarrierRegForm';
import bg from './bg.png';
import style from './style.less';
import Container from 'components/UI/Wrappers/Container';
import forwardTo from 'libs/forwardTo';

class MainScreen extends React.Component {
	handleSubmit = values => {
		window.registration_driver = values;
		forwardTo('/registration/driver');
	};

	render() {
		
		return (
			<div className={style.main__screen}>
				<div className={style.bg}>
					<img src={bg} alt="" />
				</div>
				<Container className={style.relative}>
					<h1>Поиск выгодных заказов по всей России</h1>
					<p>Зарабатывайте больше с HurryLorry — без простоев, с максимальной эффективностью. Выбирайте подходящие
						направления и работайте с гарантией оплаты</p>
					{
						this.props.role === 'guest' &&
						<BecomeCarrierRegForm
							onSubmit={this.handleSubmit}
						/>
					}
				</Container>
			</div>
		)
	}
}

export default MainScreen;
