const routeServerToClient = serverData => {

    //разложим основные поля
    let result = {
        ...serverData,
        id: serverData.id,
        transportId: serverData.transport.id,
        distance: serverData.distance,
        price: parseInt(serverData.price),
        items: [],
    };
    
    if (serverData.bodyHeight) {
        result.cargo = {
            width: serverData.bodyWidth,
            height: serverData.bodyHeight,
            length: serverData.bodyLength
        }
    }

    //добавим адреса точек маршрута
    const countItems = serverData.items.length;
    result.items = serverData.items.map((it, index) => {
        if (index === 0 || index === countItems - 1) {
            return {
                key: index === 0 ? 'A' : 'B',
                startDate: new Date(it.startDate),
                point: {
                    pos: it.latitude + ' ' + it.longitude,
                    name: it.address,
                    key: index === 0 ? 'A' : 'B',
                    country: it.country,
                    region: it.region,
                    locality: it.locality
                }
            }
        } else {
            return {
                key: 'B' + index,
                point: {
                    pos: it.latitude + ' ' + it.longitude,
                    name: it.address,
                    key: 'B' + index,
                    country: it.country,
                    region: it.region,
                    locality: it.locality
                }
            }
        }
    });

    return result;
};

export default routeServerToClient;