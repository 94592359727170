import { chatActions } from 'actions/chat';
import DialogChat from 'components/Chat';
import { getProfileData } from 'components/Profile/Detail/getData';
import ProfileCustomer from 'components/Profile/Detail/ProfileCustomer';
import ProfileDriver from 'components/Profile/Detail/ProfileDriver';
import ProfileReviews from 'components/Profile/Detail/ProfileReviews';
import ProfileRight from 'components/Profile/Detail/ProfileRight';
import style from 'components/Profile/ProfileView/style.less';
import Loader from 'components/UI/_V2/Loader';
import Grid from 'components/UI/Wrappers/Grid';
import Page from 'components/UI/Wrappers/Page';
import { getMyRoutes, getProfile, getProfileDetail } from 'libs/selections';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';


class ProfileDetail extends Component {

    state = {
        loading: true,
        chat: false,
    };

    loadProfile = async () => {
        this.setState({ loading: true });

        await getProfileData(this.props.dispatch, this.props.match.params.id, () => {
            this.setState({ loading: false });
        });
    };

    toggleChat = (chat = true) => {
        if (chat) {
            this.props.dispatch(chatActions.openDialog({
                user1: this.props.profile,
                user2: { id: this.props.myID },
            }));
        }
        this.setState({ chat });
    };

    async componentWillMount() {
        await this.loadProfile();
    }

    render() {
        const { profile } = this.props;
        let title = this.state.loading ? null : profile ? `${profile.firstName} ${profile.lastName}` : 'Сожалеем';

        return (
            <Page title={title}>
                <Grid col="9-3">

                    <div className={style.profile}>
                        {
                            this.state.loading && <Loader/>
                        }
                        {
                            !this.state.loading && !profile &&
                            <p>
                                Пользователь не найден
                            </p>
                        }
                        {
                            !this.state.loading && profile &&
                            <Fragment>
                                {
                                    profile.role === 'customer' &&
                                    <ProfileCustomer
                                        {...profile}
                                        toggleChat={this.toggleChat}
                                    />
                                }
                                {
                                    profile.role === 'driver' &&
                                    <ProfileDriver
                                        {...profile}
                                        toggleChat={this.toggleChat}
                                    />
                                }
                            </Fragment>
                        }

                        {
                            !this.state.loading && profile &&
                            <ProfileReviews
                                role={profile.role}
                                id={this.props.match.params.id}
                            />
                        }

                    </div>

                    <div className={style.profile}>
                        {
                            this.state.loading && <Loader/>
                        }
                        {
                            !this.state.loading && profile &&
                            <ProfileRight
                                {...profile}
                            />
                        }
                    </div>

                </Grid>
                {
                    this.state.chat &&
                    <DialogChat handleClose={() => this.toggleChat(false)}/>
                }
            </Page>
        )
    }
}


export default connect(
    state => ({
        myID: getProfile(state).id,
        profile: getProfileDetail(state),
        routes: getMyRoutes(state),
    })
)(ProfileDetail);