import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Input from 'components/UI/Form/Fields/Input';
import Button from 'components/UI/Form/Button';

const InsureAdminForm = (props) => {

	return (
		<form onSubmit={props.handleSubmit}>
			<div style={{ marginBottom: '10px', marginTop: '15px' }}>
				<Field
					name="customerInsuranceNumber"
					label="Код страхования для клиента"
					component={Input}
				/>
			</div>
			<div style={{ marginBottom: '10px' }}>
				<Field
					name="driverInsuranceNumber"
					label="Код страхования для водителя"
					component={Input}
				/>
			</div>
			<Button
				type="submit"
			>
				Сохранить
			</Button>
		</form>
	)
};

InsureAdminForm.propTypes = {
	handleSubmit: PropTypes.func,
	error: PropTypes.string,
	submitting: PropTypes.bool,
	invalid: PropTypes.bool,
};

InsureAdminForm.defaultProps = {
	handleSubmit: () => {},
	invalid: false,
	submitting: false,
	error: '',
};

export default reduxForm({
	form: 'insureAdmin',
})(InsureAdminForm);
