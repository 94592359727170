import React from 'react';
import {connect} from 'react-redux';
import Grid from 'components/UI/Wrappers/Grid';
import {getProfileType, getPoints, getDistance} from 'libs/selections';
import Page from 'components/UI/Wrappers/Page';
import Maps from 'components/UI/Maps';
import AddCargoForm from 'components/ReduxForms/AddCargoForm';
import * as sessionStorage from 'libs/sessionStorage';
import ConfirmPhoneBySMS from 'components/ConfirmPhoneBySMS';
import style from './style.less';
import * as localStorage from 'libs/localStorage';
import {submitRegistrationUserForCargo, submitRequestCargo} from './submit.js';
import {cargoFormToRequestServer} from 'libs/maper';
import submitSingIn from 'components/Auth/submit';
import showError from 'libs/showFormErrors';
import { required, cargoPrice, datePickerValue, loadDate } from 'libs/validationsForms';
import Dialog from 'components/UI/Dialog';

class AddCargo extends React.PureComponent {

	state = {
		showSmsModal:
			localStorage.getItem('phone', false) &&
			sessionStorage.getItem('offerForm', false),
		valuesToServer: {},
		submitType: 'default',
	};

	componentWillUnmount() {
		sessionStorage.removeItem('offerForm');
	}

	handleSubmit = values => {
		const { dispatch, distance } = this.props;
		values = { ...values, route: { ...values.route, distance: distance ? parseInt(distance) : null } };
		let validateValues = {};

		if (distance) validateValues['price'] = [cargoPrice(values.price, Math.round(distance * 23))];


		validateValues = {
			'cargo.title': [required(values.cargo.title)],
			'cargo.category': [required(values.cargo.category)],
			'cargo.weight': [required(values.cargo.weight)],
			'cargo.weightCount': [required(values.cargo.weightCount)],
			'cargo.package': [required(values.cargo.package)],
			'carBodyType': [required(values.carBodyType)],
			'carLoadType': [required(values.carLoadType)],
		};

		values.route.items.forEach((point, index) => {
			validateValues[`route.items.${index}.address`] = [required(values.route.items[index].address)];
			validateValues[`route.items.${index}.timeIntervalLoad`] = [required(values.route.items[index].timeIntervalLoad)];
			validateValues[`route.items.${index}.providerName`] = [required(values.route.items[index].providerName)];
			validateValues[`route.items.${index}.providerPhone`] = [required(values.route.items[index].providerPhone)];
			validateValues[`route.items.${index}.startDate`] = [
				required(values.route.items[index].startDate),
				datePickerValue(values.route.items[index].startDate),
				loadDate(
					values.route.items[index - 1] !== undefined ? values.route.items[index - 1].startDate : values.route.items[index].startDate,
					values.route.items[index - 1] !== undefined && values.route.items[index].startDate
				)];
		});
		showError(validateValues);
		sessionStorage.setItem('offerForm', JSON.stringify(values));

		return submitRequestCargo(cargoFormToRequestServer(values), dispatch);
	};

	setSubmitType = submitType => this.setState({ submitType });

	toggleDialog = show => this.setState({ showSmsModal: show });

	afterRegistration = () => {
		this.setState({ showSmsModal: false });
		return this.state.valuesToServer;
	};

	render() {
		const { showSmsModal } = this.state;
		let initialValues = JSON.parse(sessionStorage.getItem('offerForm', '{}'));
		let additionals = [];
		if (initialValues.cargo !== undefined && initialValues.cargo.additionIds.length > 0) {
			initialValues.cargo.additionIds.forEach(el => {
				additionals.push(el.additionId.id);
			});
		}

		initialValues = {
			...initialValues,
			toInsurance: 0,
			carLoadType: initialValues.loadTypeId.id,
			carBodyType: initialValues.bodyTypeId.id,
			route: {
				distance: initialValues.route ? initialValues.route.distance : 0,
				items: this.props.points.map(el =>
					Object.assign({}, el, () => {
						if (initialValues.route && Array.isArray(initialValues.route.items))
							return initialValues.route.items.find(it => it.key === el.key) || {};
						else return {};
					})),
				...initialValues.route,
			},
			cargo: {
				...initialValues.cargo,
				category: initialValues.cargo.categoryId.id,
				package: initialValues.cargo.packageId ? initialValues.cargo.packageId.id : null,
				weight: initialValues.cargo !== undefined ? initialValues.cargo.weight >= 1000 ? initialValues.cargo.weight / 1000 : initialValues.cargo.weight : '',
				weightType: initialValues.cargo !== undefined ? initialValues.cargo.weight >= 1000 ? 1000 : 1 : 1000,
				additionals,
			},
			price: initialValues.price ? initialValues.price : 0,
		};
		delete initialValues['passwordAgain'];
		return (
			<Page title={initialValues.id ? `Редактирование заявки №${initialValues.id}` : 'Создание заявки на перевозку'}>
				<Grid className={style.marginAfterPanel} col="9-3">
					<div>
						<AddCargoForm
							initialValues={initialValues}
							onSubmit={this.handleSubmit}
							setSubmitType={this.setSubmitType}
						/>
					</div>
					<div>
						<Maps />
					</div>
				</Grid>
				{
					showSmsModal &&
					<Dialog handleClose={()=>this.toggleDialog(false)}>
						<ConfirmPhoneBySMS
							dispatch={this.props.dispatch}
							success={this.afterRegistration}
						/>
					</Dialog>
				}
			</Page>
		);
	}
}

export default connect(
	store => ({
		role: getProfileType(store),
		points: getPoints(store),
		distance: getDistance(store)
	})
)(AddCargo);
