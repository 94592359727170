export const filter = (val, arr, id) => {
	return arr.filter(el => {
		if (el.arbitrationId !== null)
			return ('арбитраж №' + el.arbitrationId).indexOf(val.toLowerCase()) > -1;
		
		if (id === el.user1.id) {
			return (
				el.user2.firstName.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
				el.user2.lastName.toLowerCase().slice(0,1).indexOf(val.toLowerCase()) > -1
			);
		} else {
			return (
				el.user1.firstName.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
				el.user1.lastName.toLowerCase().slice(0,1).indexOf(val.toLowerCase()) > -1
			);
		}
	});
};