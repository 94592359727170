import * as serverHelpersActions from 'actions/serverHelpersActions';
import request from 'libs/request';
import { call, put } from 'redux-saga/effects';


export function* getCargoList(action) {

    const response = yield call(() =>
      request('get', '/api/1.0/driver/cargo/categories'));

    if (response.data.errors || response.status !== 200) {
        console.log(response)
    } else {
        yield put(serverHelpersActions.setCargoList(response.data));
    }
}

export function* getPackageList(action) {

    const response = yield call(() =>
      request('get', '/api/1.0/driver/cargo/packages'));

    if (response.data.errors || response.status !== 200) {
        console.log(response)
    } else {
        yield put(serverHelpersActions.setPackageList(response.data));
    }
}

export function* getBodyTypesList(action) {

    const response = yield call(() =>
      request('get', '/api/1.0/driver/body-types'));

    if (response.data.errors || response.status !== 200) {
        console.log(response)
    } else {
        yield put(serverHelpersActions.setBodyTypeList(response.data));
    }
}

export function* getLoadTypesList(action) {

    const response = yield call(() =>
      request('get', '/api/1.0/driver/load-types'));

    if (response.data.errors || response.status !== 200) {
        console.log(response)
    } else {
        yield put(serverHelpersActions.setLoadTypeList(response.data));
    }
}

export function* getAdditionalsList(action) {

    const response = yield call(() =>
      request('get', '/api/1.0/driver/additions'));

    if (response.data.errors || response.status !== 200) {
        console.log(response)
    } else {
        yield put(serverHelpersActions.setAdditionalList(response.data));
    }
}

export function* getCounterList(action) {
    const response = yield call(() =>
        request('get', '/api/1.0/count/all'));

    if (response.data.errors || response.status !== 200) {
        console.log(response)
    } else {
        yield put(serverHelpersActions.setCounterList(response.data));
    }
}
