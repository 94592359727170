import React from 'react';
import PropTypes from 'prop-types';
import style from 'components/Pages/Trades/Detail/styles.less';


const StepContainer = props => {
	const { step, title, children, isActive } = props;
	let renderClass = style.step;
	if (isActive) renderClass += ` ${style.active}`;

	return (
		<div className={renderClass}>
			<div className={style.badge}>{ step } этап</div>
			<div className={style.content}>
				<h3>{ title }</h3>
				<div>{ children }</div>
			</div>
		</div>
	);
};


StepContainer.propTypes = {
	step: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	children: PropTypes.node,
	isActive: PropTypes.bool.isRequired,
};
StepContainer.defaultProps = {
	children: '',
};


export default StepContainer;