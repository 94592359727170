import TradeItem from "components/Admin/Pages/Trades/List/TradeItem";
import NotFound from 'components/Admin/UI/List/NotFound';
import listStyle from 'components/Admin/UI/List/style.less';
import AdminLoader from 'components/Admin/UI/Loader';
import React, { Fragment } from 'react';


const TradeContent = props => (
    <Fragment>
        {
            props.loading &&
            <AdminLoader className={listStyle.loading}/>
        }
        {
            !props.loading && props.trade.total > 0 &&
            <TradeItem trade={props.trade.items} link={props.link}/>
        }
        {
            !props.loading && props.trade.total === 0 &&
            <NotFound style={{ paddingLeft: '20px' }}/>
        }
    </Fragment>
);


export default TradeContent;