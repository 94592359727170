import React from 'react';
import styles from 'components/Pages/Trades/Detail/styles.less';
import PropTypes from 'prop-types';
import { dateToString, stringToDate } from 'libs/convertDate';
import Aside from 'components/UI/Wrappers/Aside';
import AsideItem from 'components/UI/AsideItems/AsideItem';
import AsideYourBid from 'components/UI/AsideItems/AsideYourBid';
import AsideOfferStatus from 'components/UI/AsideItems/AsideOfferStatus';
import Checkbox from 'components/UI/Form/Fields/Checkbox';
import Button from 'components/UI/Form/Button';
import { offersActions } from 'actions/offers';
import { cancelTrade, arbitrationTrade } from 'libs/statusActions';

class Controls extends React.Component {

	state = { acceptBtn: false };

	static propTypes = {
		status: PropTypes.string.isRequired,
		role: PropTypes.string.isRequired,
		price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		toggleChat: PropTypes.func.isRequired,
		toggleDialog: PropTypes.func.isRequired,
	};

	handleAccess = () => this.setState({ acceptBtn: !this.state.acceptBtn });

	acceptTrade = () => { this.props.dispatch(offersActions.acceptOfferById(this.props.tradeID)) };

	render() {
		let { price, status, role, downloadContract, toggleChat, toggleDialog } = this.props;
		return (
			<Aside marginBottom={30}>

				<AsideYourBid price={price} text="Сумма сделки"/>

				{
					status !== 'demo' &&
					<AsideItem onClick={downloadContract}>
						Договор №{this.props.tradeID}
						<span> от { dateToString(stringToDate(this.props.createTime), 'stringDate') }</span>
					</AsideItem>
				}

				<AsideItem onClick={toggleChat}>Написать в чат</AsideItem>

				{
					status !== 'demo' &&
					<AsideItem url={'/feedback/'}>Проблемы со сделкой?</AsideItem>
				}

				{
					status !== 'demo' &&
					cancelTrade.indexOf(status) !== -1 &&
					<AsideItem onClick={() => toggleDialog('dialogCancelTrade')}>Отменить сделку</AsideItem>
				}

				{
					arbitrationTrade.indexOf(status) !== -1 &&
					<AsideItem
						redLnk
						onClick={() => toggleDialog('dialogArbitrate')}
					>
						Отправить жалобу
					</AsideItem>
				}

				<AsideOfferStatus status={status} role={role} />

				{
					role === 'customer' && status === 'demo' &&
					<div className={styles.agree}>
						<Checkbox
							input={{ onChange: this.handleAccess }}
							label={[`Я согласен с `, <a target="_blank" href="/agreement_hl.pdf">Правилами сайта</a>]}
						/>
						<p>
							Нажимая на кнопку «Разместить заявку», вы соглашаетесь с правилами платежного сервиса
							«Название сервиса» и сервиса «Сделка без риска». Ваш банк может взымать дополнительную
							комиссию.
						</p>
						<Button
							size={'large'}
							color={'green'}
							disabled={!this.state.acceptBtn}
							onClick={() => toggleDialog('dialogTrade')}
						>
							Заключить сделку
						</Button>
					</div>
				}

				{
					role === 'driver' && (status === 'created' || status === 'edited') &&
					<div className={styles.agree}>
						<Checkbox
							input={{ onChange: this.handleAccess }}
							label={[`Я согласен с `, <a target="_blank" href="/agreement_hl.pdf">Правилами сайта</a>]}
						/>
						<p>
							Нажимая на кнопку «Разместить заявку», вы соглашаетесь с правилами платежного сервиса
							«Название сервиса» и сервиса «Сделка без риска». Ваш банк может взымать дополнительную
							комиссию.
						</p>
						<Button
							color={'green'}
							disabled={!this.state.acceptBtn}
							onClick={this.acceptTrade}
						>
							Согласен с условиями договора
						</Button>
						<Button
							className={styles.mb10}
							color={'white'}
							onClick={() => toggleDialog('dialogChangeContract')}
						>
							Редактирование договора
						</Button>
					</div>
				}

			</Aside>
		);
	}
}

export default Controls;
