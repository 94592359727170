exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ZyOpx1b_PESFGf5nffDbk {\n  display: flex;\n  margin-top: 20px;\n}\n._3UVqGojyd5h2HJcERzYSZ8 {\n  width: 211px;\n  height: 271px;\n  border-radius: 8px;\n  object-fit: cover;\n  margin-left: calc(100% - 211px);\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/Admin/Pages/Drivers/Detail/style.less"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;CAClB;AACD;EACE,aAAa;EACb,cAAc;EACd,mBAAmB;EACnB,kBAAkB;EAClB,gCAAgC;CACjC","file":"style.less","sourcesContent":[".button_group {\n  display: flex;\n  margin-top: 20px;\n}\n.profile_photo {\n  width: 211px;\n  height: 271px;\n  border-radius: 8px;\n  object-fit: cover;\n  margin-left: calc(100% - 211px);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"button_group": "ZyOpx1b_PESFGf5nffDbk",
	"profile_photo": "_3UVqGojyd5h2HJcERzYSZ8"
};