import React, { Fragment } from 'react';
import { weightConversionShort } from 'libs/weightConversion';
import { getVolume } from 'libs/getVolume';
import PropTypes from 'prop-types';

const PageCargoTitle = ({ cargo }) => {
	const volume = { width: cargo.width, height: cargo.height, length: cargo.length };
	return (
		<Fragment>
			{ cargo.categoryId && `${cargo.categoryId.titleRu}, ` }
			{ weightConversionShort(cargo.weight) },&nbsp;
			{ getVolume(volume)}м<sup>3</sup>
		</Fragment>
	)
};

PageCargoTitle.propTypes = {
	cargo: PropTypes.object.isRequired,
};

export default PageCargoTitle;
