import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Tick } from 'components/UI/Icons';
import style from './style.less';
import { LabelError } from 'components/UI/Form/Labels';

class Checkbox extends PureComponent {

	static propTypes = {
		label: PropTypes.any,
		className: PropTypes.string,
		onCustomChange: PropTypes.func,
		meta: PropTypes.object,
	};

	static defaultProps = {
		label: [],
		className: '',
		onCustomChange: () => {},
		meta: {
			error: null,
		}
	};

	state = {
		value: this.props.input.value,
	};

	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({ value: nextProps.input.value });
	}

	onHandleChange = e => {
		this.setState({ value: e.target.checked });
		this.props.input.onChange(e.target.checked);
		this.props.onCustomChange(e.target.checked);
	};

	render() {

		const { className, meta, label } = this.props;
		const errorCheck = meta.error !== null && meta.error !== undefined;
		return (
			<div>
				<label className={`${style.checkbox} ${className ? className : ''}`}>

					<input
						checked={this.state.value}
						onChange={e => this.onHandleChange(e)}
						type="checkbox"
					/>

					<div className={style.checkbox_state}>
						<Tick />
					</div>

					<div className={style.checkbox_label}>
						{
							typeof (label) === 'string' ?
								label :
								label.map((el, i) => <Fragment key={i}>{el}</Fragment>
							)
						}
					</div>

				</label>
				<div style={{ marginTop: '10px' }}>
					<LabelError message={meta.error} isOn={errorCheck} />
				</div>
			</div>
		)
	}
}



export default Checkbox;
