import React from 'react';
import Dialog from 'components/UI/Dialog';
import dialogStyles from 'components/UI/Dialog/style.less';
import Button from 'components/UI/Form/Button';

export const RouteDelete = ({ acceptAction, cancelAction }) => {
	return (
		<Dialog handleClose={cancelAction}>
			<h3 className={dialogStyles.textcenter}>
				Вы действительно хотите удалить этот маршрут?
			</h3>
			
			<div className={dialogStyles.actions}>
				<Button color={'red'} onClick={acceptAction}>
					Удалить
				</Button>
				
				<Button color={'white'} onClick={cancelAction}>
					Отменить
				</Button>
			</div>
		</Dialog>
	)
};