import React from 'react';
import styles from './style.less';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import AvatarUpload from 'components/UI/Form/Fields/AvatarUpload';

const selector = formValueSelector('uploadAvatar');

class UploadAvatarForm extends React.Component {

	static propTypes = {
		handleSubmit: PropTypes.func,
		error: PropTypes.string,
		submitting: PropTypes.bool,
		invalid: PropTypes.bool,
		className: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	};

	static defaultProps = {
		handleSubmit: () => {
		},
		invalid: false,
		submitting: false,
		error: '',
		className: false,
	};

	changePhoto = photo => {
		this.props.onChangePhoto(photo);
	};

	deletePhoto = () => {
		// console.log(this.props.photoId.images);
		this.props.photoId.images = null;
		this.props.onChangePhoto({ images: null });
	};

	render() {
		const { handleSubmit, className } = this.props;

		return (
			<div className={`${styles.mobileLoadPhoto} ${className ? className : ''}`}>
				<form onSubmit={handleSubmit}>
					<Field
						type='photo'
						name='photoId'
						className={styles.imageAvatar}
						onChange={e => this.changePhoto(e)}
						component={AvatarUpload}
					/>
				</form>
			</div>
		)
	}
}


export default compose(
	reduxForm({
		form: 'uploadAvatar',
	}),
	connect(
		state => ({
			photoId: selector(state, 'photoId')
		})
	)
)(UploadAvatarForm);