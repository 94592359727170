import style from 'components/Chat/style.less';
import Button from 'components/UI/Form/Button';
import TextareaMessage from 'components/UI/Form/Fields/TextareaMessage';
import { Unlink } from 'components/UI/Icons';
import { Return } from "components/UI/Icons/index";
import Tooltip from 'components/UI/Tooltip';
import OutsideContainer from 'components/UI/Wrappers/OutsideContainer';
import { getMyActiveCargoes, getMyRoutes, getProfileType } from 'libs/selections';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change as setValue, Field, formValueSelector, reduxForm, stopSubmit } from 'redux-form';
import SelectOffers from './SelectOffers';


class ChatForm extends React.PureComponent {

    state = {
        open: false,
    };

    toggleOffer = () => this.setState({ open: !this.state.open });

    toggleWithParamOffer = open => this.setState({ open });
    submitForm = () => {
        this.toggleWithParamOffer(false);
        this.props.handleSubmit();
    };
    handleKeyDown = e => {
        if (e.ctrlKey === false && e.keyCode === 13) {
            e.preventDefault();
            this.submitForm();
        }

        if (e.ctrlKey && e.keyCode === 13) {
            let cursor = this.getCursorPos(document.querySelector('textarea[name="text"]')),
                text = this.props.text,
                startString = text.substring(0, cursor.start),
                endString = text.substring(cursor.end, text.length);

            this.props.dispatch(setValue('ChatForm', 'text', startString + '\n' + endString)); // need to finish
        }
    };
    getCursorPos = input => {
        if ("selectionStart" in input && document.activeElement === input) {
            return {
                start: input.selectionStart,
                end: input.selectionEnd
            };
        }
        else if (input.createTextRange) {
            let sel = document.selection.createRange(),
                len = 0,
                pos = 0;
            if (sel.parentElement() === input) {
                let rng = input.createTextRange();
                rng.moveToBookmark(sel.getBookmark());
                for (len = 0;
                     rng.compareEndPoints("EndToStart", rng) > 0;
                     rng.moveEnd("character", -1)) {
                    len++;
                }
                rng.setEndPoint("StartToStart", input.createTextRange());
                for (pos = { start: 0, end: len };
                     rng.compareEndPoints("EndToStart", rng) > 0;
                     rng.moveEnd("character", -1)) {
                    pos.start++;
                    pos.end++;
                }
                return pos;
            }
        }
        return -1;
    };

    componentDidMount() {
        document.querySelector('textarea').addEventListener('keydown', this.handleKeyDown, false);
    }

    componentWillUnmount() {
        document.querySelector('textarea').removeEventListener('keydown', this.handleKeyDown, false);
    }

    render() {
        let { invalid, role, cargoes, routes, offerIds } = this.props,
            offers = [];
        switch (role) {
            case 'driver':
                offers = routes;
                break;
            case 'customer':
                offers = cargoes;
                break;
            default:
                offers = [];
                break;
        }

        let tooltip = 'Прикрепить заявку';
        if (role === 'driver') tooltip = 'Прикрепить маршрут';

        return (
            <form onSubmit={e => {e.preventDefault();this.submitForm()}}
                  autoComplete="off"
                  className={`${style.form} ${(this.props.isArbitrate || this.props.role === 'driver') && style.isArbitrate } ${this.state.open && style.open}`}
            >
                {
                    (!this.props.isArbitrate || this.props.role !== 'driver') &&
                    <OutsideContainer onClickOutside={() => this.toggleWithParamOffer(false)}>
                        <SelectOffers handleClose={this.props.handleClose} className={style.offers} offers={offers} role={role}/>
                        <Button onClick={this.toggleOffer} className={style.unlink}>
                            <Unlink/>
                            {offerIds && offerIds.length > 0 &&
                            <span className={style.offerCount}>{offerIds.length}</span>}
                            <Tooltip text={tooltip} className={style.tooltip}/>
                        </Button>
                    </OutsideContainer>
                }
                <Field name="text" component={TextareaMessage} placeholder="Введите сообщение" required={false}/>
                <Button disabled={invalid} className={style.enter} type="submit">
                    <Return/>
                    Enter
                </Button>
            </form>
        );
    }
}


const selector = formValueSelector('ChatForm');
ChatForm = connect(
    store => {
        let props = {
            text: selector(store, 'text'),
            role: getProfileType(store),
            offerIds: selector(store, 'offerIds'),
        };
        if (props.role === 'driver') props.routes = getMyRoutes(store);
        if (props.role === 'customer') props.cargoes = getMyActiveCargoes(store);
        return props;
    }
)(ChatForm);

ChatForm.propTypes = {
    handleSubmit: PropTypes.func,
    error: PropTypes.string,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
};

ChatForm.defaultProps = {
    handleSubmit: () => {
    },
    invalid: false,
    submitting: false,
    error: '',
};

ChatForm = reduxForm({
    form: 'ChatForm',
    onChange: (newValues, dispatch) => {
        dispatch(stopSubmit('ChatForm', {}));
    }
})(ChatForm);

export default ChatForm;