import React from 'react';
import PropTypes from 'prop-types';
import style from './style.less';

const InputSelectGroup = ({ children }) => (
	<div className={style.InputSelectGroup}>
		{ children }
	</div>
);

InputSelectGroup.propTypes = {
	children: PropTypes.node,
};
InputSelectGroup.defaultProps = {
	children: {},
};

export default InputSelectGroup;
