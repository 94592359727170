import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import GrayBackground from 'components/UI/Wrappers/GrayBackground';
import Container from 'components/UI/Wrappers/Container';
import Grid from 'components/UI/Wrappers/Grid';
import { getItem, removeItem, setItem } from 'libs/localStorage';
import { getProfileType } from 'libs/selections';
import style from 'components/Registration/style.less';
import ConfirmForm from 'components/ReduxForms/СonfirmForm';
import showError from 'libs/showFormErrors';
import { required } from 'libs/validationsForms';
import { confirm } from 'actions/user';
import { getData } from 'actions/profile';
import { SubmissionError } from 'redux-form';
import forwardTo from 'libs/forwardTo';

class ConfirmPhoneAfterSendOffer extends PureComponent {

	state = {
		step: getItem('phone', false) ? 2 : 1
	};

	handleSubmitConfirm = values => {
		if(!values.phone)
			values.phone = getItem('phone');

		showError({
			code: [required(values.code)],
			phone: [required(values.phone)],
		});

		return new Promise((resolve, reject) => {
			this.props.dispatch(confirm({...values}, {
				resolve: resolve,
				reject: reject,
			}));
		}).then(token => {
			setItem('token', token);
			this.props.dispatch(getData());
			removeItem('phone');
		}).then(() => {
			forwardTo('/edit/profile');
		}).catch(err => {
			this.initError(err);
		});
	};

	initError = (err:Object = {}) => {
		if (typeof this.props.error === 'function') {
			this.props.error();
		}
		throw new SubmissionError({
			_error: 'Ошибка в подтверждении SMS',
			...err,
		})
	};

	render() {

		return (
			<GrayBackground left={'sms'}>
				<Container>
					<Grid className={style.container} col="2">
						<div />
						<div className={`${style.content} ${style.form}`}>

							<h1>Подтверждение телефона</h1>
							<p>На ваш номер +{ getItem('phone') } выслан код с подтверждением</p>

							<ConfirmForm
								onSubmit={this.handleSubmitConfirm}
								initialValues={{ phone: getItem('phone') }}
							/>

						</div>
					</Grid>
				</Container>
			</GrayBackground>
		);
	}
}


export default connect(
	store => ({
		role: getProfileType(store),
	})
)(ConfirmPhoneAfterSendOffer);
