exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n._1EWcpm7osUo-DSUYMbWKX2 {\n  font-size: 14px;\n  height: 44px;\n  padding-left: 25px;\n  padding-right: 25px;\n  border-radius: 4px;\n  width: 100%;\n}\n._1EWcpm7osUo-DSUYMbWKX2::-webkit-input-placeholder {\n  color: #848484;\n  opacity: 1;\n  transition: all 0.2s;\n}\n._1EWcpm7osUo-DSUYMbWKX2:-ms-input-placeholder {\n  color: #848484;\n  opacity: 1;\n  transition: all 0.2s;\n}\n._1EWcpm7osUo-DSUYMbWKX2::placeholder {\n  color: #848484;\n  opacity: 1;\n  transition: all 0.2s;\n}\n._1EWcpm7osUo-DSUYMbWKX2:focus::-webkit-input-placeholder {\n  opacity: 0.5;\n}\n._1EWcpm7osUo-DSUYMbWKX2:focus:-ms-input-placeholder {\n  opacity: 0.5;\n}\n._1EWcpm7osUo-DSUYMbWKX2:focus::placeholder {\n  opacity: 0.5;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/Admin/UI/Forms/Input/style.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,oBAAoB;EACpB,mBAAmB;EACnB,YAAY;CACb;AACD;EACE,eAAe;EACf,WAAW;EACX,qBAAqB;CACtB;AACD;EACE,eAAe;EACf,WAAW;EACX,qBAAqB;CACtB;AACD;EACE,eAAe;EACf,WAAW;EACX,qBAAqB;CACtB;AACD;EACE,aAAa;CACd;AACD;EACE,aAAa;CACd;AACD;EACE,aAAa;CACd","file":"style.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.input {\n  font-size: 14px;\n  height: 44px;\n  padding-left: 25px;\n  padding-right: 25px;\n  border-radius: 4px;\n  width: 100%;\n}\n.input::-webkit-input-placeholder {\n  color: #848484;\n  opacity: 1;\n  transition: all 0.2s;\n}\n.input:-ms-input-placeholder {\n  color: #848484;\n  opacity: 1;\n  transition: all 0.2s;\n}\n.input::placeholder {\n  color: #848484;\n  opacity: 1;\n  transition: all 0.2s;\n}\n.input:focus::-webkit-input-placeholder {\n  opacity: 0.5;\n}\n.input:focus:-ms-input-placeholder {\n  opacity: 0.5;\n}\n.input:focus::placeholder {\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"input": "_1EWcpm7osUo-DSUYMbWKX2"
};