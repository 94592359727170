import * as serverHelpersActions from 'actions/serverHelpersActions';
import { handleActions } from 'redux-actions';

const getServerHelpers = (initStore) => handleActions({
	[serverHelpersActions.setCargoList]: (state, action) => ({
		...state,
		cargoes: {
			list: action.payload
		}
	}),
	[serverHelpersActions.setPackageList]: (state, action) => ({
		...state,
		packages: {
			list: action.payload
		}
	}),
	[serverHelpersActions.setBodyTypeList]: (state, action) => ({
		...state,
		bodyTypes: {
			list: action.payload
		}
	}),
	[serverHelpersActions.setLoadTypeList]: (state, action) => ({
		...state,
		loadTypes: {
			list: action.payload
		}
	}),
	[serverHelpersActions.setAdditionalList]: (state, action) => ({
		...state,
		additionals: {
			list: action.payload
		}
	}),
	[serverHelpersActions.setCounterList]: (state, action) => ({
		...state,
		counters: {
			...state.counters,
			...action.payload
		}
	}),
}, initStore);

export default getServerHelpers;
