exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n._2fILKXqMTtxzGPfpaqKlzn {\n  margin-bottom: 45px;\n}\n._36WAFuGhKFofsTq4Fp1FDq {\n  color: #353c4b !important;\n  font-size: 15px;\n  margin-top: -10px;\n  margin-bottom: 15px!important;\n}\n._35YELMku8ztm6xZBSTTOTy {\n  background: transparent!important;\n  border: 0!important;\n  border-radius: 0!important;\n  padding: 0!important;\n  font-weight: 400!important;\n  display: inline-block !important;\n  color: #0f52de !important;\n  margin: 0;\n  height: inherit!important;\n}\n._35YELMku8ztm6xZBSTTOTy:disabled {\n  background: transparent!important;\n}\n.JPLp8ByooBYaYpflQdlOu {\n  margin-bottom: 45px;\n  margin-top: 0;\n}\n", "", {"version":3,"sources":["/Users/michael/Projects/logist/src/components/ConfirmPhoneBySMS/style.less"],"names":[],"mappings":"AAAA,YAAY;AACZ,iBAAiB;AACjB,WAAW;AACX,iBAAiB;AACjB;EACE,oBAAoB;CACrB;AACD;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,kBAAkB;EAClB,8BAA8B;CAC/B;AACD;EACE,kCAAkC;EAClC,oBAAoB;EACpB,2BAA2B;EAC3B,qBAAqB;EACrB,2BAA2B;EAC3B,iCAAiC;EACjC,0BAA0B;EAC1B,UAAU;EACV,0BAA0B;CAC3B;AACD;EACE,kCAAkC;CACnC;AACD;EACE,oBAAoB;EACpB,cAAc;CACf","file":"style.less","sourcesContent":["/* colors */\n/* font-colors */\n/* sizes */\n/* breakpoints */\n.h3 {\n  margin-bottom: 45px;\n}\n.againSendCodeP {\n  color: #353c4b !important;\n  font-size: 15px;\n  margin-top: -10px;\n  margin-bottom: 15px!important;\n}\n.againSendCode {\n  background: transparent!important;\n  border: 0!important;\n  border-radius: 0!important;\n  padding: 0!important;\n  font-weight: 400!important;\n  display: inline-block !important;\n  color: #0f52de !important;\n  margin: 0;\n  height: inherit!important;\n}\n.againSendCode:disabled {\n  background: transparent!important;\n}\n.p {\n  margin-bottom: 45px;\n  margin-top: 0;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"h3": "_2fILKXqMTtxzGPfpaqKlzn",
	"againSendCodeP": "_36WAFuGhKFofsTq4Fp1FDq",
	"againSendCode": "_35YELMku8ztm6xZBSTTOTy",
	"p": "JPLp8ByooBYaYpflQdlOu"
};