import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/UI/Form/Button';
import Gallery from 'components/UI/Gallery';
import { Check } from 'components/UI/Icons';
import style from './style.less';
import ActionMenu from 'components/UI/ActionMenu/index';

class Car extends React.Component {
	state = {
		openImage: false,
		url: '',
	};

	static propTypes = {
		bodyTypeId: PropTypes.object,
		loadTypeId: PropTypes.object,
		carTypeId: PropTypes.object,
		id: PropTypes.number,
		loadType: PropTypes.string,
		photos: PropTypes.array,
		registrationNumber: PropTypes.string,
		vinNumber: PropTypes.string,
		justShow: PropTypes.bool,
		width: PropTypes.number,
		height: PropTypes.number,
		length: PropTypes.number,
	};

	static defaultProps = {
		bodyTypeId: { titleRu: 'Не указано' },
		carTypeId:  { titleRu: 'Не указано' },
		loadTypeId:  { titleRu: 'Не указано' },
		id: 0,
		loadType:  '',
		photos: [],
		registrationNumber: '',
		vinNumber: '',
		actionRemove: ()=>{},
		carMark: {title: ''},
		carModel: {title: ''},
		carSeries: {title: ''},
		justShow: false,
		width: 0,
		height: 0,
		length: 0,
	};

	handleOpenGallery = url => {
		this.setState({
			openImage: true,
			url,
		});
	};

	handleCloseGallery = () => {
		this.setState({ openImage: false });
	};

	render() {
		let { bodyTypeId, id, loadTypeId, photos, registrationNumber, vinNumber, actionRemove, actionEdit } = this.props,
			title = `${this.props.carMark.title}, ${this.props.carModel.title} - ${this.props.carSeries.title}`,
			editCar = () => {
				actionEdit({
					...this.props,
					photoIds: this.props.photos
				});
			};

		const iconClass = vinNumber ? style.true_icon : style.false_icon;
		const car_width = this.props.width;
		const car_height = this.props.height;
		const car_length = this.props.length;
		const car_volume = car_width * car_height * car_length;


		return (
			<React.Fragment>
				<div className={`${style.car_container} ${this.props.className}`}>
					{
						this.props.justShow
						&& <div className={`${style.title} ${style.title_noAction}`}>{ title }</div>
					}
					{
						!this.props.justShow
						&&
						<div className={style.header}>
							<div className={style.title}>
								{ title }
							</div>
							<ActionMenu
								type={'car'}
								actionEdit={editCar}
								actionDelete={actionRemove}
							/>
						</div>
					}
					<div className={style.about_table}>
						<div className={style.about_row}>
							<div className={style.about_td}><span>Грузоподъемность</span></div>
							<div className={style.about_td}>{ this.props.carrying }т.</div>
						</div>
						<div className={style.about_row}>
							<div className={style.about_td}><span>Объем</span></div>
							<div className={style.about_td}>{ car_volume }м<sup>3</sup> <span className={style.gray}>
									(Ш{ car_width }*В{ car_height }*Д{ car_length })
								</span>
							</div>
						</div>
						<div className={style.about_row}>
							<div className={style.about_td}><span>Тип кузова</span></div>
							<div className={style.about_td}>{ bodyTypeId && bodyTypeId.titleRu }</div>
						</div>
						<div className={style.about_row}>
							<div className={style.about_td}><span>Тип погрузки</span></div>
							<div className={style.about_td}>{ loadTypeId && loadTypeId.titleRu }</div>
						</div>
						<div className={style.about_row}>
							<div className={style.about_td}><span>Гос. номер</span></div>
							<div className={style.about_td}>{registrationNumber}</div>
						</div>
						<div className={style.about_row}>
							<div className={style.about_td}><span>VIN</span></div>
							<div className={style.about_td}>
								{
									this.props.justShow
									&& Check(iconClass)
								}
								{
									!this.props.justShow
									&& vinNumber
								}
							</div>
						</div>
					</div>

					<div className={style.photos}>
						{
							photos.length > 0
							&&
							photos.map(img => (
								<Button key={img.id} className={`${style.button} ${style['button' + photos.length]}`} onClick={() => this.handleOpenGallery(img.images[1].url)}>
									<img alt={'car#' + id} src={img.images[0].url}/>
								</Button>
							))
						}
						{
							photos.length === 0
							&& <span className={style.gray}>Нет фото</span>
						}
					</div>
				</div>
				{
					this.state.openImage
					&& <Gallery images={photos.map(img => img.images[1].url)} target={this.state.url} handleClose={() => this.handleCloseGallery()} />
				}
			</React.Fragment>
		)
	}
}

export default Car;
