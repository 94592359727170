import React, { Fragment } from 'react';
import style from 'components/Chat/style.less';
import { Link } from 'react-router-dom';

const ChatNotFound = ({ filterLength, dialogLength, role, onClick }) => (
	<Fragment>
		{
			filterLength === 0 && dialogLength > 0 &&
			<p className={style.not_found}>Ничего не найдено</p>
		}
		{
			dialogLength === 0 && role === 'driver' &&
			<p className={style.not_found}>
				Ваш список заказчиков пуст.<br/>
				<Link to={'/search'} onClick={onClick}>Воспользуйтесь поиском</Link>
			</p>
		}
		{
			dialogLength === 0 && role === 'customer' &&
			<p className={style.not_found}>
				У вас отстутствуют диалоги, <br/>Вы можете найти перевозчика через{' '}
				<Link to={'/search'} onClick={onClick}>Поиск</Link>
			</p>
		}
	</Fragment>
);


export default ChatNotFound;